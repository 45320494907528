import { admin } from "../../ServiceConfig";

export function createJobOpening(data) {
  return admin.post(`/job-opening`, data);
}

export function updateJobOpening(id, data) {
  return admin.put(`/job-opening/${id}`, data);
}

export function getJobById(id) {
  return admin.get(`/job-opening/${id}`);
}

export function getAllJobs() {
  return admin.get(`/job-opening`);
}

export function deleteJob(id) {
  return admin.delete(`/job-opening/${id}`);
}

export function getJobApplicationById(id){
  return admin.get(`job-application/${id}`)
}

export function updateJobApplicationStatus(id, data){
  return admin.post(`job-application/${id}`, data)
}

export function getAllJobProfiles(){
  return admin.get(`job-openingrole`)
}

export function reorderJobOpenings(data){
  return admin.post(`/order-job-opening`, data)
}

export function getAlljobNames(data){
  return admin.get(`/all-job-opening`, data)
}