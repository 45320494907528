import { Link, useNavigate } from "react-router-dom";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";
import NewsletterForm from "./NewsletterForm";

function AddLocation() {
  const navigate = useNavigate();

  const onAddNewsletter = (data) => {
    navigate(`/newsletter`);
  };

  return (
    <div className="">
      {/* <MetaTags title="Add Location | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Add Newsletter</H2>
        <Link
          to="/newsletter"
          className="btn-primary dark:bg-green-800 flex items-center gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      <NewsletterForm onSuccess={(data) => onAddNewsletter(data)} />
    </div>
  );
}

export default AddLocation;
