import { admin } from "../../ServiceConfig";

export function getBatchesByItinerary(itineraryId) {
  return admin.get(`itinerary/${itineraryId}/batches`);
}

export function createBatches(data) {
  return admin.post("/batches", data);
}

export function updateBatch(data) {
  return admin.put(`/batches/${data.id}`, data);
}

export function deleteBatch(id) {
  return admin.delete(`/batches/${id}`);
}

export function getMonthEventsBooking(month, year) {
  return admin.get(`/monthsevent/${month}/${year}`);
}
