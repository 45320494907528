import { FieldArray, useFormikContext } from "formik";
import InputLabel from "./InputLabel";
import SelectSearch from "./SelectSearch";
import { useEffect } from "react";
import { getStayById } from "../../services/StayServices";
import { useState } from "react";
import NumberIncrementDecrementInput from "./NumberIncrementDecrementInput";
import { MdClose } from "react-icons/md";

function RoomSelectInput({ stayId, index }) {
  const { values, setFieldValue } = useFormikContext();
  const [roomTypes, setRoomTypes] = useState([]);

  const updateRoomAdults = (adults, indx) => {
    let stayRooms = values.itinerary_stays[index].rooms;

    let totalAdults = 0;

    stayRooms.forEach((room, i) => {
      if (i === indx) {
        totalAdults += parseInt(adults);
      } else {
        totalAdults += parseInt(room.adults);
      }
    });

    if (totalAdults > values.adults) return;

    stayRooms[indx].adults = adults;
    setFieldValue(`itinerary_stays[index].rooms`, stayRooms);
  };

  const updateRoomChildren = (children, indx) => {
    let stayRooms = values.itinerary_stays[index].rooms;

    let totalChildren = 0;

    stayRooms.forEach((room, i) => {
      if (i === indx) {
        totalChildren += parseInt(children);
      } else {
        totalChildren += parseInt(room.children);
      }
    });

    if (totalChildren > values.children) return;

    stayRooms[indx].children = children;
    setFieldValue(`itinerary_stays[index].rooms`, stayRooms);
  };

  const updateRoomInfants = (infants, indx) => {
    let stayRooms = values.itinerary_stays[index].rooms;

    let totalInfants = 0;

    stayRooms.forEach((room, i) => {
      if (i === indx) {
        totalInfants += parseInt(infants);
      } else {
        totalInfants += parseInt(room.infants);
      }
    });

    if (totalInfants > values.infants) return;

    stayRooms[indx].infants = infants;
    setFieldValue(`itinerary_stays[index].rooms`, stayRooms);
  };

  useEffect(() => {
    if (!stayId) return;
    getStayById(stayId).then((res) => {
      console.log(res.data.data.room_type);
      setRoomTypes(res.data.data.room_type);
    });
  }, [stayId]);

  return (
    <div>
      <FieldArray
        name={`itinerary_stays.${index}.rooms`}
        render={(arrayHelpers) => (
          <div className="rounded-lg border p-2 ">
            <h3 className="mb-4 font-bold">Select Rooms</h3>
            {values.itinerary_stays[index]?.rooms?.length > 0 &&
              values.itinerary_stays[index]?.rooms?.map((rooms, indx) => (
                <div key={indx} className="mb-2 flex flex-wrap gap-4">
                  <div className="grow">
                    <InputLabel label="Room Type" required={true} />
                    <SelectSearch
                      options={roomTypes?.map((type) => ({
                        value: `${type.id}`,
                        label: type.name,
                      }))}
                      value={`${values.itinerary_stays[index]?.rooms[indx].stay_room_id}`}
                      onChange={(value) =>
                        setFieldValue(
                          `itinerary_stays.${index}.rooms.${indx}.stay_room_id`,
                          value
                        )
                      }
                    />
                  </div>

                  <div className="">
                    <InputLabel label="Adults" required={true} />
                    <NumberIncrementDecrementInput
                      value={values.itinerary_stays[index]?.rooms[indx].adults}
                      updateCounter={(value) => updateRoomAdults(value, indx)}
                    />
                  </div>
                  <div className="">
                    <InputLabel label="Children" />
                    <NumberIncrementDecrementInput
                      value={
                        values.itinerary_stays[index]?.rooms[indx].children
                      }
                      updateCounter={(value) => updateRoomChildren(value, indx)}
                    />
                    <span className="text-xs text-gray-400"> upto 11 Yrs</span>
                  </div>
                  <div className="">
                    <InputLabel label="Infants" />
                    <NumberIncrementDecrementInput
                      value={values.itinerary_stays[index]?.rooms[indx].infants}
                      updateCounter={(value) => updateRoomInfants(value, indx)}
                    />
                    <span className="text-xs text-gray-400"> upto 2 Yrs</span>
                  </div>

                  <button
                    type="button"
                    onClick={() => {
                      arrayHelpers.remove(indx);
                      setFieldValue(
                        `itinerary_stays[${index}].no_of_rooms`,
                        values.itinerary_stays[index].no_of_rooms - 1
                      );
                    }}
                    className={`self-center rounded-full border border-red-300 p-2 text-red-500 ${
                      indx === 0 ? "invisible" : ""
                    }`}
                  >
                    <MdClose />
                  </button>
                </div>
              ))}
          </div>
        )}
      />
    </div>
  );
}

export default RoomSelectInput;
