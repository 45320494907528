import { FieldArray, useFormikContext } from "formik";
import DragAndDropList from "../../../../components/dragAndDrop/DragAndDropList";
import { Draggable } from "react-beautiful-dnd";
import HotelForm from "./HotelForm";

function ItineraryHotelForm() {
  const { values, setFieldValue } = useFormikContext();

  const handleReorder = (startIndex, endIndex) => {
    let currentOrder = JSON.parse(JSON.stringify(values.itinerary_stays));

    console.log(currentOrder);

    const reorderedItems = JSON.parse(JSON.stringify(values.itinerary_stays));
    const [removed] = reorderedItems.splice(startIndex, 1);
    console.log(removed);
    reorderedItems.splice(endIndex, 0, removed);
    console.log(reorderedItems);

    reorderedItems.forEach((item, index) => {
      item.check_in_date = currentOrder[index].check_in_date;
      item.check_out_date = currentOrder[index].check_out_date;
      item.no_of_nights = currentOrder[index].no_of_nights;
    });

    console.log(reorderedItems);

    setFieldValue(`itinerary_stays`, reorderedItems);
  };

  // const removeStay = (index) => {
  //   let stays = values.itinerary_stays;

  //   stays[index - 1].check_out_date =
  //     values.itinerary_stays[index].check_out_date;
  //   stays[index - 1].no_of_nights = moment(
  //     values.itinerary_stays[index].check_out_date
  //   ).diff(values.itinerary_stays[index - 1].check_in_date, "days");

  //   stays.splice(index, 1);

  //   setFieldValue(`itinerary_stays`, stays);
  // };

  return (
    <div>
      {values.start_date && values.no_of_nights > 0 && (
        <div className="rounded-lg border-2 p-4">
          <h3 className="font-bold">Stay Details</h3>
          <FieldArray name={`itinerary_stays`}>
            {({ insert, remove, push }) => (
              <div className="mt-8">
                <DragAndDropList
                  items={values.itinerary_stays}
                  onReorder={handleReorder}
                >
                  {values.itinerary_stays?.length > 0 &&
                    values.itinerary_stays?.map((day, index) => (
                      <Draggable
                        key={day.id ? day.id : `stay-${index}`}
                        draggableId={day.id ? `${day.id}` : `${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <HotelForm index={index} />
                          </li>
                        )}
                      </Draggable>
                    ))}
                </DragAndDropList>
              </div>
            )}
          </FieldArray>
        </div>
      )}
    </div>
  );
}

export default ItineraryHotelForm;
