import React from "react";
import { Outlet } from "react-router-dom";

function AdminStatWidgetWrapper() {
  return (
    <div className="wrapper">
      {/* <MetaTags title={"All Categories"} /> */}
      {/* <div className="h-full max-h-[calc(100%-1rem)] overflow-auto"> */}
        {<Outlet />}
      </div>
    // </div>
  );
}

export default AdminStatWidgetWrapper;
