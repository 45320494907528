import { NavLink } from "react-router-dom";

function HeaderMenuItem({ className, linkTo, children }) {
  return (
    <NavLink
      to={linkTo}
      className={`rounded-md px-4 py-1 font-semibold text-gray-500 transition-all duration-300 hover:font-bold hover:text-gray-600 ${className} ${({
        isActive,
      }) => (isActive ? "bg-green-50 text-gray-700" : "")}`}
    >
      {children}
    </NavLink>
  );
}

export default HeaderMenuItem;
