import React, { useEffect, useRef } from 'react'
import DataTable from '../common/DataTable'
import { getAdminUrl, getUploadsUrl } from '../../../ServiceConfig'
import ComponentHeader from '../common/ComponentHeader'
import H2 from '../../../components/headings/H2'
import { Link, useNavigate } from 'react-router-dom'
import { FaPlusSquare } from 'react-icons/fa'
import { deleteInternalNotificationById } from '../../services/InternalNotificationsServices'
import { toast } from 'react-toastify'
import $ from "jquery";

const InternalNotificationList = () => {
    const internalNotifRef = useRef()
    const navigate = useNavigate();
    const deleteSelectedNotification = (id) => {
        if (!window.confirm("Do You really want to delete this internal notification?")) return;
        deleteInternalNotificationById(id)
            .then((res) => {
                toast.success("deleted successfully");
                internalNotifRef.current.deleteRow(id);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };
    useEffect(() => {
        $(document).on("click", ".notification-edit-btn", function (e) {
            e.stopPropagation();
            e.preventDefault();
            let cell = $(this).attr("data-id");
            navigate(`/internal-notification/edit/${cell}`);
        });

        $(document).on("click", ".notification-delete-btn", function (e) {
            e.stopPropagation();
            e.preventDefault();
            let dataId = $(this).attr("data-id");
            deleteSelectedNotification(dataId);
        });

        return () => {
            $(document).off("click", ".notification-edit-btn");
            $(document).off("click", ".notification-delete-btn");
        };
    });
    const actionIcons = function (cell, formatterParams, onRendered) {
        return `
       <div class="flex items-center gap-3">
          <div class="notification-edit-btn cursor-pointer" data-id=${cell.getRow().getData().id
            }>
              <i class="fas fa-edit"></i>
          </div>
          <div class="notification-delete-btn cursor-pointer text-red-500" data-id=${cell.getRow().getData().id
            }>
            <i class="fas fa-trash-alt"></i>
          </div>
      </div>
      `;
    };
    const isFrozen = window.innerWidth <= 768 ? false : true;
    const columns = [
        {
            title: "Id",
            field: "id",
            responsive: 0,
            width: "50",
            resizable: true,
            vertAlign: "middle",
        },
        {
            title: "Title",
            field: "title",
            minWidth: "250",
            vertAlign: "middle",
            responsive: 0,
            resizable: true,
        },
        {
            title: "Image",
            minWidth: "150",
            width: "100",
            field: "image",
            resizable: true,
            responsive: 0,
            vertAlign: "middle",
            formatter: function (cell, formatterParams, onRendered) {
                const value = cell.getValue();
                const urlPrefix = `${getUploadsUrl()}/internal-notification/`;
                const fallbackImage = ""; // Specify the path to your fallback image

                const imageUrl =
                    value != null ? `${urlPrefix}${value}` : `${urlPrefix}missing.jpg`;

                return `<div class="center"><img src="${imageUrl}" style="width:auto;height:50px"/></div>`;
            },
        },
        {
            title: "Description",
            field: "description",
            responsive: 0,
            minWidth: "100",
        },
        {
            title: "Priority",
            field: "priority",
            headerSort: false,
            vertAlign: "middle",
            hozAlign: "center",
            width: "80",
            responsive: 0,
        },
        {
            title: "Link",
            field: "link",
            headerSort: false,
            responsive: 0,
            minWidth: "170",
        },
        {
            title: "Status",
            field: "status",
            headerSort: false,
            responsive: 0,
            width: "80",
            resizable: true,
            vertAlign: "middle",
            hozAlign: "center",
            formatter: "tickCross",
        },
        {
            title: "CreatedBy",
            minWidth: "60",
            field: "created_by.first_name",
            responsive: 0,
            resizable: true,
            vertAlign: "middle",
        },
        {
            formatter: actionIcons,
            headerSort: false,
            width: 80,

            hozAlign: "center",
            vertAlign: "middle",
            frozen: isFrozen,
        },
    ];
    return (
        <div className="flex h-full flex-col">
            <ComponentHeader className="border-b">
                <H2>Internal Notification</H2>
                <Link
                    to="/internal-notification/add"
                    className="btn-primary flex items-center dark:bg-green-800 gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
                >
                    <FaPlusSquare /> Add New
                </Link>
            </ComponentHeader>
            <div className="h-full">
                <DataTable
                    ref={internalNotifRef}
                    ajaxUrl={`${getAdminUrl()}/internal-notification`}
                    columns={columns}
                    search={false}
                    responsiveLayout="collapse"
                />
            </div>
        </div>
    )
}

export default InternalNotificationList