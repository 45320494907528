import React from "react";
import SelectSearch from "./SelectSearch";
import { useEffect } from "react";
import { useState } from "react";
import { getAllStaysByLocationId } from "../../services/StayServices";
import { useFormikContext } from "formik";
import { FaPlus } from "react-icons/fa";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import StayForm from "../stays/StayForm";

function StaySelectInput({ locationId, updateLocation, fieldKey, value }) {
  const { values, setFieldValue } = useFormikContext();
  const [stays, setStays] = useState([]);

  const [addStayModal, setAddStayModal] = useState(false);

  const getstaysByLocation = (locationId) => {
    getAllStaysByLocationId(locationId).then((res) => {
      setStays(res.data.data);
    });
  };

  useEffect(() => {
    if (!locationId) return;
    getstaysByLocation(locationId);
  }, [locationId]);

  const onAddStay = (data) => {
    setAddStayModal(false);
    getstaysByLocation(locationId);
  };

  return (
    <>
      <div className="flex items-end gap-[2px]">
        <div className="grow">
          <SelectSearch
            options={stays.map((stay) => ({
              value: stay.id,
              label: stay.name,
            }))}
            value={value || ""}
            onChange={(value) => setFieldValue(fieldKey, value)}
          />
        </div>
        <button
          className="rounded-lg border border-green-300 p-2 text-green-500"
          onClick={() => {
            setAddStayModal(true);
          }}
          type="button"
        >
          <FaPlus />
        </button>
      </div>

      <Modal
        isOpen={addStayModal}
        contentLabel="Update Participant Details"
        className="mx-auto mt-[2%] max-h-[90%] max-w-[75%] overflow-auto rounded-md bg-white p-4"
        overlayClassName="fixed top-0 left-0 w-full h-full !z-40 !bg-black/50"
        onAfterClose={updateLocation}
      >
        <div className="flex items-center justify-between border-b p-4">
          <h2 className="text-xl font-bold">Add Stay</h2>
          <button
            onClick={() => setAddStayModal(false)}
            className="cursor-pointer rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
        </div>

        <div className="mt-2">
          <StayForm onSuccess={(data) => onAddStay(data)} />
        </div>
      </Modal>
    </>
  );
}

export default StaySelectInput;
