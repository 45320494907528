import { useContext, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";
import HeaderWrapper from "./components/header/HeaderWrapper";
import Sidebar from "./components/sidebar/SideBar";
import { SideBarProvider } from "./context/SideBarProvider";
import NotFound404 from "./components/NotFound404";

function AdminLayout() {
  const reloadPage = () => {
    window.location.reload();
  };
  const ErrorFallback = () => {
    return (
      <div className="flex h-screen flex-col items-center justify-center bg-gray-100">
        <div className="rounded bg-white p-8 text-center shadow-md">
          <h1 className="mb-4 text-3xl font-semibold text-sky-600">
            Oops! Something went wrong.
          </h1>
          <p className="mb-4 text-gray-700">
            Please try again by clicking the button below:
          </p>
          <button
            onClick={reloadPage}
            className="focus:shadow-outline rounded bg-sky-600 px-4 py-2 text-white hover:bg-sky-700 focus:outline-none"
          >
            Reload this page
          </button>
        </div>
      </div>
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <SideBarProvider>
        <div className="flex h-[100vh] bg-cyan-100 dark:bg-slate-900">
          <Sidebar />
          <div className={`shrink grow overflow-auto`}>
            <div className="sticky top-0 z-50 h-12 bg-white">
              <HeaderWrapper />
            </div>
            <div className="min-h-[95%] shrink grow bg-[#f3fae5] p-2 dark:bg-slate-600">
              <Outlet />
            </div>
          </div>
        </div>
      </SideBarProvider>
    </ErrorBoundary>
  );
}

export default AdminLayout;
