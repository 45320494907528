import React, { useEffect, useState } from 'react'
import ComponentHeader from '../common/ComponentHeader'
import { Link, useParams } from 'react-router-dom'
import { getHomePackageCategoryById } from '../../services/SettingsServices';
import H2 from '../../../components/headings/H2';
import { FaListAlt } from 'react-icons/fa';
import HomePageCategoryForm from './HomePageCategoryForm';
import { toast } from 'react-toastify';

const EditHomepageCategory = () => {
  const homeId = useParams().id;
  const [data, setData] = useState()
  useEffect(() => {
    getHomePackageCategoryById(homeId).then((res) => {
      // toast.success(res.data.message)
      setData(res.data.data)
    }).catch((er) => {
      console.log(er)
      toast.error("error in getting Data by Id")
    })
  }, [])


  const onEditHomePageCtaegory = (data) => {
    console.log(data)
  };


  return (
    <div className="dark:bg-slate-900 dark:text-white h-[100%]">
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Homepage category</H2>
        <Link
          to="/homepage-category"
          className="btn-primary flex items-center gap-1 whitespace-nowrap py-1 px-4 dark:bg-green-700 lg:px-12 lg:py-2"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {data && (
        <>
          <HomePageCategoryForm
            formData={data}
            edit={true}
            onSuccess={(data) => onEditHomePageCtaegory(data)}
          />
        </>
      )}
    </div>
  )
}

export default EditHomepageCategory