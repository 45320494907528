import React, { useEffect, useState } from 'react'
import { getAllHomepageCategories } from '../../services/SettingsServices'
import ComponentHeader from '../common/ComponentHeader'
import H2 from '../../../components/headings/H2'
import { Link, useNavigate } from 'react-router-dom'
import { FaListAlt } from 'react-icons/fa'
import HomePageCategoryForm from './HomePageCategoryForm'

const AddHomePageCategory = () => {
  const navigate = useNavigate()
  const onAddState = (data) => {
    navigate(`/homepage-category`);
  };
  return (
    <div><ComponentHeader className="mb-4 border-b">
      <H2>Add HomePage Category</H2>
      <Link
        to="/homepage-category"
        className="btn-primary flex dark:bg-green-800 items-center gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
      >
        <FaListAlt /> View All
      </Link>
    </ComponentHeader>
      <HomePageCategoryForm onSuccess={(data) => onAddState(data)} />
    </div>
  )
}

export default AddHomePageCategory