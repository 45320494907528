import "./booking.css";

import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getReadableDate, monthShortNames } from "../../helpers/Helper";
import { getAdminUrl } from "../../ServiceConfig";
import DataTable from "../components/common/DataTable";
import SelectSearch from "../components/inputs/SelectSearch";

function BookingStatus() {
  const fdTableRef = useRef();
  const cbTableRef = useRef();
  const allTableRef = useRef();

  const [month, setMonth] = useState(
    `${new Date().getMonth()} ${new Date().getFullYear()}`
  );

  const [mth, setMth] = useState(`${new Date().getMonth() + 1}`);
  const [year, setYear] = useState(`${new Date().getFullYear()}`);
  const [bookingStatusType, setBookingStatusType] = useState("all");

  const navigate = useNavigate();

  const columns = [
    {
      title: "Event Name",
      field: "package_name",
      minWidth: "440",
      sorter: "number",
      formatter: function (cell) {
        const data = cell.getRow().getData();
        if (cell.getRow().getData().booked_slots > 0) {
          return `<a target="_blank" href="/bookings/participants?package_id=${data.package_id
            }&event_date=${data.startdate
            }" style="color: blue;">${cell.getValue()}</a>`;
        } else {
          return cell.getValue();
        }
      },
    },

    {
      title: "Event Short Code",
      field: "event_code",
      formatter: "textarea",
      minWidth: "240",
    },
    {
      title: "Event Date",
      field: "startdate",
      minWidth: "120",
      formatter: function (cell, formatterParams, onRendered) {
        return getReadableDate(cell.getValue());
      },
    },
    {
      minWidth: "120",
      title: "Last Date",
      field: "lastdate",
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue() ? getReadableDate(cell.getValue()) : "";
      },
    },
    {
      minWidth: "110",
      title: "Bookings",
      field: "booked_slots",
      hozAlign: "center",
      formatter: function (cell) {
        if (cell.getRow().getData().booked_slots > 0) {
          return `<b style="color: blue;">${cell.getValue()}</b>`;
        } else {
          return cell.getValue();
        }
      },
    },
  ];
  const columns1 = [
    {
      title: "Event Name",
      field: "package_name",
      minWidth: "440",
      sorter: "number",
      formatter: function (cell) {
        const data = cell.getRow().getData();
        if (cell.getRow().getData().booked_slots > 0) {
          return `<a target="_blank" href="/bookings/participants?booking_type=customized&package_name=${encodeURIComponent(
            data.package_name.replaceAll(" ", "_")
          )}&event_date=${data.startdate
            }" style="color: blue;">${cell.getValue()}</a>`;
        } else {
          return cell.getValue();
        }
      },
    },
    {
      title: "Event Short Code",
      field: "event_code",
      formatter: "textarea",
      minWidth: "240",
    },

    {
      title: "Event Date",
      field: "startdate",
      minWidth: "120",
      formatter: function (cell, formatterParams, onRendered) {
        return getReadableDate(cell.getValue());
      },
    },
    {
      title: "Last Date",
      field: "lastdate",
      minWidth: "120",
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue() ? getReadableDate(cell.getValue()) : "";
      },
    },
    {
      title: "Bookings",
      field: "booked_slots",
      hozAlign: "center",
      minWidth: "110",
      formatter: function (cell) {
        if (cell.getRow().getData().booked_slots > 0) {
          return `<b style="color: blue;">${cell.getValue()}</b>`;
        } else {
          return cell.getValue();
        }
      },
    },
  ];
  const columns2 = [
    {
      title: "Event Name",
      field: "package_name",
      sorter: "number",
      minWidth: "440",
      formatter: function (cell) {
        const data = cell.getRow().getData();
        if (cell.getRow().getData().booked_slots > 0) {
          if (cell.getRow().getData().package_id > 0) {
            return `<a target="_blank" href="/bookings/participants?package_id=${data.package_id
              }&event_date=${data.startdate
              }" style="color: blue;">${cell.getValue()}</a>`;
          } else {
            return `<a target="_blank" href="/bookings/participants?booking_type=customized&package_name=${encodeURIComponent(
              data.package_name.replaceAll(" ", "_")
            )}&event_date=${data.startdate
              }" style="color: blue;">${cell.getValue()}</a>`;
          }
        } else {
          return cell.getValue();
        }
      },
    },
    {
      title: "Event Short Code",
      field: "event_code",
      minWidth: "240",
      formatter: "textarea",
    },

    {
      title: "Event Date",
      field: "startdate",
      minWidth: "120",
      formatter: function (cell, formatterParams, onRendered) {
        return getReadableDate(cell.getValue());
      },
    },
    {
      title: "Last Date",
      field: "lastdate",
      minWidth: "120",
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue() ? getReadableDate(cell.getValue()) : "";
      },
    },
    {
      title: "Bookings",
      field: "booked_slots",
      hozAlign: "center",
      minWidth: "110",
      formatter: function (cell) {
        if (cell.getRow().getData().booked_slots > 0) {
          return `<b style="color: blue;">${cell.getValue()}</b>`;
        } else {
          return cell.getValue();
        }
      },
    },
  ];

  function onChangeMonth(selectedMonth) {
    // setMonth(selectedMonth);
    setMth(parseInt(selectedMonth?.split(" ")[0]));
    setYear(parseInt(selectedMonth?.split(" ")[1]));
  }

  useEffect(() => {
    // let mth1 = parseInt(month?.split(" ")[0]) + 1;
    // let year1 = month?.split(" ")[1];
    // setMth(parseInt(month?.split(" ")[0]) + 1);
    // setYear(parseInt(month?.split(" ")[1]));
    // setMth(mth);
    // setYear(year);

    if (bookingStatusType === "fd") {
      fdTableRef.current.reloadTable(
        `${getAdminUrl()}/monthsEvent/${mth}/${year}`
      );
    } else if (bookingStatusType === "all") {
      allTableRef.current.reloadTable(
        `${getAdminUrl()}/all-monthsEvent/${mth}/${year}`
      );
    } else {
      cbTableRef.current.reloadTable(
        `${getAdminUrl()}/customized-monthsEvent/${mth}/${year}`
      );
    }
  }, [mth, year, bookingStatusType]);

  // const fdRowClick = (data) => {
  //   navigate(
  //     `/bookings/participants?package_id=${data.package_id}&event_date=${data.startdate}`
  //   );
  // };

  // const cbRowClick = (data) => {
  //   navigate(
  //     `/bookings/participants?package_name=${data.package_name.replaceAll(
  //       " ",
  //       "_"
  //     )}&event_date=${data.startdate}`,
  //     { state: { packageType: "custom" } }
  //   );
  // };

  const getCurrentYearMonth = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    const currentMonths = [];

    for (let i = 0; i < 12; i++) {
      if (i >= currentMonth) {
        currentMonths.push(`${i} ${currentYear}`);
      }
    }

    return currentMonths;
  };

  const getPreviousMonths = (numMonths) => {
    const months = [];
    const currentDate = new Date();

    for (let i = 0; i < numMonths; i++) {
      currentDate.setMonth(currentDate.getMonth() - 1);
      const month = currentDate.getMonth();
      const year = currentDate.getFullYear();
      months.unshift(`${month} ${year}`);
    }

    return months;
  };

  const getPreviousMonths2 = (numMonths) => {
    const months = [];
    const currentDate = new Date();
    for (let i = 0; i < numMonths; i++) {
      currentDate.setMonth(currentDate.getMonth() - 1);
      const month = currentDate.getMonth();
      const year = currentDate.getFullYear();
      months.unshift({ label: `${monthShortNames[month]} ${year}`, value: `${month + 1} ${year}` });
    }
    return months;
  }

  const getCurrentYearMonth2 = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    const currentMonths = [];

    for (let i = 0; i < 12; i++) {
      if (i >= currentMonth) {
        currentMonths.push({ label: `${monthShortNames[i]} ${currentYear}`, value: `${i + 1} ${currentYear}` });
      }
    }

    return currentMonths;
  };


  const currentYearMonth = getCurrentYearMonth2();
  const previousMonths = getPreviousMonths2(3);

  const monthList = [...previousMonths, ...currentYearMonth];

  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-wrap items-center justify-between border-b-2 py-4">
        <div>
          <b> Bookings -</b>{" "}
          {/* <select
            name=""
            id=""
            onChange={(e) => setBookingStatusType(e.target.value)}
            value={bookingStatusType}
            className="rounded-md border dark:bg-slate-900 dark:text-white"
          >
            <option value="fd">FDs Only</option>
            <option value="cb">Customized Booking Only</option>
            <option value="all">All Bookings</option>
          </select> */}

          <>
            <button
              className={`w-auto lg:px-4 px-2 py-2 font-semibold lg:text-sm text-xs rounded-l-lg transition duration-300 ${bookingStatusType === "all" ? 'bg-[#0183c0] text-white shadow' : 'bg-[#D7F2FD] text-black'
                }`}
              onClick={() => setBookingStatusType("all")}
            >
              All
            </button>
            <button
              className={`w-auto lg:px-4 px-2 py-2 font-semibold lg:text-sm text-xs border-x-2 border-[#0183c0] transition duration-300 ${bookingStatusType === "cb" ? 'bg-[#0183c0] text-white shadow' : 'bg-[#D7F2FD] text-black'
                }`}
              onClick={() => setBookingStatusType("cb")}
            >
              Customized Only
            </button>
            <button
              className={`w-auto lg:px-4 px-2 py-2 font-semibold lg:text-sm text-xs mb-2 rounded-r-lg transition duration-300 ${bookingStatusType === "fd" ? 'bg-[#0183c0] text-white shadow' : 'bg-[#D7F2FD] text-black'
                }`}
              onClick={() => setBookingStatusType("fd")}
            >
              FD Only
            </button>
          </>
        </div>
        <div>
          {/* <select
            name=""
            id=""
            onChange={(e) => {
              onChangeMonth(e.target.value);
            }}
            className="rounded-md border"
          >
            {monthList?.map((month) => {
              return (
                <option
                  key={month}
                  value={month}
                  selected={
                    month ===
                    `${new Date().getMonth()} ${new Date().getFullYear()}`
                  }
                >
                  {monthShortNames[parseInt(month.split(" ")[0])]}{" "}
                  {month.split(" ")[1]}
                </option>
              );
            })}
          </select> */}

          <SelectSearch
            className="rounded-md border"
            value={`${mth} ${year}`}
            options={
              monthList
            }
            onChange={(value) => onChangeMonth(value)}
          />

          {/* <SelectSearch
                      value={values.city_from}
                      options={
                        listofCity.map((oneCity) => ({ label: oneCity.name, value: oneCity.id }))
                      }
                      onChange={(value) => setFieldValue("city_from", value)}
                    /> */}
        </div>
      </div>

      <div className="py-1">
        {bookingStatusType === "fd" && (
          <h2 className="text-lg font-bold">
            Fixed Departures Booking Status for : {monthShortNames[mth - 1]} -{" "}
            {year}
          </h2>
        )}
        {bookingStatusType === "cb" && (
          <h2 className="text-lg font-bold">
            Customized Booking Status for : {monthShortNames[mth - 1]} - {year}
          </h2>
        )}
        {bookingStatusType === "all" && (
          <h2 className="text-lg font-bold">
            All (Fixed and Customized) Booking Status for :{" "}
            {monthShortNames[mth - 1]} - {year}
          </h2>
        )}
      </div>
      <div className="h-full">
        {bookingStatusType === "fd" && (
          <DataTable
            ref={fdTableRef}
            columns={columns}
            search={true}
            groupBy="startdate"
            // rowClick={fdRowClick}
            ajaxUrl={`${getAdminUrl()}/monthsEvent/${parseInt(mth)}/${parseInt(
              year
            )}`}
            downloadAble={true}
            perPage={200}
            // localSearch={true}
          />
        )}
        {bookingStatusType === "cb" && (
          <DataTable
            ref={cbTableRef}
            columns={columns1}
            search={true}
            groupBy="startdate"
            // rowClick={cbRowClick}
            ajaxUrl={`${getAdminUrl()}/customized-monthsEvent/${parseInt(
              mth
            )}/${parseInt(year)}`}
            downloadAble={true}
            perPage={200}
            // localSearch={true}
          />
        )}
        {bookingStatusType === "all" && (
          <DataTable
            ref={allTableRef}
            columns={columns2}
            search={true}
            groupBy="startdate"
            // rowClick={cbRowClick}
            ajaxUrl={`${getAdminUrl()}/all-monthsEvent/${parseInt(
              mth
            )}/${parseInt(year)}`}
            downloadAble={true}
            perPage={200}
            // localSearch={true}
          />
        )}
      </div>
    </div>
  );
}

export default BookingStatus;
