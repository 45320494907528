import { admin } from "../../ServiceConfig";

export function getAllThingsToKnow(query) {
  return admin.get(`/things-to-know?query=${query}`);
}
export function getAllThingsToKnowOption() {
  return admin.get(`/all-things-to-know`);
}
export function createThingsToKnow(data) {
  return admin.post(`/things-to-know`, data);
}
export function updateThingsToKnow(id, data) {
  return admin.put(`/things-to-know/${id}`, data);
}
export function deleteThingsToKnow(id) {
  return admin.delete(`/things-to-know/${id}`);
}
export function getThingsToKnowById(id) {
  return admin.get(`/things-to-know/${id}`);
}
export function uploadThingsToKnowImage(file) {
  return admin.post(`uploads/image?upload_path=things-to-know`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
