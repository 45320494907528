import { Helmet } from "react-helmet";
import favicon from "../../assets/image/favicons/favicon.ico";
import apple_touch_icon_57 from "../../assets/image/favicons/apple-icon-57x57.webp";
import apple_touch_icon_60 from "../../assets/image/favicons/apple-icon-60x60.webp";
import apple_touch_icon_72 from "../../assets/image/favicons/apple-icon-72x72.webp";
import apple_touch_icon_76 from "../../assets/image/favicons/apple-icon-76x76.webp";
import apple_touch_icon_114 from "../../assets/image/favicons/apple-icon-114x114.webp";
import apple_touch_icon_120 from "../../assets/image/favicons/apple-icon-120x120.webp";
import apple_touch_icon_144 from "../../assets/image/favicons/apple-icon-144x144.webp";
import apple_touch_icon_152 from "../../assets/image/favicons/apple-icon-152x152.webp";
import apple_touch_icon_180 from "../../assets/image/favicons/apple-icon-180x180.webp";
// import apple_touch_icon_192 from "../../assets/image/favicons/apple-icon-192x192.webp";

import icon_16 from "../../assets/image/favicons/favicon-16x16.webp";
import icon_32 from "../../assets/image/favicons/favicon-32x32.webp";
import icon_96 from "../../assets/image/favicons/favicon-96x96.webp";
import icon_192 from "../../assets/image/favicons/android-icon-192x192.webp";
import ms_icon_144 from "../../assets/image/favicons/ms-icon-144x144.webp";

function MetaTags({ title, description, keywords }) {
  title = title ? title : "Justwravel Admin";
  description = description ? description : "JustWravel Pvt Ltd.";
  keywords = keywords ? keywords : "JustWravel Pvt Ltd.";
  return (
    <Helmet>
      <meta charset="utf-8" />

      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <meta name="author" content="justwravel" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta
        http-equiv="Cache-Control"
        content="no-cache, no-store, must-revalidate"
      />
      <meta http-equiv="Pragma" content="no-cache" />
      <link
        rel="stylesheet"
        href="https://site-assets.fontawesome.com/releases/v5.15.4/css/all.css"
      />

      <meta
        name="B-verify"
        content="76b74ef8ad619f7c00cf0ed2c9a8c9004c6e2f8e"
      />

      <title>{title}</title>
      <link rel="icon" type="image/x-icon" href={favicon} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta httpEquiv="Last-Modified" content={new Date()} />

      <link rel="apple-touch-icon" sizes="57x57" href={apple_touch_icon_57} />
      <link rel="apple-touch-icon" sizes="60x60" href={apple_touch_icon_60} />
      <link rel="apple-touch-icon" sizes="72x72" href={apple_touch_icon_72} />
      <link rel="apple-touch-icon" sizes="76x76" href={apple_touch_icon_76} />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href={apple_touch_icon_114}
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={apple_touch_icon_120}
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href={apple_touch_icon_144}
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={apple_touch_icon_152}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={apple_touch_icon_180}
      />
      <link rel="icon" type="image/webp" sizes="192x192" href={icon_192} />
      <link rel="icon" type="image/webp" sizes="32x32" href={icon_32} />
      <link rel="icon" type="image/webp" sizes="96x96" href={icon_96} />
      <link rel="icon" type="image/webp" sizes="16x16" href={icon_16} />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content={ms_icon_144} />
      <meta name="theme-color" content="#238F23" />

      {/* ----------------------------------------------------- */}
      <link
        rel="stylesheet"
        href="https://site-assets.fontawesome.com/releases/v5.15.4/css/all.css"
      />
    </Helmet>
  );
}
export function MetaTagsDefault({ title, description, keywords }) {
  title = title ? title : "Justwravel Admin";
  description = description ? description : "JustWravel Pvt Ltd.";
  keywords = keywords ? keywords : "JustWravel Pvt Ltd.";
  return (
    <Helmet>
      <meta charset="utf-8" />

      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <meta name="author" content="justwravel" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta
        http-equiv="Cache-Control"
        content="no-cache, no-store, must-revalidate"
      />
      <meta http-equiv="Pragma" content="no-cache" />

      <meta
        name="B-verify"
        content="76b74ef8ad619f7c00cf0ed2c9a8c9004c6e2f8e"
      />

      <title>{title}</title>
      <link rel="icon" type="image/x-icon" href={favicon} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta httpEquiv="Last-Modified" content={new Date()} />

      <link rel="apple-touch-icon" sizes="57x57" href={apple_touch_icon_57} />
      <link rel="apple-touch-icon" sizes="60x60" href={apple_touch_icon_60} />
      <link rel="apple-touch-icon" sizes="72x72" href={apple_touch_icon_72} />
      <link rel="apple-touch-icon" sizes="76x76" href={apple_touch_icon_76} />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href={apple_touch_icon_114}
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={apple_touch_icon_120}
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href={apple_touch_icon_144}
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={apple_touch_icon_152}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={apple_touch_icon_180}
      />
      <link rel="icon" type="image/webp" sizes="192x192" href={icon_192} />
      <link rel="icon" type="image/webp" sizes="32x32" href={icon_32} />
      <link rel="icon" type="image/webp" sizes="96x96" href={icon_96} />
      <link rel="icon" type="image/webp" sizes="16x16" href={icon_16} />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content={ms_icon_144} />
      <meta name="theme-color" content="#238F23" />

      {/* ----------------------------------------------------- */}
      <link
        rel="stylesheet"
        href="https://site-assets.fontawesome.com/releases/v5.15.4/css/all.css"
      />
    </Helmet>
  );
}

export default MetaTags;
