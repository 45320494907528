import { Link, useNavigate } from "react-router-dom";
import H2 from "../../../components/headings/H2";
import ComponentHeader from "../common/ComponentHeader";
import { FaPlusSquare } from "react-icons/fa";
import DataTable from "../common/DataTable";
import { useEffect, useRef } from "react";
import { getAdminUrl, getUploadsUrl } from "../../../ServiceConfig";
import $ from "jquery";

function ListingSeoList() {
  const listingSeoTable = useRef();

  const navigate = useNavigate();

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
  <div class="flex items-center gap-3">
      <div class="listing-edit-btn cursor-pointer" data-id=${
        cell.getRow().getData().id
      }>
          <i class="fas fa-edit"></i>
      </div>

  </div>
  `;
  };

  const bannerImages = function (cell, formatterParams, onRendered) {
    return `<div class="flex items-center flex-wrap">

    ${cell
      .getRow()
      .getData()
      .banner_image_square?.map(
        (banner) =>
          `<img src=${getUploadsUrl()}/listing-seo/square/${banner} class="!w-20"/>`
      )
      .join("<br>")}
      ${cell
        .getRow()
        .getData()
        .banner_image_landscape?.map(
          (banner) =>
            `<img src=${getUploadsUrl()}/listing-seo/landscape/${banner} class="!w-20"/>`
        )
        .join("<br>")}
    </div>`;
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Title",
      field: "title",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Slug",
      field: "slug",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Banner Image",
      hozAlign: "center",
      vertAlign: "middle",
      headerSort: false,
      field: "banner_image_square",
      formatter: bannerImages,
    },
    {
      title: "Description",
      field: "meta_description",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
    },
    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  useEffect(() => {
    // polyfillCountryFlagEmojis();
    $(document).on("click", ".listing-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/listing-seo/edit/${cell}`);
    });

    return () => {
      $(document).off("click", ".listing-edit-btn");
    };
  }, []);

  return (
    <div className="flex h-full flex-col">
      <ComponentHeader className="border-b">
        <H2>Listing SEO</H2>
        <Link
          to="/listing-seo/add"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaPlusSquare /> Add New
        </Link>
      </ComponentHeader>
      <div className="h-full">
        <DataTable
          ref={listingSeoTable}
          ajaxUrl={`${getAdminUrl()}/get-allSeos`}
          columns={columns}
          search={true}
          responsiveLayout="collapse"
        />
      </div>
    </div>
  );
}

export default ListingSeoList;
