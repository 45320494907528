import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getJobApplicationById, updateJobApplicationStatus } from '../../services/JobServices'
import StatusDropdown from './StatusDropdown'
import SingleJobApplicationCard from './SingleJobApplicationCard'
import { toast } from 'react-toastify'

const SingleJobApplication = () => {
  const [applicantData, setApplicantData] = useState()
  const [comment, setComment] = useState('')
  const params = useParams()

  const getSingleUserApplicationData = () => {
    getJobApplicationById(params.id).then(res => { setApplicantData(res.data.data) }).catch(e => { console.log(e) })
  }
  useEffect(() => {
    getSingleUserApplicationData()
  }, [])

  // 0 = Pending, 1 = Selected, 2 = Currently Not Hiring, 3 = Rejected
  const statusArray = ["Pending", "Selected", "Currently Not Hiring", "Rejected"]

  const [selectedStatus, setSelectedStatus] = useState('');

  const handleStatusSelect = (status) => {
    setSelectedStatus(status);
  };

  const updateApplicationStatus = () => {
    updateJobApplicationStatus(applicantData?.application?.id, { status: statusArray.indexOf(selectedStatus), comment: comment }).then(res => {
      if (res.data.status === 'success') {
        toast.success(res.data.message)
        getSingleUserApplicationData()
        setComment('')
        setSelectedStatus('')
      }
    }).catch(e => {
      toast.error("Error in Updating Job Status")
      console.log(e)
    })

  }

  return (
    <div className='flex flex-col gap-4 items-center'>
      <SingleJobApplicationCard applicantData={applicantData} statusArray={statusArray} />
      <div className="bg-white shadow-md rounded-lg overflow-hidden lg:w-1/2 w-full p-6">
        <h2 className="text-xl font-semibold mb-2">Update Application Status</h2>
        <p className="text-gray-600 mb-4"> {statusArray[applicantData?.application?.status]}</p>
        <div className="container mx-auto px-2 py-4">
          <StatusDropdown statusArray={statusArray} onSelect={handleStatusSelect} />
          {selectedStatus && <><textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className="resize-y block w-full mt-3 px-3 border border-gray-300 py-2 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-transparent"
            style={{ minHeight: '4rem' }}
            rows={3}
            placeholder="Enter Comment........"
          />
            <button onClick={updateApplicationStatus} type="button" className="ml-2 mt-3 px-3 py-2 rounded-md bg-indigo-500 text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700">
              UPDATE
            </button>
          </>}
        </div>
      </div>
    </div>

  )
}

export default SingleJobApplication