import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import DataTable from "../common/DataTable";
import { getAdminUrl } from "../../../ServiceConfig";
import ComponentHeader from "../common/ComponentHeader";
import H2 from "../../../components/headings/H2";
import InputLabel from "../inputs/InputLabel";
import DateRangeInput from "../inputs/DateRangeInput";
import SelectSearch from "../inputs/SelectSearch";
import { getAllInternships } from "../../services/InternshipServices";

function InternshipApplications() {
  const navigate = useNavigate();
  const internshipTable = useRef();
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const statusArray = ["Pending", "Selected", "Rejected"]
  const [roles, setRoles] = useState([])

  useEffect(() => {
    getAllInternships()
    .then((res) => {
      setRoles(res.data.data.data.map((oneint) => ({label: oneint.title, value: oneint.id})));
    })
    .catch((err) => {
      console.log(err);
    });
  }, [])

  useEffect(() => {
    internshipTable.current.setFilter(selectedFilter);
  }, [selectedFilter]);

  // const deleteSelectedLocation = (id) => {
  //   deleteLocation(id)
  //     .then((res) => {
  //       toast.success(res.data.message);
  //       internshipTable.current.deleteRow(id);
  //     })
  //     .catch((err) => {
  //       toast.error(err.response.data.message);
  //     });
  // };

  
  const updateFilter = (filter) => {
    let index = selectedFilter.findIndex((sf) => sf.field === filter.field);

    if (index >= 0) {
      if (filter.value && !Array.isArray(filter.value)) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else if (Array.isArray(filter.value) && filter.value[0]) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else {
        setSelectedFilter(
          selectedFilter.filter((af) => af.field !== filter.field)
        );
      }
    } else {
      setSelectedFilter([...selectedFilter, filter]);
    }
  };

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
  <div class="flex items-center gap-3">
      <div class="internship-edit-btn cursor-pointer" data-id=${
        cell.getRow().getData().id
      }>
          <i class="fas fa-edit"></i>
      </div>
      <div class="internship-delete-btn cursor-pointer text-red-500" data-id=${
        cell.getRow().getData().id
      }>
        <i class="fas fa-trash-alt"></i>
      </div>
  </div>
  `;
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "First Name",
      field: "first_name",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Last Name",
      minWidth: "100",
      field: "last_name",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Email",
      field: "email",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Phone",
      field: "phone",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Applied For",
      hozAlign: "center",
      vertAlign: "middle",
      headerSort: false,
      field: "internship.title",
    },
    {
      title: "Status",
      hozAlign: "center",
      vertAlign: "middle",
      headerSort: false,
      field: "status",
      formatter: function (cell) {
        return `${statusArray[cell.getData().status]}`;
      },
    },
    {
      title: "Message",
      field: "message",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return `<div  class="applicant-message" data-message=${JSON.stringify(
          cell.getRow().getData().message
        )}>${cell.getRow().getData().message}
        </div>`;
      },
    },
    // {
    //   formatter: actionIcons,
    //   headerSort: false,
    //   width: 80,
    //   responsive: 0,
    //   hozAlign: "center",
    //   vertAlign: "middle",
    // },
  ];

  useEffect(() => {
    $(document).on("click", ".internship-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/jobs/edit/${cell}`);
    });

    // $(document).on("click", ".internship-delete-btn", function (e) {
    //   e.stopPropagation();
    //   e.preventDefault();
    //   let dataId = $(this).attr("data-id");
    //   deleteSelectedLocation(dataId);
    // });

    return () => {
      $(document).off("click", ".internship-edit-btn");
      // $(document).off("click", ".internship-delete-btn");
    };
  }, []);

  
  const onRowClick = (data) => {
    window.open(`/internships/applications/${data.id}`, "_blank");
  };

  
  return (
    <div className="flex h-full flex-col">
      <ComponentHeader className="border-b">
        <H2>Intership Applications</H2>
      </ComponentHeader>
      <div className="relative h-full">
        <div className="my-8 rounded-md">
          <button
            onClick={() => setFilterOpen(!filterOpen)}
            className={` inline-block whitespace-nowrap rounded-md border dark:bg-slate-700 bg-white p-8 py-2 text-lg font-semibold tracking-wide ${filterOpen
              ? "border-b-1 -mb-[1px] rounded-b-none px-8 pb-1 pt-2"
              : ""
              }`}
          >
            Filters
          </button>
          <div
            className={`flex grow flex-wrap dark:bg-slate-700 dark:text-white  items-center gap-4 rounded-md rounded-tl-none bg-white ${filterOpen ? "mb-4 border p-4" : "h-0 overflow-hidden p-0"
              }`}
          >
            <div className="min-w-[250px]">
              <InputLabel label="Applied Date" />
              <DateRangeInput
                controlBtns={true}
                horizontalPosition="left"
                onRangeChange={(startDate, endDate) =>
                  updateFilter({
                    field: "created_at",
                    type: "in",
                    value: [
                      startDate
                        ? `${new Date(startDate).getFullYear()}-${new Date(startDate).getMonth() + 1
                        }-${new Date(startDate).getDate()}`
                        : "",
                      endDate
                        ? `${new Date(endDate).getFullYear()}-${new Date(endDate).getMonth() + 1
                        }-${new Date(endDate).getDate()}`
                        : "",
                    ],
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Job Profile" />
              <SelectSearch
                value={
                  selectedFilter.find(
                    (filter) => filter.field === "internship_id"
                  )?.value
                }
                options={roles}
                onChange={(value) =>
                  updateFilter({
                    field: "internship_id",
                    type: "=",
                    value: value,
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Application Status"  />
              <SelectSearch
                value={
                  selectedFilter.find(
                    (filter) => filter.field === "status"
                  )?.value
                }
                options={statusArray?.map((onestatus, index) => ({
                  label: `${onestatus}`,
                  value: index,
                }))}
                onChange={(value) =>
                  updateFilter({
                    field: "status",
                    type: "=",
                    value: value
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="First Name"  />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "first_name",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="Last Name" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "last_name",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="Phone" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "phone",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="Email" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "email",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <DataTable
          ref={internshipTable}
          ajaxUrl={`${getAdminUrl()}/internship-application`}
          columns={columns}
          search={false}
          responsiveLayout="collapse"
          rowClick={onRowClick}
        />
      </div>
    </div>
  );
}

export default InternshipApplications;
