import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import MetaTags from "../../../web/layouts/MetaTags";
import { getCategoryById } from "../../services/CategoryServices";
import CategoryForm from "./CategoryForm";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";

function EditCategory() {
  const navigate = useNavigate();

  const categoryId = useParams().id;

  const [category, setCategory] = useState();

  const onEditCategory = (data) => {
    navigate(`/category`);
  };

  useEffect(() => {
    categoryId &&
      getCategoryById(categoryId)
        .then((res) => {
          setCategory(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Edit Category | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Category</H2>
        <Link
          to="/category"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {category && (
        <CategoryForm
          formData={category}
          edit={true}
          onSuccess={(data) => onEditCategory(data)}
        />
      )}
    </div>
  );
}

export default EditCategory;
