import { Link } from "react-router-dom";
import H2 from "../../../components/headings/H2";
import ComponentHeader from "../../components/common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import ConditionFormWrapper from "./conditionForm/ConditionFormWrapper";

function AddCondition() {
  return (
    <div>
      <ComponentHeader className="border-b">
        <H2>Add New Condition</H2>
        <Link
          to="/lead-conditions"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      <div>
        <ConditionFormWrapper />
      </div>
    </div>
  );
}

export default AddCondition;
