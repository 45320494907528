import React, { useEffect, useState } from "react";
import FileInput from "../../../components/inputFields/FileInput";
import { getAllStateByCountry } from "../../services/StateServices";
import { toast } from "react-toastify";
import { getAllCountriesOption } from "../../services/CountryServices";
import InputLabel from "../inputs/InputLabel";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import SelectSearch from "../inputs/SelectSearch";
import MultiSelect from "../inputs/MultiSelect";
import {
  getAllLocationByCountryId,
  getLocationsByStateId,
} from "../../services/LocationServices";
import {
  addNewStay,
  deleteStayRoomImages,
  getAllStayAmeneties,
  getAllStayCategories,
  getAllStayFeatures,
  updateStay,
  uploadStayImage,
} from "../../services/StayServices";
import { getUploadsUrl } from "../../../ServiceConfig";
import { FaPlus } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import ImageInput from "../inputs/ImageInput";
import Modal from "react-modal";
import LocationForm from "../locations/LocationForm";
import AmenitiesForm from "../amenities/AmenitiesForm";
import FeaturesForm from "../features/FeaturesForm";
import StayCategoryForm from "../stayCategory/StayCategoryForm";

const stayFormSchema = Yup.object().shape({
  country_id: Yup.string().required("Required"),
  location_id: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  stay_category_id: Yup.string().required("Required"),
  star_category: Yup.string().required("Required"),
  features: Yup.array().min(1, "required").required("Required"),
  room_types: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Required"),
      no_of_rooms: Yup.string().required("Required"),
    })
  ),
});

function StayForm({ formData, edit, onSuccess }) {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [locations, setLocations] = useState([]);
  const [stayCategories, setStayCategories] = useState([]);
  const [stayAmenities, setStayAmenities] = useState([]);
  const [stayFeatures, setStayFeatures] = useState([]);
  const [addlocationModal, setAddlocationModal] = useState(false);
  const [addAmenitiesModal, setAddAmenitiesModal] = useState(false)
  const [addFeaturesModal, setAddFeaturesModal] = useState(false)
  const [addCategoryModal, setAddCategoryModal] = useState(false)

  useEffect(() => {
    getAllCountriesOption()
      .then((res) => {
        setCountries(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    if (edit && formData.state_id) {
      getStatesByCountry(formData.country_id);
      getLocationByState(formData.state_id);
    } else {
      getLocationsByCountry(formData?.country_id);
    }

    getAllStayCategories().then((res) => {
      setStayCategories(res.data.data.data);
    });

    getAllStayAmeneties().then((res) => {
      setStayAmenities(res.data.data.data);
    });

    getAllStayFeatures().then((res) => {
      setStayFeatures(res.data.data.data);
    });
  }, []);

  const getStatesByCountry = (value) => {
    setLocations([]);
    getAllStateByCountry(value).then((res) => {
      setStates(res.data.data);
    });
  };

  const getLocationsByCountry = (value) => {
    getAllLocationByCountryId(value).then((res) => {
      setLocations(res.data.data);
    });
  };

  const getLocationByState = (value) => {
    getLocationsByStateId(value).then((res) => {
      setLocations(res.data.data);
    });
  };

  const addStay = (values) => {
    addNewStay(values)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const editStay = (values) => {
    values.id = formData.id;
    updateStay(values)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const deleteImage = (data) => {
    deleteStayRoomImages(data.id).then((res) => {
    });
  };

  const onAddLocation = (data) => {
    setAddlocationModal(false);
    setLocations([...locations, data]);
  };

  const onAddAmenities = (data) => {
    setAddAmenitiesModal(false)
    setStayAmenities([...stayAmenities, data])
  }

  const onAddFeatures = (data) => {
    setAddFeaturesModal(false)
    setStayFeatures([...stayFeatures, data])
  }

  const onAddCategory = (data) => {
    setAddCategoryModal(false)
    setStayCategories([...stayCategories, data])
  }

  return (
    <>
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            image: formData?.image ? formData?.image : "",
            country_id: formData?.country_id ? formData?.country_id : "",
            state_id: formData?.state_id ? formData?.state_id : "",
            location_id: formData?.location_id ? formData?.location_id : "",
            name: formData?.name ? formData?.name : "",
            stay_category_id: formData?.stay_category_id
              ? formData?.stay_category_id
              : "",
            address: formData?.address ? formData?.address : "",
            url: formData?.url ? formData?.url : "",
            star_category: formData?.star_category
              ? formData?.star_category
              : "",
            hotel_ameneties: formData?.hotel_ameneties
              ? formData?.hotel_ameneties
              : [],
            features: formData?.features ? formData?.features : [],
            room_types: formData?.room_type
              ? formData?.room_type
              : [{ images: [] }],
          }}
          validationSchema={stayFormSchema}
          onSubmit={(values) => {
            edit ? editStay(values) : addStay(values);
          }}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <div className="relative">
                {/* //? Banner Image */}
                <div className="grow">
                  <FileInput
                    value={`${getUploadsUrl()}/stays/${values.image}`}
                    noOfFilesAllowed={1}
                    onChange={(file) => {
                      uploadStayImage({ image: file }).then((res) => {
                        setFieldValue("image", res.data.data[0].uploaded_image);
                      });
                    }}
                  />
                </div>

                <div className="grow">
                  <div className="my-4 flex flex-wrap gap-4 lg:flex-nowrap">
                    <div className="lg:min-w-[25%] min-w-[100%] grow">
                      <InputLabel label="Select Country" required={true} />
                      <SelectSearch
                        options={countries?.map((country) => ({
                          value: country.id,
                          label: country.name,
                        }))}
                        value={values.country_id}
                        onChange={(value) => {
                          setFieldValue("country_id", value);
                          value === 101
                            ? getStatesByCountry(value)
                            : getLocationsByCountry(value);
                        }}
                      />
                      <ErrorMessage name="country_id">
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    {/* //? Select State */}
                    <div className="lg:min-w-[25%] min-w-[100%] grow">
                      <InputLabel label="Select State" />
                      <SelectSearch
                        options={states?.map((state) => ({
                          value: state.id,
                          label: state.name,
                        }))}
                        disabled={values.country_id !== 101}
                        value={values.state_id}
                        onChange={(value) => {
                          setFieldValue("state_id", value);
                          getLocationByState(value);
                        }}
                      />
                      <ErrorMessage name="state_id">
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    {/* //? Select Location */}
                    <div className="min-w-[25%] grow">
                      <InputLabel label="Select Location" required={true} />
                      <div className="flex gap-[2px]">
                        <div className="grow">
                          <SelectSearch
                            options={locations?.map((location) => ({
                              value: location.id,
                              label: location.name,
                            }))}
                            value={values.location_id}
                            onChange={(value) =>
                              setFieldValue("location_id", value)
                            }
                          />
                        </div>
                        <button
                          className="rounded-lg border border-green-300 p-2 text-green-500"
                          onClick={() => {
                            setAddlocationModal(true);
                          }}
                          type="button"
                        >
                          <FaPlus />
                        </button>
                      </div>
                      <ErrorMessage name="location_id">
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                  </div>

                  <div className="">
                    <InputLabel label="Name" required={true} />
                    <Field className="field" name="name" />
                    <ErrorMessage name="name">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  {/* //? Stay Category */}
                  <div className="my-4 flex flex-wrap gap-4 lg:flex-nowrap">
                    <div className="grow">
                      <InputLabel
                        label="Select Stay Category"
                        required={true}
                      />
                      <div className="flex items-center gap-2 w-full">
                        <div className="w-full">
                        <SelectSearch
                          options={stayCategories?.map((category) => ({
                            value: category.id,
                            label: category.name,
                          }))}
                          value={values.stay_category_id}
                          onChange={(value) =>
                            setFieldValue("stay_category_id", value)
                          }
                        />
                        </div>
                        <button
                          className="rounded-lg border border-green-300 px-2 py-3 text-green-500"
                          onClick={() => {
                            setAddCategoryModal(true);
                          }}
                          type="button"
                        >
                          <FaPlus />
                        </button>
                      </div>
                      <ErrorMessage name="stay_category_id">
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="grow">
                      <InputLabel
                        label="Select Star Category"
                        required={true}
                      />
                      <SelectSearch
                        options={[
                          { value: 2, label: "2 Star" },
                          { value: 3, label: "3 Star" },
                          { value: 4, label: "4 Star" },
                          { value: 5, label: "5 Star" },
                          { value: 6, label: "6 Star" },
                          { value: 7, label: "7 Star" },
                        ]}
                        value={values.star_category}
                        onChange={(value) =>
                          setFieldValue("star_category", value)
                        }
                      />
                      <ErrorMessage name="star_category">
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                  </div>

                  <div className="my-4 flex flex-wrap gap-4 lg:flex-nowrap">
                    <div className="lg:max-w-[calc(50%-10px)] max-w-[100%] grow">
                      <InputLabel label="Stay Ameneties" required={true} />
                      <div className="flex gap-1">
                        <MultiSelect
                          options={stayAmenities?.map((amenety) => ({
                            label: (
                              <div className="flex items-center gap-3">
                                <i className={amenety.icon}></i> {amenety?.name}
                              </div>
                            ),
                            value: amenety?.id,
                          }))}
                          value={values.hotel_ameneties}
                          onChange={(v) => setFieldValue("hotel_ameneties", v)}
                        />
                        <button
                          className="rounded-lg border border-green-300 p-2 text-green-500"
                          onClick={() => {
                            setAddAmenitiesModal(true);
                          }}
                          type="button"
                        >
                          <FaPlus />
                        </button>
                      </div>

                      <ErrorMessage name="hotel_ameneties">
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="lg:max-w-[calc(50%-10px)] max-w-[100%] grow">
                      <InputLabel label="Stay Features" required={true} />
                      <div className="flex gap-1">
                        <MultiSelect
                          options={stayFeatures?.map((feature) => ({
                            label: feature?.name,
                            value: feature?.id,
                          }))}
                          value={values.features}
                          onChange={(v) => setFieldValue("features", v)}
                        />
                        <button
                          className="rounded-lg border border-green-300 p-2 text-green-500"
                          onClick={() => {
                            setAddFeaturesModal(true);
                          }}
                          type="button"
                        >
                          <FaPlus />
                        </button>
                      </div>

                      <ErrorMessage name="features">
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                  </div>

                  <div className="my-4">
                    <InputLabel label="Address" />
                    <Field as="textarea" className="field" name="address" />
                    <ErrorMessage name="address">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="my-4">
                    <InputLabel label="URL" />
                    <Field className="field" name="url" />
                    <ErrorMessage name="url">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="my-4">
                    <InputLabel label="Add Room Types" />
                    <FieldArray
                      name="room_types"
                      render={(arrayHelpers) => (
                        <div className="mt-2">
                          {values.room_types.map((type, index) => (
                            <div
                              key={index}
                              className="relative my-2 mb-4 rounded-lg bg-gray-100 p-4"
                            >
                              <div className="flex flex-wrap gap-4 ">
                                <div className="grow">
                                  <InputLabel label="Type" required={true} />
                                  <Field
                                    name={`room_types.${index}.name`}
                                    className="field !pr-8"
                                  />
                                  <ErrorMessage
                                    name={`room_types.${index}.name`}
                                  >
                                    {(msg) => (
                                      <div className="error-msg">{msg}</div>
                                    )}
                                  </ErrorMessage>
                                </div>
                                <div className="grow">
                                  <InputLabel label="Number Of Rooms" />
                                  <Field
                                    name={`room_types.${index}.no_of_rooms`}
                                    className="field !pr-8"
                                  />
                                  <ErrorMessage
                                    name={`room_types.${index}.no_of_rooms`}
                                  >
                                    {(msg) => (
                                      <div className="error-msg">{msg}</div>
                                    )}
                                  </ErrorMessage>
                                </div>
                              </div>
                              <div className="mt-4">
                                <InputLabel label="Upload Images" />
                                <ImageInput
                                  allowedFiles={8}
                                  path="stay/rooms"
                                  images={values.room_types[index].images}
                                  setImages={(value) =>
                                    setFieldValue(
                                      `room_types.${index}.images`,
                                      value
                                    )
                                  }
                                  onDelete={(image) => deleteImage(image)}
                                />
                              </div>
                              <div></div>
                              <button
                                type="button"
                                className="absolute right-2 top-2"
                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                              >
                                <MdClose />
                              </button>
                            </div>
                          ))}
                          <button
                            className="text-primary"
                            type="button"
                            onClick={() => arrayHelpers.push({ images: [] })}
                          >
                            <FaPlus className="inline-block" /> Room
                          </button>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="text-center">
                <button type="submit" className="btn-primary">
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      {/* Location Modal */}
      <Modal
        isOpen={addlocationModal}
        contentLabel="add location modal"
        className="mx-auto mt-[2%] max-h-[90%] max-w-[75%] overflow-auto rounded-md bg-white p-4"
        overlayClassName="fixed top-0 left-0 w-full h-full !z-50 !bg-black/50"
      >
        <div className="flex items-center justify-between border-b p-4">
          <h2 className="text-xl font-bold">Add Location</h2>
          <button
            onClick={() => setAddlocationModal(false)}
            className="cursor-pointer rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
        </div>

        <div className="mt-2">
          <LocationForm onSuccess={(data) => onAddLocation(data)} />
        </div>
      </Modal>

      {/* Ameniteies Modal */}
      <Modal
        isOpen={addAmenitiesModal}
        contentLabel="add amenities modal"
        className="mx-auto mt-[2%] max-h-[90%] max-w-[75%] overflow-auto rounded-md bg-white p-4"
        overlayClassName="fixed top-0 left-0 w-full h-full !z-50 !bg-black/50"
      >
        <div className="flex items-center justify-between border-b p-4">
          <h2 className="text-xl font-bold">Add Amenities</h2>
          <button
            onClick={() => setAddAmenitiesModal(false)}
            className="cursor-pointer rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
        </div>

        <div className="mt-2">
          <AmenitiesForm onSuccess={(data) => onAddAmenities(data)} />
        </div>
      </Modal>


      {/* Features Modal */}
      <Modal
        isOpen={addFeaturesModal}
        contentLabel="add features modal"
        className="mx-auto mt-[2%] max-h-[90%] max-w-[75%] overflow-auto rounded-md bg-white p-4"
        overlayClassName="fixed top-0 left-0 w-full h-full !z-50 !bg-black/50"
      >
        <div className="flex items-center justify-between border-b p-4">
          <h2 className="text-xl font-bold">Add Features</h2>
          <button
            onClick={() => setAddFeaturesModal(false)}
            className="cursor-pointer rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
        </div>

        <div className="mt-2">
          <FeaturesForm onSuccess={(data) => onAddFeatures(data)} />
        </div>
      </Modal>

      {/* Category Modal */}
      <Modal
        isOpen={addCategoryModal}
        contentLabel="add category modal"
        className="mx-auto mt-[2%] max-h-[30%] max-w-[25%] overflow-auto rounded-md bg-white p-4"
        overlayClassName="fixed top-0 left-0 w-full h-full !z-50 !bg-black/50"
      >
        <div className="flex items-center justify-between border-b p-4">
          <h2 className="text-xl font-bold">Add Category</h2>
          <button
            onClick={() => setAddCategoryModal(false)}
            className="cursor-pointer rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
        </div>
        <div className="mt-2">
          <StayCategoryForm onSuccess={(data) => onAddCategory(data)} />
        </div>
      </Modal>
    </>
  );
}

export default StayForm;
