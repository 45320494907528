import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import DataTable from "../common/DataTable";
import { deleteState } from "../../services/StateServices";
import { getAdminUrl, getUploadsUrl } from "../../../ServiceConfig";
import ComponentHeader from "../common/ComponentHeader";
import H2 from "../../../components/headings/H2";
import { FaPlusSquare } from "react-icons/fa";
import { deleteTransferById } from "../../services/TransferServices";

function TransferList() {
  const navigate = useNavigate();
  const transferTable = useRef();

  const deleteSelectedTransfer = (id) => {
    if (!window.confirm("Do You really want to delete this transfer?")) return;
    deleteTransferById(id)
      .then((res) => {
        toast.success(res.data.message);
        transferTable.current.deleteRow(id);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
    <div class="flex items-center gap-3">
      <div class="transfer-edit-btn cursor-pointer" data-id=${
        cell.getRow().getData().id
      }>
          <i class="fas fa-edit"></i>
      </div>
      <div class="transfer-delete-btn cursor-pointer text-red-500" data-id=${
        cell.getRow().getData().id
      }>
        <i class="fas fa-trash-alt"></i>
      </div>
  </div>`;
  };

  const printStar = (cell, formatterParams) => {
    let html = `<div class="flex items-center text-yellow-500 text-sm">`;
    cell.getData();
    for (let i = 0; i < cell.getData().star_category; i++) {
      html += `<i class="fas fa-star"></i>`;
    }

    html += `</div>`;
    return html;
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Type",
      field: "type",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Seats",
      minWidth: "100",
      field: "seats",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Icon",
      hozAlign: "center",
      vertAlign: "middle",
      headerSort: false,
      field: "icon",
      formatter: "image",
      formatterParams: {
        height: "50px",
        width: "80px",
        urlPrefix: `${getUploadsUrl()}/transfers/`,
        urlSuffix: "",
      },
    },

    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  useEffect(() => {
    $(document).on("click", ".transfer-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/transfers/edit/${cell}`);
    });

    $(document).on("click", ".transfer-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      deleteSelectedTransfer(dataId);
    });

    return () => {
      $(document).off("click", ".transfer-edit-btn");
      $(document).off("click", ".transfer-delete-btn");
    };
  }, []);

  return (
    <div className="flex h-full flex-col">
      <ComponentHeader className="border-b">
        <H2>Transfers</H2>
        <Link
          to="/transfers/add"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaPlusSquare /> Add New
        </Link>
      </ComponentHeader>
      <div className="h-full">
        <DataTable
          ref={transferTable}
          ajaxUrl={`${getAdminUrl()}/transfer`}
          columns={columns}
          search={true}
          responsiveLayout="collapse"
        />
      </div>
    </div>
  );
}

export default TransferList;
