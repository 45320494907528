import { admin } from "../../ServiceConfig";

export function addPageContact(data) {
  return admin.post(`/page-contact`, data);
}

export function updatePageContact(data) {
  return admin.put(`/page-contact/${data.id}`, data);
}

export function getAllPageContacts(data) {
  return admin.get(`/page-contact`, data);
}

export function getPageContactById(id) {
  return admin.get(`/page-contact/${id}`);
}

export function deletePageContact(id) {
  return admin.delete(`/page-contact/${id}`);
}
