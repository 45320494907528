import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const SideBarContext = createContext();

export const SideBarProvider = ({ children }) => {
  const location = useLocation();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [forceOpenSideBar, setForceOpenSideBar] = useState(false);

  const toggleSidebar = (status) => {
    if (sidebarOpen) {
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
    }
  };

  const lockSidebar = (status) => {
    setForceOpenSideBar(status);
    setSidebarOpen(status);
  };

  useEffect(() => {
    if (window.innerWidth < 620) {
      setSidebarOpen(false);
    }
  }, [location]);

  return (
    <SideBarContext.Provider
      value={{
        sidebarOpen,
        setSidebarOpen,
        toggleSidebar,
        lockSidebar,
        forceOpenSideBar,
      }}
    >
      {children}
    </SideBarContext.Provider>
  );
};

export default SideBarContext;
