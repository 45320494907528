import { admin } from "../../ServiceConfig";

export function uploadGiftCardPreFilledImage(file) {
  return admin.post(`/uploads/image?upload_path=gift-cards/prefilled`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function uploadGiftCardBlankImage(file) {
  return admin.post(`/uploads/image?upload_path=gift-cards/blank`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function addGiftCardCategory(value) {
  return admin.post(`/gift-card/category`, value);
}

export function addGiftCardTheme(value) {
  return admin.post(`/store/category-theme`, value);
}

export function updateGiftCardTheme(value) {
  return admin.put(`/update/category-theme/${value.id}`, value);
}

export function getGiftCardCategories() {
  return admin.get(`/gift-card/category`);
}

export function deleteGiftCard(id) {
  return admin.delete(`/delete/category-theme/${id}`);
}

export function getGiftCardThemeById(id) {
  return admin.get(`/category-theme/${id}`);
}

export function saveGiftCardCategory(id) {
  return admin.post(`/gift-card/category/${id}`);
}
