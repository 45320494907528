import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import MetaTags from "../../../web/layouts/MetaTags";
import { getPermissionById } from "../../services/PermissionServices";
import PermissionForm from "./PermissionForm";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";

function EditPermission() {
  const navigate = useNavigate();

  const permissionId = useParams().id;

  const [permission, setPermission] = useState();

  const onEditPermission = (data) => {
    navigate(`/permissions`);
  };

  useEffect(() => {
    permissionId &&
      getPermissionById(permissionId)
        .then((res) => {
          setPermission(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Edit Permission | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Permission</H2>
        <Link
          to="/permissions"
          className="btn-primary flex items-center gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {permission && (
        <PermissionForm
          formData={permission}
          edit={true}
          onSuccess={(data) => onEditPermission(data)}
        />
      )}
    </div>
  );
}

export default EditPermission;
