import { useEffect } from "react";
import ComponentHeader from "../components/common/ComponentHeader";
import H2 from "../../components/headings/H2";
import { useParams } from "react-router-dom";

import EmployeeForm from "../employees/EmployeeForm";
import { useState } from "react";
import { changePassword, getEmployeeById } from "../services/EmployeeServices";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import PasswordInput from "../../components/inputFields/PasswordInput";

const passwordValidation = Yup.object().shape({
  old_password: Yup.string().required("Required"),
  password: Yup.string().required("Password is required"),
  confirm_password: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

function Profile() {
  const employeeId = useParams().id;
  const [employeeData, setEmployeeData] = useState();
  const [activeTab, setActiveTab] = useState("profile");

  useEffect(() => {
    employeeId &&
      getEmployeeById(employeeId)
        .then((res) => {
          setEmployeeData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  const updatePassword = (values) => {
    changePassword(values)
      .then((res) => {
        console.log(res, "resssssss========");
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div>
      {/* <ComponentHeader className="mb-4 border-b">
        <H2 className="tracking-wide">Profile</H2>
      </ComponentHeader> */}
      <div className="mb-4 flex items-center gap-4 border-b">
        <button
          onClick={() => setActiveTab("profile")}
          className={`p-4 uppercase ${
            activeTab === "profile" ? "border-b border-jw-green font-bold" : ""
          }`}
        >
          Profile
        </button>
        <button
          onClick={() => setActiveTab("password")}
          className={`p-4 uppercase ${
            activeTab === "password" ? "border-b border-jw-green font-bold" : ""
          }`}
        >
          Password
        </button>
      </div>
      {employeeData && activeTab === "profile" && (
        <EmployeeForm
          formData={employeeData}
          edit={true}
          profile={true}
          // onSuccess={(data) => onEditState(data)}
        />
      )}
      {activeTab === "password" && (
        <div className="">
          <div className="p-4 ">
            <h2 className="text-lg font-bold">Change Password</h2>
            <Formik
              initialValues={{
                old_password: "",
                password: "",
                confirm_password: "",
              }}
              validationSchema={passwordValidation}
              onSubmit={(values) => {
                updatePassword(values);
              }}
            >
              {({ values, setFieldValue, errors, touched }) => (
                <Form>
                  <div className="mt-4 flex flex-wrap gap-4">
                    <div className="grow">
                      <PasswordInput
                        id="old_password"
                        placeholder="Current Password"
                        name="old_password"
                        value={values.old_password}
                        onChange={(e) =>
                          setFieldValue("old_password", e.target.value)
                        }
                      />
                      <ErrorMessage name="old_password">
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="grow">
                      <PasswordInput
                        id="password"
                        placeholder="New Password"
                        value={values.password}
                        onChange={(e) =>
                          setFieldValue("password", e.target.value)
                        }
                      />
                      <ErrorMessage name="password">
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="grow">
                      <PasswordInput
                        id="confirm_password"
                        placeholder="Confirm New Password"
                        value={values.confirm_password}
                        onChange={(e) =>
                          setFieldValue("confirm_password", e.target.value)
                        }
                      />
                      <ErrorMessage name="confirm_password">
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="text-center">
                      <button
                        className="btn-primary"
                        disabled={
                          !values.old_password ||
                          !values.password ||
                          !values.confirm_password ||
                          values.password !== values.confirm_password
                        }
                      >
                        Change Password
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;
