import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getInternshipApplicationById, updateInternshipApplicationStatus } from '../../services/InternshipServices'
import StatusDropdown from '../job/StatusDropdown'
import SingleInternshipApplicationCard from './SingleInternshipApplicationCard'

const SingleInternshipApplication = () => {
  const [applicantData, setApplicantData] = useState()
  const params = useParams()

  const getSingleUserApplicationData = () => {
    getInternshipApplicationById(params.id).then(res => { setApplicantData(res.data.data) }).catch(e => { console.log(e) })
  }
  useEffect(() => {
    getSingleUserApplicationData()
  }, [])

  // 0 = Pending, 1 = Selected, 2 = Rejected
  const statusArray = ["Pending", "Selected", "Rejected"]

  const [selectedStatus, setSelectedStatus] = useState('');

  const handleStatusSelect = (status) => {
    setSelectedStatus(status);
  };

  const updateApplicationStatus = () => {
    updateInternshipApplicationStatus(applicantData?.id, { status: statusArray.indexOf(selectedStatus) }).then(res => {
      if (res.data.status === 'success') {
        toast.success(res.data.message)
        getSingleUserApplicationData()
        setSelectedStatus('')
      }
    }).catch(e => {
      toast.error("Error in Updating Internship Status")
      console.log(e)
    })

  }
  return (
    <div className='flex flex-col gap-4 items-center'>
      <SingleInternshipApplicationCard applicantData={{ application: applicantData }} statusArray={statusArray} />
      <div className="bg-white shadow-md rounded-lg overflow-hidden lg:w-1/2 w-full p-6">
        <h2 className="text-xl font-semibold mb-2">Update Application Status</h2>
        <p className="text-gray-600 mb-4"> {statusArray[applicantData?.status]}</p>
        <div className="container mx-auto px-2 py-4">
          <StatusDropdown statusArray={statusArray} onSelect={handleStatusSelect} />
          {selectedStatus && <div className='w-full flex'>
            <button onClick={updateApplicationStatus} type="button" className="btn-primary mx-auto my-4">
              UPDATE
            </button>
          </div>}
        </div>
      </div>
    </div>

  )
}

export default SingleInternshipApplication