import { Link } from "react-router-dom";
import H2 from "../../../components/headings/H2";
import ComponentHeader from "../common/ComponentHeader";
import ListingSeoForm from "./ListingSeoForm";
import { FaListAlt } from "react-icons/fa";

function AddListingSeo() {
  return (
    <div>
      <ComponentHeader className="mb-4 border-b">
        <H2>Add Listing SEO</H2>
        <Link
          to="/listing-seo"
          className="btn-primary dark:bg-green-800 flex items-center gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      <ListingSeoForm />
    </div>
  );
}

export default AddListingSeo;
