import { admin } from "../../ServiceConfig";

export function getAnnualSaleSeriesData() {
  return admin.get("/number-of-booking-by-month-fy-wise");
}

export function getMonthlySalesLeaderboard() {
  return admin.get("/monthly-leaderboard");
}
export function getMonthlySalesLeaderboardLastFY() {
  return admin.get("/monthly-leaderboard-last-fy");
}

export function getMonthlySalesPaxLeaderboard() {
  return admin.get("/pax/monthly-leaderboard");
}
export function getMonthlySalesPaxLeaderboardLastFY() {
  return admin.get("/pax/monthly-leaderboard-last-fy");
}

export function getSalesLeaderboardByDate(startDate, endDate) {
  return admin.get(
    `/sales-leaderboard-by-date?startdate=${startDate}&lastdate=${endDate}`
  );


}
export function getAdminWidgets() {
  return admin.get('/get-widgets');
}

export function getWeekDepartures(){
  return admin.get(`/this-week`)
}

export function getCurrentlyHiring(){
  return admin.get(`/currently-hiring`)
}

export function getTodayBirthDay(){
  return admin.get(`/today-birthdays`)
}