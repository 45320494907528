import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import MetaTags from "../../../web/layouts/MetaTags";
import { getRiderById } from "../../services/RiderServices";
import RiderForm from "./RiderForm";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";

function EditRider() {
  const navigate = useNavigate();

  const riderId = useParams().id;

  const [rider, setRider] = useState();

  const onEditRider = (data) => {
    navigate(`/rider`);
  };

  useEffect(() => {
    riderId &&
      getRiderById(riderId)
        .then((res) => {
          setRider(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Edit Rider | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Travel Mode</H2>
        <Link
          to="/travel-modes"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {rider && (
        <RiderForm
          formData={rider}
          edit={true}
          onSuccess={(data) => onEditRider(data)}
        />
      )}
    </div>
  );
}

export default EditRider;
