import { Outlet } from "react-router-dom";

function PermissionWrapper() {
  return (
    <div className="wrapper">
      {/* <MetaTags title="All States | Admin Panel" /> */}
      <div className="h-full max-h-[calc(100%-1rem)] min-h-[50vh] overflow-auto"> 
        {<Outlet />}
      </div>
    </div>
  );
}

export default PermissionWrapper;
