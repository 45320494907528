import { Link, useNavigate } from "react-router-dom";
import ComponentHeader from "../common/ComponentHeader";
import H2 from "../../../components/headings/H2";
import { FaListAlt } from "react-icons/fa";
import StayForm from "./StayForm";

function AddStay() {
  const navigate = useNavigate();

  const onAddStay = (data) => {
    navigate(`/stays`);
  };

  return (
    <div className="">
      {/* <MetaTags title="Add State | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Add Stay</H2>
        <Link
          to="/stays"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>

      <StayForm onSuccess={(data) => onAddStay(data)} />
    </div>
  );
}

export default AddStay;
