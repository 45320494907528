import { ErrorMessage, FieldArray, Field, Form, Formik } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { FaChevronDown, FaRoute } from "react-icons/fa";
import * as Yup from "yup";
import { getAllLocationByCountryId } from "../../../services/LocationServices";
import InputLabel from "../../inputs/InputLabel";
import EditorInput from "../../inputs/EditorInput";
import usePackageData from "../../../hooks/usePackageData";
import {
  createItinerary,
  getAllItinerariesByPackageId,
  updateItinerary,
} from "../../../services/PackageService";
import SelectSearch from "../../inputs/SelectSearch";

const packageItinerarySchema = Yup.object().shape({
  itineraries: Yup.array()
    .of(
      Yup.object().shape({
        starting_location_id: Yup.string().required("Required"),
        ending_location_id: Yup.string().required("Required"),
        // reporting_place: Yup.string().required("Required"),
        inclusions: Yup.string().required("Required"),
        exclusions: Yup.string().required("Required"),
        departure_time: Yup.string().required("Required"),
        arrival_time: Yup.string().required("Required"),
      })
    )
    .required("Required"),
});

function Step3({ onSuccess }) {
  const { packageData } = usePackageData();

  const [packageItineraries, setPackageItineraries] = useState();
  const [locations, setLocations] = useState();
  const [activeItinerary, setActiveItinerary] = useState();

  const editItinerary = (values) => {
    if (values?.id) {
      updateItinerary(values.id, values).then((res) => {
        console.log(res.data.data);
      });
    }
  };

  const addItinerary = (values) => {
    values.itineraries.forEach((itinerary) => {
      itinerary.package_id = packageData.package?.id;
    });

    createItinerary(values)
      .then((res) => {
        setPackageItineraries((packageItineraries) => [
          ...packageItineraries,
          res.data.data,
        ]);
        onSuccess();
      })
      .catch((err) => {
        console.log(err, "erererre-------");
      });
  };

  useEffect(() => {
    getAllLocationByCountryId(packageData.package?.country_id)
      .then((res) => {
        setLocations(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    getAllItinerariesByPackageId(packageData.package.id)
      .then((res) => {
        setPackageItineraries(res.data.data);
        if (res.data.data.length === 0) {
          setActiveItinerary(0);
          setPackageItineraries([
            {
              starting_location_id: "",
              ending_location_id: "",
              reporting_place: "",
              inclusions: "",
              exclusions: "",
              departure_time: "",
              arrival_time: "",
              departure_option: 0,
              arrival_option: 0,
            },
          ]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          itineraries: packageItineraries,
        }}
        validationSchema={packageItinerarySchema}
        onSubmit={(values) => {
          addItinerary(values);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div>
              <h2 className="text-lg font-bold">Package Itineraries</h2>
            </div>
            <FieldArray name="itineraries">
              {({ insert, remove, push }) => (
                <div>
                  {values.itineraries?.length > 0 &&
                    values.itineraries?.map((itinerary, index) => (
                      <div
                        key={index}
                        className="relative mt-4 rounded-lg border-2 p-4"
                      >
                        {/* <div
                          onClick={() => remove(index)}
                          className="absolute right-0 top-0 rounded-full border-2 p-1"
                        >
                          <MdClose />
                        </div> */}
                        <div
                          onClick={() =>
                            setActiveItinerary(
                              activeItinerary === index ? undefined : index
                            )
                          }
                          className={`flex items-center justify-between ${
                            index === activeItinerary ? "hidden" : ""
                          }`}
                        >
                          <div className="flex items-center gap-2">
                            <FaRoute />
                            <h2 className="font-bold">
                              {itinerary.starting_location_name} To{" "}
                              {itinerary.ending_location_name}
                            </h2>
                            {/* <p>(5 Days)</p> */}
                          </div>
                          <div>
                            <FaChevronDown />
                          </div>
                        </div>
                        <div
                          className={`${
                            index === activeItinerary ? "" : "hidden"
                          }`}
                        >
                          <div className="my-4 flex gap-4">
                            {/* //? Starting Location */}
                            <div className="grow basis-full lg:basis-[45%]">
                              <InputLabel
                                label="Starting Location"
                                labelFor="location"
                              />
                              <SelectSearch
                                onBlur={() =>
                                  editItinerary(values.itineraries[index])
                                }
                                onChange={(value) =>
                                  setFieldValue(
                                    `itineraries.${index}.starting_location_id`,
                                    value
                                  )
                                }
                                value={parseInt(
                                  values.itineraries[index].starting_location_id
                                )}
                                options={locations?.map((location) => ({
                                  label: location.name,
                                  value: location.id,
                                }))}
                              />

                              <ErrorMessage
                                name={`itineraries.${index}.starting_location_id`}
                              >
                                {(msg) => (
                                  <div className="error-msg">{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>

                            {/* //? Ending Location */}
                            <div className="grow basis-full lg:basis-[45%]">
                              <InputLabel
                                label="Ending Location"
                                labelFor="location"
                              />
                              <SelectSearch
                                onBlur={() =>
                                  editItinerary(values.itineraries[index])
                                }
                                onChange={(value) =>
                                  setFieldValue(
                                    `itineraries.${index}.ending_location_id`,
                                    value
                                  )
                                }
                                value={parseInt(
                                  values.itineraries[index].ending_location_id
                                )}
                                options={locations?.map((location) => ({
                                  label: location.name,
                                  value: location.id,
                                }))}
                              />

                              <ErrorMessage
                                name={`itineraries.${index}.ending_location_id`}
                              >
                                {(msg) => (
                                  <div className="error-msg">{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>
                          </div>
                          <div className="my-4 flex gap-4">
                            {/* //? Departure Time */}
                            <div className="grow basis-full lg:basis-[45%]">
                              <InputLabel label="Departure Time" />
                              <div className="flex items-center gap-2">
                                <div className="grow">
                                  <input
                                    type="time"
                                    name="departure_time"
                                    value={
                                      values.itineraries[index].departure_time
                                    }
                                    className="field"
                                    onChange={(e) =>
                                      setFieldValue(
                                        `itineraries.${index}.departure_time`,
                                        e.target.value
                                      )
                                    }
                                    onBlur={() =>
                                      editItinerary(values.itineraries[index])
                                    }
                                  />
                                  <ErrorMessage
                                    name={`itineraries.${index}.departure_time`}
                                  >
                                    {(msg) => (
                                      <div className="error-msg">{msg}</div>
                                    )}
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>

                            {/* //? Arrival Time */}
                            <div className="grow basis-full lg:basis-[45%]">
                              <InputLabel label="Arrival Time" />
                              <div className="flex items-center gap-2">
                                <div className="grow">
                                  <input
                                    type="time"
                                    name="arrival_time"
                                    value={
                                      values.itineraries[index].arrival_time
                                    }
                                    className="field"
                                    onChange={(e) =>
                                      setFieldValue(
                                        `itineraries.${index}.arrival_time`,
                                        e.target.value
                                      )
                                    }
                                    onBlur={() =>
                                      editItinerary(values.itineraries[index])
                                    }
                                  />
                                  <ErrorMessage
                                    name={`itineraries.${index}.departure_time`}
                                  >
                                    {(msg) => (
                                      <div className="error-msg">{msg}</div>
                                    )}
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="my-4 flex flex-wrap gap-4">
                            <div className="grow basis-full lg:basis-[45%]">
                              <InputLabel label="Departure Option" />
                              <div className="flex gap-12">
                                <label htmlFor="day-before-departure">
                                  <Field
                                    type="radio"
                                    name={`itineraries.${index}.departure_option`}
                                    id="day-before-departure"
                                    value="-1"
                                  />
                                  &nbsp; One Day Before{" "}
                                  {values.itineraries[index].departure_option}
                                </label>
                                <label htmlFor="same-day-departure">
                                  <Field
                                    type="radio"
                                    name={`itineraries.${index}.departure_option`}
                                    id="same-day-departure"
                                    value="0"
                                  />
                                  &nbsp; Same Day
                                </label>
                              </div>
                            </div>
                            <div className="grow basis-full lg:basis-[45%]">
                              <InputLabel label="Arrival Option" />
                              <div
                                onChange={(e) =>
                                  setFieldValue(
                                    `itineraries.${index}.arrival_option`,
                                    e.target.value
                                  )
                                }
                                className="flex gap-12"
                              >
                                <label htmlFor="same-day-arrival">
                                  <Field
                                    type="radio"
                                    name={`itineraries.${index}.arrival_option`}
                                    id="same-day-arrival"
                                    value="0"
                                  />
                                  &nbsp; Same Day
                                </label>
                                <label htmlFor="next-day-arrival">
                                  <Field
                                    type="radio"
                                    name={`itineraries.${index}.arrival_option`}
                                    id="next-day-arrival"
                                    value="1"
                                  />
                                  &nbsp; Next Day
                                </label>
                              </div>
                            </div>
                          </div>

                          {/* //? Reporting Point */}
                          <div className="my-4 flex gap-4">
                            <div className="grow basis-full lg:basis-[45%]">
                              <InputLabel label="Reporting Point" />
                              <Field
                                className="field"
                                id="reporting_place"
                                name={`itineraries.${index}.reporting_place`}
                                onBlur={() =>
                                  editItinerary(values.itineraries[index])
                                }
                              />
                              <ErrorMessage
                                name={`itineraries.${index}.reporting_place`}
                              >
                                {(msg) => (
                                  <div className="error-msg">{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>
                            <div className="grow basis-full lg:basis-[45%]">
                              <InputLabel label="Reporting Time" />
                              <div className="flex items-center gap-2">
                                <div className="grow">
                                  <input
                                    type="time"
                                    name="arrival_time"
                                    value={
                                      values.itineraries[index].reporting_time
                                    }
                                    className="field"
                                    onChange={(e) =>
                                      setFieldValue(
                                        `itineraries.${index}.reporting_time`,
                                        e.target.value
                                      )
                                    }
                                    onBlur={() =>
                                      editItinerary(values.itineraries[index])
                                    }
                                  />
                                  <ErrorMessage
                                    name={`itineraries.${index}.departure_time`}
                                  >
                                    {(msg) => (
                                      <div className="error-msg">{msg}</div>
                                    )}
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* //? Inclusions */}
                          <div className="my-4">
                            <InputLabel label="Inclusions" />
                            <EditorInput
                              fieldKey={`itineraries.${index}.inclusions`}
                              data={values.itineraries[index].inclusions}
                              onBlur={() =>
                                editItinerary(values.itineraries[index])
                              }
                            />
                          </div>

                          {/* //? Exclusions */}
                          <div className="my-4">
                            <InputLabel label="Exclusions" />
                            <EditorInput
                              fieldKey={`itineraries.${index}.exclusions`}
                              data={values.itineraries[index].exclusions}
                              onBlur={() =>
                                editItinerary(values.itineraries[index])
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  {/* <button
                    disabled
                    type="button"
                    className="my-2 flex items-center gap-1 px-4 text-jw-green"
                    onClick={() =>
                      push({
                        starting_location_id: "",
                        ending_location_id: "",
                        departure_time: "",
                        arrival_time: "",
                        reporting_place: "",
                        inclusions: "",
                        exclusions: "",
                      })
                    }
                  >
                    <FaPlusSquare /> Itinerary
                  </button> */}
                </div>
              )}
            </FieldArray>
            <div className="text-center">
              <button type="submit" className="btn-primary mt-4">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Step3;
