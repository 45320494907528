import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { useState } from "react";

import { FaEyeSlash, FaEye } from "react-icons/fa";
import { MdPassword } from "react-icons/md";

const PasswordInput = forwardRef((props, ref) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState("");

  useImperativeHandle(ref, () => ({
    setError(err) {
      setError(err);
    },
  }));

  const handleInputChange = (e) => {
    setError("");
    props.onChange(e);
  };
  return (
    <div
      className={`relative flex items-center gap-2 rounded-full border-2 py-1 px-4 transition-all duration-300 lg:py-2 ${
        isFocused ? "border-jw-green/50" : ""
      } ${error && "border-red-300"} ${props.className}`}
    >
      <label
        className={`absolute left-8 px-1 transition-all duration-300 ease-out ${
          isFocused || props.value
            ? "-top-3 bg-white text-sm font-bold"
            : "top-1/2 -translate-y-1/2"
        }`}
        htmlFor={props.id}
      >
        {props.placeholder ? props.placeholder : "Password"}
      </label>
      <div className="text-gray-500">
        <MdPassword />
      </div>
      <input
        type={passwordVisible ? "text" : "password"}
        ref={ref}
        name={props.name}
        id={props.id}
        value={props.value}
        onChange={handleInputChange}
        autoComplete="new-password"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onKeyPress={props.onKeyPress}
        className="grow bg-transparent focus:outline-none "
      />
      <div onClick={() => setPasswordVisible(!passwordVisible)}>
        {!passwordVisible && <FaEye />}
        {passwordVisible && <FaEyeSlash />}
      </div>
      {error && (
        <div className="absolute -bottom-2 bg-white px-1 text-xs text-red-400">
          {error}
        </div>
      )}
    </div>
  );
});

export default PasswordInput;
