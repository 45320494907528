import { useRef } from "react";
import DateRangeInput from "../../components/inputs/DateRangeInput";
import BarChart from "../../graphs/BarChart";
import { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import { getAllEmployeesOption } from "../../services/EmployeeServices";
import SelectSearch from "../../components/inputs/SelectSearch";
import { generateReport } from "../../services/LeadService";

function LeadReportDonut({
  selectedEmployee,
  setSelectedEmployee,
  report,
  setReport,
  dateRange,
  setDateRange,
}) {
  const dateInputRef = useRef();

  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    generateReport({
      startdate: moment(dateRange.start_date).format("YYYY-MM-DD"),
      lastdate: moment(dateRange.end_date).format("YYYY-MM-DD"),
      employee_id: selectedEmployee,
    })
      .then((res) => {
        console.log(res.data.data);
        setReport(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedEmployee, dateRange]);

  useEffect(() => {
    getAllEmployeesOption()
      .then((res) => {
        console.log(res.data.data);
        setEmployees(res.data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="h-full">
      <div className="flex h-full flex-col rounded-lg border p-2">
        <div className="flex items-center justify-between border-b pb-2">
          <SelectSearch
            value={selectedEmployee}
            onChange={setSelectedEmployee}
            options={employees?.map((employee) => ({
              label: `${employee.first_name} ${employee.last_name}`,
              value: employee.id,
            }))}
          />
          <DateRangeInput
            ref={dateInputRef}
            dateClassName="text-xs font-bold"
            maxDate={new Date()}
            startDate={dateRange.start_date}
            endDate={dateRange.end_date}
            onRangeChange={(startDate, endDate) => {
              setDateRange((dateRange) => ({
                start_date: moment(startDate).format("YYYY-MM-DD"),
                end_date: moment(endDate).format("YYYY-MM-DD"),
              }));
            }}
          />
        </div>
        <div className="flex h-full items-center justify-center py-4">
          <BarChart label="Count" chartData={report} />
        </div>
      </div>
    </div>
  );
}

export default LeadReportDonut;
