import { createContext, useState } from "react";

const AdminAuthContext = createContext({});

export const AdminAuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});

  return (
    <AdminAuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AdminAuthContext.Provider>
  );
};

export default AdminAuthContext;
