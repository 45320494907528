import React from 'react'
import { Link } from 'react-router-dom'
import { getUploadsUrl } from '../../../ServiceConfig'

const SingleJobApplicationCard = ({ applicantData, statusArray }) => {
    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden lg:w-1/2 w-full">
            <div className="p-6">
                <h2 className="text-xl font-semibold mb-2">{applicantData?.application?.applied_for?.title}</h2>
                <p className="text-gray-600 mb-4">{applicantData?.application?.applied_for?.role}</p>
                <div className="border-t border-gray-200 pt-4">
                    <p className="text-sm text-gray-500"><strong>Applied By:</strong> {applicantData?.application?.first_name} {applicantData?.application?.last_name}</p>
                    <p className="text-sm text-gray-500"><strong>Email:</strong> {applicantData?.application?.email}</p>
                    <p className="text-sm text-gray-500"><strong>Phone:</strong> {applicantData?.application?.phone}</p>
                    <p className="text-sm text-gray-500"><strong>Message:</strong> {applicantData?.application?.message}</p>
                    <p className="text-sm text-gray-500"><strong>Status:</strong> {statusArray[applicantData?.application?.status]}</p>
                    <p dangerouslySetInnerHTML={{
                        __html: applicantData?.applied_for?.description
                    }}></p>
                </div>
            </div>
            <div className="bg-gray-100 px-6 py-4 flex items-center justify-between">
                <a href={`${getUploadsUrl()}/resume/${applicantData?.application?.resume}`} target="_blank" className="text-blue-500 hover:underline">Download Resume</a>
                <div className="flex space-x-2">
                    {applicantData?.application?.fb_url && <Link href={applicantData?.application?.fb_url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline"><i className="fab fa-facebook-f"></i></Link>}
                    {applicantData?.application?.instagram_url && <Link href={applicantData?.application?.instagram_url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline"><i className="fab fa-instagram"></i></Link>}
                    {applicantData?.application?.youtube_url && <Link href={applicantData?.application?.youtube_url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline"><i className="fab fa-youtube"></i></Link>}
                    {applicantData?.application?.linkedin_url && <Link href={applicantData?.application?.linkedin_url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline"><i className="fab fa-linkedin-in"></i></Link>}
                    {applicantData?.application?.drive_link && <Link href={applicantData?.application?.drive_link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline"><i className="fas fa-link"></i></Link>}
                </div>
            </div>
        </div>
    )
}

export default SingleJobApplicationCard