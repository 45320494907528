import { memo, useEffect, useState } from "react";

import InputLabel from "../../components/inputs/InputLabel";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

import EditorInput from "../../components/inputs/EditorInput";
import UserDetailForm from "./UserDetailForm";
import PackageDetailsForm from "./PackageDetailsForm";
import PackagePriceForm from "./PackagePriceForm";
import CostingTotalForm from "./CostingTotalForm";
import { getAllEmployeesOption } from "../../services/EmployeeServices";
import SelectSearch from "../../components/inputs/SelectSearch";
import { getUploadsUrl } from "../../../ServiceConfig";
import useAdminAuth from "../../hooks/useAdminAuth";
import ToggleInput from "../../components/inputs/ToggleInput";
import Collapsible from "../../../hooks/Collapsible";
import { uploadCustomItineraryPDF } from "../../services/BookingServices";
import { getAllCustomItineraries } from "../../services/CustomPackageServices";

function BookingForm({
  setPackageName,
  formData,
  edit,
  leadData,
  verificationLink,
  isCustomized,
  proformaInvoice,
  onSubmit,
  selectedIndex,
  setSelectedIndex,
}) {
  const [employees, setEmployees] = useState([]);
  const [userMembership, setUserMembership] = useState({});
  const [userCreditNote, setUserCreditNote] = useState(0);
  const [customItineraries, setCustomItineraries] = useState();
  const [phoneNumberExactDigits, setPhoneNumberExactDigits] = useState(10);
  const { auth, setAuth } = useAdminAuth();
  // console.log(auth?.id);

  const bookingFormSchema = Yup.object().shape({
    first_name: Yup.string().required("This field is Required"),
    last_name: Yup.string().when([], {
      is: () => verificationLink === true,
      then: Yup.string().required("This field is Required"),
      otherwise: Yup.string(),
    }),
    email: Yup.string().email().required("This field is Required"),
    phone: Yup.string().when("country_code", {
      is: (country_code) => phoneNumberExactDigits != 14,
      then: Yup.string()
        .length(
          phoneNumberExactDigits,
          `Number must be exactly ${phoneNumberExactDigits} digits.`
        )
        .required("This field is Required"),
      otherwise: Yup.string()
        .min(8, "Number must be min 8 digits.")
        .max(12, "Number must be max 12 digits.")
        .required("This field is Required"),
    }),
    firm_name: Yup.string().when([], {
      is: () => proformaInvoice === true,
      then: Yup.string().required("Firm Name is required"),
      otherwise: Yup.string(),
    }),
    gst_state: Yup.string(),
    gst_number: Yup.string().test(
      "gst-state-match",
      "GST Number must start with the selected GST State code",
      function (value) {
        const { gst_state } = this.parent;
        if (!value || !gst_state) {
          return true;
        }
        // const gstStateCode = gst_state.substring(0, 2);
        return parseInt(value.substring(0, 2)) === parseInt(gst_state);
      }
    ),
    country_id: Yup.string().required("This field is Required"),
    state_id: Yup.string().when("country_id", {
      is: (country_id) => country_id == 101,
      then: Yup.string().required("This field is Required"),
      otherwise: Yup.string().nullable(),
    }),
    // package_id: Yup.string().when("isCustomized", {
    //   is: 1,
    //   then: Yup.string(),
    //   otherwise: Yup.string().required("This field is Required"),
    // }),
    event_date: Yup.string().required("This field is Required"),
    total_pax: Yup.number().required("This field is Required"),
    total_cost: Yup.number().required("This field is Required"),
    total_gst: Yup.number().required("This field is Required"),
    total_with_gst: Yup.number().required("This field is Required"),
    corporate_verification_link: Yup.boolean(),
    total_amount_to_pay: Yup.number().required("This field is Required"),
    total_amount_paid: Yup.number().when("corporate_verification_link", {
      is: (corporate_verification_link) =>
        !corporate_verification_link && verificationLink === true,
      then: Yup.number()
        .min(1, "Should not be zero")
        .required("This field is Required"),
      otherwise: Yup.number().required("This Field is required"),
    }),
    converted_by: Yup.string().required("This field is Required"),
    cost: Yup.array().of(
      Yup.object().shape({
        travel_mode_id: Yup.string("please select Travel mode")
          .nullable()
          .required("Please select a Travel Mode"),
        occupancy_id: Yup.string("please select Occupancy")
          .nullable()
          .required("Please select an Occupancy"),
        package_price: Yup.number()
          .min(1, "Price can not be 0")
          .required("This field is Required"),
        package_discount: Yup.number().required("This field is Required"),
        package_price_after_discount: Yup.number().required(
          "This field is Required"
        ),
        package_gst: Yup.string().required("This field is Required"),
        package_with_gst: Yup.number().required("This field is Required"),
        pax: Yup.number()
          .min(1, "Add Minimum 1 Traveller")
          .required("This field is Required"),
        amount: Yup.number().required("This field is Required"),
      })
    ),

    // payment: Yup.string().required("Payment Method is required"),
    payment: Yup.object().shape({
      payment_method:
        !verificationLink &&
        !proformaInvoice &&
        Yup.string().required("Payment Method is required"),
    }),
    // payment: Yup.object().shape({
    //   payment_method: Yup.string().when("verificationLink", {
    //     is: true,
    //     then: Yup.string().notRequired(),
    //     otherwise: Yup.string().required("Payment Method is required"),
    //   }),
    // }),
  });

  useEffect(() => {
    getAllEmployeesOption().then((res) => {
      setEmployees(res.data.data.data);
    });
  }, []);

  useEffect(() => {
    getAllCustomItineraries()
      .then((res) => {
        setCustomItineraries(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getUserInitials = (first_name, last_name) => {
    const firstInitial = first_name ? first_name[0] : "";
    const lastInitial = last_name ? last_name[0] : "";
    return `${firstInitial}${lastInitial}`.toUpperCase();
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          first_name: formData?.first_name || leadData?.first_name || "",
          last_name: formData?.last_name || leadData?.last_name || "",
          email: formData?.email || leadData?.email || "",
          country_code: formData?.country_code || "91",
          phone: formData?.phone || leadData?.phone || "",
          firm_name: formData?.firm_name || "",
          gst_number: formData?.gst_number || "",
          firm_address: formData?.firm_address || "",
          gst_state: formData?.gst_state || "",
          cc_email: formData?.cc_email || "",
          country_id: parseInt(formData?.country_id) || 101,
          state_id: formData?.state_id || "",
          package_name: formData?.package_name || leadData?.package_name || "",
          corporate_verification_link:
            formData?.corporate_verification_link || "",
          package_id: formData?.package_id || leadData?.package_id || "",
          itinerary_id: `${formData?.itinerary_id}` || null,
          custom_itinerary_id: formData?.custom_itinerary_id || "",
          cust_itinerary_text: formData?.cust_itinerary_text || "",
          cust_itinerary_pdf: formData?.cust_itinerary_pdf || "",

          start_location: formData?.starting_location || null,
          end_location: formData?.ending_location || null,
          event_date: formData?.event_date || formData?.event_date || "",

          available_credit_note: userCreditNote?.credit_note || 0,

          membership_card_number: userMembership?.card_number || "",
          amount_used_in_credit_note: formData?.amount_used_in_credit_note || 0,
          amount_used_in_gift_card: formData?.amount_used_in_gift_card || 0,
          total_pax: formData?.total_pax || 0,
          total_cost: formData?.total_cost || 0,
          total_discount: formData?.total_discount || 0,
          total_cost_after_discount: formData?.total_cost_after_discount || 0,
          total_gst: formData?.total_gst || "",
          total_with_gst: formData?.total_with_gst || 0,
          total_tcs: formData?.total_tcs || 0,
          round_off_amount: formData?.round_off_amount || 0,
          total_amount_to_pay: formData?.total_amount_to_pay || 0,
          total_amount_paid: formData?.total_amount_paid || 0,
          remaining_amount: formData?.remaining_amount || 0,
          converted_by:
            formData?.converted_by?.id ||
            leadData?.assigned_to ||
            (edit ? "website" : auth?.id),
          comment: "",
          cost: formData?.cost || [
            {
              travel_mode_id: null,
              rider_id: null,
              occupancy_id: null,
              package_price: 0,
              package_discount: 0,
              package_price_after_discount: 0,
              package_gst: 0,
              package_with_gst: 0,
              pax: 0,
              amount: 0,
            },
          ],
          addons: formData?.addons?.length > 0 ? formData?.addons : [{}],
          other_services:
            formData?.other_services?.length > 0
              ? formData?.other_services
              : [{}],
          payment: formData?.payment
            ? formData?.payment[0]
            : {
                payment_method: "",
                transaction_id: "",
                payment_source: "admin",
                transaction_verification_status: 1,
              },
          no_of_days: formData?.no_of_days ? formData?.no_of_days : "",
          special_notes: formData?.special_notes ? formData?.special_notes : "",
        }}
        validationSchema={bookingFormSchema}
        onSubmit={(values, { setSubmitting }) => {
          // console.log(values, "here values")
          onSubmit(values, setSubmitting);
        }}
      >
        {({ values, setFieldValue, errors, touched, isSubmitting }) => (
          <Form>
            {verificationLink && (
              <>
                <div className="hidden lg:block">
                  <InputLabel label="Is Corporate" />
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    value={values.corporate_verification_link}
                    onChange={(e) =>
                      setFieldValue(
                        "corporate_verification_link",
                        e.target.checked
                      )
                    }
                  />
                  <span className="lg:text-md text-sm">
                    {" "}
                    &nbsp; Mark this as Corporate Verification Link{" "}
                  </span>
                </div>
                <div className="mb-3 block lg:hidden">
                  <InputLabel label="Is Corporate Booking?" />
                  <ToggleInput
                    checked={values.corporate_verification_link}
                    onToggle={(e) =>
                      setFieldValue(
                        "corporate_verification_link",
                        e.target.checked
                      )
                    }
                  />
                </div>
              </>
            )}
            <div className="my-3">
              {isCustomized ? (
                <Collapsible
                  heading="Customized Itinerary"
                  selectedIndex={selectedIndex}
                  index={7}
                  setSelectedIndex={setSelectedIndex}
                >
                  {/* dropdown */}
                  <div className="flex flex-col">
                    <InputLabel label="Select Custom Itinerary" />
                    <SelectSearch
                      options={customItineraries?.map((itinerary) => ({
                        value: itinerary?.id,
                        label: `${itinerary.package_title} - ${
                          itinerary.no_of_nights
                        }N/${itinerary.no_of_nights + 1}D - ${
                          itinerary?.first_name
                        }`,
                      }))}
                      value={values.custom_itinerary_id}
                      onChange={(value) => {
                        const selected = customItineraries.filter(
                          (itin) => itin.id === value
                        )[0];
                        setFieldValue("state_id", selected?.state_id);
                        setFieldValue("package_name", selected?.package_title);
                        setFieldValue("event_date", selected?.start_date);
                        setFieldValue("no_of_days", selected?.no_of_nights);
                        setFieldValue("custom_itinerary_id", value);
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-6">
                    {/* write */}
                    <div className="flex flex-col">
                      <InputLabel label="Write Itinerary" />
                      <EditorInput
                        fieldKey="cust_itinerary_text"
                        data={values.cust_itinerary_text}
                      />
                    </div>

                    {/* Seleect PDF file */}
                    <div className="flex flex-col gap-1">
                      <InputLabel label="Choose PDF file" />
                      {values?.cust_itinerary_pdf && (
                        <a
                          target="_blank"
                          href={`${getUploadsUrl()}/custom-itinerary-pdf/${
                            values.cust_itinerary_pdf
                          }`}
                        >
                          <p className="font-semibold text-blue-500">
                            {" "}
                            {values.cust_itinerary_pdf}
                          </p>
                        </a>
                      )}
                      <input
                        type="file"
                        className="block text-sm text-gray-500
                     file:mr-4 file:rounded-full file:border-0
                     file:bg-violet-50 file:py-2
                     file:px-4 file:text-sm
                     file:font-semibold file:text-violet-700
                     hover:file:bg-violet-100"
                        accept="application/pdf"
                        onChange={(event) => {
                          uploadCustomItineraryPDF({
                            pdf: event.target.files[0],
                          })
                            .then((res) => {
                              setFieldValue(
                                "cust_itinerary_pdf",
                                res.data.data
                              );
                            })
                            .catch((er) => {
                              console.log(er);
                            });
                        }}
                      />
                    </div>
                  </div>
                </Collapsible>
              ) : (
                <></>
              )}
            </div>

            {/* //? User Detail Form */}
            <div className="my-3">
              <UserDetailForm
                setUserMembership={setUserMembership}
                setUserCreditNote={setUserCreditNote}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                proformaInvoice={proformaInvoice}
                setPhoneNumberExactDigits={setPhoneNumberExactDigits}
                verificationLink={verificationLink}
              />
            </div>

            <div className="my-3">
              <PackageDetailsForm
                edit={edit}
                setPackageName={setPackageName}
                isCustomized={isCustomized}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
              />
            </div>

            <div className="my-3">
              <PackagePriceForm
                edit={edit}
                userMembership={userMembership}
                isCustomized={isCustomized}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
              />
            </div>

            <div className="my-3">
              <CostingTotalForm
                edit={edit}
                proformaInvoice={proformaInvoice}
                userCreditNote={userCreditNote}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
              />
            </div>

            <div className="my-4">
              <div className="">
                <InputLabel
                  label="Special Notes (if any):"
                  instruction="Special Notes will be visible to clients incase of any customization"
                />
                <Field
                  as="textarea"
                  className="field min-h-[10rem] lg:min-h-[3rem]"
                  name="special_notes"
                />
              </div>
            </div>

            <div className="my-4">
              <div className="">
                <InputLabel label="Custom Itinerary" />
                <EditorInput fieldKey="custom_itinerary" />
              </div>
            </div>

            <div className="my-2">
              <div className="rounded-lg bg-white p-1 shadow-sm">
                <h3 className="mb-2 font-bold">Agents and Comments</h3>
                <div className="flex flex-wrap gap-4">
                  <div className="grow">
                    <InputLabel label="Lead Converted By:" required={true} />

                    <SelectSearch
                      onChange={(value) => setFieldValue("converted_by", value)}
                      value={values.converted_by}
                      // disabled={edit && !values.converted_by}
                      options={[
                        { label: "Website", value: "website" },
                        ...employees?.map((employee) => ({
                          label: `${employee?.first_name} ${
                            employee?.last_name || ""
                          } - ${employee?.official_contact?.email}- ${
                            employee?.official_contact?.phone
                          }`,
                          value: employee?.id,
                        })),
                      ]}
                    />

                    <ErrorMessage name={`converted_by`}>
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="max-w-full grow">
                    <InputLabel label="Comments (if any):" />
                    <EditorInput fieldKey="comment" />

                    <ErrorMessage name={`comment`}>
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
              </div>
            </div>

            <div className="my-4 text-center">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn-primary"
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default memo(BookingForm);
