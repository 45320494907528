import React, { useState } from "react";
import { useEffect } from "react";
import { getAnnualSaleSeriesData } from "../../services/DashboardServices";
import ReactApexChart from "react-apexcharts";
import useTheme from "../../hooks/useTheme";

function AnnualSale() {
  const { theme, setTheme } = useTheme();
  setTheme("light"); // if useTheme has no values

  const chartDataOptions = {
    chart: {
      id: "area",
      foreColor: theme && theme === "dark" ? "#fff" : "#000",
    },
    xaxis: {
      categories: [
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
        "Jan",
        "Feb",
        "Mar",
      ],
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
  };

  const [seriesData, setSeriesData] = useState([]);
  const [currentTarget, setCurrentTarget] = useState(0);
  const [currentBookings, setCurrentBookings] = useState(0);
  const [currentCancellation, setCurrentCancellation] = useState(0);

  useEffect(() => {
    getAnnualSaleSeriesData()
      .then((res) => {
        console.log(res);
        setSeriesData(res.data.data.series);
        setCurrentTarget(res.data.data.target);
        setCurrentBookings(res.data.data.total_bookings);
        setCurrentCancellation(res.data.data.total_cancellations);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="m-2 ">
      <div className="flex flex-col  rounded-lg bg-white">
        <div className="flex flex-col items-start justify-between rounded-t-lg border-b bg-[#D7F2FD] p-4 dark:bg-slate-900 dark:text-white lg:flex-row lg:items-center">
          <h3 className="font-bold">Number of Bookings in a FY</h3>
          <h3 className="font-bold">Target : {currentTarget} Wravelers</h3>
          <div>
            <h3 className="font-bold">Total Bookings : {currentBookings}</h3>
            <h3 className="font-bold">
              Total Cancellation : {currentCancellation}
            </h3>
          </div>
        </div>
        <div className="items-center justify-center py-4 dark:bg-slate-800 ">
          <ReactApexChart
            options={chartDataOptions}
            series={seriesData}
            type="area"
            height={450}
          />
        </div>
      </div>
    </div>
  );
}
export default AnnualSale;
