import React, { useEffect, useState } from "react";
import FileInput from "../../../components/inputFields/FileInput";

import {
  createPermission,
  updatePermission,
} from "../../services/PermissionServices";
import InputLabel from "../inputs/InputLabel";
import useLoader from "../../../hooks/useLoader";
import { toast } from "react-toastify";
import { getUploadsUrl } from "../../../ServiceConfig";

// -----------------------------------------------------------
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import KeywordsInput from "../inputs/KeywordsInput";
import { getAllModulesOption } from "../../services/ModuleServices";
import SelectSearch from "../inputs/SelectSearch";

const moduleFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  module_id: Yup.string().required("Required"),
});

function PermissionForm({ formData, edit, onSuccess }) {
  const { setLoading } = useLoader();
  const [modules, setModules] = useState([]);

  useEffect(() => {
    getAllModulesOption()
      .then((res) => {
        let modules = [];
        res.data.data.forEach((data) => {
          modules.push({ value: `${data.id}`, label: data.name });
        });
        setModules(modules);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const renderModules = modules?.map((module) => {
    return (
      <option key={module.id} value={module.id}>
        {module.name}
      </option>
    );
  });

  // ? Add new country
  const addPermission = (values) => {
    let data = Object.assign({}, values);
    createPermission(data)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  // ? Update country
  const editPermission = (values) => {
    let data = Object.assign({}, values);
    updatePermission(formData.id, data)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div>
      <Formik
        initialValues={{
          name: formData?.name,
          module_id: formData?.module_id?.toString() || "",
        }}
        validationSchema={moduleFormSchema}
        onSubmit={(values) => {
          edit ? editPermission(values) : addPermission(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="flex gap-4">
              <div className="grow">
                <div className="flex flex-wrap gap-4">
                  {/* //? country Name */}
                  <div className="grow basis-full lg:max-w-[50%] lg:basis-[45%]">
                    <InputLabel label="Name" labelFor="name" />
                    <Field className="field" id="name" name="name" />
                    <ErrorMessage name="name">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="my-2 grow lg:max-w-[50%] lg:basis-[45%]">
                  <InputLabel label="Select Module" labelFor="country" />

                  <SelectSearch
                    options={modules}
                    value={`${values.module_id}`}
                    onChange={(value) => setFieldValue("module_id", value)}
                  />
                  <ErrorMessage name="module_id">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
            </div>

            <div className="mt-4 text-center">
              <button type="submit" className="btn-primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default PermissionForm;
