import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import DataTable from "../components/common/DataTable";
import { getAdminUrl, getUploadsUrl } from "../../ServiceConfig";
import ComponentHeader from "../components/common/ComponentHeader";
import H2 from "../../components/headings/H2";
import { FaPlusSquare } from "react-icons/fa";

const months = [
    { number: "01", month: "January" },
    { number: "02", month: "February" },
    { number: "03", month: "March" },
    { number: "04", month: "April" },
    { number: "05", month: "May" },
    { number: "06", month: "June" },
    { number: "07", month: "July" },
    { number: "08", month: "August" },
    { number: "09", month: "September" },
    { number: "10", month: "October" },
    { number: "11", month: "November" },
    { number: "12", month: "December" }
];

function RnRLists() {
    const navigate = useNavigate();
    const rnrTable = useRef();

    const actionIcons = function (cell, formatterParams, onRendered) {
        return `<div class="rnr-edit-btn" data-id=${cell.getRow().getData().id
            } style="display: flex; align-items: center; gap: 12px;">
    <div style="cursor: pointer; title="Edit">
    <svg
      x="0px"
      y="0px"
      width="16px"
      height="16px"
      viewBox="0 0 497.182 497.182"
      xml:space="preserve"
    >
      <g>
        <path
          d="M376.956,237.143l58.504-57.637v255.468c0,34.352-27.852,62.207-62.207,62.207H62.209
  c-34.352,0-62.207-27.855-62.207-62.207V123.932c0-34.355,27.855-62.211,62.207-62.211h255.453l-59.848,58.234H58.597v318.359
  h318.359V237.143z M377.469,56.755l62.967,62.985l20.993-20.986l-62.968-62.985L377.469,56.755z M434.211,0l-20.989,20.99
  l62.968,62.968l20.989-20.989L434.211,0z M171.288,262.987l188.91-188.953l62.969,62.984l-188.91,188.938l-91.703,28.677
  L171.288,262.987z M202.596,265.369l10.77,10.953l161.938-159.184l-10.77-10.953L202.596,265.369z"
        />
      </g>
    </svg></div>
  </div>`;
    };


    const columns = [
        {
            title: "Id",
            field: "id",
            responsive: 0,
            width: "50",
            resizable: true,
            vertAlign: "middle",
        },
        {
            title: "Month",
            field: "month",
            minWidth: "270",
            vertAlign: "middle",
            responsive: 0,
            resizable: true,
            formatter: function (cell, formatterParams, onRendered) {
                return months.find(month => month.number === cell.getValue())?.month;
              },
        },
        {
            title: "Year",
            field: "year",
            minWidth: "270",
            vertAlign: "middle",
            responsive: 0,
            resizable: true,
        },
        {
            title: "Proposed at",
            field: "proposed_at",
            minWidth: "270",
            vertAlign: "middle",
            responsive: 0,
            resizable: true,
        },
        {
            title: "Presented at",
            field: "presented_at",
            minWidth: "270",
            vertAlign: "middle",
            responsive: 0,
            resizable: true,
        },
        {
            formatter: actionIcons,
            headerSort: false,
            width: 80,
            responsive: 0,
            hozAlign: "center",
            vertAlign: "middle",
        },

    ];

    useEffect(() => {
        $(document).on("click", ".rnr-edit-btn", function (e) {
            e.stopPropagation();
            e.preventDefault();
            let cell = $(this).attr("data-id");
            navigate(`/rnr/edit/${cell}`);
        });
        return () => {
            $(document).off("click", ".rnr-edit-btn");
        };
    }, []);

    return (
        <div className="flex h-full flex-col">
            <ComponentHeader className="border-b">
                <H2>RnR</H2>
                <Link
                    to="/rnr/add"
                    className="btn-primary flex items-center dark:bg-green-800 gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
                >
                    <FaPlusSquare /> Add New
                </Link>
            </ComponentHeader>
            <div className="h-full">
                <DataTable
                    ref={rnrTable}
                    ajaxUrl={`${getAdminUrl()}/rnr`}
                    columns={columns}
                    search={true}
                    responsiveLayout="collapse"
                    perPage={25}
                />
            </div>
        </div>
    );
}

export default RnRLists;
