import { Link, useParams } from "react-router-dom";
import H2 from "../../../components/headings/H2";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import { useEffect } from "react";
import { useState } from "react";
import {
  getCustomPackageById,
  mailCustomPdf,
} from "../../services/CustomPackageServices";
import { toast } from "react-toastify";
import ItineraryPdfTemplate from "../../../components/ItineraryPdfTemplate";
import { useRef } from "react";
import html2pdf from "html2pdf.js";
import useLoader from "../../../hooks/useLoader";
import ItinerarypdftemplateMobile from "../../../components/Itinerarypdftemplatemobile";

function ViewItinerary() {
  const customPackageId = useParams().id;

  const { loading, setLoading } = useLoader();

  const pdfTemplate = useRef();

  const containerRef = useRef();
  // const [containerHeight, setContainerHeight] = useState();
  const [customItinerary, setCustomItinerary] = useState();
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    getCustomPackageById(customPackageId)
      .then((res) => {
        // console.log(res);
        setCustomItinerary(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }, []);

  const handleGeneratePDF = async () => {
    setLoading(true);
    containerRef.current.classList.remove('hidden');
    let containerHeight = pdfTemplate.current.getContainerHeight();
    if (!imageLoaded) {
      toast.error("Image not loaded yet.");
      setLoading(false);
      return; // Wait for the image to load completely before generating PDF
    }

    const options = {
      margin: [0, 0, 0, 0],
      filename: `${customItinerary.first_name.replace(
        /[\s.]/g,
        "-"
      )}-${customItinerary.package_title.replace(/[\s.]/g, "-")}-${customItinerary.start_date
        }`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "px",
        format: [600, containerHeight],
        orientation: "portrait",
      },
    };

    await html2pdf().set(options).from(containerRef.current).save();
    containerRef.current.classList.add('hidden');
    setLoading(false);
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleEmailPDF = async () => {
    setLoading(true);

    let containerHeight = pdfTemplate.current.getContainerHeight();
    if (!imageLoaded) {
      setLoading(false);
      toast.error("Image not loaded yet.");
      return; // Wait for the image to load completely before generating PDF
    }

    const options = {
      margin: [0, 0, 0, 0],
      filename: `${customItinerary.first_name}-${customItinerary.package_title}-${customItinerary.start_date}`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "px",
        format: [600, containerHeight],
        orientation: "portrait",
      },
    };

    html2pdf()
      .set(options)
      .from(containerRef.current)
      .outputPdf()
      .then((pdf) => {
        const pdfData = "data:application/pdf;base64," + btoa(pdf);

        const blob = dataURItoBlob(pdfData);

        const formData = new FormData();

        formData.append("pdf", blob);

        mailCustomPdf(customItinerary.id, formData)
          .then((res) => {
            toast.success(res.data.message);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            toast.error(err.response.data.message);
          });
      });
  };

  return (
    <div>
      <ComponentHeader className="border-b">
        <H2>Custom Package</H2>
        <Link
          to="/itinerary-builder"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>

      <div className="relative flex flex-wrap items-start justify-around lg:flex-nowrap overflow-x-auto">
        <div ref={containerRef} className="border lg:block hidden">
          <ItineraryPdfTemplate
            ref={pdfTemplate}
            customItinerary={customItinerary}
            setImageLoaded={setImageLoaded}
          />

        </div>
        <div className="block lg:hidden">
          <ItinerarypdftemplateMobile
            customItinerary={customItinerary}
            setImageLoaded={setImageLoaded}
          />
        </div>
        <div className="sticky top-0 mt-8 flex items-center justify-center text-center lg:mt-0 lg:h-[90vh]">
          <div>
            <button
              onClick={handleGeneratePDF}
              className="btn-primary !py-2 !px-16 text-lg"
            >
              Generate PDF
            </button>
            <Link
              to={`/itinerary-builder/edit/${customItinerary?.id}`}
              className="btn-primary mx-auto my-4 block !py-2 !px-16 text-lg"
            >
              Edit
            </Link>
            <button
              onClick={handleEmailPDF}
              className="btn-primary mx-auto my-4 block !py-2 !px-16 text-lg"
            >
              Email PDF
            </button>
            <Link
              to={`/bookings/verification-links/add?itinerary=${customItinerary?.id}`}
              className="btn-primary mx-auto my-4 block !py-2 !px-16 text-lg"
            >
              Convert
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewItinerary;
