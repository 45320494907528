import React from 'react';

import Modal from "react-modal";
import { getUploadsUrl } from '../../../ServiceConfig';
import { MdClose } from 'react-icons/md';
const JobPostNotificationPopup = ({ open, setOpen, data }) => {
    if (!open) return null;

    return (
        <Modal
            isOpen={open}
            onRequestClose={() => { setOpen(false) }}
            contentLabel="Job Post Notification"
            className="absolute top-1/2 left-1/2 p-6 lg:max-h-[90vh] max-h-[80vh] overflow-auto lg:min-w-[50%] lg:max-w-[60%] w-[90vw] -translate-y-1/2 -translate-x-1/2 rounded-lg dark:bg-slate-800 bg-white"
        >
            <div className='flex flex-col p-2 gap-6'>
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold dark:text-white text-gray-900">{data.title}</h2>
                    <button
                        onClick={() => { setOpen(false) }}
                        className="text-gray-500 hover:text-gray-700 focus:outline-none"
                    >
                        <MdClose size={25} color='dark:white' />
                    </button>
                </div>
                <div className='flex gap-6'>
                    <div className='flex flex-col gap-4'>
                        {data.salary && <p> <strong>Salary</strong>: {data.salary}</p>}
                        {data.experience && <p> <strong>Experience</strong>:  {data.experience}</p>}
                        {data.skills && <span><strong>Skills</strong>: <span className='overflow-auto' dangerouslySetInnerHTML={{ __html: data.skills }} /></span>}
                        <button onClick={() => {
                            window.location.href = "https://www.justwravel.in/career"
                        }} className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
                            Go to Career Page
                        </button>
                    </div>
                </div>
                <div className='overflow-auto' dangerouslySetInnerHTML={{ __html: data.description }} />

            </div>
        </Modal>
    );
};

export default JobPostNotificationPopup;
