import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getAdBannersByKey,
  getSettingsByKey,
  updateAdBanner,
  updateWebsiteSettings,
  uploadSettingsImage,
} from "../../services/SettingsServices";
import { getUploadsUrl } from "../../../ServiceConfig";

const ImageComponent = ({ data }) => {
  const [value, setValue] = useState("");
  const [initialValue, setInitialvalue] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [image, setImage] = useState(null);
  const [initialUrl, setInitialUrl] = useState("");
  const [url, setUrl] = useState("");

  const changeInValues = initialUrl !== url || initialValue !== value;

  const handleSubmit = () => {
    setSubmitting(true);

    if (changeInValues) {
      console.log(value, "gettting value of image");
      if (initialValue != value) {
        console.log("licking from here");
        uploadSettingsImage({ image: [value] })
          .then((res) => {
            const submitData = {
              key: data.key,
              file_type: "image",
              file: res.data.data[0].uploaded_image,
              title: "Image",
              link: url,
              status: 1,
            };
            updateAdBanner(submitData)
              .then((res) => {
                toast.success("updated image");
                getInitialImage();
                setSubmitting(false);
              })
              .catch((error) => {
                console.log(error);
                toast.error("error in updating settings");
                setSubmitting(false);
              });
          })
          .catch((e) => {
            setSubmitting(false);
            console.log(e);
          });
      } else {
        const submitData = {
          key: data.key,
          file_type: "image",

          title: "Image",
          link: url,
          status: 1,
        };
        updateAdBanner(submitData)
          .then((res) => {
            toast.success("updated image");
            getInitialImage();
            setSubmitting(false);
          })
          .catch((error) => {
            console.log(error);
            toast.error("error in updating settings");
            setSubmitting(false);
          });
      }
    }
  };

  const getInitialImage = () => {
    getAdBannersByKey(data.key)
      .then((res) => {
        console.log(res.data.data.file, ":image");
        setValue(res.data.data.file);
        setInitialvalue(res.data.data.file);
        setUrl(res.data.data.link);
        setInitialUrl(res.data.data.link);
      })
      .catch((er) => {
        console.log(er, "error");
      });
  };

  useEffect(() => {
    getInitialImage();
    // getInitialUrl();
  }, []);

  return (
    <div className="m-3 w-full rounded-md border border-gray-300 bg-white dark:bg-slate-900 dark:text-white lg:max-w-[800px]">
      <div className="flex  w-full gap-3 px-5 py-8 ">
        <div className="w-full">
          <h2 className="mb-2 text-2xl font-bold capitalize">{data.heading}</h2>
          <div>{data.description}</div>
          <div className="flex items-start justify-between">
            <div>
              <input
                onChange={(e) => {
                  setValue(e.target.files[0]);
                  setImage(URL.createObjectURL(e.target.files[0]));
                }}
                type="file"
                accept="image/*"
                className=" field mt-5 rounded-md border border-gray-300 p-2"
                placeholder={data.placeholder}
              />
              <input
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value);
                }}
                type={data.urlType}
                className=" field mt-5 w-[23rem] rounded-md border border-gray-300 p-2"
                placeholder="place redirect URL here"
              />
            </div>

            <img
              src={image ? image : `${getUploadsUrl()}/settings/${value}`}
              alt="Uploaded"
              style={{ maxWidth: "300px" }}
            />
          </div>
        </div>
      </div>
      <hr className="border-t-2 border-gray-300" />
      <div className="flex justify-between p-5">
        <div>{data.warning}</div>
        <button
          onClick={handleSubmit}
          disabled={!changeInValues}
          className={`rounded py-2 px-4 font-bold text-white shadow-2xl  focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-opacity-50 ${
            !changeInValues || submitting
              ? "cursor-not-allowed bg-gray-400 hover:bg-gray-400"
              : "bg-green-700 hover:bg-green-600"
          }`}
        >
          {submitting ? `Saving ...` : `Save`}
        </button>
      </div>
    </div>
  );
};

export default ImageComponent;
