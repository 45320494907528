import React, { useState } from 'react'

const TransactionInput = ({ handleTransaction, transactionType, setTransactionType }) => {
    const [open, setOpen] = useState(false)
    const options = transactionType === 'credit' ? ['booking_cancellation', 'sdfgsdfdsf', 'adfdsfsd'] : ['booking', 'asfdasdfas', 'asfdasfas']
    const [amount, setAmount] = useState()
    const [creditDebitType, setCreditDebitType] = useState('')

    const updateTransaction = async () => {
        handleTransaction(amount, creditDebitType)
        setAmount()
        setCreditDebitType('')
        setTransactionType()
    }

    return (
        <div className='flex flex-col gap-3'>
            <div className="flex gap-4 w-full rounded-md  px-3 py-2 flex-col items-start">
                <div className='flex flex-col  w-full'>
                    <label className='capitalize'>{transactionType} Amount</label>
                    <input value={amount} onChange={(e) => { setAmount(e.target.value) }} type="number" className=" px-3 border border-gray-300 py-2 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-transparent capitalize" placeholder={`Enter ${transactionType} amount`} />
                </div>
                <div className='flex flex-col  w-full'>

                    <textarea
                        value={creditDebitType}
                        onChange={(e) => setCreditDebitType(e.target.value)}
                        className="resize-y block w-full px-3 border border-gray-300 py-2 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-transparent"
                        style={{ minHeight: '4rem' }}
                        rows={2}
                        placeholder="Enter description........"
                    />
                </div>
            </div>
            <button onClick={updateTransaction} type="button" className="ml-2 lg:w-1/6 px-3 py-2 rounded-md bg-indigo-500 text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700">
                Submit
            </button>
        </div>
    )
}

export default TransactionInput