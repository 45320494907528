import { Link, useNavigate } from "react-router-dom";
// import OccupancyForm from "./OccupancyForm";
// import MetaTags from "../../../web/layouts/MetaTags";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";
import PushNotificationForm from "./PushNotificationForm";

function AddPushNotification() {
  const navigate = useNavigate();

  const onAddOccupancy = (data) => {
    navigate(`/push-notifications`);
  };

  return (
    <div className="">
      {/* <MetaTags title="Add Travel Mode | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <h2 className="text-xl font-bold">Add Push Notification</h2>
        <Link
          to="/push-notifications"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      <PushNotificationForm onAdd={onAddOccupancy} />
    </div>
  );
}

export default AddPushNotification;
