import { ErrorMessage, Field, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { formatNumberToTwoDigit } from "../../../helpers/Helper";
import InputLabel from "../../components/inputs/InputLabel";
import { getAllStateOption } from "../../services/StateServices";
import PhoneNumberInput from "../../../components/inputFields/PhoneInput";
import SelectSearch from "../../components/inputs/SelectSearch";
import {
  checkUserCreditNote,
  checkUserMembership,
} from "../../services/BookingServices";
import { toast } from "react-toastify";
import Collapsible from "../../../hooks/Collapsible";

function UserDetailForm({
  setUserMembership,
  setUserCreditNote,
  selectedIndex,
  setSelectedIndex,
  proformaInvoice,
  setPhoneNumberExactDigits,
  verificationLink,
}) {
  const { values, setFieldValue } = useFormikContext();

  const [stateGst, setStateGst] = useState([]);

  useEffect(() => {
    getAllStateOption().then((res) => {
      setStateGst(res.data.data);
    });
  }, []);

  const countDots = (originalString) => {
    // Step 1: Remove text before the first space
    let indexOfSpace = originalString.indexOf(" ");
    let stringWithoutPrefix = originalString.slice(indexOfSpace + 1);

    // Step 2: Remove the dash "-"
    let stringWithoutDash = stringWithoutPrefix.replace(/\s/g, "");

    // Step 3: Count all dots
    let dots = stringWithoutPrefix.split(".").length - 1;
    return dots;
  };

  const checkMembership = () => {
    if (values.phone.length < 10) return;

    checkUserMembership({
      country_code: values.country_code,
      phone: values.phone,
    })
      .then((res) => {
        setUserMembership(res.data.data);
      })
      .finally(() => {
        checkUserCreditNote({
          country_code: values.country_code,
          phone: values.phone,
        })
          .then((res) => {
            setUserCreditNote(res.data.data);
          })
          .catch((err) => {
            toast.error(err.response.data.message);
          });
      });
  };
  return (
    <>
      <Collapsible
        heading="User Details"
        selectedIndex={selectedIndex}
        index={6}
        setSelectedIndex={setSelectedIndex}
      >
        <div className="rounded-lg border border-slate-200  bg-white  p-4 dark:border-slate-700 dark:bg-slate-800">
          <div className="my-4 grid grid-cols-12 items-end gap-4 lg:grid-cols-8">
            <div className="col-span-6 lg:col-span-2">
              <InputLabel label="First Name" required={true} />
              <Field className="field" name="first_name" />
              <ErrorMessage name="first_name">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="col-span-6 lg:col-span-2">
              <InputLabel label="Last Name" required={verificationLink} />
              <Field className="field" name="last_name" />
              <ErrorMessage name="last_name">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>

            <div className="col-span-12 lg:col-span-2">
              <PhoneNumberInput
                phone={`${values.country_code}${values.phone}`}
                onChange={(value, data) => {
                  setFieldValue(`country_code`, data.dialCode);
                  setFieldValue(`phone`, value.slice(data.dialCode.length));
                  setPhoneNumberExactDigits(countDots(data.format));
                }}
                onBlur={checkMembership}
              />
              <ErrorMessage name="phone">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>

            <div className="col-span-12 lg:col-span-2">
              <InputLabel label="Email" required={true} />
              <Field className="field" name="email" />
              <ErrorMessage name="email">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>

            <div className="col-span-12 lg:col-span-2">
              <InputLabel
                label="Firm Name"
                instruction="(in case of company)"
                required={proformaInvoice}
              />
              <Field className="field" name="firm_name" />
              <ErrorMessage name="firm_name">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>

            <div className="col-span-12 lg:col-span-2">
              <InputLabel label="Select State GST" />
              <SelectSearch
                options={stateGst?.map((state) => ({
                  label: `${formatNumberToTwoDigit(state.gst_code)} - ${
                    state.name
                  }`,
                  value: state.gst_code,
                }))}
                value={values.gst_state}
                onChange={(value) => setFieldValue("gst_state", value)}
              />

              <ErrorMessage name="gst_state">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="col-span-12 lg:col-span-2">
              <InputLabel label="GST Number" instruction="(if applicable)" />
              <Field
                className="field"
                name="gst_number"
                onChange={(e) => {
                  setFieldValue(`gst_number`, e.target.value);
                  // setFieldValue("gst_state", e.target.value.slice(0, 2));
                }}
              />
              <ErrorMessage name="gst_number">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="col-span-12 lg:col-span-2">
              <InputLabel
                label="Firm Address"
                instruction="(Registered with GST)"
              />
              <Field
                as="textarea"
                rows="2"
                className="field"
                name="firm_address"
              />
              <ErrorMessage name="firm_address">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="col-span-12 lg:col-span-2">
              <InputLabel
                label="CC Email Ids"
                instruction="(Comma Seperated)"
              />
              <Field as="textarea" rows="2" className="field" name="cc_email" />

              <ErrorMessage name="cc_email">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
          </div>
        </div>
      </Collapsible>
    </>
  );
}

export default UserDetailForm;
