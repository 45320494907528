import { useEffect } from "react";
import { useState } from "react";
import { getAllCountriesOption } from "../../../services/CountryServices";
import {
  createPackageCoupons,
  deletePackageCoupon,
  getCouponPackages,
  getItineraryCouponPrices,
  updatePackageCoupon,
} from "../../../services/CouponService";
import {
  getAllItinerariesByPackageId,
  getAllOccupancies,
  getAllPackages,
  getAllTravelModes,
  getPackagesByCountry,
  getPackagesByState,
  getPriceByItinerary,
} from "../../../services/PackageService";
import { getAllRidersOption } from "../../../services/RiderServices";
import { getAllStateByCountry } from "../../../services/StateServices";
import InputLabel from "../../inputs/InputLabel";

import { FaChevronDown, FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import SelectSearch from "../../inputs/SelectSearch";

function PackageCouponForm({ couponData }) {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [packages, setPackages] = useState([]);
  const [itineraries, setItineraries] = useState([]);
  const [prices, setPrices] = useState([]);

  const [travelModes, setTravelModes] = useState([]);
  const [occupancies, setOccupancies] = useState([]);
  const [riders, setRiders] = useState([]);

  const [discountPercent, setDiscountPercent] = useState("");
  const [maximumDiscount, setMaximumDiscount] = useState("");

  const [selectedCountry, setSelectedCountry] = useState(101);
  const [selectedState, setSelectedState] = useState("");
  const [selectedPkg, setSelectedPkg] = useState("");
  const [selectedItinerary, setSelectedItinerary] = useState();

  const [availableCoupons, setAvailableCoupons] = useState([]);

  const [activePackageToEdit, setActivePackageToEdit] = useState();
  const [activeItineraryToEdit, setActiveItineraryToEdit] = useState();
  const [activeIntineraryCouponPrices, setActiveIntineraryCouponPrices] =
    useState([]);

  const [discountPercentEditing, setDiscountPercentEditing] = useState("");
  const [maximumDiscountEditing, setMaximumDiscountEditing] = useState("");

  useEffect(() => {
    getAllPackages()
      .then((res) => {
        console.log(res.data.data);
        setPackages(res.data.data);
      })
      .catch((err) => {
        console.log(err, "ererererer============");
      });
  }, []);

  useEffect(() => {
    getAllCountriesOption().then((res) => {
      setCountries(res.data.data);
    });

    getAllTravelModes().then((res) => {
      setTravelModes(res.data.data);
    });

    getAllRidersOption().then((res) => {
      setRiders(res.data.data);
    });

    getAllOccupancies().then((res) => {
      setOccupancies(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (selectedCountry === "") return;

    if (selectedCountry === 101) {
      getAllStateByCountry(selectedCountry).then((res) => {
        setStates(res.data.data);
      });
    } else {
      getPackagesByCountry(selectedCountry).then((res) => {
        setPackages(res.data.data);
      });
    }
  }, [selectedCountry]);

  const handleStateChange = (stateId) => {
    setSelectedState(stateId);
    getPackagesByState(stateId).then((res) => {
      setPackages(res.data.data);
    });
  };

  const handlePackageChange = (packageId) => {
    setSelectedPkg(packageId);
    getAllItinerariesByPackageId(packageId).then((res) => {
      setItineraries(res.data.data);
    });
  };

  const handleItineraryChange = (itineraryId) => {
    setSelectedItinerary(itineraryId);
    getPriceByItinerary(itineraryId).then((res) => {
      setPrices(res.data.data.prices);
    });
  };

  useEffect(() => {
    let pricesCopy = [...prices];
    if (discountPercent) {
      pricesCopy.forEach((price, index) => {
        let discounted_price = (price.price * discountPercent) / 100;
        if (
          maximumDiscount > 0 &&
          discounted_price > 0 &&
          discounted_price > maximumDiscount
        ) {
          pricesCopy[index].discounted_price = price.price - maximumDiscount;
        } else {
          pricesCopy[index].discounted_price = price.price - discounted_price;
        }
        setPrices(pricesCopy);
      });
    } else if (maximumDiscount && maximumDiscount > 0) {
      pricesCopy.forEach((price, index) => {
        if (price.price > 0) {
          pricesCopy[index].discounted_price = price.price - maximumDiscount;
          setPrices(pricesCopy);
        }
      });
    }
  }, [discountPercent, maximumDiscount]);

  useEffect(() => {
    let pricesCopy = [...activeIntineraryCouponPrices];
    if (discountPercentEditing && discountPercentEditing > 0) {
      pricesCopy.forEach((price, index) => {
        let discounted_price = (price.price * discountPercentEditing) / 100;
        if (
          maximumDiscountEditing > 0 &&
          discounted_price > 0 &&
          discounted_price > maximumDiscountEditing
        ) {
          pricesCopy[index].discounted_price =
            price.price - maximumDiscountEditing;
        } else {
          pricesCopy[index].discounted_price = price.price - discounted_price;
        }
        setActiveIntineraryCouponPrices(pricesCopy);
      });
    } else if (maximumDiscountEditing && maximumDiscountEditing > 0) {
      pricesCopy.forEach((price, index) => {
        pricesCopy[index].discounted_price =
          price.price - maximumDiscountEditing;
        setActiveIntineraryCouponPrices(pricesCopy);
      });
    }
  }, [discountPercentEditing, maximumDiscountEditing]);

  const resetPackageCouponForm = () => {
    // setStates([]);
    // setPackages([]);
    setItineraries([]);
    setPrices([]);
    setDiscountPercent("");
    setMaximumDiscount("");
    // setSelectedCountry("");
    setSelectedPkg("");
  };

  const getLinkedPacakges = () => {
    getCouponPackages(couponData.id).then((res) => {
      setAvailableCoupons(res.data.data);
    });
  };

  useEffect(() => {
    getLinkedPacakges();
  }, []);

  const addPackageToCoupon = () => {
    let couponPackageData = [];

    prices.forEach((price) => {
      let data = {
        package_id: price.package_id,
        coupon_id: couponData.id,
        package_price_id: price.id,
        discounted_price: price.discounted_price,
        package_itinerary_id: selectedItinerary,
      };

      couponPackageData.push(data);
    });

    createPackageCoupons(couponPackageData).then((res) => {
      resetPackageCouponForm();
      getLinkedPacakges(res.data.data.id);
    });
  };

  const getItineraryCouponPrice = (couponId, itineraryId) => {
    console.log(couponId, "get couponprices coupon id");
    setActiveItineraryToEdit(itineraryId);

    getItineraryCouponPrices(couponId, itineraryId).then((res) => {
      setActiveIntineraryCouponPrices(res.data.data);
    });
  };

  const updatePackageCouponPrice = () => {
    updatePackageCoupon({
      coupon_pacakge_prices: activeIntineraryCouponPrices,
    }).then((res) => {
      toast.success(res.data.message);
      setActiveItineraryToEdit("");
      setActiveIntineraryCouponPrices([]);
      setMaximumDiscountEditing("");
      setDiscountPercentEditing("");
    });
  };

  const updateLinkedPackages = (id, couponId) => {
    let coupons = [...availableCoupons];
    let index = coupons.findIndex((cpn) => cpn.package_id === couponId);
    coupons.splice(index, 1);
    setAvailableCoupons(coupons);
  };

  const detachPackageFromCoupon = (id, couponId) => {
    console.log(id, couponId, "got it")
    deletePackageCoupon(id, couponId).then((res) => {
      console.log(res);
      toast.success(res.data.data);
      updateLinkedPackages(id, couponId);
    });
  };
  function rearrangeValues(obj) {
    const arr = [];
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        arr.push(obj[key]);
      }
    }
    return arr;
  }

  return (
    <div>
      <h2 className="mb-2 font-semibold">
        Available Packages for Coupon - {couponData.code}
      </h2>
      {availableCoupons?.length > 0 &&
        availableCoupons.map((coupon, index) => {
          console.log(coupon)
          return (
            <div key={index} className="mb-2">
              <div>
                <div className="rounded-lg border-2 px-4">
                  <div
                    onClick={() =>
                      setActivePackageToEdit(
                        activePackageToEdit === coupon.package_id
                          ? ""
                          : coupon.package_id
                      )
                    }
                    className="flex cursor-pointer justify-between"
                  >
                    <p className="py-2 font-semibold">
                      {coupon.package_id} :-
                      {coupon.package_title}
                    </p>
                    <button className="">
                      <FaChevronDown />
                    </button>
                  </div>
                  <ul
                    className={`list-disc pl-4 ${
                      activePackageToEdit === coupon.package_id ? "" : "hidden"
                    }`}
                  >
                    {coupon.itineraries.map((itinerary, index) => {
                      return (
                        <li key={index} className="">
                          <div className="flex items-center justify-between py-2">
                            <div className="flex items-center gap-12">
                              <p>
                                {itinerary.starting_location_name} to{" "}
                                {itinerary.ending_location_name}
                              </p>
                              <button
                                className=""
                                onClick={() =>
                                  getItineraryCouponPrice(
                                    couponData.id,
                                    itinerary.itinerary_id
                                  )
                                }
                              >
                                <FaEdit />
                              </button>
                            </div>
                            <button
                              onClick={() =>
                                detachPackageFromCoupon(
                                  itinerary.itinerary_id,
                                  couponData.id,
                                )
                              }
                              className="rounded-full border border-red-500 p-1 text-[10px] text-red-500"
                            >
                              <FaTrash />
                            </button>
                          </div>

                          {activeItineraryToEdit === itinerary.itinerary_id && (
                            <div>
                              <div className="mb-4 flex basis-full gap-4">
                                <div className="grow">
                                  <InputLabel label="Discount %" />
                                  <input
                                    type="number"
                                    name=""
                                    id=""
                                    className="field"
                                    value={discountPercentEditing}
                                    onChange={(e) => {
                                      setDiscountPercentEditing(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="grow">
                                  <InputLabel label="Maximum Discount" />
                                  <input
                                    type="number"
                                    name=""
                                    id=""
                                    className="field"
                                    value={maximumDiscountEditing}
                                    onChange={(e) => {
                                      setMaximumDiscountEditing(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="">
                                {activeIntineraryCouponPrices?.map(
                                  (price, index) => (
                                    <div key={index} className="relative mb-4">
                                      <div className="flex flex-wrap gap-4">
                                        <div className="grow basis-[15%]">
                                          <div className="field text-ellipsis whitespace-nowrap">
                                            {
                                              travelModes.filter(
                                                (tm) =>
                                                  tm.id === price.travel_mode_id
                                              )[0].name
                                            }
                                          </div>
                                        </div>

                                        <div className="grow basis-[15%]">
                                          <div className="field">
                                            {price.rider_id
                                              ? riders.filter(
                                                  (rider) =>
                                                    rider.id === price.rider_id
                                                )[0].name
                                              : "N/A"}
                                          </div>
                                        </div>

                                        <div className="grow basis-[15%]">
                                          <div className="field">
                                            {
                                              occupancies.filter(
                                                (occ) =>
                                                  occ.id === price.occupancy_id
                                              )[0].name
                                            }
                                          </div>
                                        </div>

                                        <div className="grow basis-[15%]">
                                          <div className="field">
                                            {price.price}
                                          </div>
                                        </div>
                                        <div className="grow basis-[15%]">
                                          <input
                                            type="text"
                                            className="field"
                                            value={price.discounted_price}
                                            onChange={(e) => {
                                              let pricesCopy = [
                                                ...activeIntineraryCouponPrices,
                                              ];
                                              pricesCopy[
                                                index
                                              ].discounted_price = parseInt(
                                                e.target.value
                                              );
                                              setActiveIntineraryCouponPrices(
                                                pricesCopy
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                                <div className="mb-4 text-center">
                                  <button
                                    className="btn-primary"
                                    onClick={updatePackageCouponPrice}
                                  >
                                    Update
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          );
        })}

      <h3 className="mb-2 mt-8 font-semibold">
        Add Packages to coupon-{couponData.code}
      </h3>

      <div className="rounded-lg border-2 p-4">
        <div className="flex flex-wrap gap-4">
          {/* //? Select Country */}
          {/* <div className="grow">
            <InputLabel label="Select Country" labelFor="country" />
            <SelectSearch
              value={parseInt(selectedCountry)}
              onChange={(value) => {
                // alert(value);
                setSelectedCountry(parseInt(value));
              }}
              options={countries?.map((country) => ({
                label: country.name,
                value: country.id,
              }))}
            />
          </div> */}

          {/* //? Select State */}
          {/* {selectedCountry == 101 && (
            <div className="grow">
              <InputLabel label="Select State" />

              <SelectSearch
                value={parseInt(selectedState)}
                onChange={(value) => {
                  // alert(value);
                  handleStateChange(parseInt(value));
                }}
                disabled={states?.length === 0}
                options={states?.map((state) => ({
                  label: state.name,
                  value: state.id,
                }))}
              />
              
            </div>
          )} */}

          {/* //? Select Package */}
          <div className="grow">
            <InputLabel label="Select Package" />
            <SelectSearch
              value={parseInt(selectedPkg)}
              onChange={(value) => {
                // alert(value);
                handlePackageChange(parseInt(value));
              }}
              disabled={packages?.length === 0}
              options={packages?.map((pkg) => ({
                label: `${pkg.id} :- ${pkg.title}`,
                value: pkg.id,
              }))}
            />
            {/* <select
              as="select"
              className="field"
              disabled={packages?.length === 0}
              onChange={(e) => {
                handlePackageChange(e.target.value);
              }}
            >
              <option value=""></option>
              {packages.map((pkg) => (
                <option key={pkg.id} value={pkg.id}>
                  {pkg.title}
                </option>
              ))}
            </select> */}
          </div>

          {/* //? Select Itinerary */}
          <div className="grow">
            <InputLabel label="Select Itinerary" />
            <select
              as="select"
              className="field"
              disabled={itineraries?.length === 0}
              onChange={(e) => {
                handleItineraryChange(e.target.value);
              }}
            >
              <option value=""></option>
              {itineraries.map((Itinerary) => (
                <option key={Itinerary.id} value={Itinerary.id}>
                  {Itinerary.starting_location_name} -{" "}
                  {Itinerary.ending_location_name}
                </option>
              ))}
            </select>
          </div>

          <div className="flex basis-full gap-4">
            <div className="grow">
              <InputLabel label="Auto fill Discount %" />
              <input
                type="text"
                name=""
                id=""
                className="field"
                value={discountPercent}
                onChange={(e) => {
                  setDiscountPercent(e.target.value);
                }}
              />
            </div>
            <div className="grow">
              <InputLabel label="Auto Fill Maximum Discount" />
              <input
                type="number"
                name=""
                id=""
                className="field"
                value={maximumDiscount}
                onChange={(e) => {
                  setMaximumDiscount(parseInt(e.target.value));
                }}
              />
            </div>
          </div>
        </div>

        <div className="basis-full">
          <table className="my-8 w-full">
            <tr>
              <th>Travel Mode</th>
              <th>Rider</th>
              <th>Occupancy</th>
              <th>Price</th>
              <th>Discounted Price</th>
            </tr>
            {prices?.map((price, index) => (
              <tr key={index} className="relative mb-4">
                {/* <div className="flex flex-wrap gap-4"> */}
                <td className="grow basis-[15%]">
                  <div className="field text-ellipsis whitespace-nowrap">
                    {
                      travelModes.filter(
                        (tm) => tm.id === price.travel_mode_id
                      )[0].name
                    }
                  </div>
                </td>

                <td className="grow basis-[15%]">
                  <div className="field">
                    {price.rider_id
                      ? riders.filter((rider) => rider.id === price.rider_id)[0]
                          .name
                      : "N/A"}
                  </div>
                </td>

                <td className="grow basis-[15%]">
                  <div className="field">
                    {
                      occupancies.filter(
                        (occ) => occ.id === price.occupancy_id
                      )[0].name
                    }
                  </div>
                </td>

                <td className="grow basis-[15%]">
                  <div className="field">{price.price}</div>
                </td>
                <td className="grow basis-[15%]">
                  <input
                    type="number"
                    className="field"
                    value={price.discounted_price}
                    onChange={(e) => {
                      let pricesCopy = [...prices];
                      pricesCopy[index].discounted_price = parseInt(
                        e.target.value
                      );
                      setPrices(pricesCopy || 0);
                    }}
                  />
                </td>
                {/* </div> */}
              </tr>
            ))}
          </table>
        </div>
        <div className="text-center">
          <button onClick={addPackageToCoupon} className="btn-primary">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default PackageCouponForm;
