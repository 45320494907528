import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaFilePdf } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { uploadPdf } from "../../services/CommonServices";
import { getUploadsUrl } from "../../../ServiceConfig";

function PdfInput({ path, allowedFiles, pdfFiles, setPdfFiles }) {
  const [files, setFiles] = useState([]);
  const [previewFile, setPreviewFile] = useState();

  const onChange = (file) => {
    uploadPdf({ pdf: file[0] }, path).then((res) => {
      setPdfFiles(res.data.data);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf", ".PDF"],
    },
    maxFiles: allowedFiles,
    onDrop: (acceptedFiles) => {
      let selectedFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      let allFiles = [...files, ...selectedFiles];
      setFiles(allFiles);
      onChange(allFiles);

      setPreviewFile(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        })
      );
    },
  });

  const deletePdf = (e, index) => {
    e.stopPropagation();
    let f = [...pdfFiles];
    f.splice(index, 1);
    setPdfFiles(f);
  };

  const thumbs = pdfFiles?.map((file, index) => (
    <div key={index} className="relative">
      <div
        onClick={(e) => deletePdf(e, index)}
        className="absolute -top-2 -right-2 cursor-pointer rounded-full border bg-white/50 p-1"
      >
        <MdClose />
      </div>
      <div
        onClick={(e) => e.stopPropagation()}
        className="flex rounded-md border p-1"
      >
        <a
          href={`${getUploadsUrl()}/${path}/${pdfFiles}`}
          target="_blank"
          className="flex min-w-0 flex-col items-center justify-center overflow-hidden"
          rel="noreferrer"
        >
          <div className="text-6xl">
            <FaFilePdf />
          </div>
          {file}
        </a>
      </div>
    </div>
  ));

  return (
    <section className="w-full">
      <div
        {...getRootProps({
          className: "dropzone flex justify-center",
        })}
      >
        <div className="flex min-h-[10rem] w-full max-w-full items-center justify-center rounded-lg border-2 border-dashed py-4 text-center">
          <div className="h-full w-full">
            {/* <img src={previewFile?.preview} alt="" className="mx-auto h-full" /> */}
            <aside className="flex flex-wrap gap-4 px-2">{thumbs}</aside>

            <input {...getInputProps()} />
            <p className="mt-4">
              Drag 'n' drop some files here, or click to select files
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PdfInput;
