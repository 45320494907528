import { admin } from "../../ServiceConfig";

export function getAllLeads() {
  return admin.get(`/lead`);
}

export function storeLead(lead) {
  return admin.post(`/lead`, lead);
}

export function importLeads(leads) {
  return admin.post(`/import-leads`, leads);
}

export function updatelead(lead) {
  return admin.put(`/lead/${lead.id}`, lead);
}

export function getLeadById(leadId) {
  return admin.get(`/lead/${leadId}`);
}

export function getLeadHistory(leadId) {
  return admin.get(`/lead/${leadId}/history`);
}

export function updateFollowUp(lead) {
  return admin.post(`/lead/followup/${lead.id}`, lead);
}

export function deleteLeadById(leadId) {
  return admin.delete(`/lead/${leadId}`);
}

export function storeLeadConditions(data) {
  return admin.post(`/lead-condition`, data);
}

export function updateLeadConditions(id, data) {
  return admin.put(`/lead-condition/${id}`, data);
}

export function deleteLeadCondition(id) {
  return admin.delete(`/lead-condition/${id}`);
}

export function getAllLeadColumn() {
  return admin.get(`/lead-columnname`);
}

export function bulkAssignLeads(data) {
  return admin.post(`/bulk-assignLead`, data);
}

export function bulkLeadStatusUpdate(data) {
  return admin.post(`bulk-updateStatus`, data);
}

export function bulkLeadDelete(data) {
  return admin.post(`/leadBulk-delete`, data);
}

export function getLeadCountByStatus(start_date, end_date) {
  return admin.post(`/lead-count/status`, { start_date, end_date });
}

export function getLeadCountByCategory(start_date, end_date) {
  return admin.post(`/lead-count/category`, {
    start_date,
    end_date,
  });
}

export function getLeadCountBySource(start_date, end_date) {
  return admin.post(`/lead-count/source`, {
    start_date,
    end_date,
  });
}
export function leadCountByPerDay(start_date, end_date) {
  return admin.post(`/leadCountByPerDay`, {
    start_date,
    end_date,
  });
}

export function getLeadStatus() {
  return admin.get(`/lead-status`);
}

export function getAllLeadStatus() {
  return admin.get(`/all-lead-status`);
}

export function updateLeadStatus(data) {
  return admin.post(`/lead-status`, data);
}

export function deleteLeadStatus(id) {
  return admin.delete(`/lead-status/${id}`);
}

export function getAllLeadCategory() {
  return admin.get(`/lead-category`);
}

export function updateLeadCategory(data) {
  return admin.post(`/lead-category`, data);
}

export function deleteLeadCategory(id) {
  return admin.delete(`/lead-category/${id}`);
}

export function getLeadSource() {
  return admin.get(`/lead-source`);
}

export function getAllLeadSource() {
  return admin.get(`/all-lead-source`);
}

export function updateLeadSource(data) {
  return admin.post(`/lead-source`, data);
}

export function deleteLeadSource(id) {
  return admin.delete(`/lead-source/${id}`);
}

export function getLeadConditionById(id) {
  return admin.get(`/lead-condition/${id}`);
}

export function getLeadStatusGraphData(id) {
  return admin.get(`/lead-condition/${id}`);
}

export function getLeadSourceGraphData(id) {
  return admin.get(`/lead-condition/${id}`);
}

export function getLeadCategoryGraphData(id) {
  return admin.get(`/lead-condition/${id}`);
}

export function generateReport(data) {
  return admin.post(`/generate-report`, data);
}

export function submitReport(data) {
  return admin.post(`/submit-report`, data);
}

export function leadvsBooking(data){
  return admin.post(`/leadvsbookingCount`, data);
}
