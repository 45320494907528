import FileInput from "../../../components/inputFields/FileInput";

import InputLabel from "../inputs/InputLabel";
import { toast } from "react-toastify";

// -----------------------------------------------------------
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import EditorInput from "../inputs/EditorInput";
import {
  createThingsToKnow,
  updateThingsToKnow,
  uploadThingsToKnowImage,
} from "../../services/ThingsToKnowServices";
import { getUploadsUrl } from "../../../ServiceConfig";

const ttkFormSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  description: Yup.string().required("Required"),
  image: Yup.string().required("Required"),
});

function ThingsToKnowForm({ formData, edit, onSuccess }) {
  // ? Add new ttk

  const addTtk = (values) => {
    createThingsToKnow(values)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  // ? Update ttk
  const editTtk = (values) => {
    updateThingsToKnow(formData.id, values)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div>
      <Formik
        initialValues={{
          image: formData?.image,
          title: formData?.title,
          description: formData?.description,
        }}
        validationSchema={ttkFormSchema}
        onSubmit={(values) => {
          // console.log(values);
          edit ? editTtk(values) : addTtk(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="flex flex-wrap gap-4">
              {/* //? Banner Image */}
              <div className="grow">
                <FileInput
                  value={`${getUploadsUrl()}/things-to-know/${values.image}`}
                  noOfFilesAllowed={1}
                  onChange={(file) => {
                    uploadThingsToKnowImage({ image: file }).then((res) => {
                      setFieldValue("image", res.data.data[0].uploaded_image);
                    });
                  }}
                />
              </div>

              <div className="w-full grow lg:w-auto">
                <div className="flex flex-wrap gap-4">
                  <div className="grow basis-[45%]">
                    <InputLabel label="Title" labelFor="name" />
                    <Field className="field" id="name" name="title" />
                    <ErrorMessage name="title">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="my-4">
                  <InputLabel label="Description" labelFor="description" />
                  <EditorInput
                    fieldKey={`description`}
                    data={values?.description}
                  />
                  <ErrorMessage name="description">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
            </div>

            <div className="mt-4 text-center">
              <button type="submit" className="btn-primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ThingsToKnowForm;
