import { useState } from "react";
import { useEffect } from "react";
import { FaRupeeSign } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { getReadableDate } from "../../helpers/Helper";
import { getUploadsUrl } from "../../ServiceConfig";
import {
  getBookingById,
  getVerificationLinkById,
  rejectVerificationLink,
  verifyVerificationLink,
} from "../services/BookingServices";
import {
  getAllEmployee,
  getAllEmployeesOption,
} from "../services/EmployeeServices";
import { getAllOccupancies } from "../services/PackageService";
import { getAllRidersOption } from "../services/RiderServices";
import { getAllStateByCountry } from "../services/StateServices";
import { getAllTravelModesOption } from "../services/TravelModeServices";
import UpdateParticipants from "./UpdateParticipants";

import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import UpdatePaymentReciept from "./UpdatePaymentReciept";
import DataTable from "../components/common/DataTable";
import useAdminAuth from "../hooks/useAdminAuth";

Modal.setAppElement("#modal");

function ViewVerificationLink() {
  const bookingId = useParams().id;

  const navigate = useNavigate();

  const [bookingData, setBookingData] = useState();
  const [states, setStates] = useState();
  const [travelModes, setTravelModes] = useState();
  const [riders, setRiders] = useState();
  const [occupancies, setOccupancies] = useState();
  const [employees, setEmployees] = useState();
  const [participantModalIsOpen, setParticipantModalIsOpen] = useState(false);
  const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);

  const [recieptToUpdate, setRecieptToUpdate] = useState();
  const [activeParticipantData, setActiveParticipantData] = useState();
  const [particpantIdModalIsOpen, setParticpantIdModalIsOpen] = useState(false);
  const { auth, setAuth } = useAdminAuth();

  const getVerficationLinkData = () => {
    getVerificationLinkById(bookingId).then((res) => {
      setBookingData(res.data.data);
    });
  };

  useEffect(() => {
    getVerficationLinkData();
  }, [bookingId]);

  useEffect(() => {
    getAllTravelModesOption().then((res) => {
      setTravelModes(res.data.data);
    });

    getAllRidersOption().then((res) => {
      setRiders(res.data.data);
    });

    getAllOccupancies().then((res) => {
      setOccupancies(res.data.data);
    });

    getAllEmployeesOption().then((res) => {
      setEmployees(res.data.data.data);
    });
  }, []);

  useEffect(() => {
    if (!bookingData?.country_id) return;
    getAllStateByCountry(bookingData?.country_id).then((res) => {
      setStates(res.data.data);
    });
  }, [bookingData]);

  useEffect(() => {
    recieptToUpdate && setPaymentModalIsOpen(true);
  }, [recieptToUpdate]);

  const getEmployeeName = (empId) => {
    let name = "";
    let emp = employees?.filter((emp) => emp.id === empId);
    name = `${emp?.[0]?.first_name} ${emp?.[0]?.last_name}`;
    return name;
  };

  const getUpdatedData = () => {
    getBookingById(bookingId).then((res) => {
      setBookingData(res.data.data);
    });
  };

  useEffect(() => {
    activeParticipantData && setParticpantIdModalIsOpen(true);
  }, [activeParticipantData]);

  const verifyPaymentAndCreateReceipt = (e) => {
    e.target.disabled = true;
    verifyVerificationLink(bookingId)
      .then((res) => {
        navigate(`/bookings/${res.data.data.id}`);
      })
      .catch((err) => {
        e.target.disabled = false;
      });
  };

  const rejectAndSendForReview = () => {
    rejectVerificationLink(bookingId).then((res) => {
      getVerficationLinkData();
    });
  };

  const getVerificationStatus = (code) => {
    if (code === 0) return "User Verification Awaited";
    if (code === 1) return "User Verified";
    if (code === 2) return "Rejected";
    if (code === 3) return "Verified";
  };

  const viewBooking = () => {
    if (!bookingData?.booking_id) return;
    navigate(`/bookings/${bookingData?.booking_id}`);
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Membership",
      field: "package_name",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Name",
      minWidth: "100",
      field: "event_date",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return `${cell.getRow().getData().first_name} ${
          cell.getRow().getData().last_name
        }`;
      },
    },
    {
      title: "Email",
      minWidth: "100",
      field: "email",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Phone",
      field: "phone",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Whatsapp",
      field: "whatsapp",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Age",
      field: "age",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
    },
    {
      title: "Gender",
      field: "gender",
      resizable: true,
      headerSort: true,
      vertAlign: "middle",
    },
    {
      title: "Travel Mode",
      field: "travel_mode_id",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return travelModes?.filter(
          (tm) => tm.id === cell.getRow().getData().travel_mode_id
        )[0]?.name;
      },
    },
    {
      title: "Occupancy",
      field: "status",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return occupancies?.filter(
          (occ) => occ.id === cell.getRow().getData().occupancy_id
        )[0]?.name;
      },
    },
    {
      title: "Eme. Contact",
      field: "emergency_contact",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Relation",
      field: "emergency_contact_relation",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Id Proof",
      field: "id_proof",
      resizable: true,
      vertAlign: "middle",
      formatter: "image",
      formatterParams: {
        height: "50px",
        width: "80px",
        urlPrefix: `${getUploadsUrl()}/bookings/participants/ids/`,
      },
    },
    {
      title: "Medical Certificate",
      field: "medical_certificate",
      resizable: true,
      vertAlign: "middle",
      formatter: "image",
      formatterParams: {
        height: "50px",
        width: "80px",
        urlPrefix: `${getUploadsUrl()}/bookings/participants/medical-certifcates/`,
      },
    },
    {
      title: "Covid Certificate",
      field: "covid_certificate",
      resizable: true,
      vertAlign: "middle",
      formatter: "image",
      formatterParams: {
        height: "50px",
        width: "80px",
        urlPrefix: `${getUploadsUrl()}/bookings/participants/covid-certifcates/`,
      },
    },
    // {
    //   title: "Resend Declaration",
    //   formatter: actionIcons,
    //   headerSort: false,
    //   width: 80,
    //   responsive: 0,
    //   hozAlign: "center",
    //   vertAlign: "middle",
    // },
  ];

  return (
    <div className="p-4">
      <div className="my-4 flex items-center justify-between">
        <h2 className="text-xl font-bold">
          Verification Link : #{bookingData?.id}
        </h2>
        {/* <div>
          <Link
            to={`/bookings/verification-links/edit/${bookingData?.id}`}
            className="btn-primary"
          >
            Edit Verification Link
          </Link>
        </div> */}
      </div>

      <div className="rounded-lg border-2 p-4">
        <h2 className="border-b-2 pb-4 text-xl font-bold">Billing Info</h2>
        <div className="my-4 flex flex-wrap items-center">
          <div className="my-2 basis-1/3">
            <b>Name : </b> {bookingData?.first_name} {bookingData?.last_name}
          </div>
          <div className="my-2 basis-1/3">
            <b>Email : </b> {bookingData?.email}
          </div>
          <div className="my-2 basis-1/3">
            <b>Phone : </b> {bookingData?.phone}
          </div>
          <div className="my-2 basis-1/3">
            <b>Company : </b> {bookingData?.firm_name}
          </div>
          <div className="my-2 basis-1/3">
            <b>GST Number : </b> {bookingData?.gst_number}
          </div>
          <div className="my-2 basis-1/3">
            <b>Address : </b> {bookingData?.firm_address}
          </div>
          <div className="my-2 basis-1/3">
            <b>State : </b>{" "}
            {
              states?.filter((state) => state.id === bookingData.state_id)[0]
                ?.name
            }
          </div>
        </div>
      </div>

      <div className="my-4 rounded-lg border-2 p-4">
        <h2 className="border-b-2 pb-4 text-xl font-bold">Package Info</h2>
        <div className="my-4 flex flex-wrap items-center capitalize">
          <div className="items my-2 flex basis-full justify-between gap-8">
            {!bookingData?.is_customized && (
              <div className="">
                <b>Package Id: </b> {bookingData?.package_id}
              </div>
            )}
            <div className="grow">
              <b>Package : </b> {bookingData?.package_name}
            </div>
          </div>
          <div className="my-2 basis-1/3">
            <b>Event Date : </b> {getReadableDate(bookingData?.event_date)}
          </div>
          <div className="my-2 basis-1/3">
            <b>Pick Drop Location : </b> {bookingData?.starting_location} to{" "}
            {bookingData?.ending_location}
          </div>
          <div className="my-2 basis-1/3">
            <b>Number of days : </b> {bookingData?.no_of_days}{" "}
          </div>
          <div className="my-2 basis-full">
            <b>Special Notes : </b>
          </div>
          <div className="my-2 basis-full">
            <b>Custom Itinerary : </b>
          </div>
        </div>
      </div>

      <div className="my-4 rounded-lg border-2 p-4">
        <h2 className="border-b-2 pb-4 text-xl font-bold">Cost Calculation</h2>
        <div className="my-4">
          <table className="w-full text-center">
            <thead>
              <tr className="border-b bg-gray-200">
                <th className="py-2">Travel Mode</th>
                <th className="py-2">Occupancy</th>
                <th className="py-2">Price</th>
                <th className="py-2">Discount</th>
                <th className="py-2">Final</th>
                <th className="py-2">GST</th>
                <th className="py-2">With GST</th>
                <th className="py-2">Wraveler</th>
                <th className="py-2">Amount</th>
              </tr>
            </thead>
            <tbody>
              {bookingData?.cost?.map((booking, index) => {
                return (
                  <tr
                    key={booking.id}
                    className={`border-b ${
                      index % 2 === 0 ? "" : "bg-gray-50"
                    }`}
                  >
                    <td className="py-2">
                      {booking?.travel_mode?.name}
                      {/* {
                        travelModes?.filter(
                          (tm) => tm.id === booking?.travel_mode_id
                        )[0]?.name
                      } */}
                    </td>
                    <td className="py-2">
                      {
                        booking?.occupancy?.name
                        // occupancies?.filter(
                        //   (occ) => occ.id === booking.occupancy_id
                        // )[0]?.name
                      }
                    </td>
                    <td className="py-2">{booking?.package_price}</td>
                    <td className="py-2">{booking?.package_discount}</td>
                    <td className="py-2">
                      {booking?.package_price_after_discount}
                    </td>
                    <td className="py-2">{booking?.package_gst}</td>
                    <td className="py-2">{booking?.package_with_gst}</td>
                    <td className="py-2">{booking?.pax}</td>
                    <td className="py-2">{booking?.amount}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="my-4 rounded-lg border-2 p-4">
        <div className="flex items-center justify-between border-b-2 pb-4">
          <h2 className="text-xl font-bold">Payments</h2>
        </div>
        <div className="my-4">
          <div className="flex justify-between">
            <div className="max-w-[500px] grow basis-1/2">
              <table className="w-full">
                <tbody>
                  <tr className="border-b">
                    <td className="py-2 font-bold">Total Amount to Pay</td>
                    <td className="flex items-center gap-1 py-2">
                      <FaRupeeSign />
                      {bookingData?.total_amount_to_pay}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2 font-bold">Amount Paid</td>
                    <td className="flex items-center gap-1 py-2">
                      <FaRupeeSign />
                      {bookingData?.total_amount_paid}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 font-bold">Remaining Amount to Pay</td>
                    <td className="flex items-center gap-1 py-2">
                      <FaRupeeSign />
                      {bookingData?.remaining_amount}
                    </td>
                  </tr>

                  <tr>
                    <td className="py-2 font-bold">Payment Source / Method</td>
                    <td className="flex items-center gap-1 py-2">
                      {bookingData?.payment[0]?.payment_method}
                    </td>
                  </tr>

                  <tr>
                    <td className="py-2 font-bold">Transaction Id</td>
                    <td className="flex items-center gap-1 py-2">
                      {bookingData?.payment[0]?.transaction_id}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 font-bold">Payment Screenshot</td>
                    <td className="flex items-center gap-1 py-2">
                      {bookingData?.payment[0]?.screenshot && (
                        <img
                          src={`${getUploadsUrl()}/bookings/payment-screenshot/${
                            bookingData?.payment[0]?.screenshot
                          }`}
                          alt=""
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="py-2 font-bold">Lead Converted By</td>
                    <td className="flex items-center gap-1 py-2">
                      {bookingData?.converted_by}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex flex-col gap-1">
              <button
                onClick={viewBooking}
                className={`rounded-lg border py-2 px-4  ${
                  bookingData?.booking_status === 2
                    ? "border-red-500 text-red-500"
                    : "border-blue-500 text-blue-500"
                }`}
              >
                Status : {getVerificationStatus(bookingData?.booking_status)}{" "}
                {bookingData?.booking_status === 3 &&
                  `#${bookingData?.booking_id} Check Now`}
              </button>
              {(auth?.permissions?.includes("PaymentVerification.approve") ||
                auth?.roles?.includes("Superadmin")) && (
                <>
                  {bookingData?.booking_status === 1 && (
                    <>
                      <button
                        onClick={verifyPaymentAndCreateReceipt}
                        className="my-2 rounded-lg bg-blue-500 px-4 py-2 text-white disabled:bg-blue-300"
                      >
                        Verify Payment & create Payment Receipt
                      </button>
                      <button
                        onClick={rejectAndSendForReview}
                        className="rounded-lg bg-red-500 px-4 py-2 text-white"
                      >
                        Reject Payment & send for Review
                      </button>
                    </>
                  )}
                  {bookingData?.booking_status === 2 && (
                    <>
                      <button
                        onClick={verifyPaymentAndCreateReceipt}
                        className="my-2 rounded-lg bg-blue-500 px-4 py-2 text-white disabled:bg-blue-300"
                      >
                        Verify Payment & create Payment Receipt
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="my-4 rounded-lg border-2 p-4">
        <div className="flex items-center justify-between border-b-2 pb-4">
          <h2 className=" text-xl font-bold">Participant Detail</h2>
        </div>
        <div className="my-4">
          <DataTable
            columns={columns}
            tableData={bookingData?.participants}
            height="auto"
            pagination={false}
            responsiveLayout="collapse"
          />
        </div>
      </div>

      <Modal
        isOpen={participantModalIsOpen}
        contentLabel="Update Participant Details"
        onAfterClose={getUpdatedData}
      >
        <div className="relative p-4">
          <h2 className="mb-4 text-xl font-bold">Update Participants Detail</h2>
          <button
            onClick={() => setParticipantModalIsOpen(false)}
            className="absolute right-4 top-4 rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
          <UpdateParticipants
            bookingData={bookingData}
            participants={bookingData?.participants}
            travelModes={travelModes}
            riders={riders}
            occupancies={occupancies}
            setModalIsOpen={setParticipantModalIsOpen}
          />
        </div>
      </Modal>

      <Modal
        isOpen={paymentModalIsOpen}
        onAfterClose={() => {
          getUpdatedData();
          setRecieptToUpdate(null);
        }}
        contentLabel="Update Payment Reciept"
        className="absolute top-1/2 left-1/2 h-fit min-w-[50%] max-w-[90%] -translate-y-1/2 -translate-x-1/2 rounded-lg bg-white"
      >
        <div className="relative p-4">
          <h2 className="mb-4 text-xl font-bold">
            Update Payment Reciept #{recieptToUpdate?.id}
          </h2>
          <button
            onClick={() => setPaymentModalIsOpen(false)}
            className="absolute right-4 top-4 rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
          <UpdatePaymentReciept
            setModalIsOpen={setPaymentModalIsOpen}
            receipt={recieptToUpdate}
          />
        </div>
      </Modal>

      <Modal
        isOpen={particpantIdModalIsOpen}
        onAfterClose={() => setActiveParticipantData(null)}
        contentLabel="Update Payment Reciept"
        className="absolute top-1/2 left-1/2 h-fit max-w-[90%] -translate-y-1/2 -translate-x-1/2 rounded-lg bg-white"
      >
        <div className="relative p-4">
          <h2 className="mb-4 text-xl font-bold">
            {activeParticipantData?.first_name}{" "}
            {activeParticipantData?.last_name}
            's ID Proof
          </h2>
          <button
            onClick={() => setParticpantIdModalIsOpen(false)}
            className="absolute right-4 top-4 rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
          <div className="rounded-lg border-2">
            <img
              src={`${getUploadsUrl()}/bookings/participants/ids/${
                activeParticipantData?.id_proof
              }`}
              alt=""
              className="max-h-[80vh] rounded-lg"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ViewVerificationLink;
