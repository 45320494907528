import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";

import OutsideClick from "../../../hooks/OutsideClick";
import { Calendar } from "react-date-range";
import { FaCalendarAlt } from "react-icons/fa";
import { useState } from "react";
import { getReadableDate } from "../../../helpers/Helper";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { useEffect } from "react";

const DateInput = forwardRef((props, ref) => {
  const [date, setDate] = useState();
  const [isDateRangeActive, setIsDateRangeActive] = useState(false);
  //   const [startDate, setStartDate] = useState(props.startDate);
  //   const [endDate, setEndDate] = useState(props.endDate);

  useImperativeHandle(ref, () => ({
    resetDates() {},
  }));

  const handleDateChange = (item) => {
    setDate(item);
    props.onDateChange(item);
    setIsDateRangeActive(false);
  };

  useEffect(() => {
    props.value && setDate(props.value);
  }, [props.value]);

  return (
    <div>
      <OutsideClick onClick={() => setIsDateRangeActive(false)}>
        <div
          className={`relative rounded-md ${
            isDateRangeActive ? "z-30" : "z-0"
          }`}
        >
          {props.disabled && (
            <div className="absolute top-0 left-0 h-full w-full rounded-md bg-gray-500/10"></div>
          )}
          <div
            onClick={() => setIsDateRangeActive(!isDateRangeActive)}
            className="flex w-full items-center rounded-md border py-1 px-2"
          >
            <div className="grow">{date ? getReadableDate(date) : "Select a date"}</div>
            <div>
              <FaCalendarAlt />
            </div>
          </div>
          <div
            className={`absolute left-0 top-full overflow-hidden rounded-md transition-all duration-300 ${
              isDateRangeActive ? "z-10 h-fit border-2" : "z-0 h-0"
            }`}
          >
            <Calendar
              minDate={props.minDate}
              maxDate={props.maxDate}
              onChange={handleDateChange}
            />
          </div>
        </div>
      </OutsideClick>
    </div>
  );
});

export default DateInput;
