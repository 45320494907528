import React, { useState } from "react";
import { MdPassword } from "react-icons/md";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { motion } from "framer-motion";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import PasswordInput from "../../components/inputFields/PasswordInput";
import H2 from "../../components/headings/H2";
import { Link, useNavigate, useParams } from "react-router-dom";
import OtpInput from "../../components/inputFields/OtpInput";
import { useRef } from "react";
import UserAuthImage from "../../assets/image/User/justwravel-user-auth.png";
import {
  checkResetPasswordToken,
  resetPassword,
} from "../services/AdminServices";
import { Formik, Form, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import { useEffect } from "react";
import * as Yup from "yup";

function ResetPassword() {
  const passwordSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confirm_password: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  const navigate = useNavigate();

  const token = useParams().token;

  const [isTokenValid, setIsTokenValid] = useState();

  useEffect(() => {
    checkResetPasswordToken({ token: token })
      .then((res) => {
        if (res.data.status === "success") {
          setIsTokenValid("valid");
        }
      })
      .catch((err) => {
        setIsTokenValid("invalid");
      });
  }, []);

  const handleResetPassword = (values) => {
    resetPassword(values)
      .then((res) => {
        navigate(`/login`);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <motion.div
      className="w-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3, delay: 0.3 } }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <div className="flex flex-col gap-4 py-4 lg:min-h-[calc(100vh-3rem)] lg:flex-row">
        <div className="after:bg-jw-green-dark/25 relative flex grow basis-1/2 items-center p-4 after:absolute after:top-1/2 after:right-0 after:hidden after:h-3/4 after:w-1 after:-translate-y-1/2 after:content-[''] lg:px-8 after:lg:block">
          <img
            src={UserAuthImage}
            alt=""
            className="mx-auto w-3/4 drop-shadow-lg"
          />
        </div>
        <div className="flex grow items-center justify-center">
          {isTokenValid === "valid" && (
            <div className="w-full px-4 lg:px-16">
              <H2 className={"mb-4 text-center"}>Reset Password</H2>

              <Formik
                //   innerRef={formRef}
                initialValues={{
                  password: "",
                  confirm_password: "",
                  token: token,
                }}
                validationSchema={passwordSchema}
                onSubmit={(values) => {
                  // alert(JSON.stringify(values));
                  handleResetPassword(values);
                }}
              >
                {({ values, setFieldValue, errors, touched }) => (
                  <Form>
                    <div>
                      <div className="my-4">
                        <PasswordInput
                          id="password"
                          placeholder="New Password*"
                          value={values.password}
                          onChange={(e) =>
                            setFieldValue("password", e.target.value)
                          }
                        />
                        <ErrorMessage name="password">
                          {(msg) => <div className="error-msg">{msg}</div>}
                        </ErrorMessage>
                      </div>

                      <div>
                        <PasswordInput
                          id="confirm_password"
                          value={values.confirm_password}
                          placeholder="Confirm New Password*"
                          onChange={(e) =>
                            setFieldValue("confirm_password", e.target.value)
                          }
                        />
                        <ErrorMessage name="confirm_password">
                          {(msg) => <div className="error-msg">{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="mt-4 text-center">
                      <button className="btn-primary w-full" type="submit">
                        Reset Password
                      </button>
                    </div>
                    <Link
                      to="/login"
                      className="mt-4 block text-center font-semibold text-jw-green dark:text-white"
                    >
                      Want to Try Login Again? <u>Login Here!</u>
                    </Link>
                  </Form>
                )}
              </Formik>
            </div>
          )}
          {isTokenValid === "invalid" && (
            <div className="w-full px-4 lg:px-16">
              <H2 className={"mb-4 text-center"}>Token Expired!!!</H2>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default ResetPassword;
