import { Link, useNavigate } from "react-router-dom"; 
// import MetaTags from "../../../web/layouts/MetaTags";
import ComponentHeader from "../components/common/ComponentHeader";
import H2 from "../../components/headings/H2";
import { FaListAlt } from "react-icons/fa";
import B2BForm from "./B2BForm";
import { Helmet } from "react-helmet";
import MetaTags from "../components/Metatags";
import { useEffect, useState } from "react";
import { checkPermission } from "../services/AdminServices";
import NotAuthorized403 from "../components/NotAuthorized403";
import { toast } from "react-toastify";
import useLoader from "../../hooks/useLoader";
import { getAllLocationByCountryId } from "../services/LocationServices";

function AddB2B() {
  const navigate = useNavigate();
  const [authorized, setAuthorized] = useState(false);

  const { setLoading } = useLoader();

  useEffect(() => {
    setLoading(true);
    checkPermission("B2B.create")
      .then((res) => {
        setAuthorized(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });

     
  }, []);
  const onAddState = (data) => {
    navigate(`/b2b`);
  };

  return !authorized ? (
    <NotAuthorized403 />
  ) : (
    <div className="">
      <MetaTags title="Add Employee | Admin Panel" />
      {/* <Helmet>
        <title>Add Employee</title>
      </Helmet> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Add Business</H2>
        <Link
          to="/b2b"
          className="btn-primary flex dark:bg-green-800 items-center gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>

      <B2BForm onSuccess={(data) => onAddState(data)} />
    </div>
  );
}

export default AddB2B;
