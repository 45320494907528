import React, { useEffect, useState } from 'react'
import ComponentHeader from '../../components/common/ComponentHeader'
import H2 from '../../../components/headings/H2'
import SelectSearch from '../../components/inputs/SelectSearch'
import { getAllCategories } from '../../services/CategoryServices'
import InputLabel from '../../components/inputs/InputLabel'
import { getBatchesByPackageIdandMonth, getFDTrekparticipants, getMonthsWithBatchesOfPackage, getPackagesByCategoryId } from '../../services/PackageService'
import moment from 'moment/moment'
import TableofParticipants from './TableofParticipants'

const ShowtrekCertificate = () => {

  const [allCtaegories, setAllCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState()

  const [allPackages, setAllpackages] = useState([])
  const [selecctedPackage, setSelectedPackage] = useState()

  const [monthArray, setMonthArray] = useState([])
  const [selectedMonth, setSelectedmOnth] = useState()

  const [batchesArray, setBatchesArray] = useState([])
  const [selectedBatch, setSelectedBatch] = useState()

  const [participants, setParticipants] = useState([])

  const getCategories = () => {
    getAllCategories().then(res => {
      setAllCategories(res.data.data.data.map((oneCat) => ({ value: oneCat.id, label: oneCat.name })))
      setSelectedCategory(1)
    }).catch(er => { console.log(er, "error in getting Categories") })
  }

  const getAllPackagesByCategory = (catid) => {
    getPackagesByCategoryId(catid).then((res) => {
      setAllpackages(res.data.data.map((onepkg) => ({ value: onepkg.id, label: onepkg.title })))
    }).catch((er) => {
      console.log(er, "error in getting pcakges")
    })
  }

  const getAllMonthswithBatches = (data) => {
    getMonthsWithBatchesOfPackage(data).then(res => {
      setMonthArray(res.data.data.map((singleMonth) => ({ value: singleMonth, label: moment(singleMonth, 'YYYY-MM-DD').format('MMMM YYYY') })))
    }).catch(er => { console.log(er) })
  }

  const getAllBatches = () => {
    getBatchesByPackageIdandMonth({ month: selectedMonth, package_id: selecctedPackage }).then(res => {
      setBatchesArray(res.data.data.map(onebatch => ({ value: onebatch, label: moment(onebatch).format('DD MMMM YYYY') })))
    }).catch(er => { console.log(er, "errror in getting batches") })
  }

  const getAllParticipants = () => {
    getFDTrekparticipants(selecctedPackage, selectedBatch).then(res => { setParticipants(res.data.data.data) }).catch(er => { console.log(er, "error in getting Fd trek participants") })
  }

  useEffect(() => {
    getCategories()
  }, [])

  useEffect(() => {
    if (selectedCategory) {
      getAllPackagesByCategory(selectedCategory)
    }
  }, [selectedCategory])

  useEffect(() => {
    if (selecctedPackage) {
      setSelectedmOnth()
      getAllMonthswithBatches({ package_id: selecctedPackage })
    }
  }, [selecctedPackage])

  useEffect(() => {
    if (selecctedPackage && selectedMonth) {
      setSelectedBatch()
      getAllBatches()
    }
  }, [selecctedPackage, selectedMonth])

  useEffect(() => {
    if (selecctedPackage && selectedMonth && selectedBatch) {
      setParticipants([])
      getAllParticipants()
    }
  }, [selectedBatch])

  return (
    <div>
      <ComponentHeader className="border-b">
        <H2>Trek Certificates</H2>
      </ComponentHeader>
      <div className='flex lg:flex-row flex-col lg:gap-6 gap-2 mt-6'>
        <div className='flex-1'>
          <InputLabel label='Select Category' />
          <SelectSearch
            options={allCtaegories}
            value={1}
            disabled={true}
            onChange={(e) => {
              setSelectedCategory(e)
            }}
          />
        </div>
        <div className='flex-1'>
          <InputLabel label='Select Package' />
          <SelectSearch
            options={allPackages}
            value={selecctedPackage}
            onChange={(e) => {
              setSelectedPackage(e)
            }}
          />
        </div>
        <div className='flex-1'>
          <InputLabel label='Select Month' />
          <SelectSearch
            options={monthArray}
            value={selectedMonth}
            onChange={(e) => {
              setSelectedmOnth(e)
            }}
          />
        </div>
        {(selecctedPackage && selectedMonth) && <div className='flex-1'>
          <InputLabel label='Select Batch' />
          <SelectSearch
            options={batchesArray}
            value={selectedBatch}
            onChange={(e) => {
              setSelectedBatch(e)
            }}
          />
        </div>}
      </div>
      {selectedBatch && <TableofParticipants participants={participants} onSuccess={getAllParticipants} />}
    </div>
  )
}

export default ShowtrekCertificate