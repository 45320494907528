import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import DataTable from "../common/DataTable";
import { getAdminUrl } from "../../../ServiceConfig";
import ComponentHeader from "../common/ComponentHeader";
import { FaPlusSquare } from "react-icons/fa";

import {
  formatNumberToTwoDigit,
  monthShortNames,
} from "../../../helpers/Helper";
import { deletePushNotification } from "../../services/PushNotificationServices";

function PushNotificationList() {
  const navigate = useNavigate();
  const PushNotificationTable = useRef();

  const deleteSelectedNotification = (id) => {
    deletePushNotification(id)
      .then((res) => {
        toast.success(res.data.message);
        PushNotificationTable.current.deleteRow(id);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const actionIcons = function (cell, formatterParams, onRendered) {
    if (cell.getRow().getData().sent_status) {
      return "";
    } else {
      return `<div class="notification-edit-btn" data-id=${
        cell.getRow().getData().id
      } style="display: flex; align-items: center; gap: 12px;">
    <div style="cursor: pointer; title="Edit">
    <svg
      x="0px"
      y="0px"
      width="16px"
      height="16px"
      viewBox="0 0 497.182 497.182"
      xml:space="preserve"
    >
      <g>
        <path
          d="M376.956,237.143l58.504-57.637v255.468c0,34.352-27.852,62.207-62.207,62.207H62.209
  c-34.352,0-62.207-27.855-62.207-62.207V123.932c0-34.355,27.855-62.211,62.207-62.211h255.453l-59.848,58.234H58.597v318.359
  h318.359V237.143z M377.469,56.755l62.967,62.985l20.993-20.986l-62.968-62.985L377.469,56.755z M434.211,0l-20.989,20.99
  l62.968,62.968l20.989-20.989L434.211,0z M171.288,262.987l188.91-188.953l62.969,62.984l-188.91,188.938l-91.703,28.677
  L171.288,262.987z M202.596,265.369l10.77,10.953l161.938-159.184l-10.77-10.953L202.596,265.369z"
        />
      </g>
    </svg></div>
    <div class="notification-delete-btn" data-id=${
      cell.getRow().getData().id
    } style="cursor: pointer;">
    <svg width="16px" height="16px" x="0px" y="0px" fill="red"
     viewBox="0 0 172.541 172.541" style="enable-background:new 0 0 172.541 172.541;" xml:space="preserve">
<g>
    <path d="M166.797,25.078h-13.672h-29.971V0H49.388v25.078H19.417H5.744v15h14.806l10,132.463h111.443l10-132.463h14.805V25.078z
         M64.388,15h43.766v10.078H64.388V15z M128.083,157.541H44.46L35.592,40.078h13.796h73.766h13.796L128.083,157.541z"/>
    <rect x="80.271" y="65.693" width="12" height="66.232"/>
    <rect x="57.271" y="65.693" width="12" height="66.232"/>
    <rect x="103.271" y="65.693" width="12" height="66.232"/>
</g>
</svg></div>
  </div>`;
    }
  };

  const columns = [
    // {
    //   formatter: "responsiveCollapse",
    //   width: 30,
    //   minWidth: 30,
    //   responsive: 0,
    //   hozAlign: "center",
    //   resizable: false,
    //   headerSort: false,
    // },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Title",
      field: "title",
      minWidth: "200",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      formatter: "textarea",
    },
    {
      title: "Body",
      field: "body",
      minWidth: "200",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      formatter: "textarea",
    },
    {
      title: "Image",
      hozAlign: "center",
      vertAlign: "middle",
      headerSort: false,
      field: "image",
      formatter: "image",
      minWidth: "80",
      formatterParams: {
        height: "50px",
        width: "80px",
      },
    },
    {
      title: "Button Title",
      field: "button_title",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Button Action",
      field: "button_action",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Scheduled at",
      field: "scheduled_at",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue()
          ? `${new Date(cell.getValue()).getDate()} ${
              monthShortNames[new Date(cell.getValue()).getMonth()]
            }, ${new Date(
              cell.getValue()
            ).getFullYear()} - ${formatNumberToTwoDigit(
              new Date(cell.getValue()).getHours()
            )}:${formatNumberToTwoDigit(
              new Date(cell.getValue()).getMinutes()
            )}`
          : "";
      },
    },

    {
      title: "Sent Status",
      field: "sent_status",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      width: 90,
      hozAlign: "center",
      formatter: "tickCross",
    },
    {
      title: "Total Sent",
      field: "total_sent",
      minWidth: "100",
      vertAlign: "middle",
      hozAlign: "center",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Total Success",
      field: "total_success",
      minWidth: "100",
      vertAlign: "middle",
      hozAlign: "center",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Total Failed",
      field: "total_failed",
      minWidth: "100",
      vertAlign: "middle",
      hozAlign: "center",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Total Opened",
      field: "total_view",
      minWidth: "100",
      vertAlign: "middle",
      hozAlign: "center",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Open percentage",
      field: "total_view",
      minWidth: "100",
      vertAlign: "middle",
      hozAlign: "center",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      formatter: function (cell, formatterParams, onRendered) {
        return `${(
          (cell.getRow().getData().total_view * 100) /
          cell.getRow().getData().total_success
        ).toFixed(2)} %`;
      },
    },

    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  useEffect(() => {
    $(document).on("click", ".notification-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/push-notifications/edit/${cell}`);
    });

    $(document).on("click", ".notification-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      deleteSelectedNotification(dataId);
    });

    return () => {
      $(document).off("click", ".notification-edit-btn");
      $(document).off("click", ".notification-delete-btn");
    };
  }, []);

  return (
    <div className="">
      <ComponentHeader className="border-b">
        <h2 className="text-xl font-bold">Push Notifications</h2>
        <Link
          to="/push-notifications/add"
          className="btn-primary flex items-center dark:bg-green-800 gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
        >
          <FaPlusSquare /> Add New
        </Link>
      </ComponentHeader>
      <div className="">
        <DataTable
          ref={PushNotificationTable}
          ajaxUrl={`${getAdminUrl()}/push-notification`}
          columns={columns}
          search={true}
          // responsiveLayout="collapse"
        />
      </div>
    </div>
  );
}

export default PushNotificationList;
