import { admin } from "../../ServiceConfig";

export function uploadImage(file, path) {
  return admin.post(`/uploads/image?upload_path=${path}`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function cropImage(data) {
  return admin.post("/uploads/image/crop", data);
}

export function uploadPdf(file, path) {
  return admin.post(`/uploads/pdf?upload_path=${path}`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function uploadCSV(file, path) {
  return admin.post(`/uploads/csv?upload_path=${path}`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getAllThingsToPack() {
  return admin.get(`/things-to-pack`);
}

export function uploadEditorImage(file) {
  return admin.post(`/uploads/image?upload_path=editor`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
