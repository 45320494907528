import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { deleteLocation } from "../../services/LocationServices";
import { toast } from "react-toastify";
import DataTable from "../common/DataTable";
import { getAdminUrl, getUploadsUrl } from "../../../ServiceConfig";
import ComponentHeader from "../common/ComponentHeader";
import { FaFilter, FaListAlt, FaPlusSquare } from "react-icons/fa";
import H2 from "../../../components/headings/H2";
import { getReadableDate, monthShortNames } from "../../../helpers/Helper";
import { DeleteCoupon } from "../../services/CouponService";
import InputLabel from "../inputs/InputLabel";
import SelectSearch from "../inputs/SelectSearch";
import ToggleInput from "../inputs/ToggleInput";
import DateInput from "../inputs/DateInput";

const typeArray = [
  {
    value: "global",
    label: "Global",
  },
  {
    value: "category",
    label: "Category",
  },
  {
    value: "package",
    label: "Package",
  }
]

function CouponList() {
  const navigate = useNavigate();
  const couponTable = useRef();
  const [selectedFilter, setSelectedFilter] = useState([]);
  const filterRef1 = useRef(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedCouponType, setSelectedCouponType] = useState(0)
  const [statusCheck, setStatusCheck] = useState(false)
  const [visibleCheck, setVisibleCheck] = useState(false)

  const deleteSelectedCoupon = (id) => {
    if (!window.confirm("Do You really want to delete this coupon?")) return;
    DeleteCoupon(id)
      .then((res) => {
        toast.success(res.data.message);
        couponTable.current.deleteRow(id);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
  <div class="flex items-center gap-3">
      <div class="coupon-edit-btn cursor-pointer" data-id=${cell.getRow().getData().id
      }>
          <i class="fas fa-edit"></i>
      </div>
      <div class="coupon-delete-btn cursor-pointer text-red-500" data-id=${cell.getRow().getData().id
      }>
        <i class="fas fa-trash-alt"></i>
      </div>
  </div>
  `;
  };

  const columns = [
    // {
    //   formatter: "responsiveCollapse",
    //   width: 30,
    //   minWidth: 30,
    //   responsive: 0,
    //   hozAlign: "center",
    //   resizable: false,
    //   headerSort: false,
    // },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Code",
      field: "code",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Type",
      field: "type",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Default",
      field: "is_default",
      hozAlign: "center",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getValue() === 1) {
          return "Yes";
        } else {
          return "No";
        }
      },
    },
    {
      title: "Visible",
      field: "is_visible",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getValue() === 1) {
          return "Yes";
        } else {
          return "No";
        }
      },
    },

    {
      title: "Status",
      field: "status",
      resizable: true,
      hozAlign: "center",
      vertAlign: "middle",
      // formatter: function (cell, formatterParams, onRendered) {
      //   if (cell.getValue() === 1) {
      //     return "Active";
      //   } else {
      //     return "Inactive";
      //   }
      // },
      formatter: "tickCross",
    },

    {
      title: "Valid From",
      minWidth: "100",
      field: "valid_from",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return getReadableDate(cell.getValue());
      },
    },
    {
      title: "Valid Thru",
      field: "valid_thru",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return getReadableDate(cell.getValue());
      },
    },
    {
      title: "Image",
      hozAlign: "center",
      vertAlign: "middle",
      headerSort: false,
      field: "image",
      formatter: "image",
      formatterParams: {
        height: "50px",
        width: "80px",
        urlPrefix: `${getUploadsUrl()}/coupons/`,
        urlSuffix: "",
      },
    },
    {
      title: "Event From",
      minWidth: "100",
      field: "event_date_from",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getValue()) {
          return getReadableDate(cell.getValue());
        }
      },
    },
    {
      title: "Event To",
      field: "event_date_to",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getValue()) {
          return getReadableDate(cell.getValue());
        }
      },
    },
    {
      title: "Availability used",
      field: "availability_used",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center"
    },
    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  useEffect(() => {
    $(document).on("click", ".coupon-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/coupons/edit/${cell}`);
    });

    $(document).on("click", ".coupon-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      deleteSelectedCoupon(dataId);
    });

    return () => {
      $(document).off("click", ".coupon-edit-btn");
      $(document).off("click", ".coupon-delete-btn");
    };
  }, []);

  const updateFilter = (filter) => {
    let index = selectedFilter.findIndex((sf) => sf.field === filter.field);

    if (index >= 0) {
      if (filter.value && !Array.isArray(filter.value)) {
        console.log("one test", selectedFilter)
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else if (Array.isArray(filter.value) && filter.value[0]) {
        console.log("two test", selectedFilter)
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else {
        console.log("three test", selectedFilter)
        setSelectedFilter(
          selectedFilter.filter((af) => af.field !== filter.field)
        );
      }
    } else {
      console.log("four test", selectedFilter)
      setSelectedFilter([...selectedFilter, filter]);
    }
  };

  useEffect(() => {
    couponTable.current.setFilter(selectedFilter);
  }, [selectedFilter])

  return (
    <div className="">
      <ComponentHeader className="border-b">
        <H2>Coupons</H2>
        <Link
          to="/coupons/add"
          className="btn-primary flex items-center dark:bg-green-800 gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
        >
          <FaPlusSquare /> Add New
        </Link>
      </ComponentHeader>
      <div
        ref={filterRef1}
        className=" mr-1 inline-block pt-4 lg:mr-2 "
      >
        <button
          onClick={() => setFilterOpen(!filterOpen)}
          className={`flex items-center gap-1 whitespace-nowrap rounded-md border bg-white p-2 py-2  text-xs font-semibold tracking-wide dark:bg-slate-800 lg:p-2 lg:text-lg ${filterOpen
            ? "-mb-[1px] rounded-b-none border-b-0 px-2 pb-4 pt-2 lg:px-8"
            : ""
            }`}
        >
          <FaFilter />
          Filters
        </button>
      </div>
      <div
        className={`flex grow flex-wrap  items-center gap-4 rounded-md rounded-tl-none bg-white dark:bg-slate-800 ${filterOpen ? "mb-4 border p-4" : "h-0 overflow-hidden p-0"
          }`}
      >
        <div className="min-w-[250px]">
          <InputLabel label="Validity From" />
          <DateInput
            onDateChange={(value) => {
              updateFilter({
                field: "valid_from",
                type: "in",
                value: `${new Date(value).getFullYear()}-${new Date(value).getMonth() + 1
                  }-${new Date(value).getDate()}`
              })
            }}
          />
        </div>

        <div className="min-w-[250px]">
          <InputLabel label="Validity Thru" />
          <DateInput
            onDateChange={(value) => {
              updateFilter({
                field: "valid_thru",
                type: "in",
                value: `${new Date(value).getFullYear()}-${new Date(value).getMonth() + 1
                  }-${new Date(value).getDate()}`
              })
            }}
          />
        </div>

        <div>
          <InputLabel label="Code Name" />
          <input
            type="text"
            className="field"
            onChange={(e) =>
              updateFilter({
                field: "code",
                type: "like",
                value: e.target.value,
              })
            }
          />
        </div>
        {/* <div>
            <InputLabel label="Package Id" />
            <input
              type="text"
              className="field"
              onChange={(e) =>
                updateFilter({
                  field: "package_id",
                  type: "like",
                  value: e.target.value,
                })
              }
            />
          </div> */}
        <div className="min-w-[250px]">
          <InputLabel label="Coupon Type" />
          <SelectSearch
            options={typeArray}
            value={selectedCouponType}
            onChange={(value) => {
              setSelectedCouponType(value)
              updateFilter({
                field: "type",
                type: "=",
                value: value,
              });
            }}
          />
        </div>

        <div className="min-w-[100px]">
          <InputLabel label="Visible in Offers" />
          <ToggleInput
            checked={visibleCheck}
            onToggle={(e) => {
              setVisibleCheck(e.target.checked)
              updateFilter({
                field: "is_visible",
                type: "=",
                value: e.target.checked ? 1 : 0,
              })
            }}
          />
        </div>

        <div className="min-w-[100px]">
          <InputLabel label="Status" />
          <ToggleInput
            checked={statusCheck}
            onToggle={(e) => {
              setStatusCheck(e.target.checked)
              updateFilter({
                field: "status",
                type: "=",
                value: e.target.checked ? 1 : 0,
              })
            }}
          />
        </div>



      </div>
      <div className="">
        <DataTable
          ref={couponTable}
          ajaxUrl={`${getAdminUrl()}/coupons`}
          columns={columns}
          search={false}
          responsiveLayout="collapse"
        />
      </div>
    </div>
  );
}

export default CouponList;
