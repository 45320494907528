import React, { useEffect, useState } from "react";
import { FaListAlt } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import H2 from "../../../components/headings/H2";
// import MetaTags from "../../../web/layouts/MetaTags";
import { getStateById, updateState } from "../../services/StateServices";
import ComponentHeader from "../common/ComponentHeader";
import StateForm from "./StateForm";

function EditState() {
  const navigate = useNavigate();

  const stateId = useParams().id;

  const [stateData, setStateData] = useState();

  const onEditState = (data) => {
    navigate(`/state`);
  };

  useEffect(() => {
    stateId &&
      getStateById(stateId)
        .then((res) => {
          res.data.data.country_id = res.data.data.country.id;
          setStateData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Edit State | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update State</H2>
        <Link
          to="/state"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {stateData && (
        <StateForm
          formData={stateData}
          edit={true}
          onSuccess={(data) => onEditState(data)}
        />
      )}
    </div>
  );
}

export default EditState;
