function ComponentHeader({ className, children }) {
  return (
    <div
      className={`flex items-center justify-between pb-1 pt-2 lg:px-4 ${className}`}
    >
      {children}
    </div>
  );
}

export default ComponentHeader;
