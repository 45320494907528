import { Outlet } from "react-router-dom";

function GiftCardWrapper() {
  return (
    <div className="wrapper">
      {/* <MetaTags title={"All Locations"} /> */}
      <div className="h-full max-h-[calc(100%-1rem)] overflow-auto">
        {<Outlet />}
      </div>
    </div>
  );
}

export default GiftCardWrapper;
