import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import MetaTags from "../../../web/layouts/MetaTags";
import { getModuleById } from "../../services/ModuleServices";
import ModuleForm from "./ModuleForm";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";

function EditModule() {
  const navigate = useNavigate();

  const moduleId = useParams().id;

  const [module, setModule] = useState();

  const onEditModule = (data) => {
    navigate(`/module`);
  };

  useEffect(() => {
    moduleId &&
      getModuleById(moduleId)
        .then((res) => {
          setModule(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Edit Module | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Travel Mode</H2>
        <Link
          to="/module"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {module && (
        <ModuleForm
          formData={module}
          edit={true}
          onSuccess={(data) => onEditModule(data)}
        />
      )}
    </div>
  );
}

export default EditModule;
