import { Link, useNavigate } from "react-router-dom"; 
// import MetaTags from "../../../web/layouts/MetaTags";
import ComponentHeader from "../components/common/ComponentHeader";
import H2 from "../../components/headings/H2";
import { FaListAlt } from "react-icons/fa";
import RewardForm from "./RewardForm";

function AddReward() {
  const navigate = useNavigate();


//   const [authorized, setAuthorized] = useState(false);

//   const { setLoading } = useLoader();

//   useEffect(() => {
//     setLoading(true);
//     checkPermission("B2B.create")
//       .then((res) => {
//         setAuthorized(res.data.data);
//         setLoading(false);
//       })
//       .catch((err) => {
//         toast.error(err.response.data.message);
//       });

     
//   }, []);



  const onAddState = (data) => {
    navigate(`/rnr/reward`);
  };

  return  <div className="">
      <ComponentHeader className="mb-4 border-b">
        <H2>Add Reward</H2>
        <Link
          to="/rnr/reward"
          className="btn-primary flex dark:bg-green-800 items-center gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>

      <RewardForm onSuccess={(data) => onAddState(data)} />
    </div>
}

export default AddReward;
