import React, { useState, useCallback, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getAllHomepageCategories, reorderCategories } from "../../services/SettingsServices";
import { toast } from "react-toastify";
import ComponentHeader from "../common/ComponentHeader";
import H2 from "../../../components/headings/H2";
import { FaListAlt, FaPlusSquare } from "react-icons/fa";
import { Link } from "react-router-dom";
import { MdReorder } from "react-icons/md";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderRadius: '6px',
  background: isDragging ? "#2680bd" : "#d8f2fc",
  color: isDragging ? "white" : "black",
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  padding: grid,
  width: '50%',
  margin: 'auto',
  padding: '2rem',
  border: 'dashed',
  borderRadius: '1rem'
});

const ReorderCategory = () => {
  const [items, setItems] = useState([]);
  const [showingArray, setShowingArray] = useState([])

  const getAllCategories = () => {
    getAllHomepageCategories().then((res) => {
      setItems(res.data.data.data.map(cat => ({ id: `${cat.id}`, content: `${cat.title}`, order: `${cat.order}` })))
    }).catch((er) => {
      console.log(er, "error in getting data")
    })
  }

  useEffect(() => {
    getAllCategories()
  }, [])

  useEffect(() => {
    setShowingArray(items.sort((a, b) => a.order - b.order))
  }, [items])

  const onDragEnd = useCallback((result) => {
    if (!result.destination) {
      toast.error("Please drop the category inside the list")
      return;
    }

    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    const submitData = reorderedItems.map(({ content, id, order }, index) => ({ id: `${id}`, order: `${index + 1}` }));
    reorderCategories({ "order": submitData }).then((res) => {
      toast.success("Reordered Successfully.....")
      setItems(reorderedItems);
      getAllCategories()
    }).catch(er => {
      toast.error("error in reorderring")
      console.log(er)
    })
  }, [items]);

  return (
    <div className="flex h-full flex-col">
      <ComponentHeader className="border-b">
        <H2>Reorder Homepage Categories</H2>
        <div className='flex gap-4'>
          <Link
            to="/homepage-category"
            className="btn-primary flex dark:bg-green-800 items-center gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
          >
            <FaListAlt /> View All
          </Link>
          <Link
            to="/homepage-category/add"
            className="btn-primary flex items-center gap-1 dark:bg-green-800 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
          >
            <FaPlusSquare /> Add New
          </Link>
        </div>
      </ComponentHeader>
      <h3 className="w-full m-auto text-center mt-4 font-semibold text-lg">Drag and Drop The Homepage Categories to Reorder the list</h3>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              className="flex flex-col gap-2 justify-center items-center w-full"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {showingArray.length > 0 && showingArray.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className="w-full flex justify-between font-bold"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {item.order}. {item.content} <MdReorder size={30} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default ReorderCategory;