import React, { useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { getAdminUrl, getUploadsUrl } from '../../../ServiceConfig';
import H2 from '../../../components/headings/H2';
import { FaPlusSquare } from 'react-icons/fa';
import $ from "jquery";
import DataTable from '../common/DataTable';
import ComponentHeader from "../common/ComponentHeader";
import { deleteHomePagecategory } from '../../services/SettingsServices';
import { toast } from 'react-toastify';
import { MdReorder } from 'react-icons/md';

const HomepageCategoryList = () => {
  const navigate = useNavigate();
  const homecategoryTabel = useRef();
  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
    <div class="flex items-center gap-3">
        <div class="home-edit-btn cursor-pointer" data-id=${cell.getRow().getData().id
      }>
            <i class="fas fa-edit"></i>
        </div>
        <div class="home-delete-btn cursor-pointer text-red-500" data-id=${cell.getRow().getData().id
      }>
          <i class="fas fa-trash-alt"></i>
        </div>
    </div>
    `;
  };

  const deleteHomecategory = (id) => {
    if (!window.confirm("Do You really want to delete this HomePage Category?")) return;
    deleteHomePagecategory(id)
      .then((res) => {
        toast.success("deleted successfully");
        homecategoryTabel.current.deleteRow(id);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Title",
      field: "title",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Image",
      field: "image",
      minWidth: "100",
      resizable: true,
      vertAlign: "middle",
      formatter: "image",
      formatterParams: {
        height: "50px",
        width: "80px",
        urlPrefix: `${getUploadsUrl()}/homepage/home-categories/`,
        urlSuffix: "",
      },
    },
    {
      title: "Order",
      field: "order",
      minWidth: "100",
      resizable: true,
      hozAlign: "center",
      vertAlign: "middle",
    },
    {
      title: "URL",
      field: "url",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: function (cell, formatterParams, onRendered) {
        return ` <button style="
        background-color: #007BFF; 
        color: white; 
        padding: 7px 15px; 
        border: none; 
        border-radius: 5px; 
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
        cursor: pointer; 
        font-size: 15px;
    ">
        <a href=https://www.justwravel.com/${cell.getValue()} target="_blank" style="color: white; text-decoration: none;">Link</a>
    </button>`
      },
    },
    {
      title: "Assign Packages",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: function (cell, formatterParams, onRendered) {
        return ` <button style="
        background-color: green; 
        color: white; 
        padding: 7px 15px; 
        border: none; 
        border-radius: 5px; 
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
        cursor: pointer; 
        font-size: 15px;
    ">
        <a href=/homepage-category/assign-package/${cell.getRow().getData().id} target="_blank" style="color: white; text-decoration: none;">Assign</a>
    </button>`
      },
    },
    {
      title: "Status",
      field: "status",
      resizable: true,
      headerSort: false,
      hozAlign: "center",
      vertAlign: "middle",
      formatter: "tickCross"
    },
    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  useEffect(() => {
    $(document).on("click", ".home-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/homepage-category/edit/${cell}`);
    });

    $(document).on("click", ".home-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      deleteHomecategory(cell)
    });

    return () => {
      $(document).off("click", ".home-edit-btn");
      $(document).off("click", ".home-delete-btn");
    };
  });
  return (
    <div className="flex h-full flex-col">
      <ComponentHeader className="border-b">
        <H2>Homepage Categories</H2>
        <div className='flex gap-4'>
          <Link
            to="/homepage-category/changeorder"
            className="btn-primary flex items-center gap-1 dark:bg-green-800 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
          >
            <MdReorder /> Reorder
          </Link>
          <Link
            to="/homepage-category/add"
            className="btn-primary flex items-center gap-1 dark:bg-green-800 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
          >
            <FaPlusSquare /> Add New
          </Link>
        </div>
      </ComponentHeader>
      <div className="h-full">
        <DataTable
          ref={homecategoryTabel}
          ajaxUrl={`${getAdminUrl()}/home-category`}
          columns={columns}
          search={true}
          responsiveLayout="collapse"
          movableRows={true}
        />
      </div>
    </div>
  );
}

export default HomepageCategoryList