import React, { useEffect, useState } from 'react'
import { getWeekDepartures } from '../../services/DashboardServices'
import moment from 'moment'

const DeparturesUpcoming = () => {
    const [data, setData] = useState()
    useEffect(() => {
        getWeekDepartures().then(res => {
            setData(res.data)
        }).catch((er) => {
            console.log(er)
        })
    }, [])
    return (
        <div>
            <div className="flex flex-col rounded-lg bg-white dark:border-slate-700 border">
                <div className="card-header-background bg- flex items-center justify-between border-b bg-gray-100 dark:bg-slate-900 dark:border-slate-700 dark:border-b dark:text-white p-4">
                    <h3 className="font-bold">Upcoming Departures</h3>
                </div>
                <div className="max-h-[50vh] overflow-hidden dark:bg-gray-600 px-2">
                    <div className="h-96 flex flex-col items-center overflow-auto gap-3 py-4">
                        {
                            data?.map((onePckage) =>
                                <div
                                    key={onePckage.package_id}
                                    className={`flex w-full items-center justify-between gap-4 rounded-md ${onePckage.remaining_amount === 0 ? 'bg-green-100' : 'bg-red-100'}  dark:text-white p-2 shadow-[1px_1px_4px_rgba(0,0,0,0.15)] transition-all duration-300 ease-out hover:shadow-md`}
                                >
                                    <div className="w-2/5 font-medium">
                                        <p className="lg:text-md text-sm font-bold text-gray-800  dark:text-white">
                                           {onePckage.package_name}
                                        </p>
                                        <p className="lg:text-sm text-xs text-gray-800 dark:text-white">
                                           { moment(onePckage.batch).format("ll")}
                                        </p>
                                    </div>
                                    <div className="w-2/5 font-medium">
                                        <p className="lg:text-md text-sm font-bold text-gray-800  dark:text-white">
                                          Total pax: {onePckage.total_pax}
                                        </p>
                                        <p className="lg:text-sm text-xs text-gray-800 dark:text-white">
                                          Incomplete details: { onePckage.participant_incomplete_count} participants
                                        </p>
                                    </div>
                                    <div className="w-1/5 font-medium">
                                        <p className="lg:text-sm text-xs break-keep text-gray-800  dark:text-white">
                                          Remaining Amount: {onePckage.remaining_amount}
                                        </p>
                                        <p className="lg:text-sm text-xs text-gray-800 dark:text-white">
                                          {/* Incomplete Particiapnt Count: { onePckage.participant_incomplete_count} */}
                                        </p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeparturesUpcoming