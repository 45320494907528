import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import MetaTags from "../../../web/layouts/MetaTags";
import { getLocationById } from "../../services/LocationServices";
import LocationForm from "./LocationForm";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";

function EditLocation() {
  const navigate = useNavigate();

  const locationId = useParams().id;

  const [location, setLocation] = useState();

  const onEditLocation = (data) => {
    navigate(`/location`);
  };

  useEffect(() => {
    locationId &&
      getLocationById(locationId)
        .then((res) => {
          res.data.data.country_id = res.data.data.country.id;
          res.data.data.state_id = res.data.data.state.id;
          setLocation(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Edit Location | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Location</H2>
        <Link
          to="/location"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {location && (
        <LocationForm
          formData={location}
          edit={true}
          onSuccess={(data) => onEditLocation(data)}
        />
      )}
    </div>
  );
}

export default EditLocation;
