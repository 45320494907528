import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { useEffect } from "react";
import { useState } from "react";

import { FaPlusSquare } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import usePackageData from "../../../hooks/usePackageData";
import {
  AddThingsToKnowToPackage,
  AddThingsToPackToPackage,
  addPackageExtraContent,
  deleteExtraContent,
  getPackageExtraContent,
  updateExtraContent,
} from "../../../services/PackageService";
import EditorInput from "../../inputs/EditorInput";
import InputLabel from "../../inputs/InputLabel";
import { getAllThingsToPack } from "../../../services/CommonServices";
import MultiSelect from "../../inputs/MultiSelect";
import { getAllThingsToKnowOption } from "../../../services/ThingsToKnowServices";
import { toast } from "react-toastify";
import { getUploadsUrl } from "../../../../ServiceConfig";
import { getAllThingsToPackOption } from "../../../services/ThingsToPackService";

function Step9({ onSuccess }) {
  const navigate = useNavigate();

  const { packageData } = usePackageData();

  const [content, setContent] = useState();
  const [ttp, setTtp] = useState([]);
  const [ttk, setTtk] = useState([]);

  const createPackageExtraContent = (values) => {
    values.extra_content.forEach((content) => {
      content.package_id = packageData.package.id;
    });

    addPackageExtraContent(values)
      .then((res) => {
        navigate("/package");
      })
      .catch((err) => {
        console.log(err, "ererererer---------");
      });
  };

  const updateEC = (value) => {
    if (value?.id) {
      updateExtraContent(value.id, value).then((res) => {
        console.log(res.data.data);
      });
    }
  };

  const deleteEC = (value) => {
    if (!window.confirm("Do You really want to delete this content?")) {
      return false;
    } else {
      deleteExtraContent(value?.id).then((res) => {
        console.log(res.data.data);
      });
      return true;
    }
  };

  useEffect(() => {
    if (packageData.package?.id) {
      getPackageExtraContent(packageData.package.id).then((res) => {
        if (res.data.data.length > 0) {
          setContent(res.data.data);
        } else {
          setContent([{ question: "", answer: "" }]);
        }
      });
    } else {
      setContent([{ question: "", answer: "" }]);
    }
  }, []);

  useEffect(() => {
    getAllThingsToPackOption()
      .then((res) => {
        setTtp(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    getAllThingsToKnowOption()
      .then((res) => {
        setTtk(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const linkTtkToPackage = (values) => {
    values.package_id = packageData?.package?.id;

    AddThingsToKnowToPackage(values)
      .then((res) => {
        toast.success(res.data.message);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const linkTtpToPackage = (values) => {
    values.package_id = packageData?.package?.id;
    AddThingsToPackToPackage(values)
      .then((res) => {
        toast.success(res.data.message);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          things_to_pack: packageData?.package?.things_to_pack || [],
        }}
        onSubmit={(values) => {
          linkTtpToPackage(values);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="my-4">
              <h2 className="text-lg font-bold">Things to Pack</h2>

              <MultiSelect
                onChange={(value) => setFieldValue("things_to_pack", value)}
                value={values.things_to_pack}
                options={
                  ttp?.length > 0 &&
                  ttp?.map((t) => ({
                    label: (
                      <div className="flex items-center gap-2">
                        <img
                          src={`${getUploadsUrl()}/things-to-pack/${t.icon}`}
                          width={40}
                          height={40}
                          className="h-10 w-10"
                          alt="icon"
                        />{" "}
                        {t?.title}
                      </div>
                    ),
                    value: t?.id,
                  }))
                }
              />

              <div className="mt-4 text-center">
                <button className="btn-primary">Save</button>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <Formik
        enableReinitialize
        initialValues={{
          things_to_know: packageData?.package?.things_to_know || [],
        }}
        onSubmit={(values) => {
          linkTtkToPackage(values);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="my-4">
              <h2 className="text-lg font-bold">Things to Know</h2>
              <MultiSelect
                onChange={(value) => setFieldValue("things_to_know", value)}
                value={values.things_to_know}
                options={
                  ttk?.length > 0 &&
                  ttk?.map((t) => ({ label: t?.title, value: t?.id }))
                }
              />

              <div className="mt-4 text-center">
                <button className="btn-primary">Save</button>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <Formik
        enableReinitialize
        initialValues={{
          extra_content: content,
        }}
        onSubmit={(values) => {
          // alert(JSON.stringify(values, null, 2));
          createPackageExtraContent(values);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="my-4">
              <h2 className="text-lg font-bold">Package Extra Content</h2>
            </div>
            <FieldArray name="extra_content">
              {({ insert, remove, push }) => (
                <div>
                  {values.extra_content?.length > 0 &&
                    values.extra_content?.map((itinerary, index) => (
                      <div className="mb-2 rounded-lg border-2 p-4" key={index}>
                        <div className="relative mb-2 border-b pb-2 text-center font-bold">
                          <h3>{index + 1}. Extra Content</h3>
                          <div
                            onClick={() => {
                              let isDeleting = deleteEC(
                                values.extra_content[index]
                              );
                              isDeleting && remove(index);
                            }}
                            className="absolute right-0 top-0 rounded-full border-2 p-1"
                          >
                            <MdClose />
                          </div>
                        </div>
                        {/* //? Itinerary Title */}
                        <div className="">
                          <InputLabel
                            label="Title"
                            labelFor={`extra_content.${index}.title`}
                          />
                          <Field
                            id={`extra_content.${index}.title`}
                            name={`extra_content.${index}.title`}
                            type="text"
                            className="field"
                            onBlur={() => updateEC(values.extra_content[index])}
                          />
                          <ErrorMessage
                            name={`extra_content.${index}.title`}
                            component="div"
                            className="field-error"
                          />
                        </div>

                        {/* //? Itinerary Description */}
                        <div className="my-4">
                          <InputLabel
                            label="Description"
                            labelFor={`extra_content.${index}.description`}
                          />
                          <EditorInput
                            fieldKey={`extra_content.${index}.description`}
                            data={values.extra_content[index].description}
                            onBlur={() => updateEC(values.extra_content[index])}
                          />
                        </div>
                      </div>
                    ))}
                  <button
                    type="button"
                    className="my-2 flex items-center gap-1 px-4 text-jw-green"
                    onClick={() => push({ title: "", description: "" })}
                  >
                    <FaPlusSquare /> Content
                  </button>
                </div>
              )}
            </FieldArray>
            <div className="text-center">
              <button type="submit" className="btn-primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Step9;
