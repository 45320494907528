import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";
import JobForm from "./JobForm";
import { getJobById } from "../../services/JobServices";

function EditJobOpenings() {
  const navigate = useNavigate();

  const jobId = useParams().id;

  const [jobOpening, setJobOpening] = useState();

  const onEditJobOpening = (data) => {
    navigate(`/jobs`);
  };

  useEffect(() => {
    getJobById(jobId)
      .then((res) => {
        setJobOpening(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Edit Location | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Job Opening</H2>
        <Link
          to="/jobs"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>

      <JobForm
        formData={jobOpening}
        edit={true}
        onSuccess={(data) => onEditJobOpening(data)}
      />
    </div>
  );
}

export default EditJobOpenings;
