import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import { lazy, Suspense, useEffect } from "react";
import { Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

// ? -------------------- Services --------------------
import { ToastContainer } from "react-toastify";

import Loader from "./components/Loader";
import AdminRoutes from "./routes/AdminRoutes";
import { ScrollDirectionProvider } from "./context/ScrollDirectionProvider";
// import { getHomePageData } from "./web/services/CommonService";
// import useHomePageData from "./web/hooks/useHomePageData";
import ScrollToTop from "./helpers/ScrollToTop";
import MetaTags, { MetaTagsDefault } from "./admin/components/Metatags";
import useTheme from "./admin/hooks/useTheme";

function App() {
  const location = useLocation();
  // const { setAuth } = useAuth();
  const { theme, setTheme } = useTheme()
  // const { setHomePageData } = useHomePageData();
  // const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  // Scroll to top if path changes

  // ? Update Document size on orientation change
  // const resizeObserver = new ResizeObserver(() => {
  //   document.querySelector(
  //     ".app-wrapper"
  //   ).style.height = `${window.innerHeight}px`; // ? sets initial height of app
  // });
  // resizeObserver.observe(document.body);

  useEffect(() => {
    // ? sets theme mode to app

    // Set theme according to Last prefrence of user OR if no preference then according to the system's theme
    // if (localStorage.getItem('theme')) {
    //   if (localStorage.getItem('theme') === 'dark') {
    //     document.documentElement.classList.add("dark");
    //     setTheme('dark')
    //   }
    //   else {
    //     document.documentElement.classList.remove("dark");
    //     setTheme('light')
    //   }
    // }
    // else {
    //   if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    //     document.documentElement.classList.add("dark");
    //     localStorage.setItem('theme', 'dark')
    //     setTheme('dark')
    //   } else {
    //     document.documentElement.classList.remove("dark");
    //     setTheme('light')
    //     localStorage.setItem('theme', 'light')
    //   }
    // }
    // getHomePageData().then((res) => {
    //   console.log(res, "hdhdhdhdhddh-----------");
    //   setHomePageData(res.data.data);
    // });

    // ? Check Is User LoggedIn
    // checkLogin()
    //   .then((res) => {
    //     res.data.data.token = localStorage.getItem("token");
    //     setAuth(res.data.data);
    //   })
    //   .catch((err) => {
    //     localStorage.removeItem("token");
    //   });
  }, []);

  return (
    <>
      <Loader />
      <ToastContainer
      // position="bottom-left"
      // autoClose={5000}
      // hideProgressBar={false}
      // newestOnTop={false}
      // closeOnClick
      // rtl={false}
      // pauseOnFocusLoss
      // draggable
      // pauseOnHover
      // theme="dark"
      />
      {/* <ToastContainer /> */}
      <Suspense>
        <AnimatePresence>
          {/* <ScrollToTop /> */}
          <MetaTagsDefault />
          <AdminRoutes key={1} />

          {/* //? Public Route Will be always at bottom */}
          {/* <ScrollDirectionProvider>
            <PublicRoutes key={3} />
          </ScrollDirectionProvider> */}
        </AnimatePresence>
      </Suspense>
    </>
  );
}

export default App;
