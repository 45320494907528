import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import DataTable from "../common/DataTable";
import { getAdminUrl, getUploadsUrl } from "../../../ServiceConfig";
import ComponentHeader from "../common/ComponentHeader";
import H2 from "../../../components/headings/H2";
import { FaPlusSquare } from "react-icons/fa";
import { deleteStayById } from "../../services/StayServices";
import InputLabel from "../inputs/InputLabel";
import SelectSearch from "../inputs/SelectSearch";
import { getAllStateByCountry } from "../../services/StateServices";
import { getAllCountriesOption } from "../../services/CountryServices";
import {
  getAllLocationByCountryId,
  getLocationsByStateId,
} from "../../services/LocationServices";

function StayList() {
  const stayTable = useRef();
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [allLocations, setAllLocations] = useState([]);

  useEffect(() => {
    getAllCountriesOption()
      .then((res) => {
        setAllCountries(
          res.data.data.map((onecont) => ({
            label: onecont.name,
            value: onecont.id,
          }))
        );
      })
      .catch((er) => {
        console.log(er, "error in getting countries");
      });
    getStates(101);
    getLocationsbyCountry(101);
  }, []);

  const getStates = (val) => {
    getAllStateByCountry(val)
      .then((res) => {
        setAllStates(
          res.data.data.map((oneState) => ({
            label: oneState.name,
            value: oneState.id,
          }))
        );
      })
      .catch((er) => console.log(er, "error in getting states"));
  };

  const getLocationsbyCountry = (val) => {
    getAllLocationByCountryId(val)
      .then((res) => {
        setAllLocations(
          res.data.data.map((oneloc) => ({
            label: oneloc.name,
            value: oneloc.id,
          }))
        );
      })
      .catch((er) => {
        console.log(er, "error in getting locations by country");
      });
  };

  const getLocationsbyState = (val) => {
    getLocationsByStateId(val)
      .then((res) => {
        setAllLocations(
          res.data.data.map((oneloc) => ({
            label: oneloc.name,
            value: oneloc.id,
          }))
        );
      })
      .catch((er) => {
        console.log(er, "error in getting locations by state");
      });
  };

  useEffect(() => {
    stayTable.current.setFilter(selectedFilter);
  }, [selectedFilter]);

  const deleteSelectedStay = (id) => {
    if (!window.confirm("Do You really want to delete this stay?")) return;
    deleteStayById(id)
      .then((res) => {
        toast.success(res.data.message);
        stayTable.current.deleteRow(id);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
    <div class="flex items-center gap-3">
      <div class="stay-edit-btn cursor-pointer" data-id=${
        cell.getRow().getData().id
      }>
          <i class="fas fa-edit"></i>
      </div>
      <div class="stay-delete-btn cursor-pointer text-red-500" data-id=${
        cell.getRow().getData().id
      }>
        <i class="fas fa-trash-alt"></i>
      </div>
  </div>`;
  };

  const printStar = (cell, formatterParams) => {
    let html = `<div class="flex items-center text-yellow-500 text-sm">`;
    cell.getData();
    for (let i = 0; i < cell.getData().star_category; i++) {
      html += `<i class="fas fa-star"></i>`;
    }

    html += `</div>`;
    return html;
  };

  const updateFilter = (filter) => {
    let index = selectedFilter.findIndex((sf) => sf.field === filter.field);

    if (index >= 0) {
      if (filter.value && !Array.isArray(filter.value)) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else if (Array.isArray(filter.value) && filter.value[0]) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else {
        setSelectedFilter(
          selectedFilter.filter((af) => af.field !== filter.field)
        );
      }
    } else {
      setSelectedFilter([...selectedFilter, filter]);
    }
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Name",
      field: "name",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Country",
      minWidth: "100",
      field: "country",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "State",
      minWidth: "100",
      field: "state",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Location",
      minWidth: "100",
      field: "location",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Stay Category",
      minWidth: "100",
      field: "stay_category",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Star Category",
      field: "star_category",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: printStar,
      // formatterParams: {
      //   stars: 7,
      // },
    },
    {
      title: "Image",
      hozAlign: "center",
      vertAlign: "middle",
      headerSort: false,
      field: "image",
      formatter: "image",
      formatterParams: {
        height: "50px",
        width: "80px",
        urlPrefix: `${getUploadsUrl()}/stays/`,
        urlSuffix: "",
      },
    },
    {
      title: "Ameneties",
      minWidth: "100",
      field: "hotel_ameneties",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Features",
      minWidth: "100",
      field: "features",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Address",
      minWidth: "100",
      field: "address",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "URL",
      minWidth: "100",
      field: "url",
      resizable: true,
      vertAlign: "middle",
      formatter: "link",
      formatterParams: {
        target: "_blank",
      },
    },
    // {
    //   title: "Description",
    //   field: "description",
    //   resizable: true,
    //   headerSort: false,
    //   vertAlign: "middle",
    // },
    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  useEffect(() => {
    $(document).on("click", ".stay-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      window.open(`/stays/edit/${cell}`, "_blank");
    });

    $(document).on("click", ".stay-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      deleteSelectedStay(dataId);
    });

    return () => {
      $(document).off("click", ".stay-edit-btn");
      $(document).off("click", ".stay-delete-btn");
    };
  }, []);

  return (
    <div className="flex h-full flex-col">
      <ComponentHeader className="border-b">
        <H2>Stays</H2>
        <Link
          to="/stays/add"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaPlusSquare /> Add New
        </Link>
      </ComponentHeader>
      <div className="h-full">
        <div className="my-8 rounded-md">
          <button
            onClick={() => setFilterOpen(!filterOpen)}
            className={` inline-block whitespace-nowrap rounded-md border bg-white p-8 py-2 text-lg font-semibold tracking-wide dark:bg-slate-900 dark:text-white ${
              filterOpen
                ? "border-b-1 -mb-[1px] rounded-b-none px-8 pb-1 pt-2"
                : ""
            }`}
          >
            Filters
          </button>
          <div
            className={`flex grow flex-wrap  items-center gap-4 rounded-md rounded-tl-none bg-white dark:bg-slate-900 dark:text-white ${
              filterOpen ? "mb-4 border p-4" : "h-0 overflow-hidden p-0"
            }`}
          >
            <div className="min-w-[250px]">
              <InputLabel label="Country" />
              <SelectSearch
                value={
                  selectedFilter.find((filter) => filter.field === "country_id")
                    ?.value
                    ? selectedFilter.find(
                        (filter) => filter.field === "country_id"
                      )?.value
                    : 101
                }
                options={allCountries}
                onChange={(value) => {
                  getStates(value);
                  getLocationsbyCountry(value);
                  updateFilter({
                    field: "country_id",
                    type: "=",
                    value: value,
                  });
                }}
              />
            </div>

            <div className="min-w-[250px]">
              <InputLabel label="State" />
              <SelectSearch
                value={
                  selectedFilter.find((filter) => filter.field === "state_id")
                    ?.value
                }
                options={allStates}
                onChange={(value) => {
                  getLocationsbyState(value);
                  updateFilter({
                    field: "state_id",
                    type: "=",
                    value: value,
                  });
                }}
              />
            </div>

            <div className="min-w-[250px]">
              <InputLabel label="Locations" />
              <SelectSearch
                value={
                  selectedFilter.find(
                    (filter) => filter.field === "location_id"
                  )?.value
                }
                options={allLocations}
                onChange={(value) => {
                  updateFilter({
                    field: "location_id",
                    type: "=",
                    value: value,
                  });
                }}
              />
            </div>
            <div>
              <InputLabel label="Name" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "name",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <DataTable
          ref={stayTable}
          ajaxUrl={`${getAdminUrl()}/stay`}
          columns={columns}
          search={false}
          responsiveLayout="collapse"
        />
      </div>
    </div>
  );
}

export default StayList;
