import FileInput from "../../../components/inputFields/FileInput";
import { toast } from "react-toastify";
import InputLabel from "../inputs/InputLabel";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { getUploadsUrl } from "../../../ServiceConfig";
import {
  addNewTransfer,
  updateTransfer,
  uploadTransferImage,
} from "../../services/TransferServices";

function TransferForm({ formData, edit, onSuccess }) {
  const addTransfer = (values) => {
    addNewTransfer(values)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const editTransfer = (values) => {
    values.id = formData.id;
    updateTransfer(values, values.id)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div>
      <Formik
        initialValues={{
          icon: formData?.icon ? formData?.icon : "",
          type: formData?.type ? formData?.type : "",
          seats: formData?.seats ? formData?.seats : "",
        }}
        // validationSchema={stateFormSchema}
        onSubmit={(values) => {
          edit ? editTransfer(values) : addTransfer(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="flex flex-wrap gap-4">
              {/* //? Banner Image */}
              <div className="w-full grow lg:w-auto">
                <FileInput
                  value={`${getUploadsUrl()}/transfers/${values.icon}`}
                  noOfFilesAllowed={1}
                  onChange={(file) => {
                    uploadTransferImage({ image: file }).then((res) => {
                      setFieldValue("icon", res.data.data[0].uploaded_image);
                    });
                  }}
                />
              </div>

              <div className="grow">
                <div className="">
                  <InputLabel label="Type" />
                  <Field className="field" name="type" />
                </div>

                <div className="my-4">
                  <InputLabel label="Available Seats" />
                  <Field className="field" name="seats" />
                </div>
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn-primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default TransferForm;
