import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import MetaTags from "../../../web/layouts/MetaTags";
import { getTravelModeById } from "../../services/TravelModeServices";
import TravelModeForm from "./TravelModeForm";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";

function EditTravelMode() {
  const navigate = useNavigate();

  const travelModeId = useParams().id;

  const [travelMode, setTravelMode] = useState();

  const onEditTravelMode = (data) => {
    navigate(`/travel-mode`);
  };

  useEffect(() => {
    travelModeId &&
      getTravelModeById(travelModeId)
        .then((res) => {
          setTravelMode(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Edit TravelMode | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Travel Mode</H2>
        <Link
          to="/travel-modes"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {travelMode && (
        <TravelModeForm
          formData={travelMode}
          edit={true}
          onSuccess={(data) => onEditTravelMode(data)}
        />
      )}
    </div>
  );
}

export default EditTravelMode;
