import React from "react";
import ComponentHeader from "../common/ComponentHeader";
import H2 from "../../../components/headings/H2";
import { Link, useNavigate } from "react-router-dom";
import { FaListAlt } from "react-icons/fa";
import AdminStatWidgetForm from "./AdminStatWidgetForm";

function AddAdminStatWidget() {
  const navigate = useNavigate();
  const onAddAdminStatWidget = (data) => {
    navigate(`/admin-stat-widget`);
  };
  return (
    <div className="">
      {/* <MetaTags title="Add Category | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Add Admin Stat widget</H2>
        <Link
          to="/admin-stat-widget"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      <AdminStatWidgetForm onSuccess={(data) => onAddAdminStatWidget(data)} />
    </div>
  );
}

export default AddAdminStatWidget;
