import React from 'react'
import { Outlet } from "react-router-dom";
const InterNalNotificationsWrapper = () => {
  return (
    <div className="wrapper">
        {<Outlet />}
    </div>
  )
}

export default InterNalNotificationsWrapper