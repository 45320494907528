import React, { useEffect, useState } from "react";
import { FaListAlt } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import H2 from "../../../components/headings/H2";
// import MetaTags from "../../../web/layouts/MetaTags";
import ComponentHeader from "../common/ComponentHeader";
import StayForm from "./StayForm";
import { getStayById } from "../../services/StayServices";

function EditStay() {
  const navigate = useNavigate();

  const stayId = useParams().id;

  const [stayData, setStayData] = useState();

  const onEditState = (data) => {
    navigate(`/stays`);
  };

  useEffect(() => {
    stayId &&
      getStayById(stayId)
        .then((res) => {
          setStayData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Edit State | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Stay</H2>
        <Link
          to="/stays"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {stayData && (
        <StayForm
          formData={stayData}
          edit={true}
          onSuccess={(data) => onEditState(data)}
        />
      )}
    </div>
  );
}

export default EditStay;
