import { Outlet } from "react-router-dom";
// import MetaTags from "../../../web/layouts/MetaTags";

const EventDatesWrapper = () => {
  return (
    <div className="dark:bg-slate-900 min-h-[93vh] dark:text-white h-full rounded-lg bg-white px-4 text-gray-700">
      {/* <MetaTags title={"Event Dates"} /> */}
      {/* <div className="h-full max-h-[calc(100%-1rem)] overflow-auto">   */}
        {<Outlet />}
      {/* </div> */}
    </div>
  );
};

export default EventDatesWrapper;
