import React from 'react'
import { getUploadsUrl } from '../../../ServiceConfig'
import useScreenSize from '../../../hooks/useScreenSize'


const SingleRewardAndRecognition = ({ winner }) => {
    const { breakpoint } = useScreenSize()
    console.log(breakpoint)

    return (
        <>
            {breakpoint === '2xl' || breakpoint === 'xl' ? <div
                className={`flex lg:w-full h-full items-center justify-center gap-4  rounded-md bg-white dark:bg-slate-800 dark:text-white 
         transition-all duration-300 ease-out hover:shadow-md`}
            >
                <div className={`relative ${winner.rnr_cat.color === 'green' ? "bg-[#8BEFA1]" : "bg-[#83E1FF]"} w-[67%] h-[80%] rounded-xl bg-[url('https://storage.googleapis.com/storage.justwravel.com/test-rnr/Background%20Vector.png')]`}>
                    <img
                        src={`${getUploadsUrl()}/rnr-cat/${winner?.rnr_cat?.image}`}
                        alt="RnR Winning Category Image"
                        className="absolute top-[8%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[65%] z-20"
                    />
                    <img
                        src={`${getUploadsUrl()}/employees_cutout/${winner.employee.cut_out_image}`}
                        alt={`${winner.employee.first_name + " " + winner.employee.last_name}'s Image`}
                        className="absolute top-[30%] left-0 right-0 mx-auto w-[82%] z-20"
                    />
                    <div className="absolute bottom-[-2.3rem] left-1/2 transform -translate-x-1/2 w-[90%] bg-[#075985] border-4 border-white rounded-3xl p-1 flex flex-col justify-center items-center z-20">
                        <h1 className="text-xl font-bold text-white text-center  capitalize font-fraunces">{winner.employee.first_name + " " + winner.employee.last_name}</h1>
                        <h2 className="text-sm text-white font-fraunces text-center tracking-wider">{winner.employee.department.name}</h2>
                    </div>
                </div>
            </div> : <div className={`relative ${winner.rnr_cat.color === 'green' ? "bg-[#8BEFA1]" : "bg-[#83E1FF]"} w-[80%] left-8 h-[90%] top-7 rounded-xl bg-[url('https://storage.googleapis.com/storage.justwravel.com/test-rnr/Background%20Vector.png')]`}>
                <img
                    src={`${getUploadsUrl()}/rnr-cat/${winner?.rnr_cat?.image}`}
                    alt="RnR Winning Category Image"
                    className="absolute top-5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[50%] z-20"
                />
                <img
                    src={`${getUploadsUrl()}/employees_cutout/${winner.employee.cut_out_image}`}
                    alt={`${winner.employee.first_name + " " + winner.employee.last_name}'s Image`}
                    className="absolute top-14 left-0 right-0 mx-auto w-[80%] z-20"
                />
                <div className="absolute bottom-3 left-1/2 transform -translate-x-1/2 -mb-6 w-[75%] bg-[#075985] border-4 border-white rounded-3xl p-1 flex flex-col justify-center items-center z-20">
                    <h1 className="text-xl font-bold text-white text-center  capitalize font-fraunces">{winner.employee.first_name + " " + winner.employee.last_name}</h1>
                    <h2 className="text-sm text-white font-fraunces text-center tracking-wider">{winner.employee.department.name}</h2>
                </div>
            </div>}
        </>
    )
}

export default SingleRewardAndRecognition