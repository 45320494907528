import React, { useEffect, useState } from "react";
import FileInput from "../../../components/inputFields/FileInput";
import { getAllStateByCountry } from "../../services/StateServices";
import { getAllCountriesOption } from "../../services/CountryServices";
import {
  updateLocation,
  uploadLocationImage,
} from "../../services/LocationServices";
import InputLabel from "../inputs/InputLabel";
import useLoader from "../../../hooks/useLoader";
import { toast } from "react-toastify";
import { createLocation } from "../../services/LocationServices";
import { getUploadsUrl } from "../../../ServiceConfig";

// -----------------------------------------------------------
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import EditorInput from "../inputs/EditorInput";
import {
  createInternship,
  updateInternship,
} from "../../services/InternshipServices";
import { getAllDepartments } from "../../services/DepartmentServices";

const InternshipFormSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  role: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  department_id: Yup.string().required("Required"),
});

function InternshipForm({ formData, edit, onSuccess }) {

  const [departmentsArray, setDepartmentsArray] = useState([])

  useEffect(() => {
    getAllDepartments().then((res) => {
      setDepartmentsArray(res.data.data.map((onedept) => ({label: onedept?.name, value: onedept?.id})))
    }).catch((er) => {
      console.log(er, "error in getting departments")
    })
  }, [])

  // ? Add new Location
  const addInternship = (values) => {
    createInternship(values)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  // ? Update Location
  const editInternship = (values) => {
    updateInternship(formData.id, values)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          title: formData?.title,
          role: formData?.role,
          department_id: formData?.department_id,
          description: formData?.description,
        }}
        validationSchema={InternshipFormSchema}
        onSubmit={(values) => {
          edit ? editInternship(values) : addInternship(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="grid grid-cols-7 gap-4">
              {/* //? location Name */}
              <div className="lg:col-span-3 col-span-7">
                <InputLabel label="Title" labelFor="" />
                <Field className="field" id="name" name="title" />
                <ErrorMessage name="title">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              {/* //? Location Tag Line */}
              <div className="lg:col-span-3 col-span-7">
                <InputLabel label="Role" labelFor="" />
                <Field className="field" id="tag_line" name="role" />
                <ErrorMessage name="role">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              <div className="lg:col-span-1 col-span-7">
                <InputLabel label="Department" labelFor="" />
                <Field as="select" name='department_id' className="field">
                    <option>Select a Department</option>
                    {
                      departmentsArray.map((onedept) => <option key={onedept.value} value={onedept.value}>{onedept.label}</option>)
                    }
                  </Field>
                <ErrorMessage name="department_id">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>

            <div className="mt-4">
              <InputLabel label="Description" labelFor="" />
              <EditorInput
                fieldKey="description"
                data={values.description}
                onChange={(data) => setFieldValue("description", data)}
              />
            </div>

            <div className="mt-4 text-center">
              <button type="submit" className="btn-primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default InternshipForm;
