import { admin } from "../../ServiceConfig";

export function getAllRoles(query) {
    return admin.get(`/roles?query=${query}`)
}
export function getAllRolesOption() {
    return admin.get(`/allRolesOption`)
}
export function createRole(data) {
    return admin.post(`/roles`, data)
}
export function updateRole(id, data) {
    return admin.put(`/roles/${id}`, data)
}
export function deleteRole(id) {
    return admin.delete(`/roles/${id}`)
}
export function getRoleById(id) {
    return admin.get(`/roles/${id}`)
}

