import { useEffect } from "react";
import DateRangeInput from "../../components/inputs/DateRangeInput";
import { useRef, useState } from "react";
import moment from "moment";
import {
  getLeadCountByCategory,
  getLeadCountBySource,
  getLeadCountByStatus,
} from "../../services/LeadService";
import BarChart from "../../graphs/BarChart";

function LeadBySource({ dateRange }) {
  const [graphData, setGraphData] = useState({});

  useEffect(() => {
    getLeadCountBySource(
      moment(dateRange.start_date).format("YYYY-MM-DD"),
      moment(dateRange.end_date).format("YYYY-MM-DD")
    )
      .then((res) => {
        console.log(res);
        setGraphData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dateRange]);

  return (
    <div className="h-full">
      <div className="flex h-full flex-col rounded-lg border p-2">
        <div className="flex items-center justify-between border-b pb-2">
          <h3 className="font-bold">Leads By Source</h3>
        </div>
        <div className="flex h-full items-center justify-center py-4">
          <BarChart label="Count" chartData={graphData} />
        </div>
      </div>
    </div>
  );
}

export default LeadBySource;
