import React, { useState } from 'react'
import CreditDebitSelector from './CreditDebitSelector'
import TransactionInput from './TransactionInput'
import { updateCreditNotes } from '../../services/CreditNotesServices'
import { toast } from 'react-toastify'

const UpdateTransaction = ({ creditNoteData, handleTableReload, balanceAmount, setBalanceAmount }) => {
    const [transactionType, setTransactionType] = useState()
    const handleOptionChange = (event) => {
        setTransactionType(event.target.id)
    }
    const handleTransaction = async (amount, creditDebitType) => {
        if (amount && creditDebitType && transactionType) {
            const data = {
                user_id: creditNoteData.data[0].user_id,
            }
            if (transactionType === 'debit') {
                if (amount > balanceAmount) {
                    toast.error('debit amount should be less than balance amount')
                    return;
                }
                data.debit = 1
                data.debit_type = creditDebitType
                data.debit_amount = amount
            } else {
                data.credit = 1
                data.credit_type = creditDebitType
                data.credit_amount = amount
            }
            updateCreditNotes(data).then(res => {
                if (res.data.status === "success") {
                    setBalanceAmount(res.data.data.balance_amount)
                    toast.success(res.data.message)
                    setTransactionType()
                    handleTableReload()
                }
            }).catch((e) => { console.log(e, "failure") })
        }
        else {
            toast.error("All Fields are Required")
        }
    }

    return (
        <div style={{ minHeight: '20rem' }} className='flex flex-col border border-slate-400 rounded-xl p-4'>
            <div className='flex justify-between w-full font-bold lg:text-xl'>
                <p>Update Transaction</p>
                <p className='text-green-700'>Current Balance : {balanceAmount}</p>
            </div>
            <div class="mt-4 border-t border-gray-300 mb-4"></div>
            <CreditDebitSelector transactionType={transactionType} handleOptionChange={handleOptionChange} />
            {transactionType && <TransactionInput handleTransaction={handleTransaction} transactionType={transactionType} setTransactionType={setTransactionType} />}
        </div>
    )
}

export default UpdateTransaction