import { Field, Form, FieldArray, Formik, ErrorMessage } from "formik";
import InputLabel from "../../components/inputs/InputLabel";
import { CancelBooking } from "../../services/BookingServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { MdClose } from "react-icons/md";

function BookingCancellationForm({
  bookingData,
  participants,
  travelModes,
  riders,
  occupancies,
  costing,
  setCancellationModal,
}) {
  const cancellationValidation = Yup.object().shape({
    refund_amount: Yup.number().max(
      bookingData.total_amount_paid -
        (bookingData?.cancellation?.refund_amount || 0),
      `Max Refundable Amount ${
        bookingData.total_amount_paid -
        (bookingData?.cancellation?.refund_amount || 0)
      }`
    ),
  });

  const navigate = useNavigate();

  const getParticipantPrice = (travelMode_id, rider_id, occupancy_id) => {
    let price = 0;

    if (rider_id) {
      price = costing?.find(
        (cst) =>
          cst.travel_mode_id === travelMode_id &&
          cst.occupancy_id === occupancy_id
      )?.package_price;
    } else {
      price = costing?.find(
        (cst) =>
          cst.travel_mode_id === travelMode_id &&
          cst.occupancy_id === occupancy_id
      )?.package_price;
    }

    return price;
  };

  const getParticipantDiscount = (travelMode_id, rider_id, occupancy_id) => {
    let discount = 0;

    if (rider_id) {
      discount = costing?.find(
        (cst) =>
          cst.travel_mode_id === travelMode_id &&
          cst.occupancy_id === occupancy_id
      )?.package_discount;
    } else {
      discount = costing?.find(
        (cst) =>
          cst.travel_mode_id === travelMode_id &&
          cst.occupancy_id === occupancy_id
      )?.package_discount;
    }

    return discount;
  };

  const getTravelModeName = (id) => {
    return travelModes.filter((tm) => tm.id === id)[0].name;
  };

  const getRiderName = (id) => {
    if (id) {
      return riders.filter((rider) => rider.id === id)[0].name;
    }
    return "N/A";
  };

  const getOccupancyName = (id) => {
    return occupancies.filter((occ) => occ.id === id)[0].name;
  };

  const cancelBooking = (values, setSubmitting) => {
    if (
      !window.confirm(
        "All participants will be cancelled. Do you want to cancel complete booking?"
      )
    )
      return;

    const activeParticipants = participants.filter(
      (participant) => participant.status === 1
    );

    let cancellation_data = {
      participant: activeParticipants.map((participant) => participant.id),
      cost: [],
      cancellation_cost: [],
      cancellation: {
        booking_id: bookingData?.id,
        cancellation_type: "full",
        package_id: bookingData?.package_id,
        event_date: bookingData?.event_date,
        total_pax: 0,
        cancellation_reason: values?.reason,
        refund_amount: values.refund_amount,
        credit_note: values.credit_note,
        amount_deducted: values.amount_deducted,
        comments: values?.comment,
        reason: values?.reason,
      },
    };

    // values?.participants?.forEach((participant) => {
    //   if (participant?.cancel) {
    //     cancellation_data.participant.push(participant.id);
    //     cancellation_data.cancellation.total_pax += 1;
    //   }
    // });

    // cancellation_data.cancellation.cancellation_type =
    //   cancellation_data.participant.length === participants.length
    //     ? "full"
    //     : "partial";

    cancellation_data.cost = [...bookingData.cost];

    values?.participants?.forEach((participant) => {
      let cost_index;

      if (participant?.rider_id) {
        cost_index = cancellation_data.cost.findIndex(
          (cst) =>
            cst.travel_mode_id === participant.travel_mode_id &&
            cst.rider_id === participant.rider_id &&
            cst.occupancy_id === participant.occupancy_id
        );
      } else {
        cost_index = cancellation_data.cost.findIndex(
          (cst) =>
            cst.travel_mode_id === participant.travel_mode_id &&
            cst.occupancy_id === participant.occupancy_id
        );
      }

      if (cost_index >= 0) {
        cancellation_data.cost[cost_index].pax -= 1;
        cancellation_data.cost[cost_index].amount =
          cancellation_data.cost[cost_index].pax *
          cancellation_data.cost[cost_index].package_with_gst;
      }
    });

    values.participants.forEach((participant) => {
      if (!participant.cancel) return;
      let bookingCost = [...bookingData?.cost];

      let selectedCost;
      let cncl_cost_index;

      if (participant?.rider_id) {
        selectedCost = bookingCost.filter(
          (cost) =>
            cost.travel_mode_id === participant.travel_mode_id &&
            cost.rider_id === participant.rider_id &&
            cost.occupancy_id === participant.occupancy_id
        );

        cncl_cost_index = cancellation_data.cancellation_cost.findIndex(
          (cncl) =>
            cncl.travel_mode_id === selectedCost[0].travel_mode_id &&
            cncl.rider_id === selectedCost[0].rider_id &&
            cncl.occupancy_id === selectedCost[0].occupancy_id
        );
      } else {
        selectedCost = bookingCost.filter(
          (cost) =>
            cost.travel_mode_id === participant.travel_mode_id &&
            cost.occupancy_id === participant.occupancy_id
        );

        cncl_cost_index = cancellation_data.cancellation_cost.findIndex(
          (cncl) =>
            cncl.travel_mode_id === selectedCost[0].travel_mode_id &&
            cncl.occupancy_id === selectedCost[0].occupancy_id
        );
      }

      if (cncl_cost_index >= 0) {
        cancellation_data.cancellation_cost[cncl_cost_index].pax += 1;
        cancellation_data.cancellation_cost[cncl_cost_index].amount =
          cancellation_data.cancellation_cost[cncl_cost_index].pax *
          cancellation_data.cancellation_cost[cncl_cost_index].package_with_gst;
      } else {
        let cancel_cost = Object.assign(selectedCost[0]);
        cancel_cost.pax = 1;
        cancel_cost.amount = cancel_cost.pax * cancel_cost.package_with_gst;
        cancellation_data.cancellation_cost.push(cancel_cost);
      }
    });

    CancelBooking(cancellation_data)
      .then((res) => {
        toast.success(res.data.message);
        setCancellationModal(false);
        navigate(`/bookings`);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      }).finally(() => {
        setSubmitting(false)
      })
  };

  return (
    <>
      <Formik
        initialValues={{
          participants: participants,
          amount_deducted: 0,
          refund_amount: 0,
          refund_type: "",
        }}
        validationSchema={cancellationValidation}
        onSubmit={(values, {setSubmitting}) => {
          cancelBooking(values, setSubmitting);
        }}
      >
        {({ values, setFieldValue, errors, touched, isSubmitting }) => (
          <Form>
            <div className="overflow-auto">
              <FieldArray name="participants">
                {({ insert, remove, push }) => (
                  <table>
                    <thead>
                      <th className="sticky left-0 border bg-white p-2">
                        {/* <input
                          type="checkbox"
                          name="select-all"
                          onChange={(e) => {
                            values.participants.forEach(
                              (participant, index) => {
                                if (e.target.checked) {
                                  setFieldValue(
                                    `participants[${index}].cancel`,
                                    true
                                  );
                                } else {
                                  setFieldValue(
                                    `participants[${index}].cancel`,
                                    false
                                  );
                                }
                              }
                            );
                          }}
                        /> */}
                      </th>
                      <th className="border p-2">Id</th>
                      <th className="border p-2">Name</th>
                      <th className="border p-2">Travel Mode</th>
                      <th className="border p-2">Rider</th>
                      <th className="border p-2">Occupancy</th>
                      <th className="border p-2">Price</th>
                      <th className="border p-2">Discount</th>
                      <th className="border p-2">Age</th>
                      <th className="border p-2">Gender</th>
                      <th className="border p-2">Phone</th>
                      <th className="border p-2">Email</th>
                    </thead>
                    {values.participants?.length > 0 &&
                      values.participants?.map((participant, index) => (
                        <tr
                          className={`rounded-lg p-4 ${
                            participant.status === 0 ? "text-[#D83F31]" : ""
                          }`}
                          key={index}
                        >
                          <td className="sticky left-0 border bg-white p-2">
                            {/* <Field
                              type="checkbox"
                              name={`participants[${index}].cancel`}
                              disabled={participant.status === 0}
                            /> */}
                            <MdClose className="text-[#D83F31]" />
                          </td>
                          <td className="border p-2">{participant?.id}</td>
                          <td className="whitespace-nowrap border p-2">
                            {participant?.first_name} {participant?.last_name}
                          </td>
                          <td className="whitespace-nowrap border p-2">
                            {getTravelModeName(participant?.travel_mode_id)}
                          </td>
                          <td className="border p-2">
                            {getRiderName(participant?.rider_id)}
                          </td>
                          <td className="whitespace-nowrap border p-2">
                            {getOccupancyName(participant?.occupancy_id)}
                          </td>
                          <td className="border p-2">
                            {getParticipantPrice(
                              participant?.travel_mode_id,
                              participant?.rider_id,
                              participant?.occupancy_id
                            )}
                          </td>
                          <td className="border p-2">
                            {getParticipantDiscount(
                              participant?.travel_mode_id,
                              participant?.rider_id,
                              participant?.occupancy_id
                            )}
                          </td>
                          <td className="border p-2">{participant?.age}</td>
                          <td className="border p-2">{participant?.gender}</td>
                          <td className="border p-2">{participant?.phone}</td>
                          <td className="border p-2">{participant?.email}</td>
                        </tr>
                      ))}
                  </table>
                )}
              </FieldArray>
            </div>
            <div className="my-8 grid grid-cols-4 gap-4">
              <div className="grow">
                <InputLabel label="Deducted Amount" />
                <Field
                  type="number"
                  name="amount_deducted"
                  max={
                    bookingData.total_amount_paid -
                    (bookingData?.cancellation?.refund_amount || 0) -
                    (bookingData?.cancellation?.amount_deducted || 0) -
                    (bookingData?.cancellation?.credit_note || 0)
                  }
                  className="field"
                />
                <ErrorMessage name={`amount_deducted`}>
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
              <div className="grow">
                <InputLabel label="Refund Amount" />
                <Field
                  type="number"
                  name="refund_amount"
                  max={
                    bookingData.total_amount_paid -
                    (bookingData?.cancellation?.refund_amount || 0) -
                    (bookingData?.cancellation?.amount_deducted || 0) -
                    (bookingData?.cancellation?.credit_note || 0)
                  }
                  className="field"
                />
                <ErrorMessage name={`refund_amount`}>
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
              <div className="grow">
                <InputLabel label="Credit Note" />
                <Field
                  type="number"
                  name="credit_note"
                  max={
                    bookingData.total_amount_paid -
                    (bookingData?.cancellation?.refund_amount || 0) -
                    (bookingData?.cancellation?.amount_deducted || 0) -
                    (bookingData?.cancellation?.credit_note || 0)
                  }
                  className="field"
                />
                <ErrorMessage name={`credit_note`}>
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              <div className="col-span-2">
                <InputLabel label="Reason" />
                <Field as="textarea" name="reason" className="field" />
                <ErrorMessage name={`reason`}>
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              <div className="col-span-2">
                <InputLabel label="Comment" />
                <Field as="textarea" name="comment" className="field" />
                <ErrorMessage name={`comment`}>
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>

            <div className="mt-4 text-center">
              <button disabled={isSubmitting} className="btn-primary">Cancel</button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default BookingCancellationForm;
