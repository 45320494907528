import React from "react";
import { useMultiStepForm } from "../../../../hooks/useMultiStepForm";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Form, Formik } from "formik";
import ItineraryUserForm from "./ItineraryUserForm";
import ItineraryDaysForm from "./ItineraryDaysForm";
import ItineraryExtraDetailForm from "./ItineraryExtraDetailForm";
import ItineraryHotelForm from "./ItineraryHotelForm";
import {
  createCustomPackage,
  deleteItineraryDraft,
  updateCustomPackage,
} from "../../../services/CustomPackageServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import useAdminAuth from "../../../hooks/useAdminAuth";

const itinerarySchema = Yup.object().shape({
  first_name: Yup.string().required("Required"),
  country_code: Yup.string().required("Required"),
  phone: Yup.string().required("Required").matches(/^\d{10}$/, "10 digits required"),
  country_id: Yup.number().required("Required"),
  starting_location: Yup.string().required("Required"),
  ending_location: Yup.string().required("Required"),
  start_date: Yup.string().required("Required"),
  no_of_nights: Yup.number().required("Required"),
  end_date: Yup.string().required("Required"),
  adults: Yup.number().min(1, "minimum 1").required("Required"),
  package_title: Yup.string().required("Required"),
  banner_image: Yup.string().required("Image Not uploaded Yet."),
  price_type: Yup.string().required("Required"),
  price: Yup.number().min(1, "Price can't be 0").required("Required"),
  booking_amount: Yup.number().required("Required"),
  inclusions: Yup.string().required("Required"),
  exclusions: Yup.string().required("Required"),
  itinerary_days: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required("Required"),
      description: Yup.string().required("Required"),
      transfer_id: Yup.string().required("Required")
    })
  ),
});

function ItineraryBuilderFormWrapper({ formData, edit, selectedDraft, setFormData }) {
  const { auth } = useAdminAuth();
  const navigate = useNavigate();

  const deleteDraft = () => {
    if (!selectedDraft) return;

    deleteItineraryDraft(selectedDraft.id)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addCustomPacakge = (values) => {
    let stays = values.itinerary_stays.filter((v) => v.stay_id);
    values.itinerary_stays = stays;

    createCustomPackage(values)
      .then((res) => {
        deleteDraft();
        localStorage.removeItem("CP");
        toast.success(res.data.message);
        navigate(`/itinerary-builder/${res.data.data.id}`);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const editCustomPackage = (values) => {
    values.id = formData.id;

    console.log("updating");

    updateCustomPackage(values)
      .then((res) => {
        // navigate(`/itinerary-builder/{values.id}`);
        toast.success(res.data.message);
        navigate(`/itinerary-builder/${res.data.data.id}`);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div>
      <div className="relative z-0">
        <Formik
          enableReinitialize
          initialValues={{
            first_name: formData?.first_name ? formData?.first_name : "",
            last_name: formData?.last_name ? formData?.last_name : "",
            country_code: formData?.country_code
              ? formData?.country_code
              : "91",
            phone: formData?.phone ? formData?.phone : "",
            email: formData?.email ? formData?.email : "",
            point_of_contact: formData?.point_of_contact
              ? formData?.point_of_contact.id
              : auth?.id,
            is_bestseller: formData?.is_bestseller ? formData?.is_bestseller : 0,
            package_type: formData ? `${formData?.package_type}` : "",
            country_id: formData?.country_id ? formData?.country_id : "",
            state_id: formData?.state_id ? formData?.state_id : "",
            starting_location: formData?.starting_location
              ? formData?.starting_location
              : "",
            ending_location: formData?.ending_location
              ? formData?.ending_location
              : "",
            start_date: formData?.start_date ? formData?.start_date : "",
            end_date: formData?.end_date ? formData?.end_date : "",
            no_of_nights: formData?.no_of_nights ? formData?.no_of_nights : 0,
            adults: formData?.adults ? formData?.adults : 0,
            children: formData?.children ? formData?.children : 0,
            infants: formData?.infants ? formData?.infants : 0,
            total_pax: formData?.total_pax ? formData?.total_pax : 0,
            price_type: formData?.price_type ? formData?.price_type : "",
            price: formData?.price ? formData?.price : 0,
            service_charge_percent: formData?.service_charge_percent
              ? formData?.service_charge_percent
              : 0,
            booking_amount: formData?.booking_amount
              ? formData?.booking_amount
              : 0,
            package_title: formData?.package_title
              ? formData?.package_title
              : "",
            banner_image: formData?.banner_image ? formData?.banner_image : "",
            day0: formData?.day0 ? formData?.day0 : null,
            itinerary_days: formData?.itinerary_days
              ? formData?.itinerary_days
              : [],
            itinerary_stays: formData?.itinerary_stays
              ? formData?.itinerary_stays
              : [{ rooms: [] }],
            payment_policy: formData?.payment_policy
              ? formData?.payment_policy
              : "",
            default_payment_policy: formData?.default_payment_policy
              ? formData?.default_payment_policy
              : true,
            default_cancellation_policy: formData?.default_cancellation_policy
              ? formData?.default_cancellation_policy
              : true,
            cancellation_policy: formData?.cancellation_policy
              ? formData?.cancellation_policy
              : "",
            visa_policy: formData?.visa_policy ? formData?.visa_policy : "",
            inclusions: formData?.inclusions ? formData?.inclusions : "",
            exclusions: formData?.exclusions ? formData?.exclusions : "",
            important_notes: formData?.important_notes
              ? formData?.important_notes
              : "",
            custom_fields: formData?.custom_fields
              ? formData?.custom_fields
              : [
                  {
                    heading: null,
                    description: null,
                  },
                ],
          }}
          validationSchema={itinerarySchema}
          onSubmit={(values) => {
            edit ? editCustomPackage(values) : addCustomPacakge(values);
          }}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <div className="mt-4">
                <ItineraryUserForm edit={edit} />
                <ItineraryHotelForm edit={edit} />
                <ItineraryDaysForm edit={edit} />
                <ItineraryExtraDetailForm
                  edit={edit}
                  selectedDraft={selectedDraft}
                  deleteDraft={deleteDraft}
                  setFormData={setFormData}
                  id={formData?.id}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ItineraryBuilderFormWrapper;
