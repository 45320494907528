import React, { useEffect, useState } from 'react'
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import { createWinners, deleteWinner, getRNRCategorieswithoutPagination, updateRnRById } from '../services/RewardsAndRecognitionServices';
import { getAllEmployeesOption } from '../services/EmployeeServices';
import InputLabel from '../components/inputs/InputLabel';
import SelectSearch from '../components/inputs/SelectSearch';
import { MdAdd, MdDelete } from "react-icons/md";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const RnRWinnersForm = ({ formData, rnrid, rnrDetails, proposedDate, presntedAt }) => {
    const navigate = useNavigate()
    const [listOfAllRewards, setListOfAllrewards] = useState([])
    const [listOfAllEmployee, setListOfAllEmployee] = useState([])
    useEffect(() => {
        getRNRCategorieswithoutPagination().then(res => {
            const newArray = []
            if (res.data.data.length > 0) {
                res.data.data.map((onereward) => newArray.push({ value: onereward.id, label: onereward.name }))
            }
            setListOfAllrewards(newArray)
        }).catch(er => {
            console.log(er)
        })

        getAllEmployeesOption().then(res => {
            const newArray = []
            if (res.data.data.data.length > 0) {
                res.data.data.data.map((oneemployee) => newArray.push({ value: oneemployee.id, label: oneemployee.full_name }))
            }
            setListOfAllEmployee(newArray)
        }).catch(er => {
            console.log(er)
        })
    }, [])

    const deleteWinnerById = (delid) => {
        deleteWinner(delid).then((res) => {
            console.log(res)
        })
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{ items: formData }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)
                const submitData = []
                if (values.items.length > 0) {
                    values.items.map((oneData) => {
                        if (oneData.employee_id !== 0 && oneData.rnr_cat_id !== 0) {
                            const newData = { rnr_id: oneData.rnr_id, employee_id: oneData.employee_id, rnr_cat_id: oneData.rnr_cat_id }
                            if (oneData.id) {
                                newData.id = oneData.id
                            }
                            submitData.push(newData)

                        }
                    })
                }
                console.log(submitData, "submit data")
                updateRnRById(rnrid, { month: rnrDetails.month, year: rnrDetails.year, proposed_at: proposedDate, presented_at: presntedAt }).then((res) => {
                    console.log(res)
                    // toast.success("Updated Dates successfully")
                }).catch((err) => {
                    console.log(err)
                    toast.error(err.response.data.message)
                })
                createWinners(submitData).then((res) => {
                    console.log(res)
                    toast.success("Added winner successfully")
                    navigate('/rnr')
                }).catch((er) => {
                    console.log(er)
                    toast.error(er.response.data.message)
                })
                setSubmitting(false)
            }}
        >
            {({ values, setFieldValue, errors, touched, isSubmitting }) => {
                console.log(values, "values here")
                return (
                    <Form>
                        <FieldArray
                            name="items"
                            render={(arrayHelpers) => (
                                <div>
                                    {values.items?.length > 0 ? (
                                        values.items.map((item, index) => {
                                            console.log(item)
                                            return (
                                                <div key={index} className="grid grid-cols-12 gap-2 my-5">
                                                    <div className='flex flex-col lg:col-span-5 col-span-3'>
                                                        <InputLabel label="Employee Name" />
                                                        <SelectSearch
                                                            // value={listOfAllEmployee.find(emp => {
                                                            //     if (emp.value === item.employee_id) return emp.value
                                                            // })}
                                                            value={item.employee_id}
                                                            options={listOfAllEmployee}
                                                            onChange={(value) =>
                                                                setFieldValue(`items.${index}.employee_id`, value)
                                                            }
                                                        />
                                                        <ErrorMessage name={`items.${index}.employee_id`}>
                                                            {(msg) => <div className="error-msg">{msg}</div>}
                                                        </ErrorMessage>
                                                    </div>
                                                    <div className='flex flex-col lg:col-span-5 col-span-3'>
                                                        <InputLabel label="Reward Category" />
                                                        <SelectSearch
                                                            value={item.rnr_cat_id}
                                                            options={listOfAllRewards}
                                                            onChange={(value) =>
                                                                setFieldValue(`items.${index}.rnr_cat_id`, value)
                                                            }
                                                        />
                                                        <ErrorMessage name={`items.${index}.rnr_cat_id`}>
                                                            {(msg) => <div className="error-msg">{msg}</div>}
                                                        </ErrorMessage>
                                                    </div>
                                                    <div className='flex items-end gap-4 col-span-1 '>
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                if (window.confirm("Do you want to delete this winner?")) {
                                                                    arrayHelpers.remove(index)
                                                                    deleteWinnerById(item?.id)
                                                                }
                                                            }}
                                                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                                        >
                                                            <MdDelete size={22} />
                                                        </button>

                                                        {(index === values.items.length - 1) && <button
                                                            type="button"
                                                            onClick={() => {
                                                                arrayHelpers.push({ rnr_cat_id: 0, employee_id: 0, rnr_id: rnrid })
                                                            }}
                                                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                                        >
                                                            <MdAdd size={22} />
                                                        </button>}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : (<>
                                        <div>No Award Winners Till Now. Click on Add button to start adding </div>
                                        {values?.items?.length === 0 && <button
                                            type="button"
                                            onClick={() => {
                                                arrayHelpers.push({ rnr_cat_id: 0, employee_id: 0, rnr_id: rnrid })
                                            }}
                                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex gap-2"
                                        >
                                            <MdAdd size={22} /> <p>Add a Winner</p>
                                        </button>}
                                    </>)}

                                </div>
                            )}
                        />
                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
                        >
                            Submit
                        </button>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default RnRWinnersForm