import { Formik, Form, Field, ErrorMessage } from "formik";
import InputLabel from "../../inputs/InputLabel";
import { addGiftCardCategory } from "../../../services/GiftCardServices";
import { toast } from "react-toastify";
import * as Yup from "yup";

const giftCardCategoryFormSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
});

function GiftCardCategoryForm({ getCategories, closeModal }) {
  const addCategory = (values) => {
    addGiftCardCategory(values)
      .then((res) => {
        toast.success(res.data.message);
        getCategories();
        closeModal();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className=" mt-4">
      <Formik
        initialValues={{
          name: "",
          description: "",
        }}
        validationSchema={giftCardCategoryFormSchema}
        onSubmit={(values) => {
          addCategory(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="mt-4">
              <div className="rounded-lg border p-4">
                <div>
                  <InputLabel label="Name" />
                  <Field className="field" name="name" />
                  <ErrorMessage name={`name`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="mt-2">
                  <InputLabel label="Description" />
                  <Field className="field" as="textarea" name="description" />
                </div>
              </div>
              <button type="submit" className="btn-primary mt-4 text-center">
                Add Category
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default GiftCardCategoryForm;
