import React from "react";
import InputLabel from "../inputs/InputLabel";

// -----------------------------------------------------------
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import SelectSearch from "../inputs/SelectSearch";
import { toast } from "react-toastify";
import { addNewStayAmenity, editStayAmenity } from "../../services/StayServices";
import { icons } from './FontIcons';


const AmenitiesSchema = Yup.object().shape({
    name: Yup.string()
        .required("Required"),
    icon: Yup.string()
        .required("Required"),
});

const AmenitiesForm = ({formData, edit, onSuccess }) => {
    const addAmenities = (values) => {
        addNewStayAmenity(values).then((res) => {
            console.log(res)
            onSuccess(res.data.data)
            toast.success("added amenity successfully")
        }).catch((er) => {
            console.log(er, "error in adding amenities")
            toast.error(er.response.data.message)
        })
    }

    const editAmenities = (values) => {
        editStayAmenity(formData.id, values).then((res) => {
            console.log(res)
            onSuccess(res.data.data)
            toast.success("updated amenity successfully")
        }).catch((er) => {
            console.log(er, "error in updating amenities")
            toast.error(er.response.data.message)
        })
    }
    return (
        <div className="h-96">
            <Formik
                enableReinitialize
                initialValues={{
                    name: formData?.name ? formData?.name : "",
                    icon: formData?.icon ? formData?.icon :"",
                }}
                validationSchema={AmenitiesSchema}
                onSubmit={(values) => {
                    edit ? editAmenities(values) : addAmenities(values);
                }}
            >
                {({ values, setFieldValue, errors, touched }) => (
                    <Form>
                        <div className="flex flex-wrap gap-4">

                            <div className="grow">
                                <div className="flex flex-wrap gap-4">
                                    <div className="lg:max-w-[50%] grow lg:basis-[45%] max-w-[100%] basis-[100%]">
                                        <InputLabel label="Name" labelFor="name" />
                                        <Field className="field" id="name" name="name" />
                                        <ErrorMessage name="name">
                                            {(msg) => <div className="error-msg">{msg}</div>}
                                        </ErrorMessage>
                                    </div>

                                    <div className="lg:max-w-[50%] grow lg:basis-[45%] max-w-[100%] basis-[100%]">
                                        <InputLabel label="Icon" labelFor="tag_line" />
                                        <SelectSearch
                                            onChange={(value) => {
                                                setFieldValue("icon", value);
                                            }}
                                            value={values.icon}
                                            options={icons.map((ic) => ({value: ic, label: <span className={ic}>   {ic}</span>}))}
                                        />

                                        <ErrorMessage name="icon">
                                            {(msg) => <div className="error-msg">{msg}</div>}
                                        </ErrorMessage>
                                    </div>
                                </div>

                                <div className="text-center mt-2">
                                    <button type="submit" className="btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default AmenitiesForm