import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import MetaTags from "../../../web/layouts/MetaTags";
import { getOccupancyById } from "../../services/OccupancyServices";
import OccupancyForm from "./OccupancyForm";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";

function EditOccupancy() {
  const navigate = useNavigate();

  const occupancyId = useParams().id;

  const [occupancy, setOccupancy] = useState();

  const onEditOccupancy = (data) => {
    navigate(`/occupancy`);
  };

  useEffect(() => {
    occupancyId &&
      getOccupancyById(occupancyId)
        .then((res) => {
          setOccupancy(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Edit Occupancy | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Occupancy</H2>
        <Link
          to="/occupancy"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {occupancy && (
        <OccupancyForm
          formData={occupancy}
          edit={true}
          onSuccess={(data) => onEditOccupancy(data)}
        />
      )}
    </div>
  );
}

export default EditOccupancy;
