import React, { useEffect, useState } from "react";
import FileInput from "../../../components/inputFields/FileInput";

import InputLabel from "../inputs/InputLabel";
import useLoader from "../../../hooks/useLoader";
import { toast } from "react-toastify";

import { getUploadsUrl } from "../../../ServiceConfig";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  createAdminStatWidget,
  updateAdminStatWidget,
} from "../../services/AdminStatWidgetService";

function AdminStatWidgetForm({ formData, edit, onSuccess }) {
  const CategoryFormSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    type: Yup.number().required("Required"),

    status: Yup.number().required("Required"),
  });
  const { setLoading } = useLoader();

  // ? Add new Category
  const addAdminStatWidget = (values) => {
    let data = Object.assign({}, values);
    console.log("logggggg");
    setLoading(true);
    createAdminStatWidget(data)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };
  // ? Update Category
  const editAdminStatWidget = (values) => {
    let data = Object.assign({}, values);
    updateAdminStatWidget(formData.id, data)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  return (
    <div>
      <Formik
        initialValues={{
          title: formData?.title,
          type: formData?.type.toString() || "0",
          status: formData?.status.toString() || "1",
          //   slug: formData?.slug,
          value: formData?.value,
          query: formData?.query,
        }}
        validationSchema={CategoryFormSchema}
        onSubmit={(values) => {
          edit ? editAdminStatWidget(values) : addAdminStatWidget(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="mb-8 flex justify-center gap-4">
              <div className="max-w-[50%] grow basis-[45%]">
                <div className="flex flex-wrap gap-4">
                  {/* //? Stat Widget name */}
                  <div className="w-full">
                    <InputLabel label="Name" labelFor="title" />
                    <Field
                      className="block w-full rounded-md border-2 py-1 px-2 dark:bg-slate-600"
                      id="title"
                      name="title"
                    />
                    <ErrorMessage name="title">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="my-4 flex flex-wrap gap-4">
                  {/* //? Select State */}
                  <div className="w-full">
                    <InputLabel label="Select Category Type" labelFor="type"/>
                    <Field
                      name="type"
                      as="select"
                      className="block w-full rounded-md border-2 py-1 px-2 disabled:cursor-not-allowed dark:bg-slate-600"
                      onChange={(e) => {
                        setFieldValue("type", e.target.value);
                      }}
                    >
                      <option>Select Category Type</option>
                      <option value="1">Dynamic</option>
                      <option value="0">Static</option>
                    </Field>
                    <ErrorMessage name="type">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                {/* //? Category Status */}
                <div className="my-4">
                  <div role="group" aria-labelledby="my-radio-group">
                    <InputLabel label="Status"/>
                    <div className="flex items-center gap-12">
                      <label className="flex items-baseline gap-1">
                        <Field type="radio" name="status" value="1" />
                        Active
                      </label>
                      <label className="flex items-baseline gap-1">
                        <Field type="radio" name="status" value="0" />
                        Inactive
                      </label>
                    </div>
                  </div>
                </div>

                {/* //? Category Description */}
                {values.type == 1 ? (
                  <div className="w-full">
                    <InputLabel
                      label="Write a SQL Query that Returns vallue as count"
                      labelFor="query"
                    
                    />
                    <Field
                      name="query"
                      as="textarea"
                      className="block w-full rounded-md border-2 py-1 px-2 dark:bg-slate-600"
                    ></Field>
                    <ErrorMessage name="query">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                ) : (
                  <div className="w-full">
                    <InputLabel label="Value" labelFor="value"/>
                    <Field
                      name="value"
                      as="input"
                      className="block w-full rounded-md border-2 py-1 px-2 dark:bg-slate-600"
                    ></Field>
                    <ErrorMessage name="value">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                )}
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn-primary dark:bg-green-800">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AdminStatWidgetForm;
