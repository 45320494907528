import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import GoogleReviewForm from "./GoogleReviewForm";
// import MetaTags from "../../../web/layouts/MetaTags";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";

function AddGoogleReview() {
  const navigate = useNavigate();

  const onAddGoogleReview = (data) => {
    navigate(`/google-reviews`);
  };

  return (
    <div className="">
      {/* <MetaTags title="Add GoogleReview | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Add GoogleReview</H2>
        <Link
          to="/google-reviews"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      <GoogleReviewForm onSuccess={(data) => onAddGoogleReview(data)} />
    </div>
  );
}

export default AddGoogleReview;
