import React from "react";
import InputLabel from "../inputs/InputLabel";

// -----------------------------------------------------------
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { addNewStayFeatures, editStayFeatures } from "../../services/StayServices";
import { toast } from "react-toastify";




const FeaturesSchema = Yup.object().shape({
    name: Yup.string()
        .required("Required")
});

const FeaturesForm = ({onSuccess, edit, formData}) => {

    const addFeatures = (values) => {
        addNewStayFeatures(values).then((res) => {
            toast.success("added feature successfully")
            onSuccess(res.data.data)
        }).catch((er) => {
            console.log(er.response.data.message)
            toast.error(er.response.data.message)
        })
    } 

    const editFeatures = (values) => {
        editStayFeatures(formData.id, values).then((res) => {
            toast.success("updated feature successfully")
            onSuccess(res.data.data)
        }).catch((er) => {
            console.log(er.response.data.message)
            toast.error(er.response.data.message)
        })
    } 

  return (
    <div>
    <Formik
        enableReinitialize
        initialValues={{
            name: formData?.name ? formData?.name : "",
        }}
        validationSchema={FeaturesSchema}
        onSubmit={(values) => {
           edit ? editFeatures(values) : addFeatures(values);
        }}
    >
        {({ values, setFieldValue, errors, touched }) => (
            <Form>
                <div className="flex flex-wrap gap-4">

                    <div className="grow">
                        <div className="flex flex-wrap gap-4">
                            <div className="lg:max-w-[50%] grow lg:basis-[45%] max-w-[100%]  basis-[100%]">
                                <InputLabel label="Name" labelFor="name" />
                                <Field className="field" id="name" name="name" />
                                <ErrorMessage name="name">
                                    {(msg) => <div className="error-msg">{msg}</div>}
                                </ErrorMessage>
                            </div>
                        </div>

                        <div className="text-center mt-2">
                            <button type="submit" className="btn-primary">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </Form>
        )}
    </Formik>
</div>
  )
}

export default FeaturesForm