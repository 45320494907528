import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import InputLabel from "../components/inputs/InputLabel";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import SelectSearch from "../components/inputs/SelectSearch";
import { addB2B, getB2BbyId, updateB2BbyId } from "../services/B2BServices";
import { getAllLocationByCountryId } from "../services/LocationServices";
import PhoneNumberInput from "../../components/inputFields/PhoneInput";

const b2bSchema = Yup.object().shape({
  firm_name: Yup.string().required("Required"),
  firm_email: Yup.string().email().required("Required"),
  firm_address: Yup.string().required("Required"),
  firm_gst: Yup.string().required("Required"),
  poc_first_name: Yup.string().required("Required"),
  poc_email: Yup.string().email().required("Required"),
  poc_phone: Yup.number().required("Required"),
  city_from: Yup.string().required("Required"),
});

function B2BForm({ formData, edit, onSuccess, profile }) {

  const [listofCity, setListOfCity] = useState([])

  useEffect(() => {
    getAllLocationByCountryId(101).then((res) => {
      setListOfCity(res.data.data)
    }).catch((err) => {
      console.log(err)
    })
  }, [])


  // ? Add new B2B
  const addnewB2B = (values) => {
    console.log(values, "values")
    addB2B(values)
      .then((res) => {
        toast.success(res.data.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error("error in adding new B2B");
        console.log(err, "not added b2b")
      });
  };

  // ? Update State
  const editB2B = (values) => {
    console.log(values)
    let data = Object.assign({}, values);
    updateB2BbyId(formData.id, data)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          firm_name: formData?.firm_name ? formData.firm_name : "",
          firm_email: formData?.firm_email ? formData.firm_email : "",
          firm_address: formData?.firm_address ? formData.firm_address : "",
          firm_gst: formData?.firm_gst ? formData.firm_gst : "",
          poc_first_name: formData?.poc_first_name ? formData.poc_first_name : "",
          poc_last_name: formData?.poc_last_name
            ? formData.poc_last_name
            : "",
          poc_email: formData?.poc_email
            ? formData.poc_email
            : "",
          poc_phone: formData?.poc_phone ? formData.poc_phone : "",
          city_from: formData?.city_from ? formData.city_from : "",
        }}
        validationSchema={b2bSchema}
        onSubmit={(values) => {
          console.log(values, "real")
          edit ? editB2B(values) : addnewB2B(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="flex flex-wrap gap-4 lg:flex-nowrap">

              <div className="my-4 grow">
                <div className="flex flex-wrap gap-4">

                  {/* //? Firm Name */}
                  <div className="grow basis-[45%] lg:max-w-[50%]">
                    <InputLabel label="Firm Name" labelFor="firm_name" />
                    <Field
                      className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                      id="firm_name"
                      name="firm_name"
                    />
                    <ErrorMessage name="firm_name">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  {/* Firm Email */}
                  <div className="grow basis-[45%] lg:max-w-[50%]">
                    <InputLabel label="Firm Email" labelFor="firm_email" />
                    <Field
                      className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                      id="firm_email"
                      name="firm_email"
                    />
                    <ErrorMessage name="firm_email">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                {/* //? Firm Address */}
                <div className="flex flex-wrap gap-4">
                  <div className="my-4 grow basis-[45%] lg:max-w-[50%]">
                    <InputLabel label="Firm Address" labelFor="firm_address" />
                    <Field
                      as="textarea"
                      id="firm_address"
                      name="firm_address"
                      className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                    />
                    <ErrorMessage name="firm_address">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>


                  {/* Firm GST */}
                  <div className="my-4 grow basis-[45%] lg:max-w-[50%]">
                    <InputLabel label="Firm GST" labelFor="firm_gst" />
                    <Field
                      id="firm_gst"
                      name="firm_gst"
                      className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                    />
                    <ErrorMessage name="firm_gst">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>


                {/* //? POC First Name */}
                <div className="flex flex-wrap gap-4">
                  <div className="my-4 grow basis-[45%] lg:max-w-[50%]">
                    <InputLabel label="POC First Name" labelFor="poc_first_name" />
                    <Field
                      id="poc_first_name"
                      name="poc_first_name"
                      className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                    />
                    <ErrorMessage name="poc_first_name">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>


                  {/* POC Last Name */}
                  <div className="my-4 grow basis-[45%] lg:max-w-[50%]">
                    <InputLabel label="POC Last Name" labelFor="poc_last_name" />
                    <Field
                      id="poc_last_name"
                      name="poc_last_name"
                      className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                    />
                    <ErrorMessage name="poc_last_name">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>


                {/* //? POC Email */}
                <div className="flex flex-wrap gap-4">
                  <div className="my-4 grow basis-[45%] lg:max-w-[50%]">
                    <InputLabel label="POC Email" labelFor="poc_email" />
                    <Field
                      id="poc_email"
                      name="poc_email"
                      className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                    />
                    <ErrorMessage name="poc_email">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>


                  {/* POC Phone */}
                  <div className="my-4 grow basis-[45%] lg:max-w-[20%]">
                    <PhoneNumberInput
                      label="POC Phone"
                      phone={`+91 ${values.poc_phone}`}
                      onChange={(value, data) => {
                        setFieldValue(`poc_phone`, data.dialCode);
                        setFieldValue(
                          `poc_phone`,
                          value.slice(data.dialCode.length)
                        );
                      }}
                    />
                    <ErrorMessage name="poc_phone">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="my-4 grow basis-[45%] lg:max-w-[20%]">
                    <InputLabel label="City From" />
                    <SelectSearch
                      value={values.city_from}
                      options={
                        listofCity.map((oneCity) => ({ label: oneCity.name, value: oneCity.id }))
                      }
                      onChange={(value) => setFieldValue("city_from", value)}
                    />
                    <ErrorMessage name="poc_phone">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 text-center">
              <button type="submit" className="btn-primary dark:bg-green-700">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default B2BForm;
