import React from "react";

const StatWidget = ({ data, textBgClasses }) => {
  console.log(
    Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(10000000),
    "Number format"
  );
  return (
    // <div className="flex-grow basis-full sm:basis-1/2 md:basis-1/4 lg:basis-1/4">
    //   <div className={`size-full  rounded-md border ${textBgClasses}  p-2`}>
    //     <div class="flex justify-between">
    //       <div class="text-md font-semibold ltr:mr-1 rtl:ml-1">
    //         {data.title}
    //       </div>
    //     </div>
    //     <div class="mt-5 flex items-center">
    //       <div class="text-3xl font-bold ltr:mr-3 rtl:ml-3"> {data.data} </div>
    //       {/* <div class=" bg-white/30">+ 2.35% </div> */}
    //     </div>
    //   </div>
    // </div>
    <div className="w-1/2 px-2 py-2 lg:w-6/12 xl:w-3/12">
      <div className="relative mb-0 flex min-h-[90px] min-w-0 flex-col break-words rounded-lg bg-white shadow-lg dark:bg-slate-900 dark:text-white xl:mb-0">
        <div className="flex-auto p-4">
          <div className="flex flex-wrap">
            <div className="relative w-full max-w-full flex-1 flex-grow pr-4">
              <h5 className="text-blueGray-400 text-xs font-bold uppercase">
                {data.title}
              </h5>
              <span className="text-xl font-bold">{data.data}</span>
            </div>
            {/* <div className="relative w-auto flex-initial pl-4">
              <div className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-red-500 p-3 text-center text-white shadow-lg">
                <i className="far fa-chart-bar"></i>
              </div>
            </div> */}
          </div>
          {/* <p className="text-blueGray-500 mt-4 text-sm">
            <span className="mr-2 text-emerald-500">
              <i className="fas fa-arrow-up"></i> 3.48%
            </span>
            <span className="whitespace-nowrap">Since last month</span>
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default StatWidget;
