import { useState } from "react";
import { countryCodes } from "../../localData/CountryCode";
import Select from "react-select";

function CountryCodeSelector({ setCountryCode }) {
  const countryOptions = [];

  const [selectedCode, setSelectedCode] = useState("91");
  const [selectOpen, setSelectOpen] = useState(false);

  const handleOptionSelect = (value) => {
    setSelectOpen(false);
    setSelectedCode(value);
    setCountryCode(value.substring(1));
  };

  const toggleDropdown = () => {
    setSelectOpen(true);
  };

  return (
    <div className="!border-0">
      {/* <Select
        value={
          value
            ? {
                value: value,
                label: options?.filter((opt) => opt.value === value)[0]?.label,
              }
            : null
        }
        options={options ? [{ label: "None", value: "" }, ...options] : []}
        onChange={(v) => onChange(v.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        ariaAutocomplete={false}
      ></Select> */}
      <Select
        value={selectedCode}
        onChange={handleOptionSelect}
        options={countryCodes.map((country) => ({
          label: (
            <div className="option-label flex items-center gap-4">
              {/* <div className="shrink-0">{country.flag}</div> */}
              <div className="shrink-0">{country.dial_code}</div>
              {/* <div className="country-name grow overflow-hidden text-ellipsis whitespace-nowrap">
                {country.name}
              </div> */}
            </div>
          ),
          value: country.dial_code,
        }))}
      />
    </div>
  );
}

export default CountryCodeSelector;
