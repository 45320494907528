import React, { useState } from 'react'
import JobPostNotificationPopup from './JobPostNotificationPopup'

const SingleJobPost = ({ onePost }) => {
    const [openModal, setOpenModal] = useState()
    return (<>
        <div
            key={onePost.id}
            className={`flex w-full items-center justify-between gap-4 rounded-md bg-white dark:text-white p-2 shadow-[1px_1px_4px_rgba(0,0,0,0.15)] transition-all duration-300 ease-out hover:shadow-md`}
        >
            <div className='flex w-1/2 flex-col'>
                <p className="lg:text-md text-sm font-bold text-gray-800  dark:text-white">
                    {onePost.title}
                </p>
                {onePost.salary && <p className="lg:text-sm text-xs text-gray-800 dark:text-white">
                    Salary: {onePost.salary}
                </p>}
            </div>
            <div className='flex w-1/2 flex-col'>
                {onePost.experience && <p className="lg:text-md text-sm text-gray-800  dark:text-white">
                    Experience:  {onePost.experience}
                </p>}
                <div className='flex gap-3'>
                    <p onClick={() => { setOpenModal(true) }} className="cursor-pointer underline  text-blue-500 text-sm font-bold rounded">
                        Read More
                    </p>
                </div>
            </div>
        </div>
        <JobPostNotificationPopup open={openModal} setOpen={setOpenModal} data={onePost} />
    </>
    )
}

export default SingleJobPost