import React, { useEffect, useRef } from "react";
import InputLabel from "../../inputs/InputLabel";
import EditorInput from "../../inputs/EditorInput";
import { ErrorMessage, Field, FieldArray, useFormikContext } from "formik";
import { MdClose } from "react-icons/md";
import { FaPlusSquare } from "react-icons/fa";

import CP_DT from "../../../../assets/image/policy/CP_DT.png";
import CP_ID from "../../../../assets/image/policy/CP_ID.png";
import PP_DT from "../../../../assets/image/policy/PP_DT.png";
import PP_ID from "../../../../assets/image/policy/PP_ID.png";
import {
  createCustomPackageAsNew,
  storeItineraryDraft,
  updateItineraryDraft,
} from "../../../services/CustomPackageServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function ItineraryExtraDetailForm({id, selectedDraft, edit, deleteDraft, setFormData }) {
  const { values, setFieldValue, resetForm } = useFormikContext();
  const navigate = useNavigate();

  const saveDraft = () => {
    if (selectedDraft) {
      updateItineraryDraft({
        id: selectedDraft.id,
        module: "itinerary",
        draft: values,
      }).then((res) => {
        localStorage.removeItem("CP");
        toast.success(res.data.message);
      });
    } else {
      storeItineraryDraft({ module: "itinerary", draft: values })
        .then((res) => {
          localStorage.removeItem("CP");
          toast.success(res.data.message);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };

  const resetWholeForm = () => {
    localStorage.removeItem("CP");
    deleteDraft()
    resetForm()
    setFormData()
  }

  const typingTimer = useRef();

  useEffect(() => {
    console.log(values);

    if (edit) return;
    clearTimeout(typingTimer.current);
    typingTimer.current = setTimeout(() => {
      localStorage.setItem("CP", JSON.stringify(values));
    }, 1000);
  }, [values]);

  const saveAsNew = () => {
    createCustomPackageAsNew(id, values).then((res) => {
      navigate(`/itinerary-builder/${res.data.data.id}`);
      toast.success("created new successfully")
    }).catch(er => {
      console.log(er, "error in creation")
      toast.error("error in saving as new")
    })
  }

  return (
    <>
      {values.package_type && (
        <div className="mt-12 border-t-4 border-dashed">
          <div>
            <FieldArray name="custom_fields">
              {({ insert, remove, push }) => (
                <div>
                  {values.custom_fields?.map((custom_field, index) => (
                    <div
                      key={index}
                      className="relative mb-1 mt-12 rounded-lg border-2 p-4"
                    >
                      <h3 className="mb-2 font-bold">Custom Section</h3>
                      <button
                        onClick={() => remove(index)}
                        className="absolute top-2 right-2 rounded-full border-2 p-1"
                      >
                        <MdClose />
                      </button>
                      <div className="">
                        <div className="my-4">
                          <InputLabel label="Heading" />
                          <Field
                            className="field"
                            name={`custom_fields.${index}.heading`}
                          />
                          <ErrorMessage name={`custom_fields.${index}.heading`}>
                            {(msg) => <div className="error-msg">{msg}</div>}
                          </ErrorMessage>
                        </div>
                        <div className="my-4">
                          <InputLabel label="Description" />
                          <EditorInput
                            id={`custom_fields.${index}.description`}
                            fieldKey={`custom_fields.${index}.description`}
                            onChange={(data) =>
                              setFieldValue(
                                `custom_fields.${index}.description`,
                                data
                              )
                            }
                            data={
                              values.custom_fields[index].description
                                ? values.custom_fields[index].description
                                : ""
                            }
                          />
                        </div>
                        {/* <div className="my-4">
                          <InputLabel label="Image" />
                          <FileInput
                            value={values.custom_fields[index].image}
                            noOfFilesAllowed={1}
                            onChange={(file) => {
                              uploadCustomPackageFieldImage({
                                image: file,
                              }).then((res) => {
                                setFieldValue(
                                  `custom_fields.${index}.image`,
                                  `${getUploadsUrl()}/custom-packages/custom-field/${
                                    res.data.data[0].uploaded_image
                                  }`
                                );
                              });
                            }}
                          />
                        </div> */}
                      </div>
                    </div>
                  ))}
                  <button
                    type="button"
                    className="mb-4 flex items-center gap-1 px-4 text-jw-green"
                    onClick={() => push({})}
                  >
                    <FaPlusSquare /> Custom Section
                  </button>
                </div>
              )}
            </FieldArray>
          </div>

          <div className="my-4">
            <InputLabel label="Inclusions" required={true} />
            <EditorInput
              id="inclusions"
              fieldKey="inclusions"
              onChange={(data) => setFieldValue("inclusions", data)}
              data={values.inclusions}
            />
            <ErrorMessage name="inclusions">
              {(msg) => <div className="error-msg">{msg}</div>}
            </ErrorMessage>
          </div>
          {/* //? Exclusion */}
          <div className="my-4">
            <InputLabel label="Exclusions" required={true} />
            <EditorInput
              id="exclusions"
              fieldKey="exclusions"
              onChange={(data) => setFieldValue("exclusions", data)}
              data={values.exclusions}
            />
            <ErrorMessage name="exclusions">
              {(msg) => <div className="error-msg">{msg}</div>}
            </ErrorMessage>
          </div>
          {/* //? Important Note */}
          <div className="my-4">
            <InputLabel label="Important Notes" />
            <EditorInput
              id="important-notes"
              fieldKey="important_notes"
              onChange={(data) => setFieldValue("important_notes", data)}
              data={values.important_notes}
            />
          </div>
          {/* //? payment Policy */}
          <div className="my-4">
            <div className="flex items-end gap-4">
              <InputLabel label="Payment Policy" />
              <label
                htmlFor="default-payment"
                className="text-sm font-semibold text-gray-600"
              >
                <input
                  type="checkbox"
                  name=""
                  id="default-payment"
                  checked={values.default_payment_policy}
                  onChange={(e) =>
                    setFieldValue(`default_payment_policy`, e.target.checked)
                  }
                />{" "}
                Include Default Payment Policy
              </label>
            </div>
            {
              <EditorInput
                id="payment-policy"
                fieldKey="payment_policy"
                onChange={(data) => setFieldValue("payment_policy", data)}
                data={values.payment_policy}
              />
            }
            {values.default_payment_policy && values.package_type === "1" && (
              <img
                src={PP_ID}
                alt="payment policy"
                className="w-full max-w-[600px]"
              />
            )}
            {values.default_payment_policy && values.package_type !== "1" && (
              <img
                src={PP_DT}
                alt="payment policy"
                className="w-full max-w-[600px]"
              />
            )}
          </div>
          {/* //? cancellation Policy */}
          <div className="my-4">
            <div className="flex items-end gap-4">
              <InputLabel label="Cancellation Policy" />
              <label
                htmlFor="default-cencellation"
                className="text-sm font-semibold text-gray-600"
              >
                <input
                  type="checkbox"
                  name=""
                  id="default-cencellation"
                  checked={values.default_cancellation_policy}
                  onChange={(e) =>
                    setFieldValue(
                      `default_cancellation_policy`,
                      e.target.checked
                    )
                  }
                />{" "}
                Include Default Cancellation Policy
              </label>
            </div>
            {
              <EditorInput
                id="cancellation_policy"
                fieldKey="cancellation_policy"
                onChange={(data) => setFieldValue("cancellation_policy", data)}
                data={values.cancellation_policy}
              />
            }
            {values.default_cancellation_policy &&
              values.package_type === "1" && (
                <img
                  src={CP_ID}
                  alt="cancellation policy"
                  className="w-full max-w-[600px]"
                />
              )}
            {values.default_cancellation_policy &&
              values.package_type !== "1" && (
                <img
                  src={CP_DT}
                  alt="cancellation policy"
                  className="w-full max-w-[600px]"
                />
              )}
          </div>
          {values.package_type !== "0" && (
            <div className="my-4">
              <InputLabel label="Visa Policy" required={true} />
              <EditorInput
                id="visa_policy"
                fieldKey="visa_policy"
                onChange={(data) => setFieldValue("visa_policy", data)}
                data={values.visa_policy}
              />
            </div>
          )}

          <div className="flex justify-center gap-4">
            {!edit && (
              <>
              <button
                type="button"
                onClick={saveDraft}
                className="btn-primary border-2 border-jw-green !bg-white !text-jw-green"
              >
                Save as Draft
              </button>
              <button
                type="button"
                onClick={resetWholeForm}
                className="btn-primary border-2 border-red-500 !bg-white text-red-500"
              >
                Reset Form
              </button>
              </>
            )}
            {
              edit && <button
              type="button"
              onClick={saveAsNew}
              className="btn-primary border-2 border-jw-green !bg-white !text-jw-green">
                Save as New
              </button>
            }
            <button className="btn-primary" type="submit">
              {edit ? 'Update' : 'Save'}
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default ItineraryExtraDetailForm;
