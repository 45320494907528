import { Outlet } from "react-router-dom";

function PageContactWrapper() {
  return (
    <div className="dark:bg-slate-900 dark:text-white h-full dark:bg-slate-900 dark:text-white rounded-lg bg-white px-4 text-gray-700">
      <div className="h-full max-h-[calc(100%-1rem)] overflow-auto">
        {<Outlet />}
      </div>
    </div>
  );
}

export default PageContactWrapper;
