import React, { useRef } from 'react'
import DateRangeInput from '../components/inputs/DateRangeInput';

const LeadsDateSelector = ({ dateRange, setDateRange }) => {
    const dateInputRef = useRef();
    return (
        <div>
            <DateRangeInput
                ref={dateInputRef}
                dateClassName="text-xs font-bold"
                startDate={dateRange.start_date}
                endDate={dateRange.end_date}
                onRangeChange={(startDate, endDate) => {
                    setDateRange((dateRange) => ({
                        start_date: startDate,
                        end_date: endDate,
                    }));
                }}
            />
        </div>
    )
}

export default LeadsDateSelector