import { admin } from "../../ServiceConfig";

export function createLocation(data) {
    return admin.post(`/locations`, data)
}

export function getAllLocation(query) {
    return admin.get(`/locations?query=${query}`)
}

export function getAllLocationByCountryId(countryId) {
    return admin.get(`/locationsByCountry/${countryId}`)
}

export function getLocationsByStateId(stateId){
    return admin.get(`/locationsByState/${stateId}`)
}

export function getLocationById(id) {
    return admin.get(`/locations/${id}`)
}

export function updateLocation(id, data) {
    return admin.put(`/locations/${id}`, data)
}

export function deleteLocation(locationId) {
    return admin.delete(`/locations/${locationId}`)
}

export function uploadLocationImage(file) {
    return admin.post(`/uploads/image?upload_path=locations`, file, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}