import { ErrorMessage, Form, Formik } from "formik";
import InputLabel from "../inputs/InputLabel";
import { toast } from "react-toastify";
import SelectSearch from "../inputs/SelectSearch";
import { useEffect } from "react";
import { getAllCategories } from "../../services/CategoryServices";
import { useState } from "react";
import { getAllCountriesOption } from "../../services/CountryServices";
import { getAllStateByCountry } from "../../services/StateServices";
import {
  getAllEmployee,
  getAllEmployeesOption,
} from "../../services/EmployeeServices";
import {
  addPageContact,
  updatePageContact,
} from "../../services/PageContactService";

function PageContactForm({ edit, formData, onEdit, onAdd }) {
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    getAllCategories().then((res) => {
      setCategories(res.data.data.data);
    });

    getAllCountriesOption().then((res) => {
      setCountries(res.data.data);
    });

    getAllEmployeesOption().then((res) => {
      setEmployees(res.data.data.data);
    });

    if (formData?.country_slug == "india") {
      getCountryStates(formData.country_slug);
    }
  }, []);

  const getCountryStates = (country) => {
    if (country == "india") {
      getAllStateByCountry(101).then((res) => {
        setStates(res.data.data);
      });
    }
  };

  const addContact = (values) => {
    addPageContact(values)
      .then((res) => {
        toast.success(res.data.message);
        onAdd(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const editContact = (values) => {
    values.id = formData.id;
    updatePageContact(values)
      .then((res) => {
        console.log(res);
        toast.success(res.data.data);
        onEdit(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div>
      <Formik
        initialValues={{
          page_name: formData?.page_name ? formData.page_name : "",
          category_slug: formData?.category_slug ? formData.category_slug : "",
          country_slug: formData?.country_slug ? formData.country_slug : "",
          state_slug: formData?.state_slug ? formData.state_slug : "",
          phone: formData?.phone ? formData.phone : "",
          watsapp: formData?.watsapp ? formData.watsapp : "",
        }}
        // validationSchema={}
        onSubmit={(values) => {
          edit ? editContact(values) : addContact(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="my-2 flex flex-wrap gap-4">
              <div className="grow">
                <InputLabel label="Page Name" />
                <SelectSearch
                  options={[
                    { value: "home-page", label: "Home Page" },
                    { value: "category", label: "Category" },
                    { value: "careers", label: "Careers" },
                    { value: "internships", label: "Internships" },
                    { value: "contact-page", label: "Contact Page" },
                    { value: "about-page", label: "About Page" },
                    { value: "corporate", label: "Corporate" },
                    { value: "all-girls-trips", label: "All Girls Trips" },
                    {
                      value: "long-weekend-trips",
                      label: "Long Weekend Trips",
                    },
                    { value: "blog", label: "Blog" },
                  ]}
                  disabled={edit}
                  value={values.page_name}
                  onChange={(value) => setFieldValue(`page_name`, value)}
                />

                <ErrorMessage name={`page_name`}>
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              {values.page_name === "category" && (
                <>
                  {" "}
                  <div className="grow">
                    <InputLabel label="Select Category" />
                    <SelectSearch
                      options={categories.map((category) => ({
                        value: category.slug,
                        label: category.name,
                      }))}
                      disabled={edit}
                      value={values.category_slug}
                      onChange={(value) =>
                        setFieldValue(`category_slug`, value)
                      }
                    />

                    <ErrorMessage name={`page_name`}>
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="grow">
                    <InputLabel label="Select Country" />
                    <SelectSearch
                      options={countries.map((country) => ({
                        value: country.slug,
                        label: country.name,
                      }))}
                      disabled={edit}
                      value={values.country_slug}
                      onChange={(value) => {
                        setFieldValue(`country_slug`, value);
                        setFieldValue(`state_slug`, "");
                        setStates([]);
                        getCountryStates(value);
                      }}
                    />

                    <ErrorMessage name={`country_slug`}>
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="grow">
                    <InputLabel label="Select State" />
                    <SelectSearch
                      options={states?.map((state) => ({
                        value: state.slug,
                        label: state.name,
                      }))}
                      disabled={edit}
                      value={values.state_slug}
                      onChange={(value) => setFieldValue(`state_slug`, value)}
                    />

                    <ErrorMessage name={`state_slug`}>
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </>
              )}
            </div>
            <div className="my-2 flex flex-wrap gap-4">
              <div className="grow">
                <InputLabel label="Phone" />
                <SelectSearch
                  options={employees.map((employee) => ({
                    value: employee.id,
                    label: `${employee.first_name} ${employee.last_name}`,
                  }))}
                  value={values.phone}
                  onChange={(value) => setFieldValue(`phone`, value)}
                />

                <ErrorMessage name={`phone`}>
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              <div className="grow">
                <InputLabel label="Whatsapp" />
                <SelectSearch
                  options={employees.map((employee) => ({
                    value: employee.id,
                    label: `${employee.first_name} ${employee.last_name}`,
                  }))}
                  value={values.watsapp}
                  onChange={(value) => setFieldValue(`watsapp`, value)}
                />

                <ErrorMessage name={`watsapp`}>
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>

            <div className="my-4 text-center">
              <button type="submit" className="btn-primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default PageContactForm;
