import { ErrorMessage, Field, Form, Formik } from "formik";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import InputLabel from "../../admin/components/inputs/InputLabel";
import EditorInput from "../../admin/components/inputs/EditorInput";

const DragAndDropList = ({ onReorder, items, children }) => {
  const handleDragEnd = (result) => {
    if (!result.destination) return; // Exit if the item was dropped outside of a droppable area
    onReorder(result.source.index, result.destination.index);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <ul {...provided.droppableProps} ref={provided.innerRef}>
            {children}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragAndDropList;
