import { faker } from "@faker-js/faker";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAdminAuth from "../../hooks/useAdminAuth";
import { logout } from "../../services/AdminServices";
import SubMenuFloating from "../menus/SubMenuFloating";
import HeaderMenuItem from "./HeaderMenuItem";
import OutsideClick from "../../../hooks/OutsideClick";
import { getUploadsUrl } from "../../../ServiceConfig";
import { FaUserAlt } from "react-icons/fa";
import { useEffect } from "react";

function HeaderWidgetWrapper({ className }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [profileSubMenuOpen, setProfileSubMenuOpen] = useState(false);

  const { auth, setAuth } = useAdminAuth();
  const handleLogout = () => {
    logout()
      .then((res) => {
        toast.success(res.data.message);
        navigate("/login");
        localStorage.removeItem("admin-token");
        setAuth({});
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    setProfileSubMenuOpen(false);
  }, [location]);

  return (
    <div className={`flex items-center gap-4 px-4 ${className}`}>
      <OutsideClick onClick={() => setProfileSubMenuOpen(false)}>
        <div className="relative z-10 dark:bg-slate-900 bg-white">
          <div onClick={() => setProfileSubMenuOpen(!profileSubMenuOpen)}>
            {auth.profile_pic && (
              <img
                src={`${getUploadsUrl()}/employees/${auth.profile_pic}`}
                className="h-8 w-8 cursor-pointer rounded-full"
                alt=""
              />
            )}
            {!auth.profile_pic && (
              <div className="rounded-full border border-jw-green p-2 text-jw-green">
                <FaUserAlt />
              </div>
            )}
          </div>
          <SubMenuFloating
            className={`absolute right-0 dark:bg-black dark:text-white shadow-lg shadow-white transition-all duration-300 ${
              profileSubMenuOpen
                ? "visible top-[calc(100%+1.25rem)]"
                : "top-10rem invisible opacity-0"
            }`}
          >
            <div className="flex items-center gap-3 border-b pb-2">
              <div>
                <img
                  src={`${getUploadsUrl()}/employees/${auth.profile_pic}`}
                  alt=""
                  width={50}
                  height={50}
                  className="max-w-[3rem] rounded-lg"
                />
              </div>
              <div>
                <h6 className="whitespace-nowrap font-semibold text-gray-600 dark:text-white">
                  {auth.first_name} {auth.last_name}
                </h6>
                <p className="text-sm font-bold text-jw-green">{auth.email}</p>
              </div>
            </div>

            <HeaderMenuItem
              linkTo={`/user/profile/${auth?.id}`}
              className="block dark:text-white"
            >
              Profile
            </HeaderMenuItem>
            {/* <HeaderMenuItem className="block">Leads</HeaderMenuItem>
            <HeaderMenuItem className="block">Booking</HeaderMenuItem> */}
            <button
              onClick={handleLogout}
              className="rounded-md px-4 py-1 font-semibold text-gray-500 dark:text-white"
            >
              Logout
            </button>
          </SubMenuFloating>
        </div>
      </OutsideClick>
    </div>
  );
}

export default HeaderWidgetWrapper;
