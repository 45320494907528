import { admin } from "../../ServiceConfig";

export function addPushNotification(data) {
  return admin.post(`/push-notification`, data);
}

export function updatePushNotification(data) {
  return admin.post(`/push-notification/${data.id}`, data);
}

export function getAllPushNotification(data) {
  return admin.get(`/push-notification`, data);
}

export function getPushNotificationById(id) {
  return admin.get(`/push-notification/${id}`);
}

export function uploadPushNotificationImage(file) {
  return admin.post(`/uploads/image?upload_path=push-notifications`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function deletePushNotification(id) {
  return admin.delete(`/push-notification/${id}`);
}
