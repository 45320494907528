import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCountryById } from "../../services/CountryServices";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";
import ThingsToKnowForm from "./ThingsToKnowForm";
import { getThingsToKnowById } from "../../services/ThingsToKnowServices";

function EditThingsToKnow() {
  const navigate = useNavigate();

  const ttkId = useParams().id;

  const [ttk, setTtk] = useState();

  const onEditThingsToKnow = (data) => {
    navigate(`/things-to-know`);
  };

  useEffect(() => {
    ttkId &&
      getThingsToKnowById(ttkId)
        .then((res) => {
          setTtk(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Edit Country | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Things To Know</H2>
        <Link
          to="/things-to-know"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {ttk && (
        <ThingsToKnowForm
          formData={ttk}
          edit={true}
          onSuccess={(data) => onEditThingsToKnow(data)}
        />
      )}
    </div>
  );
}

export default EditThingsToKnow;
