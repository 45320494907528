import { useState } from "react";
import { getReadableDate } from "../../helpers/Helper";

function LeadHistoryContainer({ lead, employees }) {
  const [expandHistory, setExpandHistory] = useState(false);

  return (
    <div
      className={`relative my-2 border p-4 ${
        ["cancelled-plan", "DNP"].includes(lead.lead_status_name)
          ? "border-red-200 bg-red-50"
          : ""
      }`}
    >
      <button
        onClick={() => setExpandHistory(!expandHistory)}
        className="absolute bottom-4 right-4 text-xs font-bold text-blue-500"
      >
        {expandHistory ? "View less" : "View Full"}
      </button>
      <div className="flex justify-between font-bold">
        <p className="text-sm">
          {getReadableDate(lead.created_at)}{" "}
          <span className="text-gray-500">via {lead.lead_source_name}</span>
        </p>
        <p
          className={`text-xs capitalize ${
            ["cancelled-plan", "dnp"].includes(lead.lead_status_name)
              ? "text-red-500"
              : ""
          }`}
        >
          {lead.lead_status_name}
        </p>
      </div>
      <p className="my-1 text-sm text-gray-500">
        Assigned To -{" "}
        <span className="font-bold text-gray-600">{lead.assigned_to}</span>{" "}
      </p>
      {expandHistory && (
        <div className="">
          <div className="text-sm text-gray-500">
            {lead?.lead_category_name && (
              <span>
                Enquired for -{" "}
                <span className="font-bold text-gray-600">
                  {lead.lead_category_name}
                </span>
              </span>
            )}
            <div className="my-1 font-bold text-gray-600">
              {lead.location} - {getReadableDate(lead.prefered_date)} -{" "}
              {lead.no_of_days > 0 && (
                <span>
                  {lead.no_of_days - 1}N/{lead.no_of_days}D
                </span>
              )}
            </div>
          </div>
          <div className="max-h-[200px] overflow-auto">
            <table className="w-full text-sm">
              <tbody>
                {lead.remark.map((rmark, index) => {
                  return (
                    <tr>
                      <td className="py-2 align-top font-bold">
                        {lead.remark.length - index}.
                      </td>
                      <td className="p-2 text-gray-500">
                        <div>{rmark.remark}</div>
                        <div className="text-xs text-gray-400">
                          -By{" "}
                          <span className="font-bold">
                            {
                              employees.filter(
                                (emp) => emp.id === rmark.created_by
                              )[0].label
                            }
                          </span>{" "}
                          on 24 Aug, 2023
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default LeadHistoryContainer;
