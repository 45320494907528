import { useEffect } from "react";
import useScreenSize from "../../../hooks/useScreenSize";
import useSideBarToggle from "../../hooks/useSideBarToggle";
import SideBarHeader from "./SideBarHeader";
import SideBarMenuWrapper from "./SideBarMenuWrapper";

function Sidebar() {
  const { sidebarOpen, lockSidebar } = useSideBarToggle();

  const { screenSize } = useScreenSize();

  useEffect(() => {
    screenSize.innerWidth >= 1024 && lockSidebar(true);
  }, []);

  return (
    <div
      className={`fixed top-0 left-0 z-20 h-full w-full bg-white text-slate-900 dark:bg-slate-900 dark:text-white lg:relative lg:w-auto ${
        sidebarOpen ? "" : "-translate-x-full lg:translate-x-0"
      }`}
    >
      <SideBarHeader />
      <SideBarMenuWrapper />
    </div>
  );
}

export default Sidebar;
