import { BsChevronDoubleRight } from "react-icons/bs";
import LogoText from "../../../assets/image/common/logo/justwravel-logo-text.png";
import LogoSmall from "../../../assets/image/common/logo/justwravel-logo-small.png";
import useSideBarToggle from "../../hooks/useSideBarToggle";
import { MdClose } from "react-icons/md";
import { memo } from "react";

function SideBarHeader() {
  const { sidebarOpen, lockSidebar, forceOpenSideBar } = useSideBarToggle();

  return (
    <div
      className={`flex h-12 items-center justify-between px-4 lg:justify-center dark:bg-slate-900  shadow-md dark:shadow-slate-700 ${sidebarOpen ? "gap-4" : ""
        }`}
    >
      <div className={`flex items-center ${sidebarOpen ? "gap-1" : ""}`}>
        <img src={LogoSmall} className={` ${sidebarOpen ? "h-6" : "h-8"}`} />
        <img
          src={LogoText}
          className={`h-4  ${sidebarOpen ? "w-full" : "w-0"}`}
        />
      </div>
      {/* <div
        onClick={() => {
          lockSidebar(!sidebarOpen);
        }}
        className={`flex cursor-pointer items-center justify-center rounded-md p-1 text-slate-900 ${sidebarOpen ? "dark:bg-slate-500 dark:text-white rotate-180 bg-gray-200" : "bg-gray-900 lg:-mr-6"
          }`}
      >
        <div className="hidden lg:block">
          <BsChevronDoubleRight />
        </div>
        <div className="block lg:hidden">
          <MdClose />
        </div>
      </div> */}
    </div>
  );
}

export default memo(SideBarHeader);
