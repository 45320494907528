import React, { Children, useEffect, useRef, useState } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { FaEdit, FaImage } from "react-icons/fa";

function FileInput({ noOfFilesAllowed, onChange, value, maxSize }) {
  const [files, setFiles] = useState([]);

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: {
        "image/jpeg": [".jpeg", ".png", ".jpg", ".gif", ".webp"],
      },
      maxFiles: noOfFilesAllowed,
      maxSize: maxSize && maxSize,
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) => {
            return Object.assign(file, {
              preview: URL.createObjectURL(file),
            });
          })
        );
        onChange(acceptedFiles);
      },
    });

  const thumbs = files.map((file) => (
    <div
      className="inline-flex h-full w-1/3 max-w-[300px] rounded-sm border"
      key={file.name}
    >
      <div className="flex min-w-0 items-center overflow-hidden">
        <img
          src={file.preview}
          className="block h-auto w-full"
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <section className="container relative h-full min-h-[10rem] w-full rounded-lg border-2 border-dashed">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div className="absolute z-10 grid h-full w-full grid-cols-3 items-center justify-center px-8 text-center">
          {!Array.isArray(value) &&
            value !== undefined &&
            files.length === 0 && (
              <div className="top absolute left-0 z-0 h-full w-full">
                <img
                  src={value}
                  alt=""
                  className="h-full w-full object-contain"
                />
              </div>
            )}
          {Array.isArray(value) &&
            value?.length > 0 &&
            value?.map((v) => (
              <div>
                <img src={v} alt="" className="h-full w-full object-contain" />
              </div>
            ))}
          {!value && files.length === 0 && (
            <div className="relative z-10">
              <FaImage className="mx-auto text-7xl text-gray-400" />
              <p className="bg-white/30 font-semibold">
                Drag 'n' drop some files here, or click to select files
              </p>
            </div>
          )}
          {(value || files.length > 0) && (
            <div className="absolute right-4 bottom-4 z-10 cursor-pointer">
              <FaEdit className="text-gry-400 text-2xl" />
            </div>
          )}
        </div>
      </div>
      <aside className="absolute top-0 left-0 flex h-full w-full flex-wrap">
        {thumbs}
      </aside>
    </section>
  );
}

export default FileInput;
