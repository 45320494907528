import { useEffect, useState } from "react";
import { getAllInternalNotifications } from "../../services/InternalNotificationsServices";
import VerticalMarquee from "../common/VerticalMarquee ";
import { getUploadsUrl } from "../../../ServiceConfig";

function InternalNotifications() {
  const [allInternalNotifications, setAllInternalNotifications] = useState();

  const getAll = async () => {
    const result = await getAllInternalNotifications();
    if (result.data.status === "success") {
      const activeNotificationArray = result.data.data.data.filter(
        (notif) => notif.status === 1
      );
      setAllInternalNotifications(activeNotificationArray);
    }
  };
  useEffect(() => {
    getAll();
  }, []);

  return (
    <div>
      <div className="flex flex-col rounded-lg border bg-white dark:border-slate-700">
        <div className="card-header-background relative flex items-center border-b bg-gray-100 p-4 dark:border-b dark:border-slate-700 dark:bg-slate-900 dark:text-white">
          <img
            src={`${getUploadsUrl()}/dashboard-icons/notifications.png`}
            className="absolute bottom-2 left-0 h-16 w-16"
          />
          <h3 className="ml-12 font-bold">Notifications</h3>
        </div>
        <div className="max-h-[50vh] overflow-auto px-2 dark:bg-gray-600">
          <div className="flex h-96 flex-col items-center justify-center gap-3 py-4">
            <VerticalMarquee
              allInternalNotifications={allInternalNotifications}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InternalNotifications;
