import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { deleteRider } from "../../services/RiderServices";
import { toast } from "react-toastify";
import DataTable from "../common/DataTable";
import { getAdminUrl } from "../../../ServiceConfig";
import ComponentHeader from "../common/ComponentHeader";
import { FaPlusSquare } from "react-icons/fa";

import H2 from "../../../components/headings/H2";

function RiderList() {
  const navigate = useNavigate();
  const RiderTable = useRef();

  const deleteSelectedRider = (id) => {
    deleteRider(id)
      .then((res) => {
        toast.success(res.data.message);
        RiderTable.current.deleteRow(id);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
  <div class="flex items-center gap-3">
      <div class="rider-edit-btn cursor-pointer" data-id=${
        cell.getRow().getData().id
      }>
          <i class="fas fa-edit"></i>
      </div>
      <div class="rider-delete-btn cursor-pointer text-red-500" data-id=${
        cell.getRow().getData().id
      }>
        <i class="fas fa-trash-alt"></i>
      </div>
  </div>`;
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Name",
      field: "name",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },

    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  useEffect(() => {
    $(document).on("click", ".rider-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/rider/edit/${cell}`);
    });

    $(document).on("click", ".rider-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      deleteSelectedRider(dataId);
    });

    return () => {
      $(document).off("click", ".rider-edit-btn");
      $(document).off("click", ".rider-delete-btn");
    };
  }, []);

  return (
    <div className="">
      <ComponentHeader className="border-b">
        <H2>Riders</H2>
        <Link
          to="/rider/add"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaPlusSquare /> Add New
        </Link>
      </ComponentHeader>
      <div className="">
        <DataTable
          ref={RiderTable}
          ajaxUrl={`${getAdminUrl()}/riders`}
          columns={columns}
          search={true}
          responsiveLayout="collapse"
        />
      </div>
    </div>
  );
}

export default RiderList;
