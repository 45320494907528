import { admin } from "../../ServiceConfig";

export function getAllTravelModes(query) {
    return admin.get(`/travelModes?query=${query}`)
}
export function getAllTravelModesOption() {
    return admin.get(`/allTravelModesOption`)
}
export function createTravelMode(data) {
    return admin.post(`/travelModes`, data)
}
export function updateTravelMode(id, data) {
    return admin.put(`/travelModes/${id}`, data)
}
export function deleteTravelMode(id) {
    return admin.delete(`/travelModes/${id}`)
}
export function getTravelModeById(id) {
    return admin.get(`/travelModes/${id}`)
}

