import React, { useEffect, useState } from "react";
import { FaListAlt } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import H2 from "../../../components/headings/H2";
import ComponentHeader from "../common/ComponentHeader";
import { getStayCategoryById } from "../../services/StayServices";
import StayCategoryForm from "./StayCategoryForm";

function EditStayCategories() {
  const navigate = useNavigate();

  const stayCategoriesId = useParams().id;

  const [catData, setCatData] = useState();

  const onEditState = (data) => {
    navigate(`/stay-categories`);
  };

  useEffect(() => {
    stayCategoriesId &&
    getStayCategoryById(stayCategoriesId)
        .then((res) => {
          setCatData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Edit State | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Stay Categories</H2>
        <Link
          to="/stay-categories"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {catData && (
        <StayCategoryForm
          formData={catData}
          edit={true}
          onSuccess={(data) => onEditState(data)}
        />
      )}
    </div>
  );
}

export default EditStayCategories;
