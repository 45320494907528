import React, { useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";

// ? ----------------- Services ----------------

import { toast } from "react-toastify";

import UserAuthImage from "./assets/image/User/justwravel-user-auth.png";

import H2 from "./components/headings/H2";
import EmailInput from "./components/inputFields/EmailInput";
import PasswordInput from "./components/inputFields/PasswordInput";
import {
  checkLogin,
  login,
  loginWithGoogle,
} from "./admin/services/AdminServices";
import useAdminAuth from "./admin/hooks/useAdminAuth";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

const loginFormSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(5, "Password must be at least 6 characters"),
});

function AdminLogin() {
  const formRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();

  const { auth, setAuth } = useAdminAuth();
  const from = location.state?.from?.pathname || "/";

  !auth?.id &&
    checkLogin().then((res) => {
      setAuth(res.data.data);
      navigate(from, { replace: true });
    });

  // ? ----------------- Login or SignUp With Otp ----------------
  const handleUserLogin = (values) => {
    login(values)
      .then((res) => {
        console.log(res.data.data);
        let user = res.data.data;
        user.type = "admin";
        setAuth(user);
        localStorage.setItem("admin-token", res.data.data.token);
        navigate(from, { replace: true });

        toast.success(res.data.message);
      })
      .catch((err) => {
        localStorage.removeItem("admin-token");
        toast.error(err.response.data.message);
      });
  };

  const getLoggedinWithGoogle = (googledata) => {
    loginWithGoogle(googledata)
      .then((res) => {
        let user = res.data.data;
        setAuth(user);
        localStorage.setItem("admin-token", res.data.data.token);
        navigate(from, { replace: true });

        toast.success(res.data.message);
      })
      .catch((err) => {
        localStorage.removeItem("admin-token");
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    const keyDownHandler = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        formRef.current.handleSubmit();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  return (
    <motion.div
      className="w-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3, delay: 0.3 } }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <div>
        <div className="flex flex-col gap-4 py-4 lg:min-h-[calc(100vh-3rem)] lg:flex-row">
          <div className="after:bg-jw-green-dark/25 relative flex grow basis-1/2 items-center p-4 after:absolute after:top-1/2 after:right-0 after:hidden after:h-3/4 after:w-1 after:-translate-y-1/2 after:content-[''] lg:px-8 after:lg:block">
            <img
              src={UserAuthImage}
              alt=""
              className="mx-auto w-3/4 drop-shadow-lg"
            />
          </div>
          <div className="flex grow items-center justify-center">
            <div className="w-full px-4 lg:px-16">
              <H2 className={"mb-4 text-center"}>Login</H2>
              {/* <H2>Running Env: {process.env.REACT_APP_ENVIRONMENT}</H2> */}

              <Formik
                innerRef={formRef}
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={loginFormSchema}
                onSubmit={(values) => {
                  // alert(JSON.stringify(values));
                  handleUserLogin(values);
                }}
              >
                {({ values, setFieldValue, errors, touched }) => (
                  <Form>
                    <div>
                      <div className="my-4">
                        <EmailInput
                          id="email"
                          value={values.email}
                          onChange={(e) =>
                            setFieldValue("email", e.target.value)
                          }
                        />
                        <ErrorMessage name="email">
                          {(msg) => <div className="error-msg">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="mt-4">
                        <PasswordInput
                          id="password"
                          autoComplete="new password"
                          value={values.password}
                          onChange={(e) =>
                            setFieldValue("password", e.target.value)
                          }
                        />
                        <ErrorMessage name="password">
                          {(msg) => <div className="error-msg">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="text- text-right">
                        <Link
                          to="/forgot-password"
                          // state={{
                          //   userPhoneOrEmail: userPhoneOrEmail,
                          //   loginUsingEmail: loginUsingEmail,
                          // }}
                          className="font-semibold text-jw-green"
                        >
                          Forgot Password
                        </Link>
                      </div>
                    </div>
                    <div className="mt-4 text-center">
                      <button className="btn-primary w-full" type="submit">
                        Login
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
              <div className="mt-2">
                {/* //? Login Button */}
                <h3 className="my-1 text-center text-lg font-bold">Or</h3>

                {/* //? Google Login Button */}
                <GoogleOAuthProvider clientId="860956348662-69kuucvg077obfehiu5uv2t6r8cpgdag.apps.googleusercontent.com">
                  <div className="flex w-full items-center justify-center">
                    <GoogleLogin
                      type="statndard"
                      theme="outline"
                      shape="pill"
                      text="contunue_with"
                      size="large"
                      width="100%"
                      logo_alignment="center"
                      onSuccess={(credentialResponse) => {
                        getLoggedinWithGoogle(credentialResponse);
                      }}
                      onError={() => {
                        console.log("Login Failed");
                      }}
                      useOneTap={true}
                    />
                  </div>
                </GoogleOAuthProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default AdminLogin;
