import { admin } from "../../ServiceConfig";

export function createAdminStatWidget(data) {
    return admin.post(`/widget`, data)
}

export function getAllAdminStatWidget() {
    return admin.get(`/widget`)
}

export function getAdminStatWidgetById(id) {
    return admin.get(`/widget/${id}`)
}

// export function getAdminStatWidgetPackages(categoryId) {
//     return admin.get(`/widget/${categoryId}/packages`);
// }

export function updateAdminStatWidget(id, data) {
    return admin.put(`/widget/${id}`, data)
}

export function deleteAdminStatWidget(locationId) {
    return admin.delete(`/widget/${locationId}`)
}

// export function uploadAdminStatWidgetImage(file) {
//     return admin.post(`/uploads/image?upload_path=widget`, file, {
//         headers: {
//             "Content-Type": "multipart/form-data"
//         }
//     })
// }