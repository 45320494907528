import React, { useState } from "react";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { createState } from "../../services/StateServices";
// import MetaTags from "../../../web/layouts/MetaTags";
import PackageForm from "./packageForms/PackageForm";
import ComponentHeader from "../common/ComponentHeader";
import { Link } from "react-router-dom";
import { FaListAlt } from "react-icons/fa";
import { PackageDataProvider } from "../../context/PackageDataProvider";

function AddPackage() {
  const navigate = useNavigate();

  return (
    <div className="py-4 lg:p-4">
      {/* <MetaTags title="Add Package | Admin Panel" /> */}
      <ComponentHeader className="border-b px-0 lg:px-4">
        <h2 className="text-xl font-bold">Add Package</h2>

        <Link
          to="/package"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      <div className="mt-4">
        <PackageDataProvider>
          <PackageForm />
        </PackageDataProvider>
      </div>
    </div>
  );
}

export default AddPackage;
