import H2 from "../../components/headings/H2";
import ComponentHeader from "../components/common/ComponentHeader";
import LeadReportDonut from "../widgets/leads/LeadReportDonut";
import InputLabel from "../components/inputs/InputLabel";
import { useState } from "react";
import useAdminAuth from "../hooks/useAdminAuth";
import { submitReport } from "../services/LeadService";
import { toast } from "react-toastify";
import moment from "moment";

function LeadReport() {
  const { auth } = useAdminAuth();

  const [selectedEmployee, setSelectedEmployee] = useState(auth.id);
  const [report, setReport] = useState({});
  const [comment, setComment] = useState();
  const [dateRange, setDateRange] = useState({
    start_date: moment(new Date())
      .subtract(1, "days")
      .utcOffset("+05:30")
      .format(),
    end_date: new Date(),
  });

  const sendReport = () => {
    submitReport({
      ...report,
      comments: comment,
      employee_id: selectedEmployee,
      report_generated_by: auth.id,
      date_from: moment(dateRange.start_date).format("YYYY-MM-DD"),
      date_to: moment(dateRange.end_date).format("YYYY-MM-DD"),
    })
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="flex flex-col gap-4">
      <ComponentHeader className="border-b">
        <div className="flex flex-wrap items-center gap-8">
          <H2>Lead Report</H2>
        </div>
      </ComponentHeader>

      <div className="flex gap-4">
        <div className="h-[400px] grow basis-1/2">
          <LeadReportDonut
            selectedEmployee={selectedEmployee}
            setSelectedEmployee={setSelectedEmployee}
            report={report}
            setReport={setReport}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        </div>
        <div className="flex grow basis-1/2 flex-col gap-4">
          <div>
            <InputLabel label="Comment" />
            <textarea
              className="field"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
          </div>

          <div className="text-center">
            <button onClick={sendReport} className="btn-primary">
              Send Report
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeadReport;
