import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import MetaTags from "../../../web/layouts/MetaTags";
import { getGoogleReviewById } from "../../services/GoogleReviewsServices";
import GoogleReviewForm from "./GoogleReviewForm";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";

function EditGoogleReview() {
  const navigate = useNavigate();

  const googleReviewId = useParams().id;

  const [googleReview, setGoogleReview] = useState();

  const onEditGoogleReview = (data) => {
    navigate(`/google-reviews`);
  };

  useEffect(() => {
    googleReviewId &&
      getGoogleReviewById(googleReviewId)
        .then((res) => {
          // res.data.data.country_id = res.data.data.country.id;
          // res.data.data.state_id = res.data.data.state.id;
          // if (
          //   res.data.data.meta_keywords != null &&
          //   res.data.data.meta_keywords != ""
          // ) {
          //   res.data.data.meta_keywords =
          //     res.data.data.meta_keywords.split(",");
          // } else {
          //   res.data.data.meta_keywords = [];
          // }
          setGoogleReview(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Edit GoogleReview | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Google Review</H2>
        <Link
          to="/google-reviews"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {googleReview && (
        <GoogleReviewForm
          formData={googleReview}
          edit={true}
          onSuccess={(data) => onEditGoogleReview(data)}
        />
      )}
    </div>
  );
}

export default EditGoogleReview;
