import { useEffect, useRef } from "react";
import { getAdminUrl } from "../../ServiceConfig";
import H2 from "../../components/headings/H2";
import ComponentHeader from "../components/common/ComponentHeader";
import DataTable from "../components/common/DataTable";
import { toast } from "react-toastify";
import {
  deleteLeadCategory,
  deleteLeadSource,
  deleteLeadStatus,
  updateLeadCategory,
  updateLeadSource,
  updateLeadStatus,
} from "../services/LeadService";
import $ from "jquery";
import { isObjectEmpty } from "../../helpers/Helper";

function LeadSettings() {
  const leadCategoryTable = useRef();
  const leadStatusTable = useRef();
  const leadSourceTable = useRef();

  const updateCategory = (value) => {
    if (!value.category) {
      toast.error("Category is required");
      return;
    }

    updateLeadCategory(value)
      .then((res) => {
        leadCategoryTable.current.updateTable();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  const updateStatus = (value) => {
    if (!value.status) {
      toast.error("Status is required");
      return;
    }

    updateLeadStatus(value)
      .then((res) => {
        leadStatusTable.current.updateTable();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const updateSource = (value) => {
    if (!value.source) {
      toast.error("source is required");
      return;
    }

    updateLeadSource(value)
      .then((res) => {
        leadSourceTable.current.updateTable();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
    <div class="delete-btn" data-id=${JSON.stringify(
      cell.getRow().getData()
    )} style="cursor: pointer;">
    <svg width="16px" height="16px" x="0px" y="0px" fill="red"
     viewBox="0 0 172.541 172.541" style="enable-background:new 0 0 172.541 172.541;" xml:space="preserve">
<g>
    <path d="M166.797,25.078h-13.672h-29.971V0H49.388v25.078H19.417H5.744v15h14.806l10,132.463h111.443l10-132.463h14.805V25.078z
         M64.388,15h43.766v10.078H64.388V15z M128.083,157.541H44.46L35.592,40.078h13.796h73.766h13.796L128.083,157.541z"/>
    <rect x="80.271" y="65.693" width="12" height="66.232"/>
    <rect x="57.271" y="65.693" width="12" height="66.232"/>
    <rect x="103.271" y="65.693" width="12" height="66.232"/>
</g>
</svg></div>
  </div>`;
  };

  const categoryColumns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Category",
      field: "category",
      minWidth: "100",
      vertAlign: "middle",
      editor: "input",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      validator: ["required", "unique"],
      resizable: true,
    },

    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];
  const statusColumns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Status",
      field: "status",
      minWidth: "100",
      vertAlign: "middle",
      editor: "input",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      validator: ["required", "unique"],
      resizable: true,
    },

    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  const sourceColumns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Source",
      field: "source",
      minWidth: "200",
      vertAlign: "middle",
      editor: "input",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      validator: ["required", "unique"],
      resizable: true,
    },

    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  const deleteRow = (data) => {
    let value = JSON.parse(data);

    if (value.status) {
      if (!window.confirm("Do You really want to delete this lead status?"))
        return;
      deleteLeadStatus(value.id)
        .then((res) => {
          leadStatusTable.current.deleteRow(value.id);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    } else if (value.category) {
      if (!window.confirm("Do You really want to delete this lead category?"))
        return;
      deleteLeadCategory(value.id)
        .then((res) => {
          leadCategoryTable.current.deleteRow(value.id);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    } else if (value.source) {
      if (!window.confirm("Do You really want to delete this lead source?"))
        return;
      deleteLeadSource(value.id)
        .then((res) => {
          leadSourceTable.current.deleteRow(value.id);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    } else {
    }
  };

  useEffect(() => {
    $(document).on("click", ".delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let data = $(this).attr("data-id");
      deleteRow(data);
    });

    return () => {
      $(document).off("click", ".delete-btn");
    };
  }, []);

  return (
    <div>
      <ComponentHeader className="border-b">
        <H2>Lead Settings</H2>
      </ComponentHeader>

      <div className="flex flex-wrap">
        <div className="mb-8 w-full overflow-hidden px-2 md:w-1/2 lg:w-1/2 xl:w-1/3">
          <DataTable
            ref={leadCategoryTable}
            ajaxUrl={`${getAdminUrl()}/lead-category`}
            layout="fitColumns"
            columns={categoryColumns}
            search={true}
            onCellEdit={updateCategory}
            addRowBtn="Add Category"
            responsiveLayout="collapse"
          />
        </div>
        <div className="mb-8 w-full overflow-hidden px-2 md:w-1/2 lg:w-1/2 xl:w-1/3">
          <DataTable
            ref={leadStatusTable}
            ajaxUrl={`${getAdminUrl()}/lead-status`}
            layout="fitColumns"
            columns={statusColumns}
            search={true}
            onCellEdit={updateStatus}
            addRowBtn="Add Status"
            responsiveLayout="collapse"
          />
        </div>

        <div className="mb-8 w-full overflow-hidden px-2 md:w-1/2 lg:w-1/2 xl:w-1/3">
          <DataTable
            ref={leadSourceTable}
            ajaxUrl={`${getAdminUrl()}/lead-source`}
            layout="fitColumns"
            columns={sourceColumns}
            search={true}
            onCellEdit={updateSource}
            addRowBtn="Add Source"
            responsiveLayout="collapse"
          />
        </div>
      </div>
    </div>
  );
}

export default LeadSettings;
