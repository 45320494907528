import { admin } from "../../ServiceConfig";

export function addNewTransfer(data) {
  return admin.post(`/transfer`, data);
}

export function updateTransfer(data, id) {
  return admin.put(`/transfer/${id}`, data);
}

export function getTransferById(id) {
  return admin.get(`/transfer/${id}`);
}

export function getAllTransfers() {
  return admin.get(`/transfer`);
}

export function deleteTransferById(id) {
  return admin.delete(`/transfer/${id}`);
}

export function uploadTransferImage(file) {
  return admin.post(`uploads/image?upload_path=transfers`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
