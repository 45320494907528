import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// import MetaTags from "../../../web/layouts/MetaTags";
import ComponentHeader from "../../components/common/ComponentHeader";
import H2 from "../../../components/headings/H2";
import { FaListAlt } from "react-icons/fa";
import BookingForm from "../BookingForms/BookingForm";
import { useEffect } from "react";
import { getLeadById } from "../../services/LeadService";
import { useState } from "react";
import ToggleInput from "../../components/inputs/ToggleInput";
import {
  getAllCustomItineraries,
  getCustomPackageById,
} from "../../services/CustomPackageServices";
import SelectSearch from "../../components/inputs/SelectSearch";
import InputLabel from "../../components/inputs/InputLabel";
import { createProformaInvoice } from "../../services/ProformaInvoiceServices";
import { toast } from "react-toastify";
import { MetaTagsDefault } from "../../components/Metatags";

function AddProformaInvoice() {
  const navigate = useNavigate();

  const { leadId } = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const itinerary = searchParams.get("itinerary");

  const [leadData, setLeadData] = useState();
  const [isCustomized, setIsCustomized] = useState(0);
  const [itineraryData, setItineraryData] = useState({});
  const [customItineraries, setCustomItineraries] = useState();
  const [selectedItinerary, setSelectedItinerary] = useState();
  const [packageName, setPackageName] = useState();
  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    itinerary != null &&
      getCustomPackageById(itinerary)
        .then((res) => {
          console.log(res.data.data);
          setIsCustomized(1);
          setItineraryData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [itinerary]);

  useEffect(() => {
    if (!leadId) return;

    getLeadById(leadId).then((res) => {
      setLeadData(res.data.data);
    });
  }, [leadId]);

  useEffect(() => {
    getAllCustomItineraries()
      .then((res) => {
        setCustomItineraries(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!selectedItinerary) return;

    getCustomPackageById(selectedItinerary)
      .then((res) => {
        setItineraryData(res.data.data);
      })
      .catch((err) => { console.log(err) });
  }, [selectedItinerary]);

  const onSubmit = (values, setSubmitting) => {
    let total_pax = 0;
    let total_cost = 0;
    let total_discount = 0;
    let total_cost_after_discount = 0;
    let total_gst = 0;

    values.cost.forEach((cost) => {
      total_pax += parseInt(cost.pax);
      total_cost += cost.package_price * parseInt(cost.pax);
      total_discount += cost.package_discount * parseInt(cost.pax);
      total_cost_after_discount +=
        total_cost - cost.package_discount * parseInt(cost.pax);
      total_gst += cost.package_gst * parseInt(cost.pax);
      cost.package_itinerary_id = values.itinerary_id;
    });
    values.addons.forEach((addon, index) => {
      if (!addon.amount || addon.amount === 0) {
        values.addons.splice(index, 1);
      } else {
        total_cost += parseFloat(addon.unit_price) * parseFloat(addon.unit);
        total_gst += parseFloat(addon.unit_gst) * parseFloat(addon.unit);
        total_cost_after_discount +=
          parseFloat(addon.unit_price) * parseFloat(addon.unit);
      }
    });

    values.is_customized = isCustomized;
    values.total_pax = total_pax;
    values.total_cost = total_cost;
    values.total_discount = total_discount;
    values.total_cost_after_discount = total_cost_after_discount;
    values.total_amount =
      total_gst + total_cost_after_discount + values.total_tcs;
    values.total_gst = total_gst;
    values.booking_status = 1;
    values.payment.amount = values.total_amount_paid;
    values.payment.payment_type =
      values.remaining_amount > 0 ? "partial amount" : "full amount";
    console.log(values);
    values.package_name = packageName ? packageName : values.package_name;

    values.other_services = values.other_services.filter(
      (obj) => Object.keys(obj).length !== 0
    );

    // if (isCustomized) {
    // createCustomBooking({ booking: values })
    //   .then((res) => {
    //     toast.success(res.data.message);
    //     navigate(`/bookings`);
    //   })
    //   .catch((err) => {
    //     toast.error(err.response.data.message);
    //   })
    //   .finally(() => {
    //     setSubmitting(false);
    //   });
    // } else {
    createProformaInvoice({
      proforma: values,
      secret_code: values.secret_code,
    })
      .then((res) => {
        navigate("/bookings/proforma-invoice");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="">
      <MetaTagsDefault title="Add Proforma Invoice | Admin Panel" />
      <ComponentHeader className="mb-4 border-b">
        <div className="flex flex-wrap gap-4 lg:items-center">
          <H2>Add Proforma Invoice</H2>
          <div className="hidden items-center gap-1 lg:flex">
            <ToggleInput
              checked={isCustomized}
              onToggle={(e) => setIsCustomized(e.target.checked ? 1 : 0)}
            />
            <span className="font-medium">Customized Booking</span>
          </div>
        </div>
        <Link
          to="/bookings/proforma-invoice"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>

      <div className="mb-2 flex items-center gap-1 lg:hidden">
        <ToggleInput
          checked={isCustomized}
          onToggle={(e) => setIsCustomized(e.target.checked ? 1 : 0)}
        />
        <span className="font-medium">Customized Booking</span>
      </div>

      <BookingForm
        itineraryId={itineraryData?.id}
        onSubmit={onSubmit}
        leadData={leadData}
        proformaInvoice={true}
        setPackageName={setPackageName}
        isCustomized={isCustomized}
        formData={itineraryData}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
      />
    </div>
  );
}

export default AddProformaInvoice;
