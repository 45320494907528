function InputLabel({ className, labelFor, label, instruction, required }) {
  return (
    <label
      htmlFor={labelFor}
      className={`block font-semibold text-gray-500 dark:text-white ${className}`}
    >
      {label}
      <span className={`text-red-500 ${required ? "" : "hidden"}`}>*</span>{" "}
      <span className="ml-1 text-xs text-gray-400">{instruction}</span>
    </label>
  );
}

export default InputLabel;
