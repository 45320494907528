import moment from "moment/moment";
import HeaderMenuItem from "./HeaderMenuItem";

function HeaderMenuWrapper() {
  return (
    <div className="flex items-center gap-1 px-4 text-gray-500 dark:bg-slate-900 dark:text-white">
      {/* <HeaderMenuItem className="bg-green-50 dark:bg-green-800 dark:text-white text-gray-700">
        Dashboard
      </HeaderMenuItem>
      <HeaderMenuItem className="dark:text-white">Menu</HeaderMenuItem> */}
    </div>
  );
}

export default HeaderMenuWrapper;
