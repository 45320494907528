import { createContext, useState } from "react";

const DataTableContext = createContext();

export const DataTableProvider = ({ children }) => {
  const [selectedData, setSelectedData] = useState([]);

  return (
    <DataTableContext.Provider value={{ selectedData, setSelectedData }}>
      {children}
    </DataTableContext.Provider>
  );
};

export default DataTableContext;
