import FileInput from "../../../components/inputFields/FileInput";

import InputLabel from "../inputs/InputLabel";
import { toast } from "react-toastify";

// -----------------------------------------------------------
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  createThingsToKnow,
  updateThingsToKnow,
} from "../../services/ThingsToKnowServices";
import DateInput from "../inputs/DateInput";
import {
  createSpecialDate,
  getSpecialDateById,
  updateSpecialDate,
} from "../../services/SpecialDateServices";
import { formatNumberToTwoDigit } from "../../../helpers/Helper";
import { useState } from "react";
import { useEffect } from "react";

const SdFormSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  start_date: Yup.string().required("Required"),
  end_date: Yup.string().required("Required"),
});

function SpecialDatesForm({ sdIdToEdit, setSdIdToEdit, onSuccess }) {
  // ? Add new ttk

  const [sdData, setSdData] = useState({});

  useEffect(() => {
    if (!sdIdToEdit) return;

    getSpecialDateById(sdIdToEdit)
      .then((res) => {
        console.log(res);
        setSdData(res.data.data);
      })
      .catch((err) => {});
  }, [sdIdToEdit]);

  const addSpecialDates = (values, resetForm) => {
    createSpecialDate(values)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
        setSdIdToEdit({});
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  // ? Update ttk
  const editSpecialDates = (values, resetForm) => {
    updateSpecialDate(sdData.id, values)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
        setSdIdToEdit({});
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          title: sdData?.title || "",
          start_date: sdData?.start_date || "",
          end_date: sdData?.end_date || "",
          status: sdData?.stauts ? `${sdData?.stauts}` : "0",
        }}
        validationSchema={SdFormSchema}
        onSubmit={(values, { resetForm }) => {
          // console.log(values);
          sdIdToEdit
            ? editSpecialDates(values, resetForm)
            : addSpecialDates(values, resetForm);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="my-8 flex flex-wrap gap-4">
              <div className="grow">
                <InputLabel label="Title" />
                <Field name="title" className="field" />
                <ErrorMessage name="title">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
              <div className="grow">
                <InputLabel label="Start Date" />
                <DateInput
                  value={values.start_date}
                  onDateChange={(value) =>
                    setFieldValue(
                      `start_date`,
                      `${new Date(
                        value
                      ).getFullYear()}-${formatNumberToTwoDigit(
                        new Date(value).getMonth() + 1
                      )}-${formatNumberToTwoDigit(new Date(value).getDate())}`
                    )
                  }
                />
                <ErrorMessage name="start_date">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
              <div className="grow">
                <InputLabel label="End Date" />
                <DateInput
                  value={values.end_date}
                  onDateChange={(value) =>
                    setFieldValue(
                      `end_date`,
                      `${new Date(
                        value
                      ).getFullYear()}-${formatNumberToTwoDigit(
                        new Date(value).getMonth() + 1
                      )}-${formatNumberToTwoDigit(new Date(value).getDate())}`
                    )
                  }
                />
                <ErrorMessage name="end_date">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
              <div className="">
                <InputLabel label="Status" />
                <div
                  onChange={(e) => setFieldValue("status", e.target.value)}
                  className="flex h-8 items-center gap-12"
                >
                  <label htmlFor="active">
                    <Field type="radio" name="status" id="active" value="1" />{" "}
                    Active
                  </label>
                  <label htmlFor="inactive">
                    <Field type="radio" name="status" id="inactive" value="0" />{" "}
                    Inactive
                  </label>
                </div>
              </div>
              <div className="w-full self-end text-center lg:ml-8 lg:w-auto">
                <button type="submit" className="btn-primary">
                  {sdIdToEdit ? "Update" : "Add New"}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SpecialDatesForm;
