import React, { useEffect, useState } from "react";

import { updateGoogleReview } from "../../services/GoogleReviewsServices";
import InputLabel from "../inputs/InputLabel";
import useLoader from "../../../hooks/useLoader";
import { toast } from "react-toastify";
import { createGoogleReview } from "../../services/GoogleReviewsServices";

// -----------------------------------------------------------
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { getAllPackages } from "../../services/PackageService";
import SelectSearch from "../inputs/SelectSearch";
import { getAllCategories } from "../../services/CategoryServices";

const GoogleReviewFormSchema = Yup.object().shape({
  author_name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  // package_id: Yup.string().required("Required"),
  // state_id: Yup.string().when("country_id", {
  //   is: (country_id) => country_id === "101",
  //   then: Yup.string().required("Required"),
  //   otherwise: Yup.string().nullable(),
  // }),
  status: Yup.number().required("Required"),
  // meta_title: Yup.string()
  //   .nullable()
  //   .max(70, "Meta Title should not more than 70 characters"),
  // meta_description: Yup.string()
  //   .max(160, "Meta Description should not more than 160 characters")
  //   .nullable(),
  // meta_keywords: Yup.array().of(Yup.string().nullable()).max(10).nullable(),
});

function GoogleReviewForm({ formData, edit, onSuccess }) {
  const [packages, setPackages] = useState([]);

  const [categories, setCategories] = useState([]);

  const { setLoading } = useLoader();

  // useEffect(() => {
  //   edit && formData?.country_id;
  // }, [countries]);

  useEffect(() => {
    getAllPackages()
      .then((res) => {
        let packages = [];
        res.data.data.forEach((data) => {
          packages.push({ value: data.id, label: `${data.title}` });
        });
        setPackages(packages);
      })
      .catch((err) => {
        console.log(err);
      });

    getAllCategories()
      .then((res) => {
        let categories = [];
        res.data.data.data.forEach((data) => {
          categories.push({ value: data.id, label: `${data.name}` });
        });
        setCategories(categories);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // const renderStates = states?.map((state) => {
  //   return (
  //     <option key={state.id} value={state.id}>
  //       {state.name}
  //     </option>
  //   );
  // });

  // const handleCountryChange = (e) => {
  //   getStatesByCountry(e.target.value);
  // };

  // ? Add new GoogleReview
  const addGoogleReview = (values) => {
    let data = Object.assign({}, values);
    data.meta_keywords = data.meta_keywords.toString();
    createGoogleReview(data)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  // ? Update GoogleReview
  const editGoogleReview = (values) => {
    let data = Object.assign({}, values);
    updateGoogleReview(formData.id, data)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div>
      <Formik
        initialValues={{
          status: formData?.status.toString() || "1",
          is_common: formData?.is_common.toString() || "0",
          google_review_id: formData?.google_review_id,
          author_name: formData?.author_name,
          package_id: formData?.package_id ?? "",
          category_id: formData?.category_id ?? "",
          review_text: formData?.review_text,
        }}
        validationSchema={GoogleReviewFormSchema}
        onSubmit={(values) => {
          edit ? editGoogleReview(values) : addGoogleReview(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="flex gap-4">
              <div className="grow">
                <div className="flex flex-wrap gap-4">
                  {/* //? googleReview Name */}
                  <div className="max-w-[50%] grow basis-[45%]">
                    <InputLabel label="Name" labelFor="author_name" />
                    <Field
                      className="block w-full rounded-md border-2 py-1 px-2"
                      id="author_name"
                      name="author_name"
                    />
                    <ErrorMessage name="author_name">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="my-4">
                  <div role="group" aria-labelledby="my-radio-group">
                    <InputLabel label="Include in common Reviews" />
                    <div className="flex items-center gap-12">
                      <label className="flex items-baseline gap-1">
                        <Field type="radio" name="is_common" value="1" />
                        Yes
                      </label>
                      <label className="flex items-baseline gap-1">
                        <Field type="radio" name="is_common" value="0" />
                        No
                      </label>
                    </div>
                  </div>
                </div>

                <div className="my-4 flex flex-wrap gap-4">
                  {/* //? Select Country */}
                  <div className="max-w-[50%] grow basis-[45%]">
                    <InputLabel
                      label="Select Category"
                      labelFor="category_id"
                    />

                    <SelectSearch
                      options={categories}
                      value={values.category_id}
                      onChange={(value) => setFieldValue("category_id", value)}
                    />
                    <ErrorMessage name="category_id">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="my-4 flex flex-wrap gap-4">
                  {/* //? Select Country */}
                  <div className="max-w-[50%] grow basis-[45%]">
                    <InputLabel label="Select Package" labelFor="package_id" />

                    <SelectSearch
                      options={packages}
                      value={values.package_id}
                      onChange={(value) => setFieldValue("package_id", value)}
                    />
                    <ErrorMessage name="package_id">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                {/* //? GoogleReview Description */}

                <div>
                  <InputLabel label="Review Text" labelFor="review_text" />
                  <Field
                    name="review_text"
                    as="textarea"
                    className="block w-full rounded-md border-2 py-1 px-2"
                  ></Field>
                  <ErrorMessage name="review_text">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>

                {/* //? GoogleReview Status */}
                <div className="my-4">
                  <div role="group" aria-labelledby="my-radio-group">
                    <InputLabel label="Status" />
                    <div className="flex items-center gap-12">
                      <label className="flex items-baseline gap-1">
                        <Field type="radio" name="status" value="1" />
                        Active
                      </label>
                      <label className="flex items-baseline gap-1">
                        <Field type="radio" name="status" value="0" />
                        Inactive
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn-primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default GoogleReviewForm;
