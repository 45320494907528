import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Justwravel404 from "../../assets/image/common/justwravel-404.png";
import { FaHome } from "react-icons/fa";

function NotFound404() {
  return (
    <motion.div
      className="flex flex-col justify-center p-8 dark:text-white/75"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3, delay: 0.3 } }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <img src={Justwravel404} alt="" className="mx-auto lg:w-1/3" />
      <div className="mt-8 text-center lg:mt-16">
        <h5>Oops! Looks like you are lost.</h5>
        <h3 className="text-2xl">
          Find your way back{" "}
          <Link
            to="/"
            className="inline-flex items-center gap-2 font-semibold text-jw-green dark:text-white"
          >
            Home <FaHome />
          </Link>
        </h3>
      </div>
    </motion.div>
  );
}

export default NotFound404;
