import SidebarMenuDropdown from "./SidebarMenuDropdown";
import { megaMenu } from "../../../localData/MegaMenu";
import useSideBarToggle from "../../hooks/useSideBarToggle";
import useAdminAuth from "../../hooks/useAdminAuth";
import useScreenSize from "../../../hooks/useScreenSize";
import { useState } from "react";

function SideBarMenuWrapper() {
  const { toggleSidebar } = useSideBarToggle();
  const { auth } = useAdminAuth();
  const { screenSize } = useScreenSize();
  const [selectedMenu, setSelectedMenu] = useState(0)

  const renderMenus = megaMenu.map((menu, index) => {
    const hasMatchingPermission = menu.parentPermissionNames.some((name) =>
      auth?.permissions?.includes(name)
    );
    if (auth.roles && auth?.roles?.includes("Superadmin")) {
      return (
        <div key={index}>
          <SidebarMenuDropdown key={index} menu={menu} index={index} selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu}/>
        </div>
      );
    }
    if (hasMatchingPermission) {
      // At least one of the parentPermissionNames matches a permission
      return (
        <div key={index}>
          <SidebarMenuDropdown key={index} menu={menu} index={index} selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu}/>
        </div>
      );
    } else {
      // No match found
      console.log("No match found");
    }
  });

  return (
    <div
      // onMouseEnter={() => {
      //   screenSize.innerWidth >= 1008 && toggleSidebar(true);
      // }}
      // onMouseLeave={() => {
      //   screenSize.innerWidth >= 1008 && toggleSidebar(false);
      // }}
      className="scrollbar-hidden h-[calc(100vh-4rem)] overflow-y-auto p-4 dark:text-white text-slate-900"
    >
      {renderMenus}
    </div>
  );
}

export default SideBarMenuWrapper;
