import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CheckboxTree from "react-checkbox-tree";
import { MdExpandLess } from "react-icons/md";
import { MdExpandMore } from "react-icons/md";
import { MdIndeterminateCheckBox } from "react-icons/md";
import {
  Fa500Px,
  FaCheckSquare,
  FaChevronDown,
  FaChevronRight,
  FaChevronUp,
  FaMinusSquare,
  FaRegSquare,
} from "react-icons/fa";
import { getAllModulesOption } from "../services/ModuleServices";
import { getRoleById } from "../services/RoleServices";
import { updateEmployeePermissionById } from "../services/EmployeeServices";
import { IoMdClose } from "react-icons/io";

const EmployeePermissionComponent = ({
  open,
  setOpen,
  permissionsEmployeeAlreadyHave,
  permissionsOfRole,
  employeeId,
}) => {
  const [modules, setModules] = useState();
  const [checked, setChecked] = useState(permissionsEmployeeAlreadyHave);
  const [expanded, setExpanded] = useState([]);
  useEffect(() => {
    getAllModulesOption()
      .then((res) => {
        setModules(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleOncheck = (checkedArray) => {
    setChecked(checkedArray);
  };

  const listOfAllPermissions = () => {
    const showingArray = [];
    if (modules) {
      modules.map((oneParent, index) => {
        showingArray[index] = {
          value: oneParent.name,
          label: oneParent.name,
          children: [],
        };
        const childrenArray = [];
        oneParent.permissions.map((oneChild, index) => {
          if (permissionsOfRole.includes(oneChild.name)) {
            childrenArray[index] = {
              value: oneChild.name,
              label: oneChild.name,
              disabled: true,
            };
          } else {
            childrenArray[index] = {
              value: oneChild.name,
              label: oneChild.name,
            };
          }
        });
        showingArray[index].children = childrenArray;
      });
    }
    return showingArray;
  };

  const handleAssignPermission = () => {
    const newPermissionArray = checked.filter(
      (n) => !permissionsOfRole.includes(n)
    );
    const submitData = {
      employee_id: employeeId,
      permissions: newPermissionArray,
    };

    updateEmployeePermissionById(submitData)
      .then((res) => {
        if (res.data.status === "success") {
          setOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Transition show={open}>
      <Dialog
        className="relative z-10"
        onClose={() => {
          setOpen(false);
        }}
      >
        <div
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left text-black shadow-xl transition-all dark:bg-slate-800 dark:text-white sm:my-8 sm:w-full sm:max-w-lg">
                <div className=" px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div className="flex justify-between">
                        <div
                          as="h3"
                          className="text-xl font-semibold leading-6 text-gray-900 dark:text-white"
                        >
                          Assign permissions Directly
                          <div className="mt-2">
                            <p className="mb-6 text-sm text-gray-500 dark:text-white">
                              You can assign some permissions to an employee
                              other than the permissions related to its assigned
                              role
                            </p>
                          </div>
                        </div>
                        <p
                          onClick={() => {
                            setOpen(false);
                          }}
                          className="cursor-pointer rounded-lg font-bold"
                        >
                          <IoMdClose size={25} />
                        </p>
                      </div>

                      <div className="h-[70vh] overflow-y-scroll dark:scrollbar">
                        <CheckboxTree
                          nodes={listOfAllPermissions()}
                          checked={checked}
                          expanded={expanded}
                          onCheck={handleOncheck}
                          onExpand={(expanded) => setExpanded(expanded)}
                          iconsClass="fa5"
                          icons={{
                            check: (
                              <FaCheckSquare className="bg-white bg-transparent text-jw-green" />
                            ),
                            uncheck: <FaRegSquare />,
                            halfCheck: (
                              <FaMinusSquare className="bg-white bg-transparent text-blue-600" />
                            ),
                            expandClose: <FaChevronRight />,
                            expandOpen: <FaChevronDown />,
                            expandAll: "expandAll",
                            collapseAll: "collapseAll",
                            parentClose: (
                              <hr className="border-t-2 border-dashed border-gray-500" />
                            ),
                            parentOpen: (
                              <hr className="border-t-2 border-dashed border-gray-500" />
                            ),
                            leaf: (
                              <hr className="border-t-2 border-dashed border-gray-500" />
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 dark:bg-slate-900 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 dark:bg-green-700 sm:ml-3 sm:w-auto"
                    onClick={handleAssignPermission}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:bg-slate-500 dark:text-white sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    data-autofocus
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EmployeePermissionComponent;
