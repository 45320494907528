import { admin } from "../../ServiceConfig";

export function createCoupon(data) {
  return admin.post("/coupons", data);
}

export function createCategoryCoupons(data) {
  return admin.post("/coupons/categoryDiscount", data);
}

export function createPackageCoupons(data) {
  return admin.post("/coupons/packageDiscount", data);
}

export function getCouponById(id) {
  return admin.get(`/coupons/${id}`);
}

export function getCouponPackages(couponId) {
  return admin.get(`/packagesByCoupon/${couponId}`);
}

export function getCouponCateogries(couponId) {
  return admin.get(`/categoriesByCoupon/${couponId}`);
}

export function getItineraryCouponPrices(couponId, itineraryId) {
  return admin.get(`/couponPriceByItinerary/${couponId}/${itineraryId}`);
}

export function UpdateCoupon(data) {
  return admin.put(`/coupons/${data.id}`, data);
}

export function updatePackageCoupon(data) {
  return admin.put(`/updateCouponPackagePrice`, data);
}

export function UpdateCategoryCoupon(data) {
  return admin.put(`/updateCouponCategoryDiscount`, data);
}

export function DeleteCoupon(dataId) {
  return admin.delete(`/coupons/${dataId}`);
}

export function uploadCouponImage(file) {
  return admin.post(`uploads/image?upload_path=coupons`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function deleteCategoryCoupon(id) {
  return admin.delete(`/category/coupon/${id}`);
}

export function deletePackageCoupon(id, couponId) {
  return admin.delete(`/package/coupon/${id}/${couponId}`);
}
