import React from "react";
import ComponentHeader from "../common/ComponentHeader";
import H2 from "../../../components/headings/H2";
import { Link } from "react-router-dom";
import { FaListAlt } from "react-icons/fa";
import GiftCardThemeForm from "./gift-card-forms/GiftCardThemeForm";

function AddGiftCard() {
  return (
    <div>
      <ComponentHeader className="border-b">
        <H2>Add Gift Card</H2>
        <Link
          to="/gift-cards"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>

      <div className="mt-8">
        <GiftCardThemeForm />
      </div>
    </div>
  );
}

export default AddGiftCard;
