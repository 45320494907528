import { useState } from "react";
import { useEffect } from "react";
import { getCouponById } from "../../../services/CouponService";
import CategoryCouponForm from "./CategoryCouponForm";
import PackageCouponForm from "./PackageCouponForm";

function CouponTypeFormWrapper({ couponId }) {
  const [couponData, setCouponData] = useState();

  useEffect(() => {
    console.log(couponId, "cicicciccic=============");
    getCouponById(couponId).then((res) => {
      console.log(res.data.data, "coupon by id");
      setCouponData(res.data.data);
    });
  }, [couponId]);

  return (
    <div>
      {couponData?.type === "category" && (
        <CategoryCouponForm couponData={couponData} />
      )}
      {couponData?.type === "package" && (
        <PackageCouponForm couponData={couponData} />
      )}
    </div>
  );
}

export default CouponTypeFormWrapper;
