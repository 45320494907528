import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { addNewHomepageCategory, editHomepageCategory, uploadCategoryImage } from "../../services/SettingsServices";
import FileInput from "../../../components/inputFields/FileInput";
import { getUploadsUrl } from "../../../ServiceConfig";
import ToggleInput from "../inputs/ToggleInput";
import InputLabel from "../inputs/InputLabel";

const categorySchema = Yup.object().shape({
  title: Yup.string().required("Required").max(25, "Maximum 25 characters are valid"),
  // image: Yup.string().required("Required"),
  // url: Yup.string().required("Required"),
  // status: Yup.string().required("Required"),
});

function HomePageCategoryForm({ formData, edit, onSuccess }) {

  const addnewCategory = (values) => {
    console.log(values, "values")
    addNewHomepageCategory(values)
      .then((res) => {
        console.log(res)
        toast.success(res.data.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        console.log(err)
        toast.error("error in adding new Home page Category");
        console.log(err, "not added")
      });
  };

  const editCategory = (values) => {
    let data = Object.assign({}, values);
    editHomepageCategory(formData.id, data)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  function removeSubstrings(input) {
    const substringsToRemove = [
      'https://www.justwravel.com/',
      'www.justwravel.com/',
      'justwravel.com'
    ];
    let result = input;
    substringsToRemove.forEach(substring => {
      result = result.replace(substring, '');
    });
    return result.trim();
  }


  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          title: formData?.title ? formData.title : "",
          image: formData?.image ? formData.image : "",
          url: formData?.url ? formData.url : "",
          status: formData?.status ? formData.status : "",
        }}
        validationSchema={categorySchema}
        onSubmit={(values) => {
          edit ? editCategory({ ...values, url: removeSubstrings(values.url) }) : addnewCategory({ ...values, url: removeSubstrings(values.url) });
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="flex flex-wrap gap-4 lg:flex-nowrap">

              <div className="my-4 grow">
                <div className="flex flex-wrap gap-10">

                  {/* //? Title */}
                  <div className="grow basis-[45%] lg:max-w-[40%]">
                    <InputLabel label="Title" labelFor="title" />
                    <Field
                      className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                      id="title"
                      name="title"
                    />
                    <ErrorMessage name="title">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>


                  {/* //? URL */}
                  <div className="grow basis-[45%] lg:max-w-[40%] relative">
                    <InputLabel label="URL" labelFor="url" />
                    <Field
                      className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                      id="url"
                      name="url"
                    />
                    <div>
                      URL Preview : <a href={"https://www.justwravel.com/" + values.url} target="_blank" className="text-blue-600 cursor-pointer">https://www.justwravel.com/{values.url}</a>
                    </div>
                    <ErrorMessage name="url">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  {/* //? Status */}
                  <div className="grow basis-[45%] lg:max-w-[10%]">
                    <InputLabel label="Status" labelFor="status" />
                    <ToggleInput
                      fieldKey="status"
                      checked={values.status}
                      onToggle={() =>
                        setFieldValue("status", !values.status)
                      }
                    />
                    <ErrorMessage name="url">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>


                  {/* //? Image */}
                  <div className="grow basis-[45%] lg:max-w-[30%]">
                    <InputLabel label="Image" labelFor="image" />
                    <FileInput
                      maxSize={200000}
                      value={`${getUploadsUrl()}/homepage/home-categories/${values.image
                        }`}
                      noOfFilesAllowed={1}
                      onChange={(file) => {
                        uploadCategoryImage({ image: file }).then(
                          (res) => {
                            setFieldValue(
                              `image`,
                              res.data.data[0].uploaded_image
                            );
                          }
                        ).catch((er) => {
                          toast.error("Image size should be less than 200kb")
                        })
                      }}
                    />
                    <p className="tracking-tighter text-xs">Image should be less than 200kb | format - .jpg, .jpeg</p>
                    <ErrorMessage name="image">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:mt-4 mt-10 text-center">
              <button type="submit" className="btn-primary dark:bg-green-700">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default HomePageCategoryForm;
