import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import InputLabel from "../../../components/inputs/InputLabel";
import SelectSearch from "../../../components/inputs/SelectSearch";
import { FaPlus } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { useEffect } from "react";
import { getAllCategories } from "../../../services/CategoryServices";
import { useState } from "react";
import { getAllLocation } from "../../../services/LocationServices";
import { getAllLeadCategory } from "../../../services/LeadService";

function ConditionForm({ formData, onSuccess, setConditions }) {
  const [categories, setCategories] = useState();
  const [locations, setLocations] = useState();
  const [inputWidth, setInputWidth] = useState();

  const fieldValues = [
    {
      value: "default",
      label: "Default",
    },
    {
      value: "lead_category_id",
      label: "Category",
    },
    {
      value: "location",
      label: "Location",
    },
    // {
    //   value: "package_id",
    //   label: "Package",
    // },
  ];

  const conditionalValues = [
    {
      value: "is",
      label: "Is",
    },
    {
      value: "like",
      label: "Like",
    },
  ];

  useEffect(() => {
    getAllLeadCategory().then((res) => {
      let categories = [];
      res.data.data.data.forEach((data) => {
        categories.push({ value: `${data.id}`, label: data.category });
      });
      setCategories(categories);
    });

    getAllLocation().then((res) => {
      let locations = [];
      res.data.data.data.forEach((data) => {
        locations.push({ value: data.name, label: data.name });
      });
      setLocations(locations);
    });
  }, []);

  const getSelectOptions = (type) => {
    if (type === "lead_category_id") return categories;
    if (type === "location") return locations;
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          condition: formData || [{}],
        }}
        // validationSchema={employeeFormSchema}
        onSubmit={(values) => {
          setConditions(values.condition);
          // setConditions(values);
          onSuccess();
          // edit ? editEmployee(values) : addNewEmployee(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <FieldArray
              name="condition"
              render={(arrayHelpers) => (
                <div>
                  {values.condition.map((condition, index) => (
                    <div
                      key={index}
                      className="group relative mb-2 rounded-lg border p-4 hover:bg-gray-100"
                    >
                      <div className="mb-1 font-bold">
                        Condition {index + 1}
                      </div>
                      <div
                        onClick={() => {
                          arrayHelpers.remove(index);
                        }}
                        className="absolute top-1 right-1 hidden rounded-full border p-1 group-hover:block"
                      >
                        <MdClose />
                      </div>
                      <div className="flex items-end gap-4">
                        <div className="grow">
                          <InputLabel label="Field" />
                          <SelectSearch
                            options={fieldValues}
                            value={values.condition[index].parameter}
                            onChange={(value) => {
                              setFieldValue(
                                `condition[${index}].parameter`,
                                value
                              );
                              value === "lead_category_id" &&
                                setFieldValue(
                                  `condition[${index}].logical_operator`,
                                  "is"
                                );
                            }}
                          />
                        </div>
                        <div className="grow">
                          <InputLabel label="Condition" />
                          <SelectSearch
                            disabled={
                              values.condition[index].parameter ===
                              "lead_category_id"
                            }
                            options={conditionalValues}
                            value={values.condition[index].logical_operator}
                            onChange={(value) =>
                              setFieldValue(
                                `condition[${index}].logical_operator`,
                                value
                              )
                            }
                          />
                        </div>
                        {values.condition[index].logical_operator === "is" && (
                          <div className="grow">
                            <InputLabel label={`Value`} />
                            <SelectSearch
                              options={getSelectOptions(
                                values.condition[index].parameter
                              )}
                              value={values.condition[index].value}
                              onChange={(value) =>
                                setFieldValue(
                                  `condition[${index}].value`,
                                  value
                                )
                              }
                            />
                          </div>
                        )}
                        {values.condition[index].logical_operator !== "is" && (
                          <div className="grow">
                            <InputLabel label="Value" />
                            <Field
                              name={`condition[${index}].value`}
                              className="field"
                            />
                          </div>
                        )}
                        <div className="">
                          <p
                            onClick={() => {
                              arrayHelpers.insert(index + 1, {});
                              setFieldValue(
                                `condition[${index}].conditional_operator`,
                                "and"
                              );
                            }}
                          >
                            <FaPlus />
                          </p>
                        </div>
                      </div>
                      {/* {values.condition.length - 1 !== index && (
                        <p
                          onClick={() => {
                            setFieldValue(
                              `condition[${index}].logical_operator`,
                              values.condition[index].logical_operator === "or"
                                ? "and"
                                : "or"
                            );
                       
                          }}
                          className="mt-1 inline-block rounded-lg border px-4 py-1 text-sm font-semibold uppercase"
                        >
                          {values.condition[index].logical_operator}
                        </p>
                      )} */}
                    </div>
                  ))}

                  {/* {values.condition.length > 1 && (
                    <div className="font-semibold">
                      <Field
                        className={`focus:border focus:outline-none w-[${inputWidth}px]`}
                        name="criteria_pattern"
                        onChange={(e) => {
                          setFieldValue("criteria_pattern", e.target.value);
                          setInputWidth(e.target.value.length * 10);
                        }}
                      />
                      Criteria Pattern
                    </div>
                  )} */}

                  <div className="mt-4 text-center">
                    <button type="submit" className="btn-primary">
                      Next
                    </button>
                  </div>
                </div>
              )}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ConditionForm;
