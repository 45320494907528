import { admin } from "../../ServiceConfig";

export function getAllCountries(query) {
  return admin.get(`/countries?query=${query}`);
}
export function getAllCountriesOption() {
  return admin.get(`/allCountriesOption`);
}
export function createCountry(data) {
  return admin.post(`/countries`, data);
}
export function updateCountry(id, data) {
  return admin.put(`/countries/${id}`, data);
}
export function deleteCountry() {
  return admin.get(`/countries`);
}
export function getCountryById(id) {
  return admin.get(`/countries/${id}`);
}
export function uploadCountryImage(file) {
  return admin.post(`uploads/image?upload_path=countries`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
