import { ErrorMessage, Field, FieldArray, useFormikContext } from "formik";
import { useEffect } from "react";
import InputLabel from "../../inputs/InputLabel";
import SelectSearch from "../../inputs/SelectSearch";
import ToggleInput from "../../inputs/ToggleInput";
import EditorInput from "../../inputs/EditorInput";
import { useState } from "react";
import { getAllTransfers } from "../../../services/TransferServices";
import DragAndDropList from "../../../../components/dragAndDrop/DragAndDropList";
import { Draggable } from "react-beautiful-dnd";
import { FaPlus } from "react-icons/fa";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import TransferForm from "../../transfers/TransferForm";

function ItineraryDaysForm() {
  const { values, setFieldValue } = useFormikContext();
  const [transfers, setTransfers] = useState([]);
  const [addTransferModal, setAddTransferModal] = useState(false);
  const [serviceChargeApplicable, setServiceChargeApplicable] = useState(
    values.service_charge_percent > 0
  );
  const [day0Included, setDay0Included] = useState(false);

  useEffect(() => {
    getAllTransfers().then((res) => {
      setTransfers(res.data.data.data);
    });
  }, []);

  useEffect(() => {
    if (!values.day0) return;

    setDay0Included(true);
  }, [values.day0]);

  const handleReorder = (startIndex, endIndex) => {
    const reorderedItems = Array.from(values.itinerary_days);
    const [removed] = reorderedItems.splice(startIndex, 1);
    reorderedItems.splice(endIndex, 0, removed);
    setFieldValue(`itinerary_days`, reorderedItems);
  };

  const onAddTransfer = (data) => {
    setTransfers([...transfers, data]);
    setAddTransferModal(false);
  };

  return (
    <div>
      {values.no_of_nights > 0 && (
        <div className="my-4 rounded-lg border-2 p-4">
          <div className="flex items-end gap-4">
            <h3 className=" text-lg font-bold">Create Itinerary</h3>
            <label htmlFor="day-0">
              <input
                type="checkbox"
                name=""
                id="day-0"
                checked={day0Included}
                onChange={(e) => {
                  setDay0Included(e.target.checked);
                  if (e.target.checked === false) setFieldValue("day0", null);
                }}
              />{" "}
              Include day 0
            </label>
          </div>

          {day0Included && (
            <div className="my-4">
              <div className="mb-8 rounded-lg border border-gray-500 bg-white p-4">
                <div className="relative mb-2 border-b pb-2 font-bold">
                  <h3>Enter Detail for Day 0</h3>
                </div>
                {/* //? Itinerary Title */}
                <div className="">
                  <InputLabel label="Heading" required={true} />
                  <Field name={`day0.title`} className="field" />
                  <ErrorMessage name={`day0.title`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>

                {/* //? Itinerary Detail */}
                <div className="my-4">
                  <InputLabel label="Description" required={true} />
                  <EditorInput
                    fieldKey={`day0.description`}
                    data={values?.day0?.description}
                  />
                </div>

                <div className="my-4 flex flex-wrap gap-4">
                  <div className="grow">
                    <InputLabel label="Transfer" />
                    <div className="flex gap-[2px]">
                      <SelectSearch
                        options={transfers?.map((transfer) => ({
                          key: transfer.id,
                          value: `${transfer.type} - (${transfer.seats} seats)`,
                          label: `${transfer.type} - (${transfer.seats} seats)`,
                        }))}
                        value={values?.day0?.transfer_id || ""}
                        onChange={(value) =>
                          setFieldValue(`day0.transfer_id`, value)
                        }
                      />

                      <button
                        className="rounded-lg border border-green-300 p-2 text-green-500"
                        onClick={() => {
                          setAddTransferModal(true);
                        }}
                        type="button"
                      >
                        <FaPlus />
                      </button>
                    </div>
                  </div>

                  <div className="grow">
                    <InputLabel label="Transfer From" />
                    <Field className="field" name={`day0.transfer_from`} />
                  </div>

                  <div className="grow">
                    <InputLabel label="Transfer to" />
                    <Field className="field" name={`day0.transfer_to`} />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="my-4">
            <FieldArray name={`itinerary_days`}>
              {({ insert, remove, push }) => (
                <div className="mt-8">
                  <DragAndDropList
                    items={values.itinerary_days}
                    onReorder={handleReorder}
                  >
                    {values.itinerary_days?.length > 0 &&
                      values.itinerary_days?.map((day, index) => (
                        <Draggable
                          key={day?.id ? day?.id : index}
                          draggableId={day?.id ? `${day?.id}` : `${index}`}
                          index={index}
                        >
                          {(provided) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className="mb-8 rounded-lg border border-gray-500 bg-white p-4">
                                <div className="relative mb-2 border-b pb-2 font-bold">
                                  <h3>Enter Detail for Day {index + 1}</h3>
                                </div>
                                {/* //? Itinerary Title */}
                                <div className="">
                                  <InputLabel label="Heading" required={true} />
                                  <Field
                                    name={`itinerary_days.${index}.title`}
                                    className="field"
                                  />
                                  <ErrorMessage
                                    name={`itinerary_days.${index}.title`}
                                  >
                                    {(msg) => (
                                      <div className="error-msg">{msg}</div>
                                    )}
                                  </ErrorMessage>
                                </div>

                                {/* //? Itinerary Detail */}
                                <div className="my-4">
                                  <InputLabel
                                    label="Description"
                                    required={true}
                                  />
                                  <EditorInput
                                    fieldKey={`itinerary_days.${index}.description`}
                                    data={
                                      values?.itinerary_days[index]?.description
                                    }
                                  />
                                </div>

                                {/* //? Itinerary day inclusions */}
                                <div className="my-4">
                                  <InputLabel label="Included :-" />
                                  <div className="flex flex-wrap gap-4 px-4">
                                    <div className="min-w-[15%]">
                                      <InputLabel label="Breakfast" />
                                      <ToggleInput
                                        fieldKey={`itinerary_days.${index}.breakfast`}
                                        checked={
                                          values.itinerary_days[index]
                                            ?.breakfast
                                        }
                                        onToggle={(e) => {
                                          setFieldValue(
                                            `itinerary_days.${index}.breakfast`,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="min-w-[15%]">
                                      <InputLabel label="Lunch" />
                                      <ToggleInput
                                        fieldKey={`itinerary_days.${index}.lunch`}
                                        checked={
                                          values.itinerary_days[index]?.lunch
                                        }
                                        onToggle={(e) => {
                                          setFieldValue(
                                            `itinerary_days.${index}.lunch`,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="min-w-[15%]">
                                      <InputLabel label="Dinner" />
                                      <ToggleInput
                                        fieldKey={`itinerary_days.${index}.dinner`}
                                        checked={
                                          values.itinerary_days[index]?.dinner
                                        }
                                        onToggle={(e) => {
                                          setFieldValue(
                                            `itinerary_days.${index}.dinner`,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="my-4 flex flex-wrap gap-4">
                                  <div className="w-full sm:grow">
                                    <InputLabel label="Transfer" required={true} />
                                    <div className="flex gap-[2px] w-full">
                                      <SelectSearch
                                        options={transfers?.map((transfer) => ({
                                          value: transfer.id,
                                          label: `${transfer.type} - (${transfer.seats} seats)`,
                                        }))}
                                        value={
                                          values?.itinerary_days[index]
                                            ?.transfer_id
                                        }
                                        onChange={(value) =>
                                          setFieldValue(
                                            `itinerary_days.${index}.transfer_id`,
                                            value
                                          )
                                        }
                                      />
                                      <button
                                        className="rounded-lg border border-green-300 p-2 text-green-500"
                                        onClick={() => {
                                          setAddTransferModal(true);
                                        }}
                                        type="button"
                                      >
                                        <FaPlus />
                                      </button>
                                    </div>
                                    <ErrorMessage name={`itinerary_days.${index}.transfer_id`}>
                                      {(msg) => <div className="error-msg">{msg}</div>}
                                    </ErrorMessage>
                                  </div>

                                  <div className="grow">
                                    <InputLabel label="Transfer From" />
                                    <Field
                                      className="field"
                                      name={`itinerary_days.${index}.transfer_from`}
                                    />
                                  </div>

                                  <div className="grow">
                                    <InputLabel label="Transfer to" />
                                    <Field
                                      className="field"
                                      name={`itinerary_days.${index}.transfer_to`}
                                    />
                                  </div>
                                </div>
                              </div>
                            </li>
                          )}
                        </Draggable>
                      ))}
                  </DragAndDropList>
                </div>
              )}
            </FieldArray>
          </div>
        </div>
      )}

      <div className="my-4 rounded-lg border-2 p-4">
        <div className="flex items-end gap-4">
          <h3 className=" text-lg font-bold">Costing Detail</h3>
          <label htmlFor="service-charge" className="text-sm text-gray-600">
            <input
              type="checkbox"
              name=""
              id="service-charge"
              checked={values.service_charge_percent > 0}
              onChange={(e) => setServiceChargeApplicable(e.target.checked)}
            />{" "}
            Is Service Charge Applicable?
          </label>
        </div>
        <div className="my-4 flex flex-wrap gap-4 lg:flex-nowrap">
          <div className="grow">
            <InputLabel label="Costing Type" required={true} />
            <Field as="select" className="field" name="price_type">
              <option value="">Select Costing type</option>
              <option value="per_person">Per Person</option>
              <option value="total">Total</option>
            </Field>
            <ErrorMessage name="price_type">
              {(msg) => <div className="error-msg">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="grow">
            <InputLabel
              label="Cost"
              instruction="(GST Excluded)"
              required={true}
            />
            <Field className="field" name="price" type="number" min="0" />
            <ErrorMessage name="price">
              {(msg) => <div className="error-msg">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="grow">
            <InputLabel label="Booking Amount" />
            <Field
              className="field"
              name="booking_amount"
              type="number"
              min="0"
            />
          </div>
          {serviceChargeApplicable && (
            <div className="grow">
              <InputLabel label="Service Charge %" />
              <Field
                className="field"
                name="service_charge_percent"
                type="number"
                min="0"
              />
            </div>
          )}
        </div>
      </div>

      <Modal
        isOpen={addTransferModal}
        contentLabel="Update Participant Details"
        className="mx-auto mt-[2%] max-h-[90%] max-w-[75%] overflow-auto rounded-md bg-white p-4"
        overlayClassName="fixed top-0 left-0 w-full h-full !z-40 !bg-black/50"
      // onAfterClose={getUpdatedData}
      >
        <div className="flex items-center justify-between border-b p-4">
          <h2 className="text-xl font-bold">Add Location</h2>
          <button
            onClick={() => setAddTransferModal(false)}
            className="cursor-pointer rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
        </div>

        <div className="mt-2">
          <TransferForm onSuccess={onAddTransfer} />
        </div>
      </Modal>
    </div>
  );
}

export default ItineraryDaysForm;
