import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCountryById } from "../../services/CountryServices";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";
import ThingsToPackForm from "./ThingsToPackForm";
import { getThingsToPackById } from "../../services/ThingsToPackService";

function EditThingsToPack() {
  const navigate = useNavigate();

  const ttkId = useParams().id;

  const [ttk, setTtk] = useState();

  const onEditThingsToPack = (data) => {
    navigate(`/things-to-pack`);
  };

  useEffect(() => {
    ttkId &&
      getThingsToPackById(ttkId)
        .then((res) => {
          setTtk(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Edit Country | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Things To Pack</H2>
        <Link
          to="/things-to-pack"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {ttk && (
        <ThingsToPackForm
          formData={ttk}
          edit={true}
          onSuccess={(data) => onEditThingsToPack(data)}
        />
      )}
    </div>
  );
}

export default EditThingsToPack;
