import { admin } from "../../ServiceConfig";

export function getAllThingsToPack(query) {
  return admin.get(`/things-to-pack?query=${query}`);
}
export function getAllThingsToPackOption() {
  return admin.get(`/all-things-to-pack`);
}
export function createThingsToPack(data) {
  return admin.post(`/things-to-pack`, data);
}
export function updateThingsToPack(id, data) {
  return admin.put(`/things-to-pack/${id}`, data);
}
export function deleteThingsToPack(id) {
  return admin.delete(`/things-to-pack/${id}`);
}
export function getThingsToPackById(id) {
  return admin.get(`/things-to-pack/${id}`);
}
export function uploadThingsToPackImage(file) {
  return admin.post(`uploads/image?upload_path=things-to-pack`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
