import React, { useEffect, useState } from "react";
import { FaListAlt } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import H2 from "../../components/headings/H2";
import ComponentHeader from "../components/common/ComponentHeader";
import { getEmployeeById } from "../services/EmployeeServices";
import NotAuthorized403 from "../components/NotAuthorized403";
import { checkPermission } from "../services/AdminServices";
import useLoader from "../../hooks/useLoader";
import { getRoleById } from "../services/RoleServices";
import { getB2BbyId } from "../services/B2BServices";
import { getRewardById } from "../services/RewardsAndRecognitionServices";
import RewardForm from "./RewardForm";

function EditReward() {
  const navigate = useNavigate();

  const [authorized, setAuthorized] = useState(true);
  const { setLoading } = useLoader();
  const [role, setRole] = useState();
  const [rewardData, setRewardData] = useState();
  const employeeData = {};
  const [openEmployeePermissionModal, setOpenEmployeePermissionModal] =
    useState(true);
  const rewardId = useParams().id;

  useEffect(() => {
    getRewardById(rewardId)
      .then((res) => {
        toast.success(res.data.data.message);
        setRewardData(res.data.data);
      })
      .catch((er) => {
        console.log(er);
        toast.error("error in updating b2b");
      });
  }, []);

  const onEditReward = (data) => {
    console.log(data);
    navigate(`/rnr/reward`);
  };

  useEffect(() => {
    // employeeId &&
    //   getEmployeeById(employeeId)
    //     .then((res) => {
    //       setEmployeeData(res.data.data);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
  }, []);

  return (
    <div className="h-[100%] dark:bg-slate-900 dark:text-white">
      {/* <MetaTags title="Edit State | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Reward</H2>
        <Link
          to="/rnr/reward"
          className="btn-primary flex items-center gap-1 whitespace-nowrap py-1 px-4 dark:bg-green-700 lg:px-12 lg:py-2"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {rewardData && (
        <>
          <RewardForm
            formData={rewardData}
            edit={true}
            onSuccess={(data) => onEditReward(data)}
          />
        </>
      )}
    </div>
  );
}

export default EditReward;
