import React, { useEffect, useState } from "react";
import { FaListAlt } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import H2 from "../../../components/headings/H2";
import ComponentHeader from "../../components/common/ComponentHeader";
import BookingForm from "../BookingForms/BookingForm";
import ToggleInput from "../../components/inputs/ToggleInput";
import {
  getProformaInvoiceById,
  updateProformaInvoice,
} from "../../services/ProformaInvoiceServices";
import { MetaTagsDefault } from "../../components/Metatags";

function EditProformaInvoice() {
  const navigate = useNavigate();

  const proformaId = useParams().id;

  const [packageName, setPackageName] = useState();
  const [proformaData, setProformaData] = useState();
  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    proformaId &&
      getProformaInvoiceById(proformaId)
        .then((res) => {
          setProformaData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  const onSubmit = (values, setSubmitting) => {
    values.other_services = values?.other_services?.filter(
      (obj) => Object.keys(obj).length !== 0
    );

    values.addons.forEach((addon, index) => {
      if (!addon.amount || addon.amount === 0) {
        values.addons.splice(index, 1);
      }
    });

    if (!Array.isArray(values.payment)) {
      values.payment = [values.payment];
    }

    values.is_customized = proformaData.is_customized;

    updateProformaInvoice(proformaData?.id, { proforma: values })
      .then((res) => {
        console.log(res);
        toast.success(res.data.message);
        navigate(`/bookings/proforma-invoice`);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="">
      <MetaTagsDefault title="Edit Proforma Invoice | Admin Panel" />
      <ComponentHeader className="mb-4 border-b">
        <div className="flex items-center gap-4">
          <H2>Update Proforma Invoice</H2>
          <div className="flex items-center gap-1">
            <ToggleInput
              // disabled={true}
              checked={proformaData?.is_customized}
              onToggle={(e) =>
                setProformaData((proformaData) => ({
                  ...proformaData,
                  is_customized: e.target.checked,
                }))
              }
            />
            <span className="font-medium">Customized Booking</span>
          </div>

          {/* <div className="flex items-center gap-1">
            <ToggleInput
              checked={isInternational}
              onToggle={(e) => setIsInternational(e.target.checked)}
            />
            <span className="font-medium">International</span>
          </div> */}
        </div>

        <Link
          to="/bookings/proforma-invoice"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {proformaData && (
        <BookingForm
          setPackageName={setPackageName}
          formData={proformaData}
          edit={true}
          proformaInvoice={true}
          onSubmit={onSubmit}
          isCustomized={proformaData.is_customized}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
      )}
    </div>
  );
}

export default EditProformaInvoice;
