import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { useState } from "react";
import { FaEnvelope } from "react-icons/fa";
import validator from "validator";

const EmailInput = forwardRef((props, ref) => {
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState("");

  useImperativeHandle(ref, () => ({
    setError(err) {
      setError(err);
    },
  }));

  const handleInputBlur = (e) => {
    setIsFocused(false);
    if (validator.isEmail(e.target.value)) {
      setError("");
    } else {
      e.target.value && setError("Invalid Email Id");
    }
  };

  const handleInputChange = (e) => {
    setError("");
    props.onChange(e);
  };
  return (
    <div
      className={`relative flex items-center gap-2 rounded-full border-2 py-1 px-4 lg:py-2 ${
        isFocused ? "border-jw-green/50" : ""
      } ${error && "border-red-300"}`}
    >
      <label
        className={`absolute left-8 px-1 transition-all duration-300 ease-out ${
          isFocused || props.value
            ? "-top-3 bg-white text-sm font-bold"
            : "top-1/2 -translate-y-1/2"
        }`}
        htmlFor={props.id}
      >
        {props.placeholder ? props.placeholder : "Email Id"}
      </label>
      <div className="text-gray-500">
        <FaEnvelope />
      </div>
      <input
        type="email"
        ref={ref}
        name={props.name}
        id={props.id}
        value={props.value}
        onChange={handleInputChange}
        onFocus={() => setIsFocused(true)}
        onBlur={handleInputBlur}
        className="grow bg-transparent focus:outline-none"
      />

      {error && (
        <div className="absolute -bottom-2 bg-white px-1 text-xs text-red-400">
          {error}
        </div>
      )}
    </div>
  );
});

export default EmailInput;
