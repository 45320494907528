import { Link, useParams } from "react-router-dom";
import H2 from "../../../components/headings/H2";
import ComponentHeader from "../common/ComponentHeader";
import { FaEye, FaListAlt } from "react-icons/fa";
import ItineraryBuilderFormWrapper from "./itineraryForm/ItineraryBuilderFormWrapper";
import { useState } from "react";
import { useEffect } from "react";
import { getCustomPackageById } from "../../services/CustomPackageServices";
import { toast } from "react-toastify";

function UpdateItinerary() {
  const customPackageId = useParams().id;

  const [customItinerary, setCustomItinerary] = useState();

  useEffect(() => {
    getCustomPackageById(customPackageId)
      .then((res) => {
        console.log(res);
        setCustomItinerary(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }, []);

  return (
    <div>
      <ComponentHeader className="border-b">
        <H2>Update Custom Package</H2>
        <div className="flex gap-4">
          {/* <Link
            to={`/itinerary-builder/${customItinerary?.id}`}
            className="btn-primary flex items-center gap-1 whitespace-nowrap"
          >
            <FaEye /> Preview
          </Link> */}
          <Link
            to="/itinerary-builder"
            className="btn-primary flex items-center gap-1 whitespace-nowrap"
          >
            <FaListAlt /> View All
          </Link>
        </div>
      </ComponentHeader>

      <div className="mt-4">
        <ItineraryBuilderFormWrapper formData={customItinerary} edit={true} />
      </div>
    </div>
  );
}

export default UpdateItinerary;
