import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import DataTable from "../components/common/DataTable";
import { getAdminUrl } from "../../ServiceConfig";
import ComponentHeader from "../components/common/ComponentHeader";
import H2 from "../../components/headings/H2";
import { FaFilePdf, FaFilter, FaPlusSquare } from "react-icons/fa";
import { getReadableDate } from "../../helpers/Helper";
import InputLabel from "../components/inputs/InputLabel";
import DateRangeInput from "../components/inputs/DateRangeInput";
import SelectSearch from "../components/inputs/SelectSearch";
import { getAllEmployeesOption } from "../services/EmployeeServices";
import useLoader from "../../hooks/useLoader";
import { checkPermission } from "../services/AdminServices";
import NotAuthorized403 from "../components/NotAuthorized403";
import {
  FaCheckSquare,
  FaCog,
  FaDownload,
  FaEdit,
  FaFileCsv,
  FaFileExcel,
  FaTimes,
} from "react-icons/fa";
import OutsideClick from "../../hooks/OutsideClick";

function EmployeeList() {
  const navigate = useNavigate();
  const EmployeeTable = useRef();

  const [selectedData, setSelectedData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [bulkActionOpen, setBulkActionOpen] = useState(false);
  const [columnVisibilityOpen, setColumnVisibilityOpen] = useState(false);
  const [downloadOptionOpen, setDownloadOptionOpen] = useState(false);
  const [renderedColumns, setRenderedColumns] = useState();

  const filterRef = useRef(null);
  const filterRef1 = useRef(null);
  const bulkActionRef = useRef(null);
  const bulkActionRef1 = useRef(null);
  const [employees, setEmployees] = useState([]);
  const [authorized, setAuthorized] = useState(false);
  const { setLoading } = useLoader();

  useEffect(() => {
    setLoading(true);
    checkPermission("Employee.read")
      .then((res) => {
        setAuthorized(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
    getAllEmployeesOption()
      .then((res) => {
        setEmployees(res.data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    EmployeeTable.current.setFilter(selectedFilter);
  }, [selectedFilter]);

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `<div class="employee-edit-btn" data-id=${
      cell.getRow().getData().id
    } style="display: flex; align-items: center; gap: 12px;">
    <div style="cursor: pointer; title="Edit">
    <svg
      x="0px"
      y="0px"
      width="16px"
      height="16px"
      viewBox="0 0 497.182 497.182"
      xml:space="preserve"
    >
      <g>
        <path
          d="M376.956,237.143l58.504-57.637v255.468c0,34.352-27.852,62.207-62.207,62.207H62.209
  c-34.352,0-62.207-27.855-62.207-62.207V123.932c0-34.355,27.855-62.211,62.207-62.211h255.453l-59.848,58.234H58.597v318.359
  h318.359V237.143z M377.469,56.755l62.967,62.985l20.993-20.986l-62.968-62.985L377.469,56.755z M434.211,0l-20.989,20.99
  l62.968,62.968l20.989-20.989L434.211,0z M171.288,262.987l188.91-188.953l62.969,62.984l-188.91,188.938l-91.703,28.677
  L171.288,262.987z M202.596,265.369l10.77,10.953l161.938-159.184l-10.77-10.953L202.596,265.369z"
        />
      </g>
    </svg></div>
  </div>`;
  };

  const updateFilter = (filter) => {
    let index = selectedFilter.findIndex((sf) => sf.field === filter.field);

    if (index >= 0) {
      if (filter.value && !Array.isArray(filter.value)) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else if (Array.isArray(filter.value) && filter.value[0]) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else {
        setSelectedFilter(
          selectedFilter.filter((af) => af.field !== filter.field)
        );
      }
    } else {
      setSelectedFilter([...selectedFilter, filter]);
    }
  };

  const columns = [
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Name",
      field: "full_name",
      minWidth: "170",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },

      resizable: true,
    },
    {
      title: "Email",
      minWidth: "150",
      width: "260",
      field: "email",
      resizable: true,
      responsive: 0,
      vertAlign: "middle",
    },
    {
      title: "Phone",
      field: "phone",
      responsive: 0,
      minWidth: "100",
    },
    {
      title: "Gender",
      field: "gender",
      responsive: 0,
      minWidth: "100",
    },
    {
      title: "status",
      field: "status",
      headerSort: false,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: "tickCross",
      minWidth: "60",
      responsive: 0,
    },
    {
      title: "Official Email",
      field: "official_contact.email",
      headerSort: false,
      responsive: 0,
      minWidth: "270",
    },
    {
      title: "Official Phone",
      field: "official_contact.phone",
      headerSort: false,
      responsive: 0,
      minWidth: "110",
    },
    {
      title: "Joining Date",
      minWidth: "120",
      field: "joining_date",
      responsive: 0,
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        let v = cell.getValue();
        return v != "" && v != null ? getReadableDate(cell.getValue()) : "";
      },
    },
    {
      title: "Date of Birth",
      minWidth: "120",
      field: "dob",
      resizable: true,
      responsive: 0,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        let v = cell.getValue();
        return v != "" && v != null ? getReadableDate(cell.getValue()) : "";
      },
    },
    {
      title: "Department",
      field: "department_name",
      resizable: true,
      headerSort: false,
      responsive: 0,
      vertAlign: "middle",
    },
    {
      title: "Employment Type",
      field: "employment_type",
      resizable: true,
      headerSort: false,
      responsive: 0,
      vertAlign: "middle",
      minWidth: "140",
    },

    // {
    //   formatter: actionIcons,
    //   headerSort: false,
    //   width: 80,
    //   responsive: 0,
    //   hozAlign: "center",
    //   responsive: 0,
    //   vertAlign: "middle",
    // },
  ];

  useEffect(() => {
    $(document).on("click", ".employee-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/employees/edit/${cell}`);
    });

    return () => {
      $(document).off("click", ".employee-edit-btn");
    };
  }, []);

  const rowClick = (data) => {
    navigate(`/employees/edit/${data.id}`);
  };

  return (
    <div className="flex h-full flex-col">
      <ComponentHeader className="border-b">
        <H2>Employees</H2>
        <Link
          to="/employees/add"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaPlusSquare /> Add New
        </Link>
      </ComponentHeader>
      <div className="h-full">
        {/* {selectedData?.length > 0 && (
          <div ref={bulkActionRef1} className="mr-2 inline-block pt-4">
            <button
              onClick={() => setBulkActionOpen(!bulkActionOpen)}
              className={`text-md flex items-center gap-1 whitespace-nowrap rounded-md border bg-white p-8 py-2 font-semibold tracking-wide ${
                bulkActionOpen
                  ? "-mb-[1px] rounded-b-none border-b-0 px-8 pb-4 pt-2"
                  : ""
              }`}
            >
              <FaEdit />
              Bulk Action
            </button>
          </div>
        )} */}
        <div
          ref={filterRef1}
          // onClick={() => setFilterOpen(!filterOpen)}
          className=" mr-2 inline-block pt-4"
        >
          <button
            onClick={() => setFilterOpen(!filterOpen)}
            className={` flex items-center whitespace-nowrap rounded-md border bg-white p-4 py-2 text-lg font-semibold tracking-wide dark:bg-slate-800 ${
              filterOpen
                ? "-mb-[1px] rounded-b-none border-b-0 px-8 pb-4 pt-2"
                : ""
            }`}
          >
            <FaFilter />
            Filters
          </button>
        </div>

        <div className="relative inline-block">
          <OutsideClick onClick={() => setColumnVisibilityOpen(false)}>
            <button
              onClick={() => {
                setColumnVisibilityOpen(!columnVisibilityOpen);
                setRenderedColumns(EmployeeTable.current.getColumns());
              }}
              className={`text-md mr-2 flex  items-center gap-1 rounded-md border bg-white p-8 py-2 font-semibold tracking-wide `}
            >
              <FaCog /> Column Visibility
            </button>

            <div
              className={`${
                columnVisibilityOpen ? "" : "hidden"
              } absolute left-0 top-full  z-40 mt-2 w-full rounded-md border bg-white py-0`}
            >
              <ul className="flex max-h-[300px] flex-col overflow-auto">
                {renderedColumns &&
                  renderedColumns.map((column, key) => (
                    <div key={key}>
                      {column._column.definition.title && (
                        <label htmlFor={`id${key}`} className={`m-0 !h-0 p-0`}>
                          <li
                            className={`${
                              column.isVisible()
                                ? "bg-white text-gray-900"
                                : "bg-gray-200 text-gray-900"
                            } text-md items-center border-y border-slate-100 py-2 px-4 text-justify  align-middle font-bold`}
                          >
                            <div className="flex items-center justify-between">
                              <p className="inline-block">
                                {column._column.definition.title}
                              </p>
                              <input
                                id={`id${key}`}
                                type="checkbox"
                                value="1"
                                checked={column.isVisible()}
                                onChange={(e) => {
                                  column.toggle();
                                  setRenderedColumns(
                                    EmployeeTable.current.getColumns()
                                  );
                                }}
                                className="hidden"
                              />
                              <div className="right-1 inline-block">
                                {column.isVisible() ? (
                                  <div className="text-green-700">
                                    <FaCheckSquare />
                                  </div>
                                ) : (
                                  <div className="text-red-700">
                                    <FaTimes />
                                  </div>
                                )}
                              </div>
                            </div>
                          </li>
                        </label>
                      )}
                    </div>
                  ))}
              </ul>
            </div>
          </OutsideClick>
        </div>
        <div className="relative inline-block">
          <OutsideClick onClick={() => setDownloadOptionOpen(false)}>
            <button
              onClick={() => {
                setDownloadOptionOpen(!downloadOptionOpen);
              }}
              className={`text-md mr-2 flex  items-center gap-1 rounded-md border bg-white p-8 py-2 font-semibold tracking-wide `}
            >
              <FaDownload />
              Download
            </button>

            <div
              className={`${
                downloadOptionOpen ? "" : "hidden"
              } absolute left-0 top-full  z-40 mt-2 w-full rounded-md border bg-white py-0`}
            >
              <ul className="flex max-h-[300px] flex-col overflow-auto">
                <div key="csv">
                  <li
                    onClick={() =>
                      EmployeeTable.current.download("csv", `Data.csv`, {
                        delimiter: ",",
                      })
                    }
                    className={`text-gray-900text-md cursor-pointer items-center border-y border-slate-100 bg-white py-2 px-4  text-justify align-middle font-bold`}
                  >
                    <div className="flex items-center justify-between">
                      <p className="inline-block">CSV</p>

                      <div className="right-1 inline-block">
                        <div className="text-green-700">
                          <FaFileCsv />
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
                <div key="xlsx">
                  <li
                    onClick={() =>
                      EmployeeTable.current.download("xlsx", `Data.csv`, {
                        sheetName: "sheet1",
                      })
                    }
                    className={`text-gray-900text-md cursor-pointer items-center border-y border-slate-100 bg-white py-2 px-4  text-justify align-middle font-bold`}
                  >
                    <div className="flex items-center justify-between">
                      <p className="inline-block">XLSX</p>

                      <div className="right-1 inline-block">
                        <div className="text-green-700">
                          <FaFileExcel />
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
                <div key="pdf">
                  <li
                    onClick={() =>
                      EmployeeTable.current.download(
                        "pdf",
                        `team-justwravel.pdf`,
                        {
                          orientation: "portrait",
                          title: "Team JustWravel",
                        }
                      )
                    }
                    className={`text-gray-900text-md cursor-pointer items-center border-y border-slate-100 bg-white py-2 px-4  text-justify align-middle font-bold`}
                  >
                    <div className="flex items-center justify-between">
                      <p className="inline-block">PDF</p>

                      <div className="right-1 inline-block">
                        <div className="text-red-500">
                          <FaFilePdf />
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </OutsideClick>
        </div>
        <div className="rounded-md">
          <div
            className={`flex grow flex-wrap  items-center gap-4 rounded-md rounded-tl-none bg-white dark:bg-slate-700 dark:text-white ${
              filterOpen ? "mb-4 border p-4" : "h-0 overflow-hidden p-0"
            }`}
          >
            <div>
              <InputLabel label="First Name" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "first_name",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="Last Name" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "last_name",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="Personal Email" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "email",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="Personal Phone" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "phone",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="Official Email" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "official_email",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="Official Phone" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "official_phone",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Joining Date" />
              <DateRangeInput
                controlBtns={true}
                horizontalPosition="left"
                onRangeChange={(startDate, endDate) =>
                  updateFilter({
                    field: "joining_date",
                    type: "in",
                    value: [
                      startDate
                        ? `${new Date(startDate).getFullYear()}-${
                            new Date(startDate).getMonth() + 1
                          }-${new Date(startDate).getDate()}`
                        : "",
                      endDate
                        ? `${new Date(endDate).getFullYear()}-${
                            new Date(endDate).getMonth() + 1
                          }-${new Date(endDate).getDate()}`
                        : "",
                    ],
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Birth Date" />
              <DateRangeInput
                controlBtns={true}
                horizontalPosition="left"
                onRangeChange={(startDate, endDate) =>
                  updateFilter({
                    field: "dob",
                    type: "in",
                    value: [
                      startDate
                        ? `${new Date(startDate).getFullYear()}-${
                            new Date(startDate).getMonth() + 1
                          }-${new Date(startDate).getDate()}`
                        : "",
                      endDate
                        ? `${new Date(endDate).getFullYear()}-${
                            new Date(endDate).getMonth() + 1
                          }-${new Date(endDate).getDate()}`
                        : "",
                    ],
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Reporting Manager" />
              <SelectSearch
                className="dark:text-black"
                value={
                  selectedFilter.find(
                    (filter) => filter.field === "reporting_manager"
                  )?.value
                }
                options={employees?.map((emp) => ({
                  label: `${emp?.first_name} ${emp?.last_name}`,
                  value: emp?.id,
                }))}
                onChange={(value) =>
                  updateFilter({
                    field: "reporting_manager",
                    type: "=",
                    value: value,
                  })
                }
              />
            </div>

            <div className="min-w-[250px]">
              <InputLabel label="Current Status" />
              <SelectSearch
                className="dark:text-black"
                value={
                  selectedFilter.find((filter) => filter.field === "status")
                    ?.value
                }
                options={[
                  { label: "Active", value: 1 },
                  { label: "Inactive", value: 0 },
                ]}
                onChange={(value) =>
                  updateFilter({
                    field: "status",
                    type: "=",
                    value: value,
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Gender" />
              <SelectSearch
                className="dark:text-black"
                value={
                  selectedFilter.find((filter) => filter.field === "gender")
                    ?.value
                }
                options={[
                  { label: "Female", value: "Female" },
                  { label: "Male", value: "Male" },
                ]}
                onChange={(value) =>
                  updateFilter({
                    field: "gender",
                    type: "=",
                    value: value,
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Employement Type" />
              <SelectSearch
                className="dark:text-black"
                value={
                  selectedFilter.find(
                    (filter) => filter.field === "employment_type"
                  )?.value
                }
                options={[
                  { label: "Full Time", value: "full time" },
                  { label: "Part Time", value: "part time" },
                ]}
                onChange={(value) =>
                  updateFilter({
                    field: "employment_type",
                    type: "=",
                    value: value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <DataTable
          ref={EmployeeTable}
          ajaxUrl={`${getAdminUrl()}/employees`}
          columns={columns}
          search={false}
          rowClick={rowClick}
          responsiveLayout="collapse"
        />
      </div>
    </div>
  );
}

export default EmployeeList;
