import React, { useEffect, useState } from "react";
import FileInput from "../../../components/inputFields/FileInput";
import { getAllStateByCountry } from "../../services/StateServices";
import { getAllCountriesOption } from "../../services/CountryServices";
import {
  updateLocation,
  uploadLocationImage,
} from "../../services/LocationServices";
import InputLabel from "../inputs/InputLabel";
import useLoader from "../../../hooks/useLoader";
import { toast } from "react-toastify";
import { createLocation } from "../../services/LocationServices";
import { getUploadsUrl } from "../../../ServiceConfig";

// -----------------------------------------------------------
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import KeywordsInput from "../inputs/KeywordsInput";
import SelectSearch from "../inputs/SelectSearch";

const LocationFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  country_id: Yup.string().required("Required"),
  state_id: Yup.string().when("country_id", {
    is: (country_id) => country_id === "101",
    then: Yup.string().required("Required"),
    otherwise: Yup.string().nullable(),
  }),
  status: Yup.number().required("Required"),
  meta_title: Yup.string()
    .nullable()
    .max(70, "Meta Title should not more than 70 characters"),
  meta_description: Yup.string()
    .max(160, "Meta Description should not more than 160 characters")
    .nullable(),
  // meta_keywords: Yup.array().of(Yup.string().nullable()).max(10).nullable(),
});

function LocationForm({ formData, edit, onSuccess }) {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  console.log(formData, "fdfdfdfdfddf==============");

  const { setLoading } = useLoader();

  // ? Fetch States By Country on Country Selection
  const getStatesByCountry = (countryId) => {
    setLoading(true);
    getAllStateByCountry(countryId)
      .then((res) => {
        setLoading(false);
        setStates(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    edit && formData?.country_id && getStatesByCountry(formData?.country_id);
  }, [countries]);

  useEffect(() => {
    getAllCountriesOption()
      .then((res) => {
        setCountries(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleCountryChange = (value) => {
    getStatesByCountry(value);
  };

  // ? Add new Location
  const addLocation = (values) => {
    console.log(values, "vvvvvvvvvv=================");

    let data = Object.assign({}, values);
    createLocation(data)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  // ? Update Location
  const editLocation = (values) => {
    let data = Object.assign({}, values);
    updateLocation(formData.id, data)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          banner_image: formData?.banner_image,
          name: formData?.name,
          tag_line: formData?.tag_line,
          country_id: formData?.country_id,
          state_id: formData?.state_id,
          status: formData?.status.toString() || "1",
          description: formData?.description,
          meta_title: formData?.meta_title,
          meta_description: formData?.meta_description,
          meta_keywords: formData?.meta_keywords,
        }}
        // validationSchema={LocationFormSchema}
        onSubmit={(values) => {
          edit ? editLocation(values) : addLocation(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="flex flex-wrap gap-4">
              {/* //? Banner Image */}
              <div className="grow">
                <FileInput
                  noOfFilesAllowed={1}
                  onChange={(file) => {
                    uploadLocationImage({ image: file }).then((res) => {
                      setFieldValue(
                        "banner_image",
                        res.data.data[0].uploaded_image
                      );
                    });
                  }}
                />
              </div>

              <div className="grow">
                <div className="flex flex-wrap gap-4">
                  {/* //? location Name */}
                  <div className="max-w-[50%] grow basis-[45%]">
                    <InputLabel label="Name" labelFor="name" />
                    <Field className="field" id="name" name="name" />
                    <ErrorMessage name="name">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  {/* //? Location Tag Line */}
                  <div className="max-w-[50%] grow basis-[45%]">
                    <InputLabel label="Tagline" labelFor="tag_line" />
                    <Field className="field" id="tag_line" name="tag_line" />
                    <ErrorMessage name="tag_line">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="my-4 flex flex-wrap gap-4">
                  {/* //? Select Country */}
                  <div className="max-w-[50%] grow basis-[45%]">
                    <InputLabel label="Select Country" labelFor="country" />
                    <SelectSearch
                      onChange={(value) => {
                        setFieldValue("country_id", value);
                        value !== 101 && setFieldValue("state_id", null);
                        value === 101 && getStatesByCountry(value);
                      }}
                      value={values.country_id}
                      options={countries?.map((country) => ({
                        label: country.name,
                        value: country.id,
                      }))}
                    />

                    <ErrorMessage name="country_id">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  {/* //? Select State */}
                  <div className="max-w-[50%] grow basis-[45%]">
                    <InputLabel label="Select State" labelFor="state" />
                    <SelectSearch
                      onChange={(value) => setFieldValue("state_id", value)}
                      disabled={values.country_id !== 101}
                      value={values.state_id}
                      options={states?.map((state) => ({
                        label: state.name,
                        value: state.id,
                      }))}
                    />

                    <ErrorMessage name="state_id">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                {/* //? Location Status */}
                <div className="my-4">
                  <div role="group" aria-labelledby="my-radio-group">
                    <InputLabel label="Status" />
                    <div className="flex items-center gap-12">
                      <label className="flex items-baseline gap-1">
                        <Field type="radio" name="status" value="1" />
                        Active
                      </label>
                      <label className="flex items-baseline gap-1">
                        <Field type="radio" name="status" value="0" />
                        Inactive
                      </label>
                    </div>
                  </div>
                </div>

                {/* //? Location Description */}
                <div>
                  <InputLabel label="Description" labelFor="description" />
                  <Field
                    name="description"
                    as="textarea"
                    className="field"
                  ></Field>
                  <ErrorMessage name="description">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
            </div>

            {/* //? Meta Fields */}
            <div className="my-4 rounded-lg border p-4">
              <h3 className="mb-2 text-lg font-bold">Meta Info</h3>

              {/* //? Location Meta Title */}
              <div>
                <InputLabel
                  label="Meta Title"
                  labelFor="meta_title"
                  instruction="(Max 70 Characters)"
                />
                <Field className="field" id="meta_title" name="meta_title" />
                <ErrorMessage name="meta_title">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              {/* //? location Meta Description */}
              <div className="my-4">
                <InputLabel
                  label="Meta Description"
                  labelFor="meta_description"
                  instruction="(Max 160 Characters)"
                />
                <Field
                  name="meta_description"
                  as="textarea"
                  className="field"
                ></Field>
                <ErrorMessage name="meta_description">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              {/* //? Location Meta Keywords */}
              <div>
                <InputLabel label="Meta Keywords" />
                {values.meta_keywords}
                <KeywordsInput
                  value={
                    values?.meta_keywords
                      ? values?.meta_keywords?.split(",")?.map((v) => ({
                          label: v,
                          value: v,
                        }))
                      : []
                  }
                  onChange={(value) =>
                    setFieldValue(
                      "meta_keywords",
                      value.map((v) => v.value).toString()
                    )
                  }
                />
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn-primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default LocationForm;
