import { admin } from "../../ServiceConfig";

export function getAllOccupancies(query) {
    return admin.get(`/occupancies?query=${query}`)
}
export function getAllOccupanciesOption() {
    return admin.get(`/allOccupancyOption`)
}
export function createOccupancy(data) {
    return admin.post(`/occupancies`, data)
}
export function updateOccupancy(id, data) {
    return admin.put(`/occupancies/${id}`, data)
}
export function deleteOccupancy(id) {
    return admin.delete(`/occupancies/${id}`)
}
export function getOccupancyById(id) {
    return admin.get(`/occupancies/${id}`)
}

