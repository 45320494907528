import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import InputLabel from "../components/inputs/InputLabel";
import FileInput from "../../components/inputFields/FileInput";
import {
  updateParticipantsById,
  uploadParticipantAirTicket,
  uploadParticipantId,
  uploadParticipantMedicalCertificate,
  uploadParticipantPanCard,
  uploadParticipantVisa,
} from "../services/ParticipantsService";
import { getUploadsUrl } from "../../ServiceConfig";
import { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import DateInput from "../components/inputs/DateInput";
import { formatNumberToTwoDigit } from "../../helpers/Helper";
import CountryCodeSelector from "../../components/inputFields/CountryCodeSelector";
import PhoneNumberInput from "../../components/inputFields/PhoneInput";

function UpdateParticipants({
  bookingData,
  participants,
  travelModes,
  riders,
  occupancies,
  setModalIsOpen,
}) {
  const [activeParticiapnt, setActiveParticiapnt] = useState(0);

  function calculateAge(date) {
    const today = new Date();
    const birthDate = new Date(date);
    const diff = today.getTime() - birthDate.getTime();
    const age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
    return age;
  }

  const updateParticipants = (participants) => {
    updateParticipantsById(bookingData.id, participants).then((res) => {
      setModalIsOpen(false);
    });
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          participants: participants,
        }}
        // validationSchema={bookingFormSchema}
        onSubmit={(values) => {
          // alert(JSON.stringify(values));
          updateParticipants(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <FieldArray
              name="participants"
              render={(arrayHelpers) => (
                <div>
                  {values.participants &&
                    values.participants.length > 0 &&
                    values.participants.map((participant, index) => (
                      <div key={index} className="my-4 rounded-[.5rem] border">
                        <div
                          onClick={() => setActiveParticiapnt(index)}
                          className="flex items-center justify-between px-4 py-2 font-medium"
                        >
                          Participant {index + 1}
                          <FaChevronDown />
                        </div>
                        <div
                          className={`flex-wrap gap-4 px-4 py-2 ${
                            activeParticiapnt === index
                              ? "flex border-t"
                              : "hidden"
                          }`}
                        >
                          <div className="grow">
                            <InputLabel label="First Name" />
                            <Field
                              className="field"
                              name={`participants.${index}.first_name`}
                            />
                          </div>
                          <div className="grow">
                            <InputLabel label="Last Name" />
                            <Field
                              className="field"
                              name={`participants.${index}.last_name`}
                            />
                          </div>

                          <div className="grow">
                            <InputLabel label="Email" />
                            <Field
                              className="field"
                              name={`participants.${index}.email`}
                            />
                          </div>
                          <div className="grow">
                            <PhoneNumberInput
                              phone={`${values.participants[index].country_code}${values.participants[index].phone}`}
                              onChange={(value, data) => {
                                setFieldValue(
                                  `participants.${index}.country_code`,
                                  data.dialCode
                                );
                                setFieldValue(
                                  `participants.${index}.phone`,
                                  value.slice(data.dialCode.length)
                                );
                              }}
                            />

                            {/* <div className="h-6 w-[1px] border"></div> */}
                          </div>
                          <div className="grow">
                            <div className="flex items-end gap-4">
                              <InputLabel label="Whatsapp" />
                              <label
                                htmlFor="same-whatsapp"
                                className="text-p-xs text-gray-500"
                              >
                                <input
                                  type="checkbox"
                                  name=""
                                  id="same-whatsapp"
                                  value={
                                    values.participants[index].same_as_phone
                                  }
                                  checked={
                                    values.participants[index].phone ===
                                    values.participants[index].whatsapp
                                  }
                                  onChange={(e) => {
                                    setFieldValue(
                                      `participants.${index}.same_as_phone`,
                                      e.target.checked
                                    );

                                    setFieldValue(
                                      `participants.${index}.country_code`,
                                      "91"
                                    );

                                    setFieldValue(
                                      `participants.${index}.whatsapp`,
                                      values.participants[index].phone
                                    );
                                  }}
                                />
                                &nbsp; Same as Phone
                              </label>
                            </div>
                            <div className="flex grow">
                              <div>
                                <div className="h-[33px] rounded-l-lg border">
                                  <CountryCodeSelector
                                    setCountryCode={(value) =>
                                      setFieldValue(
                                        `participants.${index}.whatsapp_country_code`,
                                        value
                                      )
                                    }
                                  />
                                </div>
                                <ErrorMessage name={`whatsapp_country_code`}>
                                  {(msg) => (
                                    <div className="error-msg">{msg}</div>
                                  )}
                                </ErrorMessage>
                              </div>

                              <div className="grow">
                                <Field
                                  className="field !rounded-l-none"
                                  name={`participants.${index}.whatsapp`}
                                />
                                <ErrorMessage name="whatsapp">
                                  {(msg) => (
                                    <div className="error-msg">{msg}</div>
                                  )}
                                </ErrorMessage>
                              </div>
                            </div>
                          </div>
                          <div className="grow">
                            <InputLabel label="DOB" />
                            <DateInput
                              className="!rounded-full border-[1px] py-[0.625rem]"
                              onDateChange={(value) => {
                                setFieldValue(
                                  `participants.${index}.dob`,
                                  `${new Date(
                                    value
                                  ).getFullYear()}-${formatNumberToTwoDigit(
                                    new Date(value).getMonth() + 1
                                  )}-${formatNumberToTwoDigit(
                                    new Date(value).getDate()
                                  )}`
                                );
                                setFieldValue(
                                  `participants.${index}.age`,
                                  calculateAge(
                                    `${new Date(value).getFullYear()}-${
                                      new Date(value).getMonth() + 1
                                    }-${new Date(value).getDate()}`
                                  )
                                );
                              }}
                              selectedDate={values.date_of_birth}
                            />
                          </div>
                          <div className="grow">
                            <InputLabel label="Age" />
                            <Field
                              className="field"
                              disabled
                              name={`participants.${index}.age`}
                            />
                          </div>
                          <div className="grow">
                            <InputLabel label="Gender" />
                            <Field
                              as="select"
                              className="field"
                              name={`participants.${index}.gender`}
                            >
                              <option value=""></option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </Field>
                          </div>
                          <div className="grow">
                            <InputLabel label="Coming From (City)" />
                            <Field
                              className="field"
                              name={`participants.${index}.coming_from`}
                            />
                          </div>

                          <div className="flex basis-full gap-4">
                            <div className="grow">
                              <InputLabel label="Emergency Contact Name" />
                              <Field
                                className="field"
                                name={`participants.${index}.emergency_contact_name`}
                              />
                            </div>
                            <div className="grow">
                              <InputLabel label="Emergency Contact Relation" />
                              <Field
                                as="select"
                                className="field"
                                name={`participants.${index}.emergency_contact_relation`}
                              >
                                <option value=""></option>
                                <option value="father">Father</option>
                                <option value="mother">Mother</option>
                                <option value="sister">Sister</option>
                                <option value="brother">Brother</option>

                                <option value="spouse">Spouse</option>
                                <option value="guardian">Guardian</option>
                              </Field>
                            </div>
                            <div className="grow">
                              <InputLabel label="Emergency Contact" />
                              <div className="flex grow">
                                <div>
                                  <div className="h-[33px] rounded-l-lg border">
                                    <CountryCodeSelector
                                      setCountryCode={(value) =>
                                        setFieldValue(
                                          `emergency_contact_country_code`,
                                          value
                                        )
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="grow">
                                  <Field
                                    className="field !rounded-l-none"
                                    name={`participants.${index}.emergency_contact`}
                                  />
                                  <ErrorMessage
                                    name={`participants.${index}.emergency_contact`}
                                  >
                                    {(msg) => (
                                      <div className="error-msg">{msg}</div>
                                    )}
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex basis-full gap-4">
                            <div className="grow">
                              <InputLabel label="ID Type" />
                              <Field
                                as="select"
                                className="field"
                                name={`participants.${index}.id_proof`}
                              >
                                {bookingData?.country_id === 101 && (
                                  <>
                                    <option value="aadhar">Aadhar Card</option>
                                    <option value="driving_license">
                                      Driving License
                                    </option>
                                    <option value="voter_id">Voter ID</option>
                                  </>
                                )}
                                <option value="passport">Passport</option>
                                <option value="other">Other</option>
                              </Field>
                            </div>
                            <div className="grow">
                              <InputLabel label="ID Number" />
                              <Field
                                className="field"
                                name={`participants.${index}.id_number`}
                              />
                            </div>
                            <div className="max-w-[33%] grow">
                              <div className="grow">
                                <InputLabel label="Id Card" />
                                <FileInput
                                  value={`${getUploadsUrl()}/bookings/participant_id_attachment/${
                                    values.participants[index].id_proof
                                  }`}
                                  noOfFilesAllowed={1}
                                  onChange={(file) => {
                                    uploadParticipantId({ image: file }).then(
                                      (res) => {
                                        setFieldValue(
                                          `participants.${index}.id_proof`,
                                          res.data.data[0].uploaded_image
                                        );
                                      }
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="basis-full">
                            <div>
                              <label
                                htmlFor="include_insurance"
                                className="text-primary"
                              >
                                <input
                                  type="checkbox"
                                  name=""
                                  id="include_insurance"
                                  value={values.participants[index].insurance}
                                  onChange={(e) =>
                                    setFieldValue(
                                      `participants.${index}.insurance`,
                                      e.target.checked
                                    )
                                  }
                                />
                                &nbsp;Include Insurance (Upto 4.5L for free)
                              </label>
                            </div>

                            {values.participants[index].insurance && (
                              <div className="flex flex-wrap gap-4">
                                <div className="grow">
                                  <InputLabel label="Address" />
                                  <Field
                                    as="textarea"
                                    className="field"
                                    name={`participants.${index}.address`}
                                  />
                                </div>
                                <div className="grow">
                                  <InputLabel label="Blood Group" />
                                  <Field
                                    className="field"
                                    name={`participants.${index}.blood_group`}
                                  />
                                </div>
                                <div className="grow">
                                  <InputLabel label="Nationality" />
                                  <Field
                                    className="field"
                                    name={`participants.${index}.nationality`}
                                  />
                                </div>
                                <div className="grow">
                                  <InputLabel label="Health Issue" />
                                  <div className="flex gap-12">
                                    <label
                                      className="flex items-baseline gap-1"
                                      htmlFor="sumerTrip"
                                    >
                                      <Field
                                        type="radio"
                                        name={`participants.${index}.health_issue`}
                                        id="sumerTrip"
                                        value="1"
                                      />
                                      Yes
                                    </label>
                                    <label className="flex items-baseline gap-1">
                                      <Field
                                        type="radio"
                                        name={`participants.${index}.health_issue`}
                                        value="0"
                                      />
                                      No
                                    </label>
                                  </div>
                                </div>
                                {values?.participants[index]?.health_issue ===
                                  "1" && (
                                  <div className="grow">
                                    <InputLabel label="Describe health Issue" />
                                    <Field
                                      as="textarea"
                                      className="field"
                                      name={`participants.${index}.health_condition`}
                                    />
                                  </div>
                                )}
                                <div className="grow">
                                  <InputLabel label="Allergies (if any)" />
                                  <Field
                                    as="textarea"
                                    className="field"
                                    name={`participants.${index}.allergy`}
                                  />
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="mb-8 flex basis-full gap-4">
                            {bookingData?.country_id !== 101 && (
                              <>
                                <div className="grow">
                                  <InputLabel label="Pan Card" />
                                  <FileInput
                                    value={`${getUploadsUrl()}/bookings/participants/pan_card/${
                                      values.participants[index].id_proof
                                    }`}
                                    noOfFilesAllowed={1}
                                    onChange={(file) => {
                                      uploadParticipantPanCard({
                                        image: file,
                                      }).then((res) => {
                                        setFieldValue(
                                          `participants.${index}.pan_card`,
                                          res.data.data[0].uploaded_image
                                        );
                                      });
                                    }}
                                  />
                                </div>
                                <div className="grow">
                                  <InputLabel label="Visa" />
                                  <FileInput
                                    value={`${getUploadsUrl()}/bookings/participants/visa/${
                                      values.participants[index].id_proof
                                    }`}
                                    noOfFilesAllowed={1}
                                    onChange={(file) => {
                                      uploadParticipantVisa({
                                        image: file,
                                      }).then((res) => {
                                        setFieldValue(
                                          `participants.${index}.visa`,
                                          res.data.data[0].uploaded_image
                                        );
                                      });
                                    }}
                                  />
                                </div>
                                <div className="grow">
                                  <InputLabel label="Air Ticket" />
                                  <FileInput
                                    value={`${getUploadsUrl()}/bookings/participants/air_ticket/${
                                      values.participants[index].id_proof
                                    }`}
                                    noOfFilesAllowed={1}
                                    onChange={(file) => {
                                      uploadParticipantAirTicket({
                                        image: file,
                                      }).then((res) => {
                                        setFieldValue(
                                          `participants.${index}.air_ticket`,
                                          res.data.data[0].uploaded_image
                                        );
                                      });
                                    }}
                                  />
                                </div>
                              </>
                            )}
                            <div className="grow">
                              <InputLabel label="Medical Certificate" />
                              <FileInput
                                value={`${getUploadsUrl()}/bookings/participants/medical-certifcates/${
                                  values.participants[index].id_proof
                                }`}
                                noOfFilesAllowed={1}
                                onChange={(file) => {
                                  uploadParticipantMedicalCertificate({
                                    image: file,
                                  }).then((res) => {
                                    setFieldValue(
                                      `participants.${index}.medical_certificate`,
                                      res.data.data[0].uploaded_image
                                    );
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            />

            <div className="text-center">
              <button type="submit" className="btn-primary">
                Update Participants
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default UpdateParticipants;
