import { Link, useNavigate } from "react-router-dom";
import StateForm from "./EmployeeForm";
// import MetaTags from "../../../web/layouts/MetaTags";
import ComponentHeader from "../components/common/ComponentHeader";
import H2 from "../../components/headings/H2";
import { FaListAlt } from "react-icons/fa";
import EmployeeForm from "./EmployeeForm";
import { Helmet } from "react-helmet";
import MetaTags from "../components/Metatags";
import { useEffect, useState } from "react";
import { checkPermission } from "../services/AdminServices";
import NotAuthorized403 from "../components/NotAuthorized403";
import { toast } from "react-toastify";
import useLoader from "../../hooks/useLoader";

function AddEmployee() {
  const navigate = useNavigate();
  const [authorized, setAuthorized] = useState(false);
  const { setLoading } = useLoader();

  useEffect(() => {
    setLoading(true);
    checkPermission("Employee.create")
      .then((res) => {
        setAuthorized(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }, []);

  const onAddState = (data) => {
    navigate(`/employees`);
  };

  return !authorized ? (
    <NotAuthorized403 />
  ) : (
    <div className="">
      <MetaTags title="Add Employee | Admin Panel" />
      {/* <Helmet>
        <title>Add Employee</title>
      </Helmet> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Add Employee</H2>
        <Link
          to="/employees"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>

      <EmployeeForm onSuccess={(data) => onAddState(data)} />
    </div>
  );
}

export default AddEmployee;
