import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import DataTable from "../common/DataTable";
import { getAdminUrl } from "../../../ServiceConfig";
import ComponentHeader from "../common/ComponentHeader";
import H2 from "../../../components/headings/H2";
import { FaPlusSquare } from "react-icons/fa";
import { deleteStayCategory } from "../../services/StayServices";


function StayCategoriesList() {
    const navigate = useNavigate();
    const stayCategoriesList = useRef();

    const deleteStayCategoryById = (id) => {
        if (!window.confirm("Do You really want to delete this Stay Category?")) return;
        deleteStayCategory(id)
            .then((res) => {
                toast.success(res.data.message);
                stayCategoriesList.current.deleteRow(id);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };

    const actionIcons = function (cell, formatterParams, onRendered) {
        return `
    <div class="flex items-center gap-3">
      <div class="cate-edit-btn cursor-pointer" data-id=${cell.getRow().getData().id
            }>
          <i class="fas fa-edit"></i>
      </div>
      <div class="cat-delete-btn cursor-pointer text-red-500" data-id=${cell.getRow().getData().id
            }>
        <i class="fas fa-trash-alt"></i>
      </div>
  </div>`;
    };


    const columns = [
        {
            formatter: "responsiveCollapse",
            width: 30,
            minWidth: 30,
            responsive: 0,
            hozAlign: "center",
            resizable: false,
            headerSort: false,
        },
        {
            title: "Id",
            field: "id",
            responsive: 0,
            width: "50",
            resizable: true,
            vertAlign: "middle",
        },
        {
            title: "Name",
            field: "name",
            responsive: 0,
            minWidth: "200",
            resizable: true,
            vertAlign: "middle",
        },
        {
            formatter: actionIcons,
            headerSort: false,
            width: 80,
            responsive: 0,
            hozAlign: "center",
            vertAlign: "middle",
        },
    ];

    useEffect(() => {
        $(document).on("click", ".cate-edit-btn", function (e) {
            e.stopPropagation();
            e.preventDefault();
            let cell = $(this).attr("data-id");
            navigate(`/stay-categories/edit/${cell}`);
        });
        $(document).on("click", ".cat-delete-btn", function (e) {
            e.stopPropagation();
            e.preventDefault();
            let dataId = $(this).attr("data-id");
            deleteStayCategoryById(dataId);
        });
        return () => {
            $(document).off("click", ".cate-edit-btn");
            $(document).off("click", ".cat-delete-btn");
        };
    }, []);

    return (
        <div className="flex h-full flex-col">
            <ComponentHeader className="border-b">
                <H2>Stay categories</H2>
                <Link
                    to="/stay-categories/add"
                    className="btn-primary flex items-center gap-1 whitespace-nowrap"
                >
                    <FaPlusSquare /> Add New
                </Link>
            </ComponentHeader>
            <div className="h-full">
                <DataTable
                    ref={stayCategoriesList}
                    ajaxUrl={`${getAdminUrl()}/stay-categories`}
                    columns={columns}
                    search={true}
                    responsiveLayout="collapse"
                />
            </div>
        </div>
    );
}

export default StayCategoriesList;
