import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import useTheme from "../hooks/useTheme";
import moment from "moment";

function ColumnChartLeadVsBooking({ chartData }) {
  const { theme } = useTheme();
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (chartData) {
      setOptions({
        chart: {
          type: "bar",
          height: 350,
          background: "transparent",
        },
        theme: {
          mode: theme, // Enable dark mode
        },
        colors: [
          "#3699FF",
          "#1BC5BD",
          "#F64E60",
          "#FF5733",
          "#FF33A1",
          "#8950FC",
          "#FFA800", // Yellow
          "#33FFF6", // Cyan
          "#FF5733", // Orange
          "#33D4FF", // Light Blue
          "#FF33B1", // Magenta
        ],

        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: Object.keys(chartData.leadCount).map((date) =>
            moment(date, "YYYY-MM-DD").format("DD MMM")
          ),
          labels: {
            style: {
              colors: theme === "dark" ? "#fff" : "#000",
            },
          },
        },
        yaxis: {
          title: {
            text: "",
          },
          labels: {
            style: {
              colors: theme === "dark" ? "#fff" : "#000",
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
          theme: theme, // Tooltip theme based on chart theme
        },
      });

      setSeries([
        {
          name: "Leads",
          data: Object.values(chartData.leadCount),
        },
        {
          name: "Bookings",
          data: Object.values(chartData.bookingCount),
        },
      ]);
    }
  }, [chartData, theme]);

  return (
    <div className="w-full">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
}

export default ColumnChartLeadVsBooking;
