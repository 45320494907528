import React, { useEffect, useState } from "react";
import FileInput from "../../../components/inputFields/FileInput";

import InputLabel from "../inputs/InputLabel";
import { toast } from "react-toastify";
import EditorInput from "../inputs/EditorInput";

// -----------------------------------------------------------
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import KeywordsInput from "../inputs/KeywordsInput";
import { getAllBlogs } from "../../services/PackageService";
import MultiSelect from "../inputs/MultiSelect";
import {
  createListingSeo,
  updateListingSeoData,
  uploadListingLandscapeImage,
  uploadListingSquareImage,
  uploadMetaImage,
} from "../../services/ListingSeoServices";
import { useNavigate } from "react-router-dom";
import { getUploadsUrl } from "../../../ServiceConfig";
import { MdClose } from "react-icons/md";
import { FaPlusSquare } from "react-icons/fa";

const ListingSeoFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  status: Yup.number().required("Required"),
  meta_title: Yup.string()
    .nullable()
    .max(70, "Meta Title should not more than 70 characters"),
  meta_description: Yup.string()
    .max(160, "Meta Description should not more than 160 characters")
    .nullable(),
  meta_keywords: Yup.array().of(Yup.string().nullable()).max(10).nullable(),
});

function ListingSeoForm({ formData, edit, onSuccess }) {
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();

  // ? Add new country
  const addListingSeo = (values) => {
    createListingSeo(values)
      .then((res) => {
        toast.success(res.data.message);
        navigate(`/listing-seo`);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  // ? Update country
  const editListingSeo = (values) => {
    updateListingSeoData(formData.id, values)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllBlogs().then((res) => {
      setBlogs(res.data);
    });
  }, []);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          banner_image_square: formData?.banner_image_square || [],
          banner_image_landscape: formData?.banner_image_landscape || [],
          title: formData?.title || "",
          slug: formData?.slug || "",
          short_description: formData?.short_description || "",
          description: formData?.description || "",
          blog_ids: formData?.blog_ids || [],
          youtube_videos: formData?.youtube_videos || [],
          insta_carousel: formData?.insta_carousel || [],
          more_description: formData?.more_description || "",
          meta_title: formData?.meta_title || "",
          meta_description: formData?.meta_description || "",
          meta_keywords: formData?.meta_keywords || "",
          meta_image: formData?.meta_image || "",
          faqs: formData?.faqs || [],
        }}
        // validationSchema={ListingSeoFormSchema}
        onSubmit={(values, { isSubmitting }) => {
          edit
            ? editListingSeo(values, isSubmitting)
            : addListingSeo(values, isSubmitting);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="flex flex-wrap gap-4">
              {/* //? Banner Image */}
              <div className="grid grow grid-cols-2 gap-4">
                <div className="">
                  <InputLabel
                    label="Banner Image"
                    instruction={`(2 images in square format 500 X 500)`}
                  />
                  <FileInput
                    value={values.banner_image_square.map(
                      (img) => `${getUploadsUrl()}/listing-seo/square/${img}`
                    )}
                    noOfFilesAllowed={2}
                    onChange={(file) => {
                      uploadListingSquareImage({ image: file }).then((res) => {
                        setFieldValue(
                          "banner_image_square",
                          res.data.data.map((img) => img.uploaded_image)
                        );
                      });
                    }}
                  />
                </div>
                <div className="">
                  <InputLabel
                    label="Banner Image"
                    instruction={`(2 images in landscape format 500 X 310)`}
                  />
                  <FileInput
                    value={values.banner_image_landscape.map(
                      (img) => `${getUploadsUrl()}/listing-seo/landscape/${img}`
                    )}
                    noOfFilesAllowed={2}
                    onChange={(file) => {
                      uploadListingLandscapeImage({ image: file }).then(
                        (res) => {
                          setFieldValue(
                            "banner_image_landscape",
                            res.data.data.map((img) => img.uploaded_image)
                          );
                        }
                      );
                    }}
                  />
                </div>
              </div>

              <div className="my-4 grow">
                <div className="flex flex-wrap gap-4">
                  {/* //? country Name */}
                  <div className="max-w-[50%] grow basis-[45%]">
                    <InputLabel label="Title" labelFor="title" />
                    <Field
                      className="field"
                      id="title"
                      name="title"
                      // disabled={edit}
                    />
                    <ErrorMessage name="title">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  {/* //? country Tag Line */}
                  <div className="max-w-[50%] grow basis-[45%]">
                    <InputLabel label="Slug" labelFor="slug" />
                    <Field
                      className="field"
                      id="slug"
                      name="slug"
                      // disabled={edit}
                    />
                    <ErrorMessage name="slug">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="my-4">
                  <InputLabel
                    label="Short Description"
                    labelFor="description"
                  />
                  <EditorInput
                    fieldKey="short_description"
                    data={values.short_description}
                  />

                  <ErrorMessage name="description">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>

                <div className="my-4">
                  <InputLabel label="Description" labelFor="description" />
                  <EditorInput
                    fieldKey="description"
                    data={values.description}
                  />

                  <ErrorMessage name="description">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                {/* //? Related Blogs */}
                <div className="my-4 grow basis-full lg:basis-[45%]">
                  <InputLabel label="Related Blogs" />
                  <MultiSelect
                    onChange={(value) => setFieldValue("blog_ids", value)}
                    value={Array.isArray(values.blog_ids) && values.blog_ids}
                    options={blogs?.map((blog) => ({
                      label: blog?.title,
                      value: blog.id,
                    }))}
                  />

                  <ErrorMessage name="blog_ids">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>

                <div className="flex gap-4">
                  <div className="grow basis-full lg:basis-[45%]">
                    <InputLabel label="Youtube Video Ids" />
                    <KeywordsInput
                      value={
                        Array.isArray(values.youtube_videos) &&
                        values.youtube_videos?.map((v) => ({
                          label: v,
                          value: v,
                        }))
                      }
                      onChange={(value) =>
                        setFieldValue(
                          "youtube_videos",
                          value.map((v) => v.value)
                        )
                      }
                    />
                    <ErrorMessage name="youtube_videos">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="grow basis-full lg:basis-[45%]">
                    <InputLabel label="Insta Post Ids" />
                    <KeywordsInput
                      value={
                        Array.isArray(values.insta_carousel) &&
                        values.insta_carousel?.map((v) => ({
                          label: v,
                          value: v,
                        }))
                      }
                      onChange={(value) =>
                        setFieldValue(
                          "insta_carousel",
                          value.map((v) => v.value)
                        )
                      }
                    />
                    <ErrorMessage name="insta_carousel">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="my-4">
                  <InputLabel
                    label="More Description"
                    labelFor="more_description"
                  />
                  <EditorInput
                    fieldKey="more_description"
                    data={values.more_description}
                  />

                  <ErrorMessage name="more_description">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>

                <div className="my-4">
                  <FieldArray name={`faqs`}>
                    {({ insert, remove, push }) => (
                      <div className="relative rounded-lg border-2 p-4">
                        <h5 className="mb-2 text-lg font-medium">Faqs</h5>
                        {values.faqs.map((faq, indx) => (
                          <div className="relative rounded-lg" key={indx}>
                            <div className="flex items-center gap-4">
                              <div className="grow">
                                <InputLabel label="Question" />
                                <Field
                                  name={`faqs.${indx}.question`}
                                  type="text"
                                  className="field"
                                  // onBlur={() => updateFaq(values.faqs[index])}
                                />
                                <ErrorMessage name={`faqs${indx}.question`}>
                                  {(msg) => (
                                    <div className="error-msg">{msg}</div>
                                  )}
                                </ErrorMessage>
                              </div>
                              <button
                                type="button"
                                onClick={() => remove(indx)}
                                className="rounded-full border p-1"
                              >
                                <MdClose />
                              </button>
                            </div>

                            {/* //? Itinerary Description */}
                            <div className="my-4">
                              <InputLabel label="Answer" />
                              <EditorInput
                                fieldKey={`faqs.${indx}.answer`}
                                data={values.faqs[indx].answer}
                                // onBlur={() => updateFaq(values.faqs[index])}
                              />
                            </div>
                          </div>
                        ))}
                        <button
                          type="button"
                          className="my-4 flex items-center gap-1 rounded-md border border-jw-green px-4 text-jw-green"
                          onClick={() =>
                            push({
                              question: "",
                              answer: "",
                            })
                          }
                        >
                          <FaPlusSquare /> Faq
                        </button>
                      </div>
                    )}
                  </FieldArray>
                </div>
              </div>
            </div>

            {/* //? Meta Fields */}
            <div className="my-4 rounded-lg border p-4">
              <h3 className="mb-2 text-lg font-bold">Meta Info</h3>

              {/* //? country Meta Title */}
              <div>
                <InputLabel
                  label="Meta Title"
                  labelFor="meta_title"
                  instruction="(Max 70 Characters)"
                />
                <Field className="field" id="meta_title" name="meta_title" />
                <ErrorMessage name="meta_title">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              {/* //? country Meta Description */}
              <div className="my-4">
                <InputLabel
                  label="Meta Description"
                  labelFor="meta_description"
                  instruction="(Max 160 Characters)"
                />
                <Field
                  name="meta_description"
                  as="textarea"
                  className="field"
                ></Field>
                <ErrorMessage name="meta_description">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              {/* //? country Meta Keywords */}
              <div className="my-4">
                <InputLabel label="Meta Keywords" />
                <KeywordsInput
                  value={
                    values?.meta_keywords
                      ? values?.meta_keywords?.split(",")?.map((v) => ({
                          label: v,
                          value: v,
                        }))
                      : []
                  }
                  onChange={(value) =>
                    setFieldValue(
                      "meta_keywords",
                      value.map((v) => v.value).toString()
                    )
                  }
                />
              </div>

              <div>
                <InputLabel label="Meta Image" />
                <FileInput
                  value={`${getUploadsUrl()}/listing-seo/meta-image/${
                    values.meta_image
                  }`}
                  noOfFilesAllowed={1}
                  onChange={(file) => {
                    uploadMetaImage({ image: file }).then((res) => {
                      setFieldValue(
                        "meta_image",
                        res.data.data[0].uploaded_image
                      );
                    });
                  }}
                />
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn-primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ListingSeoForm;
