import { ErrorMessage, Field, useFormikContext } from "formik";
import InputLabel from "../../inputs/InputLabel";
import { getAllStateByCountry } from "../../../services/StateServices";
import SelectSearch from "../../inputs/SelectSearch";
import NumberIncrementDecrementInput from "../../inputs/NumberIncrementDecrementInput";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { getAllCountriesOption } from "../../../services/CountryServices";
import DateInput from "../../inputs/DateInput";
import {
  getCustomPackageByCountryId,
  getCustomPackageById,
  getCustomPackageByStateId,
  uploadCustomPackageImage,
} from "../../../services/CustomPackageServices";
import FileInput from "../../../../components/inputFields/FileInput";
import { getUploadsUrl } from "../../../../ServiceConfig";
import { getAllEmployeesOption } from "../../../services/EmployeeServices";
import useAdminAuth from "../../../hooks/useAdminAuth";
import PhoneNumberInput from "../../../../components/inputFields/PhoneInput";
import ToggleInput from "../../inputs/ToggleInput";

function ItineraryUserForm({ edit }) {
  const { auth } = useAdminAuth();

  const { values, setFieldValue, errors } = useFormikContext();
  console.log(errors);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [customPackages, setCustomPackages] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null)

  const getStateByCountry = () => {
    getAllStateByCountry(101).then((res) => {
      setStates(res.data.data);
    });
  };

  useEffect(() => {
    getAllCountriesOption().then((res) => {
      setCountries(res.data.data);
    });

    getAllEmployeesOption().then((res) => {
      setEmployees(res.data.data.data);
    });
  }, []);

  useEffect(() => {
    setFieldValue(
      "total_pax",
      values.adults + values.children + values.infants
    );
  }, [values.adults, values.children, values.infants]);

  useEffect(() => {
    if (values.state_id) {
      getCustomPackageByStateId(values.state_id).then((res) => {
        setCustomPackages(res.data.data);
      });
      return;
    }

    if (values.country_id && !values.state_id) {
      getCustomPackageByCountryId(values.country_id).then((res) => {
        setCustomPackages(res.data.data);
      });
    }
  }, [values.state_id, values.country_id]);

  useEffect(() => {
    if (!values.no_of_nights) return;

    let days = [];

    for (let i = 0; i <= values.no_of_nights; i++) {
      days.push({
        title: values?.itinerary_days[i]?.title || "",
        description: values?.itinerary_days[i]?.description || "",
        breakfast:
          values?.itinerary_days[i]?.breakfast || values?.package_type === "1"
            ? 1
            : 0,
        lunch: values?.itinerary_days[i]?.lunch || 0,
        dinner: values?.itinerary_days[i]?.dinner || 0,
        transfer_id: values?.itinerary_days[i]?.transfer_id,
        transfer_from: values?.itinerary_days[i]?.transfer_from,
        transfer_to: values?.itinerary_days[i]?.transfer_to,
      });
    }

    setFieldValue(`itinerary_days`, days);
  }, [values.no_of_nights]);

  useEffect(() => {
    if (!values.selected_package) return;

    getCustomPackageById(values.selected_package).then((res) => {
      setSelectedPackage(res.data.data)
    })
  }, [values.selected_package]);

  useEffect(() => {
    if (selectedPackage) {
      setFieldValue(`no_of_nights`, selectedPackage.no_of_nights);
      handleNoOfNightsInput(selectedPackage.no_of_nights)
      setFieldValue(`package_title`, selectedPackage.package_title);
      setFieldValue(`itinerary_stays`, selectedPackage.itinerary_stays);
      setFieldValue(`itinerary_days`, selectedPackage.itinerary_days);
      setFieldValue(`cancellation_policy`, selectedPackage.cancellation_policy);
      setFieldValue(`payment_policy`, selectedPackage.payment_policy);
      setFieldValue(`inclusions`, selectedPackage.inclusions);
      setFieldValue(`exclusions`, selectedPackage.exclusions);
      setFieldValue(`visa_policy`, selectedPackage.visa_policy);
    }
  }, [selectedPackage])

  useEffect(() => {
    if (values?.start_date && values?.no_of_nights) {
      updateStays(values?.start_date, values?.no_of_nights)
    }
  }, [values?.itinerary_stays])

  useEffect(() => {
    if (parseInt(values.package_type) === 0) {
      setFieldValue("country_id", 101);
      getStateByCountry();
    } else {
      setFieldValue(
        "country_id",
        values.country_id === 101 ? "" : values.country_id
      );
      setFieldValue("state_id", "");
    }
  }, [values.package_type]);

  const updateStays = (startDate, noOfNights, endDate) => {
    let stays = values.itinerary_stays;

    if (stays.length === 1) {
      stays[0].check_in_date = startDate;
      stays[0].no_of_nights = noOfNights;
      stays[0].check_out_date = moment(startDate)
        .add(noOfNights, "days")

        .format("YYYY-MM-DD");
    }

    stays.forEach((stay, index) => {
      if (index === 0) {
        stay.check_in_date = startDate;
        stay.check_out_date = moment(startDate)
          .add(stay.no_of_nights, "days")
          //
          .format("YYYY-MM-DD");
      }

      if (index !== 0 && index !== stays.length) {
        stay.check_in_date = stays[index - 1].check_out_date;
        stay.check_out_date = moment(stays[index - 1].check_out_date)
          .add(stay.no_of_nights, "days")
          //
          .format("YYYY-MM-DD");
      }
    });

    setFieldValue(`itinerary_stays`, stays);
  };

  const handleStartDateInput = (value) => {
    let startDate = moment(value).format("YYYY-MM-DD");
    setFieldValue(`start_date`, startDate);
    if (values.no_of_nights) {
      let endDate = moment(startDate)
        .add(values.no_of_nights, "days")

        .format("YYYY-MM-DD");

      setFieldValue(`end_date`, endDate);
      updateStays(startDate, values.no_of_nights, endDate);
    } else {
      setFieldValue(`no_of_nights`, 1);
      let endDate = moment(startDate)
        .add(1, "days")

        .format("YYYY-MM-DD");

      setFieldValue(`end_date`, endDate);

      updateStays(startDate, 1, endDate);
    }
  };

  const updateStaysOnEndDateChange = (endDate) => {
    let stays =
      values.itinerary_stays.length > 0
        ? values.itinerary_stays
        : [{ check_in_date: values.start_date }];

    stays[stays.length - 1].check_out_date = endDate;
    stays[stays.length - 1].no_of_nights = moment(endDate).diff(
      stays[stays.length - 1].check_in_date,
      "days"
    );

    if (stays[stays.length - 1].no_of_nights <= 0) {
      stays.splice(stays.length - 1, 1);
      updateStaysOnEndDateChange(endDate);
    } else {
      setFieldValue(`itinerary_stays`, stays);
    }
  };

  const handleEndDateInput = (value) => {
    let endDate = moment(value).format("YYYY-MM-DD");
    setFieldValue(`end_date`, endDate);
    setFieldValue(
      "no_of_nights",
      moment(endDate).diff(values.start_date, "days")
    );
    updateStaysOnEndDateChange(endDate);
  };

  const handleNoOfNightsInput = (value) => {
    setFieldValue("no_of_nights", value);
    if (!value) return;
    setFieldValue(
      "end_date",
      moment(values.start_date).add(value, "days").format("YYYY-MM-DD")
    );

    updateStaysOnEndDateChange(
      moment(values.start_date).add(value, "days").format("YYYY-MM-DD")
    );
  };

  return (
    <div>
      <div className="rounded-lg border-2 p-4">
        <h3 className="font-bold">User Details</h3>
        <div className="my-4 flex flex-wrap gap-4 lg:flex-nowrap">
          <div className="grow">
            <InputLabel label="First Name" required={true} />
            <Field className="field" name="first_name" />
            <ErrorMessage name="first_name">
              {(msg) => <div className="error-msg">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="grow">
            <InputLabel label="Last Name" />
            <Field className="field" name="last_name" />
            <ErrorMessage name="last_name">
              {(msg) => <div className="error-msg">{msg}</div>}
            </ErrorMessage>
          </div>

          <div className="grow z-50">
            <PhoneNumberInput
              phone={`${values.country_code}${values.phone}`}
              onChange={(value, data) => {
                setFieldValue(`country_code`, data.dialCode);
                setFieldValue(`phone`, value.slice(data.dialCode.length));
              }}
            />
            <ErrorMessage name="phone">
              {(msg) => <div className="error-msg">{msg}</div>}
            </ErrorMessage>
          </div>

          <div className="grow">
            <InputLabel label="Email" />
            <Field className="field" name="email" />
            <ErrorMessage name="email">
              {(msg) => <div className="error-msg">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
      </div>

      <div className="flex gap-4">
        <div className="relative my-4 max-w-[300px] lg:z-30 z-40">
          <InputLabel label="Point of Contact" />
          <SelectSearch
            options={employees.map((employee) => ({
              value: employee.id,
              label: `${employee.first_name} ${employee.last_name}`,
            }))}
            value={values.point_of_contact || auth?.id}
            onChange={(value) => {
              setFieldValue("point_of_contact", value);
            }}
          />
        </div>


        <div className="relative my-4 max-w-[300px] lg:z-30 z-40">
          <InputLabel label="Is Best Seller" />
          <ToggleInput
            fieldKey='is_bestseller'
            checked={values.is_bestseller}
            onToggle={(e) => {
              setFieldValue('is_bestseller', e.target.checked ? 1 : 0)
            }} 
          />
        </div>
      </div>

      <div className="my-4 rounded-lg border-2 p-4">
        <h3 className="font-bold">Package Details</h3>
        <div className="my-4">
          <div className="grow basis-full lg:basis-[30%]">
            <div role="group" aria-labelledby="my-radio-group">
              <InputLabel label="Package Type" required={true} />
              <div className="flex items-center gap-12">
                <label className="flex items-baseline gap-1">
                  <Field type="radio" name="package_type" value="1" />
                  International
                </label>
                <label className="flex items-baseline gap-1">
                  <Field type="radio" name="package_type" value="0" />
                  Domestic
                </label>
              </div>
              <ErrorMessage name="package_type">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
          </div>
        </div>
        {values.package_type && (
          <div>
            <div className="my-4 flex flex-wrap gap-4 lg:flex-nowrap">
              <div className="lg:min-w-[25%] min-w-[100%] grow relative z-20">
                <InputLabel label="Select Country" required={true} />
                <SelectSearch
                  options={countries.map((country) => ({
                    value: country.id,
                    label: country.name,
                  }))}
                  disabled={values.country_id === 101}
                  value={values.country_id}
                  onChange={(value) => {
                    setFieldValue("country_id", value);
                    setFieldValue("state_id", "");
                    if (value === 101) setFieldValue("package_type", "0");
                  }}
                />
                <ErrorMessage name="country_id">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
              {values.package_type === "0" && (
                <div className="lg:min-w-[25%] min-w-[100%]  grow z-30 relative">
                  <InputLabel
                    label="Select State"
                    required={values.country_id === 101}
                  />
                  <SelectSearch
                    options={states.map((state) => ({
                      value: state.id,
                      label: state.name,
                    }))}
                    value={values.state_id}
                    onChange={(value) => {
                      setFieldValue("state_id", value);
                    }}
                  />
                  <ErrorMessage name="state_id">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
              )}

              <div className="grow">
                <InputLabel label="Starting Location" required={true} />
                <Field className="field" name="starting_location" />
                <ErrorMessage name="starting_location">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
              <div className="grow">
                <InputLabel label="Ending Location" required={true} />
                <Field className="field" name="ending_location" />
                <ErrorMessage name="ending_location">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>

            <div className="my-6 flex flex-wrap gap-4 lg:flex-nowrap">
              <div className="flex lg:flex-row flex-col grow gap-4">
                <div className="grow ">
                  <InputLabel label="Start Date" required={true} />
                  <DateInput
                    name="start_date"
                    minDate={new Date()}
                    value={values.start_date}
                    onDateChange={(value) => {
                      handleStartDateInput(value);
                    }}
                  />
                  <ErrorMessage name="start_date">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>

                <div className="">
                  <InputLabel label="Nights" required={true} />
                  <Field
                    className="field max-w-[100px]"
                    type="number"
                    min="1"
                    name="no_of_nights"
                    disabled={!values.start_date}
                    onChange={(e) => {
                      handleNoOfNightsInput(e.target.value);
                    }}
                  />
                  <ErrorMessage name="no_of_nights">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>

                <div className="grow ">
                  <InputLabel label="End Date" required={true} />
                  <DateInput
                    minDate={
                      values.start_date
                        ? new Date(
                          moment(values.start_date)
                            .add(1, "days")

                            .format("YYYY-MM-DD")
                        )
                        : new Date()
                    }
                    value={values.end_date}
                    onDateChange={(value) => {
                      handleEndDateInput(value);
                    }}
                  />
                  <ErrorMessage name="end_date">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>

              <div className="flex flex-wrap gap-4">
                <div className="">
                  <InputLabel label="Adults" required={true} />
                  <NumberIncrementDecrementInput
                    value={values.adults}
                    updateCounter={(value) => setFieldValue("adults", value)}
                  />
                  <ErrorMessage name="adults">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="">
                  <InputLabel label="Children" />
                  <NumberIncrementDecrementInput
                    value={values.children}
                    updateCounter={(value) => setFieldValue("children", value)}
                  />
                </div>
                <div className="">
                  <InputLabel label="Infants" />
                  <NumberIncrementDecrementInput
                    value={values.infants}
                    updateCounter={(value) => setFieldValue("infants", value)}
                  />
                </div>
                <div>
                  <InputLabel label="Total Pax" />
                  <Field
                    className="field max-w-[100px]"
                    name="total_pax"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="my-4 lg:flex-row flex-col flex lg:items-end gap-4">
              <div className="grow">
                <InputLabel label="Enter Package Title" required={true} />
                <Field className="field" name="package_title" />
                <ErrorMessage name="package_title">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
              <div className="mb-1 text-lg font-bold">OR</div>
              <div className="grow z-20">
                <InputLabel label="Select Package" />
                <SelectSearch
                  options={customPackages?.map((customPackage) => ({
                    value: customPackage?.id,
                    label: customPackage.package_title,
                  }))}
                  value={values.selected_package}
                  onChange={(value) => {
                    setFieldValue("selected_package", value);
                  }}
                />
                <ErrorMessage name="selected_package">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>

            <div className="my-4 overflow-hidden">
              <InputLabel
                label="Select Package Image Or Paste URL"
                required={true}
              />
              <FileInput
                value={values.banner_image}
                noOfFilesAllowed={1}
                onChange={(file) => {
                  uploadCustomPackageImage({ image: file }).then((res) => {
                    setFieldValue(
                      "banner_image",
                      `${getUploadsUrl()}/custom-packages/${res.data.data[0].uploaded_image
                      }`
                    );
                  });
                }}
              />
              <ErrorMessage name="banner_image">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
              {/* <div>
                <InputLabel label="Image Url" />
                <Field className="field" name="banner_image" />
              </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ItineraryUserForm;
