function SvgTrip({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 130 130"
    >
      <path d="M89.4 112.4H62.7c-2 7.4-6.8 11.4-13.4 11.3-5.1-.1-11.3-3.3-13-11.4H15.9c-5.9 0-10-4-10-10V85.7c0-1.7.5-3.2 1.7-4.5l8.5-9.4c.3-.3.6-.7 1-1.2-2.1 0-4-.1-5.8 0-1.1.1-1.6.9-1.6 2v3.6H5.8c.1-1.8 0-3.7.4-5.5.5-2.3 2.8-3.9 5.2-4h8.4c.5 0 1-.2 1.4-.5 2.7-2.3 5.8-3.3 9.3-3.3H64c-.6-.4-.9-.6-1.3-.8-3.7-2.2-7.5-4.2-11.1-6.5-7.5-4.7-11.4-11.7-11.6-20.7-.1-4.3 0-8.7 0-13 0-1.1.4-1.8 1.4-2.4l22-12.8c.9-.5 1.7-.5 2.7 0l22 12.8c1 .6 1.4 1.3 1.3 2.4v12.9c-.3 9.2-4.3 16.2-12 21.1-3.6 2.2-7.3 4.3-10.9 6.4-.3.2-.6.4-1.2.7H117.1c4.1 0 6.4 2.3 6.4 6.4v36.4c0 4.3-2.2 6.5-6.6 6.5h-1.1c-.7 3.8-2.5 6.9-5.7 9.1-2.4 1.6-5 2.4-7.9 2.3-6.3-.2-10.8-4.1-12.8-11.3m18.9-26.5H21.1v5c0 3.9-2.3 6.3-6.3 6.3H9.7v5.1c0 3.9 2.3 6.3 6.2 6.3h20.4c1.4-6.7 6.3-11.1 12.5-11.4 3.1-.1 5.9.7 8.4 2.5 3 2.2 4.8 5.2 5.4 8.8h26.8c1.7-8.1 7.8-11 12.3-11.3 2.9-.2 5.6.5 8 2.1 3.4 2.2 5.3 5.3 6 9.3h1.6c1.6 0 2.3-.7 2.3-2.3v-9h-5.5c-3.5 0-5.9-2.4-5.9-5.9 0-1.9.1-3.6.1-5.5M43.8 29.7v5.4c.2 6.9 3 12.5 8.6 16.5 3.7 2.6 7.8 4.7 11.8 7.1.3.2.9.1 1.2-.1 3.6-2 7.2-4 10.6-6.2 5.4-3.5 8.7-8.5 9.3-14.9.4-4.8.2-9.6.2-14.4 0-.3-.4-.8-.8-1L65.6 10.9c-.6-.4-1.1-.4-1.8 0L44.6 22c-.6.3-.8.7-.8 1.4v6.3M12.1 82h31.6V66.9c-4.8 0-9.6-.1-14.3 0-2.3 0-4.4.9-6 2.6L12.1 82m35.5 0h15V67h-15v15zm34-15h-15v15h15V67zm19-.1h-15v15h15v-15zm19 15.1V68.7c0-1.2-.9-1.8-2.2-1.8h-12.2c-.2 0-.4.1-.7.1v15h15.1zM59 110.5c0-5.2-4.1-9.5-9.3-9.6-5.3-.1-9.6 4.1-9.6 9.3-.1 5.3 4.2 9.6 9.4 9.6 5.1.2 9.4-4 9.5-9.3m43.6-9.5c-5.2 0-9.5 4.3-9.5 9.5s4.3 9.4 9.4 9.4c5.3 0 9.6-4.3 9.5-9.6 0-5.1-4.3-9.3-9.4-9.3M9.7 93.4h5.6c1 0 1.8-.6 1.8-1.5.1-2 0-4 0-6H9.7v7.5zm109.8 0v-7.5H112c0 2-.1 3.9 0 5.8 0 1 .8 1.7 1.8 1.7h5.7"></path>
      <path d="M59.6 36.2L72.8 23c1.6-1.6 2.3-1.6 3.9 0l4.8 4.8c1.2 1.2 1.2 2.1 0 3.3L61.2 51.4c-1.2 1.2-2.1 1.1-3.3 0L47.1 40.6c-1.1-1.1-1.1-2.1 0-3.2l5.2-5.2c1.1-1.1 2.1-1.1 3.2 0 1.4 1.2 2.7 2.6 4.1 4m-8.4 2.5l8.4 8.4 17.8-17.8-2.7-2.8-.9.9-12.4 12.4c-1.4 1.4-2.2 1.4-3.6 0L54.1 36l-2.9 2.7"></path>
    </svg>
  );
}

export default SvgTrip;
