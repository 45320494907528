import React, { useEffect, useState } from "react";
import { FaListAlt } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import H2 from "../../components/headings/H2";
import ComponentHeader from "../components/common/ComponentHeader";
import B2BForm from "./B2BForm";
import { getEmployeeById } from "../services/EmployeeServices";
import NotAuthorized403 from "../components/NotAuthorized403";
import { checkPermission } from "../services/AdminServices";
import useLoader from "../../hooks/useLoader";
import { getRoleById } from "../services/RoleServices";
import { getB2BbyId } from "../services/B2BServices";


function EditB2B() {
  const navigate = useNavigate();

  const [authorized, setAuthorized] = useState(true);
  const { setLoading } = useLoader();
  const [role, setRole] = useState()
  const [businessData, setBusinessData] = useState()
  const employeeData = {}
  const [openEmployeePermissionModal, setOpenEmployeePermissionModal] = useState(true)
  // For permission
  // useEffect(() => {
  //   setLoading(true);
  //   checkPermission("B2B.update")
  //     .then((res) => {
  //       setAuthorized(res.data.data);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       toast.error(err.response.data.message);
  //     });
  // }, []);

  const b2bId = useParams().id;

  useEffect(() => {
getB2BbyId(b2bId).then((res) => {
  toast.success(res.data.data.message)
  setBusinessData(res.data.data)
}).catch((er) => {
  console.log(er)
  toast.error("error in updating b2b")
})
  }, [])


  const onEditBusiness = (data) => {
    console.log(data)
    // navigate(`/employees`);
  };

  useEffect(() => {
    // employeeId &&
    //   getEmployeeById(employeeId)
    //     .then((res) => {
    //       setEmployeeData(res.data.data);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
  }, []);

  return !authorized ? (
    <NotAuthorized403 />
  ) : (
    <div className="dark:bg-slate-900 dark:text-white h-[100%]">
      {/* <MetaTags title="Edit State | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update B2B</H2>
        <Link
          to="/b2b"
          className="btn-primary flex items-center gap-1 whitespace-nowrap py-1 px-4 dark:bg-green-700 lg:px-12 lg:py-2"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {businessData && (
        <>
        <B2BForm
          formData={businessData}
          edit={true}
          onSuccess={(data) => onEditBusiness(data)}
        />
        </>
      )}
    </div>
  );
}

export default EditB2B;
