
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { createRewards, createRnR, updateRewardById, updateRnRById, uploadRewardImage } from "../services/RewardsAndRecognitionServices";
import InputLabel from '../components/inputs/InputLabel'
import { getUploadsUrl } from "../../ServiceConfig";
import FileInput from "../../components/inputFields/FileInput";
import DateInput from "../components/inputs/DateInput";
import SelectSearch from "../components/inputs/SelectSearch";
import moment from "moment";


const months = [
    { number: "01", month: "January" },
    { number: "02", month: "February" },
    { number: "03", month: "March" },
    { number: "04", month: "April" },
    { number: "05", month: "May" },
    { number: "06", month: "June" },
    { number: "07", month: "July" },
    { number: "08", month: "August" },
    { number: "09", month: "September" },
    { number: "10", month: "October" },
    { number: "11", month: "November" },
    { number: "12", month: "December" }
];

const years = ["2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027"];
const rnrSchema = Yup.object().shape({
    year: Yup.string().required("Required"),
    month: Yup.string().required("Required"),
    proposed_at: Yup.string().required("Required"),
});

function RnRForm({ formData, edit, onSuccess }) {
    // ? Add new RnR
    const addNewRnR = (values) => {
        createRnR(values)
            .then((res) => {
                toast.success(res.data.data.message);
                onSuccess(res.data.data);
            })
            .catch((err) => {
                console.log(err.response.data.message)
                toast.error(err.response.data.message);
            });
    };

    // ? Update RnR
    const editRnR = (values) => {
        let data = Object.assign({}, values);
        updateRnRById(formData.id, data)
            .then((res) => {
                toast.success(res.data.message);
                onSuccess(res.data.data);
            })
            .catch((err) => {
                console.log(err)
                toast.error(err.response.data.message);
            });
    };

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    year: formData?.year ? formData.year : moment(new Date()).format('YYYY'),
                    month: formData?.month ? formData.month : moment().format('MM'),
                    proposed_at: formData?.proposed_at ? formData.proposed_at : moment(new Date()).format('YYYY-MM-DD'),
                    presented_at: formData?.presented_at ? formData.presented_at : "",
                }}
                validationSchema={rnrSchema}
                onSubmit={(values) => {
                    edit ? editRnR(values) : addNewRnR(values);
                }}
            >
                {({ values, setFieldValue, errors, touched }) => (
                    <Form>
                        <div className="">

                            <div className="">
                                <div className="grid grid-cols-4 gap-4">

                                    {/* //? Month */}
                                    <div className="col-span-1">
                                        <InputLabel label="Select Month" />
                                        <SelectSearch
                                            options={months.map((month) => ({
                                                value: month.number,
                                                label: month.month,
                                            }))}
                                            value={values.month}
                                            onChange={(value) => {
                                                setFieldValue('month', value)
                                            }}
                                        />
                                        <ErrorMessage name="month">
                                            {(msg) => <div className="error-msg">{msg}</div>}
                                        </ErrorMessage>
                                    </div>

                                    {/* //? Year */}
                                    <div className="col-span-1">
                                        <InputLabel label="Select Year" />
                                        <SelectSearch
                                            options={years.map((year) => ({
                                                value: year,
                                                label: year,
                                            }))}
                                            value={values.year}
                                            onChange={(value) => {
                                                setFieldValue('year', value)
                                            }}
                                        />
                                        <ErrorMessage name="year">
                                            {(msg) => <div className="error-msg">{msg}</div>}
                                        </ErrorMessage>
                                    </div>


                                    {/* Proposed Date */}
                                    <div className="col-span-1">
                                        <InputLabel label="Select Proposed Date" />
                                        <DateInput
                                            className="!rounded-full border-[1px] py-[0.625rem]"
                                            onDateChange={(value) => {
                                                setFieldValue('proposed_at', moment(value).format("YYYY-MM-DD"));
                                            }}
                                            value={values.proposed_at}
                                        />
                                        <ErrorMessage name="proposed_at">
                                            {(msg) => <div className="error-msg">{msg}</div>}
                                        </ErrorMessage>
                                    </div>




                                    {/* Presented Date */}
                                    <div className="col-span-1">
                                        <InputLabel label="Select Presented Date" />
                                        <DateInput
                                            className="!rounded-full border-[1px] py-[0.625rem]"
                                            onDateChange={(value) => {
                                                setFieldValue('presented_at', moment(value).format("YYYY-MM-DD"));
                                            }}
                                            value={values.presented_at}
                                        />
                                        <ErrorMessage name="presented_at">
                                            {(msg) => <div className="error-msg">{msg}</div>}
                                        </ErrorMessage>
                                    </div>


                                </div>

                            </div>
                        </div>
                        <div className="mt-4 text-center">
                            <button type="submit" className="btn-primary dark:bg-green-700">
                                Submit
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default RnRForm;
