import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import DataTable from "../common/DataTable";
import { getAdminUrl, getUploadsUrl } from "../../../ServiceConfig";
import ComponentHeader from "../common/ComponentHeader";
import { FaPlusSquare } from "react-icons/fa";
import H2 from "../../../components/headings/H2";
import InputLabel from "../inputs/InputLabel";
import { Field, Form, Formik } from "formik";
import DateInput from "../inputs/DateInput";
import SpecialDatesForm from "./SpecialDatesForm";
import { useState } from "react";
import { deleteSpecialDate } from "../../services/SpecialDateServices";

function SpecialDatesList() {
  const navigate = useNavigate();
  const sdTable = useRef();

  const [sdIdToEdit, setSdIdToEdit] = useState("");

  const deleteSelectedTtk = (id) => {
    if (!window.confirm("Do You really want to delete this Special date?"))
      return;
    deleteSpecialDate(id)
      .then((res) => {
        toast.success(res.data.message);
        sdTable.current.deleteRow(id);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
  <div class="flex items-center gap-3">
      <div class="sd-edit-btn cursor-pointer" data-id=${
        cell.getRow().getData().id
      }>
          <i class="fas fa-edit"></i>
      </div>

  </div>
  `;
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Title",
      field: "title",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Start Date",
      field: "start_date",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
    },
    {
      title: "End Date",
      field: "end_date",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
    },
    {
      title: "Status",
      field: "status",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
    },

    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  useEffect(() => {
    $(document).on("click", ".sd-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      setSdIdToEdit(cell);
    });

    $(document).on("click", ".sd-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      deleteSelectedTtk(dataId);
    });

    return () => {
      $(document).off("click", ".sd-edit-btn");
      $(document).off("click", ".sd-delete-btn");
    };
  }, []);

  return (
    <div className="">
      <ComponentHeader className="border-b">
        <H2>Special Dates</H2>
      </ComponentHeader>

      <SpecialDatesForm
        sdIdToEdit={sdIdToEdit}
        setSdIdToEdit={setSdIdToEdit}
        onSuccess={() => sdTable.current.updateTable()}
      />

      <div className="mt-12 border-t">
        <DataTable
          ref={sdTable}
          ajaxUrl={`${getAdminUrl()}/special-date`}
          columns={columns}
          search={true}
          responsiveLayout="collapse"
        />
      </div>
    </div>
  );
}

export default SpecialDatesList;
