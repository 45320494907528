import React, { useState } from 'react';

const StatusDropdown = ({ statusArray, onSelect }) => {
    const [selectedStatus, setSelectedStatus] = useState('');

    const handleChange = (e) => {
        setSelectedStatus(e.target.value);
        onSelect(e.target.value);
    };

    return (
        <div className="relative">
            <select
                value={selectedStatus}
                onChange={handleChange}
                className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
                <option value="">Select Status</option>
                {statusArray.map((status, index) => (
                    <option key={index} value={status}>{status}</option>
                ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 11.293a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L10 12.414l-2.293 2.293a1 1 0 01-1.414-1.414l3-3a1 1 0 010-1.414z" />
                </svg>
            </div>
        </div>
    );
};

export default StatusDropdown;
