import { Link, useNavigate } from "react-router-dom"; 
// import MetaTags from "../../../web/layouts/MetaTags";
import ComponentHeader from "../components/common/ComponentHeader";
import H2 from "../../components/headings/H2";
import { FaListAlt } from "react-icons/fa";
import RewardForm from "./RewardForm";
import RnRForm from "./RnRForm";

function AddRnR() {
  const navigate = useNavigate();

  const onAddState = (data) => {
    navigate(`/rnr`);
  };

  return  <div className="">
      <ComponentHeader className="mb-4 border-b">
        <H2>Add RnR</H2>
        <Link
          to="/rnr"
          className="btn-primary flex dark:bg-green-800 items-center gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>

      <RnRForm onSuccess={(data) => onAddState(data)} />
    </div>
}

export default AddRnR;
