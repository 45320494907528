import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import DataTable from "../common/DataTable";
import { deleteState } from "../../services/StateServices";
import { getAdminUrl, getUploadsUrl } from "../../../ServiceConfig";
import ComponentHeader from "../common/ComponentHeader";
import H2 from "../../../components/headings/H2";
import { FaPlusSquare } from "react-icons/fa";

function StateList() {
  const navigate = useNavigate();
  const stateTable = useRef();

  const deleteSelectedState = (id) => {
    deleteState(id)
      .then((res) => {
        toast.success(res.data.message);
        stateTable.current.deleteRow(id);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
  <div class="flex items-center gap-3">
      <div class="state-edit-btn cursor-pointer" data-id=${
        cell.getRow().getData().id
      }>
          <i class="fas fa-edit"></i>
      </div>
      <div class="state-delete-btn cursor-pointer text-red-500" data-id=${
        cell.getRow().getData().id
      }>
        <i class="fas fa-trash-alt"></i>
      </div>
  </div>
  `;
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Name",
      field: "name",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "GST code",
      field: "gst_code",
      minWidth: "100",
      vertAlign: "middle",
      hozAlign: "center",
      responsive: 0,
      resizable: true,
    },
    {
      title: "Alpha code",
      field: "alpha_code",
      minWidth: "100",
      vertAlign: "middle",
      hozAlign: "center",
      responsive: 0,
      resizable: true,
    },
    {
      title: "Packages",
      field: "package_count",
      hozAlign: "center",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      resizable: true,
    },
    {
      title: "Starting Price",
      field: "price_starting_from",
      hozAlign: "center",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      resizable: true,
    },
    // {
    //   title: "Country",
    //   minWidth: "100",
    //   field: "country.name",
    //   resizable: true,
    //   vertAlign: "middle",
    // },
    {
      title: "Status",
      field: "status",
      resizable: true,
      hozAlign: "center",
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
          return  `<div
          class="relative grid select-none items-center ${cell.getValue() === 1 ? "bg-green-200 text-green-800" : "bg-red-200 text-red-800"} rounded-full border  py-1.5 px-3 font-sans text-xs font-bold">
          <span class=""> ${cell.getValue() === 1 ? "Active" : "Inactive"}</span>
        </div>`;
      },
    },
    {
      title: "Banner Image",
      hozAlign: "center",
      vertAlign: "middle",
      headerSort: false,
      field: "banner_image",
      formatter: "image",
      formatterParams: {
        height: "50px",
        width: "80px",
        urlPrefix: `${getUploadsUrl()}/states/`,
        urlSuffix: "",
      },
    },
    // {
    //   title: "Description",
    //   field: "description",
    //   resizable: true,
    //   headerSort: false,
    //   vertAlign: "middle",
    // },
    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  useEffect(() => {
    $(document).on("click", ".state-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/state/edit/${cell}`);
    });

    $(document).on("click", ".state-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      deleteSelectedState(dataId);
    });

    return () => {
      $(document).off("click", ".state-edit-btn");
      $(document).off("click", ".state-delete-btn");
    };
  }, []);

  return (
    <div className="flex h-full flex-col">
      <ComponentHeader className="border-b">
        <H2>States</H2>
        <Link
          to="/state/add"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaPlusSquare /> Add New
        </Link>
      </ComponentHeader>
      <div className="h-full">
        <DataTable
          ref={stateTable}
          ajaxUrl={`${getAdminUrl()}/states`}
          columns={columns}
          search={true}
          responsiveLayout="collapse"
        />
      </div>
    </div>
  );
}

export default StateList;
