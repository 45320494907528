import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { FaListAlt } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
// import MetaTags from "../../../web/layouts/MetaTags";
import { PackageDataProvider } from "../../context/PackageDataProvider";
import { getPackage, updatePackageStatus } from "../../services/PackageService";
import ComponentHeader from "../common/ComponentHeader";
import ToggleInput from "../inputs/ToggleInput";
import PackageForm from "./packageForms/PackageForm";

function EditPackage() {
  const packageId = useParams().id;

  const [packageData, setPackageData] = useState();
  const [packageStatus, setPackageStatus] = useState();

  const handleStatusChange = (e) => {
    setPackageStatus(e.target.checked);
    updatePackageStatus(packageData.id, {
      status: e.target.checked,
    }).then((res) => {
      console.log(res.data.data);
    });
  };

  useEffect(() => {
    getPackage(packageId)
      .then((res) => {
        setPackageData(res.data.data);
        setPackageStatus(res.data.data.status);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <div className="py-4 lg:p-4">
      {/* <MetaTags title="Add Package | Admin Panel" /> */}
      <ComponentHeader className="border-b px-0 lg:px-4">
        <h2 className="flex items-center gap-4 text-xl font-bold">
          <div>{packageData?.title ? packageData.title : "Edit Package"}</div>
          <div>
            <ToggleInput
              fieldKey="package-status"
              checked={packageStatus}
              onToggle={handleStatusChange}
            />
          </div>
        </h2>

        <Link
          to="/package"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      <div className="mt-4">
        <PackageDataProvider>
          <PackageForm edit={true} packageId={packageId} />
        </PackageDataProvider>
      </div>
    </div>
  );
}

export default EditPackage;
