import { Link, useNavigate } from "react-router-dom";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";
import JobForm from "./JobForm";

function AddJob() {
  const navigate = useNavigate();

  const onAddJob = (data) => {
    navigate(`/jobs`);
  };

  return (
    <div className="">
      {/* <MetaTags title="Add Location | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Add Job Opening</H2>
        <Link
          to="/jobs"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      <JobForm onSuccess={(data) => onAddJob(data)} />
    </div>
  );
}

export default AddJob;
