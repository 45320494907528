import React from 'react';

import Modal from "react-modal";
import { getUploadsUrl } from '../../../ServiceConfig';
import { MdClose } from 'react-icons/md';
const NotificationPopup = ({ open, setOpen, data }) => {
    if (!open) return null;

    return (
        <Modal
            isOpen={open}
            onRequestClose={() => { setOpen(false) }}
            contentLabel="Update Payment Reciept"
            className="absolute top-1/2 left-1/2 p-6 lg:max-h-[90vh] max-h-[80vh] overflow-auto lg:min-w-[50%] lg:max-w-[60%] w-[90vw] -translate-y-1/2 -translate-x-1/2 rounded-lg dark:bg-slate-800 bg-white"
        >
            <div className='flex flex-col p-2 gap-6'>
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold dark:text-white text-gray-900">{data.title}</h2>
                    <button
                        onClick={() => { setOpen(false) }}
                        className="text-gray-500 hover:text-gray-700 focus:outline-none"
                    >
                        <MdClose size={25} color='dark:white' />
                    </button>
                </div>
                <img
                    src={`${getUploadsUrl()}/internal-notification/${data.image}`}
                    alt={data.title}
                    className="w-full h-56 object-contain rounded-lg mb-4"
                />
            </div>
            <div className="text-gray-500 dark:text-white space-y-2">
            <p className='text-gray-500 dark:text-white mb-4 lg:max-w-full max-w-xs break-all whitespace-normal'><strong>Description:</strong> {data.description}</p>
                {data.link && <p className='text-gray-500 dark:text-white mb-4 lg:max-w-full max-w-xs break-all whitespace-normal'><strong>Link:</strong> <a href={data.link} className="text-blue-500 hover:underline">{data.link}</a></p>}
                <p><strong>Created by:</strong> {data.created_by.first_name}</p>
            </div>
        </Modal>
    );
};

export default NotificationPopup;
