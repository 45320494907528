import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { deletePermission } from "../../services/PermissionServices";
import { toast } from "react-toastify";
import DataTable from "../common/DataTable";
import { getAdminUrl } from "../../../ServiceConfig";
import ComponentHeader from "../common/ComponentHeader";
import { FaPlusSquare } from "react-icons/fa";

import H2 from "../../../components/headings/H2";

function PermissionList() {
  const navigate = useNavigate();
  const permissionTable = useRef();

  const deleteSelectedPermission = (id) => {
    deletePermission(id)
      .then((res) => {
        toast.success(res.data.message);
        permissionTable.current.deleteRow(id);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
  <div class="flex items-center gap-3">
      <div class="permission-edit-btn cursor-pointer" data-id=${
        cell.getRow().getData().id
      }>
          <i class="fas fa-edit"></i>
      </div>
      <div class="permission-delete-btn cursor-pointer text-red-500" data-id=${
        cell.getRow().getData().id
      }>
        <i class="fas fa-trash-alt"></i>
      </div>
  </div>`;
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Name",
      field: "name",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Module",
      field: "module",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: false,
      headerSort: false,
    },

    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  useEffect(() => {
    $(document).on("click", ".permission-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/permissions/edit/${cell}`);
    });

    $(document).on("click", ".permission-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      deleteSelectedPermission(dataId);
    });

    return () => {
      $(document).off("click", ".permission-edit-btn");
      $(document).off("click", ".permission-delete-btn");
    };
  }, []);

  return (
    <div className="">
      <ComponentHeader className="border-b">
        <H2>Permissions</H2>
        <Link
          to="/permissions/add"
          className="btn-primary flex items-center gap-1 dark:bg-green-800 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
        >
          <FaPlusSquare /> Add New
        </Link>
      </ComponentHeader>
      <div className="">
        <DataTable
          ref={permissionTable}
          ajaxUrl={`${getAdminUrl()}/permissions`}
          columns={columns}
          search={true}
          responsiveLayout="collapse"
          groupBy="module"
          initialSort={[{ column: "name", dir: "asc" }]}
        />
      </div>
    </div>
  );
}

export default PermissionList;
