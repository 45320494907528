import InputLabel from "../../../components/inputs/InputLabel";
import MultiSelect from "../../../components/inputs/MultiSelect";
import { useEffect, useState } from "react";
import {
  getAllEmployee,
  getAllEmployeesOption,
} from "../../../services/EmployeeServices";
import { ErrorMessage, Form, Formik } from "formik";
import {
  storeLeadConditions,
  updateLeadConditions,
} from "../../../services/LeadService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function ConditionAssignmentForm({ formData, conditions, setAssingedTo }) {
  const navigate = useNavigate();

  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    getAllEmployeesOption().then((res) => {
      setEmployees(res.data.data.data);
    });
  }, []);

  const saveCondition = (values) => {
    let data = {
      condition: conditions,
      employee_ids: values.selected_employees.map((employee) =>
        parseInt(employee)
      ),
    };

    storeLeadConditions(data)
      .then((res) => {
        toast.success(res.data.message);
        navigate(`/lead-conditions`);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const updateCondition = (values) => {
    let data = {
      condition: conditions,
      employee_ids: values.selected_employees.map((employee) =>
        parseInt(employee)
      ),
    };

    updateLeadConditions(formData?.id, data)
      .then((res) => {
        toast.success(res.data.message);
        navigate(`/lead-conditions`);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className="rounded-lg border p-4">
      <Formik
        initialValues={{
          selected_employees: formData?.employee_ids || [],
        }}
        // validationSchema={loginFormSchema}
        onSubmit={(values) => {
          // alert(JSON.stringify(values));
          formData ? updateCondition(values) : saveCondition(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div>
              <div className="relative my-4">
                <InputLabel label="Select Employees to Assign Condition" />
                <MultiSelect
                  options={
                    Array.isArray(employees)
                      ? employees?.map((employee) => ({
                          label: `${employee?.first_name} ${
                            employee?.last_name
                          } - ${
                            employee?.official_contact?.email ??
                            "No official Contact"
                          } | ${employee?.official_contact?.phone}`,
                          value: employee?.id,
                        }))
                      : []
                  }
                  onChange={(value) =>
                    setFieldValue("selected_employees", value)
                  }
                  value={values?.selected_employees}
                />
                <ErrorMessage name="selected_employees">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>
            <div className="mt-4 text-center">
              <button className="btn-primary" type="submit">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ConditionAssignmentForm;
