import { ErrorMessage, Field, useFormikContext } from "formik";
import moment from "moment";
import { MdClose } from "react-icons/md";
import InputLabel from "../../inputs/InputLabel";
import DateInput from "../../inputs/DateInput";
import { useState } from "react";
import { getAllLocationByCountryId } from "../../../services/LocationServices";
import { useEffect } from "react";
import SelectSearch from "../../inputs/SelectSearch";
import StaySelectInput from "../../inputs/StaySelectInput";
import RoomSelectInput from "../../inputs/RoomSelectInput";
import Modal from "react-modal";
import { getStayById } from "../../../services/StayServices";

function HotelForm({ index }) {
  const { values, setFieldValue } = useFormikContext();
  const [locations, setLocations] = useState([]);
  const [stayModal, setStayModal] = useState(false);
  const [roomTypes, setRoomTypes] = useState([]);
  const [selectedRoomId, setSelectedRoomId] = useState();
  const [paxPerRoom, setPaxPerRoom] = useState(0);

  const getLocationOptions = () => {
    getAllLocationByCountryId(values.country_id).then((res) => {
      setLocations(res.data.data);
    });
  };

  useEffect(() => {
    if (!values?.itinerary_stays?.[index]?.stay_id) return;
    getStayById(values?.itinerary_stays?.[index]?.stay_id).then((res) => {
      setRoomTypes(res.data.data.room_type);
    });
  }, [values?.itinerary_stays?.[index]?.stay_id]);

  useEffect(() => {
    if (!values.country_id) return;
    getLocationOptions();
  }, [values.country_id]);

  const updateLocationOptions = () => {
    getLocationOptions();
  };

  const removeStay = (index) => {
    let stays = values.itinerary_stays;

    stays[index - 1].check_out_date =
      values.itinerary_stays[index].check_out_date;
    stays[index - 1].no_of_nights = moment(
      values.itinerary_stays[index].check_out_date
    ).diff(values.itinerary_stays[index - 1].check_in_date, "days");

    stays.splice(index, 1);

    setFieldValue(`itinerary_stays`, stays);
  };

  const addNewStay = (stays, index) => {
    stays.push({
      check_in_date: stays[index].check_out_date,
      no_of_nights: moment(values.end_date).diff(
        stays[index].check_out_date,
        "days"
      ),
      check_out_date: values.end_date,
    });

    setFieldValue(`itinerary_stays`, stays);
  };

  const updateNextStayIndex = (stays, index) => {
    if (
      moment(stays[index].check_out_date).format("DD MM YYYY") ===
      moment(values.end_date).format(`DD MM YYYY`)
    ) {
      stays.length = index + 1;
    } else {
      stays[index + 1].check_in_date = stays[index].check_out_date;
      stays[index + 1].no_of_nights = moment(
        stays[index + 1].check_out_date
      ).diff(stays[index].check_out_date, "days");

      if (stays[index + 1].no_of_nights <= 0) stays.splice(index + 1, 1);
    }

    setFieldValue(`itinerary_stays`, stays);
  };

  const handleNightInput = (nights, index) => {
    let stays = values.itinerary_stays;

    let totalNights = 0;

    stays.forEach((stay, i) => {
      if (i === index) {
        totalNights += parseInt(nights);
      } else {
        totalNights += parseInt(stay.no_of_nights);
      }
    });

    if (totalNights > values.no_of_nights && index === stays.length - 1) return;

    if (index === stays.length - 1) {
      stays[index].no_of_nights = nights;
      stays[index].check_out_date = moment(stays[index].check_in_date)
        .add(nights, "days")
        .format("YYYY-MM-DD");
      addNewStay(stays, index);
    } else {
      stays[index].no_of_nights = nights;
      stays[index].check_out_date = moment(stays[index].check_in_date)
        .add(nights, "days")
        .format("YYYY-MM-DD");

      updateNextStayIndex(stays, index);
    }

    setFieldValue(`itinerary_stays`, stays);
  };

  const handleCheckOutDateInput = (checkOutDate, index) => {
    let stays = values.itinerary_stays;

    stays[index].check_out_date = checkOutDate;
    stays[index].no_of_nights = moment(checkOutDate).diff(
      stays[index].check_in_date,
      "days"
    );

    if (index === stays.length - 1) {
      addNewStay(stays, index);
      // setFieldValue(`itinerary_stays`, stays)
    } else {
      updateNextStayIndex(stays, index);
    }
  };

  const setNumberOfRooms = (rooms, index) => {
    setFieldValue(`itinerary_stays.${index}.no_of_rooms`, rooms);

    if (rooms > 2) {
      setStayModal(true);
    }

    if (!rooms) return;

    let stayRooms = [];

    for (let i = 0; i < parseInt(rooms); i++) {
      stayRooms.push({
        stay_room_id:
          values?.itinerary_stays?.[index]?.rooms?.[i]?.stay_room_id,
        adults: values?.itinerary_stays?.[index]?.rooms?.[i]?.adults,
        children: values?.itinerary_stays?.[index]?.rooms?.[i]?.children,
        infants: values?.itinerary_stays?.[index]?.rooms?.[i]?.infants,
      });
    }
    setFieldValue(`itinerary_stays.${index}.rooms`, stayRooms);
  };

  const autoFillRooms = () => {
    let stayRooms = [];
    let remainingPax = values.adults;
    for (let i = 0; i < Math.ceil(values.adults / paxPerRoom); i++) {
      stayRooms.push({
        stay_room_id: selectedRoomId,
        adults: remainingPax >= paxPerRoom ? paxPerRoom : remainingPax,
        children: 0,
        infants: 0,
      });
      remainingPax -= paxPerRoom;
    }

    setFieldValue(`itinerary_stays.${index}.rooms`, stayRooms);
    setFieldValue(`itinerary_stays.${index}.no_of_rooms`, stayRooms.length);
    setStayModal(false);
  };

  return (
    <>
      <div className="mb-8 rounded-lg border border-gray-500 bg-white p-4">
        <div className="relative mb-2 flex items-center justify-between border-b pb-2 font-bold">
          <h3>Stay {index + 1}</h3>
          {index !== 0 && (
            <button
              type="button"
              className=""
              onClick={() => removeStay(index)}
            >
              <MdClose />
            </button>
          )}
        </div>

        <div className="my-4 flex flex-wrap gap-4">
          <div className="grow ">
            <InputLabel label="Check-in Date" />
            <DateInput
              minDate={
                new Date(moment(new Date()).add(1, "days").format("YYYY-MM-DD"))
              }
              maxDate={new Date()}
              value={values.itinerary_stays[index].check_in_date}
              onDateChange={(value) =>
                setFieldValue(`itinerary_stays.${index}.check_in_date`, value)
              }
            />
          </div>
          <div className="">
            <InputLabel label="Nights" />
            <Field
              className="field max-w-[100px]"
              name={`itinerary_stays.${index}.no_of_nights`}
              type="number"
              min="1"
              onChange={(e) => {
                handleNightInput(e.target.value, index);
              }}
            />
          </div>
          <div className="grow ">
            <InputLabel label="Check-out Date" />
            <DateInput
              minDate={
                new Date(
                  moment(values.itinerary_stays[index].check_in_date)
                    .add(1, "days")
                    .format("YYYY-MM-DD")
                )
              }
              maxDate={new Date(moment(values.end_date).toISOString())}
              value={values.itinerary_stays[index].check_out_date}
              onDateChange={(value) => {
                handleCheckOutDateInput(value, index);
                // setNumberOfNights(value, index);
              }}
            />
          </div>
          <div className="grow">
            <InputLabel label="Location" />

            <SelectSearch
              id={`location-${index}`}
              options={locations.map((location) => ({
                value: location.id,
                label: `${location.name} ${
                  location?.state_name ? `- (${location?.state_name})` : ""
                }`,
              }))}
              value={values.itinerary_stays[index].location_id || ""}
              onChange={(value) => {
                setFieldValue(`itinerary_stays.${index}.location_id`, value);
                setFieldValue(`itinerary_stays.${index}.stay_id`, "");
              }}
            />
            <ErrorMessage name={`itinerary_stays.${index}.location_id`}>
              {(msg) => <div className="error-msg">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="grow">
            <InputLabel label="Stay" />
            <StaySelectInput
              locationId={values.itinerary_stays[index].location_id}
              value={values.itinerary_stays[index].stay_id}
              fieldKey={`itinerary_stays.${index}.stay_id`}
              updateLocation={updateLocationOptions}
            />
          </div>
          <div className="">
            <InputLabel label="No. of Rooms" />
            <Field
              className="field max-w-[100px]"
              name={`itinerary_stays.${index}.no_of_rooms`}
              type="number"
              min="1"
              value={values.itinerary_stays[index].no_of_rooms}
              onChange={(e) => {
                setNumberOfRooms(e.target.value, index);
              }}
            />
          </div>
        </div>

        {values.itinerary_stays[index].stay_id &&
          values.itinerary_stays[index].no_of_rooms && (
            <RoomSelectInput
              stayId={values.itinerary_stays[index].stay_id}
              index={index}
            />
          )}
      </div>

      <Modal
        isOpen={stayModal}
        onAfterClose={() => setStayModal(null)}
        contentLabel="Update Payment Reciept"
        className="absolute top-1/2 left-1/2 z-0 h-fit max-w-[90%] -translate-y-1/2 -translate-x-1/2 rounded-lg bg-white"
      >
        <div className="relative z-10 flex items-center justify-between p-4 px-8">
          <h2 className="text-xl font-bold">Auto-fill Stay</h2>
          <button
            onClick={() => setStayModal(false)}
            className="cursor-pointer p-2"
          >
            <MdClose />
          </button>
        </div>
        <div className="px-8 pb-8">
          <div className="flex gap-4">
            <div className="relative        ">
              <InputLabel label="Room Type" required={true} />
              {/* <SelectSearch
                options={roomTypes?.map((type) => ({
                  value: type.id,
                  label: type.name,
                }))}
                value={selectedRoomId}
                onChange={(value) => setSelectedRoomId(value)}
              /> */}
              <select
                className="field"
                onChange={(e) => setSelectedRoomId(e.target.value)}
                value={selectedRoomId}
              >
                <option value=""></option>
                {roomTypes?.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="">
              <InputLabel label="Pax per Room" required={true} />
              <input
                type="number"
                name=""
                id=""
                className="field"
                value={paxPerRoom}
                onChange={(e) => setPaxPerRoom(e.target.value)}
              />
            </div>
          </div>

          <div className="mt-4 text-center">
            <button
              type="button"
              onClick={autoFillRooms}
              className="btn-primary"
            >
              Auto Fill
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default HotelForm;
