import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import $ from "jquery";
import DataTable from "../components/common/DataTable";
import { getAdminUrl } from "../../ServiceConfig";
import ComponentHeader from "../components/common/ComponentHeader";
import H2 from "../../components/headings/H2";
import { FaPlusSquare } from "react-icons/fa";
import { getReadableDate } from "../../helpers/Helper";
import ToggleInput from "../components/inputs/ToggleInput";
import { toast } from "react-toastify";
import { checkPermission } from "../services/AdminServices";
import NotAuthorized403 from "../components/NotAuthorized403";
import moment from "moment";

function DuePaymentBookingList() {
  const navigate = useNavigate();
  const bookingTable = useRef();
  const [searchParams, setSearchParams] = useSearchParams();

  const [eventdate, setEventdate] = useState(moment().format("YYYY-MM-DD"));

  console.log(eventdate, "eventdate");

  const [selectedFilter, setSelectedFilter] = useState([]);
  const [onlyCustomized, setOnlyCustomized] = useState(false);
  const [authorized, setAuthorized] = useState(true);

  useEffect(() => {
    checkPermission("Booking.read")
      .then((res) => {
        setAuthorized(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }, []);

  const updateFilter = (filter) => {
    let index = selectedFilter.findIndex((sf) => sf.field === filter.field);

    if (index >= 0) {
      if (filter.value && !Array.isArray(filter.value)) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else if (Array.isArray(filter.value) && filter.value[0]) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else {
        setSelectedFilter(
          selectedFilter.filter((af) => af.field !== filter.field)
        );
      }
    } else {
      setSelectedFilter([...selectedFilter, filter]);
    }
  };

  const isFrozen = window.innerWidth <= 768 ? false : true;

  useEffect(() => {
    updateFilter({
      field: "is_customized",
      type: "=",
      value: onlyCustomized ? 1 : "",
    });
    updateFilter({
      field: "event_date",
      type: "=",
      value: eventdate,
    });
  }, [onlyCustomized, eventdate]);

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `<div className="booking-view-btn" data-id=${
      cell.getRow().getData().id
    } style="display: flex; align-items: center; gap: 12px;">
    <div style="cursor: pointer; title="Edit">
    <svg width="30" height="30">
<path d="M12.406 13.844c1.188 0 2.156 0.969 2.156 2.156s-0.969 2.125-2.156 2.125-2.125-0.938-2.125-2.125 0.938-2.156 2.125-2.156zM12.406 8.531c7.063 0 12.156 6.625 12.156 6.625 0.344 0.438 0.344 1.219 0 1.656 0 0-5.094 6.625-12.156 6.625s-12.156-6.625-12.156-6.625c-0.344-0.438-0.344-1.219 0-1.656 0 0 5.094-6.625 12.156-6.625zM12.406 21.344c2.938 0 5.344-2.406 5.344-5.344s-2.406-5.344-5.344-5.344-5.344 2.406-5.344 5.344 2.406 5.344 5.344 5.344z"></path>
</svg></div>
   `;
  };

  const onRowClick = (data) => {
    // navigate(`/bookings/${data.id}`);
    window.open(`/bookings/${data.id}`, "_blank");
  };

  const columns = [
    // {
    //   formatter: "",
    //   width: 30,
    //   minWidth: 30,
    //   responsive: 0,
    //   hozAlign: "center",
    //   resizable: false,
    //   headerSort: false,
    // },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
      minWidth: "80px",
      frozen: isFrozen,
    },
    {
      title: "Payment Ids",
      field: "payment_ids",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
      minWidth: "100px",
    },
    {
      title: "Package",
      field: "package_name",
      minWidth: "250",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      formatter: function (cell, formatterParams, onRendered) {
        return `${cell.getRow().getData().package_name}<br/> ${getReadableDate(
          cell.getRow().getData().event_date
        )} <br/> ${cell.getRow().getData().total_pax} Members`;
      },
    },
    // {
    //   title: "Event Date",
    //   minWidth: "100",
    //   field: "event_date",
    //   resizable: true,
    //   vertAlign: "middle",
    //   formatter: function (cell, formatterParams, onRendered) {
    //     return getReadableDate(cell.getValue());
    //   },
    // },
    {
      title: "User Detail",
      minWidth: "250",
      field: "first_name",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return `${cell.getRow().getData().first_name} ${
          cell.getRow().getData().last_name
        } <br/> ${cell.getRow().getData().email} <br/> ${
          cell.getRow().getData().phone
        }`;
      },
    },
    // {
    //   title: "Total Members",
    //   field: "total_pax",
    //   resizable: true,
    //   vertAlign: "middle",
    // },
    {
      title: "Total Cost",
      field: "total_cost",
      resizable: true,
      vertAlign: "middle",
      minWidth: "120px",
    },
    {
      title: "Total Discount",
      field: "total_discount",
      resizable: true,
      vertAlign: "middle",
      minWidth: "120px",
    },
    {
      title: "After Discount",
      field: "total_cost_after_discount",
      resizable: true,
      vertAlign: "middle",
      minWidth: "120px",
    },
    {
      title: "Total GST",
      field: "total_gst",
      resizable: true,
      vertAlign: "middle",
      minWidth: "120px",
    },
    {
      title: "TCS",
      field: "total_tcs",
      resizable: true,
      vertAlign: "middle",
      minWidth: "80px",
    },
    {
      title: "Total Amount to Pay",
      field: "total_amount_to_pay",
      resizable: true,
      vertAlign: "middle",
      minWidth: "180px",
    },
    {
      title: "Amount Paid",
      field: "total_amount_paid",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      minWidth: "120px",
    },
    {
      title: "Amount Remaining",
      field: "remaining_amount",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      minWidth: "120px",
      frozen: isFrozen,
    },
    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
      frozen: isFrozen,
    },
  ];

  const rowFormatter = (row) => {
    if (row.getData().is_customized === 1) {
      const children = row.getElement().childNodes;
      children.forEach((child) => {
        child.style.color = "#3876BF";
      });
    }
    if (row.getData().booking_status === 0) {
      const children = row.getElement().childNodes;
      children.forEach((child) => {
        child.style.color = "#9E6F21";
      });
    }
    if (row.getData().cancellation_type == 1) {
      const children = row.getElement().childNodes;
      children.forEach((child) => {
        child.style.color = "#D83F31";
      });
    }
  };

  useEffect(() => {
    console.log("running effect");
    bookingTable.current.setFilter(selectedFilter);

    $(document).on("click", ".booking-view-btn", function (e) {
      console.log("clicked view");
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/bookings/${cell}`);
    });

    return () => {
      $(document).off("click", ".booking-view-btn");
    };
  }, [selectedFilter]);

  return !authorized ? (
    <NotAuthorized403 />
  ) : (
    <div className="flex flex-col">
      <ComponentHeader className="border-b">
        <div className="flex flex-wrap items-center gap-4">
          <H2>Due Payment Bookings</H2>
          {/* <div className="hidden items-center gap-1 lg:flex">
            <ToggleInput
              checked={onlyCustomized}
              onToggle={(e) => setOnlyCustomized(e.target.checked)}
            />
            <span className="font-medium">Only Customized</span>
          </div> */}

          <div className="border p-2 rounded-lg font-bold border-gray-400">
            <label> Event Date : </label>
            <input
            className="dark:bg-slate-900 dark:text-white"
              type="date"
              onChange={(e) => {
                setEventdate(e.target.value);
                updateFilter({
                  field: "event_date",
                  type: "=",
                  value: e.target.value,
                });
              }}
              value={eventdate}
            />
          </div>
        </div>
      </ComponentHeader>
      {/* <div className="my-2 flex items-center gap-1 lg:hidden">
        <ToggleInput
          checked={onlyCustomized}
          onToggle={(e) => setOnlyCustomized(e.target.checked)}
        />
        <span className="font-medium">Only Customized</span>
      </div> */}

      <div className="my-2 flex items-center gap-4">
        <div className="flex items-center">
          <div className="h-2 w-2 rounded-full bg-[#D83F31]"></div>
          <p className="rounded-full px-1 text-[0.625rem] font-medium leading-3 text-[#D83F31]">
            Cancelled
          </p>
        </div>
        <div className="flex items-center">
          <div className="h-2 w-2 rounded-full bg-[#3876BF]"></div>
          <p className="rounded-full px-1 text-[0.625rem] font-medium leading-3 text-[#3876BF]">
            Customized Boking
          </p>
        </div>
        <div className="flex items-center">
          <div className="h-2 w-2 rounded-full bg-[#9E6F21]"></div>
          <p className="rounded-full px-1 text-[0.625rem] font-medium leading-3 text-[#9E6F21]">
            Pending
          </p>
        </div>
      </div>
      <div className="h-full">
        <DataTable
          ref={bookingTable}
          ajaxUrl={`${getAdminUrl()}/due-payment-bookings`}
          columns={columns}
          search={false}
          perPage={25}
          // responsiveLayout=""
          rowClick={onRowClick}
          rowFormatter={rowFormatter}
          initialSort={[{ column: "id", dir: "desc" }]}
        />
      </div>
    </div>
  );
}

export default DuePaymentBookingList;
