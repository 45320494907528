import { Outlet } from "react-router-dom";

function UserLayout() {
  return (
    <div className="h-full min-h-[94vh] rounded-lg bg-white px-4 text-gray-700 dark:bg-slate-800 dark:text-white">
      <div className="h-full max-h-[calc(100%-1rem)] overflow-auto">
        {<Outlet />}
      </div>
    </div>
  );
}

export default UserLayout;
