import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CategoryForm from "./CategoryForm";
// import MetaTags from "../../../web/layouts/MetaTags";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";

function AddCategory() {
  const navigate = useNavigate();

  const onAddCategory = (data) => {
    navigate(`/category`);
  };

  return (
    <div className="">
      {/* <MetaTags title="Add Category | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Add Category</H2>
        <Link
          to="/category"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      <CategoryForm onSuccess={(data) => onAddCategory(data)} />
    </div>
  );
}

export default AddCategory;
