import { Link, useNavigate } from "react-router-dom";

import ComponentHeader from "../components/common/ComponentHeader";
import H2 from "../../components/headings/H2";
import { FaListAlt } from "react-icons/fa";
import ToggleInput from "../components/inputs/ToggleInput";
import { useEffect, useState } from "react";
import {
  createCustomBooking,
  initiateBooking,
} from "../services/BookingServices";
import { toast } from "react-toastify";
import { checkPermission } from "../services/AdminServices";
import NotAuthorized403 from "../components/NotAuthorized403";
import BookingForm from "./BookingForm/BookingForm";

function AddBooking() {
  const navigate = useNavigate();

  const [packageName, setPackageName] = useState();
  const [isCustomized, setIsCustomized] = useState(0);
  const [authorized, setAuthorized] = useState(true);

  useEffect(() => {
    checkPermission("Booking.create")
      .then((res) => {
        setAuthorized(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }, []);

  const onSubmit = (values, setSubmitting) => {
    let total_pax = 0;
    let total_cost = 0;
    let total_discount = 0;
    let total_cost_after_discount = 0;
    let total_gst = 0;

    values.cost.forEach((cost) => {
      total_pax += parseInt(cost.pax);
      total_cost += cost.package_price * parseInt(cost.pax);
      total_discount += cost.package_discount * parseInt(cost.pax);

      total_gst += cost.package_gst * parseInt(cost.pax);
      cost.package_itinerary_id = values.itinerary_id;
    });
    values.addons.forEach((addon, index) => {
      if (!addon.amount || addon.amount === 0) {
        values.addons.splice(index, 1);
      } else {
        total_cost += parseFloat(addon.unit_price) * parseFloat(addon.unit);
        total_gst += parseFloat(addon.unit_gst) * parseFloat(addon.unit);
        total_cost_after_discount +=
          parseFloat(addon.unit_price) * parseFloat(addon.unit);
      }
    });

    total_cost_after_discount = total_cost - total_discount;

    values.total_pax = total_pax;
    values.total_cost = total_cost;
    values.total_discount = total_discount;
    values.total_cost_after_discount = total_cost_after_discount;
    values.total_amount =
      total_gst + total_cost_after_discount + values.total_tcs;
    values.total_gst = total_gst;
    values.booking_status = 1;
    values.payment.amount = values.total_amount_paid;
    values.payment.payment_type =
      values.remaining_amount > 0 ? "partial amount" : "full amount";
    values.package_name = values.package_name || packageName;

    values.other_services = values.other_services.filter(
      (obj) => Object.keys(obj).length !== 0
    );

    if (isCustomized) {
      values.is_customized = true;
      createCustomBooking({ booking: values })
        .then((res) => {
          toast.success(res.data.message);
          navigate(`/bookings`);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      initiateBooking({
        booking: values,
        secret_code: values.secret_code,
      })
        .then((res) => {
          navigate("/bookings");
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return !authorized ? (
    <NotAuthorized403 />
  ) : (
    <div className="">
      <ComponentHeader className="mb-4 border-b">
        <div className="flex flex-wrap items-center gap-4">
          <H2>Add Booking</H2>
          <div className="hidden items-center gap-1 lg:flex">
            <ToggleInput
              checked={isCustomized}
              onToggle={(e) => setIsCustomized(e.target.checked ? 1 : 0)}
            />
            <span className="font-medium">Customized Booking</span>
          </div>
        </div>
        <Link
          to="/bookings"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      <div className="mb-4 w-full justify-end flex self-end items-center gap-1 lg:hidden">
        <ToggleInput
          checked={isCustomized}
          onToggle={(e) => setIsCustomized(e.target.checked ? 1 : 0)}
        />
        <span className="font-medium">Customized Booking</span>
      </div>

      <BookingForm
        setPackageName={setPackageName}
        onSubmit={onSubmit}
        isCustomized={isCustomized}
      />
    </div>
  );
}

export default AddBooking;
