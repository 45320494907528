import { createContext, useEffect, useState } from "react";

const ScreenSizeContext = createContext();

export const ScreenSizeProvider = ({ children }) => {

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [screenSize, setScreenSize] = useState(getWindowSize());
  const [breakpoint, setBreakpoint] = useState()

  useEffect(() => {
    function handleWindowResize() {
        setScreenSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    let innerWidth = screenSize.innerWidth
    if (innerWidth <= 640) {
        setBreakpoint('sm')
    }else if (innerWidth <=768) {
        setBreakpoint('md')
    }else if (innerWidth <= 1024) {
        setBreakpoint('lg')
    }else if (innerWidth <= 1280) {
        setBreakpoint('xl')
    }else if (innerWidth <= 1536 || innerWidth > 1536){
        setBreakpoint('2xl')
    }
  }, [screenSize])
  

  return (
    <ScreenSizeContext.Provider value={{screenSize, breakpoint}}>
      {children}
    </ScreenSizeContext.Provider>
  );
};

export default ScreenSizeContext;
