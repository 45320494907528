import { admin } from "../../ServiceConfig";

export function createNewsletter(data) {
  return admin.post(`/newsletter`, data);
}

export function updateNewsletter(id, data) {
  return admin.put(`/newsletter/${id}`, data);
}

export function deleteNewsletter(id) {
  return admin.delete(`/newsletter/${id}`);
}

export function getNewsletterById(id) {
  return admin.get(`/newsletter/${id}`);
}

export function uploadNewsletterImage(file) {
  return admin.post(`/uploads/image?upload_path=newsletter`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
