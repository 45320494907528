import React, { useEffect, useRef, useState } from "react";
import { leadvsBooking } from "../services/LeadService";
import BarChartLeadVsBooking from "./BarChartLeadVsBooking";
import DateRangeInput from "../components/inputs/DateRangeInput";
import moment from "moment";

const LeadVsBooking = ({ dateRange }) => {
  const [data, setData] = useState();

  useEffect(() => {
    leadvsBooking({
      start_date: moment(dateRange.start_date).format("YYYY-MM-DD"),
      end_date: moment(dateRange.end_date).format("YYYY-MM-DD"),
    })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [dateRange]);

  return (
    <div className="h-full">
      <div className="flex h-full flex-col rounded-lg border p-2">
        <div className="flex items-center justify-between border-b pb-2">
          <h3 className="font-bold">Lead count Per day</h3>
        </div>
        <div className="flex h-full items-center justify-center py-4">
          {data && <BarChartLeadVsBooking chartData={data} />}
        </div>
      </div>
    </div>
  );
};

export default LeadVsBooking;
