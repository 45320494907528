
import { ErrorMessage, Field, Form, Formik } from "formik";
import { createInternalNotification, updateInternalNotificationById, uploadInternalNotificationImage } from "../../services/InternalNotificationsServices";
import InputLabel from "../inputs/InputLabel";
import * as Yup from "yup";
import { toast } from "react-toastify";
import FileInput from "../../../components/inputFields/FileInput";
import { getUploadsUrl } from "../../../ServiceConfig";
import SelectSearch from "../inputs/SelectSearch";
import ToggleInput from "../inputs/ToggleInput";
import DateRangeInput from "../inputs/DateRangeInput";
import { useRef } from "react";

const notificationSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    image: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    priority: Yup.string().required("Required"),
    link: Yup.string(),
    status: Yup.boolean().required("Required"),
    start_date: Yup.date().required("Required"),
    end_date: Yup.date().required("Required"),
});

function InternalNotificationForm({ formData, edit, onSuccess }) {

    const dateInputRef = useRef(null)
    // ? Add new B2B
    const addNewInternalNotification = (values) => {
        createInternalNotification(values)
            .then((res) => {
                toast.success(res.data.data.message);
                onSuccess(res.data.data);
            })
            .catch((err) => {
                toast.error("error in adding new notification");
            });
    };

    // ? Update State
    const editInternalNotification = (values) => {
        let data = Object.assign({}, values);
        updateInternalNotificationById(formData.id, data)
            .then((res) => {
                toast.success(res.data.message);
                onSuccess(res.data.data);
            })
            .catch((err) => {
                console.log(err)
                toast.error(err.response.data.message);
            });
    };

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    title: formData?.title ? formData.title : "",
                    image: formData?.image ? formData.image : "",
                    description: formData?.description ? formData.description : "",
                    priority: formData?.priority ? formData.priority : "",
                    link: formData?.link
                        ? formData.link
                        : "",
                    status: formData?.status
                        ? formData.status
                        : "",
                    start_date: formData?.start_date ? formData?.start_date : "",
                    end_date: formData?.end_date ? formData?.end_date : "",
                }}
                validationSchema={notificationSchema}
                onSubmit={(values) => {
                    edit ? editInternalNotification(values) : addNewInternalNotification(values);
                }}
            >
                {({ values, setFieldValue, errors, touched }) => (
                    <Form>
                        <div className="flex flex-wrap gap-4 lg:flex-nowrap">
                            <div className="my-4 grow">
                                <div className="flex flex-wrap gap-4">
                                    {/* //? Title */}
                                    <div className="grow basis-full lg:max-w-[40%]">
                                        <InputLabel label="Title" labelFor="title" required={true} />
                                        <Field
                                            className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                                            id="title"
                                            name="title"
                                        />
                                        <ErrorMessage name="title">
                                            {(msg) => <div className="error-msg">{msg}</div>}
                                        </ErrorMessage>
                                    </div>

                                    {/* link */}
                                    <div className="grow basis-full lg:max-w-[40%]">
                                        <InputLabel label="Link" labelFor="link" />
                                        <Field
                                            className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                                            id="link"
                                            name="link"
                                        />
                                        <ErrorMessage name="link">
                                            {(msg) => <div className="error-msg">{msg}</div>}
                                        </ErrorMessage>
                                    </div>

                                    {/* Priority */}
                                    <div className="grow basis-[60%] lg:max-w-[10%] z-20">
                                        <InputLabel label="Priority" labelFor="priority" required={true} />
                                        <SelectSearch
                                            value={values.priority}
                                            options={
                                                [{ label: 'Low', value: 'low' }, { label: 'Medium', value: 'medium' }, { label: 'High', value: 'high' }]
                                            }
                                            onChange={(value) => setFieldValue("priority", value)}
                                        />
                                        <ErrorMessage name="priority">
                                            {(msg) => <div className="error-msg">{msg}</div>}
                                        </ErrorMessage>
                                    </div>
                                    {/* Status */}
                                    <div className="grow basis-[25%] lg:max-w-[5%]">
                                        <InputLabel label="Status" labelFor="status" required={true} />
                                        <ToggleInput
                                            checked={values.status}
                                            onToggle={(e) =>
                                                setFieldValue('status', e.target.checked)
                                            }
                                        />
                                        <ErrorMessage name="status">
                                            {(msg) => <div className="error-msg">{msg}</div>}
                                        </ErrorMessage>
                                    </div>


                                    {/* Date Range */}
                                    <div className="grow basis-full lg:max-w-[30%]">
                                        <InputLabel label="Valid Duration" labelFor="valid_duration" required={true} />
                                        <DateRangeInput
                                            ref={dateInputRef}
                                            onRangeChange={(startDate, endDate) => {
                                                setFieldValue(
                                                    `start_date`,
                                                    `${new Date(
                                                        startDate
                                                    ).getFullYear()}-${new Date(startDate).getMonth() + 1
                                                    }-${new Date(startDate).getDate()}`
                                                );
                                                setFieldValue(
                                                    `end_date`,
                                                    `${new Date(endDate).getFullYear()}-${new Date(endDate).getMonth() + 1
                                                    }-${new Date(endDate).getDate()}`
                                                );
                                            }}
                                        />
                                        <ErrorMessage name="start_date">
                                            {(msg) => <div className="error-msg">{msg}</div>}
                                        </ErrorMessage>
                                    </div>


                                    {/* Description */}
                                    <div className="grow basis-full lg:max-w-[30%]">
                                        <InputLabel label="Description" labelFor="description" required={true} />
                                        <Field
                                            as="textarea"
                                            className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                                            id="description"
                                            name="description"
                                        />
                                        <ErrorMessage name="description">
                                            {(msg) => <div className="error-msg">{msg}</div>}
                                        </ErrorMessage>
                                    </div>

                                    {/* Image */}
                                    <div className="grow basis-full lg:max-w-[30%]">
                                        <InputLabel label="Image" labelFor="image" required={true} />
                                        <FileInput
                                            value={values.image && `${getUploadsUrl()}/internal-notification/${values.image
                                                }`}
                                            noOfFilesAllowed={1}
                                            onChange={(file) => {
                                                uploadInternalNotificationImage({ image: file }).then(
                                                    (res) => {
                                                        setFieldValue(
                                                            `image`,
                                                            res.data.data[0].uploaded_image
                                                        );
                                                    }
                                                );
                                            }}
                                        />
                                        <ErrorMessage name="image">
                                            {(msg) => <div className="error-msg">{msg}</div>}
                                        </ErrorMessage>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 text-center">
                            <button type="submit" className="btn-primary dark:bg-green-700">
                                Submit
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default InternalNotificationForm;
