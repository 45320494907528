import { formatNumberToTwoDigit } from "../../../helpers/Helper";

function NumberIncrementDecrementInput({ updateCounter, value = 0 }) {
  const decreaseCounter = () => {
    if (value === 0) return;
    // setCount(count - 1);
    updateCounter(value - 1);
  };

  const increaseCounter = () => {
    if (value === 999) return;
    updateCounter(value + 1);
  };

  return (
    <div className="flex items-center">
      <button
        type="button"
        onClick={decreaseCounter}
        className="rounded-l-sm border px-2 py-1"
      >
        -
      </button>
      {/* <div className="grow border px-2 py-1">
        {formatNumberToTwoDigit(value)}
      </div> */}
      <input
        type="text"
        name=""
        id=""
        className="max-w-[40px] border py-1 text-center focus:outline-none"
        value={formatNumberToTwoDigit(parseInt(value))}
        onChange={(e) => updateCounter(parseInt(e.target.value))}
      />
      <button
        type="button"
        onClick={increaseCounter}
        className="rounded-r-sm border px-2 py-1"
      >
        +
      </button>
    </div>
  );
}

export default NumberIncrementDecrementInput;
