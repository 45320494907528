import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

function useOutsideClick(ref, fn) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                fn();
            }
        }
        // ? Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            //? Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

function OutsideClick({ onClick, children }) {
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, onClick);

    return <div ref={wrapperRef}>{children}</div>;
}

OutsideClick.propTypes = {
    children: PropTypes.element.isRequired,
};

export default OutsideClick;
