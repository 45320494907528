import { Link, useParams } from "react-router-dom";
import H2 from "../../../components/headings/H2";
import ComponentHeader from "../../components/common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import ConditionFormWrapper from "./conditionForm/ConditionFormWrapper";
import { useState } from "react";
import { useEffect } from "react";
import { getLeadConditionById } from "../../services/LeadService";

function EditCondition() {
  const { id } = useParams();

  const [condition, setCondition] = useState();

  useEffect(() => {
    getLeadConditionById(id)
      .then((res) => {
        setCondition(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <ComponentHeader className="border-b">
        <H2>Edit Condition</H2>
        <Link
          to="/lead-conditions"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      <div>
        <ConditionFormWrapper formData={condition} />
      </div>
    </div>
  );
}

export default EditCondition;
