import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { generateTrekCertificates } from '../../services/PackageService';
import { FaEdit, FaEye, FaRegSadCry, FaSave } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { updateParticipantsById } from '../../services/ParticipantsService';
import { getUploadsUrl } from '../../../ServiceConfig';
import { areArraysEqual } from '../../../helpers/Helper';

const ParticipantRow = ({ participant, setSelectedParticipants, isSelected, onSuccess, setDisableCreateButton }) => {
    const { participant_id, first_name, last_name, email } = participant;
    const [editting, setEditting] = useState(false)

    const [firstName, setFirstName] = useState(first_name)
    const [lastName, setLastName] = useState(last_name)
    const [emailId, setEmailId] = useState(email)
    const handleCheck = (e) => {
        if (e.target.checked) {
            setSelectedParticipants(prevselectedParticipants => [
                ...prevselectedParticipants,
                participant
            ]);
        } else {
            setSelectedParticipants(prevselectedParticipants =>
                prevselectedParticipants.filter(
                    (selectedParticipant) => selectedParticipant.participant_id !== participant.participant_id
                )
            );
        }
    }

    const handleUpdateParticipant = () => {
        setDisableCreateButton(true)
        const participants = { participants: [{ id: participant.participant_id, first_name: firstName, last_name: lastName, email: emailId, booking_id: participant.booking_id }] }
        updateParticipantsById(participant.booking_id, participants).then(res => {
            toast.success("updated participant successfully")
            onSuccess()
        }).catch((er) => {
            console.log(er)
            toast.error("error in updating participant")
        }).finally(() => {
            setDisableCreateButton(false)
        })
    }
    return (
        <tr className="lg:text-md text-sm border-b hover:bg-gray-100">
            <td className="p-4"><input type="checkbox" checked={isSelected} onChange={handleCheck} className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" /></td>
            <td className="p-4">{participant_id}</td>
            <td className="p-4">{editting ? <input
                type="text"
                value={firstName}
                onChange={(e) => { setFirstName(e.target.value) }}
                className="block w-full p-3 text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300 ease-in-out"
            /> : firstName}</td>

            <td className="p-4">{editting ? <input
                type="text"
                value={lastName}
                onChange={(e) => { setLastName(e.target.value) }}
                className="block w-full p-3 text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300 ease-in-out"
            /> : lastName}</td>

            <td className="p-4">{editting ? <input
                type="email"
                value={emailId}
                onChange={(e) => { setEmailId(e.target.value) }}
                className="block w-full p-3 text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300 ease-in-out"
            /> : emailId}</td>
            <td className="p-4">
                {!editting ? <button
                    onClick={() => {
                        setEditting(true)
                    }}
                    className="flex items-center space-x-2 bg-[##2680bd] text-white font-semibold py-2 px-4 rounded-lg shadow-md transition-all duration-300 ease-in-out transform bg-[#2680bd]"
                >
                    <FaEdit className="w-5 h-5" />
                    <span>Edit</span>
                </button> : <button
                    onClick={() => {
                        setEditting(false)
                        handleUpdateParticipant()
                    }}
                    className="flex items-center space-x-2 bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition-all duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                >
                    <FaSave className="w-5 h-5" />
                    <span>Save</span>
                </button>}
            </td>
            <td className="p-4"> {participant?.id && <a href={`${getUploadsUrl()}/trek-certificates/generated/certificate-${participant?.id}.pdf`} target='_blank'
                className="flex items-center space-x-2 bg-[#2680bd] text-white font-semibold py-2 px-4 rounded-lg shadow-md transition-all duration-300 ease-in-out transform"
            >
                <FaEye className="w-5 h-5" />
                <span>Preview</span>
            </a>}</td>
        </tr>
    );
};

const TableofParticipants = ({ participants, onSuccess }) => {
    const [selectedParticipants, setSelectedParticipants] = useState();
    const [disableCreateButton, setDisableCreateButton] = useState(false)

    const formatDate = (date) => {
        return moment(date, 'YYYY-MM-DD').format('DD MMMM YYYY');
    };

    useEffect(() => {
        if (participants) {
            setSelectedParticipants(participants)
        }
    }, [participants])

    useEffect(() => {
        setDisableCreateButton(selectedParticipants?.length < 1)
    }, [selectedParticipants])

    const handleCreateCertificates = () => {
        setDisableCreateButton(true)
        if (selectedParticipants.length > 0) {
            const hasEmptyFirstName = selectedParticipants?.some(ptc => ptc.first_name === null);
            const hasEmptyEmail = selectedParticipants?.some(ptc => ptc.email === null);
            if (hasEmptyFirstName) {
                toast.error("First Name is required for all Selected Participants");
                setDisableCreateButton(false)
                return;
            }
            if (hasEmptyEmail) {
                toast.error("Email is required for all Selected Participants");
                setDisableCreateButton(false)
                return;
            }
            else {
                generateTrekCertificates({
                    "certificates": selectedParticipants
                }).then((res) => {
                    toast.success(res.data.message)
                    onSuccess()
                }).catch(er => {
                    console.log(er)
                    toast.error("Error in generating certificate")
                }).finally(() => {
                    setDisableCreateButton(false)
                })
            }
        }
    }

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedParticipants(participants)
        } else {
            setSelectedParticipants([])
        }
    }
    return (
        <>
      {participants.length > 0 ?  <div className="flex flex-col items-center justify-center mt-[4rem]">
            {participants.length > 0 && <h2 className="lg:text-xl text-lg font-bold mb-4 text-center">All Participants ({participants[0].package_name} -  {formatDate(participants[0].starting_date)} - {formatDate(participants[0].ending_date)})  |  Altitude - {participants[0].altitude}ft. |  {participants[0].state}</h2>}
            <div className="overflow-x-auto w-full">
                <table className="min-w-full bg-white border border-gray-200">
                    <thead className="bg-gray-200 lg:text-lg text-md">
                        <tr>
                            <th className="p-4 text-left">  <input type="checkbox" checked={areArraysEqual(participants, selectedParticipants)} onChange={handleSelectAll} className="w-5 mr-2 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" /> Select All</th>
                            <th className="p-4 text-left">Participant ID</th>
                            <th className="p-4 text-left">First Name</th>
                            <th className="p-4 text-left">Last Name</th>
                            <th className="p-4 text-left">Email</th>
                            <th className="p-4 text-left">Edit Details</th>
                            <th className="p-4 text-left">Preview</th>
                        </tr>
                    </thead>
                    <tbody>
                        {participants.map((participant) => (
                            <ParticipantRow onSuccess={onSuccess} key={participant.participant_id} participant={participant} setSelectedParticipants={setSelectedParticipants} isSelected={selectedParticipants?.some((participantItem) => participantItem.participant_id === participant.participant_id)} setDisableCreateButton={setDisableCreateButton} />
                        ))}
                    </tbody>
                </table>
            </div>
            <button
                onClick={handleCreateCertificates}
                disabled={disableCreateButton}
                className="bg-[#2680bd] text-white font-semibold py-2 px-4 rounded-lg shadow-md transition-all duration-300 ease-in-out transform mt-6 disabled:bg-sky-300"
            >
                Create Certificate for Selected Participants
            </button>
        </div> : <div>
            <h2 className='m-8 text-xl font-bold flex justify-center items-center gap-8'><FaRegSadCry size={40}/>Sorry, No Participants to show for this Trek!</h2></div>}
        </>
    );
};

export default TableofParticipants;
// trek-certificates/generated/certificate
