import { admin } from "../../ServiceConfig";

export function getAllModules(query) {
    return admin.get(`/modules?query=${query}`)
}
export function getAllModulesOption() {
    return admin.get(`/allModulesOption`)
}
export function createModule(data) {
    return admin.post(`/modules`, data)
}
export function updateModule(id, data) {
    return admin.put(`/modules/${id}`, data)
}
export function deleteModule(id) {
    return admin.delete(`/modules/${id}`)
}
export function getModuleById(id) {
    return admin.get(`/modules/${id}`)
}

