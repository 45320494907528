import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputLabel from "../components/inputs/InputLabel";
import { getBookingById, payBalanceAmount } from "../services/BookingServices";
import * as Yup from "yup";

const paymentFormSchema = Yup.object().shape({
  total_amount_to_pay: Yup.string().required("Required"),
  total_amount_paid: Yup.string().required("Required"),
  amount: Yup.string().required("Required"),
  remaining_amount: Yup.string().required("Required"),
  payment_method: Yup.string().required("Required"),
  transaction_id: Yup.string().required("Required"),
});

function AddPayments() {
  const params = useParams();
  const bookingId = params.bookingId;
  const navigate = useNavigate();

  const [bookingData, setBookingData] = useState();

  useEffect(() => {
    getBookingById(bookingId).then((res) => {
      setBookingData(res.data.data);
    });
  }, []);

  const AddPayments = (values) => {
    values.total_amount_paid += parseInt(values.amount);
    payBalanceAmount(bookingData.id, values).then((res) => {
      navigate(`/bookings/${bookingData.id}`);
    });
  };

  return (
    <div className="p-4">
      <h2 className="border-b-2 pb-4 text-xl font-bold">
        Add balance Payment for Booking #{bookingId}
      </h2>

      <div className="my-4 rounded-lg border-2 p-4">
        <h2 className="border-b pb-4 font-bold">Overall Cost Calculation</h2>

        <Formik
          enableReinitialize
          initialValues={{
            total_amount_to_pay: bookingData?.total_amount_to_pay,
            total_amount_paid: bookingData?.total_amount_paid,
            amount_deducted: bookingData?.amount_deducted,
            credit_note: bookingData?.credit_note,
            refund_amount: bookingData?.refund_amount,
            amount: "",
            remaining_amount: bookingData?.remaining_amount,
            payment_method: "",
            transaction_id: "",
            payment_type: "partial amount",
            payment_source: "admin",
          }}
          validationSchema={paymentFormSchema}
          onSubmit={(values) => {
            AddPayments(values);
          }}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              {/* //? Cost Details Form */}
              <div className="my-4 flex flex-wrap gap-4">
                <div className="">
                  <InputLabel label="Total Amount" />
                  <Field
                    name="total_amount_to_pay"
                    className="field"
                    disabled
                  />
                  <ErrorMessage name={`total_amount_to_pay`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="">
                  <InputLabel label="Amount Already Paid" />
                  <Field name="total_amount_paid" className="field" disabled />
                  <ErrorMessage name={`total_amount_paid`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="">
                  <InputLabel label="Deducted Amount" />
                  <Field name="amount_deducted" className="field" disabled />
                  <ErrorMessage name={`amount_deducted`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="">
                  <InputLabel label="Credit Note Provided" />
                  <Field name="credit_note" className="field" disabled />
                  <ErrorMessage name={`credit_note`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="">
                  <InputLabel label="Refunded to Source" />
                  <Field name="refund_amount" className="field" disabled />
                  <ErrorMessage name={`refund_amount`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="">
                  <InputLabel label="Amount Paying" />
                  <Field
                    name="amount"
                    className="field"
                    onChange={(e) => {
                      setFieldValue(`amount`, e.target.value);
                      setFieldValue(
                        "remaining_amount",
                        parseInt(values.total_amount_to_pay) -
                          (parseInt(values.total_amount_paid) +
                            parseInt(e.target.value)) +
                          parseInt(
                            values.credit_note ? values.credit_note : 0
                          ) +
                          parseInt(
                            values.amount_deducted ? values.amount_deducted : 0
                          ) +
                          parseInt(
                            values.refund_amount ? values.refund_amount : 0
                          )
                      );
                    }}
                  />
                  <ErrorMessage name={`amount`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="">
                  <InputLabel label="Reamining Amount" />
                  <Field name="remaining_amount" className="field" disabled />
                  <ErrorMessage name={`remaining_amount`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>

                <div className="grow">
                  <InputLabel label="Payment Method" />
                  <Field as="select" name={`payment_method`} className="field">
                    <option>Select a Payment method</option>
                    <option value="neft/imps">NEFT/IMPS</option>
                    <option value="google_pay">Google Pay</option>
                    <option value="phonepe">PhonePe</option>
                    <option value="paytm">Paytm</option>
                    <option value="payu_money">PayU Money</option>
                    <option value="bhim_upi">BHIM UPI</option>
                    <option value="EMI-sankash">EMI Sankash</option>
                    <option value="razorpay">Razorpay</option>
                    <option value="Others">Others</option>
                    <option value="cash">Cash</option>
                  </Field>
                  <ErrorMessage name={`payment_method`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="grow">
                  <InputLabel label="Transaction Id" />
                  <Field name={`transaction_id`} className="field" />
                  <ErrorMessage name={`transaction_id`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>

              <div className="my-4 text-center">
                <button type="submit" className="btn-primary">
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default AddPayments;
