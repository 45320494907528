import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import H2 from "../../components/headings/H2";
import UserAuthImage from "../../assets/image/User/justwravel-user-auth.png";
import { Formik, Form, ErrorMessage } from "formik";
import EmailInput from "../../components/inputFields/EmailInput";
import { forgotPassword } from "../services/AdminServices";
import { toast } from "react-toastify";

function ForgotPassword() {
  const navigate = useNavigate();

  const sendResetLink = (values) => {
    forgotPassword(values)
      .then((res) => {
        console.log(res);
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };

  return (
    <motion.div
      className="w-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3, delay: 0.3 } }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <div className="flex flex-col gap-4 py-4 lg:min-h-[calc(100vh-3rem)] lg:flex-row">
        <div className="after:bg-jw-green-dark/25 relative flex grow basis-1/2 items-center p-4 after:absolute after:top-1/2 after:right-0 after:hidden after:h-3/4 after:w-1 after:-translate-y-1/2 after:content-[''] lg:px-8 after:lg:block">
          <img
            src={UserAuthImage}
            alt=""
            className="mx-auto w-3/4 drop-shadow-lg"
          />
        </div>
        <div className="flex grow items-center justify-center">
          <div className="w-full px-4 lg:px-16">
            <H2 className={"mb-4 text-center"}>Forgot Password</H2>

            <Formik
              //   innerRef={formRef}
              initialValues={{
                email: "",
              }}
              //   validationSchema={loginFormSchema}
              onSubmit={(values) => {
                // alert(JSON.stringify(values));
                sendResetLink(values);
              }}
            >
              {({ values, setFieldValue, errors, touched }) => (
                <Form>
                  <div>
                    <div className="my-4">
                      <EmailInput
                        id="email"
                        value={values.email}
                        onChange={(e) => setFieldValue("email", e.target.value)}
                      />
                      <ErrorMessage name="email">
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                  </div>
                  <div className="mt-4 text-center">
                    <button className="btn-primary w-full" type="submit">
                      Send Reset Link
                    </button>
                  </div>
                  <Link
                    to="/login"
                    className="mt-4 block text-center font-semibold text-jw-green dark:text-white"
                  >
                    Want to Try Login Again? <u>Login Here!</u>
                  </Link>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default ForgotPassword;
