import H2 from "../../../components/headings/H2";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import ItineraryBuilderFormWrapper from "./itineraryForm/ItineraryBuilderFormWrapper";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {
  deleteItineraryDraft,
  getEmployeeItineraryDrafts,
} from "../../services/CustomPackageServices";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import DataTable from "../common/DataTable";
import { getReadableDate } from "../../../helpers/Helper";
import $ from "jquery";
import { toast } from "react-toastify";

function AddItinerary() {
  const [itineraryDrafts, setItineraryDrafts] = useState([]);
  const [draftModal, setDraftModal] = useState(false);
  const [selectedDraft, setSelectedDraft] = useState();
  const [formData, setFormData] = useState();

  const draftTable = useRef();

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
    <div class="flex items-center gap-3">
      <div class="custom-itinerary-edit-btn cursor-pointer" data-id=${
        cell.getRow().getData().id
      }>
          <i class="fas fa-edit"></i>
      </div>
      <div class="custom-itinerary-delete-btn cursor-pointer text-red-500" data-id=${
        cell.getRow().getData().id
      }>
        <i class="fas fa-trash-alt"></i>
      </div>
  </div>`;
  };

  const columns = [
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Package Detail",
      // field: "draft.package_title",
      maxWidth: "200",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      formatter: function (cell, formatterParams, onRendered) {
        return `${cell.getData().draft.package_title} </br> ${
          cell.getData().draft.state_name
            ? cell.getData().draft.state_name
            : cell.getData().draft.country_name
        } `;
      },
    },
    {
      title: "Package Type",
      minWidth: "100",
      field: "draft.package_type",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getValue() === 0) {
          return "Domestic";
        } else {
          return "International";
        }
      },
    },
    {
      title: "User Detail",
      field: "draft.first_name",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      formatter: function (cell, formatterParams, onRendered) {
        return `${cell.getData().draft.first_name} ${
          cell.getData().draft.last_name
        } </br> ${cell.getData().draft.phone} </br> ${
          cell.getData().draft.email
        } `;
      },
    },
    {
      title: "Starting Location",
      minWidth: "100",
      field: "draft.starting_location",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Ending Location",
      minWidth: "100",
      field: "draft.ending_location",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Start Date",
      minWidth: "100",
      responsive: 0,
      field: "draft.start_date",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return `${getReadableDate(cell.getValue())}`;
      },
    },
    {
      title: "End Date",
      field: "draft.end_date",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: function (cell, formatterParams, onRendered) {
        return `${getReadableDate(cell.getValue())}`;
      },
    },
    {
      title: "Total Pax",
      minWidth: "100",
      field: "draft.total_pax",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Costing Type",
      minWidth: "100",
      field: "draft.price_type",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getValue() === "per_person") {
          return "Per Person Cost";
        } else {
          return "Total Cost";
        }
      },
    },
    {
      title: "Cost",
      minWidth: "100",
      field: "draft.price",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Booking Amount",
      minWidth: "100",
      field: "draft.booking_amount",
      resizable: true,
      vertAlign: "middle",
    },
    {
      formatter: actionIcons,
      headerSort: false,
      minWidth: "100",
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  const deleteDraft = (id) => {
    if (!window.confirm("Do You really want to delete this draft?")) return;

    deleteItineraryDraft(id)
      .then((res) => {
        toast.success(res.data.message);
        draftTable.current.deleteRow(id);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    $(document).on("click", ".custom-itinerary-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      console.log(cell, itineraryDrafts);
      let selectedItinerary = itineraryDrafts.filter(
        (draft) => draft.id === parseInt(cell)
      );
      setSelectedDraft(selectedItinerary[0]);
      setFormData(selectedItinerary[0].draft);
      console.log(selectedItinerary);
      setDraftModal(false);
    });

    $(document).on("click", ".custom-itinerary-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      deleteDraft(dataId);
    });

    return () => {
      $(document).off("click", ".custom-itinerary-edit-btn");
      $(document).off("click", ".custom-itinerary-delete-btn");
    };
  }, [itineraryDrafts]);

  useEffect(() => {
    const cp = localStorage.getItem(`CP`);
    console.log(JSON.parse(cp));
    if (cp) setFormData(JSON.parse(cp));

    getEmployeeItineraryDrafts()
      .then((res) => {
        setItineraryDrafts(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <ComponentHeader className="border-b">
        <H2>Add Custom Package</H2>
        <Link
          to="/itinerary-builder"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>

      {itineraryDrafts.length > 0 && (
        <div className="">
          <div className="mt-4 flex items-center justify-center gap-8">
            <p className="text-lg font-semibold">
              You have drafts Saved. Want to use saved Draft?
            </p>
            <button
              onClick={() => setDraftModal(true)}
              className="btn-primary border-2 !border-jw-green !bg-white !text-jw-green"
            >
              Use Draft
            </button>
          </div>
        </div>
      )}

      <div className="mt-4">
        <ItineraryBuilderFormWrapper
          formData={formData}
          selectedDraft={selectedDraft}
          setFormData={setFormData}
        />
      </div>

      <Modal
        isOpen={draftModal}
        contentLabel="Update Participant Details"
        className="mx-auto mt-[2%] max-h-[90%] max-w-[75%] overflow-auto rounded-md bg-white p-4"
        overlayClassName="fixed top-0 left-0 w-full h-full !z-40 !bg-black/50"
        // onAfterClose={updateLocation}
      >
        <div className="flex items-center justify-between border-b p-4">
          <h2 className="text-xl font-bold">Saved Drafts</h2>
          <button
            onClick={() => setDraftModal(false)}
            className="cursor-pointer rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
        </div>

        <div className="mt-2">
          <DataTable
            ref={draftTable}
            columns={columns}
            tableData={itineraryDrafts}
            search={true}
            selectable={false}
          />
        </div>
      </Modal>
    </div>
  );
}

export default AddItinerary;
