import React, { useEffect, useState } from "react";
import FileInput from "../../../components/inputFields/FileInput";

import { createModule, updateModule } from "../../services/ModuleServices";
import InputLabel from "../inputs/InputLabel";
import useLoader from "../../../hooks/useLoader";
import { toast } from "react-toastify";
import { getUploadsUrl } from "../../../ServiceConfig";

// -----------------------------------------------------------
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import KeywordsInput from "../inputs/KeywordsInput";

const countryFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  has_modules: Yup.number().required("Required"),
});

function ModuleForm({ formData, edit, onSuccess }) {
  const { setLoading } = useLoader();

  // ? Add new country
  const addModule = (values) => {
    let data = Object.assign({}, values);
    createModule(data)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  // ? Update country
  const editModule = (values) => {
    let data = Object.assign({}, values);
    updateModule(formData.id, data)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div>
      <Formik
        initialValues={{
          name: formData?.name,
          has_modules: formData?.has_modules?.toString() || "0",
        }}
        validationSchema={countryFormSchema}
        onSubmit={(values) => {
          edit ? editModule(values) : addModule(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="flex gap-4">
              <div className="grow">
                <div className="flex flex-wrap gap-4">
                  {/* //? country Name */}
                  <div className="max-w-[50%] grow basis-[45%]">
                    <InputLabel label="Name" labelFor="name" />
                    <Field
                      className="block w-full rounded-md border-2 py-1 px-2"
                      id="name"
                      name="name"
                    />
                    <ErrorMessage name="name">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn-primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ModuleForm;
