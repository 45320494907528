import { useState } from "react";
import { useEffect } from "react";
import { FaEnvelope, FaRupeeSign } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import {
  formatNumberToTwoDigit,
  getReadableDate,
} from "../../../helpers/Helper";
import { getUploadsUrl } from "../../../ServiceConfig";
import {
  getBookingById,
  mailRemainingPaymentNotification,
  resendPaymentReceipt,
  sendUpdateParticipantNotification,
} from "../../services/BookingServices";
import { getAllEmployeesOption } from "../../services/EmployeeServices";
import { getAllOccupancies } from "../../services/PackageService";
import { getAllRidersOption } from "../../services/RiderServices";
import { getAllTravelModesOption } from "../../services/TravelModeServices";

import Modal from "react-modal";
import { toast } from "react-toastify";
import {
  getProformaInvoiceById,
  sendProformaInvoice,
} from "../../services/ProformaInvoiceServices";
import moment from "moment";
import { getAllStateOption } from "../../services/StateServices";

Modal.setAppElement("#modal");

function ViewProformaInvoice() {
  const proformaInvoiceId = useParams().id;

  const [invoiceData, setInvoiceData] = useState();
  const [travelModes, setTravelModes] = useState();
  const [riders, setRiders] = useState();
  const [occupancies, setOccupancies] = useState();
  const [employees, setEmployees] = useState();
  const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);
  const [allStates, setAllStates] = useState([])

  const [recieptToUpdate, setRecieptToUpdate] = useState();
  const [activeParticipantData, setActiveParticipantData] = useState();
  const [particpantIdModalIsOpen, setParticpantIdModalIsOpen] = useState(false);

  const getBookingData = () => {
    getProformaInvoiceById(proformaInvoiceId).then((res) => {
      console.log(res.data.data);
      setInvoiceData(res.data.data);
    });
  };

  useEffect(() => {
    getBookingData();
  }, [proformaInvoiceId]);

  useEffect(() => {
    getAllTravelModesOption().then((res) => {
      setTravelModes(res.data.data);
    });

    getAllRidersOption().then((res) => {
      setRiders(res.data.data);
    });

    getAllOccupancies().then((res) => {
      setOccupancies(res.data.data);
    });

    getAllEmployeesOption().then((res) => {
      setEmployees(res.data.data.data);
    });
  }, []);

  // useEffect(() => {
  //   getAllStateByCountry(invoiceData?.country_id).then((res) => {
  //     setStates(res.data.data);
  //   });
  // }, [invoiceData]);

  useEffect(() => {
    recieptToUpdate && setPaymentModalIsOpen(true);
  }, [recieptToUpdate]);

  const getEmployeeName = (empId) => {
    let name = "";
    let emp = employees?.filter((emp) => emp.id === empId);
    name = `${emp?.[0]?.first_name} ${emp?.[0]?.last_name}`;
    return name;
  };

  const getUpdatedData = () => {
    getBookingById(proformaInvoiceId).then((res) => {
      setInvoiceData(res.data.data);
    });
  };

  const getRiderName = (riderId) => {
    let riderName = riders?.filter((rider) => rider.id === riderId)[0]?.name;

    if (riderName) {
      return riderName;
    } else {
      return "N/A";
    }
  };

  useEffect(() => {
    getAllStateOption().then((res) => {
      setAllStates(res.data.data);
    });
  }, []);

  const mailInvoice = () => {
    sendProformaInvoice(proformaInvoiceId).then((res) => {
      toast.success(res.data.data);
    });
  };

  const getTotalAddonCost = () => {
    let addonCost = 0;
    if (invoiceData?.addons?.length > 0) {
      invoiceData?.addons?.forEach((addon) => {
        addonCost += addon.amount;
      });

      return addonCost;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    activeParticipantData && setParticpantIdModalIsOpen(true);
  }, [activeParticipantData]);

  const actionIcons = function (cell, formatterParams, onRendered) {
    if (cell.getRow().getData().status !== 0) {
      return `<div class="resend-declaration-btn" data-id=${
        cell.getRow().getData().id
      } style="display: flex; align-items: center; gap: 12px;">
    <div style="cursor: pointer; title="Edit">
    <svg width="16" height="16" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_901_1388)">
<path d="M22.0002 12L13.0002 22V31L16.0002 27M31.0002 1L1.00019 17L25.0002 27L31.0002 1Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_901_1388">
<rect width="32" height="32" fill="white"/>
</clipPath>
</defs>
</svg></div>
   `;
    } else {
      return "";
    }
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    // {
    //   title: "Membership",
    //   field: "package_name",
    //   minWidth: "100",
    //   vertAlign: "middle",
    //   responsive: 0,
    //   sorterParams: {
    //     locale: true,
    //     alignEmptyValues: "top",
    //   },
    //   resizable: true,
    // },
    {
      title: "Name",
      minWidth: "100",
      field: "event_date",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        if (
          cell.getRow().getData().first_name ||
          cell.getRow().getData().last_name
        ) {
          return `${
            cell.getRow().getData().first_name
              ? cell.getRow().getData().first_name
              : ""
          } ${
            cell.getRow().getData().last_name
              ? cell.getRow().getData().last_name
              : ""
          }`;
        } else {
          return "";
        }
      },
    },
    {
      title: "Email",
      minWidth: "100",
      field: "email",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Phone",
      field: "phone",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Whatsapp",
      field: "whatsapp",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Age",
      field: "age",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
    },
    {
      title: "Gender",
      field: "gender",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
    },
    {
      title: "Travel Mode",
      field: "travel_mode_id",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return travelModes?.filter(
          (tm) => tm.id === cell.getRow().getData().travel_mode_id
        )[0]?.name;
      },
    },
    {
      title: "Occupancy",
      field: "status",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return occupancies?.filter(
          (occ) => occ.id === cell.getRow().getData().occupancy_id
        )[0]?.name;
      },
    },
    {
      title: "Eme. Contact",
      field: "emergency_contact",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Eme. Relation",
      field: "emergency_contact_relation",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Eme. Name",
      field: "emergency_contact_name",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Id Proof",
      field: "id_proof",
      resizable: true,
      vertAlign: "middle",
      formatter: "image",
      formatterParams: {
        height: "50px",
        width: "80px",
        urlPrefix: `${getUploadsUrl()}/proformaInvoices/participant_id_attachment/`,
      },
    },
    {
      title: "Medical Certificate",
      field: "medical_certificate",
      resizable: true,
      vertAlign: "middle",
      formatter: "image",
      formatterParams: {
        height: "50px",
        width: "80px",
        urlPrefix: `${getUploadsUrl()}/proformaInvoices/participants/medical-certifcates/`,
      },
    },
    {
      title: "Resend Declaration",
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  const rowFormatter = (row) => {
    if (row.getData().status === 0) {
      const children = row.getElement().childNodes;
      children.forEach((child) => {
        child.style.color = "#D83F31";
      });
    }
  };

  return (
    <div className="p-4">
      <div className="my-4 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <h2
            className={`text-xl font-bold ${
              invoiceData?.cancellation?.cancellation_type
                ? "text-[#D83F31]"
                : ""
            }`}
          >
            {invoiceData?.is_customized ? "Customized" : ""} Proforma Invoice :
            #{invoiceData?.id}
          </h2>

          {/* {invoiceData?.cancellation?.cancellation_type && (
            <div className="rounded-full bg-[#D83F31] px-2 py-1 capitalize text-white">
              {invoiceData?.cancellation?.cancellation_type}{" "}
              Cancellation
            </div>
          )} */}
        </div>
        {!invoiceData?.cancellation?.cancellation_type && (
          <div>
            {/* <button
              onClick={() => setCancellationModal(true)}
              className="btn-primary mr-4 bg-red-500"
            >
              Cancel Booking
            </button> */}
            <Link
              to={`/bookings/proforma-invoice/edit/${invoiceData?.id}`}
              className="btn-primary"
            >
              Edit Booking
            </Link>
          </div>
        )}
      </div>

      <div className="rounded-lg border-2 bg-white p-4">
        <h2 className="border-b-2 pb-4 text-xl font-bold">Billing Info</h2>
        <div className="my-4 flex flex-wrap items-center">
          <div className="my-2 basis-1/3">
            <b>Name : </b> {invoiceData?.first_name} {invoiceData?.last_name}
          </div>
          <div className="my-2 basis-1/3">
            <b>Email : </b> {invoiceData?.email}
          </div>
          <div className="my-2 basis-1/3">
            <b>Phone : </b>+{invoiceData?.country_code}-{invoiceData?.phone}
          </div>
          <div className="my-2 basis-1/3">
            <b>Company : </b> {invoiceData?.firm_name}
          </div>
          <div className="my-2 basis-1/3">
            <b>GST Number : </b> {invoiceData?.gst_number}
          </div>
          <div className="my-2 basis-1/3">
            <b>Address : </b> {invoiceData?.firm_address}
          </div>
          <div className="my-2 basis-1/3">
            <b>State : </b>{" "}
            {
              allStates?.filter((state) => state.id === invoiceData?.state_id)[0]?.name
            }
          </div>
        </div>
      </div>

      <div className="my-4 rounded-lg border-2 bg-white p-4">
        <h2 className="border-b-2 pb-4 text-xl font-bold">Package Info</h2>
        <div className="my-4 flex flex-wrap items-center">
          <div className="items my-2 flex basis-full justify-between gap-8">
            <div className={invoiceData?.is_customized ? "hidden" : ""}>
              <b>Package Id: </b> {invoiceData?.package_id}
            </div>
            <div className="grow">
              <b>Package : </b> {invoiceData?.package_name}
            </div>
          </div>
          <div className="my-2 basis-1/3">
            <b>Event Date : </b> {getReadableDate(invoiceData?.event_date)}
          </div>
          <div className="my-2 basis-1/3">
            <b>Pick Drop Location : </b> {invoiceData?.starting_location}{" "}
            &#8594; {invoiceData?.ending_location}
          </div>
          <div className="my-2 basis-1/3">
            <b>Number of days : </b> {invoiceData?.no_of_days}{" "}
          </div>
          <div className="my-2 basis-full">
            <b>Special Notes : </b>{invoiceData?.special_notes}
          </div>
          <div className="my-2 basis-full">
            <b>Custom Itinerary : </b>
          </div>
        </div>
      </div>

      <div className="my-4 rounded-lg border-2 bg-white p-4">
        <h2 className="text-xl font-bold">Cost Calculation</h2>
        <div className="mb-4">
          <table className="w-full text-center">
            <thead className="text-sm">
              <tr className="border-b bg-gray-200">
                <th className="py-2">Travel Mode</th>
                <th className="py-2">Rider</th>
                <th className="py-2">Occupancy</th>
                <th className="py-2">Price</th>
                <th className="py-2">Discount</th>
                <th className="py-2">Final</th>
                <th className="py-2">GST</th>
                <th className="py-2">With GST</th>
                <th className="py-2">Wraveler</th>
                <th className="py-2">Amount</th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {invoiceData?.cost?.map((proformaInvoice, index) => {
                return (
                  <tr
                    key={proformaInvoice.id}
                    className={`border-b ${
                      index % 2 === 0 ? "" : "bg-gray-50"
                    }`}
                  >
                    <td className="py-2">
                      {
                        travelModes?.filter(
                          (tm) => tm.id === proformaInvoice?.travel_mode_id
                        )[0]?.name
                      }
                    </td>
                    <td className="py-2">
                      {getRiderName(proformaInvoice?.rider_id)}
                    </td>
                    <td className="py-2">
                      {
                        occupancies?.filter(
                          (occ) => occ.id === proformaInvoice?.occupancy_id
                        )[0]?.name
                      }
                    </td>
                    <td className="py-2">{proformaInvoice?.package_price}</td>
                    <td className="py-2">
                      {proformaInvoice?.package_discount}
                    </td>
                    <td className="py-2">
                      {proformaInvoice?.package_price_after_discount}
                    </td>
                    <td className="py-2">{proformaInvoice?.package_gst}</td>
                    <td className="py-2">
                      {proformaInvoice?.package_with_gst}
                    </td>
                    <td className="py-2">{proformaInvoice?.pax}</td>
                    <td className="py-2">{proformaInvoice?.amount}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {invoiceData?.addons?.length > 0 && (
        <div className="my-4 rounded-lg border-2 p-4">
          <h2 className="text-xl font-bold">Addons</h2>
          <div className="mb-4">
            <table className="w-full text-center">
              <thead>
                <tr className="border-b bg-gray-200">
                  <th className="py-2">Title</th>
                  <th className="py-2">Description</th>
                  <th className="py-2">Unit Type</th>
                  <th className="py-2">Unit Price</th>
                  <th className="py-2">Unit GST</th>
                  <th className="py-2">Unit</th>
                  <th className="py-2">Amount</th>
                </tr>
              </thead>
              <tbody>
                {invoiceData?.addons?.map((addon, index) => {
                  return (
                    <tr
                      key={addon.id}
                      className={`border-b ${
                        index % 2 === 0 ? "" : "bg-gray-50"
                      }`}
                    >
                      <td className="py-2">{addon?.title}</td>
                      <td className="py-2">{addon?.description}</td>
                      <td className="py-2">{addon?.unit_type}</td>
                      <td className="py-2">{addon?.unit_price}</td>
                      <td className="py-2">{addon?.unit_gst}</td>
                      <td className="py-2">{addon?.unit}</td>
                      <td className="py-2">{addon?.amount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <div className="flex items-end justify-end gap-12 pb-4 ">
        <div className="text-right">
          <table className="">
            <tbody>
              <tr>
                <td className="font-semibold">Total Pax :</td>
                <td className="px-4 py-1 font-bold">
                  {formatNumberToTwoDigit(invoiceData?.total_pax)}
                </td>
              </tr>
              <tr>
                <td className="font-semibold">Total Price :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {invoiceData?.total_cost}
                </td>
              </tr>
              <tr>
                <td className="font-semibold">Total Discount :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {invoiceData?.total_discount}
                </td>
              </tr>
              <tr>
                <td className="font-semibold">Total GST :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {invoiceData?.total_gst}
                </td>
              </tr>

              <tr>
                <td className="font-semibold">Total TCS :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {invoiceData?.total_tcs}
                </td>
              </tr>
              <tr>
                <td className="font-semibold">Amount to Pay :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {invoiceData?.total_amount_to_pay}
                </td>
              </tr>
              <tr>
                <td className="font-semibold">Amount Paid :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {invoiceData?.total_amount_paid}
                </td>
              </tr>
              <tr>
                <td className="font-semibold">Remaining Amount :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {invoiceData?.remaining_amount}
                </td>
              </tr>
            </tbody>
          </table>
          {/* {invoiceData?.remaining_amount > 0 &&
            invoiceData?.cancellation?.cancellation_type !== "full" && (
              <Link
                to={`/proformaInvoices/balancePayment/${invoiceData?.id}`}
                className="mt-2 flex items-center justify-center gap-1 rounded-lg border border-jw-green px-4 py-2 text-jw-green"
              >
                <FaPlusSquare /> Add Payments
              </Link>
            )} */}
        </div>
      </div>
      <div className="flex items-center justify-end gap-4">
        <button
          onClick={mailInvoice}
          className="flex items-center justify-center gap-1 rounded-lg bg-blue-500 py-2 px-8 text-white"
        >
          <FaEnvelope /> Mail Invoice
        </button>

        <a
          href={`${getUploadsUrl()}/booking/pdf/proforma-invoice/proforma-invoice-${
            invoiceData?.id
          }.pdf`}
          target="_blank"
          className="rounded-lg bg-green-500 py-2 px-8 text-white"
        >
          Download Invoice
        </a>
      </div>

      <div className="mt-8 flex items-center gap-12 text-sm text-gray-500">
        <div className="flex items-center gap-12">
          <p>
            Converted By :{" "}
            <span className="font-semibold">
              {invoiceData?.converted_by?.first_name}{" "}
              {invoiceData?.converted_by?.last_name}
            </span>
          </p>
        </div>
        |
        <div className="flex items-center gap-12">
          <p>
            Created By :{" "}
            <span className="font-semibold">
              {invoiceData?.created_by.first_name}{" "}
              {invoiceData?.created_by.last_name}
            </span>
          </p>
          <p>
            Created At :{" "}
            <span className="font-semibold">
              {moment(invoiceData?.created_at).format("DD MMM, YYYY")}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ViewProformaInvoice;
