import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import { getAdminUrl } from "../../../ServiceConfig";
import H2 from "../../../components/headings/H2";
import { FaPlusSquare } from "react-icons/fa";
import ComponentHeader from "../../components/common/ComponentHeader";
import DataTable from "../../components/common/DataTable";
import { deleteLeadCondition } from "../../services/LeadService";

function ConditionList() {
  const navigate = useNavigate();
  const transferTable = useRef();

  const deleteSelectedCondition = (id) => {
    if (!window.confirm("Do You really want to delete this condition?")) return;
    deleteLeadCondition(id)
      .then((res) => {
        toast.success(res.data.message);
        transferTable.current.deleteRow(id);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
    <div class="flex items-center gap-3">
      <div class="condition-edit-btn cursor-pointer" data-id=${
        cell.getRow().getData().id
      }>
          <i class="fas fa-edit"></i>
      </div>
      <div class="condition-delete-btn cursor-pointer text-red-500" data-id=${
        cell.getRow().getData().id
      }>
        <i class="fas fa-trash-alt"></i>
      </div>
  </div>`;
  };

  const printStar = (cell, formatterParams) => {
    let html = `<div class="flex items-center text-yellow-500 text-sm">`;
    cell.getData();
    for (let i = 0; i < cell.getData().star_category; i++) {
      html += `<i class="fas fa-star"></i>`;
    }

    html += `</div>`;
    return html;
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Condition",
      field: "condition",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      formatter: function (cell, formatterParams, onRendered) {
        let str = `<ul class="text-center">`;
        let conditionCount = cell.getData().leadConditions.length;
        cell.getData().leadConditions.forEach((condition, index) => {
          str += `
                    <li><span class="text-black font-bold capitalize"> ${
                      condition.parameter === "lead_category_id"
                        ? "Category"
                        : condition.parameter === "location_id"
                        ? "Location"
                        : condition.parameter === "default"
                        ? "Default"
                        : condition.parameter
                    }</span> <span class="text-orange-500">${
            condition.logical_operator
          }</span> <span class="text-blue-500 capitalize">${
            condition.value_name
          }</span></li>
                    ${
                      index < conditionCount - 1
                        ? `<li class="font-bold text-gray-500 text-sm">${condition.conditional_operator}</li>`
                        : ""
                    }
                  
                  `;
        });

        str += `</ul>`;
        return str;
      },
    },
    {
      title: "Assigned To",
      minWidth: "100",
      field: "employee_names",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Last Assigned",
      hozAlign: "center",
      vertAlign: "middle",
      headerSort: false,
      field: "condition_last_assigned_to",
    },

    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  useEffect(() => {
    $(document).on("click", ".condition-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/lead-conditions/edit/${cell}`);
    });

    $(document).on("click", ".condition-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      deleteSelectedCondition(dataId);
    });

    return () => {
      $(document).off("click", ".condition-edit-btn");
      $(document).off("click", ".condition-delete-btn");
    };
  }, []);

  return (
    <div className="">
      <ComponentHeader className="border-b">
        <H2>Lead Conditions</H2>
        <Link
          to="/lead-conditions/add"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaPlusSquare /> Add New
        </Link>
      </ComponentHeader>
      <div className="">
        <DataTable
          ref={transferTable}
          ajaxUrl={`${getAdminUrl()}/lead-condition`}
          columns={columns}
          search={true}
          responsiveLayout="collapse"
        />
      </div>
    </div>
  );
}

export default ConditionList;
