import { admin } from "../../ServiceConfig";

export function createInternship(data) {
  return admin.post(`/internship`, data);
}

export function updateInternship(id, data) {
  return admin.put(`/internship/${id}`, data);
}

export function getInternshipById(id) {
  return admin.get(`/internship/${id}`);
}

export function deleteInternship(id) {
  return admin.delete(`/internship/${id}`);
}

export function getInternshipApplicationById(id){
  return admin.get(`internship-application/${id}`)
}

export function updateInternshipApplicationStatus(id, data){
  return admin.post(`internship-application/${id}`, data)
}

export function getAllInternships() {
  return admin.get('/internship')
}