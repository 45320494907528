import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PermissionForm from "./PermissionForm";
// import MetaTags from "../../../web/layouts/MetaTags";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";

function AddPermission() {
  const navigate = useNavigate();

  const onAddPermission = (data) => {
    navigate(`/permissions`);
  };

  return (
    <div className="">
      {/* <MetaTags title="Add Travel Mode | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Add Permission</H2>
        <Link
          to="/permissions"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      <PermissionForm onSuccess={(data) => onAddPermission(data)} />
    </div>
  );
}

export default AddPermission;
