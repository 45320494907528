import React, { useEffect, useState } from "react";
import FileInput from "../../../components/inputFields/FileInput";

import {
    createTravelMode,
    updateTravelMode,
} from "../../services/TravelModeServices";
import InputLabel from "../inputs/InputLabel";
import useLoader from "../../../hooks/useLoader";
import { toast } from "react-toastify";
import { getUploadsUrl } from "../../../ServiceConfig";

// -----------------------------------------------------------
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import KeywordsInput from "../inputs/KeywordsInput";

const countryFormSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
    has_riders: Yup.number().required("Required"),
});

function TravelModeForm({ formData, edit, onSuccess, setAddTmModal }) {
    const { setLoading } = useLoader();

    // ? Add new country
    const addTravelMode = (values) => {
        let data = Object.assign({}, values);
        createTravelMode(data)
            .then((res) => {
                setAddTmModal(false)
                toast.success(res.data.message);
                onSuccess(res.data.data);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };

    // ? Update country
    const editTravelMode = (values) => {
        let data = Object.assign({}, values);
        updateTravelMode(formData.id, data)
            .then((res) => {
                setAddTmModal(false)
                toast.success(res.data.message);
                onSuccess(res.data.data);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };

    return (
        <div>
            <Formik
                initialValues={{
                    name: formData?.name,
                    has_riders: formData?.has_riders.toString() || "0",
                }}
                validationSchema={countryFormSchema}
                onSubmit={(values) => {
                    edit ? editTravelMode(values) : addTravelMode(values);
                }}
            >
                {({ values, setFieldValue, errors, touched }) => (
                    <Form>
                        <div className="flex gap-4">
                            <div className="grow">
                                <div className="flex flex-wrap gap-4">
                                    {/* //? country Name */}
                                    <div className="md:max-w-[50%] max-w-[100%] grow basis-[45%]">
                                        <InputLabel
                                            label="Name"
                                            labelFor="name"
                                        />
                                        <Field
                                            className="field"
                                            id="name"
                                            name="name"
                                        />
                                        <ErrorMessage name="name">
                                            {(msg) => (
                                                <div className="error-msg">
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                </div>
                                {/* //? country Status */}
                                <div className="my-4">
                                    <div
                                        role="group"
                                        aria-labelledby="my-radio-group"
                                    >
                                        <InputLabel label="Has Riders" />
                                        <div className="flex items-center gap-12">
                                            <label className="flex items-baseline gap-1">
                                                <Field
                                                    type="radio"
                                                    name="has_riders"
                                                    value="1"
                                                />
                                                Yes
                                            </label>
                                            <label className="flex items-baseline gap-1">
                                                <Field
                                                    type="radio"
                                                    name="has_riders"
                                                    value="0"
                                                />
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="text-center">
                            <button type="submit" className="btn-primary">
                                Submit
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default TravelModeForm;
