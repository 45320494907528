import { admin } from "../../ServiceConfig";

export function getAllCustomItineraries() {
  return admin.get(`/all-customItineraries`);
}

export function createCustomPackage(data) {
  return admin.post(`/custom-itinerary`, data);
}

export function getCustomPackageByCountryId(countryId) {
  return admin.get(`/get-itineraryByCountry/${countryId}`);
}

export function createCustomPackageAsNew(id, data){
  return admin.post(`/create-new-custom-itinerary/${id}`, data)
}
export function getCustomPackageByStateId(stateId) {
  return admin.get(`/get-itineraryByState/${stateId}`);
}

export function getCustomPackageById(id) {
  return admin.get(`/custom-itinerary/${id}`);
}

export function updateCustomPackage(data) {
  return admin.put(`/custom-itinerary/${data.id}`, data);
}

export function deleteCustomPackage(id) {
  return admin.delete(`/custom-itinerary/${id}`);
}

export function mailCustomPdf(id, pdf) {
  return admin.post(`/send-itineraryEmail/${id}`, pdf, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function uploadCustomPackageImage(file) {
  return admin.post(`/uploads/image?upload_path=custom-packages`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function uploadCustomPackageFieldImage(file) {
  return admin.post(
    `/uploads/image?upload_path=custom-packages/custom-field`,
    file,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

export function storeItineraryDraft(data) {
  return admin.post(`/store-draft`, data);
}

export function updateItineraryDraft(data) {
  return admin.put(`/store-draft/${data.id}`, data);
}

export function deleteItineraryDraft(id) {
  return admin.delete(`/store-draft/${id}`);
}

export function getEmployeeItineraryDrafts() {
  return admin.get(`/store-draft`);
}
