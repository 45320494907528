import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { deleteRole } from "../../services/RoleServices";
import { toast } from "react-toastify";
import DataTable from "../common/DataTable";
import { getAdminUrl } from "../../../ServiceConfig";
import ComponentHeader from "../common/ComponentHeader";
import { FaPlusSquare } from "react-icons/fa";

import H2 from "../../../components/headings/H2";

function RoleList() {
  const navigate = useNavigate();
  const roleTable = useRef();

  const deleteSelectedRole = (id) => {
    deleteRole(id)
      .then((res) => {
        toast.success(res.data.message);
        roleTable.current.deleteRow(id);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
  <div class="flex items-center gap-3">
      <div class="role-add-permission-btn cursor-pointer" data-id=${
        cell.getRow().getData().id
      }>
          <i class="fas fa-plus"></i>
      </div>
      <div class="role-edit-btn cursor-pointer" data-id=${
        cell.getRow().getData().id
      }>
          <i class="fas fa-edit"></i>
      </div>
      <div class="role-delete-btn cursor-pointer text-red-500" data-id=${
        cell.getRow().getData().id
      }>
        <i class="fas fa-trash-alt"></i>
      </div>
  </div>`;
  };
  const usersFormatter = function (cell, formatterParams, onRendered) {
    const users = cell.getValue();

    // Create an array of chips for each user
    const chips = users.map((user) => {
      return `
        
        <div
    class="relative grid select-none items-center rounded-full border  py-1.5 px-3 font-sans text-xs font-bold">
    <span class=""> ${user.first_name} ${user.last_name}</span>
  </div>
      `;
    });

    // Join the chips and return as HTML
    return `<div class="flex flex-wrap gap-2">${chips.join("")}</div>`;
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Role Name",
      field: "name",
      minWidth: "100",
      vertAlign: "middle",
      hozAlign: "center",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Assigned Users",
      field: "users",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      formatter: usersFormatter,
    },
    {
      title: "Having Permissions",
      field: "permissions",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      variableHeight: true,
      formatter: function (cell, formatterParams, onRendered) {
        const permissions = cell.getValue();

        // Create an array of chips for each user
        const chips = permissions.map((user) => {
          return `
        
        <div
    class="relative grid select-none items-center rounded-full border py-1.5 px-3 font-sans text-xs font-bold">
    <span class=""> ${user}</span>
  </div>
      `;
        });
        // Join the chips and return as HTML
        return `<div class="flex flex-wrap gap-2">${chips.join("")}</div>`;
      },
    },

    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  useEffect(() => {
    $(document).on("click", ".role-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/roles/edit/${cell}`);
    });

    $(document).on("click", ".role-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      deleteSelectedRole(dataId);
    });

    return () => {
      $(document).off("click", ".role-edit-btn");
      $(document).off("click", ".role-delete-btn");
    };
  }, []);

  return (
    <div className="">
      <ComponentHeader className="border-b">
        <H2>Roles</H2>
        <Link
          to="/roles/add"
          className="btn-primary flex items-center gap-1 whitespace-nowrap py-1 px-4 dark:bg-green-800 lg:px-12 lg:py-2"
        >
          <FaPlusSquare /> Add New
        </Link>
      </ComponentHeader>
      <div className="">
        <DataTable
          ref={roleTable}
          ajaxUrl={`${getAdminUrl()}/roles`}
          columns={columns}
          search={true}
          responsiveLayout="collapse"
        />
      </div>
    </div>
  );
}

export default RoleList;
