import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import RiderForm from "./RiderForm";
// import MetaTags from "../../../web/layouts/MetaTags";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";

function AddRider() {
  const navigate = useNavigate();

  const onAddRider = (data) => {
    navigate(`/rider`);
  };

  return (
    <div className="">
      {/* <MetaTags title="Add Travel Mode | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Add Rider</H2>
        <Link
          to="/rider"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      <RiderForm onSuccess={(data) => onAddRider(data)} />
    </div>
  );
}

export default AddRider;
