import { FaPlusSquare } from "react-icons/fa";
import { Link } from "react-router-dom";
import H2 from "../../../components/headings/H2";
import ComponentHeader from "../common/ComponentHeader";
import EventDatesForm from "./EventDatesForm";

function EventList() {
  return (
    <div>
      <ComponentHeader className="border-b">
        <H2>Event Dates</H2>
        {/* <Link
          to="/location/add"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaPlusSquare /> Add New
        </Link> */}
      </ComponentHeader>

      <div>
        <EventDatesForm />
      </div>
    </div>
  );
}

export default EventList;
