import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { deleteGoogleReview } from "../../services/GoogleReviewsServices";
import { toast } from "react-toastify";
import DataTable from "../common/DataTable";
import { getAdminUrl, getUploadsUrl } from "../../../ServiceConfig";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt, FaPlusSquare } from "react-icons/fa";
import H2 from "../../../components/headings/H2";
import InputLabel from "../inputs/InputLabel";
import SelectSearch from "../inputs/SelectSearch";
import { getAllPackages } from "../../services/PackageService";
import { getAllCategories } from "../../services/CategoryServices";

function GoogleReviewList() {
  const navigate = useNavigate();
  const googleReviewTable = useRef();

  const deleteSelectedGoogleReview = (id) => {
    deleteGoogleReview(id)
      .then((res) => {
        toast.success(res.data.message);
        googleReviewTable.current.deleteRow(id);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
  <div class="flex items-center gap-3">
      <div class="googleReview-edit-btn cursor-pointer" data-id=${
        cell.getRow().getData().id
      }>
          <i class="fas fa-edit"></i>
      </div>
      <div class="googleReview-delete-btn cursor-pointer text-red-500" data-id=${
        cell.getRow().getData().id
      }>
        <i class="fas fa-trash-alt"></i>
      </div>
  </div>
  `;
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Author",
      field: "author_name",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Reviews",
      minWidth: "100",
      field: "review_text",
      resizable: true,
      vertAlign: "middle",
      formatter: "textarea",
    },
    {
      title: "ratings",
      field: "review_rating",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Status",
      field: "status",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getValue() === 1) {
          return "Active";
        } else {
          return "Inactive";
        }
      },
    },
    {
      title: "Package",
      hozAlign: "center",
      vertAlign: "middle",
      headerSort: true,
      field: "package",
    },
    {
      title: "Category",
      hozAlign: "center",
      vertAlign: "middle",
      headerSort: true,
      field: "category",
    },
    {
      title: "Is Common",
      hozAlign: "center",
      vertAlign: "middle",
      headerSort: true,
      field: "is_common",
    },

    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  const [categories, setCategories] = useState([]);
  const [packages, setPackages] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [iscommon, setIscommon] = useState();

  const updateFilter = (filter) => {
    let index = selectedFilter.findIndex((sf) => sf.field === filter.field);

    if (index >= 0) {
      if (filter.value && !Array.isArray(filter.value)) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else if (Array.isArray(filter.value) && filter.value[0]) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else {
        setSelectedFilter(
          selectedFilter.filter((af) => af.field !== filter.field)
        );
      }
    } else {
      setSelectedFilter([...selectedFilter, filter]);
    }
  };
  useEffect(() => {
    googleReviewTable.current.setFilter(selectedFilter);
  }, [selectedFilter]);
  useEffect(() => {
    getAllPackages()
      .then((res) => {
        console.log(res.data.data);
        setPackages(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getAllCategories()
      .then((res) => {
        console.log(res.data.data.data);
        setCategories(res.data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    $(document).on("click", ".googleReview-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/google-reviews/edit/${cell}`);
    });

    $(document).on("click", ".googleReview-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      deleteSelectedGoogleReview(dataId);
    });

    return () => {
      $(document).off("click", ".googleReview-edit-btn");
      $(document).off("click", ".googleReview-delete-btn");
    };
  }, []);

  return (
    <div className="">
      <ComponentHeader className="border-b">
        <H2>Google Reviews</H2>
        {/* <Link
          to="/google-reviews/add"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaPlusSquare /> Add New
        </Link> */}
      </ComponentHeader>
      {console.log(selectedFilter, "selected filters")}
      <div
        className={`flex grow flex-wrap items-center gap-4 rounded-md rounded-tl-none`}
      >
        <div className="w-full">
          <InputLabel label="By Package" />
          <SelectSearch
            className="dark:text-black"
            options={packages?.map((packageItem) => ({
              label: packageItem?.title,
              value: packageItem?.id,
            }))}
            value={selectedPackage}
            onChange={(value) => {
              setSelectedPackage(value);
              updateFilter({
                field: "package_id",
                type: "=",
                value: value,
              });
            }}
          />
        </div>
        <div>
          <InputLabel label="In Category" />
          <SelectSearch
            className="dark:text-black"
            options={categories?.map((categoryItem) => ({
              label: categoryItem?.name,
              value: categoryItem?.id,
            }))}
            value={selectedCategory}
            onChange={(value) => {
              setSelectedCategory(value);
              updateFilter({
                field: "category_id",
                type: "=",
                value: value,
              });
            }}
          />
        </div>
        <div>
          <InputLabel label="In Common" />
          <SelectSearch
            className="dark:text-black"
            options={[
              { name: "No", id: "0" },
              { name: "Yes", id: "1" },
            ]?.map((categoryItem) => ({
              label: categoryItem?.name,
              value: categoryItem?.id,
            }))}
            value={iscommon}
            onChange={(value) => {
              setIscommon(`${value}`);
              updateFilter({
                field: "is_common",
                type: "=",
                value: value,
              });
            }}
          />
        </div>
        <div>
          <InputLabel label="Review Text" />
          <input
            type="text"
            className="field"
            onChange={(e) =>
              updateFilter({
                field: "review_text",
                type: "like",
                value: e.target.value,
              })
            }
          />
        </div>
        {/* <div>
          <InputLabel label="Lead owner" />
          <SelectSearch
            options={employees?.map((emp) => ({
              label: `${emp?.first_name} ${emp?.last_name}`,
              value: emp?.id,
            }))}
            onChange={(value) =>
              updateFilter({
                field: "assigned_to",
                type: "=",
                value: value,
              })
            }
          />
        </div>
        <div>
          <InputLabel label="Lead Status" />
          <SelectSearch
            options={leadStatus?.map((lead) => ({
              label: lead?.status,
              value: lead?.id,
            }))}
            onChange={(value) =>
              updateFilter({
                field: "lead_status_id",
                type: "=",
                value: value,
              })
            }
          />
        </div>
        <div>
          <InputLabel label="Name" />
          <input
            type="text"
            className="field"
            onChange={(e) =>
              updateFilter({
                field: "first_name",
                type: "like",
                value: e.target.value,
              })
            }
          />
        </div>
        <div>
          <InputLabel label="Phone" />
          <input
            type="text"
            className="field"
            onChange={(e) =>
              updateFilter({
                field: "phone",
                type: "like",
                value: e.target.value,
              })
            }
          />
        </div>
        <div>
          <InputLabel label="Email" />
          <input
            type="text"
            className="field"
            onChange={(e) =>
              updateFilter({
                field: "email",
                type: "like",
                value: e.target.value,
              })
            }
          />
        </div>
        <div>
          <InputLabel label="Location" />
          <input
            type="text"
            className="field"
            onChange={(e) =>
              updateFilter({
                field: "location",
                type: "keywords",
                value: e.target.value,
              })
            }
          />
        </div>
        <div>
          <InputLabel label="Page URL" />
          <input
            type="text"
            className="field"
            onChange={(e) =>
              updateFilter({
                field: "page_url",
                type: "keywords",
                value: e.target.value,
              })
            }
          />
        </div> */}
      </div>
      <div className="">
        <DataTable
          ref={googleReviewTable}
          ajaxUrl={`${getAdminUrl()}/google-reviews`}
          columns={columns}
          search={false}
          responsiveLayout="collapse"
        />
      </div>
    </div>
  );
}

export default GoogleReviewList;
