import { admin } from "../../ServiceConfig";

export function createCategory(data) {
    return admin.post(`/categories`, data)
}

export function getAllCategories() {
    return admin.get(`/categories`)
}

export function getCategoryById(id) {
    return admin.get(`/categories/${id}`)
}

export function getCategoryPackages(categoryId){
  return admin.get(`/categories/${categoryId}/packages`);
}

export function updateCategory(id, data) {
    return admin.put(`/categories/${id}`, data)
}

export function deleteCategory(locationId) {
    return admin.delete(`/categories/${locationId}`)
}

export function uploadCategoryImage(file) {
    return admin.post(`/uploads/image?upload_path=categories`, file, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}