import { admin } from "../../ServiceConfig";

export function getAllThingsToKnow(query) {
  return admin.get(`/things-to-know?query=${query}`);
}
export function getAllSpecialDatesOption() {
  return admin.get(`/all-special-date`);
}
export function createSpecialDate(data) {
  return admin.post(`/special-date`, data);
}
export function updateSpecialDate(id, data) {
  return admin.put(`/special-date/${id}`, data);
}
export function deleteSpecialDate(id) {
  return admin.delete(`/special-date/${id}`);
}
export function getSpecialDateById(id) {
  return admin.get(`/special-date/${id}`);
}
export function uploadThingsToKnowImage(file) {
  return admin.post(`uploads/image?upload_path=things-to-know`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
