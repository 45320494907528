import { admin } from "../../ServiceConfig";

export function getAllOfficialContacts() {
  return admin.get(`/official-contacts`);
}

export function updateOfficialContacts(data) {
  return admin.post(`/official-contacts`, data);
}

export function deleteOfficialContacts(id) {
  return admin.delete(`/official-contacts/${id}`);
}
