import { admin } from "../../ServiceConfig";

export function getAllB2BList() {
  return admin.get(`/b2b`);
}

export function getB2BbyId(id){
  return admin.get(`b2b/${id}`)
}

export function addB2B(data){
  return admin.post('/b2b', data)
}

export function updateB2BbyId(id, data){
return admin.put(`/b2b/${id}`, data)
}