import React, { useEffect, useState } from "react";
import { FaListAlt } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import H2 from "../../../components/headings/H2";
import ComponentHeader from "../common/ComponentHeader";
import { getStayFeaturesById } from "../../services/StayServices";
import FeaturesForm from "./FeaturesForm";

function EditFeatures() {
  const navigate = useNavigate();

  const featuresId = useParams().id;

  const [featuresData, setFeaturesData] = useState();

  const onEditState = (data) => {
    navigate(`/features`);
  };

  useEffect(() => {
    featuresId &&
    getStayFeaturesById(featuresId)
        .then((res) => {
          setFeaturesData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Edit State | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Features</H2>
        <Link
          to="/features"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {featuresData && (
        <FeaturesForm
          formData={featuresData}
          edit={true}
          onSuccess={(data) => onEditState(data)}
        />
      )}
    </div>
  );
}

export default EditFeatures;
