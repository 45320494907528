import { Outlet } from "react-router-dom";
import MetaTags from "../Metatags";

function CountryWrapper() {
  return (
    <div className="wrapper">
      <MetaTags title={"All Countries"} />
      <div className="h-full max-h-[calc(100%-1rem)] overflow-auto">
        {<Outlet />}
      </div>
    </div>
  );
}

export default CountryWrapper;
