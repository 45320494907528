import { ErrorMessage, Field, Form, Formik } from "formik";

import InputLabel from "../../inputs/InputLabel";
import * as Yup from "yup";
import MultiSelect from "../../inputs/MultiSelect";
import EditorInput from "../../inputs/EditorInput";
import KeywordsInput from "../../inputs/KeywordsInput";

import {
  getAllBlogs,
  getAllPackages,
  getDifficulties,
  updatePackage,
} from "../../../services/PackageService";
import usePackageData from "../../../hooks/usePackageData";
import { useEffect } from "react";
import { useState } from "react";
import SelectSearch from "../../inputs/SelectSearch";

function Step6({ onSuccess }) {
  const { packageData, setPackageData } = usePackageData();
  const [difficulties, setDifficulties] = useState();
  const [blogs, setBlogs] = useState([]);
  const [allPackages, setAllPackages] = useState([]);

  const packageDetailSchema = Yup.object().shape({
    related_packages: Yup.array().max(5),
    related_blogs: Yup.array().max(5),
    // payment_policy: Yup.string().required("Required"),
    // cancellation_policy: Yup.string().required("Required"),
  });

  const updatePackageDetail = (data) => {
    updatePackage(packageData.package.id, data).then((res) => {
      setPackageData((packageData) => ({
        ...packageData,
        package: res.data.data,
      }));
      onSuccess();
    });
  };

  useEffect(() => {
    getDifficulties()
      .then((res) => {
        setDifficulties(res.data.data);
      })
      .catch((err) => {
        console.log(err, "erererer----------");
      });
  }, []);

  useEffect(() => {
    getAllBlogs().then((res) => {
      setBlogs(res.data);
    });

    getAllPackages()
      .then((res) => {
        setAllPackages(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        altitude: packageData?.package?.altitude,
        trek_length: packageData?.package?.trek_length,
        trek_difficulty_id: packageData?.package?.trek_difficulty_id,
        base_camp: packageData?.package?.base_camp,
        nearest_town: packageData?.package?.nearest_town,
        best_time_to_visit: packageData?.package?.best_time_to_visit,
        // payment_policy: packageData?.package?.payment_policy,
        // cancellation_policy: packageData?.package?.cancellation_policy,
        blog_ids: packageData?.package?.blog_ids,
        related_packages: packageData?.package?.related_packages,
        youtube_videos: packageData?.package?.youtube_videos || [],
        insta_ids: packageData?.package?.insta_ids || [],
      }}
      validationSchema={packageDetailSchema}
      onSubmit={(values) => {
        // alert(JSON.stringify(values, null, 2));
        updatePackageDetail(values);
      }}
    >
      {({ values, setFieldValue, handleSubmit, errors, touched }) => (
        <Form>
          <div className="my-4">
            <h2 className="text-lg font-bold">Package Details</h2>
          </div>
          <div className="my-4 flex flex-wrap gap-4">
            {/* // ? Altitude */}
            <div className="grow basis-full lg:basis-[45%]">
              <InputLabel
                label="Altitude From Sea Level"
                labelFor="altitude"
                instruction="(Enter Value in Feet)"
              />
              <Field className="field" id="altitude" name="altitude" />
              <ErrorMessage name="altitude">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
            {/* //? Trek Length */}
            <div className="grow basis-full lg:basis-[45%]">
              <InputLabel
                label="Trek Length"
                labelFor="trek_length"
                instruction="(Enter Value in Km)"
              />
              <Field className="field" id="trek_length" name="trek_length" />
              <ErrorMessage name="trek_length">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
          </div>

          <div className="my-4 flex flex-wrap gap-4">
            {/* //? Trek Difficulty */}
            <div className="grow basis-full lg:basis-[45%]">
              <InputLabel
                label="Select Trek Difficulty"
                labelFor="trek_difficulty_id"
              />
              <SelectSearch
                onChange={(value) => setFieldValue("trek_difficulty_id", value)}
                value={values.trek_difficulty_id}
                options={difficulties?.map((difficulty) => ({
                  label: difficulty.name,
                  value: difficulty.id,
                }))}
              />

              <ErrorMessage name="trek_difficulty_id">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>

            {/* //? Base Camp */}
            <div className="grow basis-full lg:basis-[45%]">
              <InputLabel label="Base Camp" labelFor="base_camp" />
              <Field className="field" id="base_camp" name="base_camp" />
              <ErrorMessage name="base_camp">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
          </div>

          <div className="my-4 flex flex-wrap gap-4">
            {/* //? Nearest Town */}
            <div className="grow basis-full lg:basis-[45%]">
              <InputLabel label="Nearest Town" labelFor="nearest_town" />
              <Field className="field" id="nearest_town" name="nearest_town" />
              <ErrorMessage name="nearest_town">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>

            {/* //? Best Months To Visit */}
            <div className="grow basis-full lg:basis-[45%]">
              <InputLabel label="Best Time to Visit" />
              <Field type="text" className="field" name="best_time" />
              <ErrorMessage name="best_time">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
          </div>

          <div className="my-4 flex flex-wrap gap-4">
            {/* //? Related Blogs */}
            <div className="grow basis-full lg:basis-[45%]">
              <InputLabel label="Related Blogs" />
              <MultiSelect
                onChange={(value) => setFieldValue("blog_ids", value)}
                value={Array.isArray(values.blog_ids) && values.blog_ids}
                options={blogs?.map((blog) => ({
                  label: blog?.title,
                  value: blog.id,
                }))}
              />

              <ErrorMessage name="blog_ids">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>

            {/* //? Related Packages */}
            <div className="grow basis-full lg:basis-[45%]">
              <InputLabel label="Related Packages" />
              <MultiSelect
                onChange={(value) => setFieldValue("related_packages", value)}
                value={
                  Array.isArray(values.related_packages) &&
                  values.related_packages
                }
                options={allPackages.map((pkg) => ({
                  label: pkg.title,
                  value: pkg.id,
                }))}
              />

              <ErrorMessage name="related_packages">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
          </div>

          <div className="flex gap-4">
            <div className="grow basis-full lg:basis-[45%]">
              <InputLabel label="Youtube Video Ids" />
              <KeywordsInput
                value={
                  Array.isArray(values.youtube_videos)
                    ? values.youtube_videos?.map((v) => ({
                        label: v,
                        value: v,
                      }))
                    : []
                }
                onChange={(value) =>
                  setFieldValue(
                    "youtube_videos",
                    value.map((v) => v.value)
                  )
                }
              />
              <ErrorMessage name="youtube_videos">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="grow basis-full lg:basis-[45%]">
              <InputLabel label="Insta Post Ids" />
              <KeywordsInput
                value={
                  Array.isArray(values.insta_ids)
                    ? values.insta_ids?.map((v) => ({
                        label: v,
                        value: v,
                      }))
                    : []
                }
                onChange={(value) =>
                  setFieldValue(
                    "insta_ids",
                    value.map((v) => v.value)
                  )
                }
              />
              <ErrorMessage name="insta_ids">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
          </div>

          {/* //? payment Policy */}
          {/* <div className="my-4">
            <InputLabel label="Payment Policy" />
            <EditorInput fieldKey="payment_policy" />
          </div> */}

          {/* //? cancellation Policy */}
          {/* <div className="my-4">
            <InputLabel label="Cancellation Policy" />
            <EditorInput fieldKey="cancellation_policy" />
          </div> */}

          <div className="my-4 text-center">
            <button
              type="button"
              onClick={handleSubmit}
              className="btn-primary"
            >
              submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default Step6;
