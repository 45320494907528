import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import MetaTags from "../../../web/layouts/MetaTags";
import { getRoleById } from "../../services/RoleServices";
import RoleForm from "./RoleForm";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";
import CollapsibleCard from "./CollapsibleCard";

function EditRole() {
  const navigate = useNavigate();

  const roleId = useParams().id;

  const [role, setRole] = useState();

  const onEditRole = (data) => {
    navigate(`/roles`);
  };

  useEffect(() => {
    roleId &&
      getRoleById(roleId)
        .then((res) => {
          setRole(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Edit Role | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Role</H2>
        <Link
          to="/roles"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {role && (
        <RoleForm
          formData={role}
          edit={true}
          onSuccess={(data) => onEditRole(data)}
        />
      )}
    </div>
  );
}

export default EditRole;
