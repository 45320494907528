import { useEffect } from "react";
import { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { toast } from "react-toastify";
import { cropImage } from "../../services/CommonServices";
import getCroppedImg from "./cropImage";
import { getUploadsUrl } from "../../../ServiceConfig";

function ImageEditor({
  isImageEditorOpen,
  setIsImageEditorOpen,
  source,
  cropRatios,
  onImageCrop,
}) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [activeRatio, setActiveRatio] = useState(cropRatios[0]);
  const [activeRatioIndex, setActiveRatioIndex] = useState(0);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleSubmit = () => {
    let data = {
      uploaded_image: source,
      new_path: activeRatio.path,
      specification: croppedAreaPixels,
    };

    cropImage(data)
      .then((res) => {
        toast.success("Image Cropped successfully.");
        onImageCrop();
      })
      .catch((err) => {
        console.log(err, "erererre-----------");
      });

    if (activeRatioIndex === cropRatios.length - 1)
      return setIsImageEditorOpen(false);
    setActiveRatioIndex(activeRatioIndex + 1);
  };

  useEffect(() => {
    setActiveRatio(cropRatios[activeRatioIndex]);
  }, [activeRatioIndex]);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(source, croppedAreaPixels);
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  return (
    <div>
      <div className="flex gap-8">
        <div className="basis-[30%]">
          <h3 className="mb-4 text-lg font-bold">{activeRatio.label}</h3>
          <img src={activeRatio.sample} alt="" className="w-full border-2" />
          <ul className="mt-4 list-disc pl-6">
            <li>Drag Image To adjust</li>
            <li>Pinch image to Zoom</li>
          </ul>
        </div>
        <div className="h-full w-full basis-[70%]">
          <div className="relative h-[500px] max-h-[60vh] w-full">
            <Cropper
              image={`${getUploadsUrl()}/${source}`}
              crop={crop}
              zoom={zoom}
              aspect={activeRatio.x / activeRatio.y}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>

          <div className="mt-4 text-center">
            <button className="btn-primary" onClick={handleSubmit}>
              Crop
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageEditor;
