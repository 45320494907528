import useSideBarToggle from "../../hooks/useSideBarToggle";
import HeaderMenuWrapper from "./HeaderMenuWrapper";
import HeaderWidgetWrapper from "./HeaderWidgetWrapper";
import { MdDarkMode } from "react-icons/md";
import { MdLightMode } from "react-icons/md";
import useTheme from "../../hooks/useTheme";

function HeaderWrapper() {
  const { lockSidebar, sidebarOpen, setSideBarOpen, toggleSidebar } =
    useSideBarToggle();
  const { theme, setTheme } = useTheme();
  // const handleChangeTheme = () => {
  //   document.getElementsByTagName("html")[0].classList.toggle("dark");
  //   if (theme && theme === "dark") {
  //     setTheme("light");
  //     localStorage.setItem("theme", "light");
  //   } else {
  //     setTheme("dark");
  //     localStorage.setItem("theme", "dark");
  //   }
  // };
  return (
    <>
      <div className="hidden h-12 items-center justify-between dark:bg-slate-900 dark:text-white lg:flex">
        <HeaderMenuWrapper />
        <div className=" flex">
          {/* <div
            className="flex cursor-pointer items-center justify-center rounded-xl border-[3px] border-slate-200  p-2 hover:bg-slate-50 hover:dark:bg-slate-800"
            onClick={handleChangeTheme}
          >
            {theme === "light" ? (
              <MdDarkMode size={22} />
            ) : (
              <MdLightMode size={22} />
            )}
          </div> */}
          <HeaderWidgetWrapper />
        </div>
      </div>
      <div className="flex h-12 items-center justify-between px-4 dark:bg-slate-900 dark:text-white lg:hidden">
        <div className="" onClick={toggleSidebar}>
          <div className="my-[3px] h-1 w-6 bg-gray-900 dark:bg-white"></div>
          <div className="my-[3px] h-1 w-6 bg-gray-900 dark:bg-white"></div>
          <div className="my-[3px] h-1 w-6 bg-gray-900 dark:bg-white"></div>
        </div>

        <div className="flex gap-4">
          {/* <div className="border-[3px] border-slate-200 flex rounded-xl justify-center items-center p-2  cursor-pointer hover:bg-slate-50 hover:dark:bg-slate-800" onClick={handleChangeTheme}>
            {theme === 'light' ? <MdDarkMode size={22} /> : <MdLightMode size={22} />}
          </div> */}
          <HeaderWidgetWrapper className="px-0" />
        </div>
      </div>
    </>
  );
}

export default HeaderWrapper;
