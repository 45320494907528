import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import usePackageData from "../../../hooks/usePackageData";
import { updatePackage } from "../../../services/PackageService";
import EditorInput from "../../inputs/EditorInput";
import InputLabel from "../../inputs/InputLabel";
import KeywordsInput from "../../inputs/KeywordsInput";

const packageMetaInfoSchema = Yup.object().shape({
  meta_title: Yup.string().required("Required"),
  // meta_description: Yup.string().required("Required"),
  // meta_keywords: Yup.array().max(10).required("Required"),
});

function Step8({ onSuccess }) {
  const { packageData, setPackageData } = usePackageData();

  const updatePackageMeta = (values) => {
    updatePackage(packageData.package.id, values).then((res) => {
      setPackageData((packageData) => ({
        ...packageData,
        package: res.data.data,
      }));
      onSuccess();
    });
  };

  return (
    <div className="my-4">
      <h2 className="text-lg font-bold">Package Meta Info</h2>
      <Formik
        initialValues={{
          meta_title: packageData?.package?.meta_title,
          meta_description: packageData?.package?.meta_description,
          meta_keywords: packageData?.package?.meta_keywords,
          canonical_urls: [""],
        }}
        validationSchema={packageMetaInfoSchema}
        onSubmit={(values) => {
          // alert(JSON.stringify(values, null, 2));
          updatePackageMeta(values);
          //   edit ? editLocation(values) : addLocation(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="my-4 flex flex-wrap gap-4">
              {/* // ? Meta Title */}
              <div className="grow basis-full lg:basis-[45%]">
                <InputLabel label="Meta Title" />
                <Field name="meta_title" className="field" />
                <ErrorMessage name="meta_title">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              {/* //? Status */}
              {/* <div className="grow basis-full lg:basis-[45%]">
                <div role="group" aria-labelledby="my-radio-group">
                  <InputLabel label="Status" />
                  <div className="flex items-center gap-12">
                    <label className="flex items-baseline gap-1">
                      <Field type="radio" name="status" value="1" />
                      Active
                    </label>
                    <label className="flex items-baseline gap-1">
                      <Field type="radio" name="status" value="0" />
                      Inactive
                    </label>
                  </div>
                </div>
              </div> */}
            </div>

            {/* //? Package Description */}
            <div className="mb-4">
              <InputLabel label="Meta Description" />
              <EditorInput
                fieldKey="meta_description"
                data={values.meta_description}
              />
            </div>

            {/* //? Meta Keywords */}
            <div className="my-4">
              <InputLabel
                label="Meta Keywords"
                instruction="(Max 10 Keywords)"
              />
              <KeywordsInput
                value={
                  values?.meta_keywords
                    ? values?.meta_keywords?.split(",")?.map((v) => ({
                        label: v,
                        value: v,
                      }))
                    : []
                }
                onChange={(value) =>
                  setFieldValue(
                    "meta_keywords",
                    value.map((v) => v.value).toString()
                  )
                }
              />
            </div>

            {/* //? Canonical Url */}
            <div className="my-4">
              <InputLabel
                label="Canonical Urls"
                instruction="(Comma Separated links)"
              />
              <Field name="canonical_urls" className="field" />
              <ErrorMessage name="canonical_urls">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>

            <div className="my-4 text-center">
              <button type="submit" className="btn-primary">
                submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Step8;
