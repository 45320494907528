import "react-dual-listbox/lib/react-dual-listbox.css";
import "../../../assets/css/trips.css";

import H2 from "../../../components/headings/H2";
import ComponentHeader from "../common/ComponentHeader";
import InputLabel from "../inputs/InputLabel";
import SelectSearch from "../inputs/SelectSearch";
import { useState } from "react";
import {
  assignPackagesToEmployee,
  getAllEmployeesOption,
  getEmployeeAssignedPackages,
} from "../../services/EmployeeServices";
import { useEffect } from "react";
import { getUnassignedPackages } from "../../services/PackageService";
import DualListBox from "react-dual-listbox";
import {
  FaBackward,
  FaChevronLeft,
  FaChevronRight,
  FaForward,
} from "react-icons/fa";
import { toast } from "react-toastify";

function AssignPacakgeToExecutive() {
  const [selectedEmployee, setSelectedEmployee] = useState();

  const [employees, setEmployees] = useState([]);

  const [selectedPackage, setSelectedPackage] = useState([]);

  const [unassignedPackages, setUnassignedPackages] = useState([]);

  useEffect(() => {
    getAllEmployeesOption().then((res) => {
      setEmployees(res.data.data.data);
    });

    getUnassignedPackages().then((res) => {
      setUnassignedPackages(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (!selectedEmployee) return;

    const getAssignedPkg = async () => {
      try {
        const res = await getEmployeeAssignedPackages(selectedEmployee);
        return res.data.data.map((pkg) => ({
          id: pkg.id,
          title: pkg.title,
        }));
      } catch (error) {
        console.log(error);
      }
    };

    const getUnassignedPkg = async () => {
      try {
        const res = await getUnassignedPackages();
        return res.data.data;
      } catch (error) {
        console.log(error);
      }
    };

    const updateList = async () => {
      const [unassignedPkg, assignedPkg] = await Promise.all([
        getUnassignedPkg(),
        getAssignedPkg(),
      ]);

      setUnassignedPackages([...unassignedPkg, ...assignedPkg]);

      let selectedIds = assignedPkg.map((pkg) => pkg.id);

      setSelectedPackage(selectedIds);
    };

    updateList();
  }, [selectedEmployee]);

  const assignPackages = () => {
    assignPackagesToEmployee({
      package_ids: selectedPackage,
      employee_id: selectedEmployee,
    })
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className="wrapper">
      {/* <div className="h-full max-h-[calc(100%-1rem)] overflow-auto"> */}
        <ComponentHeader className="border-b">
          <H2>Assign Package To Executive</H2>
        </ComponentHeader>

        <div className="my-4">
          <div>
            <InputLabel label="Select Executive" />
            <SelectSearch
              options={employees.map((employee) => ({
                value: employee.id,
                label: `${employee.first_name} ${employee.last_name}`,
              }))}
              value={selectedEmployee}
              onChange={(value) => {
                setSelectedEmployee(value);
              }}
            />
          </div>

          <div className="my-4">
            <DualListBox
              canFilter={true}
              icons={{
                moveLeft: <FaChevronLeft />,
                moveAllLeft: <FaBackward />,
                moveRight: <FaChevronRight />,
                moveAllRight: <FaForward />,
              }}
              showHeaderLabels={true}
              className="lg:h-[300px]"
              options={unassignedPackages.map((pkg) => ({
                value: pkg.id,
                label: pkg.title,
                className: "bg-green",
              }))}
              selected={selectedPackage}
              onChange={(value) => {
                setSelectedPackage(value);
              }}
            />
          </div>

          <div className="text-center">
            <button onClick={assignPackages} className="btn-primary">
              Submit
            </button>
          </div>
        </div>
      {/* </div> */}
    </div>
  );
}

export default AssignPacakgeToExecutive;
