import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import { getAllEmployeesOption } from "../../services/EmployeeServices";
import { getReadableDate } from "../../../helpers/Helper";
import ComponentHeader from "../common/ComponentHeader";
import H2 from "../../../components/headings/H2";
import InputLabel from "../inputs/InputLabel";
import DateRangeInput from "../inputs/DateRangeInput";
import SelectSearch from "../inputs/SelectSearch";
import DataTable from "../common/DataTable";
import { getAdminUrl, getUploadsUrl } from "../../../ServiceConfig";
import moment from "moment";
import useTheme from "../../hooks/useTheme";

function TeamList() {
  const { theme, setTheme } = useTheme();
  const navigate = useNavigate();
  const stateTable = useRef();
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    getAllEmployeesOption()
      .then((res) => {
        setEmployees(res.data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    stateTable.current.setFilter(selectedFilter);
  }, [selectedFilter]);

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `<div class="employee-edit-btn" data-id=${
      cell.getRow().getData().id
    } style="display: flex; align-items: center; gap: 12px;">
    <div style="cursor: pointer; title="Edit">
    <svg
      x="0px"
      y="0px"
      width="16px"
      height="16px"
      viewBox="0 0 497.182 497.182"
      xml:space="preserve"
    >
      <g>
        <path
          d="M376.956,237.143l58.504-57.637v255.468c0,34.352-27.852,62.207-62.207,62.207H62.209
  c-34.352,0-62.207-27.855-62.207-62.207V123.932c0-34.355,27.855-62.211,62.207-62.211h255.453l-59.848,58.234H58.597v318.359
  h318.359V237.143z M377.469,56.755l62.967,62.985l20.993-20.986l-62.968-62.985L377.469,56.755z M434.211,0l-20.989,20.99
  l62.968,62.968l20.989-20.989L434.211,0z M171.288,262.987l188.91-188.953l62.969,62.984l-188.91,188.938l-91.703,28.677
  L171.288,262.987z M202.596,265.369l10.77,10.953l161.938-159.184l-10.77-10.953L202.596,265.369z"
        />
      </g>
    </svg></div>
  </div>`;
  };

  const updateFilter = (filter) => {
    let index = selectedFilter.findIndex((sf) => sf.field === filter.field);

    if (index >= 0) {
      if (filter.value && !Array.isArray(filter.value)) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else if (Array.isArray(filter.value) && filter.value[0]) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else {
        setSelectedFilter(
          selectedFilter.filter((af) => af.field !== filter.field)
        );
      }
    } else {
      setSelectedFilter([...selectedFilter, filter]);
    }
  };

  const columns = [
    {
      title: "Employee Code",
      field: "employee_id",
      responsive: 0,
      width: "70",
      resizable: true,
      vertAlign: "middle",
    },
    // {
    //   title: "Id",
    //   field: "id",
    //   responsive: 0,
    //   width: "50",
    //   resizable: true,
    //   vertAlign: "middle",
    // },
    {
      title: "Profile Pic",
      field: "profile_pic",
      minWidth: "70",
      vertAlign: "middle",
      hozAlign: "center",

      formatter: function (cell, formatterParams, onRendered) {
        const value = cell.getValue();
        const urlPrefix = `${getUploadsUrl()}/employees/`;
        const fallbackImage = ""; // Specify the path to your fallback image

        const imageUrl =
          value != null ? `${urlPrefix}${value}` : `${urlPrefix}missing.jpg`;

        return `<div class="center"><img src="${imageUrl}" style="width:auto;height:50px"/></div>`;
      },
    },
    {
      title: "Name",
      field: "name",
      minWidth: "170",
      vertAlign: "middle",
      hozAlign: "center",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      formatter: function (cell, formatterParams, onRendered) {
        return `${cell.getRow().getData().first_name} ${
          cell.getRow().getData().last_name
        }`;
      },
      resizable: true,
    },
    {
      title: "Email",
      minWidth: "150",
      width: "260",
      field: "email",
      resizable: true,
      responsive: 0,
      vertAlign: "middle",
      hozAlign: "center",
    },
    {
      title: "Phone",
      field: "phone",
      responsive: 0,
      minWidth: "100",
      vertAlign: "middle",
      hozAlign: "center",
    },
    {
      title: "Gender",
      field: "gender",
      responsive: 0,
      minWidth: "100",
      vertAlign: "middle",
      hozAlign: "center",
    },
    {
      title: "Department",
      field: "department_name",
      resizable: true,
      headerSort: false,
      responsive: 0,
      minWidth: "120",
      vertAlign: "middle",
      hozAlign: "center",
    },

    {
      title: "Official Email",
      field: "official_contact.email",
      headerSort: false,
      responsive: 0,
      minWidth: "270",
      vertAlign: "middle",
      hozAlign: "center",
    },
    {
      title: "Official Phone",
      field: "official_contact.phone",
      headerSort: false,
      responsive: 0,
      minWidth: "110",
      vertAlign: "middle",
      hozAlign: "center",
    },

    {
      title: "Birthday",
      minWidth: "120",
      field: "dob",
      resizable: true,
      responsive: 0,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: function (cell, formatterParams, onRendered) {
        let v = cell.getValue();
        return v != "" && v != null ? moment(v).format("Do MMMM") : "";
      },
    },

    // {
    //   title: "Employment Type",
    //   field: "employment_type",
    //   resizable: true,
    //   headerSort: false,
    //   responsive: 0,
    //   vertAlign: "middle",
    //   minWidth: "140",
    // },

    // {
    //   formatter: actionIcons,
    //   headerSort: false,
    //   width: 80,
    //   responsive: 0,
    //   hozAlign: "center",
    //   responsive: 0,
    //   vertAlign: "middle",
    // },
  ];

  useEffect(() => {
    $(document).on("click", ".employee-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/employees/edit/${cell}`);
    });

    return () => {
      $(document).off("click", ".employee-edit-btn");
    };
  }, []);

  //   const rowClick = (data) => {
  //     navigate(`/employees/edit/${data.id}`);
  //   };

  return (
    <div className="flex h-full flex-col dark:bg-slate-900 dark:text-white">
      <ComponentHeader className="border-b">
        <H2>Team</H2>
      </ComponentHeader>
      <div className="h-full">
        {/* <div className="my-8 rounded-md">
          <button
            onClick={() => setFilterOpen(!filterOpen)}
            className={` inline-block whitespace-nowrap rounded-md border bg-white p-8 py-2 text-lg font-semibold tracking-wide ${
              filterOpen
                ? "border-b-1 -mb-[1px] rounded-b-none px-8 pb-1 pt-2"
                : ""
            }`}
          >
            Filters
          </button>
          <div
            className={`flex grow flex-wrap  items-center gap-4 rounded-md rounded-tl-none bg-white ${
              filterOpen ? "mb-4 border p-4" : "h-0 overflow-hidden p-0"
            }`}
          >
            <div>
              <InputLabel label="First Name" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "first_name",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="Last Name" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "last_name",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="Personal Email" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "email",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="Personal Phone" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "phone",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="Official Email" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "official_email",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="Official Phone" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "official_phone",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Joining Date" />
              <DateRangeInput
                controlBtns={true}
                horizontalPosition="left"
                onRangeChange={(startDate, endDate) =>
                  updateFilter({
                    field: "joining_date",
                    type: "in",
                    value: [
                      startDate
                        ? `${new Date(startDate).getFullYear()}-${
                            new Date(startDate).getMonth() + 1
                          }-${new Date(startDate).getDate()}`
                        : "",
                      endDate
                        ? `${new Date(endDate).getFullYear()}-${
                            new Date(endDate).getMonth() + 1
                          }-${new Date(endDate).getDate()}`
                        : "",
                    ],
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Birth Date" />
              <DateRangeInput
                controlBtns={true}
                horizontalPosition="left"
                onRangeChange={(startDate, endDate) =>
                  updateFilter({
                    field: "dob",
                    type: "in",
                    value: [
                      startDate
                        ? `${new Date(startDate).getFullYear()}-${
                            new Date(startDate).getMonth() + 1
                          }-${new Date(startDate).getDate()}`
                        : "",
                      endDate
                        ? `${new Date(endDate).getFullYear()}-${
                            new Date(endDate).getMonth() + 1
                          }-${new Date(endDate).getDate()}`
                        : "",
                    ],
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Reporting Manager" />
              <SelectSearch
                value={
                  selectedFilter.find(
                    (filter) => filter.field === "reporting_manager"
                  )?.value
                }
                options={employees?.map((emp) => ({
                  label: `${emp?.first_name} ${emp?.last_name}`,
                  value: emp?.id,
                }))}
                onChange={(value) =>
                  updateFilter({
                    field: "reporting_manager",
                    type: "=",
                    value: value,
                  })
                }
              />
            </div>

            <div className="min-w-[250px]">
              <InputLabel label="Current Status" />
              <SelectSearch
                value={
                  selectedFilter.find((filter) => filter.field === "status")
                    ?.value
                }
                options={[
                  { label: "Active", value: 1 },
                  { label: "Inactive", value: 0 },
                ]}
                onChange={(value) =>
                  updateFilter({
                    field: "status",
                    type: "=",
                    value: value,
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Gender" />
              <SelectSearch
                value={
                  selectedFilter.find((filter) => filter.field === "gender")
                    ?.value
                }
                options={[
                  { label: "Female", value: "Female" },
                  { label: "Male", value: "Male" },
                ]}
                onChange={(value) =>
                  updateFilter({
                    field: "gender",
                    type: "=",
                    value: value,
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Employement Type" />
              <SelectSearch
                value={
                  selectedFilter.find(
                    (filter) => filter.field === "employment_type"
                  )?.value
                }
                options={[
                  { label: "Full Time", value: "full time" },
                  { label: "Part Time", value: "part time" },
                ]}
                onChange={(value) =>
                  updateFilter({
                    field: "employment_type",
                    type: "=",
                    value: value,
                  })
                }
              />
            </div>
          </div>
        </div> */}
        <DataTable
          ref={stateTable}
          ajaxUrl={`${getAdminUrl()}/team`}
          columns={columns}
          search={false}
          //   rowClick={rowClick}
          responsiveLayout=""
          initialSort={[
            {
              column: "employee_id",
              dir: "asc",
            },
          ]}
        />
      </div>
    </div>
  );
}

export default TeamList;
