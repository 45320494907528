import React from "react";
import * as ReactDOMClient from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AdminAuthProvider } from "./admin/context/AdminAuthProvider";
import { LoaderProvider } from "./context/LoaderProvider";
import { ScreenSizeProvider } from "./context/ScreenSizeProvider";
import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "./admin/context/ThemeProvider";

const root = ReactDOMClient.createRoot(document.getElementById("root"));

const helmetContext = {};

root.render(
  <BrowserRouter>
    <LoaderProvider>
      <ScreenSizeProvider>
        <AdminAuthProvider>
          <ThemeProvider>
          <HelmetProvider context={helmetContext}>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </HelmetProvider>
          </ThemeProvider>
        </AdminAuthProvider>
      </ScreenSizeProvider>
    </LoaderProvider>
  </BrowserRouter>
);
// </React.StrictMode>

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
