import React, { useEffect, useState } from "react";
import ComponentHeader from "../common/ComponentHeader";
import { Link } from "react-router-dom";
import H2 from "../../../components/headings/H2";
import { getUpcomingPackages } from "../../services/PackageService";
import moment from "moment/moment";

const UpcomingPackages = () => {
  const [upcomingPackages, setUpComingPackages] = useState([]);
  const [packageNameList, setPackageNameList] = useState([]);
  const [uniquePackageNames, setUniquePackageNames] = useState([]);
  const [listOfDates, setListOfDates] = useState([]);

  // getting all packages
  useEffect(() => {
    getUpcomingPackages()
      .then((r) => {
        setUpComingPackages(r.data.data);
      })
      .catch((e) => {
        console.log("error in fetching upcoming packages" + e);
      });

    return () => setUpComingPackages([]);
  }, []);

  // getting list of all package names and list of all dates
  useEffect(() => {
    if (upcomingPackages) {
      for (const key in upcomingPackages) {
        setListOfDates((prev) => [...prev, key]);
        upcomingPackages[key].map((onePackage) => {
          setPackageNameList((prev) => [...prev, onePackage.package_name]);
        });
      }
    }
    return () => {
      setListOfDates([]);
      setPackageNameList([]);
    };
  }, [upcomingPackages]);

  // getting list of unique package names
  useEffect(() => {
    setUniquePackageNames([...new Set(packageNameList)]);
    return () => setUniquePackageNames([]);
  }, [packageNameList]);

  const getNumberOfBookedPackages = (date, name) => {
    let ans;
    for (const key in upcomingPackages) {
      upcomingPackages[key].map((onePackage) => {
        if (key === date && onePackage.package_name === name) {
          ans = onePackage;
        }
      });
    }
    return ans;
  };
  return (
    <div className="flex h-full flex-col">
      <ComponentHeader className="border-b px-0 dark:text-white lg:px-4">
        <Link to="/package/upcoming-events">
          <H2>Upcoming Events</H2>
        </Link>
      </ComponentHeader>

      <div className="overflow-x-auto">
        <table className="order w-full rounded-t-lg rounded-b-lg">
          <tr className="bg-white">
            <th
              style={{ minWidth: "20vw" }}
              className="sticky top-0 left-0 z-20 border-r border-gray-400 bg-[#0183c0] p-1 text-lg text-white dark:border-gray-600 dark:bg-gray-800 dark:text-white"
            >
              Package Name
            </th>
            {listOfDates &&
              listOfDates.map((date) => (
                <th
                  style={{ minWidth: "50px" }}
                  className="sticky top-0 z-10 border-r border-gray-400 bg-[#0183c0] text-white dark:border-gray-600 dark:bg-gray-800 dark:text-white"
                >
                  {moment(date).format("DD MMM")}
                </th>
              ))}
          </tr>
          {uniquePackageNames.map((singlePackageName, index) => (
            <tr>
              <td
                className={`sticky left-0 w-1/2 border-r border-gray-400 p-1 px-3 font-medium dark:border-gray-600 md:w-3 ${
                  index % 2 === 0
                    ? "bg-white dark:bg-gray-500 dark:text-white"
                    : "bg-[#cce9f3] dark:bg-gray-700 dark:text-white"
                }`}
              >
                {singlePackageName}
              </td>
              {listOfDates.map((singleDate) => (
                <td
                  style={{
                    color:
                      getNumberOfBookedPackages(singleDate, singlePackageName)
                        ?.booked_slots > 0
                        ? "green"
                        : "gray",
                  }}
                  className={`w-1/16 border-r border-gray-400 p-1 py-3 text-center font-semibold dark:border-gray-600 ${
                    index % 2 === 0
                      ? "bg-white dark:bg-gray-500 dark:text-white"
                      : "bg-[#cce9f3] dark:bg-gray-700 dark:text-white"
                  }`}
                >
                  <Link
                    to={`/bookings/participants?package_id=${
                      getNumberOfBookedPackages(singleDate, singlePackageName)
                        ?.package_id
                    }&event_date=${singleDate}`}
                    target="_blank"
                  >
                    {getNumberOfBookedPackages(singleDate, singlePackageName)
                      ? ` ${
                          getNumberOfBookedPackages(
                            singleDate,
                            singlePackageName
                          )?.booked_slots
                        } /
                       ${
                         getNumberOfBookedPackages(
                           singleDate,
                           singlePackageName
                         )?.total_slots
                       }`
                      : ``}
                  </Link>
                </td>
              ))}
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};

export default UpcomingPackages;
