import { admin } from "../../ServiceConfig";

export function getAllRewards() {
    return admin.get(`/rnr-cat`)
}

export function createRewards(data) {
    return admin.post(`/rnr-cat`, data)
}

export function getRewardById(id) {
    return admin.get(`/rnr-cat/${id}`)
}

export function updateRewardById(id, data) {
    return admin.put(`/rnr-cat/${id}`, data)
}

export function deleteRewardById(id) {
    return admin.delete(`/rnr-cat/${id}`)
}

export function createRnR(data) {
    return admin.post(`/rnr`, data)
}

export function updateRnRById(id, data) {
    return admin.put(`/rnr/${id}`, data)
}

export function getRnRBymonthyear(month, year) {
    return admin.get(`/rnr?month=${month}&year=${year}`)
}

export function uploadRewardImage(file) {
    return admin.post(
      `uploads/image?upload_path=rnr-cat`,
      file,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

export function saveDates(data){
    return admin.post(`/`, data)
}

export function updateDates(data){
    return admin.put(`/`, data)
}

export function getWinnersById(id){
    return admin.get(`rnr-winner/${id}`)
}

export function getRnRById(id){
    return admin.get(`rnr/${id}`)
}

export function createWinners(data){
    return admin.post(`rnr-win`, data)
}

export function deleteWinner(id){
    return admin.delete(`rnr-win/${id}`)
}

export function getRnRDashboard(){
    return admin.get(`/rnr-winner-thisMonth`)
}

export function getRNRCategorieswithoutPagination(){
    return admin.get(`/all-rnr-cat`)
}