import React, { useEffect, useState } from "react";
import FileInput from "../../../components/inputFields/FileInput";

import {
  updateCountry,
  uploadCountryImage,
} from "../../services/CountryServices";
import InputLabel from "../inputs/InputLabel";
import useLoader from "../../../hooks/useLoader";
import { toast } from "react-toastify";
import { createCountry } from "../../services/CountryServices";
import { getUploadsUrl } from "../../../ServiceConfig";

// -----------------------------------------------------------
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import KeywordsInput from "../inputs/KeywordsInput";

const countryFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  status: Yup.number().required("Required"),
  meta_title: Yup.string()
    .nullable()
    .max(70, "Meta Title should not more than 70 characters"),
  meta_description: Yup.string()
    .max(160, "Meta Description should not more than 160 characters")
    .nullable(),
  meta_keywords: Yup.array().of(Yup.string().nullable()).max(10).nullable(),
});

function CountryForm({ formData, edit, onSuccess }) {
  // ? Add new country
  const addCountry = (values) => {
    let data = Object.assign({}, values);
    createCountry(data)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  // ? Update country
  const editCountry = (values) => {
    let data = Object.assign({}, values);
    updateCountry(formData.id, data)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div>
      <Formik
        initialValues={{
          banner_image: formData?.banner_image,
          name: formData?.name,
          tag_line: formData?.tag_line,

          status: formData?.status.toString() || "1",
          description: formData?.description,
          meta_title: formData?.meta_title,
          meta_description: formData?.meta_description,
          meta_keywords: formData?.meta_keywords,
        }}
        validationSchema={countryFormSchema}
        onSubmit={(values) => {
          edit ? editCountry(values) : addCountry(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="flex flex-wrap gap-4">
              {/* //? Banner Image */}
              <div className="grow">
                <FileInput
                  noOfFilesAllowed={1}
                  value={`${getUploadsUrl()}/countries/${values.banner_image}`}
                  onChange={(file) => {
                    uploadCountryImage({ image: file }).then((res) => {
                      setFieldValue(
                        "banner_image",
                        res.data.data[0].uploaded_image
                      );
                    });
                  }}
                />
              </div>

              <div className="grow">
                <div className="flex flex-wrap gap-4">
                  {/* //? country Name */}
                  <div className="max-w-[50%] grow basis-[45%]">
                    <InputLabel label="Name" labelFor="name" />
                    <Field className="field" id="name" name="name" />
                    <ErrorMessage name="name">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  {/* //? country Tag Line */}
                  <div className="max-w-[50%] grow basis-[45%]">
                    <InputLabel label="Tagline" labelFor="tag_line" />
                    <Field className="field" id="tag_line" name="tag_line" />
                    <ErrorMessage name="tag_line">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                {/* //? country Status */}
                <div className="my-4">
                  <div role="group" aria-labelledby="my-radio-group">
                    <InputLabel label="Status" />
                    <div className="flex items-center gap-12">
                      <label className="flex items-baseline gap-1">
                        <Field type="radio" name="status" value="1" />
                        Active
                      </label>
                      <label className="flex items-baseline gap-1">
                        <Field type="radio" name="status" value="0" />
                        Inactive
                      </label>
                    </div>
                  </div>
                </div>

                {/* //? country Description */}
                <div>
                  <InputLabel label="Description" labelFor="description" />
                  <Field
                    name="description"
                    as="textarea"
                    className="field"
                  ></Field>
                  <ErrorMessage name="description">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
            </div>

            {/* //? Meta Fields */}
            <div className="my-4 rounded-lg border p-4">
              <h3 className="mb-2 text-lg font-bold">Meta Info</h3>

              {/* //? country Meta Title */}
              <div>
                <InputLabel
                  label="Meta Title"
                  labelFor="meta_title"
                  instruction="(Max 70 Characters)"
                />
                <Field className="field" id="meta_title" name="meta_title" />
                <ErrorMessage name="meta_title">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              {/* //? country Meta Description */}
              <div className="my-4">
                <InputLabel
                  label="Meta Description"
                  labelFor="meta_description"
                  instruction="(Max 160 Characters)"
                />
                <Field
                  name="meta_description"
                  as="textarea"
                  className="field"
                ></Field>
                <ErrorMessage name="meta_description">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              {/* //? country Meta Keywords */}
              <div>
                <InputLabel label="Meta Keywords" />
                <KeywordsInput
                  value={
                    values?.meta_keywords
                      ? values?.meta_keywords?.split(",")?.map((v) => ({
                          label: v,
                          value: v,
                        }))
                      : []
                  }
                  onChange={(value) =>
                    setFieldValue(
                      "meta_keywords",
                      value.map((v) => v.value).toString()
                    )
                  }
                />
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn-primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CountryForm;
