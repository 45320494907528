import { memo } from "react";

function ToggleInput({ fieldKey, checked, onToggle, disabled }) {
  // const { setFieldValue, values } = useFormikContext();

  return (
    <div>
      <label
        htmlFor={fieldKey}
        className={`relative block h-6 w-12 rounded-full border-2 ${
          checked ? "border-jw-green" : "border-gray-400"
        }`}
      >
        <span
          className={`absolute top-1/2 h-[18px] w-5 -translate-y-1/2 rounded-full  ${
            checked ? "right-[1px] bg-jw-green" : "left-[1px] bg-gray-400"
          }`}
        ></span>
        <input
          type="checkbox"
          name=""
          id={fieldKey}
          checked={checked}
          hidden
          onChange={onToggle}
          disabled={disabled}
        />
      </label>
    </div>
  );
}

export default ToggleInput;
