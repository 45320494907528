import { createContext, useState } from "react";
import { FaChevronRight } from "react-icons/fa";

const RightSlidePanelContext = createContext();

export const RightSlidePanelProvider = ({ children }) => {
  const [slideOpen, setSlideOpen] = useState(false);
  return (
    <RightSlidePanelContext.Provider value={{ slideOpen, setSlideOpen }}>
      <div
        className={`fixed right-0 top-0 z-40 block h-screen w-full overflow-hidden transition-all duration-300 ease-out lg:max-w-[50%] ${
          slideOpen ? "w-full pl-8 lg:w-[35%]" : "w-0"
        }`}
      >
        <div
          onClick={() => setSlideOpen(false)}
          className="before:shadow-[-4px,0px,8px,rgba(0,0,0,0.25)] absolute top-1/2 left-0 z-0 flex h-16 w-8 -translate-y-1/2 items-center justify-center before:absolute before:left-0 before:bottom-0 before:-z-[1] before:h-8 before:w-8 before:rounded-bl-full before:bg-white after:absolute after:top-0 after:left-0 after:-z-[1] after:h-8 after:w-8 after:rounded-tl-full after:bg-white"
        >
          <FaChevronRight />
        </div>
        {children}
      </div>
    </RightSlidePanelContext.Provider>
  );
};

export default RightSlidePanelContext;
