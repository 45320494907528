import React from 'react';
import ConfettiExplosion from 'react-confetti-explosion'
import { IoMdCloseCircleOutline } from 'react-icons/io';


const BirthdayModal = ({ isOpen, onClose, name, imageUrl }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-8 shadow-lg max-w-sm mx-auto">
                <div className="flex justify-end">
                    <button
                        className="text-gray-500 hover:text-gray-700"
                        onClick={onClose}
                    >
                        {/* &times; */}
                        <IoMdCloseCircleOutline size={30} />
                    </button>
                </div>
                <div className="text-center">
                    <img
                        src={imageUrl}
                        alt="Birthday"
                        className="w-32 h-32 rounded-full mx-auto mb-4"
                    />
                    <ConfettiExplosion zIndex='51' />
                    <h2 className="text-xl font-semibold mb-2">Happy Birthday to {name}!</h2>
                    <p className="text-gray-700">Wishing {name} a fantastic day filled with joy and happiness!</p>
                </div>
            </div>
        </div>
    );
};

export default BirthdayModal;
