import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import { getPushNotificationById } from "../../services/PushNotificationServices";
import PageContactForm from "./PageContactForm";
import { getPageContactById } from "../../services/PageContactService";

function EditPageContact() {
  const navigate = useNavigate();

  const contactId = useParams().id;

  const [contact, setContact] = useState();

  const onEditContact = (data) => {
    navigate(`/page-contacts`);
  };

  useEffect(() => {
    contactId &&
      getPageContactById(contactId)
        .then((res) => {
          setContact(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
    <div className="">
      <ComponentHeader className="mb-4 border-b">
        <h2 className="text-xl font-bold">Update Page Contact</h2>
        <Link
          to="/page-contacts"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {contact && (
        <PageContactForm
          formData={contact}
          edit={true}
          onEdit={onEditContact}
        />
      )}
    </div>
  );
}

export default EditPageContact;
