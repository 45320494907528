import React, { memo, useEffect, useState } from "react";
import { FaListAlt } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import H2 from "../../components/headings/H2";
import ComponentHeader from "../components/common/ComponentHeader";
import BookingForm from "./BookingForm/BookingForm";
import {
  getBookingById,
  updateBookingById,
  updateCustomBookingById,
} from "../services/BookingServices";
import ToggleInput from "../components/inputs/ToggleInput";
import NotAuthorized403 from "../components/NotAuthorized403";
import { checkPermission } from "../services/AdminServices";

function EditBooking() {
  const navigate = useNavigate();

  const bookingId = useParams().id;

  const [bookingData, setbookingData] = useState();
  const [packageName, setPackageName] = useState(null);

  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    checkPermission("Booking.update")
      .then((res) => {
        setAuthorized(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }, []);

  const onSubmit = (values, setSubmitting) => {
    if (values.remaining_amount < 0)
      return toast.error("Remaining amount is negative. Check details again.");

    values.cost.forEach((cost) => {
      cost.package_itinerary_id = values.itinerary_id;
    });

    values.id = bookingData?.id;
    values.payment.payment_type =
      values.remaining_amount > 0 ? "partial amount" : "full amount";

    if (bookingData.is_customized !== 1) {
      values.package_name = packageName ? packageName : "";
    }

    values.other_services = values?.other_services?.filter(
      (obj) => Object.keys(obj).length !== 0
    );

    values.addons.forEach((addon, index) => {
      if (!addon.amount || addon.amount === 0) {
        values.addons.splice(index, 1);
      }
    });

    if (!Array.isArray(values.payment)) {
      values.payment = [values.payment];
    }

    if (values.converted_by === "website") {
      delete values.converted_by;
    }

    if (values.amount_deducted || values.credit_note || values.refund_amount) {
      values.cancellation = {
        booking_id: bookingData.id,
        package_id: bookingData.package_id,
        cancellation_type: "partial",
        event_date: bookingData.event_date,
        total_pax: bookingData.total_pax - values.total_pax,
        total_cost: values.total_cost,
        cancellation_reason: values.cancellation_reason,
        credit_note: values.credit_note || 0,
        refund_amount: values.refund_amount || 0,
        amount_deducted: values.amount_deducted || 0,
      };

      values.cancellation_cost = [];

      bookingData.cost.forEach((cst) => {
        let prevCost = bookingData.cost.filter(
          (pc) =>
            pc.travel_mode_id === cst.travel_mode_id &&
            pc.rider_id === cst.rider_id &&
            pc.occupancy_id === cst.occupancy_id
        );

        let currentCost = values.cost.filter(
          (cc) =>
            cc.travel_mode_id === cst.travel_mode_id &&
            cc.rider_id === cst.rider_id &&
            cc.occupancy_id === cst.occupancy_id
        );

        if (
          currentCost.length > 0 &&
          prevCost[0].pax - currentCost[0].pax !== 0
        ) {
          values.cancellation_cost.push({
            travel_mode_id: currentCost[0].travel_mode_id,
            rider_id: currentCost[0].rider_id,
            occupancy_id: currentCost[0].occupancy_id,
            package_price: currentCost[0].package_price,
            package_discount: currentCost[0].package_discount,
            package_itinerary_id: currentCost[0].package_itinerary_id,
            package_price_after_discount:
              currentCost[0].package_price_after_discount,
            package_gst: currentCost[0].package_gst,
            package_with_gst: currentCost[0].package_with_gst,
            pax: prevCost[0].pax - currentCost[0].pax,
            amount:
              currentCost[0].package_with_gst *
              (prevCost[0].pax - currentCost[0].pax),
          });
        }
      });
    }

    if (bookingData.is_customized) {
      values.cost.forEach((cst) => {
        cst.start_location = values.start_location;
        cst.end_location = values.end_location;
      });

      values?.cancellation_cost?.forEach((cst) => {
        delete cst.package_itinerary_id;
      });

      updateCustomBookingById({ booking: values })
        .then((res) => {
          toast.success(res.data.message);
          navigate(`/bookings/${bookingData?.id}`);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      updateBookingById({ booking: values })
        .then((res) => {
          toast.success(res.data.message);
          navigate(`/bookings/${bookingData?.id}`);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          console.log(err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  useEffect(() => {
    bookingId &&
      getBookingById(bookingId)
        .then((res) => {
          setbookingData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return !authorized ? (
    <NotAuthorized403 />
  ) : (
    <div className="">
      <ComponentHeader className="mb-4 border-b">
        <div className="flex items-center gap-4">
          <H2>Update Booking</H2>
          <div className="flex items-center gap-1">
            <ToggleInput
              checked={bookingData?.is_customized}
              onToggle={(e) =>
                setbookingData((bookingData) => ({
                  ...bookingData,
                  is_customized: e.target.checked,
                }))
              }
            />
            <span className="font-medium">Customized Booking</span>
          </div>
        </div>

        <Link
          to="/bookings"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {bookingData && (
        <BookingForm
          setPackageName={setPackageName}
          formData={bookingData}
          bookingData={bookingData}
          edit={true}
          onSubmit={onSubmit}
          isCustomized={bookingData.is_customized ? 1 : 0}
        />
      )}
    </div>
  );
}

export default memo(EditBooking);
