import { admin } from "../../ServiceConfig";

export function getAllInternalNotifications() {
    return admin.get(`/all-internal-notification`)
}

export function getInternalNotificationById(id) {
    return admin.get(`/internal-notification/${id}`)
}

export function updateInternalNotificationById(id, data) {
    return admin.put(`/internal-notification/${id}`, data)
}

export function createInternalNotification(data) {
    return admin.post(`/internal-notification`, data)
}

export function uploadInternalNotificationImage(file) {
    return admin.post(
      `uploads/image?upload_path=internal-notification`,
      file,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  export function deleteInternalNotificationById(id) {
    return admin.delete(`/internal-notification/${id}`)
}