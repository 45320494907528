import { useEffect } from "react";
import { useState } from "react";
import { getMonthlySalesLeaderboard } from "../../services/DashboardServices";
import {
  formatCurrencyINRCommaSeparated,
  monthShortNames,
} from "../../../helpers/Helper";
import { getUploadsUrl } from "../../../ServiceConfig";

function SalesByRevenueLeaderboard() {
  const [saleByRevenue, setSaleByRevenue] = useState([]);

  const backgroundColors = [
    "bg-red-100/20",
    "bg-orange-100/20",
    "bg-amber-100/20",
    "bg-yellow-100/20",
    "bg-lime-100/20",
    "bg-green-100/20",
    "bg-blue-100/20",
    "bg-purple-100/20",
    "bg-pink-100/20",
  ];

  const borderColors = [
    "border-red-100",
    "border-orange-100",
    "border-amber-100",
    "border-yellow-100",
    "border-lime-100",
    "border-green-100",
    "border-blue-100",
    "border-purple-100",
    "border-pink-100",
  ];

  const textColors = [
    "text-red-500",
    "text-orange-500",
    "text-amber-500",
    "text-yellow-500",
    "text-lime-500",
    "text-green-500",
    "text-blue-500",
    "text-purple-500",
    "text-pink-500",
  ];

  useEffect(() => {
    getMonthlySalesLeaderboard()
      .then((res) => {
        setSaleByRevenue(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <div className="flex flex-col rounded-lg border bg-white dark:border-slate-700">
        <div className="card-header-background relative flex items-center border-b bg-gray-100 p-4 dark:border-b dark:border-slate-700 dark:bg-slate-900 dark:text-white">
          <img
            src={`${getUploadsUrl()}/dashboard-icons/max-revenue-in-a-FY.png`}
            className="absolute bottom-2 left-0 h-16 w-16"
          />
          <h3 className="ml-12 font-bold">
            Max Revenue in a Month in Current FY
          </h3>
        </div>
        <div className="dark:scrollbar max-h-[50vh] overflow-auto px-2 dark:bg-gray-600">
          <div className="flex flex-col items-center justify-center gap-3 py-4">
            {saleByRevenue?.map((sale, index) => (
              <div
                key={sale.month}
                className={`flex w-full items-center justify-between gap-4 rounded-md bg-white p-2 shadow-[1px_1px_4px_rgba(0,0,0,0.15)] transition-all duration-300 ease-out hover:shadow-md dark:bg-slate-800 dark:text-white`}
              >
                <div
                  className={`flex h-12 w-12 shrink-0 items-center justify-center rounded-full ${backgroundColors[index]}`}
                >
                  {sale.profile_pic && (
                    <img
                      src={`${getUploadsUrl()}/employees/${sale.profile_pic}`}
                      alt="profile pic"
                      className="h-12 w-12 rounded-full"
                    />
                  )}
                  {!sale.profile_pic && (
                    <p className={`${textColors[index]}`}>
                      {sale.converted_by_name?.split("")[0]}
                      {sale.converted_by_name?.split("")[1]}
                    </p>
                  )}
                </div>
                <div className="line-clamp-1 lg:text-md flex grow flex-col gap-1 text-sm font-medium">
                  <p>{sale.converted_by}</p>

                  <div className="flex items-center gap-1 text-xs lg:text-sm">
                    <div className="text-gray-400">
                      {monthShortNames[sale.month - 1]} {sale.year}
                    </div>
                  </div>
                </div>
                <div className="whitespace-nowrap text-lg font-bold text-gray-700 lg:text-2xl">
                  {formatCurrencyINRCommaSeparated(sale.total_amount)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesByRevenueLeaderboard;
