import { Link, useNavigate } from "react-router-dom";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";
import ThingsToPackForm from "./ThingsToPackForm";

function AddThingsToPack() {
  const navigate = useNavigate();

  const onAddThingsToPack = (data) => {
    navigate(`/things-to-pack`);
  };

  return (
    <div className="">
      {/* <MetaTags title="Add Country | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Add Things To Pack</H2>
        <Link
          to="/things-to-pack"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      <ThingsToPackForm onSuccess={(data) => onAddThingsToPack(data)} />
    </div>
  );
}

export default AddThingsToPack;
