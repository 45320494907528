import { ErrorMessage, Field, Form, Formik } from "formik";
import InputLabel from "../inputs/InputLabel";
import {
  addPushNotification,
  updatePushNotification,
  uploadPushNotificationImage,
} from "../../services/PushNotificationServices";
import { toast } from "react-toastify";
import FileInput from "../../../components/inputFields/FileInput";
import { getUploadsUrl } from "../../../ServiceConfig";

function PushNotificationForm({ edit, formData, onEdit, onAdd }) {
  const addNotification = (values) => {
    addPushNotification(values).then((res) => {
      console.log(res);
      toast.success(res.data.data);
      onAdd(res.data.data);
    });
  };

  const editNotification = (values) => {
    values.id = formData.id;
    updatePushNotification(values).then((res) => {
      console.log(res);
      toast.success(res.data.data);
      onEdit(res.data.data);
    });
  };

  return (
    <div>
      <Formik
        initialValues={{
          title: formData?.title ? formData.title : "",
          body: formData?.body ? formData.body : "",
          image: formData?.image ? formData.image : "",
          button_title: formData?.button_title ? formData.button_title : "",
          button_action: formData?.button_action ? formData.button_action : "",
          scheduled_at: formData?.scheduled_at ? formData.scheduled_at : "",
        }}
        // validationSchema={}
        onSubmit={(values) => {
          edit ? editNotification(values) : addNotification(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="my-2">
              <InputLabel label="Title" />
              <Field className="field" id="name" name="title" />
              <ErrorMessage name="title">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="my-2">
              <InputLabel label="Body" />
              <Field as="textarea" className="field" id="name" name="body" />
              <ErrorMessage name="body">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="my-2">
              <InputLabel label="Select Image Or Paste URL" />
              <FileInput
                value={values.image}
                noOfFilesAllowed={1}
                onChange={(file) => {
                  uploadPushNotificationImage({ image: file }).then((res) => {
                    setFieldValue(
                      "image",
                      `${getUploadsUrl()}/push-notifications/${
                        res.data.data[0].uploaded_image
                      }`
                    );
                  });
                }}
              />
              <div>
                <InputLabel label="Image Url" />
                <Field className="field" name="image" />
              </div>
            </div>
            <div className="my-2">
              <InputLabel label="Button Label / Title" />
              <Field className="field" id="name" name="button_title" />
              <ErrorMessage name="button_title">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="my-2">
              <InputLabel label="Button Action Url" />
              <Field className="field" id="name" name="button_action" />
              <ErrorMessage name="button_action">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="my-2">
              <InputLabel label="Shedule for Date and time" />
              <input
                type="datetime-local"
                className="field"
                id="date-input"
                name="scheduled_at"
                value={values.scheduled_at}
                onChange={(e) => setFieldValue(`scheduled_at`, e.target.value)}
              />
              <ErrorMessage name="scheduled_at">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="my-4 text-center">
              <button type="submit" className="btn-primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default PushNotificationForm;
