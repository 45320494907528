import React, { useState } from "react";

import CreatableSelect from "react-select/creatable";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

function KeywordsInput({ value, onChange }) {
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
      case ",":
        onChange([...value, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
    }
  };

  return (
    <div>
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={(newValue) => onChange(newValue)}
        onInputChange={(newValue) => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        placeholder="Type something and press enter..."
        value={value}
        classNamePrefix={`react-select`}
      />
    </div>
  );
}

export default KeywordsInput;
