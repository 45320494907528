import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import DataTable from "../common/DataTable";
import { deleteCategory } from "../../services/CategoryServices";
import { getAdminUrl, getUploadsUrl } from "../../../ServiceConfig";

function CategoryList() {
  const navigate = useNavigate();
  const stateTable = useRef();

  const deleteSelectedCategory = (id) => {
    deleteCategory(id)
      .then((res) => {
        toast.success(res.data.message);
        stateTable.current.deleteRow(id);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
  <div class="flex items-center gap-3">
      <div class="category-edit-btn cursor-pointer" data-id=${cell.getRow().getData().id
      }>
          <i class="fas fa-edit"></i>
      </div>
      <div class="category-delete-btn cursor-pointer text-red-500" data-id=${cell.getRow().getData().id
      }>
        <i class="fas fa-trash-alt"></i>
      </div>
  </div>
  `;
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Name",
      field: "name",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Banner Image",
      hozAlign: "center",
      vertAlign: "middle",
      headerSort: false,
      field: "banner_image",
      formatter: "image",
      formatterParams: {
        height: "50px",
        width: "80px",
        urlPrefix: `${getUploadsUrl()}/categories/`,
        urlSuffix: "",
      },
    },
    {
      title: "Category Type",
      minWidth: "100",
      field: "category_type",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getValue() === 1) {
          return "Customized";
        } else {
          return "Fixed Departure";
        }
      },
    },
    {
      title: "Status",
      field: "status",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getValue() === 1) {
          return "Active";
        } else {
          return "Inactive";
        }
      },
    },
    {
      title: "Description",
      field: "description",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
    },
    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  useEffect(() => {
    $(document).on("click", ".category-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/category/edit/${cell}`);
    });

    $(document).on("click", ".category-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      deleteSelectedCategory(dataId);
    });
  }, []);

  return (
    <DataTable
      ref={stateTable}
      ajaxUrl={`${getAdminUrl()}/categories`}
      columns={columns}
      search={true}
      responsiveLayout="collapse"
    />
  );
}

export default CategoryList;
