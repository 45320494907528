import React, { useEffect, useState } from "react";

import { useMultiStepForm } from "../../../../hooks/useMultiStepForm";

import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { getPackage } from "../../../services/PackageService";
import usePackageData from "../../../hooks/usePackageData";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Step8 from "./Step8";
import Step9 from "./Step9";

function PackageForm({ edit, packageId }) {
  const { setPackageData } = usePackageData();

  const renderNextForm = () => {
    next();
  };

  useEffect(() => {
    if (packageId) {
      getPackage(packageId)
        .then((res) => {
          setPackageData({ package: res.data.data });
        })
        .catch((err) => console.error(err));
    }
  }, []);

  const {
    steps,
    currentStepIndex,
    step,
    stepsCount,
    isFirstStep,
    isLastStep,
    goTo,
    back,
    next,
  } = useMultiStepForm([
    <Step1 onSuccess={renderNextForm} />,
    <Step2 onSuccess={renderNextForm} />,
    <Step3 onSuccess={renderNextForm} />,
    <Step4 onSuccess={renderNextForm} />,
    <Step5 onSuccess={renderNextForm} />,
    <Step6 onSuccess={renderNextForm} />,
    <Step7 onSuccess={renderNextForm} />,
    <Step8 onSuccess={renderNextForm} />,
    <Step9 />,
  ]);

  const handleFromIndexClick = (index) => {
    goTo(index);
  };

  const renderStepIndexes = steps.map((step, index) => {
    return (
      <div
        key={index}
        onClick={() => handleFromIndexClick(index)}
        className={`h-8 ${index === currentStepIndex ? "bg-jw-green text-white" : ""
          } w-8 rounded-full border bg-white lg:p-[2px] p-[10px] ${index === currentStepIndex
            ? "border-2 border-jw-green"
            : "border-gray-400"
          }`}
      >
        <div
          className={`flex  h-full w-full cursor-pointer items-center justify-center rounded-full`}
        >
          {index + 1}
        </div>
      </div>
    );
  });

  if (window.innerWidth < 780) {
    return (<div>
      <div className="sticky top-0 z-10 flex flex-row dark:bg-slate-900 bg-white py-1 lg:justify-between">
        <div className="lg:order-1">
          <button
            onClick={back}
            className="flex items-center justify-center gap-1 p-[2px] text-jw-green underline underline-offset-2"
          >
            <FaChevronLeft className={`rounded-full p-2 text-[2rem]  ${isFirstStep ? 'bg-slate-400 text-white' : 'bg-jw-green text-white'}`} />
          </button>
        </div>
        <div className="relative inline-flex  overflow-auto w-[calc(100% - 10rem)] gap-4 px-2 py-[2px] border-gray-600 bg-slate-200 rounded-lg">
          <div className="absolute top-1/2 -z-[1] h-[2px] w-full -translate-y-1/2 bg-gray-400"></div>
          {renderStepIndexes}
        </div>

        <div className="lg:order-3">
          <button
            onClick={next}
            className={`flex items-center justify-center gap-1 p-[2px] text-jw-green underline underline-offset-2`}
          >
            <FaChevronRight className={`rounded-full p-2 text-[2rem]  ${isLastStep ? 'bg-slate-400 text-white' : 'bg-jw-green text-white'}`} />
          </button>
        </div>
      </div>
      <div className="relative z-0">{step}</div>
      {/* <div className="flex items-center justify-center gap-4">
        {!isFirstStep && (
          <button
            onClick={back}
            className="rounded-full border-2 border-jw-green px-12 py-2 text-jw-green"
          >
            Back
          </button>
        )}
        <button onClick={next} className="btn-primary border-2 border-jw-green">
          {isLastStep ? "Submit" : "Next"}
        </button>
      </div> */}
    </div>)
  }

  else {
    return <div>
      <div className="sticky top-0 z-10 flex flex-wrap overflow-auto bg-white py-1 lg:justify-between">
        <div className="relative inline-flex items-center justify-center gap-4 lg:order-2 lg:gap-8">
          <div className="absolute top-1/2 -z-[1] h-[2px] w-full -translate-y-1/2 bg-gray-400"></div>
          {renderStepIndexes}
        </div>
        <div className="lg:order-1">
          {!isFirstStep && (
            <button
              onClick={back}
              className="flex items-center justify-center gap-1 px-4 text-jw-green underline underline-offset-2"
            >
              <FaChevronLeft className="bg-jw-green text-white rounded-full p-1 text-3xl" /> Prev
            </button>
          )}
        </div>
        <div className="lg:order-3">
          {!isLastStep && (
            <button
              onClick={next}
              className="flex items-center justify-center gap-1 px-4 text-jw-green underline underline-offset-2"
            >
              Next <FaChevronRight className="bg-jw-green text-white rounded-full p-1 text-3xl" />
            </button>
          )}
        </div>
      </div>
      <div className="relative z-0">{step}</div>
      {/* <div className="flex items-center justify-center gap-4">
        {!isFirstStep && (
          <button
            onClick={back}
            className="rounded-full border-2 border-jw-green px-12 py-2 text-jw-green"
          >
            Back
          </button>
        )}
        <button onClick={next} className="btn-primary border-2 border-jw-green">
          {isLastStep ? "Submit" : "Next"}
        </button>
      </div> */}
    </div>
  }
}
export default PackageForm;