import { Link, useParams } from "react-router-dom";
import H2 from "../../../components/headings/H2";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import GiftCardThemeForm from "./gift-card-forms/GiftCardThemeForm";
import { useState } from "react";
import { useEffect } from "react";
import { getGiftCardThemeById } from "../../services/GiftCardServices";

function EditGiftCard() {
  const giftCardId = useParams().id;

  const [giftCardData, setGiftCardData] = useState();

  useEffect(() => {
    getGiftCardThemeById(giftCardId)
      .then((res) => {
        console.log(res.data.data);
        setGiftCardData(res.data.data);
      })
      .catch((err) => {});
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Edit Category | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Gift Card</H2>
        <Link
          to="/gift-cards"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      <GiftCardThemeForm formData={giftCardData} edit={true} />
    </div>
  );
}

export default EditGiftCard;
