import { createContext, useEffect, useState } from "react";
import Loader from "../components/Loader";

const LoaderContext = createContext();

export const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return (
    <LoaderContext.Provider value={{ loading, setLoading }}>
      <Loader load={loading} />
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderContext;
