import { useEffect } from "react";
import { useState } from "react";
import {
  getMonthlySalesPaxLeaderboard,
  getMonthlySalesPaxLeaderboardLastFY,
} from "../../services/DashboardServices";
import {
  formatNumberCommaSeparated,
  monthShortNames,
} from "../../../helpers/Helper";
import { getUploadsUrl } from "../../../ServiceConfig";

function SalesByPaxLeaderboardLastFY() {
  const [salesByPax, setsalesByPax] = useState([]);

  useEffect(() => {
    getMonthlySalesPaxLeaderboardLastFY().then((res) => {
      setsalesByPax(res.data.data);
    });
  }, []);

  const backgroundColors = [
    "bg-red-100/25",
    "bg-orange-100/25",
    "bg-amber-100/25",
    "bg-yellow-100/25",
    "bg-lime-100/25",
    "bg-green-100/25",
    "bg-blue-100/25",
    "bg-purple-100/25",
    "bg-pink-100/25",
  ];

  const borderColors = [
    "border-red-100",
    "border-orange-100",
    "border-amber-100",
    "border-yellow-100",
    "border-lime-100",
    "border-green-100",
    "border-blue-100",
    "border-purple-100",
    "border-pink-100",
  ];

  const textColors = [
    "text-red-500",
    "text-orange-500",
    "text-amber-500",
    "text-yellow-500",
    "text-lime-500",
    "text-green-500",
    "text-blue-500",
    "text-purple-500",
    "text-pink-500",
  ];

  return (
    <div>
      <div className="flex flex-col rounded-lg border bg-white dark:border-slate-700">
        <div className="card-header-background relative flex items-center border-b bg-gray-100 p-4 dark:border-b dark:border-slate-700 dark:bg-slate-900 dark:text-white">
          <img
            src={`${getUploadsUrl()}/dashboard-icons/max-bookings-in-a-FY.png`}
            className="absolute bottom-2 left-0 h-16 w-16"
          />
          <h3 className="ml-12 font-bold">Max. Booking in Last FY</h3>
        </div>
        <div className="max-h-[50vh] overflow-auto px-2 dark:bg-gray-600">
          <div className="flex items-center justify-center gap-3 py-4">
            {salesByPax?.map((sale, index) => (
              <div
                key={sale.month}
                className={`flex w-full items-center justify-between gap-4 rounded-md bg-white p-2 shadow-[1px_1px_4px_rgba(0,0,0,0.15)] transition-all duration-300 ease-out hover:shadow-md dark:bg-slate-800 dark:text-white`}
              >
                <div className="flex gap-2">
                  <div
                    className={`flex h-12 w-12 shrink-0 items-center justify-center rounded-full ${backgroundColors[index]}`}
                  >
                    {sale.profile_pic && (
                      <img
                        src={`${getUploadsUrl()}/employees/${sale.profile_pic}`}
                        alt="profile pic"
                        className="h-12 w-12 rounded-full"
                      />
                    )}
                    {!sale.profile_pic && (
                      <p className={`${textColors[index]}`}>
                        {sale.converted_by?.split("")[0]}
                        {sale.converted_by?.split("")[1]}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col justify-between gap-1">
                    <div className="line-clamp-1 grow font-medium">
                      {sale.converted_by ?? sale.converted_by_name}
                    </div>
                    <div className="flex items-center gap-1 text-sm">
                      <div className="text-gray-400">
                        {monthShortNames[sale.month - 1]} {sale.year}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="whitespace-nowrap text-lg font-bold text-gray-700 lg:text-2xl">
                  {formatNumberCommaSeparated(sale.total_pax)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesByPaxLeaderboardLastFY;
