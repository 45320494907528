import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";

import InternshipForm from "./InternshipForm";
import { getInternshipById } from "../../services/InternshipServices";

function EditInternship() {
  const navigate = useNavigate();

  const jobId = useParams().id;

  const [internship, setInternship] = useState();

  const onEditJobOpening = (data) => {
    navigate(`/internships`);
  };

  useEffect(() => {
    getInternshipById(jobId)
      .then((res) => {
        setInternship(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Edit Location | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Internship</H2>
        <Link
          to="/jobs"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>

      <InternshipForm
        formData={internship}
        edit={true}
        onSuccess={(data) => onEditJobOpening(data)}
      />
    </div>
  );
}

export default EditInternship;
