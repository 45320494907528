import React, { useEffect, useState } from "react";

import {
    createOccupancy,
    updateOccupancy,
} from "../../services/OccupancyServices";
import InputLabel from "../inputs/InputLabel";
import useLoader from "../../../hooks/useLoader";
import { toast } from "react-toastify";

// -----------------------------------------------------------
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import KeywordsInput from "../inputs/KeywordsInput";

const countryFormSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
});

function OccupancyForm({ formData, edit, onSuccess, setAddOccModal }) {
    const { setLoading } = useLoader();

    // ? Add new country
    const addOccupancy = (values) => {
        let data = Object.assign({}, values);
        createOccupancy(data)
            .then((res) => {
                setAddOccModal(false)
                toast.success(res.data.message);
                onSuccess(res.data.data);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };

    // ? Update country
    const editOccupancy = (values) => {
        let data = Object.assign({}, values);
        updateOccupancy(formData.id, data)
            .then((res) => {
                setAddOccModal(false)
                toast.success(res.data.message);
                onSuccess(res.data.data);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };

    return (
        <div>
            <Formik
                initialValues={{
                    name: formData?.name,
                }}
                validationSchema={countryFormSchema}
                onSubmit={(values) => {
                    edit ? editOccupancy(values) : addOccupancy(values);
                }}
            >
                {({ values, setFieldValue, errors, touched }) => (
                    <Form>
                        <div className="flex gap-4">
                            <div className="grow">
                                <div className="flex flex-wrap gap-4">
                                    {/* //? country Name */}
                                    <div className="md:max-w-[50%] max-w-[100%] grow basis-[45%]">
                                        <InputLabel
                                            label="Name"
                                            labelFor="name"
                                        />
                                        <Field
                                            className="field"
                                            id="name"
                                            name="name"
                                        />
                                        <ErrorMessage name="name">
                                            {(msg) => (
                                                <div className="error-msg">
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-4 text-center">
                            <button type="submit" className="btn-primary">
                                Submit
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default OccupancyForm;
