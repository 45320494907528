import { createContext, useState } from "react";

const PackageDataContext = createContext();

export const PackageDataProvider = ({ children }) => {
  const [packageData, setPackageData] = useState({
    package: {},
    itineraries: [],
  });
  return (
    <PackageDataContext.Provider value={{ packageData, setPackageData }}>
      {children}
    </PackageDataContext.Provider>
  );
};

export default PackageDataContext;
