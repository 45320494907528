import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
  } from "react";
  import LogoLarge from "../assets/image/common/logo/justwravel-logo-full.webp";
  import LogoSmall from "../assets/image/common/logo/justwravel-logo-small.webp";
  import {
    formatINR,
    formatNumberToTwoDigit,
    getReadableDate,
  } from "../helpers/Helper";
  import { FaHotel, FaWhatsapp } from "react-icons/fa";
  import SvgTrip from "../assets/svg/SvgTrip";
  import SvgBike from "../assets/svg/SvgBike";
  import SvgCorporateMen from "../assets/svg/SvgCorporateMen";
  import SvgSocialReview from "../assets/svg/SvgSocialReview";
  import SvgTraveler from "../assets/svg/SvgTraveler";
  import SvgFixedDeparture from "../assets/svg/SvgFixedDeparture";
  
  import moment from "moment";
  import CP_DT from "../assets/image/policy/cpdt.png";
  import CP_ID from "../assets/image/policy/cpid.png";
  import PP_DT from "../assets/image/policy/ppdt.png";
  import PP_ID from "../assets/image/policy/ppid.png";
  import CostDetail from "../assets/image/policy/11.png";
  import { getUploadsUrl } from "../ServiceConfig";
  import useAdminAuth from "../admin/hooks/useAdminAuth";
  
  const importantNotes = `<ol>
  <li>
      <strong>Quotation Validity:</strong> The quoted price is valid for 24 hours from the date of the quote. If the guest's travel date is within three days of the quoted date, the quote is valid only for the quoted date. Justravel reserves the right to adjust prices based on availability and inventory.
  </li>
  <li>
      <strong>Hotel Availability:</strong> The hotel names mentioned indicate that our rates are based on the usage of these hotels. However, this does not imply confirmed accommodation unless we provide confirmation. Should any of the mentioned hotels be unavailable, alternate accommodation at a similar or next best available hotel will be arranged, with any rate differences (supplement/reduction as applicable) passed on.
  </li>
  <li>
      <strong>Check-in and Check-out Times:</strong> Standard check-in time is between 1 PM to 3 PM, and check-out time is between 10 AM to 11 AM. These times may vary depending on the hotel's policy.
  </li>
  <li>
      <strong>Extra Bed Policy:</strong> If the booking includes an extra bed, it will be provided as a folding cot or mattress, as per the hotel's policy.
  </li>
  <li>
      <strong>Houseboat Stay:</strong> Please note that the terms Deluxe, Premium, or Luxury refer to the category of the houseboat, not the name of the houseboat company.
  </li>
  <li>
      <strong>Air Conditioning in Hilly Areas:</strong> Air conditioning in cabs will not be operational in hilly areas and will only function in plain areas.
  </li>
  </ol>`
  
  const ItinerarypdftemplateMobile = forwardRef((props, ref) => {
    const { auth } = useAdminAuth();
  
    const customItinerary = props.customItinerary;
  
    const containerToConvertRef = useRef();
  
    const [dueDate, setDueDate] = useState();
    const [remainingDays, setRemainingDays] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [perPersonCost, setPerPersonCost] = useState(0);
    const [bookingAmount, setBookingAmount] = useState(0);
  
    const [stays, setStays] = useState([]);
  
    function toDataURL(src, callback, outputFormat) {
      var img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = function () {
        var canvas = document.createElement("CANVAS");
        var ctx = canvas.getContext("2d");
        var dataURL;
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
      };
      img.src = src;
      if (img.complete || img.complete === undefined) {
        img.src =
          "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
        img.src = src;
      }
    }
  
    const mergeStays = () => {
      const availableStays = JSON.parse(
        JSON.stringify(customItinerary.itinerary_stays)
      );
  
      let mergedStays = [];
  
      availableStays.forEach((stay) => {
        const mergedObjects = {};
        stay.rooms.forEach((obj) => {
          const stayRoomId = obj.stay_room_id;
          if (!mergedObjects[stayRoomId]) {
            mergedObjects[stayRoomId] = {
              adults: 0,
              children: 0,
              infants: 0,
              stay_room_id: stayRoomId,
              stay_room_name: obj.stay_room_name,
              check_in_date: obj.check_in_date,
              check_out_date: obj.check_out_date,
              no_of_nights: obj.no_of_nights,
              count: 0,
            };
          }
          mergedObjects[stayRoomId].adults += parseInt(obj.adults);
          mergedObjects[stayRoomId].children += parseInt(obj.children);
          mergedObjects[stayRoomId].infants += parseInt(obj.infants);
          mergedObjects[stayRoomId].count++;
        });
  
        const resultArray = Object.values(mergedObjects);
  
        stay.rooms = resultArray;
  
        mergedStays.push(stay);
      });
  
      setStays(mergedStays);
    };
  
    useEffect(() => {
      if (!customItinerary) return;
      mergeStays();
  
      let rd = moment(customItinerary?.start_date).diff(
        customItinerary?.created_at,
        "days"
      );
  
      setRemainingDays(rd);
  
      if (customItinerary.package_type === 1) {
        if (rd < 30) {
          setDueDate(
            moment(customItinerary?.created_at)
              .add(1, "days")
              .utcOffset("+05:30")
              .format("DD MMM, YYYY")
          );
        } else {
          setDueDate(
            moment(customItinerary?.start_date)
              .subtract(30, "days")
              .utcOffset("+05:30")
              .format("DD MMM, YYYY")
          );
        }
      } else {
        if (rd < 15) {
          setDueDate(
            moment(customItinerary?.created_at)
              .add(1, "days")
              .utcOffset("+05:30")
              .format("DD MMM, YYYY")
          );
        } else {
          setDueDate(
            moment(customItinerary?.start_date)
              .subtract(15, "days")
              .utcOffset("+05:30")
              .format("DD MMM, YYYY")
          );
        }
      }
  
      // ==================================================================
  
      let finalCost = 0;
  
      if (customItinerary?.price_type === "total") {
        finalCost = customItinerary?.price + customItinerary?.price * 0.05;
      } else {
        finalCost =
          customItinerary?.price * parseInt(customItinerary?.total_pax) +
          customItinerary?.price * parseInt(customItinerary?.total_pax) * 0.05;
      }
  
      if (parseInt(customItinerary?.service_charge_percent) > 0) {
        finalCost +=
          (finalCost * parseInt(customItinerary?.service_charge_percent)) / 100;
      }
  
      if (customItinerary?.package_type === 1) {
        finalCost += finalCost * 0.05;
      }
  
      setTotalCost(finalCost);
  
      // ======================================================
      let personCost = 0;
  
      if (customItinerary?.price_type === "total") {
        personCost = customItinerary?.price + customItinerary?.price * 0.05;
      } else {
        personCost = customItinerary?.price + customItinerary?.price * 0.05;
      }
  
      if (parseInt(customItinerary?.service_charge_percent) > 0) {
        personCost +=
          (personCost * parseInt(customItinerary?.service_charge_percent)) / 100;
      }
  
      if (customItinerary?.package_type === 1) {
        personCost += personCost * 0.05;
      }
  
      setPerPersonCost(personCost);
  
      // ===================================================
  
      if (customItinerary?.price_type === "total") {
        setBookingAmount(customItinerary?.booking_amount);
      } else {
        setBookingAmount(
          customItinerary?.booking_amount * customItinerary?.adults
        );
      }
    }, [customItinerary]);
  
    useEffect(() => {
      const imgElements = document.querySelectorAll("img");
  
      imgElements.forEach((imgElement, index) => {
        const currentSrc = imgElement.getAttribute("src");
  
        toDataURL(currentSrc, function (dataUrl) {
          imgElement.setAttribute("src", dataUrl);
        });
      });
  
      props.setImageLoaded(true);
    }, [stays]);
  
    useImperativeHandle(ref, () => ({
      getContainerHeight() {
        if (containerToConvertRef.current) {
          return containerToConvertRef.current.clientHeight + 60;
        }
      },
    }));
  
    return (
      <>
        {customItinerary && (
          <div
            ref={containerToConvertRef}
            id="containerToConvert"
            className="itinerary-template max-w-[330px]"
          >
            <div className="bg-gray-100 p-4">
              <div className="mt-4">
                <img src={LogoLarge} alt="logo" className="mx-auto w-1/2" />
              </div>
              <div className="my-8 text-center">
                <h1 className="mt-4 text-base font-bold capitalize">
                  Welcome, {customItinerary.first_name}{" "}
                  {customItinerary.last_name}
                </h1>
                <p className="mt-1 text-xs font-semibold text-jw-green">
                  Your hassle-free holiday starts here.
                </p>
              </div>
            </div>
  
            <div className="p-4">
              <div className="overflow-hidden rounded-lg border-2">
                <table className="w-full">
                  <tbody>
                    <tr className="bg-gray-100 text-xs">
                      <td className="p-4 font-bold uppercase text-gray-700">
                        Visiting :{" "}
                      </td>
                      <td className="font-semibold text-gray-600">
                        {customItinerary.state_name
                          ? customItinerary.state_name
                          : customItinerary.counrty_name}
                      </td>
                    </tr>
                    <tr className="text-xs">
                      <td className="p-4 font-bold uppercase text-gray-700">
                        event date :{" "}
                      </td>
                      <td className="font-semibold text-gray-600">
                        {getReadableDate(customItinerary.start_date)} from{" "}
                        <span className="capitalize">
                          {customItinerary.starting_location}
                        </span>
                      </td>
                    </tr>
                    <tr className="bg-gray-100 text-xs">
                      <td className="p-4 font-bold uppercase text-gray-700">
                        duration :{" "}
                      </td>
                      <td className="font-semibold text-gray-600">
                        {customItinerary.no_of_nights} Nights,{" "}
                        {customItinerary.no_of_nights + 1} Days
                      </td>
                    </tr>
                    <tr className="text-xs">
                      <td className="p-4 font-bold uppercase text-gray-700">
                        Travellers :{" "}
                      </td>
                      <td className="font-semibold text-gray-600">
                        {customItinerary.adults} Adults{" "}
                        <span
                          className={`${
                            customItinerary.children ? "" : "hidden"
                          }`}
                        >
                          , {customItinerary.children} Children{" "}
                        </span>{" "}
                        <span
                          className={`${customItinerary.infants ? "" : "hidden"}`}
                        >
                          , {customItinerary.infants} Infants
                        </span>
                      </td>
                    </tr>
                    {customItinerary.package_type === 1 && (
                      <tr className="bg-gray-100">
                        <td className="p-4 font-bold uppercase text-gray-700">
                          inclusions :{" "}
                        </td>
                        <td className="text-lg font-semibold text-gray-600">
                          {customItinerary.itinerary_stays.length} Hotels
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="my-4">
                <table className="table-fixed">
                  <tbody>
                    <tr>
                      <td className="py-4 text-xs font-semibold text-gray-500">
                        Total Cost Includes{" "}
                        <span
                          className={
                            customItinerary.package_type === 1 ? "" : "hidden"
                          }
                        >
                          TCS &
                        </span>{" "}
                        taxes dated on{" "}
                        <span className="text-gray-700">
                          {getReadableDate(customItinerary.created_at)}
                        </span>
                      </td>
                      <td className="w-1/2 text-right text-2xl font-bold leading-4">
                        {formatINR(perPersonCost)}
                        <br />
                        <span
                          className={`inline-block pt-4 text-xs text-gray-500 ${
                            customItinerary.price_type === "per_person"
                              ? ""
                              : "hidden"
                          }`}
                        >
                          -/ Per Person
                        </span>
                        {parseInt(customItinerary.service_charge_percent) > 0 && (
                          <div className="mt-1 text-right text-sm">
                            {customItinerary.service_charge_percent}% Service
                            charge applied.
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <a
                  href="https://razorpay.me/@travelwithjustwravel"
                  target="_blank"
                  className="mx-auto mb-4 block w-1/2 rounded-lg bg-jw-green pb-3 text-center text-xs text-white"
                >
                  Pay & Book
                </a>
              </div>
            </div>
  
            <div className="p-1">
              <div className="rounded-lg border-2 bg-gray-100 p-4">
                <div className="flex items-center gap-4 border-b pb-4">
                  <img src={LogoSmall} alt="logo" className="mx-8 w-[55px]" />
                  <div className="font-semibold text-gray-500 text-xs">
                    <p className="uppercase">curated By</p>
                    <h2 className=" font-bold text-gray-700">
                      JustWravel
                    </h2>
                    <p>
                      <span className="font-bold text-gray-700">8,000+</span>{" "}
                      Trips Planned
                    </p>
                  </div>
                </div>
  
                <div className="pt-4 text-center">
                  <p className="pb-4 text-sm font-semibold">
                    For any queries or request
                  </p>
                  <a
                    target="_blank"
                    href={`https://wa.me/${
                      props?.customItinerary?.point_of_contact?.phone ||
                      auth?.phone
                    }?text=Hello Wraveler`}
                    className="mx-auto mb-4 block w-1/2 rounded-lg border-2 border-jw-green bg-white pb-4 text-sm font-bold text-jw-green"
                  >
                    <FaWhatsapp className="-pb-4 -mb-4 inline-block" /> WhatsApp
                    Me
                  </a>
                </div>
              </div>
            </div>
  
            <div className="p-4">
              <h2 className="text-center text-lg font-bold uppercase">
                Why <span className="text-jw-green">JustWravel</span>
              </h2>
              <div className="mt-4 flex flex-wrap items-center justify-around gap-y-8 text-center">
                <div className="basis-1/3">
                  <div className="mx-auto w-12 rounded-full bg-white">
                    <SvgTrip />
                  </div>
                  <h4 className="mt-2 text-sm font-bold leading-5">8,000+</h4>
                  <p className="font-semibold text-xs">Successful Trips</p>
                </div>
                <div className="basis-1/3">
                  <div className="mx-auto w-12 rounded-full bg-white">
                    <SvgBike className="fill-gray-900" />
                  </div>
                  <h4 className="mt-2 text-sm font-bold leading-5">250+</h4>
                  <p className="font-semibold text-xs">Bike Trips</p>
                </div>
                <div className="basis-1/3">
                  <div className="mx-auto w-12 rounded-full bg-white">
                    <SvgCorporateMen />
                  </div>
                  <h4 className="mt-2 text-sm font-bold leading-5">80+</h4>
                  <p className="font-semibold text-xs">Corporate Trips</p>
                </div>
                <div className="basis-1/3">
                  <div className="mx-auto w-12 rounded-full bg-white">
                    <SvgSocialReview />
                  </div>
                  <h4 className="mt-2 text-sm font-bold leading-5">5,700+</h4>
                  <p className="font-semibold text-xs">Social Reviews</p>
                </div>
                <div className="basis-1/3">
                  <div className="mx-auto w-12 rounded-full bg-white">
                    <SvgTraveler />
                  </div>
                  <h4 className="mt-2 text-sm font-bold leading-5">25,000+</h4>
                  <p className="font-semibold text-xs">Travellers</p>
                </div>
                <div className="basis-1/3">
                  <div className="mx-auto w-12 rounded-full bg-white">
                    <SvgFixedDeparture />
                  </div>
                  <h4 className="mt-2 text-sm font-bold leading-5">50+</h4>
                  <p className="font-semibold text-xs">Fixed Departures</p>
                </div>
              </div>
            </div>
  
            {/* <div className="p-4">
              <div className="relative overflow-hidden rounded-lg bg-jw-green">
                <div className="h-full">
                  <div className="float-left h-56 w-1/2">
                    <img
                      src={ReviewBg}
                      alt=""
                      className="h-full w-full object-cover"
                    />
                  </div>
  
                  <div className="table-cell h-56 p-4 align-middle text-white">
                    <p className="">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Officiis atque sed repellat sint, optio facilis blanditiis
                      enim tempore, dignissimos doloremque eos.
                    </p>
                    <p className="text-right font-semibold">
                      - Lorem, ipsum dolor.
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
  
            <div className="p-2">
              <img
                src={customItinerary?.banner_image}
                alt=""
                className="w-full rounded-lg"
              />
              {/* <img src={ReviewBg} alt="" className="w-full rounded-lg" /> */}
              {/* <img
                src={customItinerary.banner_image}
                alt="banner"
                className="w-[600px]"
                onLoad={handleImageLoad}
              /> */}
              {/* {customItinerary.banner_image} */}
              <h1 className="mt-2 text-center text-base font-bold capitalize">
                {customItinerary.package_title}
              </h1>
            </div>
  
            <div className="p-4">
              <div className="rounded-base border">
                <h2 className="rounded-t-lg bg-gray-600 p-4 pt-0 text-base font-bold uppercase text-white">
                  Hotels
                </h2>
                <div className="p-4 text-center">
                  {stays?.map((stay, index) => {
                    return (
                      <div key={stay.id} className="pb-4">
                        <div className="flex">
                          <div className="flex w-[200px] justify-center rounded-lg">
                            {stay.stay.image && (
                              <img
                                src={`${getUploadsUrl()}/stays/${
                                  stay.stay.image
                                }`}
                                alt=""
                                className="mt-4 max-h-[50px] w-full  rounded-lg"
                              />
                            )}
                            {!stay.stay.image && (
                              <FaHotel className="mt-4 text-6xl  text-gray-400" />
                            )}
                          </div>
                          <div className="w-[450px] grow pl-4 text-left">
                            <h2 className="text-sm font-bold">
                              {stay.stay.name} - {stay.stay.location} / Similar
                            </h2>
                            <p className="mb-1 text-xs font-semibold">
                              {getReadableDate(stay.check_in_date)} -{" "}
                              {getReadableDate(stay.check_out_date)}
                            </p>
                            {stay.rooms.map((room, index) => {
                              return (
                                <div
                                  className={`flex items-center gap-2 capitalize`}
                                >
                                  <p className="text-xs font-semibold">
                                    {room.stay_room_name} x {room.count} -
                                  </p>
                                  <div className="text-xs text-gray-500">
                                    ({formatNumberToTwoDigit(room.adults)} Adults
                                    <span
                                      className={`${
                                        room.children ? "" : "hidden"
                                      }`}
                                    >
                                      {" "}
                                      , {room.children} Children
                                    </span>
                                    <span
                                      className={`${
                                        room.infants ? "" : "hidden"
                                      }`}
                                    >
                                      , {room.infants} Infants
                                    </span>
                                    )
                                  </div>
                                </div>
                              );
                            })}
  
                            {stay?.stay?.features?.length > 0 && (
                              <p className="mt-1 text-xs text-gray-500">
                                <span className="font-semibold text-gray-700">
                                  Features -
                                </span>{" "}
                                {stay.stay.features.map(
                                  (feature) => `${feature}, `
                                )}
                              </p>
                            )}
                            {stay?.stay?.hotel_ameneties?.length > 0 && (
                              <p className="text-xs text-gray-500">
                                <span className="font-semibold text-gray-700">
                                  Ameneties -
                                </span>{" "}
                                {stay.stay.hotel_ameneties.map(
                                  (amenety) => `${amenety}, `
                                )}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
  
            <div className="p-4">
              <div className="rounded-lg border">
                <h2 className="rounded-t-lg bg-gray-600 p-4 pt-0 text-base font-bold uppercase text-white">
                  Itinerary
                </h2>
  
                <div className="">
                  {props?.customItinerary?.day0 && (
                    <div>
                      <h2 className="mt-4 bg-gray-100 p-4 pt-0 text-sm font-bold">
                        {moment(customItinerary.start_date)
                          .subtract(1, "days")
                          .utcOffset("+05:30")
                          .format("DD MMM, ddd")}{" "}
                        | Day 0
                      </h2>
                      <div className="px-4">
                        <h3 className="my-2 text-sm font-bold">
                          {customItinerary.day0.title}
                        </h3>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: customItinerary.day0.description,
                          }}
                        ></div>
                        {(customItinerary.day0.transfer_id ||
                          (customItinerary.day0.transfer_from &&
                            customItinerary.day0.transfer_to)) && (
                          <div className="pb-4">
                            <h2 className="pb-4 text-sm font-bold">Transfers</h2>
                            <div className="overflow-hidden rounded-lg border">
                              <table className="w-full text-center">
                                <thead>
                                  {customItinerary?.day0?.transfer_id && (
                                    <th className="bg-gray-50 pb-4">Type</th>
                                  )}
                                  <th className="pb-4">From</th>
                                  <th className="bg-gray-50 pb-4">To</th>
                                </thead>
                                <tbody>
                                  <tr>
                                    {customItinerary?.day0?.transfer_id && (
                                      <td className="bg-gray-50 pb-4">
                                        {customItinerary.day0.transfer_id}
                                      </td>
                                    )}
                                    <td className="pb-4">
                                      {customItinerary.day0.transfer_from}
                                    </td>
                                    <td className="bg-gray-50 pb-4">
                                      {customItinerary.day0.transfer_to}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {customItinerary.itinerary_days.map((day, index) => {
                    return (
                      <div key={day.id}>
                        <h2 className="mt-4 bg-gray-100 p-4 pt-0 text-sm font-bold">
                          {moment(customItinerary.start_date)
                            .add(index, "days")
                            .utcOffset("+05:30")
                            .format("DD MMM, ddd")}{" "}
                          | Day {index + 1}
                        </h2>
                        <div className="px-4">
                          <h3 className="my-2 text-sm font-bold">{day.title}</h3>
                          <div
                          className="text-xs"
                            dangerouslySetInnerHTML={{ __html: day.description }}
                          ></div>
                          {
                            <div
                              className={`text-xs py-4 ${
                                day.breakfast || day.lunch || day.dinner
                                  ? ""
                                  : "hidden"
                              }`}
                            >
                              <span className="font-bold capitalize">
                                included -{" "}
                              </span>
                              <span
                                className={`font-semibold ${
                                  day.breakfast ? "" : "hidden"
                                }`}
                              >
                                Breakfast
                              </span>
                              <span
                                className={`font-semibold ${
                                  day.lunch ? "" : "hidden"
                                }`}
                              >
                                <span className={day.breakfast ? "" : "hidden"}>
                                  ,{" "}
                                </span>
                                Lunch
                              </span>
                              <span
                                className={`font-semibold ${
                                  day.dinner ? "" : "hidden"
                                }`}
                              >
                                <span
                                  className={
                                    day.breakfast || day.lunch ? "" : "hidden"
                                  }
                                >
                                  ,{" "}
                                </span>
                                Dinner
                              </span>
                            </div>
                          }
  
                          {(day.transfer?.type ||
                            (day.transfer_from && day.transfer_to)) && (
                            <div className="pb-4">
                              <h2 className="pb-4 text-lg font-bold">
                                Transfers
                              </h2>
                              <div className="overflow-hidden rounded-lg border">
                                <table className="w-full text-center">
                                  <thead>
                                    {day.transfer?.type && (
                                      <th className="bg-gray-50 pb-4">Type</th>
                                    )}
                                    <th className="pb-4">From</th>
                                    <th className="bg-gray-50 pb-4">To</th>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      {day.transfer?.type && (
                                        <td className="bg-gray-50 pb-4">
                                          {`${day.transfer?.type} - (${day.transfer?.seats} seats)`}
                                        </td>
                                      )}
                                      <td className="pb-4">
                                        {day.transfer_from}
                                      </td>
                                      <td className="bg-gray-50 pb-4">
                                        {day.transfer_to}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
  
            {customItinerary?.custom_fields?.length > 0 && (
              <div>
                {customItinerary?.custom_fields?.map((field, index) => {
                  return (
                    <div key={field.id} className="p-4">
                      <h2 className="text-2xl font-bold uppercase">
                        {field.heading}
                      </h2>
                      <div
                        className="p-2 pb-0"
                        dangerouslySetInnerHTML={{ __html: field.description }}
                      ></div>
                      {field?.image && (
                        <div>
                          <img
                            src={field?.image}
                            alt="image"
                            className="w-full rounded-lg"
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
  
            <div className="inclusions p-2">
              <h2 className="rounded-t-lg bg-green-500 p-2 text-base font-bold uppercase text-white">
                Inclusions
              </h2>
              <div
                className="rounded-b-lg bg-green-50 p-4 pt-0 text-xs"
                dangerouslySetInnerHTML={{
                  __html: customItinerary.inclusions,
                }}
              ></div>
            </div>
            <div className="exclusions p-2">
              <h2 className="rounded-t-lg bg-red-500 p-2 text-base font-bold uppercase text-white">
                Exclusions
              </h2>
              <div
                className="rounded-b-lg bg-red-50 p-4 pt-0 text-xs"
                dangerouslySetInnerHTML={{
                  __html: customItinerary.exclusions,
                }}
              ></div>
            </div>
            <div className="p-2">
              <h2 className="rounded-t-lg bg-gray-200 p-2 text-base font-bold uppercase text-gray-800">
                Important to know
              </h2>
              <div
                className="rounded-b-lg bg-red-50 p-4 pt-0 text-xs"
                dangerouslySetInnerHTML={{
                  __html: importantNotes,
                }}
              ></div>
            </div>
  
            {customItinerary.important_notes && (
              <div className="p-2">
                <div className="rounded-lg border">
                  <h2 className="rounded-t-lg bg-gray-600 p-2 pt-0 text-base font-bold uppercase text-white">
                    important to know
                  </h2>
                  <div className="p-2">
                    <div
                      className="rounded-b-lg p-2"
                      dangerouslySetInnerHTML={{
                        __html: customItinerary.important_notes,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
  
            <div className="">
              <div className="rounded-lg">
                {/* <h2 className="text-2xl font-bold uppercase bg-gray-600 text-white p-4 pt-0 rounded-t-lg">
                  Payments
                </h2> */}
                <div className="p-4">
                  <div>
                    <table className="w-full table-fixed overflow-hidden rounded-lg text-center">
                      <thead className="border-2 bg-gray-200">
                        <th className="p-4 text-sm">Installment No</th>
                        <th className="p-4 text-sm">Amount</th>
                        <th className="p-4 text-sm">Due Date</th>
                      </thead>
                      <tbody>
                        {((remainingDays >= 30 &&
                          customItinerary.package_type === 1) ||
                          (remainingDays >= 15 &&
                            customItinerary.package_type === 0)) && (
                          <tr>
                            <td className="border-2 pb-4 text-lg font-semibold text-gray-600">
                              Installment 1
                            </td>
                            <td className="border-2 pb-4 text-lg font-semibold text-gray-600">
                              {formatINR(bookingAmount)}
                            </td>
                            <td className="border-2 pb-4 text-lg font-semibold text-gray-600">
                              {moment(customItinerary.created_at)
                                .add(1, "days")
                                .utcOffset("+05:30")
                                .format("DD MMM, YYYY")}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td className="rounded-bl-lg border-2 pb-4 text-xs font-semibold text-gray-600">
                            Installment{" "}
                            <span>
                              {(remainingDays >= 30 &&
                                customItinerary.package_type === 1) ||
                              (remainingDays >= 15 &&
                                customItinerary.package_type === 0)
                                ? 2
                                : 1}
                            </span>
                          </td>
                          <td className="border-2 pb-4 text-xs font-semibold text-gray-600">
                            {(remainingDays >= 30 &&
                              customItinerary.package_type === 1) ||
                            (remainingDays >= 15 &&
                              customItinerary.package_type === 0) ? (
                              <> {formatINR(totalCost - bookingAmount)}</>
                            ) : (
                              <>{formatINR(totalCost)}</>
                            )}
                          </td>
                          <td className="rounded-br-lg border-2 pb-4 text-xs font-semibold text-gray-600">
                            {dueDate}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
  
                  <div className="my-4">
                    <table className="table-fixed">
                      <tbody>
                        <tr>
                          <td className="py-4 text-sm font-semibold text-gray-500">
                            Total Cost Includes{" "}
                            <span
                              className={
                                customItinerary.package_type === 1 ? "" : "hidden"
                              }
                            >
                              TCS &
                            </span>{" "}
                            taxes dated on{" "}
                            <span className="text-gray-700">
                              {getReadableDate(customItinerary.created_at)}
                            </span>
                          </td>
                          <td className="w-1/2 text-right align-middle text-4xl font-bold">
                            {formatINR(totalCost)}
                            {parseInt(customItinerary.service_charge_percent) >
                              0 && (
                              <div className="mt-1 text-right text-sm">
                                {customItinerary.service_charge_percent}% Service
                                charge applied.
                              </div>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <a
                    href="https://razorpay.me/@travelwithjustwravel"
                    target="_blank"
                    className="mx-auto mb-4 block w-1/2 rounded-lg bg-jw-green pb-4 text-center text-white"
                  >
                    Pay & Book
                  </a>
                </div>
              </div>
            </div>
  
            {customItinerary.default_payment_policy && (
              <div className="p-4">
                <div className="rounded-lg border">
                  <img
                    src={customItinerary.package_type === 1 ? PP_ID : PP_DT}
                    alt="payment policy"
                    className="w-full max-w-[600px] "
                  />
                  <div
                    className="p-4"
                    dangerouslySetInnerHTML={{
                      __html: customItinerary.payment_policy,
                    }}
                  ></div>
                </div>
              </div>
            )}
  
            {!customItinerary.default_payment_policy && (
              <div className="p-4">
                <div className="rounded-lg border">
                  <h2 className="rounded-t-lg bg-gray-600 p-4 pt-0 text-2xl font-bold uppercase text-white">
                    Payment Policy
                  </h2>
                  <div
                    className="p-4"
                    dangerouslySetInnerHTML={{
                      __html: customItinerary.payment_policy,
                    }}
                  ></div>
                </div>
              </div>
            )}
  
            {customItinerary.default_cancellation_policy && (
              <div className="p-4">
                <div className="rounded-lg border">
                  <img
                    src={customItinerary.package_type === 1 ? CP_ID : CP_DT}
                    alt="cancellation policy"
                    className="w-full max-w-[600px] "
                  />
                  <div
                    className="p-4"
                    dangerouslySetInnerHTML={{
                      __html: customItinerary.cancellation_policy,
                    }}
                  ></div>
                </div>
              </div>
            )}
  
            {!customItinerary.default_cancellation_policy && (
              <div className="p-4">
                <div className="rounded-lg border">
                  <h2 className="rounded-t-lg bg-gray-600 p-4 pt-0 text-2xl font-bold uppercase text-white">
                    Cancellation Policy
                  </h2>
                  <div
                    className="p-4"
                    dangerouslySetInnerHTML={{
                      __html: customItinerary.cancellation_policy,
                    }}
                  ></div>
                </div>
              </div>
            )}
  
            <div className="px-4">
              <div className="rounded-lg">
                <img src={CostDetail} alt="explanation" />
              </div>
            </div>
  
            {customItinerary.visa_policy && (
              <div className="p-4">
                <div className="rounded-lg border">
                  <h2 className="rounded-t-lg bg-gray-600 p-4 pt-0 text-2xl font-bold uppercase text-white">
                    Visa Policy
                  </h2>
                  <div
                    className="p-4"
                    dangerouslySetInnerHTML={{
                      __html: customItinerary.visa_policy,
                    }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  });
  
  export default ItinerarypdftemplateMobile;
  