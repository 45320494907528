import React from 'react'
import { Outlet } from "react-router-dom";

const FeaturesWrapper = () => {
    return (
        <div className="wrapper">
            {<Outlet />}
        </div>
    )
}

export default FeaturesWrapper