import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import MetaTags from "../../../web/layouts/MetaTags";
import { getRoleById } from "../../services/RoleServices";
import RoleForm from "./RoleForm";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";
import InputLabel from "../inputs/InputLabel";
import { Field } from "formik";

function ModulesAndPermissionsForm() {
  return (
    <div className="">
      {/* <MetaTags title="Edit Role | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Assgned Permissions to this Role</H2>
      </ComponentHeader>
      <div className="mb-4">
        <h3 className="text-base font-semibold">Module 1</h3>
        <div className="ml-4 space-y-2">
          <Field
            type="checkbox"
            name="permissions.module1.permission1"
            className="form-checkbox h-5 w-5 text-blue-600"
          />
          <label className="ml-2">Permission 1</label>

          <Field
            type="checkbox"
            name="permissions.module1.permission2"
            className="form-checkbox h-5 w-5 text-blue-600"
          />
          <label className="ml-2">Permission 2</label>

          <Field
            type="checkbox"
            name="permissions.module1.permission3"
            className="form-checkbox h-5 w-5 text-blue-600"
          />
          <label className="ml-2">Permission 3</label>
        </div>
      </div>

      <div className="mb-4">
        <h3 className="text-base font-semibold">Module 2</h3>
        <div className="ml-4 space-y-2">
          <Field
            type="checkbox"
            name="permissions.module2.permission4"
            className="form-checkbox h-5 w-5 text-blue-600"
          />
          <label className="ml-2">Permission 4</label>

          <Field
            type="checkbox"
            name="permissions.module2.permission5"
            className="form-checkbox h-5 w-5 text-blue-600"
          />
          <label className="ml-2">Permission 5</label>
        </div>
      </div>
    </div>
  );
}

export default ModulesAndPermissionsForm;
