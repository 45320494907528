import { FaPlusSquare } from "react-icons/fa";
import { Link, Outlet } from "react-router-dom";
import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
import H2 from "../../../components/headings/H2";
import H3 from "../../../components/headings/H3";
// import MetaTags from "../../../web/layouts/MetaTags";
import ComponentHeader from "../common/ComponentHeader";

function GoogleReviewWrapper() {
  return (
    <div className="wrapper">
      {/* <div className="h-full max-h-[calc(100%-1rem)] overflow-auto"> */}
        {<Outlet />}
      {/* </div> */}
    </div>
  );
}

export default GoogleReviewWrapper;
