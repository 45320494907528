import { Outlet } from "react-router-dom";

function OccupancyWrapper() {
  return (
    <div className="wrapper">
      {/* <MetaTags title={"All Travel Modes"} /> */}
      <div className="h-full max-h-[calc(100%-1rem)] overflow-auto">
        {<Outlet />}
      </div>
    </div>
  );
}

export default OccupancyWrapper;
