import { ErrorMessage, Field, Form, Formik } from "formik";
import { getReadableDate } from "../../helpers/Helper";
import InputLabel from "../components/inputs/InputLabel";
import { updatePaymentReceipt } from "../services/BookingServices";
import useLoader from "../../hooks/useLoader";

function UpdatePaymentReciept({ setModalIsOpen, receipt }) {
  const { setLoading } = useLoader();
  const updateReciept = (values) => {
    setLoading(true);
    delete values.payment_date;
    setModalIsOpen(false);
    updatePaymentReceipt(receipt.id, values).then((res) => {
      setLoading(false);
    });
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          transaction_id: receipt?.transaction_id,
          amount: receipt?.amount,
          payment_method: receipt?.payment_method,
          payment_date: getReadableDate(receipt?.created_at),
        }}
        // validationSchema={bookingFormSchema}
        onSubmit={(values) => {
          updateReciept(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="rounded-lg border-2 p-4">
              <div className="flex flex-wrap gap-4">
                <div className="grow">
                  <InputLabel label="Transaction Id" />
                  <Field name="transaction_id" className="field" />
                  <ErrorMessage name={`transaction_id`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="grow">
                  <InputLabel label="Amount Received" />
                  <Field name="amount" className="field" />
                  <ErrorMessage name={`amount`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="grow">
                  <InputLabel label="Payment Method" />
                  <Field as="select" name={`payment_method`} className="field">
                    <option>Select a Payment method</option>
                    <option value="neft/imps">NEFT/IMPS</option>
                    <option value="google_pay">Google Pay</option>
                    <option value="phonepe">PhonePe</option>
                    <option value="paytm">Paytm</option>
                    <option value="payu_money">PayU Money</option>
                    <option value="bhim_upi">BHIM UPI</option>
                    <option value="EMI-sankash">EMI Sankash</option>
                    <option value="razorpay">Razorpay</option>
                    <option value="Others">Others</option>
                    <option value="cash">Cash</option>
                  </Field>
                  <ErrorMessage name={`payment_method`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>

                <div>
                  <InputLabel label="Payment Date" />
                  <Field name="payment_date" className="field" disabled />
                </div>
              </div>
            </div>
            <div className="mt-4 text-center">
              <button type="submit" className="btn-primary">
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default UpdatePaymentReciept;
