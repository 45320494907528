import { Outlet } from "react-router-dom";

function RnRWrapper() {
  return (
    <div className="wrapper">
      {/* <MetaTags title="All States | Admin Panel" /> */}
      {/* <div className="h-full  overflow-auto"> */}
        {<Outlet />}
      {/* </div> */}
    </div>
  );
}

export default RnRWrapper;
