import ComponentHeader from "../common/ComponentHeader";
import H2 from "../../../components/headings/H2";
import DataTable from "../common/DataTable";
import { useEffect } from "react";
import { getAdminUrl } from "../../../ServiceConfig";
import { useRef } from "react";
import $ from "jquery";
import { isObjectEmpty } from "../../../helpers/Helper";
import {
  deleteOfficialContacts,
  updateOfficialContacts,
} from "../../services/OfficialContactServices";
import { toast } from "react-toastify";
import { Field, Form, Formik } from "formik";
import InputLabel from "../inputs/InputLabel";

function OfficialContactsWrapper() {
  const OfficialContactTable = useRef();

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
    <div class="official-contact-delete-btn" data-id=${JSON.stringify(
      cell.getRow().getData()
    )} style="cursor: pointer;">
    <svg width="16px" height="16px" x="0px" y="0px" fill="red"
     viewBox="0 0 172.541 172.541" style="enable-background:new 0 0 172.541 172.541;" xml:space="preserve">
<g>
    <path d="M166.797,25.078h-13.672h-29.971V0H49.388v25.078H19.417H5.744v15h14.806l10,132.463h111.443l10-132.463h14.805V25.078z
         M64.388,15h43.766v10.078H64.388V15z M128.083,157.541H44.46L35.592,40.078h13.796h73.766h13.796L128.083,157.541z"/>
    <rect x="80.271" y="65.693" width="12" height="66.232"/>
    <rect x="57.271" y="65.693" width="12" height="66.232"/>
    <rect x="103.271" y="65.693" width="12" height="66.232"/>
</g>
</svg></div>
  </div>`;
  };

  const deleteOfficialContact = (data) => {
    let contact = JSON.parse(data);
    if (isObjectEmpty(contact)) {
    } else {
      if (!window.confirm("Do You really want to delete this contact?")) return;
      deleteOfficialContacts(contact.id)
        .then((res) => {
          OfficialContactTable.current.deleteRow(contact.id);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };

  useEffect(() => {
    $(document).on("click", ".official-contact-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      deleteOfficialContact(dataId);
    });

    return () => {
      $(document).off("click", ".official-contact-delete-btn");
    };
  }, []);

  const isPhone = (cell, value, parameters) => {
    if (value) {
      return value.toString().length === 10;
    } else {
      return true;
    }
  };

  var emailEditor = function (cell, onRendered, success, cancel, editorParams) {
    if (cell.getData().id) {
      return false;
    } else {
      var editor = document.createElement("input");

      editor.setAttribute("type", "email");
      editor.style.padding = "3px";
      editor.style.width = "100%";
      editor.style.boxSizing = "border-box";

      onRendered(function () {
        editor.focus();
        editor.style.css = "100%";
      });

      function successFunc() {
        success(cell.getValue());
      }

      editor.addEventListener("change", successFunc);
      editor.addEventListener("blur", successFunc);

      return editor;
    }
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Email",
      field: "email",
      minWidth: "100",
      vertAlign: "middle",
      editor: emailEditor,
      editorParams: { autocomplete: "true", allowEmpty: false },
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      validator: ["required", "unique"],
      resizable: true,
    },

    {
      title: "Phone",
      field: "phone",
      minWidth: "100",
      vertAlign: "middle",
      editor: "input",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      validator: [
        "numeric",
        {
          type: isPhone,
        },
      ],
      resizable: true,
    },

    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  const updateContact = (value, setSubmitting, resetForm) => {
    if (!value.email) {
      setSubmitting(false);
      toast.error("Email is required");
      return;
    }

    updateOfficialContacts(value)
      .then((res) => {
        OfficialContactTable.current.updateTable();
        resetForm();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="wrapper">
      {/* <div className="flex h-full max-h-[calc(100%-1rem)] flex-col overflow-auto"> */}
        <ComponentHeader className="border-b">
          <H2 className="tracking-wide">Official Contacts</H2>
        </ComponentHeader>
        <div>
          <Formik
            enableReinitialize
            initialValues={{
              phone: "",
              email: "",
            }}
            // validationSchema={packageInfoSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              updateContact(values, setSubmitting, resetForm);
            }}
          >
            {({ values, setFieldValue, errors, touched, isSubmitting }) => (
              <Form>
                {/* // ? Package Type */}
                <div className="my-4 grid grid-cols-5 items-end gap-4">
                  <div className="col-span-2">
                    <InputLabel label="Email"/>
                    <Field type="email" name="email" className="field" />
                  </div>
                  <div className="col-span-2">
                    <InputLabel label="Phone"/>
                    <Field name="phone" className="field" />
                  </div>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="dark:bg-green-700 dark:text-white dark:border-none rounded-lg border border-jw-green px-4 py-2 text-jw-green disabled:border-jw-green/70 disabled:text-jw-green/70"
                  >
                    Save
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="h-full">
          <DataTable
            ref={OfficialContactTable}
            ajaxUrl={`${getAdminUrl()}/official-contacts`}
            layout="fitColumns"
            columns={columns}
            search={true}
            onCellEdit={updateContact}
            // addRowBtn="Add New Contact"
            responsiveLayout="collapse"
          />
        </div>
      {/* </div> */}
    </div>
  );
}

export default OfficialContactsWrapper;
