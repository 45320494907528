import React, { useEffect, useState } from "react";
import FileInput from "../../../../components/inputFields/FileInput";

import InputLabel from "../../inputs/InputLabel";
import { toast } from "react-toastify";

// -----------------------------------------------------------
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import DateInput from "../../inputs/DateInput";
import DateRangeInput from "../../inputs/DateRangeInput";
import ToggleInput from "../../inputs/ToggleInput";
import {
  createCoupon,
  UpdateCoupon,
  uploadCouponImage,
} from "../../../services/CouponService";
import { formatNumberToTwoDigit } from "../../../../helpers/Helper";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { getUploadsUrl } from "../../../../ServiceConfig";

function CouponForm({ formData, edit, onSuccess }) {
  const navigate = useNavigate();

  const eventDateRangeInput = useRef();

  const storeCoupon = (values) => {
    createCoupon(values)
      .then((res) => {
        toast.success(res.data.message);
        if (values.type === "global") {
          navigate("/coupons");
        } else {
          onSuccess(res.data.data.id);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const updateCoupon = (values) => {
    values.id = formData.id;

    UpdateCoupon(values).then((res) => {
      if (values.type === "global") {
        navigate("/coupons");
      } else {
        onSuccess(res.data.data.id);
      }
    });
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          type: formData?.type ? formData.type : "global",
          code: formData?.code ? formData.code : "",
          image: formData?.image ? formData.image : "",
          discount_percent: formData?.discount_percent
            ? formData.discount_percent
            : "",
          discount_maximum: formData?.discount_maximum
            ? formData.discount_maximum
            : "",
          cashback_percent: formData?.cashback_percent
            ? formData.cashback_percent
            : "",
          cashback_maximum: formData?.cashback_maximum
            ? formData.cashback_maximum
            : "",
          is_default: formData?.is_default ? formData.is_default : false,
          is_visible: formData?.is_visible ? formData.is_visible : false,
          status: formData?.status ? formData.status : false,
          valid_from: formData?.valid_from ? formData?.valid_from : "",
          valid_thru: formData?.valid_thru ? formData?.valid_thru : "",
          event_date_from: formData?.event_date_from
            ? formData?.event_date_from
            : "",
          event_date_to: formData?.event_date_to ? formData?.event_date_to : "",
          min_pax: formData?.min_pax ? formData?.min_pax : "",
          min_amount: formData?.min_amount ? formData?.min_amount : "",
          coupon_as_gift_card: formData?.coupon_as_gift_card
            ? formData?.coupon_as_gift_card
            : "0",
          applied_on_total_amt: formData?.applied_on_total_amt
            ? formData?.applied_on_total_amt
            : "0",

          total_availability: formData?.total_availability
            ? formData?.total_availability
            : "0",
        }}
        // validationSchema={CouponFormSchema}
        onSubmit={(values) => {
          // alert(JSON.stringify(values));
          edit ? updateCoupon(values) : storeCoupon(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            {/* //? Meta Fields */}
            <div className="my-4 rounded-lg border p-4">
              <div className="flex flex-wrap gap-4">
                <div className="flex basis-full flex-wrap items-center gap-8">
                  <div className="basis-full lg:basis-1/3">
                    <FileInput
                      noOfFilesAllowed={1}
                      value={`${getUploadsUrl()}/coupons/${values.image}`}
                      onChange={(file) => {
                        uploadCouponImage({ image: file }).then((res) => {
                          setFieldValue(
                            "image",
                            res.data.data[0].uploaded_image
                          );
                        });
                      }}
                    />
                  </div>
                  <div className="basis-1/2">
                    <div>
                      <InputLabel label="Coupon Type" />
                      <div
                        role="group"
                        aria-labelledby="coupon-type-group"
                        className="flex items-center gap-8"
                      >
                        <label className="flex items-baseline gap-1">
                          <Field
                            type="radio"
                            name="type"
                            value="global"
                            disabled={edit}
                          />
                          Global
                        </label>
                        <label className="flex items-baseline gap-1">
                          <Field
                            type="radio"
                            name="type"
                            value="category"
                            disabled={edit}
                          />
                          Category Based
                        </label>
                        <label className="flex items-baseline gap-1">
                          <Field
                            type="radio"
                            name="type"
                            value="package"
                            disabled={edit}
                          />
                          Package Based
                        </label>
                      </div>
                      <ErrorMessage name={"type"}>
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="flex items-center gap-12">
                      <div className="mt-4 flex items-center gap-2">
                        <InputLabel label="Apply By Default" />
                        <ToggleInput
                          fieldKey="is_default"
                          checked={values.is_default}
                          onToggle={() =>
                            setFieldValue("is_default", !values.is_default)
                          }
                        />
                      </div>
                      <div className="mt-4 flex items-center gap-2">
                        <InputLabel label="Visible in Offer" />
                        <ToggleInput
                          fieldKey="is_visible"
                          checked={values.is_visible}
                          onToggle={() =>
                            setFieldValue("is_visible", !values.is_visible)
                          }
                        />
                      </div>

                      <div className="mt-4 flex items-center gap-2">
                        <InputLabel label="Status" />
                        <ToggleInput
                          fieldKey="status"
                          checked={values.status}
                          onToggle={() =>
                            setFieldValue("status", !values.status)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grow">
                  <InputLabel label="Coupon Code" />
                  <Field name="code" className="field" disabled={edit} />
                  <ErrorMessage name={"code"}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>

                <div className="grow">
                  <InputLabel label="Coupon as GiftCard" />
                  <Field
                    as="select"
                    name="coupon_as_gift_card"
                    className="field"
                  >
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </Field>
                </div>

                <div className="grow">
                  <InputLabel label="Valid From" />
                  <DateInput
                    minDate={new Date()}
                    value={values.valid_from}
                    onDateChange={(value) =>
                      setFieldValue(
                        "valid_from",
                        `${new Date(
                          value
                        ).getFullYear()}-${formatNumberToTwoDigit(
                          new Date(value).getMonth() + 1
                        )}-${formatNumberToTwoDigit(new Date(value).getDate())}`
                      )
                    }
                  />
                  <ErrorMessage name={"valid_from"}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="grow">
                  <InputLabel label="Valid Thru" />
                  <DateInput
                    value={values.valid_thru}
                    minDate={new Date()}
                    onDateChange={(value) =>
                      setFieldValue(
                        "valid_thru",
                        `${new Date(
                          value
                        ).getFullYear()}-${formatNumberToTwoDigit(
                          new Date(value).getMonth() + 1
                        )}-${formatNumberToTwoDigit(new Date(value).getDate())}`
                      )
                    }
                  />
                  <ErrorMessage name={"valid_thru"}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="grow">
                  <InputLabel label="Applicable Date Range" />
                  <DateRangeInput
                    ref={eventDateRangeInput}
                    startDate={formData?.event_date_from}
                    endDate={formData?.event_date_to}
                    onRangeChange={(startDate, endDate) => {
                      setFieldValue(
                        "event_date_from",
                        `${new Date(
                          startDate
                        ).getFullYear()}-${formatNumberToTwoDigit(
                          new Date(startDate).getMonth() + 1
                        )}-${formatNumberToTwoDigit(
                          new Date(startDate).getDate()
                        )}`
                      );
                      setFieldValue(
                        "event_date_to",
                        `${new Date(
                          endDate
                        ).getFullYear()}-${formatNumberToTwoDigit(
                          new Date(endDate).getMonth() + 1
                        )}-${formatNumberToTwoDigit(
                          new Date(endDate).getDate()
                        )}`
                      );
                    }}
                  />
                  <div className="flex justify-end">
                    <button
                      onClick={() => {
                        setFieldValue("event_date_from", null);
                        setFieldValue("event_date_to", null);
                        eventDateRangeInput.current.resetDates();
                      }}
                      className="text-[10px] font-semibold text-orange-500"
                    >
                      Reset
                    </button>
                  </div>
                </div>

                {values.type === "global" && (
                  <>
                    <div className="flex basis-full gap-4">
                      <div className="grow">
                        <InputLabel label="Discount %" />
                        <Field className="field" name="discount_percent" />
                        <ErrorMessage name={"discount_percent"}>
                          {(msg) => <div className="error-msg">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="grow">
                        <InputLabel label="Max Discount" />
                        <Field className="field" name="discount_maximum" />
                        <ErrorMessage name={"discount_maximum"}>
                          {(msg) => <div className="error-msg">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="grow">
                        <InputLabel label="Min Pax" />
                        <Field className="field" name="min_pax" />
                        <ErrorMessage name={"min_pax"}>
                          {(msg) => <div className="error-msg">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="grow">
                        <InputLabel label="Min Amount" />
                        <Field className="field" name="min_amount" />
                        <ErrorMessage name={"min_amount"}>
                          {(msg) => <div className="error-msg">{msg}</div>}
                        </ErrorMessage>
                      </div>

                      {/* <div className="grow">
                      <InputLabel label="Cashback %" />
                      <Field className="field" name="cashback_percent" />
                      <ErrorMessage name={"cashback_percent"}>
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="grow">
                      <InputLabel label="Max Cashback" />
                      <Field className="field" name="cashback_maximum" />
                      <ErrorMessage name={"cashback_maximum"}>
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div> */}
                    </div>
                    <div className="flex flex-wrap gap-4">
                      <div className="grow">
                        <InputLabel label="Number of Available Coupons" />
                        <Field className="field" name="total_availability" />
                      </div>
                      <div className="grow">
                        <InputLabel label="Apply on" />
                        <Field
                          as="select"
                          className="field"
                          name="applied_on_total_amt"
                        >
                          <option value="0">Per Person</option>
                          <option value="1">Total Amount</option>
                        </Field>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className={`text-center `}>
              <button type="submit" className="btn-primary">
                {edit ? "Update" : "Submit"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CouponForm;
