import { Outlet } from "react-router-dom";

function RoleWrapper() {
  return (
    <div className="wrapper">
      {/* <MetaTags title="All States | Admin Panel" /> */}
      <div className="h-full max-h-[calc(100%-1rem)] overflow-auto">
        {<Outlet />}
      </div>
    </div>
  );
}

export default RoleWrapper;
