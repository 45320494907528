import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useMultiStepForm } from "../../../hooks/useMultiStepForm";
import CouponForm from "./couponForms/CouponForm";
import CouponTypeFormWrapper from "./couponForms/CouponTypeFormWrapper";
import { useState } from "react";
import { useEffect } from "react";

function CouponFormWrapper({ formData, edit, onSuccess }) {
  const [activeCouponId, setActiveCouponId] = useState(
    formData?.id ? formData.id : ""
  );

  const renderNextForm = (couponId) => {
    setActiveCouponId(couponId);
    next();
  };

  // const [forms, setForms] = useState([]);

  const {
    steps,
    currentStepIndex,
    step,
    stepsCount,
    isFirstStep,
    isLastStep,
    goTo,
    back,
    next,
  } = useMultiStepForm([
    <CouponForm formData={formData} edit={edit} onSuccess={renderNextForm} />,
    formData?.type !== "global" ? (
      <CouponTypeFormWrapper couponId={activeCouponId} />
    ) : null,
  ]);

  useEffect(() => {
    edit && setActiveCouponId(formData.id);
  }, [edit, formData]);

  const renderStepIndexes = steps.map((step, index) => {
    return (
      <div
        key={index}
        // onClick={() => handleFromIndexClick(index)}
        className={`h-8 w-8 rounded-full border bg-white p-[2px] ${
          index === currentStepIndex
            ? "border-2 border-jw-green"
            : "border-gray-400"
        }`}
      >
        <div
          className={`flex h-full w-full items-center justify-center rounded-full ${
            index < currentStepIndex ? "bg-jw-green text-white" : ""
          }`}
        >
          {index + 1}
        </div>
      </div>
    );
  });

  return (
    <div>
      <div className="sticky top-0 z-10 flex flex-wrap justify-between overflow-auto bg-white py-1 lg:justify-between">
        <div className="relative inline-flex w-full items-center justify-center gap-4 lg:order-2 lg:w-auto lg:gap-8">
          <div className="absolute top-1/2 -z-[1] hidden h-[2px] w-full -translate-y-1/2 bg-gray-400 lg:block"></div>
          {renderStepIndexes}
        </div>
        <div className="lg:order-1">
          {!isFirstStep && (
            <button
              onClick={back}
              className="flex items-center justify-center gap-1 px-4 text-jw-green underline underline-offset-2"
            >
              <FaChevronLeft /> Prev
            </button>
          )}
        </div>
        <div className="lg:order-3">
          {edit && !isLastStep && formData?.type !== "global" && (
            <button
              onClick={next}
              className="flex items-center justify-center gap-1 px-4 text-jw-green underline underline-offset-2"
            >
              Next <FaChevronRight />
            </button>
          )}
        </div>
      </div>

      <div className="relative z-0">{step}</div>
    </div>
  );
}

export default CouponFormWrapper;
