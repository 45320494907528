import React, { useEffect, useState } from "react";
import { FaListAlt } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import H2 from "../../../components/headings/H2";
import ComponentHeader from "../common/ComponentHeader";
import { getStayAmenityById } from "../../services/StayServices";
import AmenitiesForm from "./AmenitiesForm";

function EditAmenities() {
  const navigate = useNavigate();

  const amenityId = useParams().id;

  const [amenityData, setAmenityData] = useState();

  const onEditState = (data) => {
    navigate(`/amenities`);
  };

  useEffect(() => {
    amenityId &&
    getStayAmenityById(amenityId)
        .then((res) => {
          setAmenityData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Edit State | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Amenities</H2>
        <Link
          to="/amenities"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {amenityData && (
        <AmenitiesForm
          formData={amenityData}
          edit={true}
          onSuccess={(data) => onEditState(data)}
        />
      )}
    </div>
  );
}

export default EditAmenities;
