function SvgFixedDeparture({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 130 130"
    >
      <path d="M4 32.2c.4-.6.7-1.3 1.1-1.9 1.2-1.5 2.8-1.8 4.6-1.8h72.9c2.4 0 2.9.4 3.5 2.7.3 1 .9 1.6 1.9 1.5 1 0 1.6-.6 1.8-1.5.6-2.4 1.1-2.8 3.6-2.8h25.1c4 0 5.9 1.8 5.9 5.9v59.2c0 3.9-1.9 5.8-5.7 5.8H93.4c-2.5 0-2.9-.3-3.6-2.8-.2-1-.9-1.5-1.8-1.5-1 0-1.7.5-1.9 1.5-.6 2.4-1.1 2.7-3.6 2.7H10.6c-3 0-5.4-.8-6.5-3.7V32.2zm41.6-.2H9.8c-1.8 0-2.2.4-2.2 2.2v59.5c0 1.8.4 2.2 2.1 2.2h71.9c.7 0 1.1-.2 1.3-.9.9-2.2 2.7-3.4 5-3.4 2.4 0 4.1 1.2 5 3.4.3.7.6.9 1.3.9h24.4c1.7 0 2.1-.4 2.1-2.1V34.1c0-1.7-.4-2.1-2.1-2.1H94.2c-.7 0-1.1.2-1.3.9-.9 2.2-2.7 3.4-5 3.4s-4.1-1.1-5-3.3c-.4-.9-.8-1-1.6-1H45.6m20.9 26.3v19.6c0 2.8-1 4.8-3.5 6.2-.3.2-.5.6-.5 1-.2 4.3-.9 5.1-5.3 5.1h-.6c-3.5 0-4.6-1-4.7-4.6 0-.3 0-.5-.1-.8H39.5v2c-.1 2-1.4 3.3-3.3 3.4-1.2.1-2.4.1-3.6 0-2.1-.1-3.3-1.3-3.5-3.4 0-.4-.1-.8 0-1.2.1-.8-.2-1.3-1-1.7-2.2-1.2-3.1-3.2-3.1-5.7V58.3l-2-.2c-2.2-.4-3.8-2.2-3.9-4.4L19 49c.1-1.6 1.3-3.1 2.9-2.9 2.3.2 3.2-.6 4-2.7 1.4-3.6 4.5-5.5 8.4-5.6 7.8-.1 15.6-.1 23.4.1 4.2.1 7.6 3.1 8.5 7.1.2.8.5 1.1 1.3 1 3.8-.3 5.2 1 4.9 5v2c0 3.1-1.8 5-5 5.2-.2.1-.5.1-.9.1m-38-8.8v19.6c11.5.6 23 .7 34.4 0V49.5H42.8c-.5 0-1.1 0-1.6-.2-.8-.2-1.2-.9-1.1-1.7s.5-1.4 1.4-1.6c.4-.1.9-.1 1.3-.1h20c-.1-.3-.1-.6-.1-.8-.9-2.3-2.6-3.9-5.1-3.9-7.9-.1-15.7 0-23.6 0h-.5c-2.4.4-4.5 2.4-4.7 4.7h4.9c.9 0 1.5.6 1.6 1.6.1.9-.3 1.5-1.2 1.8-.4.1-.8.1-1.3.1-1.4.1-2.8.1-4.3.1m0 23.4v5c0 2.2 1 3.1 3.2 3.1H60.1c1.6 0 2.7-.7 2.8-2.1.1-2.1 0-4.2 0-6.1H28.5zm40.4-23.3h-2.3v5c1.5.2 2.2-.1 2.3-1.2.1-1.3 0-2.5 0-3.8m-46.3 0v3.2c0 1.7.6 2.1 2.3 1.9v-5h-2.3zm10 36.9h3.3v-1.8h-3.3v1.8zm23 0h3.3v-1.8h-3.3v1.8zm50.1-41.8h-6.5c-1.4 0-2.2-.7-2.2-1.8s.8-1.7 2.2-1.7h13c1.4 0 2.2.6 2.2 1.7s-.8 1.8-2.2 1.8h-6.5m.2 38.5h6.5c1.4 0 2.2.7 2.2 1.8s-.8 1.7-2.2 1.8h-13c-1.4 0-2.2-.6-2.2-1.7s.8-1.8 2.3-1.8l6.4-.1"></path>
      <path d="M105.8 55.2h6.6c1.4 0 2.1.6 2.1 1.7s-.7 1.8-2.1 1.9H99.1c-1.3 0-2.1-.7-2.1-1.8s.8-1.7 2.2-1.8h6.6m0 17.5h-6.6c-1.4 0-2.2-.7-2.2-1.8 0-1 .8-1.7 2.1-1.7h13.3c1.4 0 2.1.7 2.1 1.9 0 1.1-.8 1.6-2.1 1.7l-6.6-.1M89.7 57.3l-.1 2.2c-.1.9-.7 1.4-1.6 1.4s-1.7-.4-1.7-1.4c-.1-1.5-.1-3.1 0-4.7.1-1 .8-1.4 1.7-1.4s1.6.5 1.7 1.5c0 .9-.1 1.7 0 2.4-.1 0 0 0 0 0m-.1 13.3v2.2c-.1 1-.7 1.5-1.6 1.5-1 0-1.7-.5-1.8-1.5-.1-1.5-.1-3 0-4.5.1-1 .8-1.4 1.7-1.4s1.6.5 1.6 1.5c.2.7.1 1.5.1 2.2zm0-26.7V46c-.1 1-.7 1.6-1.7 1.6s-1.7-.5-1.7-1.6c-.1-1.5-.1-3 0-4.4.1-1 .8-1.5 1.8-1.5.9 0 1.5.6 1.6 1.5.1.8 0 1.6 0 2.3zm-3.4 40c0-.7-.1-1.4 0-2.1.1-1 .7-1.6 1.7-1.6s1.6.6 1.7 1.6c.1 1.4.1 2.9 0 4.3 0 .9-.6 1.5-1.6 1.5s-1.7-.5-1.8-1.5c-.1-.6 0-1.4 0-2.2-.1 0 0 0 0 0m-29.5-8.8c.5 0 1.1 0 1.6.1.9.2 1.5.8 1.3 1.7-.1.6-.7 1.5-1.2 1.6-1.2.2-2.4.2-3.6.1-.9-.1-1.3-.8-1.3-1.7 0-.8.5-1.5 1.4-1.6.5-.2 1.1-.2 1.8-.2M35 78.7c-.6 0-1.1 0-1.6-.1-1-.1-1.4-.8-1.4-1.7s.5-1.6 1.5-1.7 2.1-.1 3.2 0c1 .1 1.5.7 1.5 1.6s-.5 1.6-1.4 1.7l-1.8.2"></path>
    </svg>
  );
}

export default SvgFixedDeparture;
