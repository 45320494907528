import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import DataTable from "../components/common/DataTable";
import { getAdminUrl } from "../../ServiceConfig";
import ComponentHeader from "../components/common/ComponentHeader";
import H2 from "../../components/headings/H2";
import {
  FaArrowsAltH,
  FaCheckSquare,
  FaChevronDown,
  FaCog,
  FaDownload,
  FaEdit,
  FaFileCsv,
  FaFileExcel,
  FaFileImport,
  FaFilter,
  FaPlus,
  FaPlusSquare,
  FaTimes,
  FaTrashAlt,
} from "react-icons/fa";
import { getReadableDate } from "../../helpers/Helper";
import ToggleInput from "../components/inputs/ToggleInput";
import { useState } from "react";
import {
  bulkAssignLeads,
  bulkLeadDelete,
  bulkLeadStatusUpdate,
  getAllLeadColumn,
  getLeadById,
  importLeads,
  updatelead,
  getAllLeadSource,
  getAllLeadStatus,
  getAllLeadCategory,
} from "../services/LeadService";
import useDataTable from "../hooks/useDataTable";
import Modal from "react-modal";
import { MdClose, MdOutlineTransferWithinAStation } from "react-icons/md";
import SelectSearch from "../components/inputs/SelectSearch";
import {
  getAllEmployeesOption,
  updateEmployeePreference,
} from "../services/EmployeeServices";
import useAdminAuth from "../hooks/useAdminAuth";
import { RightSlidePanelProvider } from "../context/RightSlidePanelProvider";
import LeadForm from "./LeadForm";
import FileImporter from "../../components/FileImporter";
import SampleExcel from "../../assets/example.csv";
import { checkPermission } from "../services/AdminServices";
import NotAuthorized403 from "../components/NotAuthorized403";
import InputLabel from "../components/inputs/InputLabel";
import DateRangeInput from "../components/inputs/DateRangeInput";
import moment from "moment";
import OutsideClick from "../../hooks/OutsideClick";
import { getAllPackages } from "../services/PackageService";

function LeadList() {
  const navigate = useNavigate();
  const leadTable = useRef();
  const assignLeadTable = useRef();
  const leadForm = useRef();

  const { auth, setAuth } = useAdminAuth();

  const { selectedData, setSelectedData } = useDataTable();

  const [bulkAssignModal, setBulkAssignModal] = useState(false);

  const [bulkStatusUpdateModal, setBulkStatusUpdateModal] = useState(false);

  const [employees, setEmployees] = useState([]);

  const [openSlidePanel, setOpenSlidePanel] = useState(false);

  const [formData, setFormData] = useState();

  const [leadColumns, setLeadColumns] = useState([]);
  const [fileImporterModal, setFileImporterModal] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [columnMapping, setColumnMapping] = useState({});
  const [fileUploadedPath, setFileUploadedPath] = useState("");
  const [autoAssign, setAutoAssign] = useState(false);
  const [skippedColumns, setSkippedColumns] = useState([]);

  const [showButtonOnlyMyLeads, setShowButtonOnlyMyLeads] = useState(false);
  const [authorized, setAuthorized] = useState(true);

  const [leadSources, setLeadSources] = useState([]);
  const [leadStatus, setLeadStatus] = useState([]);
  const [leadCategories, setLeadCategories] = useState([]);
  const [pkgs, setPkgs] = useState([]);

  const [selectedFilter, setSelectedFilter] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);

  const [bulkActionOpen, setBulkActionOpen] = useState(false);
  const [columnVisibilityOpen, setColumnVisibilityOpen] = useState(false);
  const [downloadOptionOpen, setDownloadOptionOpen] = useState(false);
  const [renderedColumns, setRenderedColumns] = useState();

  const filterRef = useRef(null);
  const filterRef1 = useRef(null);
  const bulkActionRef = useRef(null);
  const bulkActionRef1 = useRef(null);

  useEffect(() => {
    leadTable.current.setFilter(selectedFilter);
  }, [selectedFilter]);

  useEffect(() => {
    getAllLeadStatus()
      .then((res) => {
        setLeadStatus(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getAllLeadCategory()
      .then((res) => {
        console.log(res.data.data.data, "lead category");
        setLeadCategories(res.data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getAllPackages()
      .then((res) => {
        setPkgs(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    getAllLeadSource()
      .then((res) => {
        setLeadSources(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    checkPermission("Lead.read")
      .then((res) => {
        setAuthorized(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }, []);
  useEffect(() => {
    checkPermission("Lead.view_all")
      .then((res) => {
        setShowButtonOnlyMyLeads(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }, []);

  const [onlyMyLeads, setOnlyMyLeads] = useState(
    auth?.preferences?.only_my_leads
  );
  const [onlyUntappedLeads, setOnlyUntappedLeads] = useState(
    auth?.preferences?.only_untapped_leads
  );

  const assignLeadcolumns = [
    {
      title: "Id",
      field: "id",
      minWidth: 100,
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Lead Created",
      minWidth: "100",
      field: "created_at",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return getReadableDate(cell.getValue());
      },
    },
    {
      title: "Lead Source",
      minWidth: "150",
      field: "lead_source",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Lead Owner",
      minWidth: "100",
      field: "assigned_to_name",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Name",
      field: "first_name",
      minWidth: "150",
      vertAlign: "middle",
      resizable: true,
      formatter: function (cell, formatterParams, onRendered) {
        return `${cell.getRow().getData().first_name} ${
          cell.getRow().getData().last_name
            ? cell.getRow().getData().last_name
            : ""
        }`;
      },
    },
    {
      title: "Phone",
      minWidth: "100",
      field: "phone",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Email",
      minWidth: "150",
      field: "email",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Lead Status",
      minWidth: "150",
      field: "lead_status",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Pax",
      field: "pax",
      minWidth: "100",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Location",
      field: "location",
      minWidth: "150",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Prefered Date",
      field: "prefered_date",
      minWidth: "150",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return getReadableDate(cell.getValue());
      },
    },
  ];

  const updateFilter = (filter) => {
    let index = selectedFilter.findIndex((sf) => sf.field === filter.field);

    if (index >= 0) {
      if (filter.value && !Array.isArray(filter.value)) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else if (Array.isArray(filter.value) && filter.value[0]) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else {
        setSelectedFilter(
          selectedFilter.filter((af) => af.field !== filter.field)
        );
      }
    } else {
      setSelectedFilter([...selectedFilter, filter]);
    }
  };

  const columns = [
    {
      formatter: "rowSelection",
      width: "50",
      titleFormatter: "rowSelection",
      align: "center",
      headerSort: false,
      cellClick: function (e, cell) {
        cell.getRow().toggleSelect();
      },
    },
    // {
    //   title: "Id",
    //   field: "id",
    //   width: "50",
    //   resizable: true,
    //   vertAlign: "middle",
    // },
    {
      title: "Query Created",
      minWidth: 200,
      field: "query.created_at",
      resizable: true,
      vertAlign: "middle",
      // headerFilterFunc: dateRangeFilterFunction,
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue()
          ? moment(cell.getValue()).format("DD MMM, YYYY (hh:mm A)")
          : "";
      },
    },
    {
      title: "Lead Created",
      minWidth: 150,
      field: "created_at",
      resizable: true,
      vertAlign: "middle",
      // headerFilterFunc: dateRangeFilterFunction,
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue() ? getReadableDate(cell.getValue()) : "";
      },
    },
    {
      title: "Lead Source",
      minWidth: 150,
      field: "query.lead_source_name",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getRow().getData().query.lead_source_name;
      },
      headerSort: false,
    },
    {
      title: "Lead Owner",
      minWidth: 180,
      field: "assigned_to_name",
      resizable: true,
      vertAlign: "middle",
      headerSort: false,
      formatter: "textarea",
    },
    {
      title: "Name",
      field: "first_name",
      minWidth: 160,
      vertAlign: "middle",
      resizable: true,
      formatter: function (cell, formatterParams, onRendered) {
        return `${cell.getRow().getData().first_name} ${
          cell.getRow().getData().last_name
            ? cell.getRow().getData().last_name
            : ""
        }`;
      },
      sorter: "string",
    },
    {
      title: "Phone",
      minWidth: 160,
      field: "phone",
      resizable: true,
      vertAlign: "middle",
      headerSort: false,
      formatter: "textarea",
    },
    {
      title: "Email",
      minWidth: "150",
      field: "email",
      resizable: true,
      vertAlign: "middle",
      headerSort: false,
      formatter: "textarea",
    },
    {
      title: "Lead Status",
      minWidth: "150",
      field: "query.lead_status_name",
      resizable: true,
      vertAlign: "middle",
      headerSort: false,
      formatter: "textarea",
    },
    {
      title: "Lead Category",
      minWidth: "150",
      field: "query.lead_category_name",
      resizable: true,
      vertAlign: "middle",
      headerSort: false,
      formatter: "textarea",
    },
    {
      title: "Pax",
      field: "query.pax",
      minWidth: "100",
      resizable: true,
      vertAlign: "middle",
      headerSort: false,
    },
    {
      title: "Location",
      field: "query.location",
      minWidth: "150",
      resizable: true,
      vertAlign: "middle",
      headerSort: false,
      formatter: "textarea",
    },
    {
      title: "Package",
      field: "query.package_name",
      minWidth: "250",
      resizable: true,
      vertAlign: "middle",
      headerSort: false,
      formatter: "textarea",
    },
    {
      title: "Prefered Date",
      field: "query.prefered_date",
      minWidth: "180",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue() ? getReadableDate(cell.getValue()) : " ";
      },
    },
    {
      title: "Prefered Month",
      field: "query.preferred_month",
      minWidth: "180",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue()
          ? moment(cell.getValue(), "MM").format("MMMM")
          : " ";
      },
    },
    {
      title: "Page Url",
      field: "query.page_url",
      minWidth: "280",
      resizable: true,
      vertAlign: "middle",
      headerSort: false,
      formatter: "textarea",
    },
    {
      title: "Form Type",
      field: "query.form_type",
      minWidth: "280",
      resizable: true,
      vertAlign: "middle",
      headerSort: false,
      formatter: "textarea",
    },
    {
      title: "Last Remark",
      field: "query.remark",
      minWidth: "280",
      resizable: true,
      vertAlign: "middle",
      headerSort: false,
      formatter: "textarea",
    },
    // {
    //   formatter: actionIcons,
    //   headerSort: false,
    //   minWidth: 80,
    //   resizable: false,
    //   responsive: 0,
    //   hozAlign: "center",
    //   vertAlign: "middle",
    //   frozen: true,
    // },
  ];

  useEffect(() => {
    getAllEmployeesOption()
      .then((res) => {
        setEmployees(res.data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    $(document).on("click", ".lead-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");

      getLeadById(cell).then((res) => {
        setFormData(res.data.data);
      });

      leadForm.current.toggleSlide();
    });

    $(document).on("click", ".lead-view-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/leads/${cell}`);
    });

    return () => {
      $(document).off("click", ".lead-edit-btn");
      $(document).off("click", ".lead-view-btn");
    };
  }, []);

  const onRowClick = (data) => {
    getLeadById(data.id).then((res) => {
      setFormData(res.data.data);
    });
    leadForm.current.toggleSlide(true);
  };

  const updateRow = (value) => {
    updatelead(value)
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const onDataSelection = (data) => {
    setSelectedData(data);
  };

  const leadBulkAssign = (data) => {
    let selectedIds = selectedData.map((data) => data.id);

    bulkAssignLeads({ ids: selectedIds, assigned_to: data })
      .then((res) => {
        toast.success(res.data.message);
        setBulkAssignModal(false);
        leadTable.current.updateTable();
        setSelectedData([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const bulkStatusUpdate = (e) => {
    let selectedIds = selectedData.map((data) => data.id);

    bulkLeadStatusUpdate({ ids: selectedIds, status: e.target.value })
      .then((res) => {
        toast.success(res.data.message);
        setBulkStatusUpdateModal(false);
        leadTable.current.updateTable();
        setSelectedData([]);
      })
      .catch((err) => {
        console.log(err, "rrr========");
      });
  };

  const bulkDeleteLead = () => {
    let selectedIds = selectedData.map((data) => data.id);

    if (!window.confirm("Do You really want to delete these leads?")) return;

    bulkLeadDelete({ ids: selectedIds })
      .then((res) => {
        toast.success(res.data.message);
        leadTable.current.updateTable();
        setSelectedData([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateOnlyMyLeadPreference = (value) => {
    updateEmployeePreference({ preference: "only_my_leads", value: value })
      .then((res) => {
        let currentAuth = auth;
        currentAuth.preferences.only_my_leads = value;
        setAuth(currentAuth);
        setOnlyMyLeads(value);
        leadTable.current.updateTable();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const updateOnlyUntappedLeadPreference = (value) => {
    updateEmployeePreference({
      preference: "only_untapped_leads",
      value: value,
    })
      .then((res) => {
        let currentAuth = auth;
        currentAuth.preferences.only_untapped_leads = value;
        setAuth(currentAuth);
        setOnlyUntappedLeads(value);
        leadTable.current.updateTable();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const rowFormatter = (row) => {
    if (row.getData().lead_tapped === 0) {
      const children = row.getElement().childNodes;
      children.forEach((child) => {
        child.style.color = "#3876BF";
      });
    }
    if (row.getData().lead_status === "dnp") {
      const children = row.getElement().childNodes;
      children.forEach((child) => {
        child.style.color = "#9E6F21";
      });
    }
  };

  const onSuccess = (data) => {
    getLeadById(data.id).then((res) => {
      setFormData(res.data.data);
      leadTable.current.updateTable();
    });
  };

  useEffect(() => {
    getAllLeadColumn().then((res) => {
      setLeadColumns(res.data);
    });
  }, []);

  const onImportData = () => {
    const mappedColumns = Object.values(columnMapping);

    // const notFound = ["first_name", "phone", "email"].filter(
    //   (element) => !mappedColumns.includes(element)
    // );

    // if (notFound.length > 1 || notFound[0] === "first_name") {
    //   toast.error(`${notFound[0].replace(/_/g, " ")} is required to map.`);
    //   return;
    // }

    const notFound = ["phone", "email"].filter(
      (element) => !mappedColumns.includes(element)
    );

    if (notFound.length > 1) {
      toast.error(`${notFound[0].replace(/_/g, " ")} is required to map.`);
      return;
    }

    let columnMap = columnMapping;

    Object.keys(columnMap).forEach((col) => {
      if (columnMap[col] === "" || !columnMap[col]) {
        delete columnMap[col];
      }
    });

    importLeads({
      file_path: `lead-csv/${fileUploadedPath}`,
      column_mapping: columnMap,
      auto_assign: autoAssign,
    })
      .then((res) => {
        toast.success(res.data.message);
        setFileImporterModal(false);
        setColumnMapping({});
        setFileData([]);
        leadTable.current.updateTable();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return !authorized ? (
    <NotAuthorized403 />
  ) : (
    <div className="flex h-full flex-col">
      <ComponentHeader className="border-b">
        <div className="flex flex-wrap items-center gap-8">
          <H2>Leads</H2>
          {showButtonOnlyMyLeads && (
            <div className="hidden items-center gap-1 lg:flex">
              <ToggleInput
                checked={onlyMyLeads}
                onToggle={(e) => updateOnlyMyLeadPreference(e.target.checked)}
              />
              Only My Leads
            </div>
          )}
          <div className="hidden items-center gap-1 lg:flex">
            <ToggleInput
              checked={onlyUntappedLeads}
              onToggle={(e) =>
                updateOnlyUntappedLeadPreference(e.target.checked)
              }
            />
            Only Untapped Leads
          </div>
        </div>
        <div className="group relative">
          <button className="btn-primary">
            <FaPlusSquare className="inline-block" /> Add
          </button>
          <div className="invisible absolute top-full right-0 z-10 rounded-lg bg-white py-4 text-right text-jw-green shadow-lg group-hover:visible">
            <button
              onClick={() => leadForm.current.toggleSlide()}
              className="whitespace-nowrap py-1 px-4 lg:py-2"
            >
              <FaPlus className="inline-block text-sm" /> Add New
            </button>

            <button
              onClick={() => setFileImporterModal(true)}
              className="whitespace-nowrap py-1 px-4 lg:py-2"
            >
              <FaFileImport className="inline-block" /> Bulk Import
            </button>
          </div>
        </div>
      </ComponentHeader>

      <div className="my-2 flex justify-between">
        {showButtonOnlyMyLeads && (
          <div className="flex flex-col items-center gap-1 lg:hidden">
            <ToggleInput
              checked={onlyMyLeads}
              onToggle={(e) => updateOnlyMyLeadPreference(e.target.checked)}
            />
            Only My Leads
          </div>
        )}
        <div className="flex flex-col items-center gap-1 lg:hidden">
          <ToggleInput
            checked={onlyUntappedLeads}
            onToggle={(e) => updateOnlyUntappedLeadPreference(e.target.checked)}
          />
          Only Untapped Leads
        </div>
      </div>

      <div className="mt-2 flex items-center gap-4">
        {/* <div className="flex items-center">
          <div className="h-2 w-2 rounded-full bg-[#D83F31]"></div>
          <p className="rounded-full px-1 text-[0.625rem] font-medium leading-3 text-[#D83F31]">
            Cancelled
          </p>
        </div> */}
        <div className="flex items-center">
          <div className="h-2 w-2 rounded-full bg-[#3876BF]"></div>
          <p className="rounded-full px-1 text-[0.625rem] font-medium leading-3 text-[#3876BF]">
            Untapped Lead
          </p>
        </div>
        <div className="flex items-center">
          <div className="h-2 w-2 rounded-full bg-[#9E6F21]"></div>
          <p className="rounded-full px-1 text-[0.625rem] font-medium leading-3 text-[#9E6F21]">
            DNP
          </p>
        </div>
      </div>
      <div className="h-full">
        {/* {console.log(selectedData, "selectedData")} */}
        <div
          ref={filterRef1}
          // onClick={() => setFilterOpen(!filterOpen)}
          className=" mr-2 inline-block pt-4"
        >
          <button
            onClick={() => setFilterOpen(!filterOpen)}
            className={`text-md flex items-center gap-1 whitespace-nowrap rounded-md border dark:bg-slate-900 dark:text-white bg-white p-8 py-2 font-semibold tracking-wide ${
              filterOpen
                ? "-mb-[1px] rounded-b-none border-b-0 px-8 pb-4 pt-2"
                : ""
            }`}
          >
            <FaFilter />
            Filters
          </button>
        </div>
        <div className="relative inline-block">
          <OutsideClick onClick={() => setColumnVisibilityOpen(false)}>
            <button
              onClick={() => {
                setColumnVisibilityOpen(!columnVisibilityOpen);
                setRenderedColumns(leadTable.current.getColumns());
              }}
              className={`text-md mr-2 flex  items-center dark:bg-slate-900 dark:text-white gap-1 rounded-md border bg-white p-8 py-2 font-semibold tracking-wide `}
            >
              <FaCog /> Column Visibility
            </button>

            <div
              className={`${
                columnVisibilityOpen ? "" : "hidden"
              } absolute left-0 top-full  z-40 mt-2 w-full rounded-md border bg-white py-0`}
            >
              <ul className="flex max-h-[300px] flex-col overflow-auto">
                {renderedColumns &&
                  renderedColumns.map((column, key) => (
                    <div key={key}>
                      {column._column.definition.title && (
                        <label htmlFor={`id${key}`} className={`m-0 !h-0 p-0`}>
                          <li
                            className={`${
                              column.isVisible()
                                ? "bg-white text-gray-900"
                                : "bg-gray-200 text-gray-900"
                            } text-md items-center border-y border-slate-100 py-2 px-4 text-justify  align-middle font-bold`}
                          >
                            <div className="flex items-center justify-between">
                              <p className="inline-block">
                                {column._column.definition.title}
                              </p>
                              <input
                                id={`id${key}`}
                                type="checkbox"
                                value="1"
                                checked={column.isVisible()}
                                onChange={(e) => {
                                  column.toggle();
                                  setRenderedColumns(
                                    leadTable.current.getColumns()
                                  );
                                }}
                                className="hidden"
                              />
                              <div className="right-1 inline-block">
                                {column.isVisible() ? (
                                  <div className="text-green-700">
                                    <FaCheckSquare />
                                  </div>
                                ) : (
                                  <div className="text-red-700">
                                    <FaTimes />
                                  </div>
                                )}
                              </div>
                            </div>
                          </li>
                        </label>
                      )}
                    </div>
                  ))}
              </ul>
            </div>
          </OutsideClick>
        </div>
        <div className="relative inline-block">
          <OutsideClick onClick={() => setDownloadOptionOpen(false)}>
            <button
              onClick={() => {
                setDownloadOptionOpen(!downloadOptionOpen);
              }}
              className={`text-md mr-2 flex  items-center gap-1 rounded-md border dark:bg-slate-900 dark:text-white bg-white p-8 py-2 font-semibold tracking-wide `}
            >
              <FaDownload />
              Download
            </button>

            <div
              className={`${
                downloadOptionOpen ? "" : "hidden"
              } absolute left-0 top-full  z-40 mt-2 w-full rounded-md border bg-white py-0`}
            >
              <ul className="flex max-h-[300px] flex-col overflow-auto">
                <div key="csv">
                  <li
                    onClick={() =>
                      leadTable.current.download("csv", `Data.csv`, {
                        delimiter: ",",
                      })
                    }
                    className={`text-gray-900text-md cursor-pointer items-center border-y border-slate-100 bg-white py-2 px-4  text-justify align-middle font-bold`}
                  >
                    <div className="flex items-center justify-between">
                      <p className="inline-block">CSV</p>

                      <div className="right-1 inline-block">
                        <div className="text-green-700">
                          <FaFileCsv />
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
                <div key="xlsx">
                  <li
                    onClick={() =>
                      leadTable.current.download("xlsx", `Data.xlsx`, {
                        sheetName: "sheet1",
                      })
                    }
                    className={`text-gray-900text-md cursor-pointer items-center border-y border-slate-100 bg-white py-2 px-4  text-justify align-middle font-bold`}
                  >
                    <div className="flex items-center justify-between">
                      <p className="inline-block">XLSX</p>

                      <div className="right-1 inline-block">
                        <div className="text-green-700">
                          <FaFileExcel />
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </OutsideClick>
        </div>
        {selectedData?.length > 0 && (
          <div className="relative inline-block">
            <OutsideClick onClick={() => setBulkActionOpen(false)}>
              <button
                onClick={() => {
                  setBulkActionOpen(!bulkActionOpen);
                }}
                className={`text-md mr-2 flex  items-center gap-1 rounded-md border bg-white p-8 py-2 font-semibold tracking-wide `}
              >
                <FaEdit />
                Bulk Action
              </button>

              <div
                className={`${
                  bulkActionOpen ? "" : "hidden"
                } absolute left-0 top-full  z-40 mt-2 w-full rounded-md border bg-white py-0`}
              >
                <ul className="flex max-h-[300px] flex-col overflow-auto">
                  <div key="bulk-assignment">
                    <li
                      onClick={() => setBulkAssignModal(!bulkAssignModal)}
                      className={`text-gray-900text-md cursor-pointer items-center border-y border-slate-100 bg-white py-2 px-4  text-justify align-middle font-bold`}
                    >
                      <div className="flex items-center justify-between">
                        <p className="inline-block">Bulk Assignment</p>

                        <div className="right-1 inline-block">
                          <div className="text-green-700">
                            <FaArrowsAltH />
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>
                  <div key="bulk-delete">
                    <li
                      onClick={bulkDeleteLead}
                      className={`text-gray-900text-md cursor-pointer items-center border-y border-slate-100 bg-white py-2 px-4  text-justify align-middle font-bold`}
                    >
                      <div className="flex items-center justify-between">
                        <p className="inline-block">Bulk Delete</p>

                        <div className="right-1 inline-block">
                          <div className="text-red-700">
                            <FaTrashAlt />
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>
                  {/* <div key="pdf">
                  <li
                    onClick={() =>
                      leadTable.current.download("pdf", `Data.pdf`, {
                        orientation: "portrait",
                        title: "Package List",
                      })
                    }
                    className={`text-gray-900text-md items-center border-y border-slate-100 bg-white py-2 px-4 text-justify  align-middle font-bold`}
                  >
                    <div className="flex items-center justify-between">
                      <p className="inline-block">PDF</p>

                      <div className="right-1 inline-block">
                        <div className="text-red-500">
                          <FaFilePdf />
                        </div>
                      </div>
                    </div>
                  </li>
                </div> */}
                </ul>
              </div>
            </OutsideClick>
          </div>
        )}
        {/* Filter Inputs Starts here */}
        <div
          ref={filterRef}
          className={`flex grow flex-wrap  items-center gap-4 rounded-md rounded-tl-none dark:bg-slate-900 dark:text-white bg-white ${
            filterOpen ? "mb-4 border p-4" : "h-0 overflow-hidden p-0"
          }`}
        >
          <div>
            <InputLabel label="Lead Created" />
            <DateRangeInput
              controlBtns={true}
              horizontalPosition="left"
              onRangeChange={(startDate, endDate) =>
                updateFilter({
                  field: "created_at",
                  type: "in",
                  value: [
                    startDate
                      ? `${new Date(startDate).getFullYear()}-${
                          new Date(startDate).getMonth() + 1
                        }-${new Date(startDate).getDate()}`
                      : "",
                    endDate
                      ? `${new Date(endDate).getFullYear()}-${
                          new Date(endDate).getMonth() + 1
                        }-${new Date(endDate).getDate()}`
                      : "",
                  ],
                })
              }
            />
          </div>
          <div>
            <InputLabel label="Lead Source" />
            <SelectSearch
              value={
                selectedFilter.find(
                  (filter) => filter.field === "lead_source_id"
                )?.value
              }
              options={leadSources?.map((lead) => ({
                label: lead?.source,
                value: lead?.id,
              }))}
              onChange={(value) =>
                updateFilter({
                  field: "lead_source_id",
                  type: "=",
                  value: value,
                })
              }
            />
          </div>
          <div>
            <InputLabel label="Lead owner" />
            <SelectSearch
              value={
                selectedFilter.find((filter) => filter.field === "assigned_to")
                  ?.value
              }
              options={employees?.map((emp) => ({
                label: `${emp?.first_name} ${emp?.last_name}`,
                value: emp?.id,
              }))}
              onChange={(value) =>
                updateFilter({
                  field: "assigned_to",
                  type: "=",
                  value: value,
                })
              }
            />
          </div>
          <div>
            <InputLabel label="Lead Status" />
            <SelectSearch
              value={
                selectedFilter.find(
                  (filter) => filter.field === "lead_status_id"
                )?.value
              }
              options={leadStatus?.map((lead) => ({
                label: lead?.status,
                value: lead?.id,
              }))}
              onChange={(value) =>
                updateFilter({
                  field: "lead_status_id",
                  type: "=",
                  value: value,
                })
              }
            />
          </div>
          <div>
            <InputLabel label="Lead Category" />
            <SelectSearch
              value={
                selectedFilter.find(
                  (filter) => filter.field === "lead_category_id"
                )?.value
              }
              options={leadCategories?.map((category) => ({
                label: category?.category,
                value: category?.id,
              }))}
              onChange={(value) =>
                updateFilter({
                  field: "lead_category_id",
                  type: "=",
                  value: value,
                })
              }
            />
          </div>
          <div>
            <InputLabel label="Package" />
            <SelectSearch
              value={
                selectedFilter.find((filter) => filter.field === "package_id")
                  ?.value
              }
              options={pkgs?.map((pkg) => ({
                label: pkg?.title,
                value: pkg?.id,
              }))}
              onChange={(value) =>
                updateFilter({
                  field: "package_id",
                  type: "=",
                  value: value,
                })
              }
            />
          </div>
          <div>
            <InputLabel label="First Name" />
            <input
              type="text"
              className="field"
              onChange={(e) =>
                updateFilter({
                  field: "first_name",
                  type: "like",
                  value: e.target.value,
                })
              }
            />
          </div>
          <div>
            <InputLabel label="Last Name" />
            <input
              type="text"
              className="field"
              onChange={(e) =>
                updateFilter({
                  field: "last_name",
                  type: "like",
                  value: e.target.value,
                })
              }
            />
          </div>
          <div>
            <InputLabel label="Phone" />
            <input
              type="text"
              className="field"
              onChange={(e) =>
                updateFilter({
                  field: "phone",
                  type: "like",
                  value: e.target.value,
                })
              }
            />
          </div>
          <div>
            <InputLabel label="Email" />
            <input
              type="text"
              className="field"
              onChange={(e) =>
                updateFilter({
                  field: "email",
                  type: "like",
                  value: e.target.value,
                })
              }
            />
          </div>
          <div>
            <InputLabel label="Location" />
            <input
              type="text"
              className="field"
              onChange={(e) =>
                updateFilter({
                  field: "location",
                  type: "keywords",
                  value: e.target.value,
                })
              }
            />
          </div>
          <div>
            <InputLabel label="Page URL" />
            <input
              type="text"
              className="field"
              onChange={(e) =>
                updateFilter({
                  field: "page_url",
                  type: "keywords",
                  value: e.target.value,
                })
              }
            />
          </div>
          <div>
            <InputLabel label="By Form Type" />
            <input
              type="text"
              className="field"
              onChange={(e) =>
                updateFilter({
                  field: "form_type",
                  type: "keywords",
                  value: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="h-full">
        <div className="mt-2"></div>
        <DataTable
          ref={leadTable}
          module="lead"
          ajaxUrl={`${getAdminUrl()}/lead`}
          columns={columns}
          onCellEdit={updateRow}
          onDataSelection={onDataSelection}
          rowClick={onRowClick}
          search={false}
          downloadAble={false}
          rowFormatter={rowFormatter}
          initialSort={[{ column: "id", dir: "desc" }]}
        />
      </div>

      <RightSlidePanelProvider>
        <div className="h-full overflow-auto bg-white p-4 shadow-lg">
          <LeadForm
            ref={leadForm}
            openSlidePanel={openSlidePanel}
            formData={formData}
            setFormData={setFormData}
            onSuccess={onSuccess}
          />
        </div>
      </RightSlidePanelProvider>

      <Modal
        isOpen={bulkAssignModal}
        contentLabel="Update Participant Details"
        className="mx-auto mt-[2%] max-h-[90%] max-w-[75%] overflow-auto rounded-md bg-white p-4"
        overlayClassName="fixed top-0 left-0 w-full h-full !z-40 !bg-black/50"
        // onAfterClose={updateLocation}
      >
        <div className="flex items-center justify-between border-b p-4">
          <h2 className="text-xl font-bold">Assign Leads</h2>
          <button
            onClick={() => setBulkAssignModal(false)}
            className="cursor-pointer rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
        </div>

        <div className="mt-2">
          <div className="my-4">
            <label htmlFor="" className="flex items-center gap-4">
              <div className="font-semibold">Assign these leads to</div>
              <div>
                <SelectSearch
                  options={employees.map((emp) => ({
                    key: emp.id,
                    label: `${emp.first_name} ${emp.last_name}`,
                    value: emp.id,
                  }))}
                  onChange={(value) => {
                    leadBulkAssign(value);
                  }}
                />
              </div>
            </label>
          </div>
          <DataTable
            ref={assignLeadTable}
            columns={assignLeadcolumns}
            tableData={selectedData}
            search={false}
            selectable={false}
          />
        </div>
      </Modal>

      <Modal
        isOpen={bulkStatusUpdateModal}
        contentLabel="Update Participant Details"
        className="mx-auto mt-[2%] max-h-[90%] max-w-[75%] overflow-auto rounded-md bg-white p-4"
        overlayClassName="fixed top-0 left-0 w-full h-full !z-40 !bg-black/50"
        // onAfterClose={updateLocation}
      >
        <div className="flex items-center justify-between border-b p-4">
          <h2 className="text-xl font-bold">Update Status</h2>
          <button
            onClick={() => setBulkStatusUpdateModal(false)}
            className="cursor-pointer rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
        </div>

        <div className="mt-2">
          <div className="my-4">
            <label htmlFor="" className="flex items-center gap-4">
              <div className="font-semibold">Update Status</div>
              <div>
                <select
                  name=""
                  id=""
                  className="field"
                  onChange={bulkStatusUpdate}
                >
                  <option value=""></option>
                  <option value="randomly-enquired">Ramdomly Enquired</option>
                  <option value="dnp">DNP</option>
                  <option value="interested">Intrested</option>
                  <option value="tentative">Tentative</option>
                  <option value="about-to-book">About to Book</option>
                  <option value="cancelled-plan">Cancelled Plan</option>
                  <option value="price-issue">Price Issue</option>
                  <option value="converted">Converted</option>
                  <option value="planning-in-future">Planning in Future</option>
                  <option value="connected-via-whatsapp">
                    Connected via Whatsapp
                  </option>
                  <option value="connected-via-call">Connected via Call</option>
                </select>
              </div>
            </label>
          </div>
          <DataTable
            ref={assignLeadTable}
            columns={assignLeadcolumns}
            tableData={selectedData}
            search={false}
            selectable={false}
          />
        </div>
      </Modal>

      <Modal
        isOpen={fileImporterModal}
        contentLabel=""
        className="absolute top-1/2 left-1/2 h-fit max-h-[80vh] max-w-[90%] -translate-y-1/2 -translate-x-1/2 overflow-auto rounded-lg bg-white"
        overlayClassName="fixed top-0 left-0 w-full h-full !z-50 !bg-black/50"
      >
        <div className="relative px-8 py-4">
          <div className="flex items-center justify-between gap-12  border-b pb-4">
            <h2 className="text-xl font-bold">Import Leads</h2>
            <button
              onClick={() => setFileImporterModal(false)}
              className="cursor-pointer rounded-full border-2 p-2"
            >
              <MdClose />
            </button>
          </div>
          <div className="mt-4">
            <div className="">
              <label htmlFor="auto-assign" className="font-bold">
                <input
                  type="checkbox"
                  name=""
                  id="auto-assign"
                  checked={autoAssign}
                  onChange={(e) => setAutoAssign(e.target.checked)}
                />{" "}
                Auto Assign Imported Leads
              </label>
            </div>
            <FileImporter
              fileData={fileData}
              fileUploadPath="lead-csv"
              columns={leadColumns}
              setFileData={setFileData}
              columnMapping={columnMapping}
              setColumnMapping={setColumnMapping}
              onImportData={onImportData}
              setFileUploadedPath={setFileUploadedPath}
              skippedColumns={skippedColumns}
              setSkippedColumns={setSkippedColumns}
              sampleFile={SampleExcel}
              sampleFileName="lead-sample.csv"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default LeadList;
