import axios from "axios";
import { snakeCaseToCamelCase } from "./helpers/Helper";

let BASE_URL, ADMIN_URL, CSRF_NAME;

CSRF_NAME = process.env.REACT_APP_CSRF;

if (process.env.REACT_APP_ENVIRONMENT === "DEVELOPMENT") {
  BASE_URL = process.env.REACT_APP_TEST_BASE_URL;
  ADMIN_URL = process.env.REACT_APP_TEST_ADMIN_URL;
} else if (process.env.REACT_APP_ENVIRONMENT === "staging") {
  BASE_URL = process.env.REACT_APP_STAGING_BASE_URL;
  ADMIN_URL = process.env.REACT_APP_STAGING_ADMIN_URL;
} else {
  BASE_URL = process.env.REACT_APP_BASE_URL;
  ADMIN_URL = process.env.REACT_APP_ADMIN_URL;
}

const transformCase = (obj, replacer) =>
  Object.fromEntries(Object.entries(obj).map(([k, v]) => [replacer(k), v]));

export const client = axios.create({
  baseURL: BASE_URL,
  xsrfCookieName: CSRF_NAME,
});

client.interceptors.request.use(
  function (config) {
    if (localStorage.getItem("token")) {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// ------------------- Admin Configuration --------------------------------

export const admin = axios.create({
  baseURL: ADMIN_URL,
  xsrfCookieName: CSRF_NAME,
});

admin.interceptors.request.use(
  function (config) {
    if (localStorage.getItem("admin-token")) {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        "admin-token"
      )}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const getAdminUrl = () => {
  if (process.env.REACT_APP_ENVIRONMENT === "DEVELOPMENT") {
    return process.env.REACT_APP_TEST_ADMIN_URL;
  } else if (process.env.REACT_APP_ENVIRONMENT === "staging") {
    return process.env.REACT_APP_STAGING_ADMIN_URL;
  } else {
    return process.env.REACT_APP_ADMIN_URL;
  }
};

export const getUploadsUrl = () => {
  if (process.env.REACT_APP_ENVIRONMENT === "DEVELOPMENT") {
    return process.env.REACT_APP_TEST_UPLOADS_URL;
  } else if (process.env.REACT_APP_ENVIRONMENT === "staging") {
    return process.env.REACT_APP_STAGING_UPLOADS_URL;
  } else {
    return process.env.REACT_APP_UPLOADS_URL;
  }
};
