import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { assignPackagestoHomepageCategories, getHomePackageCategoryById } from '../../services/SettingsServices'
import { getAllPackages } from '../../services/PackageService'
import SelectSearch from '../inputs/SelectSearch'
import { toast } from 'react-toastify'
import { MdDelete } from "react-icons/md";
import Loader from '../../../components/Loader'

const AssignPackagetoCategory = () => {
  const id = useParams().id
  const [selectedPackagesArray, setSelectedPackagesArray] = useState([])
  const [allPackages, setAllPackages] = useState([])
  const [showingPackages, setShowingPcakages] = useState([])
  const [dropdownshowingPackages, setDropdownshowingPckages] = useState([])
  const [loading, setLoading] = useState(false)

  const initialCalltoPcakges = () => {
    setLoading(true)
    if (id) {
      getHomePackageCategoryById(id).then((res) => {
        setSelectedPackagesArray(res.data.data.packages)
      }).catch((er) => {console.log(er)})
    }
    getAllPackages().then((res) => {
      setAllPackages(res.data.data.map((pkg) => ({ id: pkg.id, value: pkg.id, label: pkg.title })))
    }).catch((er) => { console.log(er) })
  }

  useEffect(() => {
    initialCalltoPcakges()
  }, [])

  useEffect(() => {
    if (allPackages.length > 0 && selectedPackagesArray.length > 0) {
      const selectedIds = selectedPackagesArray.map(pkg => pkg.id);
      setShowingPcakages(allPackages.filter(package1 =>
        selectedIds.includes(package1.id)
      ));
      setLoading(false)
      setDropdownshowingPckages(allPackages.filter(package1 =>
        !selectedIds.includes(package1.id)
      ))
    }
  }, [selectedPackagesArray, allPackages])

  const handleAssignPcakges = () => {
    const submitData = {
      home_category_id: id,
      package_id: showingPackages.map(pkg => pkg.id).join(',')
    }
    assignPackagestoHomepageCategories(submitData).then(r => {
      initialCalltoPcakges()
      toast.success(r.data.message)
    }).catch(e => {
      toast.error("error in assigning packages")
    })
  }
  const arraysEqual = (a, b) => {
    if (a.length !== b.length) return false;
    const aIds = a.map(pkg => pkg.id).sort();
    const bIds = b.map(pkg => pkg.id).sort();
    for (let i = 0; i < aIds.length; i++) {
      if (aIds[i] !== bIds[i]) return false;
    }
    return true;
  };

  return (
    <div className="p-4 flex flex-col items-center w-full">
      <h2 className="text-2xl font-bold mb-4">Selected Packages</h2>
      <div className='m-6 lg:w-[100vw - 10rem] w-full'>
        <SelectSearch
          options={dropdownshowingPackages}
          onChange={(e) => {
            setShowingPcakages([...showingPackages, allPackages.find(pkg => pkg.id === e)])
          }}
          placeholder="Select Pckages to Assign this Homepage Category........"
          className="mb-4"
        /></div>
      {loading ? <Loader load={loading}/> : showingPackages.length > 0 ? (
        <ol className=" ml-6 space-y-2  grid lg:grid-cols-2 grid-cols-1 lg:gap-10 gap-2 w-full">
          {showingPackages.map(pkg => (
            <li key={pkg.id} className="p-2 bg-[#D7F2FD] border rounded-md flex flex-row justify-between items-center">
              <div>
                <span className="font-semibold">ID:</span> {pkg.id} <br />
                <span className="font-semibold">Name:</span> {pkg.label}
              </div>
              <div onClick={() => { setShowingPcakages(showingPackages.filter(packg => packg.id !== pkg.id)) }}>
                <MdDelete size={30} className='text-red-500 cursor-pointer' />
              </div>
            </li>
          ))}
        </ol>
      ) : (
        <p className="text-gray-600">No packages selected.</p>
      )}
      <button
        disabled={arraysEqual(showingPackages, selectedPackagesArray)}
        onClick={handleAssignPcakges}
        className="btn-primary m-6 w-[10rem]"
      >
        Submit
      </button>
    </div>
  )
}

export default AssignPackagetoCategory