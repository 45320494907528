import ComponentHeader from "../common/ComponentHeader";
import H2 from "../../../components/headings/H2";
import DataTable from "../common/DataTable";
import { useEffect } from "react";
import { getAdminUrl } from "../../../ServiceConfig";
import { useRef } from "react";
import $ from "jquery";
import { isObjectEmpty } from "../../../helpers/Helper";
import {
  deleteOfficialContacts,
} from "../../services/OfficialContactServices";
import { toast } from "react-toastify";

function GiftCardCategories() {
  const giftCardCategoryTable = useRef();

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
   <div class="gc-delete-btn cursor-pointer text-red-500" data-id=${cell.getRow().getData().id
      }>
        <i class="fas fa-trash-alt"></i>
      </div>
  `;
  };

  const deleteOfficialContact = (data) => {
    let contact = JSON.parse(data);
    if (isObjectEmpty(contact)) {
    } else {
      if (!window.confirm("Do You really want to delete this contact?")) return;
      deleteOfficialContacts(contact.id)
        .then((res) => {
          giftCardCategoryTable.current.deleteRow(contact.id);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };

  useEffect(() => {
    $(document).on("click", ".gc-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      deleteOfficialContact(dataId);
    });

    return () => {
      $(document).off("click", ".gc-delete-btn");
    };
  }, []);

  const isPhone = (cell, value, parameters) => {
    // if (value.toString().length !== 10)
    //   toast.error("Phone must be 10 characters");
    return value.toString().length === 10;
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Name",
      field: "name",
      minWidth: "100",
      vertAlign: "middle",
      editor: "input",
      editorParams: { autocomplete: "true", allowEmpty: false },
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      validator: "required",
      resizable: true,
    },

    {
      title: "Description",
      field: "description",
      minWidth: "100",
      vertAlign: "middle",
      editor: "input",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Status",
      field: "status",
      minWidth: "100",
      vertAlign: "middle",
      editor: "list",
      editorParams: { values: { 0: "0 - Inactive", 1: "1 - Active" } },
      responsive: 0,
      validator: "required",
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },

    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  const updateGiftCardCategory = (value) => {
    console.log(value, "vvvvvvvv===========");

    // updateOfficialContacts(value)
    //   .then((res) => {
    //     giftCardCategoryTable.current.updateTable();
    //   })
    //   .catch((err) => {
    //     toast.error(err.response.data.message);
    //   });
  };

  return (
    <div className="wrapper">
      {/* <div className="h-full max-h-[calc(100%-1rem)] overflow-auto"> */}
        <ComponentHeader className="border-b">
          <H2 className="tracking-wide">Gift Card Categories</H2>
        </ComponentHeader>
        <div className="">
          <DataTable
            ref={giftCardCategoryTable}
            ajaxUrl={`${getAdminUrl()}/gift-card/category`}
            layout="fitColumns"
            columns={columns}
            search={true}
            onCellEdit={updateGiftCardCategory}
            addRowBtn="Add New Category"
            responsiveLayout="collapse"
          />
        </div>
      {/* </div> */}
    </div>
  );
}

export default GiftCardCategories;
