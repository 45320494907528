import Select from "react-select";

function SelectSearch({
  id,
  options,
  value,
  onChange,
  onFocus,
  onBlur,
  disabled,
  className,
  label,
  placeholder
}) {
  return (
    <div className="bg-white !py-0 !px-0 dark:bg-transparent">
      <Select
        id={id}
        isDisabled={disabled}
        classNamePrefix={className || "react-select"}
        value={
          value
            ? {
                value: value,
                label: options?.filter((opt) => opt.value === value)[0]?.label,
              }
            : null
        }
        options={options ? [{ label: `Select ${label ? label : ''}`, value: "" }, ...options] : []}
        onChange={(v) => onChange(v.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        ariaAutocomplete={false}
        placeholder={placeholder}
      ></Select>
    </div>
  );
}

export default SelectSearch;
