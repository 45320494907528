import { Outlet } from "react-router-dom";

function TeamWrapper() {
  return (
    <div className="wrapper">
      {/* <div className="h-full max-h-[calc(100%-1rem)] overflow-auto"> */}
        {<Outlet />}
      {/* </div> */}
    </div>
  );
}

export default TeamWrapper;
// 2xl:min-h-[92vh] xl:min-h-[90vh] lg:min-h-[88vh]