import { Link, useNavigate } from "react-router-dom";
import ComponentHeader from "../common/ComponentHeader";
import H2 from "../../../components/headings/H2";
import { FaListAlt } from "react-icons/fa";
import InternalNotificationForm from "./InternalNotificationForm";


function AddINternalNotification() {
  const navigate = useNavigate();
  // const [authorized, setAuthorized] = useState(false);

  // const { setLoading } = useLoader();

  // useEffect(() => {
  //   setLoading(true);
  //   checkPermission("B2B.create")
  //     .then((res) => {
  //       setAuthorized(res.data.data);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       toast.error(err.response.data.message);
  //     });
  // }, []);
  const onAddState = (data) => {
    navigate(`/internal-notification`);
  };

  return (
    //    !authorized ? (
    //   <NotAuthorized403 />
    // ) : (
    <div className="">
      {/* <MetaTags title="Add Employee | Admin Panel" /> */}
      {/* <Helmet>
        <title>Add Employee</title>
      </Helmet> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Add Internal Notification</H2>
        <Link
          to="/internal-notification"
          className="btn-primary flex dark:bg-green-800 items-center gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>

      <InternalNotificationForm onSuccess={(data) => onAddState(data)} />
    </div>
    // )
  );
}

export default AddINternalNotification;
