import { admin } from "../../ServiceConfig";

export function getAllPermissions(query) {
    return admin.get(`/permissions?query=${query}`)
}
export function getAllPermissionsOption() {
    return admin.get(`/allPermissionsOption`)
}
export function createPermission(data) {
    return admin.post(`/permissions`, data)
}
export function updatePermission(id, data) {
    return admin.put(`/permissions/${id}`, data)
}
export function deletePermission(id) {
    return admin.delete(`/permissions/${id}`)
}
export function getPermissionById(id) {
    return admin.get(`/permissions/${id}`)
}

