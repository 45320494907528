import React, { useEffect, useState } from 'react'
import { getCurrentlyHiring } from '../../services/DashboardServices'
import SingleJobPost from './SingleJobPost'

const CurrentlyHiring = () => {
    const [currentlyHiringData, setCurrentlyHiringData] = useState()
    useEffect(() => {
        getCurrentlyHiring().then(res => {
            console.log(res.data.data)
            setCurrentlyHiringData(res.data.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [])
    return (
        <div>
            <div className="flex flex-col rounded-lg bg-white dark:border-slate-700 border">
                <div className="card-header-background bg- flex items-center justify-between border-b bg-gray-100 dark:bg-slate-900 dark:border-slate-700 dark:border-b dark:text-white p-4">
                    <h3 className="font-bold">Currently Hiring</h3>
                </div>
                <div className="max-h-[50vh] overflow-auto dark:bg-gray-600 px-2">
                    <div className="h-96 flex flex-col items-center  gap-3 py-4">
                        {
                            currentlyHiringData?.map((onePost) =>
                                <SingleJobPost onePost={onePost}/>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CurrentlyHiring