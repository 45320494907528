import { ErrorMessage, Field, FieldArray, Formik, Form } from "formik";
import { FaPlusSquare } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import usePackageData from "../../../hooks/usePackageData";
import {
  createPackageFaqs,
  DeleteFaq,
  getPackageFaqs,
  reorderFaqList,
  updateFaqById,
} from "../../../services/PackageService";
import EditorInput from "../../inputs/EditorInput";
import InputLabel from "../../inputs/InputLabel";
import * as Yup from "yup";
import { useEffect } from "react";
import { useState } from "react";
import DragAndDropList from "../../../../components/dragAndDrop/DragAndDropList";
import { Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";

const packageFaqSchema = Yup.object().shape({
  question: Yup.string().required("Required"),
  answer: Yup.string().required("Required"),
});

function Step7({ onSuccess }) {
  const { packageData } = usePackageData();

  const [faqs, setFaqs] = useState([]);

  const addPackageFaqs = (values) => {
    values.package_id = packageData.package.id;

    createPackageFaqs(values)
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.log(err, "erererere---------");
      });
  };

  const updateFaq = (value) => {
    if (value?.id) {
      updateFaqById(value.id, value).then((res) => {
        console.log(res.data.data);
        toast.success(res.data.message);
      });
    }
  };

  const deleteFaq = (index) => {
    if (faqs[index].id) {
      if (!window.confirm("Do You really want to delete this FAQ?")) {
        return false;
      } else {
        DeleteFaq(faqs[index].id).then((res) => {
          console.log(res.data.data);
          toast.success(res.data.message);
        });
        return true;
      }
    } else {
      let fqs = [...faqs];
      fqs.splice(index, 1);
      setFaqs(fqs);
    }
  };

  useEffect(() => {
    if (packageData.package?.id) {
      getPackageFaqs(packageData.package.id).then((res) => {
        if (res.data.data.length > 0) {
          res.data.data.forEach((data) => {
            data.order = data.order.toString();
          });
          setFaqs(res.data.data);
        } else {
          setFaqs([{ question: "", answer: "" }]);
        }
      });
    } else {
      setFaqs([{ question: "", answer: "" }]);
    }
  }, []);

  const addFaq = () => {
    let fqs = [...faqs];

    fqs.push({ question: "", answer: "", order: `${faqs.length + 1}` });

    console.log(fqs, "oooooooooooooooo");
    setFaqs(fqs);
  };

  const handleReorder = (startIndex, endIndex) => {
    const reorderedItems = Array.from(faqs);
    const [removed] = reorderedItems.splice(startIndex, 1);
    reorderedItems.splice(endIndex, 0, removed);
    console.log(reorderedItems);
    setFaqs(reorderedItems);

    let newOrder = [];
    reorderedItems.forEach((items, index) => {
      newOrder.push({ id: items.id, order: index + 1 });
    });

    reorderFaqList(newOrder)
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className="my-4">
      <DragAndDropList items={faqs} onReorder={handleReorder}>
        {faqs?.map((faq, index) => (
          <Draggable key={faq.id} draggableId={faq.order} index={index}>
            {(provided) => (
              <li
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <div className="mb-8 rounded-lg border-2 p-4">
                  <Formik
                    enableReinitialize
                    initialValues={{
                      id: faq.id ? faq.id : null,
                      question: faq.question ? faq.question : "",
                      answer: faq.answer ? faq.answer : "",
                      order: faq.order ? faq.order : "1",
                    }}
                    validationSchema={packageFaqSchema}
                    onSubmit={(values) => {
                      faq.id ? updateFaq(values) : addPackageFaqs(values);
                    }}
                  >
                    {({ values, setFieldValue, errors, touched }) => (
                      <Form>
                        <div className="relative mb-2 border-b pb-2 text-center font-bold">
                          <h3>{index + 1}. FAQ</h3>

                          <button
                            onClick={() => deleteFaq(index)}
                            className="absolute top-1/2 right-2 -translate-y-1/2 text-xs text-red-500"
                          >
                            DELETE
                          </button>
                        </div>
                        {/* //? Faq Question */}
                        <div className="">
                          <InputLabel label="Question" />
                          <Field
                            name={`question`}
                            type="text"
                            className="field"
                            // onBlur={() => updateFaq(values.faqs[index])}
                          />
                          <ErrorMessage name={`question`}>
                            {(msg) => <div className="error-msg">{msg}</div>}
                          </ErrorMessage>
                        </div>

                        {/* //? Itinerary Description */}
                        <div className="my-4">
                          <InputLabel label="Answer" />
                          <EditorInput
                            fieldKey={`answer`}
                            data={values.answer}
                            // onBlur={() => updateFaq(values.faqs[index])}
                          />
                        </div>

                        <div className="mt-4 text-right">
                          <button
                            type="submit"
                            className="btn-primary border border-green-400 bg-green-50 px-8 py-1 text-jw-green"
                          >
                            {values.id ? "Update" : "Add"}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </li>
            )}
          </Draggable>
        ))}
      </DragAndDropList>

      <div className="text-left">
        <button
          onClick={addFaq}
          className="-mt-6 flex items-center gap-1 rounded-lg  border-jw-green px-4 text-jw-green"
        >
          <FaPlusSquare /> Add Faq
        </button>
      </div>

      <div className="text-center">
        <button onClick={onSuccess} className="btn-primary">
          Next
        </button>
      </div>
    </div>
  );
}

export default Step7;
