import { admin } from "../../ServiceConfig";

export function getAllRiders(query) {
    return admin.get(`/riders?query=${query}`)
}
export function getAllRidersOption() {
    return admin.get(`/allRidersOption`)
}
export function createRider(data) {
    return admin.post(`/riders`, data)
}
export function updateRider(id, data) {
    return admin.put(`/riders/${id}`, data)
}
export function deleteRider(id) {
    return admin.delete(`/riders/${id}`)
}
export function getRiderById(id) {
    return admin.get(`/riders/${id}`)
}

