import { useEffect, useState } from "react";
import { getAllInternalNotifications } from "../../services/InternalNotificationsServices";
import VerticalMarquee from "../common/VerticalMarquee ";
import { getAllRewards, getRnRDashboard } from "../../services/RewardsAndRecognitionServices";
import moment from "moment";
import { getUploadsUrl } from "../../../ServiceConfig";
import SingleRewardAndRecognition from "./SingleRewardAndRecognition";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import useScreenSize from "../../../hooks/useScreenSize";

function RewardsAndRecognition() {
    const [currentRnRWinners, setCurrentRnRWinners] = useState()
    const [upcomingRnRDate, setUpcomingRnrDate] = useState()
    const size = useScreenSize()
    const getRnRDashboardData = () => {
        getRnRDashboard().then((res) => {
            setCurrentRnRWinners(res.data.data.currentMonth.rnrwinners)
            setUpcomingRnrDate(res.data.data.nextMonth.proposed_at)
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        getRnRDashboardData()
    }, [])
    return (
        <div>
            <div className="flex flex-col rounded-lg bg-white dark:border-slate-700 border">
                <div className="card-header-background bg- flex lg:items-center justify-between border-b lg:flex-row flex-col bg-gray-100 dark:bg-slate-900 dark:border-slate-700 dark:border-b dark:text-white p-4">
                    <h3 className="font-bold">Current RnR Winners</h3>
                    <h4 className="font-semibold  text-sm">Upcoming RnR - {moment(upcomingRnRDate).format("DD MMMM YYYY")}</h4>
                </div>
                <div className="flex flex-col items-center w-full">
                    <div className="h-96 w-full flex justify-center items-center p-4">
                        <Swiper
                            navigation={true}
                            modules={[Autoplay, Scrollbar, Navigation]}
                            direction={'horizontal'}
                            pagination={{
                                clickable: true,
                            }}
                            slidesPerView={size.breakpoint === '2xl' ? 2 : size.breakpoint === 'xl' ? 2 : 1}
                            effect={'fade'}
                            spaceBetween={10}
                            autoplay={{ delay: 3000 }}
                            className="w-full h-full"
                        >
                            {currentRnRWinners?.length > 0 && currentRnRWinners?.map((winner, index) => (
                                <SwiperSlide key={winner.id} className="flex">
                                    <SingleRewardAndRecognition key={winner.id} winner={winner} />
                                </SwiperSlide>
                            ))}

                        </Swiper>

                        {/* {currentRnRWinners?.length > 0 && currentRnRWinners?.map((winner, index) =>
                            <SingleRewardAndRecognition key={winner.id} winner={winner} />
                        )} */}
                    </div>
                </div>
            </div>
        </div>
    );
}



export default RewardsAndRecognition;
