import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import DataTable from "../common/DataTable";
import { getAdminUrl, getUploadsUrl } from "../../../ServiceConfig";
import ComponentHeader from "../common/ComponentHeader";
import H2 from "../../../components/headings/H2";
import { FaPlusSquare } from "react-icons/fa";
import { deleteStayById } from "../../services/StayServices";
import { getReadableDate } from "../../../helpers/Helper";
import { deleteCustomPackage } from "../../services/CustomPackageServices";
import InputLabel from "../inputs/InputLabel";
import DateRangeInput from "../inputs/DateRangeInput";
import SelectSearch from "../inputs/SelectSearch";
import { getAllEmployeesOption } from "../../services/EmployeeServices";
import { getAllCountriesOption } from "../../services/CountryServices";
import { getAllStateByCountry } from "../../services/StateServices";

function ItineraryList() {
  const navigate = useNavigate();
  const customPackageTable = useRef();
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [countries, setCountries] = useState();
  const [selectedCountryStates, setSelectedCountryStates] = useState();

  useEffect(() => {
    getAllEmployeesOption()
      .then((res) => {
        setEmployees(res.data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getAllCountriesOption()
      .then((res) => {
        setCountries(res.data.data);
      })
      .catch((err) => {
        console.log(err, "Error---------------");
      });
    customPackageTable.current.setFilter(selectedFilter);
  }, [selectedFilter]);

  // ?--------------- Fetch State By CountryId ---------------
  const getStatesByCountry = (countryId) => {
    getAllStateByCountry(countryId)
      .then((res) => {
        setSelectedCountryStates(res.data.data);
      })
      .catch((err) => {});
  };

  const deleteSelectedStay = (id) => {
    if (!window.confirm("Do You really want to delete this custom package?"))
      return;
    deleteCustomPackage(id)
      .then((res) => {
        toast.success(res.data.message);
        customPackageTable.current.deleteRow(id);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const updateFilter = (filter) => {
    let index = selectedFilter.findIndex((sf) => sf.field === filter.field);

    if (index >= 0) {
      if (filter.value && !Array.isArray(filter.value)) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else if (Array.isArray(filter.value) && filter.value[0]) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else {
        setSelectedFilter(
          selectedFilter.filter((af) => af.field !== filter.field)
        );
      }
    } else {
      setSelectedFilter([...selectedFilter, filter]);
    }
  };

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
    <div class="flex items-center gap-3">
    <div class="custom-itinerary-view-btn cursor-pointer" data-id=${
      cell.getRow().getData().id
    }>
          <i class="fas fa-eye"></i>
      </div>
      <div class="custom-itinerary-edit-btn cursor-pointer" data-id=${
        cell.getRow().getData().id
      }>
          <i class="fas fa-edit"></i>
      </div>
      <div class="custom-itinerary-delete-btn cursor-pointer text-red-500" data-id=${
        cell.getRow().getData().id
      }>
        <i class="fas fa-trash-alt"></i>
      </div>
  </div>`;
  };

  const printStar = (cell, formatterParams) => {
    let html = `<div class="flex items-center text-yellow-500 text-sm">`;
    cell.getData();
    for (let i = 0; i < cell.getData().star_category; i++) {
      html += `<i class="fas fa-star"></i>`;
    }

    html += `</div>`;
    return html;
  };

  const isFrozen = window.innerWidth <= 768 ? false : true;

  const columns = [
    // {
    //   formatter: "responsiveCollapse",
    //   width: 30,
    //   minWidth: 30,
    //   responsive: 0,
    //   hozAlign: "center",
    //   resizable: false,
    //   headerSort: false,
    // },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Package Detail",
      field: "package_title",
      minWidth: "250",
      vertAlign: "middle",
      hozAlign: "center",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      formatter: "textarea",
    },
    {
      title: "Package Type",
      minWidth: "130",
      field: "package_type",
      resizable: true,
      responsive: 0,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getValue() === 0) {
          return "Domestic";
        } else {
          return "International";
        }
      },
    },
    {
      title: "Country",
      field: "country_name",
      minWidth: "120",
      vertAlign: "middle",
      hozAlign: "center",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      formatter: "textarea",
    },
    {
      title: "State",
      field: "state_name",
      minWidth: "120",
      vertAlign: "middle",
      hozAlign: "center",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      formatter: "textarea",
    },
    {
      title: "User Detail",
      field: "first_name",
      minWidth: "200",
      vertAlign: "middle",
      hozAlign: "center",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      formatter: function (cell, formatterParams, onRendered) {
        return `${cell.getData().first_name ?? ""} ${
          cell.getData().last_name ?? ""
        } </br> ${cell.getData().phone ?? ""} </br> ${
          cell.getData().email ?? ""
        } `;
      },
    },
    {
      title: "Starting Location",
      minWidth: "170",
      field: "starting_location",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",

      formatter: "textarea",
    },
    {
      title: "Ending Location",
      minWidth: "170",
      field: "ending_location",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: "textarea",
    },
    {
      title: "Start Date",
      minWidth: "130",
      responsive: 0,
      field: "start_date",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: function (cell, formatterParams, onRendered) {
        return `${getReadableDate(cell.getValue())}`;
      },
    },
    {
      title: "End Date",
      field: "end_date",
      minWidth: "130",
      responsive: 0,
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return `${getReadableDate(cell.getValue())}`;
      },
    },
    {
      title: "Total Pax",
      minWidth: "100",
      field: "total_pax",

      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
    },
    {
      title: "Point of Contact",
      field: "point_of_contact.first_name",
      minWidth: "240",
      vertAlign: "middle",
      headerSort: false,
      resizable: true,
      formatter: function (cell, formatterParams, onRendered) {
        return `${cell.getData().point_of_contact.first_name ?? ""} ${
          cell.getData().point_of_contact.last_name ?? ""
        } </br> ${cell.getData().point_of_contact.phone ?? ""} </br> ${
          cell.getData().point_of_contact.email ?? ""
        } `;
      },
    },
    {
      title: "Created By",
      field: "created_by.first_name",
      minWidth: "240",
      vertAlign: "middle",
      responsive: 0,
      headerSort: false,
      resizable: true,
      formatter: function (cell, formatterParams, onRendered) {
        return `${cell.getData().created_by.first_name ?? ""} ${
          cell.getData().created_by.last_name ?? ""
        } </br> ${cell.getData().created_by.phone ?? ""} </br> ${
          cell.getData().created_by.email ?? ""
        } `;
      },
    },
    {
      title: "Updated By",
      field: "last_updated_by.first_name",
      minWidth: "240",
      vertAlign: "middle",
      responsive: 0,
      headerSort: false,
      resizable: true,
      formatter: function (cell, formatterParams, onRendered) {
        return `${cell.getData().last_updated_by.first_name ?? ""} ${
          cell.getData().last_updated_by.last_name ?? ""
        } </br> ${cell.getData().last_updated_by.phone ?? ""} </br> ${
          cell.getData().last_updated_by.email ?? ""
        } `;
      },
    },

    {
      title: "Costing Type",
      minWidth: "160",
      responsive: 0,
      field: "price_type",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getValue() === "per_person") {
          return "Per Person Cost";
        } else {
          return "Total Cost";
        }
      },
    },
    {
      title: "Cost",
      minWidth: "80",
      responsive: 0,
      field: "price",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Booking Amount",
      minWidth: "150",
      field: "booking_amount",
      responsive: 0,
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
    },
    {
      formatter: actionIcons,
      headerSort: false,
      minWidth: "100",
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
      frozen: isFrozen,
    },
  ];

  useEffect(() => {
    $(document).on("click", ".custom-itinerary-view-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/itinerary-builder/${cell}`);
    });

    $(document).on("click", ".custom-itinerary-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/itinerary-builder/edit/${cell}`);
    });

    $(document).on("click", ".custom-itinerary-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      deleteSelectedStay(dataId);
    });

    return () => {
      $(document).off("click", ".custom-itinerary-edit-btn");
      $(document).off("click", ".custom-itinerary-delete-btn");
    };
  }, []);

  return (
    <div className="flex h-full flex-col">
      <ComponentHeader className="border-b">
        <H2>Custom Package Itineraries</H2>
        <Link
          to="/itinerary-builder/add"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaPlusSquare /> Add New
        </Link>
      </ComponentHeader>
      <div className="h-full">
        <div className="my-8 rounded-md">
          <button
            onClick={() => setFilterOpen(!filterOpen)}
            className={` inline-block whitespace-nowrap rounded-md border bg-white p-8 py-2 text-lg font-semibold tracking-wide dark:bg-slate-900 dark:text-white ${
              filterOpen
                ? "border-b-1 -mb-[1px] rounded-b-none px-8 pb-1 pt-2"
                : ""
            }`}
          >
            Filters
          </button>
          <div
            className={`flex grow flex-wrap  items-center gap-4 rounded-md rounded-tl-none bg-white dark:bg-slate-900 dark:text-white ${
              filterOpen ? "mb-4 border p-4" : "h-0 overflow-hidden p-0"
            }`}
          >
            <div>
              <InputLabel label="User First Name" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "first_name",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="User Last Name" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "last_name",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="User Phone" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "phone",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="User Email" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "email",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Country" />
              <SelectSearch
                value={
                  selectedFilter.find((filter) => filter.field === "country_id")
                    ?.value
                }
                options={countries?.map((country) => ({
                  label: `${country?.name}`,
                  value: country?.id,
                }))}
                onChange={(value) => {
                  getStatesByCountry(value);
                  updateFilter({
                    field: "country_id",
                    type: "=",
                    value: value,
                  });
                }}
              />
            </div>
            {selectedCountryStates?.length > 0 && (
              <div className="min-w-[250px]">
                <InputLabel label="State" />
                <SelectSearch
                  value={
                    selectedFilter.find((filter) => filter.field === "state_id")
                      ?.value
                  }
                  options={selectedCountryStates?.map((state) => ({
                    label: `${state?.name}`,
                    value: state?.id,
                  }))}
                  onChange={(value) =>
                    updateFilter({
                      field: "state_id",
                      type: "=",
                      value: value,
                    })
                  }
                />
              </div>
            )}
            <div>
              <InputLabel label="Package Title" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "package_title",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Created Between Date Range" />
              <DateRangeInput
                controlBtns={true}
                horizontalPosition="left"
                onRangeChange={(startDate, endDate) =>
                  updateFilter({
                    field: "created_at",
                    type: "in",
                    value: [
                      startDate
                        ? `${new Date(startDate).getFullYear()}-${
                            new Date(startDate).getMonth() + 1
                          }-${new Date(startDate).getDate()}`
                        : "",
                      endDate
                        ? `${new Date(endDate).getFullYear()}-${
                            new Date(endDate).getMonth() + 1
                          }-${new Date(endDate).getDate()}`
                        : "",
                    ],
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Start Date Range" />
              <DateRangeInput
                controlBtns={true}
                horizontalPosition="left"
                onRangeChange={(startDate, endDate) =>
                  updateFilter({
                    field: "start_date",
                    type: "in",
                    value: [
                      startDate
                        ? `${new Date(startDate).getFullYear()}-${
                            new Date(startDate).getMonth() + 1
                          }-${new Date(startDate).getDate()}`
                        : "",
                      endDate
                        ? `${new Date(endDate).getFullYear()}-${
                            new Date(endDate).getMonth() + 1
                          }-${new Date(endDate).getDate()}`
                        : "",
                    ],
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="End Date Range" />
              <DateRangeInput
                controlBtns={true}
                horizontalPosition="left"
                onRangeChange={(startDate, endDate) =>
                  updateFilter({
                    field: "end_date",
                    type: "in",
                    value: [
                      startDate
                        ? `${new Date(startDate).getFullYear()}-${
                            new Date(startDate).getMonth() + 1
                          }-${new Date(startDate).getDate()}`
                        : "",
                      endDate
                        ? `${new Date(endDate).getFullYear()}-${
                            new Date(endDate).getMonth() + 1
                          }-${new Date(endDate).getDate()}`
                        : "",
                    ],
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Package Type" />
              <SelectSearch
                value={
                  selectedFilter.find(
                    (filter) => filter.field === "package_type"
                  )?.value
                }
                options={[
                  { label: "Domestic", value: 0 },
                  { label: "International", value: 1 },
                ]}
                onChange={(value) =>
                  updateFilter({
                    field: "package_type",
                    type: "=",
                    value: value,
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Costing Type" />
              <SelectSearch
                value={
                  selectedFilter.find((filter) => filter.field === "price_type")
                    ?.value
                }
                options={[
                  { label: "Total Cost", value: "total" },
                  { label: "Per Person Cost", value: "per_person" },
                ]}
                onChange={(value) =>
                  updateFilter({
                    field: "price_type",
                    type: "=",
                    value: value,
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Created By" />
              <SelectSearch
                value={
                  selectedFilter.find((filter) => filter.field === "created_by")
                    ?.value
                }
                options={employees?.map((emp) => ({
                  label: `${emp?.first_name} ${emp?.last_name}`,
                  value: emp?.id,
                }))}
                onChange={(value) =>
                  updateFilter({
                    field: "created_by",
                    type: "=",
                    value: value,
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Point Of Contact" />
              <SelectSearch
                value={
                  selectedFilter.find(
                    (filter) => filter.field === "point_of_contact"
                  )?.value
                }
                options={employees?.map((emp) => ({
                  label: `${emp?.first_name} ${emp?.last_name}`,
                  value: emp?.id,
                }))}
                onChange={(value) =>
                  updateFilter({
                    field: "point_of_contact",
                    type: "=",
                    value: value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="Starting Location" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "starting_location",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="Ending Location" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "ending_location",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="Number of Nights" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "no_of_nights",
                    type: "=",
                    value: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <DataTable
          ref={customPackageTable}
          ajaxUrl={`${getAdminUrl()}/custom-itinerary`}
          columns={columns}
          search={false}
          // responsiveLayout="collapse"
          selectable={false}
        />
      </div>
    </div>
  );
}

export default ItineraryList;
