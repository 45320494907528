import { ErrorMessage, Field, Form, Formik } from "formik";
import { useRef } from "react";
import { getReadableDate } from "../../../helpers/Helper";
import { updateBatch } from "../../services/EventDatesServices";
import DateRangeInput from "../inputs/DateRangeInput";
import InputLabel from "../inputs/InputLabel";

function UpdateEventDate({ batch, onUpdate }) {
  const dateInputRef = useRef();

  const updateBatches = (values) => {
    updateBatch(values.batch).then((res) => {
      dateInputRef.current.resetDates();
      onUpdate();
    });
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          batch: batch,
        }}
        // validationSchema={batchSchema}
        onSubmit={(values, { resetForm }) => {
          //   alert(JSON.stringify(values, null, 2));
          updateBatches(values);
          resetForm({
            values: {
              batch: {
                startdate: "",
                lastdate: "",
                availability: "0",
                total_slots: 12,
              },
            },
          });
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <h4 className=" my-4 text-lg font-semibold">Update Batches</h4>
            <div className="mb-2 rounded-lg border-2 p-4">
              <div className="grow basis-full lg:basis-[30%]">
                <InputLabel label="Select Batch" />
                <DateRangeInput
                  ref={dateInputRef}
                  startDate={getReadableDate(values.batch.startdate)}
                  endDate={getReadableDate(values.batch.lastdate)}
                  onRangeChange={(startDate, endDate) => {
                    setFieldValue(
                      `batch.startdate`,
                      `${new Date(startDate).getFullYear()}-${
                        new Date(startDate).getMonth() + 1
                      }-${new Date(startDate).getDate()}`
                    );
                    setFieldValue(
                      `batch.lastdate`,
                      `${new Date(endDate).getFullYear()}-${
                        new Date(endDate).getMonth() + 1
                      }-${new Date(endDate).getDate()}`
                    );
                  }}
                />
                <ErrorMessage name={`batch.startdate`}>
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
                <ErrorMessage name={`batch.lastdate`}>
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              <div className="my-4">
                <InputLabel label="Select Availability" />
                <Field
                  as="select"
                  name={`batch.availability`}
                  className="block w-full rounded-md border-2 py-1 px-2"
                >
                  <option></option>
                  <option value="0">Available</option>
                  <option value="2">Fast Filling</option>
                  <option value="1">Full</option>
                </Field>

                <ErrorMessage name={`batch.availability`}>
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              <div className="grow basis-full lg:basis-[30%]">
                <InputLabel label="Enter Available Slots" />
                <Field
                  name={`batch.total_slots`}
                  className="block w-full rounded-md border-2 py-1 px-2"
                />

                <ErrorMessage name={`batch.total_slots`}>
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>

            <div className="text-right">
              <button
                type="submit"
                className="btn-primary inline-flex items-center"
              >
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default UpdateEventDate;
