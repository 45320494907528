import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import "../../../assets/css/daterange.css";

import OutsideClick from "../../../hooks/OutsideClick";
import { DateRange } from "react-date-range";
import { FaCalendarAlt } from "react-icons/fa";
import { useState } from "react";
import { getReadableDate } from "../../../helpers/Helper";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { addDays } from "date-fns";
import moment from "moment/moment";

const DateRangeInput = forwardRef((props, ref) => {
  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: props.dayToAdd > 0 ? addDays(new Date(), props.dayToAdd) : null,
      key: "selection",
    },
  ]);

  const [isDateRangeActive, setIsDateRangeActive] = useState(false);
  const [startDate, setStartDate] = useState(
    props.startDate ? getReadableDate(props.startDate) : ""
  );
  const [endDate, setEndDate] = useState(
    props.endDate ? getReadableDate(props.endDate) : ""
  );

  useImperativeHandle(ref, () => ({
    resetDates() {
      setStartDate(null);
      setEndDate(null);
    },
  }));

  // useEffect(() => {
  //   props.startDate && props.endDate &&
  // }, [props.startDate, props.endDate])

  const handleDateRangeChange = (item) => {
    if (item.selection.startDate) {
      // console.log("Selected start date:", item.selection.startDate);
      // console.log(
      //   "End date will be:",
      //   addDays(item.selection.startDate, props.dayToAdd)
      // );
      // setStartDate(ranges.selection.startDate);
      // setEndDate(endDate);
      if (props.dayToAdd && props.dayToAdd > 0) {
        item.selection.endDate = addDays(
          item.selection.startDate,
          props.dayToAdd
        );
        setIsDateRangeActive(false);
      } else {
        // setEndDate(endDate);
      }
    }
    // if (item.selection.endDate) {
    //   setIsDateRangeActive(false);
    // }
    setDateState([item.selection]);

    let startDate = getReadableDate(item.selection.startDate);

    let endDate = getReadableDate(item.selection.endDate);

    setStartDate(startDate);
    setEndDate(endDate);

    if (!props.controlBtns) {
      props.onRangeChange(item.selection.startDate, item.selection.endDate);
    }
  };

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    setDateState([
      {
        startDate: new Date(),
        endDate:
          props.dayToAdd > 0 ? addDays(new Date(), props.dayToAdd) : null,
        key: "selection",
      },
    ]);
    setIsDateRangeActive(false);
    props.onRangeChange(null, null);
  };

  return (
    <div>
      <OutsideClick onClick={() => setIsDateRangeActive(false)}>
        <div className={`relative ${isDateRangeActive ? "z-30" : "z-0"}`}>
          <div
            onClick={() => setIsDateRangeActive(!isDateRangeActive)}
            className="flex w-full items-center rounded-md border py-1.5 px-2"
          >
            <div className={`grow ${props.dateClassName}`}>
              {startDate ? moment(startDate).format("DD/MM/YY") : "From"} -{" "}
              {"\n "}
              {endDate ? moment(endDate).format("DD/MM/YY") : "To"}
            </div>
            <div>
              <FaCalendarAlt />
            </div>
          </div>
          <div
            className={`absolute ${
              props.horizontalPosition == "left" ? "left-0" : "right-0"
            } top-full  overflow-hidden rounded-md transition-all duration-300 ${
              isDateRangeActive ? "z-10 h-fit border-2" : "z-0 h-0"
            }`}
          >
            <DateRange
              className="!text-sm"
              editableDateInputs={true}
              minDate={props.minDate}
              maxDate={props.maxDate}
              onChange={handleDateRangeChange}
              moveRangeOnFirstSelection={true}
              ranges={dateState}
              direction="vertical"
            />
            {props?.controlBtns && (
              <div className="flex items-center justify-end bg-white py-2 text-white dark:bg-slate-900">
                <button
                  onClick={handleReset}
                  className="mx-1 rounded-sm bg-orange-500 px-4 py-1 "
                >
                  Reset
                </button>
                <button
                  onClick={() => {
                    props.onRangeChange(startDate, endDate);
                    setIsDateRangeActive(false);
                  }}
                  className="mx-1 rounded-sm bg-jw-green px-4 py-1"
                >
                  Apply
                </button>
              </div>
            )}
          </div>
        </div>
      </OutsideClick>
    </div>
  );
});

export default DateRangeInput;
