import React, { useEffect, useState } from "react";
import FileInput from "../../components/inputFields/FileInput";
import { toast } from "react-toastify";
import InputLabel from "../components/inputs/InputLabel";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import SelectSearch from "../components/inputs/SelectSearch";
import DateInput from "../components/inputs/DateInput";
import {
  addEmployee,
  changePassword,
  getAllEmployee,
  getAllEmployeesOption,
  getAllUnassignedContacts,
  updateEmployee,
  uploadEmployeeCutOutImage,
  uploadEmployeeImage,
} from "../services/EmployeeServices";
import { getAllDepartments } from "../services/DepartmentServices";
import { formatNumberToTwoDigit } from "../../helpers/Helper";
import { getUploadsUrl } from "../../ServiceConfig";
import PasswordInput from "../../components/inputFields/PasswordInput";
import { getAllRolesOption } from "../services/RoleServices";
import ToggleInput from "../components/inputs/ToggleInput";
import placeholderImage from '../../../src/assets/image/employee/placeholder.png';

const employeeFormSchema = Yup.object().shape({
  first_name: Yup.string().max(25, "Too Long!").required("Required"),
  last_name: Yup.string().max(25, "Too Long!").required("Required"),
  email: Yup.string()
    .email('Invalid email address')
    .max(50, 'Email must be 50 characters or less')
    .required('Email is required'),
  phone: Yup.string()
    .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
    .required('Phone number is required'),
  department: Yup.string().required("Required"),
  employment_type: Yup.string().required("Required"),
  reporting_manager: Yup.string().required("Required"),
  gender: Yup.string().required("Required"),
  last_working_day: Yup.date()
    .when('status', {
      is: false,
      then: Yup.date().required('Last working day is required if status is inactive'),
      otherwise: Yup.date().notRequired(),
    }),
  dob: Yup.date()
    .required('Birth date is required')
    .min(new Date('1924-01-01'), 'Birth date must be after January 1, 1924')
    .max(new Date(), 'Birth date must be before today'),
  joining_date: Yup.date()
    .required('Joining date is required')
    .min(new Date('2014-01-01'), 'Joining date must be after January 1, 2014')
    .max(new Date(), 'Joining date must be before today'),
  level: Yup.string().required("Required"),
  status: Yup.boolean(),
  temp_address: Yup.string().required("Required"),
  permanent_address: Yup.string().required("Required"),
  emergency_contact: Yup.string().matches(/^\d{10}$/, 'Emergency contact must be exactly 10 digits').required("Required"),
  emergency_contact_name: Yup.string().required("Required"),
  emergency_contact_relation: Yup.string().required("Required"),
  designation: Yup.string().required("Required"),
});

function EmployeeForm({ formData, edit, onSuccess, profile }) {
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);
  const [availableOfficialContacts, setAvailableOfficialContacts] = useState(
    []
  );

  useEffect(() => {
    getAllEmployeesOption().then((res) => {
      let emps = [];
      res.data.data.data.forEach((data) => {
        emps.push({
          value: data.id,
          label: `${data.first_name} ${data.last_name}`,
        });
      });
      setEmployees(emps);
    });

    getAllDepartments().then((res) => {
      let dpts = [];

      res.data.data.forEach((data) => {
        dpts.push({ label: data.name, value: `${data.id}` });
      });

      setDepartments(dpts);
    });
    getAllRolesOption().then((res) => {
      let roles = [];

      res.data.data.forEach((data) => {
        roles.push({ label: data.name, value: `${data.id}` });
      });

      setRoles(roles);
    });

    getAllUnassignedContacts()
      .then((res) => {
        let contacts = [];

        res.data.data?.forEach((data) => {
          contacts.push({
            value: `${data?.id}`,
            label: `${data?.email} - ${data?.phone}`,
          });
        });

        setAvailableOfficialContacts((availableOfficialContacts) => [
          ...availableOfficialContacts,
          ...contacts,
        ]);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });

    if (edit && formData?.official_contact) {
      console.log(formData?.official_contact);

      let contacts = [...availableOfficialContacts];
      contacts.push({
        value: `${formData?.official_contact?.id}`,
        label: `${formData?.official_contact?.email} - ${formData?.official_contact?.phone}`,
      });
      setAvailableOfficialContacts(contacts);
    }
  }, []);

  // ---------------------------------------------------------
  // ? Add new State
  const addNewEmployee = (values) => {
    addEmployee(values)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  // ? Update State
  const editEmployee = (values) => {
    let data = Object.assign({}, values);
    updateEmployee(formData.id, data)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const employeeType = [
    { label: "Full Time", value: "full time" },
    { label: "Part Time", value: "part time" },
    { label: "Internship", value: "internship" },
    { label: "Freelancer", value: "freelancer" },
  ];

  const levels = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
  ];

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          first_name: formData?.first_name ? formData.first_name : "",
          last_name: formData?.last_name ? formData.last_name : "",
          email: formData?.email ? formData.email : "",
          phone: formData?.phone ? formData.phone : "",
          department: formData?.department ? formData.department : "",
          employment_type: formData?.employment_type
            ? formData.employment_type
            : "",
          reporting_manager: formData?.reporting_manager
            ? formData.reporting_manager
            : "",
          status: formData?.status ? formData.status : false,
          joining_date: formData?.joining_date ? formData.joining_date : "",
          dob: formData?.dob ? formData.dob : "",
          last_working_day: formData?.last_working_day
            ? formData.last_working_day
            : "",
          gender: formData?.gender ? formData.gender : "",
          profile_pic: formData?.profile_pic ? formData.profile_pic : "",
          cut_out_image: formData?.cut_out_image ? formData.cut_out_image : "",
          level: formData?.level ? formData.level : "",
          role: formData?.role ? `${formData.role}` : "",
          official_contact_id: formData?.official_contact?.id
            ? `${formData.official_contact?.id}`
            : "",
          password: "123456",
          confirm_password: "123456",
          employee_id: formData?.employee_id ? formData?.employee_id : "",
          temp_address: formData?.temp_address ? formData?.temp_address : "",
          permanent_address: formData?.permanent_address ? formData?.permanent_address : "",
          emergency_contact: formData?.emergency_contact ? formData?.emergency_contact : "",
          emergency_contact_name: formData?.emergency_contact_name ? formData?.emergency_contact_name : "",
          emergency_contact_relation: formData?.emergency_contact_relation ? formData?.emergency_contact_relation : "",
          designation: formData?.designation ? formData?.designation : "",
        }}
        validationSchema={employeeFormSchema}
        onSubmit={(values, errors, error) => {
          console.log(error)
          edit ? editEmployee(values) : addNewEmployee(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="flex flex-wrap gap-4 lg:flex-nowrap">
              {!profile && (
                <div className="w-full flex justify-end lg:hidden">
                  <div className="">
                    <InputLabel label="Status" labelFor="status" />
                    <ToggleInput
                      checked={values.status}
                      onToggle={(e) =>
                        setFieldValue("status", e.target.checked)
                      }
                    />
                  </div>
                </div>
              )}
              <div className="flex flex-col lg:w-[20rem] w-full gap-10">
                {/* //? Banner Image */}
                <div className="grow lg:max-w-[20rem]">
                  <InputLabel label="Profile Pic"/>
                  <FileInput
                    value={values.profile_pic ? `${getUploadsUrl()}/employees/${values.profile_pic}` : placeholderImage}
                    noOfFilesAllowed={1}
                    onChange={(file) => {
                      uploadEmployeeImage({ image: file }).then((res) => {
                        setFieldValue(
                          "profile_pic",
                          res.data.data[0].uploaded_image
                        );
                      });
                    }}
                  />
                </div>

                {/* employee cut out image */}
                <div className="grow lg:max-w-[20rem]">
                  <InputLabel label="Employee Cut-Out Image" />
                  {profile ? <img
                    src={`${getUploadsUrl()}/employees_cutout/${values.cut_out_image}`}
                    alt={`Cut out image of ${values.first_name}`}
                    className="w-[50%] h-[80%]"
                  /> : <FileInput
                    value={values.cut_out_image ? `${getUploadsUrl()}/employees_cutout/${values.cut_out_image}` : placeholderImage}
                    noOfFilesAllowed={1}
                    onChange={(file) => {
                      uploadEmployeeCutOutImage({ image: file }).then((res) => {
                        setFieldValue(
                          "cut_out_image",
                          res.data.data[0].uploaded_image
                        );
                      });
                    }}
                  />}
                </div>
              </div>

              <div className="my-4 grow">
                <div className="flex flex-wrap gap-4">
                  {/* //? State Name */}
                  <div className="grow basis-[45%] lg:max-w-[40%]">
                    <InputLabel label="First Name" labelFor="name"  required={true}/>
                    <Field
                      className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                      id="name"
                      name="first_name"
                    />
                    <ErrorMessage name="first_name">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="grow basis-[45%] lg:max-w-[40%]">
                    <InputLabel label="Last Name" labelFor="name"  required={true}/>
                    <Field
                      className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                      id="name"
                      name="last_name"
                    />
                    <ErrorMessage name="last_name">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  {edit && values.employee_id && <div className="grow basis-[10%] lg:max-w-[10%]">
                    <InputLabel label="Emp. Code" labelFor="name" />
                    <Field
                      className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                      id="name"
                      name="employee_id"
                      disabled={true}
                    />
                  </div>}

                  {!profile && (
                    <div className="hidden lg:block">
                      <InputLabel label="Status" labelFor="status" />
                      <ToggleInput
                        checked={values.status}
                        onToggle={(e) =>
                          setFieldValue("status", e.target.checked)
                        }
                      />
                    </div>
                  )}
                </div>

                {/* //? Select Country */}
                <div className="flex flex-wrap gap-4">
                  <div className="my-4 grow basis-[45%] lg:max-w-[50%]">
                    <InputLabel label="Email" labelFor="country"  required={true}/>
                    <Field
                      name="email"
                      className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                    />
                    <ErrorMessage name="email">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="my-4 grow basis-[45%] lg:max-w-[50%]">
                    <InputLabel label="Phone" labelFor="country"  required={true}/>
                    <Field
                      name="phone"
                      className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                    />
                    <ErrorMessage name="phone">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="mb-4 flex flex-wrap gap-4">
                  <div className="grow">
                    <InputLabel label="Gender"  required={true}/>
                    <SelectSearch
                      value={values.gender}
                      options={[
                        { label: "Male", value: "Male" },
                        { label: "Female", value: "Female" },
                      ]}
                      onChange={(value) => setFieldValue("gender", value)}
                    />
                    <ErrorMessage name="gender">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="grow">
                    <InputLabel label="Select Department"  required={true}/>
                    <SelectSearch
                      disabled={profile}
                      value={values.department}
                      options={departments}
                      onChange={(value) => setFieldValue("department", value)}
                    />
                    <ErrorMessage name="department">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="grow lg:max-w-[calc(50%-8px)]">
                    <InputLabel label="Reporting Manager"  required={true}/>
                    <SelectSearch
                      disabled={profile}
                      value={values.reporting_manager}
                      options={employees}
                      onChange={(value) =>
                        setFieldValue("reporting_manager", value)
                      }
                    />
                    <ErrorMessage name="reporting_manager">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="mb-4 flex flex-wrap gap-4">
                  <div className="grow">
                    <InputLabel label="Employement Type"  required={true}/>
                    <SelectSearch
                      disabled={profile}
                      value={values.employment_type}
                      options={employeeType}
                      onChange={(value) =>
                        setFieldValue("employment_type", value)
                      }
                    />
                    <ErrorMessage name="employment_type">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="grow">
                    <InputLabel label="Level"  required={true}/>
                    <SelectSearch
                      disabled={profile}
                      options={levels}
                      value={values.level}
                      onChange={(value) => setFieldValue("level", value)}
                    />
                    <ErrorMessage name="level">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="grow">
                    <InputLabel label="Designation"  required={true}/>
                    <Field
                      as="input"
                      className="field"
                      name="designation"
                    />
                    <ErrorMessage name="designation">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="flex flex-wrap gap-4">
                  <div className="grow">
                    <InputLabel label="Joining Date"  required={true}/>
                    <DateInput
                      className="dark:text-black"
                      disabled={profile}
                      value={values.joining_date}
                      onDateChange={(value) => {
                        setFieldValue(
                          "joining_date",
                          `${new Date(
                            value
                          ).getFullYear()}-${formatNumberToTwoDigit(
                            new Date(value).getMonth() + 1
                          )}-${new Date(value).getDate()}`
                        );
                      }}
                    />
                    <ErrorMessage name="joining_date">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="grow">
                    <InputLabel label="Date Of Birth"  required={true}/>
                    <DateInput
                      className="dark:text-black"
                      // disabled={profile}
                      value={values.dob}
                      onDateChange={(value) => {
                        setFieldValue(
                          "dob",
                          `${new Date(
                            value
                          ).getFullYear()}-${formatNumberToTwoDigit(
                            new Date(value).getMonth() + 1
                          )}-${new Date(value).getDate()}`
                        );
                      }}
                    />
                    <ErrorMessage name="dob">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="grow">
                    <InputLabel label="Official Contacts" />
                    <SelectSearch
                      disabled={profile}
                      options={availableOfficialContacts}
                      value={values.official_contact_id}
                      onChange={(value) =>
                        setFieldValue("official_contact_id", value)
                      }
                    />
                  </div>
                </div>

                {!profile && (
                  <>
                    <div className="flex flex-wrap gap-4">
                      <div className="grow">
                        <InputLabel label="Access Role"/>
                        <SelectSearch
                          options={roles}
                          value={values.role}
                          onChange={(value) => setFieldValue("role", value)}
                        />
                        <ErrorMessage name="role">
                          {(msg) => <div className="error-msg">{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="grow">
                      <InputLabel label="Last Working Day"  required={!values.status}/>
                      <DateInput
                        // disabled={profile}
                        value={values.last_working_day}
                        onDateChange={(value) => {
                          setFieldValue(
                            "last_working_day",
                            `${new Date(
                              value
                            ).getFullYear()}-${formatNumberToTwoDigit(
                              new Date(value).getMonth() + 1
                            )}-${new Date(value).getDate()}`
                          );
                        }}
                      />
                      <ErrorMessage name="last_working_day">
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                  </>
                )}

                <div className="mb-4 flex flex-wrap gap-4">
                  <div className="grow">
                    <InputLabel label="Permanent Address"  required={true}/>
                    <Field
                      as="textarea"
                      className="field"
                      name="permanent_address"
                    />
                    <ErrorMessage name="permanent_address">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="grow">
                    <InputLabel label="Temporary Address"  required={true}/>
                    <Field
                      as="textarea"
                      className="field"
                      name="temp_address"
                    />
                    <ErrorMessage name="temp_address">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="mb-4 flex flex-wrap gap-4">
                  <div className="grow">
                    <InputLabel label="Emergency Contact"  required={true}/>
                    <Field
                      as="input"
                      className="field"
                      name="emergency_contact"
                    />
                    <ErrorMessage name="emergency_contact">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="grow">
                    <InputLabel label="Emergency Contact Name"  required={true}/>
                    <Field
                      as="input"
                      className="field"
                      name="emergency_contact_name"
                    />
                    <ErrorMessage name="emergency_contact_name">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="grow">
                    <InputLabel label="Emergency Contact Relation"  required={true}/>
                    <Field
                      as="input"
                      className="field"
                      name="emergency_contact_relation"
                    />
                    <ErrorMessage name="emergency_contact_relation">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 text-center">
              <button type="submit" className="btn-primary dark:bg-green-700">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EmployeeForm;
