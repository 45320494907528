import React from 'react'
import { Outlet } from 'react-router-dom'

const HomepageCategoryWrapper = () => {
  return (
    <div className="wrapper">
      {<Outlet />}
    </div>
  )
}

export default HomepageCategoryWrapper