import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import MetaTags from "../../../web/layouts/MetaTags";
import { getCountryById } from "../../services/CountryServices";
import CountryForm from "./CountryForm";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";

function EditCountry() {
  const navigate = useNavigate();

  const countryId = useParams().id;

  const [country, setCountry] = useState();

  const onEditCountry = (data) => {
    navigate(`/country`);
  };

  useEffect(() => {
    countryId &&
      getCountryById(countryId)
        .then((res) => {
          setCountry(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Edit Country | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Country</H2>
        <Link
          to="/country"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {country && (
        <CountryForm
          formData={country}
          edit={true}
          onSuccess={(data) => onEditCountry(data)}
        />
      )}
    </div>
  );
}

export default EditCountry;
