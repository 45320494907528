import React, { useEffect, useState } from 'react'
import SelectSearch from '../components/inputs/SelectSearch'
import { getRnRById, getRnRBymonthyear, getWinnersById, saveDates } from '../services/RewardsAndRecognitionServices'
import InputLabel from '../components/inputs/InputLabel'
import H2 from '../../components/headings/H2'
import ComponentHeader from '../components/common/ComponentHeader'
import DateInput from '../components/inputs/DateInput'
import moment from 'moment/moment'
import { useParams } from 'react-router-dom'
import RnRWinnersForm from './RnRWinnersForm'

const months = [{ label: 'January', value: '01' },
{ label: 'February', value: '02' },
{ label: 'March', value: '03' },
{ label: 'April', value: '04' },
{ label: 'May', value: '05' },
{ label: 'June', value: '06' },
{ label: 'July', value: '07' },
{ label: 'August', value: '08' },
{ label: 'September', value: '09' },
{ label: 'October', value: '10' },
{ label: 'November', value: '11' },
{ label: 'December', value: '12' },]


const RnREditForm = () => {

    const [presentDate, setPresentDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [proposedDate, setProposedDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [winners, setWinners] = useState()
    const [rnrDetails, setRnRDetails] = useState()

    const params = useParams()
    const getAllWinners = () => {
        getWinnersById(params.id).then(res => {
            console.log(res.data.data, "winners")
            setWinners(res.data.data)
        }).catch(er => { console.log(er) })
    }

    const getRnrDetails = () => {
        getRnRById(params.id).then(res => {
            setRnRDetails(res.data.data)
            setPresentDate(res.data.data.presented_at)
            setProposedDate(res.data.data.proposed_at)
        }).catch(er => { console.log(er) })
    }

    useEffect(() => {
        getAllWinners()
        getRnrDetails()
    }, [])

    return (<>
        <div className="dark:bg-slate-900 dark:text-white h-[100%] p-2 max-w-[1000px] mx-auto">
            {/* <MetaTags title="Edit State | Admin Panel" /> */}
            <ComponentHeader className="mb-4 border-b">
                <H2>Edit RNR Details</H2>
            </ComponentHeader>
            <div className='grid grid-cols-4 gap-6 mb-10'>

                <div className='col-span-1'>
                    <InputLabel label="Month" />
                    <input
                        disabled
                        type='text'
                        className='field'
                        value={months.find((month) => month.value === rnrDetails?.month)?.label}
                    />

                    {/* <div> Month : {months.find((month) => month.value === rnrDetails?.month)?.label}</div> */}
                </div>
                <div className='col-span-1'>
                    <InputLabel label="Select Present Date" />
                    <input
                        disabled
                        type='text'
                        className='field'
                        value={rnrDetails?.year}
                    />

                    {/* <div> Year : {rnrDetails?.year}</div> */}
                </div>
                <div className='col-span-1'>
                    <InputLabel label="Select Proposed Date" />
                    <DateInput
                        className="!rounded-full border-[1px] py-[0.625rem]"
                        onDateChange={(value) => {
                            setProposedDate(moment(value).format("YYYY-MM-DD"));
                        }}
                        value={proposedDate}
                    />
                </div>
                <div className='col-span-1'>
                    <InputLabel label="Select Present Date" />
                    <DateInput
                        className="!rounded-full border-[1px] py-[0.625rem]"
                        onDateChange={(value) => {
                            setPresentDate(moment(value).format("YYYY-MM-DD"));
                        }}
                        value={presentDate}
                    />
                </div>
            </div>
            <H2>Award Winners</H2>
            <RnRWinnersForm formData={winners} selectedMonth={rnrDetails?.month} selectedYear={rnrDetails?.year} presntedAt={presentDate} proposedDate={proposedDate} rnrDetails={rnrDetails} rnrid={params.id}/>

        </div>
    </>
    )
}

export default RnREditForm