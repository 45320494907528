import { admin } from "../../ServiceConfig";

export function getAllGoogleReviews(query) {
    return admin.get(`/google-reviews?query=${query}`)
}
export function getAllGoogleReviewsOption() {
    return admin.get(`/allGoogleReviewsOption`)
}
export function createGoogleReview(data) {
    return admin.post(`/google-reviews`, data)
}
export function updateGoogleReview(id, data) {
    return admin.put(`/google-reviews/${id}`, data)
}
export function deleteGoogleReview(id) {
    return admin.delete(`/google-reviews/${id}`)
}
export function getGoogleReviewById(id) {
    return admin.get(`/google-reviews/${id}`)
}

