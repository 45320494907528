
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { createRewards, updateRewardById, uploadRewardImage } from "../services/RewardsAndRecognitionServices";
import InputLabel from '../components/inputs/InputLabel'
import { getUploadsUrl } from "../../ServiceConfig";
import FileInput from "../../components/inputFields/FileInput";
import SelectSearch from "../components/inputs/SelectSearch";

const rewardSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    color: Yup.string().required("Required"),
});

function RewardForm({ formData, edit, onSuccess }) {

    // ? Add new B2B
    const addNewReward = (values) => {
        createRewards(values)
            .then((res) => {
                toast.success(res.data.data.message);
                onSuccess(res.data.data);
            })
            .catch((err) => {
                toast.error("error in adding new notification");
            });
    };

    // ? Update State
    const editInternalNotification = (values) => {
        let data = Object.assign({}, values);
        updateRewardById(formData.id, data)
            .then((res) => {
                toast.success(res.data.message);
                onSuccess(res.data.data);
            })
            .catch((err) => {
                console.log(err)
                toast.error(err.response.data.message);
            });
    };

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    name: formData?.name ? formData.name : "",
                    description: formData?.description ? formData.description : "",
                    image: formData?.image ? formData.image : "",
                    color: formData?.color ? formData.color : "",
                }}
                validationSchema={rewardSchema}
                onSubmit={(values) => {
                    edit ? editInternalNotification(values) : addNewReward(values);
                }}
            >
                {({ values, setFieldValue, errors, touched }) => (
                    <Form>
                        <div className="flex flex-wrap gap-4 lg:flex-nowrap">

                            <div className="my-4 grow">
                                <div className="flex flex-wrap gap-4">

                                    {/* //? Name */}
                                    <div className="grow basis-[45%] lg:max-w-[40%]">
                                        <InputLabel label="Name" labelFor="name" />
                                        <Field
                                            className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                                            id="name"
                                            name="name"
                                        />
                                        <ErrorMessage name="name">
                                            {(msg) => <div className="error-msg">{msg}</div>}
                                        </ErrorMessage>
                                    </div>


                                    {/* Description */}
                                    <div className="grow basis-[45%] lg:max-w-[30%]">
                                        <InputLabel label="Description" labelFor="description" />
                                        <Field
                                            as="textarea"
                                            className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                                            id="description"
                                            name="description"
                                        />
                                        <ErrorMessage name="description">
                                            {(msg) => <div className="error-msg">{msg}</div>}
                                        </ErrorMessage>
                                    </div>




                                    {/* Image */}
                                    <div className="grow basis-[45%] lg:max-w-[30%]">
                                        <InputLabel label="Image" labelFor="image" />
                                        <FileInput
                                            value={values.image && `${getUploadsUrl()}/rnr-cat/${values.image
                                                }`}
                                            noOfFilesAllowed={1}
                                            onChange={(file) => {
                                                uploadRewardImage({ image: file }).then(
                                                    (res) => {
                                                        setFieldValue(
                                                            `image`,
                                                            res.data.data[0].uploaded_image
                                                        );
                                                    }
                                                );
                                            }}
                                        />
                                        <ErrorMessage name="image">
                                            {(msg) => <div className="error-msg">{msg}</div>}
                                        </ErrorMessage>
                                    </div>

                                    {/* Color */}
                                    <div className="grow basis-[45%] lg:max-w-[30%]">
                                        <InputLabel label="Background Color" labelFor="color" />
                                        <SelectSearch
                                            value={values.color}
                                            options={
                                                [{ label: 'Blue', value: 'blue' }, { label: 'Green', value: 'green' }]
                                            }
                                            onChange={(value) => setFieldValue("color", value)}
                                        />
                                        <ErrorMessage name="color">
                                            {(msg) => <div className="error-msg">{msg}</div>}
                                        </ErrorMessage>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="mt-4 text-center">
                            <button type="submit" className="btn-primary dark:bg-green-700">
                                Submit
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default RewardForm;
