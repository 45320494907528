import { ErrorMessage, useFormikContext } from "formik";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getAdminUrl, getUploadsUrl } from "../../../ServiceConfig";
import { uploadEditorImage } from "../../services/CommonServices";

function EditorInput({ fieldKey, data, onBlur, onChange }) {
  const { setFieldValue, values } = useFormikContext();

  class MyUploadAdapter {
    constructor(loader) {
      this.loader = loader;
    }

    upload() {
      return this.loader.file.then(
        (file) =>
          new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("image[]", file);

            uploadEditorImage(formData)
              .then((response) => {
                console.log(response, "image loaded successfully");

                return `${getUploadsUrl()}/editor/${
                  response.data.data[0].uploaded_image
                }`;
              })
              .then((uploadedImageUrl) => {
                if (uploadedImageUrl) {
                  resolve({ default: uploadedImageUrl }); // resolve with the URL
                } else {
                  reject("Upload failed");
                }
              })
              .catch((error) => {
                reject(error.message);
              });
          })
      );
    }

    abort() {
      // Handle the abort method.
    }
  }

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      // The URL where images should be uploaded.

      const UPLOAD_URL = `${getAdminUrl()}/uploads/image?upload_path=editor`;
      return new MyUploadAdapter(loader, UPLOAD_URL);
    };
  }

  return (
    <div className="editor">
      <CKEditor
        editor={ClassicEditor}
        data={data ? data : ""}
        config={{
          toolbar: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "uploadImage",
            "bulletedList",
            "numberedList",
            "|",
            "blockQuote",
            "undo",
            "redo",
            "|",
            "insertTable",
            "tableColumn",
            "tableRow",
            "mergeTableCells", // table utilities
          ],
          table: {
            contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
          },
          extraPlugins: [MyCustomUploadAdapterPlugin],

          image: {
            toolbar: [
              "imageTextAlternative",
              "|",
              "imageStyle:full",
              "imageStyle:side",
            ],
            styles: ["full", "side"],
            resizeUnit: "px",
          },
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setFieldValue(fieldKey, data);
          if (onChange) onChange(data);
        }}
      />
      <ErrorMessage name={fieldKey}>
        {(msg) => <div className="error-msg">{msg}</div>}
      </ErrorMessage>
    </div>
  );
}

export default EditorInput;
