import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LocationForm from "./LocationForm";
// import MetaTags from "../../../web/layouts/MetaTags";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";

function AddLocation() {
  const navigate = useNavigate();

  const onAddLocation = (data) => {
    navigate(`/location`);
  };

  return (
    <div className="">
      {/* <MetaTags title="Add Location | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Add Location</H2>
        <Link
          to="/location"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      <LocationForm onSuccess={(data) => onAddLocation(data)} />
    </div>
  );
}

export default AddLocation;
