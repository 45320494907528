import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import PushNotificationForm from "./PushNotificationForm";
import { getPushNotificationById } from "../../services/PushNotificationServices";

function EditPushNotification() {
  const navigate = useNavigate();

  const notificationId = useParams().id;

  const [notification, setNotification] = useState();

  const onEditOccupancy = (data) => {
    navigate(`/push-notifications`);
  };

  useEffect(() => {
    notificationId &&
      getPushNotificationById(notificationId)
        .then((res) => {
          console.log(res.data.data, "nnnnnnnnnnnn===============");
          setNotification(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Edit Occupancy | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <h2 className="text-xl font-bold">Update Push Notification</h2>
        <Link
          to="/push-notifications"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {notification && (
        <PushNotificationForm
          edit={true}
          formData={notification}
          onEdit={onEditOccupancy}
        />
      )}
    </div>
  );
}

export default EditPushNotification;
