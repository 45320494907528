import { admin } from "../../ServiceConfig";

export function createState(data) {
  return admin.post(`/states`, data);
}

export function getAllState() {
  return admin.get(`/states`);
}

export function getAllStateOption() {
  return admin.get(`/allStates`);
}

export function getAllStateByCountry(countryId) {
  return admin.get(`/countries/${countryId}/states`);
}

export function getStateById(id) {
  return admin.get(`/states/${id}`);
}

export function deleteState(stateId) {
  return admin.delete(`/states/${stateId}`);
}

export function updateState(id, data) {
  return admin.put(`/states/${id}`, data);
}

export function uploadStateImage(file) {
  return admin.post(`uploads/image?upload_path=states`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
