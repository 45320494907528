import { FieldArray, Field, Form, Formik, ErrorMessage } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { FaEdit, FaPlusSquare, FaTrash } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllCategories } from "../../../services/CategoryServices";
import {
  createCategoryCoupons,
  deleteCategoryCoupon,
  getCouponCateogries,
  UpdateCategoryCoupon,
} from "../../../services/CouponService";
import InputLabel from "../../inputs/InputLabel";

function CategoryCouponForm({ couponData }) {
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [linkedCategories, setLinkedCategories] = useState([]);
  const [activeCategoryToEdit, setActiveCategoryToEdit] = useState();
  const [activeCategoryEditId, setActiveCategoryEditId] = useState();

  const getLinkedCouponCategories = () => {
    getCouponCateogries(couponData.id).then((res) => {
      setLinkedCategories(res.data.data);
    });
  };

  useEffect(() => {
    getAllCategories().then((res) => {
      setCategories(res.data.data.data);
    });

    getLinkedCouponCategories();
  }, []);

  const storeCategoryCoupon = (values) => {
    values.category_coupon.forEach((coupon) => {
      coupon.coupon_id = couponData.id;
      coupon.category_id = parseInt(coupon.category_id);
    });

    createCategoryCoupons(values.category_coupon).then((res) => {
      toast.success(res.data.message);
      getLinkedCouponCategories();
    });
  };

  const updateCategoryCoupon = () => {
    UpdateCategoryCoupon(activeCategoryToEdit).then((res) => {
      setActiveCategoryToEdit({});
      setActiveCategoryEditId("");
      getLinkedCouponCategories();
      toast.success(res.data.message);
    });
  };

  const updateLinkedCategory = (id) => {
    let categories = [...linkedCategories];
    let index = categories.findIndex((cat) => cat.id === id);
    categories.splice(index, 1);
    setLinkedCategories(categories);
  };

  const detachCategoryFromCoupon = (id) => {
    deleteCategoryCoupon(id).then((res) => {
      console.log(res);
      toast.success(res.data.data);
      updateLinkedCategory(id);
    });
  };

  return (
    <div>
      <div className="mb-12">
        <h3 className="mb-2 font-semibold">
          Available Categories for coupon-{couponData.code}
        </h3>
        <div>
          {linkedCategories?.map((category) => {
            return (
              <div className="my-2 rounded-lg border-2 px-4 py-2">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-12">
                    {
                      categories.filter(
                        (cat) => cat.id === category.category_id
                      )[0]?.name
                    }
                    <button
                      onClick={() => {
                        setActiveCategoryToEdit(category);
                        setActiveCategoryEditId(
                          activeCategoryEditId === category.id
                            ? ""
                            : category.id
                        );
                      }}
                      className=""
                    >
                      <FaEdit />
                    </button>
                  </div>

                  <button
                    onClick={() => detachCategoryFromCoupon(category.id)}
                    className="rounded-full border border-red-500 p-1 text-[10px] text-red-500"
                  >
                    <FaTrash />
                  </button>
                </div>
                <div
                  className={
                    activeCategoryEditId === category.id ? "" : "hidden"
                  }
                >
                  <div className="my-2 flex flex-wrap gap-4">
                    <div className="grow">
                      <InputLabel label="Discount %" />
                      <input
                        type="number"
                        name=""
                        id=""
                        value={activeCategoryToEdit?.discount_percent}
                        className="field"
                        onChange={(e) =>
                          setActiveCategoryToEdit((activeCategoryToEdit) => ({
                            ...activeCategoryToEdit,
                            discount_percent: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className="grow">
                      <InputLabel label="Max Discount" />
                      <input
                        type="number"
                        name=""
                        id=""
                        value={activeCategoryToEdit?.discount_maximum}
                        className="field"
                        onChange={(e) =>
                          setActiveCategoryToEdit((activeCategoryToEdit) => ({
                            ...activeCategoryToEdit,
                            discount_maximum: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className="grow">
                      <InputLabel label="Min Pax" />
                      <input
                        type="number"
                        name=""
                        id=""
                        value={activeCategoryToEdit?.min_pax}
                        className="field"
                        onChange={(e) =>
                          setActiveCategoryToEdit((activeCategoryToEdit) => ({
                            ...activeCategoryToEdit,
                            min_pax: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className="grow">
                      <InputLabel label="Min Amount" />
                      <input
                        type="number"
                        name=""
                        id=""
                        value={activeCategoryToEdit?.min_amount}
                        className="field"
                        onChange={(e) =>
                          setActiveCategoryToEdit((activeCategoryToEdit) => ({
                            ...activeCategoryToEdit,
                            min_amount: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className="grow">
                      <InputLabel label="Number of Available Coupons" />
                      <input
                        type="number"
                        name=""
                        id=""
                        value={activeCategoryToEdit?.total_availability}
                        className="field"
                        onChange={(e) =>
                          setActiveCategoryToEdit((activeCategoryToEdit) => ({
                            ...activeCategoryToEdit,
                            total_availability: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className="grow">
                      <InputLabel label="Apply on" />
                      <select
                        className="field"
                        value={activeCategoryToEdit?.applied_on_total_amt}
                        onChange={(e) =>
                          setActiveCategoryToEdit((activeCategoryToEdit) => ({
                            ...activeCategoryToEdit,
                            applied_on_total_amt: e.target.value,
                          }))
                        }
                        // name={`category_coupon.${index}.applied_on_total_amt`}
                      >
                        <option value="0">Per Person</option>
                        <option value="1">Total Amount</option>
                      </select>
                      {/* <ErrorMessage
                        name={`category_coupon.${index}.applied_on_total_amt`}
                      >
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage> */}
                    </div>
                    {/* <div className="grow">
                      <InputLabel label="Cashback %" />
                      <input
                        type="number"
                        name=""
                        id=""
                        value={activeCategoryToEdit?.cashback_percent}
                        onChange={(e) =>
                          setActiveCategoryToEdit((activeCategoryToEdit) => ({
                            ...activeCategoryToEdit,
                            cashback_percent: e.target.value,
                          }))
                        }
                        className="field"
                      />
                    </div>
                    <div className="grow">
                      <InputLabel label="Max Cashback" />
                      <input
                        type="number"
                        name=""
                        id=""
                        value={activeCategoryToEdit?.cashback_maximum}
                        onChange={(e) =>
                          setActiveCategoryToEdit((activeCategoryToEdit) => ({
                            ...activeCategoryToEdit,
                            cashback_maximum: e.target.value,
                          }))
                        }
                        className="field"
                      />
                    </div> */}
                  </div>

                  <div className="text-center">
                    <button
                      onClick={updateCategoryCoupon}
                      className="btn-primary my-4"
                    >
                      update
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Formik
        initialValues={{
          category_coupon: [
            {
              category_id: "",
              discount_percent: "",
              discount_maximum: "",
              cashback_percent: "",
              cashback_maximum: "",
              min_pax: "",
              min_amount: "",
              applied_on_total_amt: "0",
              total_availability: "0",
            },
          ],
        }}
        // validationSchema={CouponFormSchema}
        onSubmit={(values, { resetForm }) => {
          // alert(JSON.stringify(values));
          storeCategoryCoupon(values);
          resetForm();
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="basis-full">
              <h3 className="mb-2 font-semibold">
                Add Categories to coupon-{couponData.code}
              </h3>
              <FieldArray name="category_coupon">
                {({ insert, remove, push }) => (
                  <div>
                    {values.category_coupon?.map((category_coupon, index) => (
                      <div
                        key={index}
                        className="relative mb-4 rounded-lg border-2 p-4"
                      >
                        <button
                          onClick={() => remove(index)}
                          className="absolute top-2 right-2 rounded-full border-2 p-1"
                        >
                          <MdClose />
                        </button>

                        <div className="flex flex-wrap gap-4">
                          <div className="grow">
                            <InputLabel label="Select Category" />
                            <Field
                              as="select"
                              className="field"
                              name={`category_coupon.${index}.category_id`}
                            >
                              <option value=""></option>
                              {categories?.map((category) => {
                                return (
                                  <option key={category.id} value={category.id}>
                                    {category.name}
                                  </option>
                                );
                              })}
                            </Field>
                            <ErrorMessage
                              name={`category_coupon.${index}.category_id`}
                            >
                              {(msg) => <div className="error-msg">{msg}</div>}
                            </ErrorMessage>
                          </div>

                          <div className="grow">
                            <InputLabel label="Discount %" />
                            <Field
                              className="field"
                              name={`category_coupon.${index}.discount_percent`}
                            />
                            <ErrorMessage
                              name={`category_coupon.${index}.discount_percent`}
                            >
                              {(msg) => <div className="error-msg">{msg}</div>}
                            </ErrorMessage>
                          </div>

                          <div className="grow">
                            <InputLabel label="Max Discount" />
                            <Field
                              className="field"
                              name={`category_coupon.${index}.discount_maximum`}
                            />
                            <ErrorMessage
                              name={`category_coupon.${index}.discount_maximum`}
                            >
                              {(msg) => <div className="error-msg">{msg}</div>}
                            </ErrorMessage>
                          </div>

                          <div className="grow">
                            <InputLabel label="Cashback %" />
                            <Field
                              className="field"
                              name={`category_coupon.${index}.cashback_percent`}
                            />
                            <ErrorMessage
                              name={`category_coupon.${index}.cashback_percent`}
                            >
                              {(msg) => <div className="error-msg">{msg}</div>}
                            </ErrorMessage>
                          </div>
                          <div className="grow">
                            <InputLabel label="Max Cashback" />
                            <Field
                              className="field"
                              name={`category_coupon.${index}.cashback_maximum`}
                            />
                            <ErrorMessage
                              name={`category_coupon.${index}.cashback_maximum`}
                            >
                              {(msg) => <div className="error-msg">{msg}</div>}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="mt-4 flex flex-wrap gap-4">
                          <div className="grow">
                            <InputLabel label="Min Pax" />
                            <Field
                              className="field"
                              name={`category_coupon.${index}.min_pax`}
                            />
                            <ErrorMessage
                              name={`category_coupon.${index}.min_pax`}
                            >
                              {(msg) => <div className="error-msg">{msg}</div>}
                            </ErrorMessage>
                          </div>
                          <div className="grow">
                            <InputLabel label="Min Amount" />
                            <Field
                              className="field"
                              name={`category_coupon.${index}.min_amount`}
                            />
                            <ErrorMessage
                              name={`category_coupon.${index}.min_amount`}
                            >
                              {(msg) => <div className="error-msg">{msg}</div>}
                            </ErrorMessage>
                          </div>
                          <div className="grow">
                            <InputLabel label="Number of Available Coupons" />
                            <Field
                              className="field"
                              name={`category_coupon.${index}.total_availability`}
                            />
                            <ErrorMessage
                              name={`category_coupon.${index}.total_availability`}
                            >
                              {(msg) => <div className="error-msg">{msg}</div>}
                            </ErrorMessage>
                          </div>
                          <div className="grow">
                            <InputLabel label="Apply on" />
                            <Field
                              as="select"
                              className="field"
                              name={`category_coupon.${index}.applied_on_total_amt`}
                            >
                              <option value="0">Per Person</option>
                              <option value="1">Total Amount</option>
                            </Field>
                            <ErrorMessage
                              name={`category_coupon.${index}.applied_on_total_amt`}
                            >
                              {(msg) => <div className="error-msg">{msg}</div>}
                            </ErrorMessage>
                          </div>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="my-4 mt-2 flex items-center gap-1 px-4 text-jw-green"
                      onClick={() =>
                        push({
                          category_id: "",
                          discount_percent: "",
                          discount_maximum: "",
                          cashback_percent: "",
                          cashback_maximum: "",
                          min_pax: "",
                          min_amount: "",
                          applied_on_total_amt: "0",
                          total_availability: "0",
                        })
                      }
                    >
                      <FaPlusSquare /> Category
                    </button>
                  </div>
                )}
              </FieldArray>
            </div>
            <div className="text-center">
              <button type="submit" className="btn-primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CategoryCouponForm;
