import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";
import { getInternalNotificationById } from "../../services/InternalNotificationsServices";
import InternalNotificationForm from "./InternalNotificationForm";

function EditInternalNotification() {
  const navigate = useNavigate();

  const notificationId = useParams().id;

  const [notification, setNotification] = useState();

  const onEditNotification = (data) => {
    navigate(`/internal-notification`);
  };

  useEffect(() => {
    notificationId &&
      getInternalNotificationById(notificationId)
        .then((res) => {
          setNotification(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Edit Permission | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Internal Notification</H2>
        <Link
          to="/internal-notification"
          className="btn-primary flex items-center gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {notification && (
        <InternalNotificationForm
          formData={notification}
          edit={true}
          onSuccess={(data) => onEditNotification(data)}
        />
      )}
    </div>
  );
}

export default EditInternalNotification;
