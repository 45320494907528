import { admin } from "../../ServiceConfig";

export function getAllStayCategories() {
  return admin.get(`/stay-categories`);
}

export function addNewStayCategory(data){
  return admin.post(`/stay-categories`, data)
}

export function getStayCategoryById(id){
  return admin.get(`/stay-categories/${id}`)
}

export function editStayCategory(id, data){
  return admin.put(`/stay-categories/${id}`, data)
}

export function deleteStayCategory(id){
  return admin.delete(`/stay-categories/${id}`)
}

export function getAllStayAmeneties() {
  return admin.get(`/stay-amenities`);
}

export function addNewStayAmenity(data) {
  return admin.post(`/stay-amenities`, data)
}

export function getStayAmenityById(id){
  return admin.get(`/stay-amenities/${id}`)
}

export function editStayAmenity(id, data){
  return admin.put(`/stay-amenities/${id}`, data)
}

export function deleteStayAmenity(id){
  return admin.delete(`/stay-amenities/${id}`)
}

export function getAllStayFeatures() {
  return admin.get(`/stay-features`);
}

export function addNewStayFeatures(data){
  return admin.post(`/stay-features`, data)
}

export function getStayFeaturesById(id){
  return admin.get(`/stay-features/${id}`)
}

export function editStayFeatures(id, data){
  return admin.put(`/stay-features/${id}`, data)
}

export function deleteStayFeatures(id){
  return admin.delete(`/stay-features/${id}`)
}

export function addNewStay(data) {
  return admin.post(`/stay`, data);
}

export function updateStay(data) {
  return admin.put(`/stay/${data.id}`, data);
}

export function getStayById(id) {
  return admin.get(`/stay/${id}`);
}

export function deleteStayById(id) {
  return admin.delete(`/stay/${id}`);
}

export function deleteStayRoomImages(imageId) {
  return admin.delete(`/roomImages/${imageId}`);
}

export function getAllStaysByLocationId(locationId) {
  return admin.get(`/get-stay/${locationId}`);
}

export function uploadStayImage(file) {
  return admin.post(`uploads/image?upload_path=stays`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
