import { useRef } from "react";
import H2 from "../../components/headings/H2";
import ComponentHeader from "../components/common/ComponentHeader";
import DataTable from "../components/common/DataTable";
import { getAdminUrl } from "../../ServiceConfig";
import { getReadableDate, monthShortNames } from "../../helpers/Helper";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tabulator } from "tabulator-tables";
import { getMonthlyReportData } from "../services/BookingServices";

function MonthlyReport() {
  const reportTable = useRef();
  const navigate = useNavigate();

  const [month, setMonth] = useState(
    `${new Date().getMonth()} ${new Date().getFullYear()}`
  );

  const [mth, setMth] = useState(`${new Date().getMonth() + 1}`);
  const [year, setYear] = useState(`${new Date().getFullYear()}`);
  const [data, setData] = useState([]);

  useEffect(() => {
    // reportTable.current.reloadTable(
    //   `${getAdminUrl()}/monthly-report/${month}/${new Date().getFullYear()}`
    // );
    getMonthlyReportData(mth, year).then((res) => {
      console.log(res.data.data, "monthly report");
      setData(res.data.data);
    });
  }, [mth, year]);

  // const onRowClick = (data) => {
  //   if (data?.booking_id) navigate(`/bookings/${data.booking_id}`);
  // };

  const columns = [
    {
      title: "Sl no.",
      field: "batch_id",
      // responsive: 0,
      resizable: true,
      vertAlign: "middle",
      // formatter: function (cell, formatterParams, onRendered) {
      //   return getReadableDate(cell.getValue());
      // },
    },
    {
      title: "Package Name",
      field: "package_name",
      // responsive: 0,
      resizable: true,
      vertAlign: "middle",
      minWidth: "300",
      formatter: "textarea",
    },
    {
      title: "Event Date",
      field: "event_date",
      // responsive: 0,
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return getReadableDate(cell.getValue());
      },
      minWidth: "160",
    },
    {
      title: "Event Code",
      field: "event_code",
      // responsive: 0,
      minWidth: "160",
      formatter: "textarea",
      resizable: true,
      vertAlign: "middle",
    },

    {
      title: "Booking Id",
      field: "booking_id",
      // responsive: 0,
      resizable: true,
      vertAlign: "middle",
      download: true,
      minWidth: "160",
      formatter: "textarea",
    },
    {
      title: "Payment Id",
      field: "payment_ids",
      // responsive: 0,
      resizable: true,
      vertAlign: "middle",
      minWidth: "160",
      formatter: function (cell, formatterParams, onRendered) {
        var value = cell.getValue();
        if (Array.isArray(value)) {
          return value
            .map(function (item) {
              return `${item} <br>`;
            })
            .join("");
        } else if (value != null) {
          return `${value} <br>`;
        }
      },
    },
    {
      title: "Invoice Id",
      field: "invoice_ids",
      // responsive: 0,
      resizable: true,
      vertAlign: "middle",
      minWidth: "160",
      formatter: function (cell, formatterParams, onRendered) {
        var value = cell.getValue();
        if (Array.isArray(value)) {
          return value
            .map(function (item) {
              return `${item} <br>`;
            })
            .join("");
        } else if (value != null) {
          return `${value} <br>`;
        }
      },
    },
    {
      title: "Total Pax",
      field: "total_pax",
      // responsive: 0,
      resizable: true,
      vertAlign: "middle",
      minWidth: "160",
      formatter: "textarea",
    },
    {
      title: "Total Cost",
      field: "total_cost",
      // responsive: 0,
      resizable: true,
      vertAlign: "middle",
      minWidth: "160",
      formatter: "textarea",
    },
    {
      title: "Total Discount",
      field: "total_discount",
      // responsive: 0,
      resizable: true,
      vertAlign: "middle",
      minWidth: "160",
      formatter: "textarea",
    },
    {
      title: "Total After Discount",
      field: "total_cost_after_discount",
      // responsive: 0,
      resizable: true,
      vertAlign: "middle",
      minWidth: "160",
      formatter: "textarea",
    },
    {
      title: "Total GST",
      field: "total_gst",
      // responsive: 0,
      resizable: true,
      vertAlign: "middle",
      minWidth: "160",
      formatter: "textarea",
    },
    {
      title: "Total TCS",
      field: "total_tcs",
      // responsive: 0,
      resizable: true,
      vertAlign: "middle",
      minWidth: "160",
      formatter: "textarea",
    },
    {
      title: "Total Amount to Collect",
      field: "total_amount_to_pay",
      // responsive: 0,
      resizable: true,
      vertAlign: "middle",
      minWidth: "160",
      formatter: "textarea",
    },

    {
      title: "Amount Recieved",
      field: "amount_received_including_gst",
      // responsive: 0,
      resizable: true,
      vertAlign: "middle",
      minWidth: "160",
      formatter: "textarea",
    },
    {
      title: "Amount Recieved Excluding GST",
      field: "",
      // responsive: 0,
      resizable: true,
      vertAlign: "middle",
      minWidth: "160",
      formatter: function (cell, formatterParams, onRendered) {
        var row = cell.getRow();
        // Get the value of "Amount Received Including GST" from the same row
        var amountReceivedIncludingGST =
          row.getData().amount_received_including_gst;

        // Check if the value is not null
        if (
          amountReceivedIncludingGST != null &&
          amountReceivedIncludingGST > 0
        ) {
          // Perform the calculation to get "Amount Received Excluding GST"
          return (amountReceivedIncludingGST / 1.05).toFixed(2);
        } else {
          return "";
        }
      },
    },

    {
      title: "Remaining Amount",
      field: "remaining_amount",
      // responsive: 1,
      resizable: true,
      vertAlign: "middle",
      minWidth: "160",
      formatter: "textarea",
    },
    {
      title: "Refunded to Source",
      field: "refund_amount_to_source",
      // responsive: 1,
      resizable: true,
      vertAlign: "middle",
      minWidth: "160",
      formatter: "textarea",
    },
    {
      title: "Credit note Issued",
      field: "credit_note_issued",
      // responsive: 1,
      resizable: true,
      vertAlign: "middle",
      minWidth: "160",
      formatter: "textarea",
    },
    {
      title: "Deduction",
      field: "amount_deducted",
      // responsive: 1,
      resizable: true,
      vertAlign: "middle",
      minWidth: "160",
      formatter: "textarea",
    },
  ];

  function onChangeMonth(selectedMonth) {
    // setMonth(selectedMonth);
    setMth(parseInt(selectedMonth?.split(" ")[0]) + 1);
    setYear(parseInt(selectedMonth?.split(" ")[1]));
  }

  const getCurrentYearMonth = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    const currentMonths = [];

    for (let i = 0; i < 12; i++) {
      if (i >= currentMonth) {
        currentMonths.push(`${i} ${currentYear}`);
      }
    }

    return currentMonths;
  };

  const getPreviousMonths = (numMonths) => {
    const months = [];
    const currentDate = new Date();

    for (let i = 0; i < numMonths; i++) {
      currentDate.setMonth(currentDate.getMonth() - 1);
      const month = currentDate.getMonth();
      const year = currentDate.getFullYear();
      months.unshift(`${month} ${year}`);
    }

    return months;
  };

  const currentYearMonth = getCurrentYearMonth();
  const previousMonths = getPreviousMonths(3);

  const monthList = [...previousMonths, ...currentYearMonth];

  return (
    <div className="flex h-full flex-col">
      <ComponentHeader className="border-b">
        <div className="flex flex-wrap items-center gap-4">
          <H2>Monthly Report</H2>
          <div className="p-1 border-slate-400 cursor-pointer font-bold border rounded-md">
            {/* <select
              name=""
              id=""
              onChange={(e) => setMonth(e.target.value)}
              className="rounded-md border"
            >
              {monthShortNames?.map((month, index) => {
                return (
                  <option
                    key={index}
                    value={index + 1}
                    selected={index === new Date().getMonth()}
                  >
                    {month} {new Date().getFullYear()}
                  </option>
                );
              })}
            </select> */}
            <select
              name=""
              id=""
              onChange={(e) => {
                onChangeMonth(e.target.value);
              }}
              className=" dark:bg-slate-900 dark:text-white"
            >
              {monthList?.map((month) => {
                return (
                  <option
                    key={month}
                    value={month}
                    selected={
                      month ===
                      `${new Date().getMonth()} ${new Date().getFullYear()}`
                    }
                  >
                    {monthShortNames[parseInt(month.split(" ")[0])]}{" "}
                    {month.split(" ")[1]}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </ComponentHeader>

      <div className="h-[80vh] grow">
        <DataTable
          columns={columns}
          tableData={data}
          // height="100%"
          // pagination={false}
          downloadAble={true}
          pagination="local" // Enable local pagination
          paginationSize={10} // Number of rows per page
          // responsiveLayout="collapse"
          // rowFormatter={rowFormatter}
        />
        {/* <DataTable
          ref={reportTable}
          ajaxUrl={`${getAdminUrl()}/monthly-report/${month}/${new Date().getFullYear()}`}
          columns={columns}
          dataTree={true}
          search={true}
          perPage={25}
          responsiveLayout="hide"
          downloadAble={true}
          // rowClick={onRowClick}
          rowFormatter={rowFormatter}
          initialSort={[{ column: "id", dir: "desc" }]}
        /> */}
      </div>
    </div>
  );
}

export default MonthlyReport;
