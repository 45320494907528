import React from "react";
import TextComponent from "../components/settings/TextComponent";
import ImageComponent from "../components/settings/ImageComponent";

const address = {
  key: "address",
  placeholder: "write here address....",
  type: "text",
  description: "Set the Address to show throught.",
  warning: "",
};

const reviews = {
  id: 2,
  key: "reviews",
  placeholder: "Number of Reviews",
  type: "number",
  description: "Mention the number of Reviews to show on the website",
  warning: "",
};

const popupDesktop = {
  heading: "Popup Image and Link - Desktop",
  key: "popup-desktop",
  placeholder: "Upload Image",
  type: "image",
  urlType: "text",
  description: "This will be shown in the popup on the desktop only",
  warning: "Upload here image only",
};
const popupMobile = {
  heading: "Popup Image and Link - Mobile",
  key: "popup-mobile",
  placeholder: "Upload Image",
  type: "image",
  urlType: "text",
  description: "This will be shown in the popup on the phone only",
  warning: "Upload here image only",
};
const adbanner = {
  heading: "Ad Banner For Dektop - First",
  key: "ad-banner-desktop-1",
  placeholder: "Upload Image",
  type: "image",
  urlType: "text",
  description: "This is the first banner of the desktop",
  warning: "Upload here image only",
};

// const banner2 = {
//   id: 10,
//   urlId: 11,
//   urlKey: "desktop-banner-2-url",
//   key: "ad-banner-desktop-2",
//   placeholder: "image for desktop banner 1",
//   type: "image",
//   urlType: "text",
//   description: "This is the first banner of the desktop",
//   warning: "Upload here image only",
// };

const Settings = () => {
  return (
    <div className="my-4 h-[100%] overflow-auto">
      <div className="flex h-full flex-col items-center justify-start gap-3">
        <TextComponent data={address} />
        <TextComponent data={reviews} />
        <ImageComponent data={popupDesktop} />
        <ImageComponent data={popupMobile} />
        <ImageComponent data={adbanner} />
        <ImageComponent
          data={{
            heading: "Home Page Mobile Banner",
            key: "home-banner-mobile",
            placeholder: "Upload Image",
            type: "image",
            urlType: "text",
            description:
              "This will be used on the top of the home page in mobile device",
            warning: "Upload image only",
          }}
        />
        {/* <ImageComponent data={banner2} /> */}
      </div>
    </div>
  );
};

export default Settings;
