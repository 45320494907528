import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import InputLabel from "../../inputs/InputLabel";
import * as Yup from "yup";
import { MdClose } from "react-icons/md";
import { FaPlusSquare } from "react-icons/fa";
import {
  createItineraryDays,
  deleteItinDayWiseImages,
  deleteItineraryDay,
  getAllItinerariesByPackageId,
  getDaysByItineraryId,
  updateItineraryDays,
} from "../../../services/PackageService";
import ToggleInput from "../../inputs/ToggleInput";
import EditorInput from "../../inputs/EditorInput";
import ImageInput from "../../inputs/ImageInput";
import usePackageData from "../../../hooks/usePackageData";
import { toast } from "react-toastify";
import SelectSearch from "../../inputs/SelectSearch";

const packageDayWiseSchema = Yup.object().shape({
  itinerary_days: Yup.array()
    .of(
      Yup.object()
        .shape({
          heading: Yup.string().required("Required"),
          description: Yup.string().required("Required"),
          // stay_type: Yup.string().required("Required"),
        })
        .required("Required")
    )
    .required("Required"), // these constraints are shown if and only if inner constraints are satisfied
  // .min(3, "Minimum of 3 friends"),
});

function Step4({ onSuccess }) {
  const { packageData } = usePackageData();

  const [itineraries, setItineraries] = useState();
  const [selectedItinerary, setSelectedItinerary] = useState();
  const [selectedItineraryDays, setSelectedItineraryDays] = useState();

  const getSelectedItineraryDays = (itineraryId) => {
    getDaysByItineraryId(itineraryId).then((res) => {
      if (res.data.data.length > 0) {
        setSelectedItineraryDays(res.data.data);
      } else {
        setSelectedItineraryDays([
          {
            day_number: "",
            heading: "",
            description: "",
            breakfast: "",
            lunch: "",
            dinner: "",
            itinerary_day_wise_images: [],
            stay_type: "",
            stay_name: "",
          },
        ]);
      }
    });
  };

  const handleItineraryChange = (value) => {
    let itinerary = itineraries.filter((itinerary) => itinerary.id === value);
    setSelectedItinerary(itinerary[0].id);

    getSelectedItineraryDays(itinerary[0].id);
  };

  const deleteImage = (data) => {
    deleteItinDayWiseImages(data.id).then((res) => {
      console.log(res);
    });
  };

  const addItineraryDays = (values) => {
    values.itinerary_days.forEach((day) => {
      day.package_itinerary_id = selectedItinerary;
    });
    createItineraryDays(values)
      .then((res) => {
        onSuccess();
      })
      .catch((err) => {
        console.log(err, "ererere----------");
      });
  };

  const updateItineraryDay = (value, index) => {
    if (value.itinerary_days[index]?.id) {
      value.itinerary_days[index].day_number =
        packageData?.package?.category_id === 3 ? index : index + 1;
      updateItineraryDays(
        value.itinerary_days[index].id,
        value.itinerary_days[index]
      ).then((res) => {
        console.log(res.data.data);
        toast.success(res.data.message);
      });
    }
  };

  const deleteSelectedItineraryDay = (value) => {
    if (!window.confirm("Do You really want to delete this FAQ?")) {
      return false;
    } else {
      deleteItineraryDay(value).then((res) => {
        console.log(res.data.data);
      });
      return true;
    }
  };

  useEffect(() => {
    getAllItinerariesByPackageId(packageData.package?.id)
      .then((res) => {
        setItineraries(res.data.data);
      })
      .catch((err) => {
        console.log(err, "erererre---------");
      });
  }, []);

  return (
    <div>
      <div className="my-4">
        <h2 className="text-lg font-bold">Itinerary DayWise Detail</h2>
      </div>
      <div>
        <InputLabel label="Select Itinerary to Update" />
        <SelectSearch
          onChange={handleItineraryChange}
          value={selectedItinerary}
          options={itineraries?.map((itinerary) => ({
            label: `${itinerary.starting_location_name} to ${itinerary.ending_location_name}`,
            value: itinerary.id,
          }))}
        />
      </div>
      {selectedItinerary && (
        <div>
          <Formik
            enableReinitialize
            initialValues={{
              itinerary_days: selectedItineraryDays,
            }}
            validationSchema={packageDayWiseSchema}
            onSubmit={(values) => {
              addItineraryDays(values);
            }}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Form>
                <div className="mt-4">
                  <div className="">
                    <div className="">
                      <h2 className="font-bold">Enter Itinerary Days</h2>
                    </div>
                    <FieldArray name={`itinerary_days`}>
                      {({ insert, remove, push }) => (
                        <div>
                          <button
                            type="button"
                            className="my-2 flex items-center gap-1 px-4 text-jw-green"
                            onClick={() =>
                              insert(0, {
                                day_number: "",
                                heading: "",
                                description: "",
                                breakfast: false,
                                lunch: false,
                                dinner: false,
                                itinerary_day_wise_images: [],
                                stay_type: "",
                                stay_name: "",
                              })
                            }
                          >
                            <FaPlusSquare /> Day At Top
                          </button>
                          {values.itinerary_days?.length > 0 &&
                            values.itinerary_days?.map((days, index) => (
                              <div
                                className="mb-2 rounded-lg border-2 p-4"
                                key={days.id ? days.id : index}
                              >
                                <div className="relative mb-2 border-b pb-2 text-center font-bold">
                                  <h3>
                                    {console.log(
                                      packageData?.package?.category_id
                                    )}
                                    Enter Detail for Day{" "}
                                    {packageData?.package?.category_id === 3
                                      ? index
                                      : index + 1}
                                  </h3>
                                  <div
                                    onClick={() => {
                                      let isDeleting =
                                        deleteSelectedItineraryDay(
                                          values.itinerary_days[index].id
                                        );
                                      isDeleting && remove(index);
                                    }}
                                    className="absolute right-0 top-0 rounded-full border-2 p-1"
                                  >
                                    <MdClose />
                                  </div>
                                </div>
                                {/* //? Itinerary Title */}
                                <div className="">
                                  <InputLabel
                                    label="Heading"
                                    labelFor={`itinerary_days.${index}.heading`}
                                  />
                                  <Field
                                    id={`itinerary_days.${index}.heading`}
                                    name={`itinerary_days.${index}.heading`}
                                    type="text"
                                    className="field"
                                    onBlur={() => {
                                      setFieldValue(
                                        `itinerary_days.${index}.day_number`,
                                        index + 1
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`itinerary_days.${index}.heading`}
                                  >
                                    {(msg) => (
                                      <div className="error-msg">{msg}</div>
                                    )}
                                  </ErrorMessage>
                                </div>

                                {/* //? Itinerary Detail */}
                                <div className="my-4">
                                  <InputLabel
                                    label="Description"
                                    labelFor={`itinerary_days.${index}.description`}
                                  />
                                  <EditorInput
                                    fieldKey={`itinerary_days.${index}.description`}
                                    data={
                                      values.itinerary_days[index].description
                                    }
                                  />
                                </div>

                                {/* //? Itinerary day inclusions */}
                                <div className="my-4">
                                  <InputLabel label="Included :-" />
                                  <div className="flex flex-wrap justify-between px-4 lg:justify-start">
                                    <div className="min-w-[15%]">
                                      <InputLabel label="Breakfast" />
                                      <ToggleInput
                                        fieldKey={`breakfast${index}`}
                                        checked={
                                          values.itinerary_days[index].breakfast
                                        }
                                        onToggle={(e) => {
                                          setFieldValue(
                                            `itinerary_days.${index}.breakfast`,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="min-w-[15%]">
                                      <InputLabel label="Lunch" />
                                      <ToggleInput
                                        fieldKey={`lunch${index}`}
                                        checked={
                                          values.itinerary_days[index].lunch
                                        }
                                        onToggle={(e) => {
                                          setFieldValue(
                                            `itinerary_days.${index}.lunch`,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="min-w-[15%]">
                                      <InputLabel label="Dinner" />
                                      <ToggleInput
                                        fieldKey={`dinner${index}`}
                                        checked={
                                          values.itinerary_days[index].dinner
                                        }
                                        onToggle={(e) => {
                                          setFieldValue(
                                            `itinerary_days.${index}.dinner`,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="my-4 flex gap-4">
                                  <div className="grow basis-full lg:basis-[45%]">
                                    <InputLabel
                                      label="Stay Type"
                                      labelFor="stay-type"
                                    />
                                    <SelectSearch
                                      onChange={(value) =>
                                        setFieldValue(
                                          `itinerary_days.${index}.stay_type`,
                                          value
                                        )
                                      }
                                      options={[
                                        { label: "Hotel", value: "hotel" },
                                        {
                                          label: "Home Stay",
                                          value: "home-stay",
                                        },
                                        { label: "Camp", value: "camp" },
                                      ]}
                                      value={
                                        values.itinerary_days[index].stay_type
                                      }
                                    />

                                    <ErrorMessage
                                      name={`itinerary_days.${index}.stay_type`}
                                    >
                                      {(msg) => (
                                        <div className="error-msg">{msg}</div>
                                      )}
                                    </ErrorMessage>
                                  </div>
                                  <div className="grow basis-full lg:basis-[45%]">
                                    <InputLabel
                                      label="Stay Name"
                                      labelFor="stay-name"
                                    />
                                    <Field
                                      type="text"
                                      name={`itinerary_days.${index}.stay_name`}
                                      className="field disabled:cursor-not-allowed"
                                      disabled={
                                        values.itinerary_days[index]
                                          .stay_type === "camp" ||
                                        values.itinerary_days[index]
                                          .stay_type === ""
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="my-4">
                                  <InputLabel
                                    label="Images"
                                    labelFor={`itinerary_days.${index}.itinerary_day_wise_images`}
                                  />
                                  <ImageInput
                                    allowedFiles={8}
                                    path="package/images/itinerary/original"
                                    images={
                                      values.itinerary_days[index]
                                        .itinerary_day_wise_images
                                    }
                                    setImages={(value) => {
                                      setFieldValue(
                                        `itinerary_days.${index}.itinerary_day_wise_images`,
                                        value
                                      );
                                    }}
                                    onDelete={(image) => deleteImage(image)}
                                  />
                                </div>
                              </div>
                            ))}
                          <button
                            type="button"
                            className="my-2 flex items-center gap-1 px-4 text-jw-green"
                            onClick={() =>
                              push({
                                day_number: "",
                                heading: "",
                                description: "",
                                breakfast: "",
                                lunch: "",
                                dinner: "",
                                itinerary_day_wise_images: [],
                                stay_type: "",
                                stay_name: "",
                              })
                            }
                          >
                            <FaPlusSquare /> Day At Bottom
                          </button>
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </div>

                <div className="my-4 text-center">
                  <button type="submit" className="btn-primary">
                    submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
}

export default Step4;
