import { admin } from "../../ServiceConfig";

export function updateParticipantsById(bookingId, participants) {
  return admin.put(`/participants/${bookingId}`, participants);
}

export function uploadParticipantId(file) {
  return admin.post(
    `uploads/image?upload_path=bookings/participant_id_attachment`,
    file,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

export function uploadParticipantMedicalCertificate(file) {
  return admin.post(
    `uploads/image?upload_path=bookings/participants/medical-certifcates`,
    file,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

export function uploadParticipantCovidCertificate(file) {
  return admin.post(
    `uploads/image?upload_path=bookings/participants/covid-certifcates`,
    file,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

export function uploadParticipantPanCard(file) {
  return admin.post(
    `uploads/image?upload_path=bookings/participants/pan_card`,
    file,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

export function uploadParticipantVisa(file) {
  return admin.post(
    `uploads/image?upload_path=bookings/participants/visa`,
    file,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

export function uploadParticipantAirTicket(file) {
  return admin.post(
    `uploads/image?upload_path=bookings/participants/air_ticket`,
    file,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}
