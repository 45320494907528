import { Outlet } from "react-router-dom";

function ListingSeoWrapper() {
  return (
    <div className="wrapper">
      {/* <MetaTags title={"All Countries"} /> */}
      {/* <div className="h-full max-h-[calc(100%-1rem)] overflow-auto"> */}
        {<Outlet />}
      {/* </div> */}
    </div>
  );
}

export default ListingSeoWrapper;
