// src/VerticalMarquee.js

import React from 'react';
import SingleNotificationCard from '../dashboard/SingleNotificationCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const VerticalMarquee = ({ allInternalNotifications }) => {


  return (
    <div className="flex flex-col items-center w-full">
      <div className="h-96 w-full flex justify-center items-center p-4">
        <Swiper
          // navigation={true}
          modules={[Autoplay, Pagination, Scrollbar]}
          direction={'vertical'}
          pagination={{
            clickable: true,
          }}
          slidesPerView={4.6}
          effect={'fade'}
          autoplay={{ delay: 3000 }}
          className="w-full h-full mySwiper"
        >
          {allInternalNotifications?.map((singleInternalNotification, index) => (
            <SwiperSlide key={index} className="flex justify-center items-center">
              <SingleNotificationCard key={singleInternalNotification.id} singleNotification={singleInternalNotification} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default VerticalMarquee;



{/* <div className="overflow-hidden h-40 w-full relative rounded-lg">
<div className="absolute inset-0 flex flex-col-reverse animate-marquee">
  {allInternalNotifications?.map((singleInternalNotification, index) => (
      <SingleNotificationCard key={singleInternalNotification.id} singleNotification={singleInternalNotification}/>
  ))}
</div>
</div> */}


{/* <div className="flex w-full h-full flex-col items-center">
<div className="relative w-full overflow-hidden flex">
  <div ref={carouselRef} className="overflow-y-auto h-full scrollbar-hidden flex-1">
    <ul className="space-y-4 p-4">
      {allInternalNotifications?.map((singleInternalNotification, index) => (
        <SingleNotificationCard key={singleInternalNotification.id} singleNotification={singleInternalNotification} />
      ))}
    </ul>
  </div>
  <div className='flex flex-col justify-between'>
    <FaChevronUp
      onClick={scrollUp}
      className="dark:text-white cursor-pointer"
    />
    <FaChevronDown
      onClick={scrollDown}
      className="dark:text-white cursor-pointer"
    />
  </div>
</div>

</div> */}