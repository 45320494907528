import { admin } from "../../ServiceConfig";

export function createProformaInvoice(data) {
  return admin.post("/proforma-invoice", data);
}

export function getProformaInvoiceById(id) {
  return admin.get(`/proforma-invoice/${id}`);
}

export function updateProformaInvoice(id, data) {
  return admin.put(`/proforma-invoice/${id}`, data);
}

export function sendProformaInvoice(id) {
  return admin.get(`/send-proforma-email/${id}`);
}


