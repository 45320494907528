import React, { useState } from 'react'
import { getUploadsUrl } from '../../../ServiceConfig'
import NotificationPopup from './NotificationPopup'

const SingleNotificationsingleNotification = ({ singleNotification }) => {
  const [openNotificationModal, setOpenNotificationModal] = useState(false)
  return (
    <>
      <div className="w-full lg:px-6 px-1 py-2 bg-white dark:bg-slate-800 dark:bg-text-white rounded-lg shadow-[1px_1px_4px_rgba(0,0,0,0.15)] flex items-center space-x-4">
        <img src={`${getUploadsUrl()}/internal-notification/${singleNotification.image}`} alt={singleNotification.image} className="lg:w-24 w-16 h-auto rounded-md object-cover" />
        <div className="flex-1 flex justify-between">
          <div className='flex flex-col'>
            <h2 className="lg:text-xl text-md font-semibold dark:text-white  text-gray-800">{singleNotification.title}</h2>
            <p className="lg:text-sm text-xs flex dark:text-white text-gray-600">{singleNotification.description.slice(0, 12)}...
            </p>
          </div>
          <div className="flex items-center justify-between mt-4">
            <p onClick={() => { setOpenNotificationModal(true) }} className="cursor-pointer underline  text-blue-500 text-sm py-1 px-2 font-bold rounded">
              Read More
            </p>
          </div>
        </div>
      </div>
      <NotificationPopup open={openNotificationModal} setOpen={setOpenNotificationModal} data={singleNotification} />
    </>
  )
}

export default SingleNotificationsingleNotification