import { Route, Routes } from "react-router-dom";
import AdminLayout from "../admin/AdminLayout";
import AddCategory from "../admin/components/categories/AddCategory";
import CategoryList from "../admin/components/categories/CategoryList";
import CategoryWrapper from "../admin/components/categories/CategoryWrapper";
import EditCategory from "../admin/components/categories/EditCategory";
import DashboardWrapper from "../admin/components/dashboard/DashboardWrapper";
import EventDatesWrapper from "../admin/components/eventDates/EventDatesWrapper";
import EventList from "../admin/components/eventDates/EventList";
import AddLocation from "../admin/components/locations/AddLocation";
import EditLocation from "../admin/components/locations/EditLocation";
import LocationList from "../admin/components/locations/LocationList";
import LocationWrapper from "../admin/components/locations/LocationWrapper";
import AddPackage from "../admin/components/packages/AddPackage";
import EditPackage from "../admin/components/packages/EditPackage";
import PackageList from "../admin/components/packages/PackageList";
import PackageWrapper from "../admin/components/packages/PackageWrapper";
import RequireAdminAuth from "../admin/components/RequireAdminAuth";
import EditState from "../admin/components/states/EditState";
import StateList from "../admin/components/states/StateList";
import StateWrapper from "../admin/components/states/StateWrapper";
import CountryWrapper from "../admin/components/countries/CountryWrapper";
import CountryList from "../admin/components/countries/CountryList";
import AddCountry from "../admin/components/countries/AddCountry";
import EditCountry from "../admin/components/countries/EditCountry";
import TravelModeWrapper from "../admin/components/travelModes/TravelModeWrapper";
import TravelModeList from "../admin/components/travelModes/TravelModeList";
import AddTravelMode from "../admin/components/travelModes/AddTravelMode";
import EditTravelMode from "../admin/components/travelModes/EditTravelMode";
import RiderWrapper from "../admin/components/riders/RiderWrapper";
import RiderList from "../admin/components/riders/RiderList";
import AddRider from "../admin/components/riders/AddRider";
import EditRider from "../admin/components/riders/EditRider";
import OccupancyWrapper from "../admin/components/occupancies/OccupancyWrapper";
import OccupancyList from "../admin/components/occupancies/OccupancyList";
import AddOccupancy from "../admin/components/occupancies/AddOccupancy";
import EditOccupancy from "../admin/components/occupancies/EditOccupancy";
// import useLoader from "../hooks/useLoader";
import BookingWrapper from "../admin/bms/BookingWrapper";
import BookingList from "../admin/bms/BookingList";
import AddBooking from "../admin/bms/AddBooking";
import EditBooking from "../admin/bms/EditBooking";
import CouponWrapper from "../admin/components/coupons/CouponWrapper";
import CouponList from "../admin/components/coupons/CouponList";
import AddCoupon from "../admin/components/coupons/AddCoupon";
import EditCoupon from "../admin/components/coupons/EditCoupon";
import ViewBooking from "../admin/bms/ViewBooking";
import NotFound404 from "../admin/components/NotFound404";
import AdminLogin from "../AdminLogin";
import BookingStatus from "../admin/bms/BookingStatus";
import VerificationLinks from "../admin/bms/VerificationLinks";
import AddVerificationLink from "../admin/bms/AddVerificationLink";
import Participants from "../admin/bms/Participants";
import EmployeeWrapper from "../admin/employees/EmployeeWrapper";
import AddEmployee from "../admin/employees/AddEmployee";
import EditEmployee from "../admin/employees/EditEmployee";
import EmployeeList from "../admin/employees/EmployeeList";
import AddPayments from "../admin/bms/AddPayments";
import LmsWrapper from "../admin/lms/LmsWrapper";
import ViewVerificationLink from "../admin/bms/ViewVerificationLink";
import UserLayout from "../admin/user/UserLayout";
import Profile from "../admin/user/Profile";
import ForgotPassword from "../admin/user/ForgotPassword";
import ResetPassword from "../admin/user/ResetPassword";
import GiftCardWrapper from "../admin/components/gift-card/GiftCardWrapper";
import GiftCardList from "../admin/components/gift-card/GiftCardList";
import OfficialContactsWrapper from "../admin/components/officialContacts/OfficialContactsWrapper";
import LeadConditionsWrapper from "../admin/lms/leadConditions/LeadConditionsWrapper";
import ConditionsList from "../admin/lms/leadConditions/ConditionsList";
import AddCondition from "../admin/lms/leadConditions/AddCondition";
import AddGiftCard from "../admin/components/gift-card/AddGiftCard";
import EditGiftCard from "../admin/components/gift-card/EditGiftCard";
import GiftCardCategories from "../admin/components/giftCardCategories/GiftCardCategories";
import PushNotificationWrapper from "../admin/components/push-notification/PushNotificationWrapper";
import AddPushNotification from "../admin/components/push-notification/AddPushNotification";
import PushNotificationList from "../admin/components/push-notification/PushNotificationList";
import EditPushNotification from "../admin/components/push-notification/EditPushNotification";
import PageContactWrapper from "../admin/components/page-contacts/PageContactWrapper";
import PageContactList from "../admin/components/page-contacts/PageContactList";
import AddPageContact from "../admin/components/page-contacts/AddPageContact";
import EditPageContact from "../admin/components/page-contacts/EditPageContact";
import ItineraryBuilderWrapper from "../admin/components/itineraryBuilder/ItineraryBuilderWrapper";
import AssignPacakgeToExecutive from "../admin/components/assign-package-to-executive/AssignPacakgeToExecutive";
import AddItinerary from "../admin/components/itineraryBuilder/AddItinerary";
import StayWrapper from "../admin/components/stays/StayWrapper";
import StayList from "../admin/components/stays/StayList";
import AddStay from "../admin/components/stays/AddStay";
import EditStay from "../admin/components/stays/EditStay";
import TransferWrapper from "../admin/components/transfers/TransferWrapper";
import TransferList from "../admin/components/transfers/TransferList";
import AddTransfer from "../admin/components/transfers/AddTranfer";
import EditTransfer from "../admin/components/transfers/EditTransfer";
import UpdateItinerary from "../admin/components/itineraryBuilder/UpdateItinerary";
import ItineraryList from "../admin/components/itineraryBuilder/ItineraryList";
import ViewItinerary from "../admin/components/itineraryBuilder/ViewItinerary";
import LeadListWrapper from "../admin/lms/LeadListWrapper";
import LeadDashboard from "../admin/lms/LeadDashboard";
import LeadSettings from "../admin/lms/LeadSettings";
import EditCondition from "../admin/lms/leadConditions/EditCondition";
import ThingsToKnowWrapper from "../admin/components/things-to-know/ThingsToKnowWrapper";
import ThingsToKnowList from "../admin/components/things-to-know/ThingsToKnowList";
import AddThingsToKnow from "../admin/components/things-to-know/AddThingsToKnow";
import EditThingsToKnow from "../admin/components/things-to-know/EditThingsToKnow";
import SpecialDatesWrapper from "../admin/components/special-dates/SpecialDatesWrapper";
import SpecialDatesList from "../admin/components/special-dates/SpecialDatesList";
import RoleWrapper from "../admin/components/roles/RoleWrapper";
import RoleList from "../admin/components/roles/RoleList";
import AddRole from "../admin/components/roles/AddRole";
import EditRole from "../admin/components/roles/EditRole";
import PermissionWrapper from "../admin/components/permissions/PermissionWrapper";
import PermissionList from "../admin/components/permissions/PermisssionList";
import AddPermission from "../admin/components/permissions/AddPermission";
import EditPermission from "../admin/components/permissions/EditPermission";

import GoogleReviewWrapper from "../admin/components/googleReviews/GoogleReviewWrapper";
import GoogleReviewList from "../admin/components/googleReviews/GoogleReviewList";
import AddGoogleReview from "../admin/components/googleReviews/AddGoogleReview";
import EditGoogleReview from "../admin/components/googleReviews/EditGoogleReview";
import ModuleWrapper from "../admin/components/modules/ModuleWrapper";
import ModuleList from "../admin/components/modules/ModuleList";
import AddModule from "../admin/components/modules/AddModule";
import EditModule from "../admin/components/modules/EditModule";
import AddState from "../admin/components/states/AddState";
import MonthlyReport from "../admin/bms/MonthlyReport";
import NewsletterWrapper from "../admin/components/newsletter/NewsletterWrapper";
import NewsletterList from "../admin/components/newsletter/NewsletterList";
import NewsletterSubscribers from "../admin/components/newsletter/NewsletterSubscribers";
import AddNewsletter from "../admin/components/newsletter/AddNewsletter";
import EditNewsletter from "../admin/components/newsletter/EditNewsletter";
import JobWrapper from "../admin/components/job/JobWrapper";
import JobList from "../admin/components/job/JobList";
import AddJob from "../admin/components/job/AddJob";
import EditJob from "../admin/components/job/EditJob";
import InternshipWrapper from "../admin/components/internship/InternshipWrapper";
import InternshipList from "../admin/components/internship/InternshipList";
import AddInternship from "../admin/components/internship/AddInternship";
import EditInternship from "../admin/components/internship/EditInternship";
import JobApplications from "../admin/components/job/JobApplications";
import InternshipApplications from "../admin/components/internship/InternshipApplications";
import ListingSeoWrapper from "../admin/components/listing-seo/ListingSeoWrapper";
import ListingSeoList from "../admin/components/listing-seo/ListingSeoList";
import AddListingSeo from "../admin/components/listing-seo/AddListingSeo";
import UpdateListingSeo from "../admin/components/listing-seo/UpdateLisitngSeo";
import LeadReport from "../admin/lms/LeadReport";
import ProformaInvoices from "../admin/bms/proformaInvoice/ProformaInvoices";
import AddProformaInvoice from "../admin/bms/proformaInvoice/AddProformaInvoice";
import EditProformaInvoice from "../admin/bms/proformaInvoice/EditProformaInvoice";
import ViewProformaInvoice from "../admin/bms/proformaInvoice/ViewProformaInvoice";
import AdminStatWidgetWrapper from "../admin/components/admin-stat-widget/AdminStatWidgetWrapper";
import AdminStatWidgetList from "../admin/components/admin-stat-widget/AdminStatWidgetList";
import AddAdminStatWidget from "../admin/components/admin-stat-widget/AddAdminStatWidget";
import EditAdminStatWidget from "../admin/components/admin-stat-widget/EditAdminStatWidget";
import MissedBookingList from "../admin/bms/MissedBookingList";
import DuePaymentBookingList from "../admin/bms/DuePaymentBookingList";
import UpcomingPackages from "../admin/components/packages/UpcomingPackages";
import CreditNotesList from "../admin/components/creditnotes/CreditNotesList";
import SingleCreditNote from "../admin/components/creditnotes/SingleCreditNote";
import SingleJobApplication from "../admin/components/job/SingleJobApplication";
import TeamWrapper from "../admin/components/team/TeamWrapper";
import TeamList from "../admin/components/team/TeamList";
import Settings from "../admin/settings/Settings";
import B2BWrapper from "../admin/b2b/B2BWrapper";
import B2BList from "../admin/b2b/B2BList";
import AddB2B from "../admin/b2b/AddB2B";
import EditB2B from "../admin/b2b/EditB2B";
import InterNalNotificationsWrapper from "../admin/components/internal-notifications/InterNalNotificationsWrapper";
import InternalNotificationList from "../admin/components/internal-notifications/InternalNotificationList";
import AddInternalNotification from "../admin/components/internal-notifications/AddInternalNotification";
import EditInternalNotification from "../admin/components/internal-notifications/EditInternalNotification";
import RnRWrapper from "../admin/RnR/RnRWrapper";
import RewardsList from "../admin/RnR/RewardsList";
import EditReward from "../admin/RnR/EditReward";
import AddReward from "../admin/RnR/AddReward";
import RnRLists from "../admin/RnR/RnRLists";
import AddRnR from "../admin/RnR/AddRnR";
import RnREditForm from "../admin/RnR/RnREditForm";
import HomepageCategoryWrapper from "../admin/components/homepage-category/HomepageCategoryWrapper";
import HomepageCategoryList from "../admin/components/homepage-category/HomepageCategoryList";
import AddHomePageCategory from "../admin/components/homepage-category/AddHomePageCategory";
import AssignPackagetoCategory from "../admin/components/homepage-category/AssignPackagetoCategory";
import EditHomepageCategory from "../admin/components/homepage-category/EditHomepageCategory";
import ReorderCategory from "../admin/components/homepage-category/ReorderCategory";
import ReorderJobs from "../admin/components/job/ReorderJobs";
import ShowtrekCertificate from "../admin/bms/trekcertificate/ShowtrekCertificate";
import ThingsToPackWrapper from "../admin/components/things-to-pack/ThingsToPackWrapper";
import ThingsToPackList from "../admin/components/things-to-pack/ThingsToPackList";
import AddThingsToPack from "../admin/components/things-to-pack/AddThingsToPack";
import EditThingsToPack from "../admin/components/things-to-pack/EditThingsToPack";
import SingleInternshipsApplication from "../admin/components/internship/SingleInternshipsApplication";
import AmenitiesWrapper from "../admin/components/amenities/AmenitiesWrapper";
import AmenitiesList from "../admin/components/amenities/AmenitiesList";
import AddAmenities from "../admin/components/amenities/AddAmenities";
import EditAmenities from "../admin/components/amenities/EditAmenities";
import FeaturesWrapper from "../admin/components/features/FeaturesWrapper";
import FeaturesList from "../admin/components/features/FeaturesList";
import AddFeatures from "../admin/components/features/AddFeatures";
import EditFeatures from "../admin/components/features/EditFeatures";
import StayCategoriesWrapper from "../admin/components/stayCategory/StayCategoriesWrapper";
import StayCategoriesList from "../admin/components/stayCategory/StayCategoriesList";
import AddStayCategories from "../admin/components/stayCategory/AddStayCategories";
import EditStayCategories from "../admin/components/stayCategory/EditStayCategories";

function AdminRoutes() {
  // const { setLoading } = useLoader();

  return (
    <Routes>
      <Route path="/login" element={<AdminLogin />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route element={<RequireAdminAuth />}>
        <Route path="/" element={<AdminLayout />}>
          <Route index element={<DashboardWrapper />} />
          <Route path="upcoming-events" element={<UpcomingPackages />} />

          <Route path="user" element={<UserLayout />}>
            <Route exact path="profile/:id" element={<Profile />} />
          </Route>
          <Route path="team" element={<TeamWrapper />}>
            <Route index element={<TeamList />} />
          </Route>
          <Route path="location" element={<LocationWrapper />}>
            <Route index element={<LocationList />} />
            <Route exact path="add" element={<AddLocation />} />
            <Route path="edit/:id" element={<EditLocation />} />
          </Route>
          <Route path="state" element={<StateWrapper />}>
            <Route index element={<StateList />} />
            <Route exact path="add" element={<AddState />} />
            <Route path="edit/:id" element={<EditState />} />
          </Route>
          <Route path="country" element={<CountryWrapper />}>
            <Route index element={<CountryList />} />
            <Route exact path="add" element={<AddCountry />} />
            <Route path="edit/:id" element={<EditCountry />} />
          </Route>
          <Route path="category" element={<CategoryWrapper />}>
            <Route index element={<CategoryList />} />
            <Route exact path="add" element={<AddCategory />} />
            <Route path="edit/:id" element={<EditCategory />} />
          </Route>
          <Route path="travel-mode" element={<TravelModeWrapper />}>
            <Route index element={<TravelModeList />} />
            <Route exact path="add" element={<AddTravelMode />} />
            <Route path="edit/:id" element={<EditTravelMode />} />
          </Route>
          <Route path="rider" element={<RiderWrapper />}>
            <Route index element={<RiderList />} />
            <Route exact path="add" element={<AddRider />} />
            <Route path="edit/:id" element={<EditRider />} />
          </Route>
          <Route path="occupancy" element={<OccupancyWrapper />}>
            <Route index element={<OccupancyList />} />
            <Route exact path="add" element={<AddOccupancy />} />
            <Route path="edit/:id" element={<EditOccupancy />} />
          </Route>
          <Route path="package" element={<PackageWrapper />}>
            <Route index element={<PackageList />} />
            <Route exact path="add" element={<AddPackage />} />
            <Route path="edit/:id" element={<EditPackage />} />
          </Route>
          <Route path="employees" element={<EmployeeWrapper />}>
            <Route index element={<EmployeeList />} />
            <Route exact path="add" element={<AddEmployee />} />
            <Route path="edit/:id" element={<EditEmployee />} />
          </Route>
          <Route path="b2b" element={<B2BWrapper />}>
            <Route index element={<B2BList />} />
            <Route exact path="add" element={<AddB2B />} />
            <Route exact path="edit/:id" element={<EditB2B />} />
          </Route>
          <Route path="roles" element={<RoleWrapper />}>
            <Route index element={<RoleList />} />
            <Route exact path="add" element={<AddRole />} />
            <Route path="edit/:id" element={<EditRole />} />
          </Route>
          <Route path="module" element={<ModuleWrapper />}>
            <Route index element={<ModuleList />} />
            <Route exact path="add" element={<AddModule />} />
            <Route path="edit/:id" element={<EditModule />} />
          </Route>
          <Route path="permissions" element={<PermissionWrapper />}>
            <Route index element={<PermissionList />} />
            <Route exact path="add" element={<AddPermission />} />
            <Route path="edit/:id" element={<EditPermission />} />
          </Route>
          <Route path="coupons" element={<CouponWrapper />}>
            <Route index element={<CouponList />} />
            <Route exact path="add" element={<AddCoupon />} />
            <Route path="edit/:id" element={<EditCoupon />} />
          </Route>
          <Route path="gift-cards" element={<GiftCardWrapper />}>
            <Route index element={<GiftCardList />} />
            <Route exact path="add" element={<AddGiftCard />} />
            <Route path="edit/:id" element={<EditGiftCard />} />
          </Route>
          <Route path="credit-notes" element={<GiftCardWrapper />}>
            <Route index element={<CreditNotesList />} />
            <Route exact path=":id" element={<SingleCreditNote />} />
          </Route>
          <Route
            path="internal-notification"
            element={<InterNalNotificationsWrapper />}
          >
            <Route index element={<InternalNotificationList />} />
            <Route exact path="add" element={<AddInternalNotification />} />
            <Route path="edit/:id" element={<EditInternalNotification />} />
          </Route>
          <Route path="rnr" element={<RnRWrapper />}>
            <Route index element={<RnRLists />} />
            <Route exact path="add" element={<AddRnR />} />
            <Route exact path="edit/:id" element={<RnREditForm />} />
            <Route exact path="reward" element={<RewardsList />} />
            <Route exact path="reward/add" element={<AddReward />} />
            <Route path="reward/edit/:id" element={<EditReward />} />
          </Route>
          <Route path="gift-card-categories" element={<GiftCardCategories />} />
          <Route path="bookings" element={<BookingWrapper />}>
            <Route index element={<BookingList />} />
            <Route path="missed" element={<MissedBookingList />} />
            <Route path="trekcertificate" element={<ShowtrekCertificate />} />
            <Route
              path="duePaymentBookings"
              element={<DuePaymentBookingList />}
            />
            <Route exact path=":id" element={<ViewBooking />} />
            <Route exact path="add" element={<AddBooking />} />
            <Route path="edit/:id" element={<EditBooking />} />
            <Route path="status" element={<BookingStatus />} />
            <Route path="balancePayment/:bookingId" element={<AddPayments />} />
            <Route path="participants" element={<Participants />} />
            <Route path="proforma-invoice">
              <Route index element={<ProformaInvoices />} />
              <Route path=":id" element={<ViewProformaInvoice />} />
              <Route path="add" element={<AddProformaInvoice />} />
              <Route path="edit/:id" element={<EditProformaInvoice />} />
            </Route>

            <Route path="verification-links">
              <Route index element={<VerificationLinks />} />
              <Route path=":id" element={<ViewVerificationLink />} />
              <Route path="add" element={<AddVerificationLink />} />
              <Route path="add/:leadId" element={<AddVerificationLink />} />
            </Route>
          </Route>
          <Route path="settings" element={<Settings />} />
          <Route path="leads" element={<LmsWrapper />}>
            <Route index element={<LeadListWrapper />} />
            <Route exact path="dashboard" element={<LeadDashboard />} />
            <Route exact path="settings" element={<LeadSettings />} />
            <Route exact path="report" element={<LeadReport />} />
          </Route>
          <Route path="lead-conditions" element={<LeadConditionsWrapper />}>
            <Route index element={<ConditionsList />} />
            <Route exact path="add" element={<AddCondition />} />
            <Route exact path="edit/:id" element={<EditCondition />} />
          </Route>
          <Route
            path="push-notifications"
            element={<PushNotificationWrapper />}
          >
            <Route index element={<PushNotificationList />} />
            <Route exact path="add" element={<AddPushNotification />} />
            <Route path="edit/:id" element={<EditPushNotification />} />
          </Route>
          <Route path="page-contacts" element={<PageContactWrapper />}>
            <Route index element={<PageContactList />} />
            <Route exact path="add" element={<AddPageContact />} />
            <Route path="edit/:id" element={<EditPageContact />} />
          </Route>
          <Route path="google-reviews" element={<GoogleReviewWrapper />}>
            <Route index element={<GoogleReviewList />} />
            <Route exact path="add" element={<AddGoogleReview />} />
            <Route path="edit/:id" element={<EditGoogleReview />} />
          </Route>
          <Route path="event-dates" element={<EventDatesWrapper />}>
            <Route index element={<EventList />} />
          </Route>
          <Route path="itinerary-builder" element={<ItineraryBuilderWrapper />}>
            <Route index element={<ItineraryList />} />
            <Route exact path=":id" element={<ViewItinerary />} />
            <Route exact path="add" element={<AddItinerary />} />
            <Route exact path="edit/:id" element={<UpdateItinerary />} />
          </Route>
          <Route
            path="assign-package-to-executive"
            element={<AssignPacakgeToExecutive />}
          />

          <Route path="stays" element={<StayWrapper />}>
            <Route index element={<StayList />} />
            <Route exact path="add" element={<AddStay />} />
            <Route path="edit/:id" element={<EditStay />} />
          </Route>
          <Route path="transfers" element={<TransferWrapper />}>
            <Route index element={<TransferList />} />
            <Route exact path="add" element={<AddTransfer />} />
            <Route path="edit/:id" element={<EditTransfer />} />
          </Route>
          <Route path="amenities" element={<AmenitiesWrapper />}>
            <Route index element={<AmenitiesList />} />
            <Route exact path="add" element={<AddAmenities />} />
            <Route path="edit/:id" element={<EditAmenities />} />
          </Route>
          <Route path="features" element={<FeaturesWrapper />}>
            <Route index element={<FeaturesList />} />
            <Route exact path="add" element={<AddFeatures />} />
            <Route path="edit/:id" element={<EditFeatures />} />
          </Route>
          <Route path="stay-categories" element={<StayCategoriesWrapper />}>
            <Route index element={<StayCategoriesList />} />
            <Route exact path="add" element={<AddStayCategories />} />
            <Route path="edit/:id" element={<EditStayCategories />} />
          </Route>
          <Route path="things-to-pack" element={<ThingsToPackWrapper />}>
            <Route index element={<ThingsToPackList />} />
            <Route exact path="add" element={<AddThingsToPack />} />
            <Route path="edit/:id" element={<EditThingsToPack />} />
          </Route>
          <Route path="things-to-know" element={<ThingsToKnowWrapper />}>
            <Route index element={<ThingsToKnowList />} />
            <Route exact path="add" element={<AddThingsToKnow />} />
            <Route path="edit/:id" element={<EditThingsToKnow />} />
          </Route>
          <Route path="newsletter" element={<NewsletterWrapper />}>
            <Route index element={<NewsletterList />} />
            <Route exact path="add" element={<AddNewsletter />} />
            <Route path="edit/:id" element={<EditNewsletter />} />
            <Route path="subscribers" element={<NewsletterSubscribers />} />
          </Route>
          <Route path="jobs" element={<JobWrapper />}>
            <Route index element={<JobList />} />
            <Route exact path="add" element={<AddJob />} />
            <Route path="edit/:id" element={<EditJob />} />
            <Route path="applications" element={<JobApplications />} />
            <Route path="applications/:id" element={<SingleJobApplication />} />
            <Route path="changeorder" element={<ReorderJobs />} />
          </Route>
          <Route path="internships" element={<InternshipWrapper />}>
            <Route index element={<InternshipList />} />
            <Route exact path="add" element={<AddInternship />} />
            <Route path="edit/:id" element={<EditInternship />} />
            <Route path="applications" element={<InternshipApplications />} />
            <Route path="applications/:id" element={<SingleInternshipsApplication />} />
          </Route>
          <Route path="special-dates" element={<SpecialDatesWrapper />}>
            <Route index element={<SpecialDatesList />} />
          </Route>

          <Route path="/bookings/monthly-report" element={<BookingWrapper />}>
            <Route index element={<MonthlyReport />} />
          </Route>

          <Route path="/listing-seo" element={<ListingSeoWrapper />}>
            <Route index element={<ListingSeoList />} />
            <Route exact path="add" element={<AddListingSeo />} />
            <Route exact path="edit/:id" element={<UpdateListingSeo />} />
          </Route>

          <Route path="/admin-stat-widget" element={<AdminStatWidgetWrapper />}>
            <Route index element={<AdminStatWidgetList />} />
            <Route exact path="add" element={<AddAdminStatWidget />} />
            <Route exact path="edit/:id" element={<EditAdminStatWidget />} />
          </Route>

          <Route
            path="/homepage-category"
            element={<HomepageCategoryWrapper />}
          >
            <Route index element={<HomepageCategoryList />} />
            <Route exact path="add" element={<AddHomePageCategory />} />
            <Route exact path="changeorder" element={<ReorderCategory />} />
            <Route exact path="edit/:id" element={<EditHomepageCategory />} />
            <Route
              exact
              path="assign-package/:id"
              element={<AssignPackagetoCategory />}
            />
          </Route>

          <Route
            path="official-contacts"
            element={<OfficialContactsWrapper />}
          ></Route>
          <Route exact path="*" element={<NotFound404 />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default AdminRoutes;
