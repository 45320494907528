import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import DataTable from "../common/DataTable";
import { getAdminUrl } from "../../../ServiceConfig";
import ComponentHeader from "../common/ComponentHeader";
import H2 from "../../../components/headings/H2";
import { FaPlusSquare } from "react-icons/fa";
import { deleteStayFeatures } from "../../services/StayServices";

function FeaturesList() {
    const navigate = useNavigate();
    const featuresTable = useRef();

    const deleteSelectedFeature = (id) => {
        if (!window.confirm("Do You really want to delete this Feature?")) return;
        deleteStayFeatures(id)
            .then((res) => {
                toast.success(res.data.message);
                featuresTable.current.deleteRow(id);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };

    const actionIcons = function (cell, formatterParams, onRendered) {
        return `
    <div class="flex items-center gap-3">
      <div class="feature-edit-button cursor-pointer" data-id=${cell.getRow().getData().id
            }>
          <i class="fas fa-edit"></i>
      </div>
      <div class="feature-delete-button cursor-pointer text-red-500" data-id=${cell.getRow().getData().id
            }>
        <i class="fas fa-trash-alt"></i>
      </div>
  </div>`;
    };


    const columns = [
        {
            formatter: "responsiveCollapse",
            width: 30,
            minWidth: 30,
            responsive: 0,
            hozAlign: "center",
            resizable: false,
            headerSort: false,
        },
        {
            title: "Id",
            field: "id",
            responsive: 0,
            width: "50",
            resizable: true,
            vertAlign: "middle",
        },
        {
            title: "Name",
            field: "name",
            responsive: 0,
            minWidth: "200",
            resizable: true,
            vertAlign: "middle",
        },
        {
            formatter: actionIcons,
            headerSort: false,
            width: 80,
            responsive: 0,
            hozAlign: "center",
            vertAlign: "middle",
        },
    ];

    useEffect(() => {
        $(document).on("click", ".feature-edit-button", function (e) {
            e.stopPropagation();
            e.preventDefault();
            let cell = $(this).attr("data-id");
            navigate(`/features/edit/${cell}`);
        });
        $(document).on("click", ".feature-delete-button", function (e) {
            e.stopPropagation();
            e.preventDefault();
            let dataId = $(this).attr("data-id");
            deleteSelectedFeature(dataId);
        });
        return () => {
            $(document).off("click", ".feature-edit-button");
            $(document).off("click", ".feature-delete-button");
        };
    }, []);

    return (
        <div className="flex h-full flex-col">
            <ComponentHeader className="border-b">
                <H2>Stay Features</H2>
                <Link
                    to="/features/add"
                    className="btn-primary flex items-center gap-1 whitespace-nowrap"
                >
                    <FaPlusSquare /> Add New
                </Link>
            </ComponentHeader>
            <div className="h-full">
                <DataTable
                    ref={featuresTable}
                    ajaxUrl={`${getAdminUrl()}/stay-features`}
                    columns={columns}
                    search={true}
                    responsiveLayout="collapse"
                />
            </div>
        </div>
    );
}

export default FeaturesList;
