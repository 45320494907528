import React, { useEffect, useState } from "react";
import { FaListAlt } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import H2 from "../../components/headings/H2";
import ComponentHeader from "../components/common/ComponentHeader";
import EmployeeForm from "./EmployeeForm";
import { getEmployeeById } from "../services/EmployeeServices";
import NotAuthorized403 from "../components/NotAuthorized403";
import { checkPermission } from "../services/AdminServices";
import useLoader from "../../hooks/useLoader";
import { getRoleById } from "../services/RoleServices";
import EmployeePermissionComponent from "./EmployeePermissionComponent";

function EditEmployee() {
  const navigate = useNavigate();
  const [authorized, setAuthorized] = useState(true);
  const { setLoading } = useLoader();
  const [role, setRole] = useState();
  const [employeeData, setEmployeeData] = useState();
  const [openEmployeePermissionModal, setOpenEmployeePermissionModal] =
    useState(false);
  useEffect(() => {
    setLoading(true);
    checkPermission("Employee.update")
      .then((res) => {
        setAuthorized(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }, []);

  useEffect(() => {
    if (employeeData?.role)
      getRoleById(employeeData?.role)
        .then((res) => {
          setRole(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [employeeData]);

  const employeeId = useParams().id;

  console.log(employeeData, role);
  const onEditEmployee = (data) => {
    navigate(`/employees`);
  };

  useEffect(() => {
    employeeId &&
      getEmployeeById(employeeId)
        .then((res) => {
          setEmployeeData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return !authorized ? (
    <NotAuthorized403 />
  ) : (
    <div className="h-[100%] dark:bg-slate-900 dark:text-white">
      {/* <MetaTags title="Edit State | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Employee</H2>
        <Link
          to="/employees"
          className="btn-primary flex items-center gap-1 whitespace-nowrap py-1 px-4 dark:bg-green-700 lg:px-12 lg:py-2"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {employeeData && (
        <>
          <EmployeeForm
            formData={employeeData}
            edit={true}
            onSuccess={(data) => onEditEmployee(data)}
          />
          <button
            type="button"
            className="me-2 mb-2 rounded-lg bg-gradient-to-r from-green-500 via-green-600 to-green-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gradient-to-br focus:outline-none focus:ring-4 focus:ring-green-300 dark:bg-green-800 dark:focus:ring-green-800"
            onClick={() => {
              setOpenEmployeePermissionModal(true);
            }}
          >
            Assign Permission Seprately
          </button>
        </>
      )}
      {role && (
        <EmployeePermissionComponent
          setOpen={setOpenEmployeePermissionModal}
          open={openEmployeePermissionModal}
          permissionsEmployeeAlreadyHave={employeeData.permissions}
          permissionsOfRole={role?.permissions}
          employeeId={employeeId}
        />
      )}
    </div>
  );
}

export default EditEmployee;
