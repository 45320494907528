import React, { useEffect, useState } from "react";
import StatWidget from "./StatWidget";
import { getAdminWidgets } from "../services/DashboardServices";

function StatWidgetWrapper() {
  const [widgets, setWidgets] = useState();

  useEffect(() => {
    getAdminWidgets()
      .then((res) => {
        setWidgets(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <div className="flex flex-wrap">
        {widgets &&
          widgets.map((data) => (
            <StatWidget
              data={data}
              // textBgClasses="bg-gradient-to-r from-blue-600 from-10% via-sky-500 via-30% to-emerald-500 to-90% text-white"
              textBgClasses="bg-gradient-to-r from-cyan-700 to-cyan-500 text-white"
            />
          ))}
      </div>
    </div>
    // <div className="mx-auto ">
    //   <div className="2xl:gap-7.5 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4">

    //   </div>
    // </div>
  );
}

export default StatWidgetWrapper;
