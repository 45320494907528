import FileInput from "../../../components/inputFields/FileInput";

import InputLabel from "../inputs/InputLabel";
import { toast } from "react-toastify";

// -----------------------------------------------------------
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PdfInput from "../inputs/PdfInput";
import DateInput from "../inputs/DateInput";
import { formatNumberToTwoDigit } from "../../../helpers/Helper";
import {
  createNewsletter,
  updateNewsletter,
  uploadNewsletterImage,
} from "../../services/NewsletterServices";
import { getUploadsUrl } from "../../../ServiceConfig";

const NewsletterFormSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  issuedate: Yup.string().required("required"),
  image: Yup.string().required("required"),
  pdf: Yup.string().required("required"),
});

function NewsletterForm({ formData, edit, onSuccess }) {
  console.log(formData, "fdfdffdfff=============");

  // ? Add new Location
  const addNewsletter = (values) => {
    createNewsletter(values)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  // ? Update Newsletter
  const editNewsletter = (values) => {
    updateNewsletter(formData.id, values)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          image: formData?.image,
          title: formData?.title,
          description: formData?.description,
          issuedate: formData?.issuedate,
          pdf: formData?.pdf,
        }}
        validationSchema={NewsletterFormSchema}
        onSubmit={(values) => {
          edit ? editNewsletter(values) : addNewsletter(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="flex flex-wrap gap-4">
              {/* //? Banner Image */}
              <div className="grow">
                <FileInput
                  value={`${getUploadsUrl()}/newsletter/${values.image}`}
                  noOfFilesAllowed={1}
                  onChange={(file) => {
                    uploadNewsletterImage({ image: file }).then((res) => {
                      setFieldValue("image", res.data.data[0].uploaded_image);
                    });
                  }}
                />
                <ErrorMessage name="image">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              <div className="grow">
                <div className="flex flex-wrap gap-4">
                  {/* //? location Name */}
                  <div className="grow">
                    <InputLabel label="Title" labelFor="name" />
                    <Field className="field" id="title" name="title" />
                    <ErrorMessage name="title">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                {/* //? Location Description */}
                <div>
                  <InputLabel label="Description" labelFor="description" />
                  <Field
                    name="description"
                    as="textarea"
                    className="field"
                  ></Field>
                  <ErrorMessage name="description">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>

                <div className="grow">
                  <InputLabel label="Issue Date" labelFor="name" />
                  <DateInput
                    onDateChange={(value) => {
                      setFieldValue(
                        `issuedate`,
                        `${new Date(
                          value
                        ).getFullYear()}-${formatNumberToTwoDigit(
                          new Date(value).getMonth() + 1
                        )}-${formatNumberToTwoDigit(new Date(value).getDate())}`
                      );
                    }}
                    selectedDate={values.issuedate}
                  />
                  <ErrorMessage name="issuedate">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>

                <div className="my-4">
                  <h2 className="text-lg font-semibold">Upload Pdf</h2>
                  <PdfInput
                    allowedFiles={1}
                    path="newsletter/pdf"
                    pdfFiles={values.pdf ? [values.pdf] : null}
                    setPdfFiles={(value) => setFieldValue("pdf", value)}
                  />
                  <ErrorMessage name="pdf">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
            </div>

            <div className="mt-4 text-center">
              <button type="submit" className="btn-primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default NewsletterForm;
