import { Link, useNavigate, useParams } from "react-router-dom";
import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import H2 from "../../../components/headings/H2";
import NewsletterForm from "./NewsletterForm";
import { useState } from "react";
import { useEffect } from "react";
import { getNewsletterById } from "../../services/NewsletterServices";

function EditNewsletter() {
  const navigate = useNavigate();
  const newsletterId = useParams().id;
  const [newsletter, setNewsletter] = useState();

  const onAddNewsletter = (data) => {
    navigate(`/newsletter`);
  };

  useEffect(() => {
    getNewsletterById(newsletterId)
      .then((res) => {
        setNewsletter(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="">
      {/* <MetaTags title="Add Location | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Edit Newsletter</H2>
        <Link
          to="/newsletter"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      <NewsletterForm
        edit={true}
        formData={newsletter}
        onSuccess={(data) => onAddNewsletter(data)}
      />
    </div>
  );
}

export default EditNewsletter;
