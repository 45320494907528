import React, { useEffect, useState } from "react";
import FileInput from "../../../components/inputFields/FileInput";

import {
  updateCategory,
  uploadCategoryImage,
} from "../../services/CategoryServices";
import InputLabel from "../inputs/InputLabel";
import useLoader from "../../../hooks/useLoader";
import { toast } from "react-toastify";
import { createCategory } from "../../services/CategoryServices";
import { getUploadsUrl } from "../../../ServiceConfig";

// -----------------------------------------------------------
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import KeywordsInput from "../inputs/KeywordsInput";

const CategoryFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  category_type: Yup.string().required("Required"),

  status: Yup.number().required("Required"),
  meta_title: Yup.string()
    .nullable()
    .max(70, "Meta Title should not more than 70 characters"),
  meta_description: Yup.string()
    .max(160, "Meta Description should not more than 160 characters")
    .nullable(),
  meta_keywords: Yup.array().of(Yup.string().nullable()).max(10).nullable(),
});
function CategoryForm({ formData, edit, onSuccess }) {
  const { setLoading } = useLoader();

  // ? Add new Category
  const addCategory = (values) => {
    let data = Object.assign({}, values);
    createCategory(data)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  // ? Update Category
  const editCategory = (values) => {
    let data = Object.assign({}, values);
    updateCategory(formData.id, data)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div>
      <Formik
        initialValues={{
          banner_image: formData?.banner_image || "",
          name: formData?.name,
          category_type: formData?.category_type,
          status: formData?.status.toString() || "1",
          description: formData?.description,
          meta_title: formData?.meta_title,
          meta_description: formData?.meta_description || "",
          meta_keywords: formData?.meta_keywords,
        }}
        validationSchema={CategoryFormSchema}
        onSubmit={(values) => {
          edit ? editCategory(values) : addCategory(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="flex gap-4">
              {/* //? Banner Image */}
              <div className="grow">
                <FileInput
                  noOfFilesAllowed={1}
                  onChange={(file) => {
                    uploadCategoryImage({ image: file }).then((res) => {
                      setFieldValue(
                        "banner_image",
                        res.data.data[0].uploaded_image
                      );
                    });
                  }}
                />
              </div>

              <div className="grow">
                <div className="flex flex-wrap gap-4">
                  {/* //? Categroy Name */}
                  <div className="max-w-[50%] grow basis-[45%]">
                    <InputLabel label="Name" labelFor="name" />
                    <Field
                      className="block w-full rounded-md border-2 py-1 px-2"
                      id="name"
                      name="name"
                    />
                    <ErrorMessage name="name">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="my-4 flex flex-wrap gap-4">
                  {/* //? Select State */}
                  <div className="max-w-[50%] grow basis-[45%]">
                    <InputLabel
                      label="Select Category Type"
                      labelFor="category_type"
                    />
                    <Field
                      name="category_type"
                      as="select"
                      className="block w-full rounded-md border-2 py-1 px-2 disabled:cursor-not-allowed"
                      onChange={(e) => {
                        setFieldValue("category_type", e.target.value);
                      }}
                    >
                      <option>Select Category Type</option>
                      <option value="1">Fixed Departure</option>
                      <option value="0">Customized Trip</option>
                    </Field>
                    <ErrorMessage name="category_type">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                {/* //? Category Status */}
                <div className="my-4">
                  <div role="group" aria-labelledby="my-radio-group">
                    <InputLabel label="Status" />
                    <div className="flex items-center gap-12">
                      <label className="flex items-baseline gap-1">
                        <Field type="radio" name="status" value="1" />
                        Active
                      </label>
                      <label className="flex items-baseline gap-1">
                        <Field type="radio" name="status" value="0" />
                        Inactive
                      </label>
                    </div>
                  </div>
                </div>

                {/* //? Category Description */}
                <div>
                  <InputLabel label="Description" labelFor="description" />
                  <Field
                    name="description"
                    as="textarea"
                    className="block w-full rounded-md border-2 py-1 px-2"
                  ></Field>
                  <ErrorMessage name="description">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
            </div>

            {/* //? Meta Fields */}
            <div className="my-4 rounded-lg border p-4">
              <h3 className="mb-2 text-lg font-bold">Meta Info</h3>

              {/* //? Category Meta Title */}
              <div>
                <InputLabel
                  label="Meta Title"
                  labelFor="meta_title"
                  instruction="(Max 70 Characters)"
                />
                <Field
                  className="block w-full rounded-md border-2 py-1 px-2"
                  id="meta_title"
                  name="meta_title"
                />
                <ErrorMessage name="meta_title">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              {/* //? Categroy Meta Description */}
              <div className="my-4">
                <InputLabel
                  label="Meta Description"
                  labelFor="meta_description"
                  instruction="(Max 160 Characters)"
                />
                <Field
                  name="meta_description"
                  as="textarea"
                  className="block w-full rounded-md border-2 py-1 px-2"
                ></Field>
                <ErrorMessage name="meta_description">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              {/* //? Category Meta Keywords */}
              <InputLabel
                label="Meta Keywords"
                labelFor="meta_keywords"
                instruction="(Max 160 Characters)"
              />
              <KeywordsInput
                value={
                  values?.meta_keywords
                    ? values?.meta_keywords?.split(",")?.map((v) => ({
                        label: v,
                        value: v,
                      }))
                    : []
                }
                onChange={(value) =>
                  setFieldValue(
                    "meta_keywords",
                    value.map((v) => v.value).toString()
                  )
                }
              />
            </div>

            <div className="text-center">
              <button type="submit" className="btn-primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CategoryForm;
