import { Link } from "react-router-dom";
import useSideBarToggle from "../../hooks/useSideBarToggle";
import useScreenSize from "../../../hooks/useScreenSize";
import { FaCircle } from "react-icons/fa";

function SidebarSubMenuDropdown({ menu, index, selectedChildIndex, setSelectedChildIndex }) {
  const { sidebarOpen, setSidebarOpen } = useSideBarToggle();
  const isSelectedMenu = menu.submenu.some((sub) => sub.link === window.location.pathname)
  const { breakpoint } = useScreenSize();
  const handleSubmenuClick = () => {
    if (breakpoint === "sm") {
      setSidebarOpen(false);
    }
  }

  return <div className={`relative`}>
    <div onClick={() => { setSelectedChildIndex(index); console.log(index) }}
      className={`flex ${isSelectedMenu &&  "bg-[#0183c0] text-white dark:bg-slate-800"} duration-400 cursor-pointer items-center gap-4 whitespace-nowrap rounded-sm p-3 transition ease-in-out hover:bg-[#0183c0] hover:text-white hover:dark:bg-slate-800 hover:dark:text-white ${sidebarOpen ?  "gap-4" : "justify-center gap-0"
        }`}
    >
      <span
        className={`transition-all duration-100 dark:text-white ${selectedChildIndex === index ? "text-2xl" : "text-xl"
          }`}
      >
        {menu?.icon}
      </span>
      <span
        className={`transition-all duration-100 ease-out `}
      >
        {menu?.label}
      </span>
    </div>
    <div className={`pl-6 ${selectedChildIndex === index ? "" : "h-0 w-0 opacity-0"}  dark:bg-slate-700`}>
      {menu.submenu.map((menu, index) =>
        <Link
          key={menu.label}
          to={menu.link}
          onClick={handleSubmenuClick}
          className={` ${menu.link === window.location.pathname &&     "bg-[#D7F2FD] dark:bg-slate-800"} flex  ${index === 0 && 'pt-4'} flex items-center justify-start gap-2 rounded-sm px-3 py-2 hover:bg-[#D7F2FD]`}
        >
          <FaCircle size={6} /> {menu.label}
        </Link>
      )}
    </div>
  </div>
}
export default SidebarSubMenuDropdown;
