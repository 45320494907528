import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import DataTable from "../../components/common/DataTable";
import { deleteState } from "../../services/StateServices";
import { getAdminUrl } from "../../../ServiceConfig";
import ComponentHeader from "../../components/common/ComponentHeader";
import H2 from "../../../components/headings/H2";
import { FaPlusSquare } from "react-icons/fa";
import { getReadableDate } from "../../../helpers/Helper";
import ToggleInput from "../../components/inputs/ToggleInput";

function ProformaInvoices() {
  const navigate = useNavigate();
  const bookingTable = useRef();
  const [onlyCustomized, setOnlyCustomized] = useState(false);

  const deleteSelectedState = (id) => {
    deleteState(id)
      .then((res) => {
        toast.success(res.data.message);
        bookingTable.current.deleteRow(id);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  function cellClick(cell) {
    console.log(cell.getData().encoded_id, "cell clicked");

    if (cell.getColumn().getDefinition().title === "URL") {
      let copyText = `justwravel.tech/booking/payment-verification/${cell.getData().encoded_id
        }`;
      navigator.clipboard.writeText(copyText);
      toast.success("Link Copied.");
    }
  }

  const rowFormatter = (row) => {
    if (row.getData().is_customized === 1) {
      const children = row.getElement().childNodes;
      children.forEach((child) => {
        child.style.color = "#9E6F21";
      });
    }
  };

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `<div class="flex items-center gap-3">
    <div class="proforma-edit-btn cursor-pointer" data-id=${cell.getRow().getData().id
      }>
          <i class="fas fa-edit"></i>
      </div>
    <div class="proforma-view-btn" data-id=${cell.getRow().getData().id}>
    <i class="fas fa-eye"></i>
    </div></div>
   `;
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      maxWidth: "80",
      minWidth: "70",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Package",
      field: "package_name",
      minWidth: "420",
      responsive: 0,
      vertAlign: "middle",
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Event Date",
      minWidth: "250",
      field: "event_date",
      responsive: 0,
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return getReadableDate(cell.getValue());
      },
    },
    {
      title: "Traveller",
      minWidth: "220",
      field: "first_name",  
      responsive: 0,
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return `${cell.getRow().getData().first_name} ${cell.getRow().getData().last_name ? cell.getRow().getData().last_name : ''
          } <br/> ${cell.getRow().getData().email} <br/> ${cell.getRow().getData().phone
          }`;
      },
    },
    {
      title: "Pax",
      field: "total_pax",
      resizable: true,
      vertAlign: "middle",
      minWidth: "70",
      responsive: 0,
      hozAlign: "center",
    },

    {
      title: "Amount",
      field: "total_amount",
      resizable: true,
      vertAlign: "middle",
      minWidth: "90",
      responsive: 0,
    },
    {
      formatter: actionIcons,
      headerSort: false,
      minWidth: "90",
      responsive: 0,
      vertAlign: "middle",
    },
  ];

  useEffect(() => {
    $(document).on("click", ".proforma-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/bookings/proforma-invoice/edit/${cell}`);
    });

    $(document).on("click", ".proforma-view-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/bookings/proforma-invoice/${cell}`);
    });

    return () => {
      $(document).off("click", ".verification-view-btn");
    };
  }, []);

  return (
    <div className="flex flex-col">
      <ComponentHeader className="border-b">
        <div className="flex flex-wrap items-center gap-4">
          <H2>Proforma Invoices</H2>
          {/* <div className="hidden items-center gap-1 lg:flex">
            <ToggleInput
              checked={onlyCustomized}
              onToggle={(e) => setOnlyCustomized(e.target.checked)}
            />
            <span className="font-medium">Only Customized</span>
          </div> */}
        </div>
        <Link
          to="/bookings/proforma-invoice/add"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaPlusSquare /> Add New
        </Link>
      </ComponentHeader>

      {/* <div className="my-2 flex items-center gap-1 lg:hidden">
        <ToggleInput
          checked={onlyCustomized}
          onToggle={(e) => setOnlyCustomized(e.target.checked)}
        />
        <span className="font-medium">Only Customized</span>
      </div> */}

      {/* <div className="mt-2 flex items-center gap-4">
        <div className="flex items-center">
          <div className="h-2 w-2 rounded-full bg-[#D83F31]"></div>
          <p className="rounded-full px-1 text-[0.625rem] font-medium leading-3 text-[#D83F31]">
            Cancelled
          </p>
        </div>
        <div className="flex items-center">
          <div className="h-2 w-2 rounded-full bg-[#3876BF]"></div>
          <p className="rounded-full px-1 text-[0.625rem] font-medium leading-3 text-[#3876BF]">
            Customized Boking
          </p>
        </div>
        <div className="flex items-center">
          <div className="h-2 w-2 rounded-full bg-[#9E6F21]"></div>
          <p className="rounded-full px-1 text-[0.625rem] font-medium leading-3 text-[#9E6F21]">
            Customized
          </p>
        </div>
      </div> */}

      <div className="h-full">
        <DataTable
          ref={bookingTable}
          ajaxUrl={`${getAdminUrl()}/proforma-invoice`}
          columns={columns}
          search={true}
          cellClick={cellClick}
          layout="fitColumns"
          initialSort={[{ column: "id", dir: "desc" }]}
          responsiveLayout="collapse"
          rowFormatter={rowFormatter}
        />
      </div>
    </div>
  );
}

export default ProformaInvoices;
