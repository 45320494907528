import { DataTableProvider } from "../context/DataTableProvider";
import LeadList from "./LeadsList";

function LeadListWrapper() {
  return (
    <div className="h-full">
      <DataTableProvider>
        <LeadList />
      </DataTableProvider>
    </div>
  );
}

export default LeadListWrapper;
