import { FaPlusSquare } from "react-icons/fa";
import { Link, Outlet } from "react-router-dom";
import H2 from "../../../components/headings/H2";
// import MetaTags from "../../../web/layouts/MetaTags";
import ComponentHeader from "../common/ComponentHeader";

function PackageWrapper() {
  return (
    <div className="wrapper">
      {/* <MetaTags title="All States | Admin Panel" /> */}

      <div className="h-full min-h-[calc(100vh-1rem)] overflow-auto">
        {<Outlet />}
      </div>
    </div>
  );
}

export default PackageWrapper;
