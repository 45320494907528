import React, { useEffect, useState } from "react";
import ComponentHeader from "../common/ComponentHeader";
import H2 from "../../../components/headings/H2";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaListAlt } from "react-icons/fa";
import AdminStatWidgetForm from "./AdminStatWidgetForm";
import { getAdminStatWidgetById } from "../../services/AdminStatWidgetService";

function EditAdminStatWidget() {
  const navigate = useNavigate();
  const widgetId = useParams().id;
  const [widget, setWidget] = useState();
  const onEditAdminStatWidget = (data) => {
    navigate(`/admin-stat-widget`);
  };
  useEffect(() => {
    widgetId &&
      getAdminStatWidgetById(widgetId)
        .then((res) => {
          setWidget(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);
  return (
    <div className="">
      {/* <MetaTags title="Add Category | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Edit Admin Stat Widget</H2>
        <Link
          to="/admin-stat-widget"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>

      {widget && (
        <AdminStatWidgetForm
          formData={widget}
          edit={true}
          onSuccess={(data) => onEditAdminStatWidget(data)}
        />
      )}
    </div>
  );
}

export default EditAdminStatWidget;
