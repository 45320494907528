import { admin } from "../../ServiceConfig";

export function getAllEmployee() {
  return admin.get("/employees");
}
export function getAllEmployeesOption() {
  return admin.get("/allEmployeesOption");
}

export function getEmployeeById(employeeId) {
  return admin.get(`/employees/${employeeId}`);
}

export function addEmployee(employee) {
  return admin.post("/employees", employee);
}

export function updateEmployee(employeeId, data) {
  return admin.put(`/employees/${employeeId}`, data);
}

export function uploadEmployeeImage(file) {
  return admin.post(`uploads/image?upload_path=employees`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function uploadEmployeeCutOutImage(file) {
  return admin.post(`uploads/image?upload_path=employees_cutout`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function changePassword(password) {
  return admin.post(`/change-password`, password);
}

export function getAllUnassignedContacts() {
  return admin.get(`/available-contacts`);
}

export function getEmployeeAssignedPackages(employeeId) {
  return admin.get(`/assigned-package/${employeeId}`);
}

export function assignPackagesToEmployee(data) {
  return admin.post(`/assign-package`, data);
}

export function updateEmployeePreference(data) {
  return admin.post(`/employee-preference`, data);
}

export function updateEmployeePermissionById(data) {
  return admin.post(`/assignPermission`, data);
}
