import { useEffect, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAdminAuth from "../hooks/useAdminAuth";
import { checkLogin } from "../services/AdminServices";

const RequireAdminAuth = ({ allowedRoles }) => {
  const location = useLocation();
  const { auth, setAuth } = useAdminAuth();

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!auth.id && !auth.status && localStorage.getItem("admin-token")) {
      checkLogin()
        .then((res) => {
          setAuth(res.data.data);
          setChecked(true);
        })
        .catch((err) => {
          localStorage.removeItem("admin-token");
        });
    } else {
      setChecked(true);
    }
  }, []);

  if (checked) {
    return auth?.id ? (
      <Outlet />
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    );
  }
};

export default RequireAdminAuth;
